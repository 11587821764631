import { setTransitionApiConfiguration } from './TransitionService'
import { setAsparkingGateControlApiConfiguration } from './AsparkingGateControlService'
import { setAsparkingSemaphoreApiConfiguration } from './AsparkingSemaphoreControlService'
import { setAuditLogApiConfiguration } from './AuditService'
import { setCardApiConfiguration } from './CardService'
import { setCompanyApiConfiguration } from './CompanyService'
import { setContainerApiConfiguration } from './ContainerService'
import { setCoparnApiConfiguration } from './CoparnService'
import { setGateInApiConfiguration } from './GateInService'
import { setGateOutApiConfiguration } from './GateOutService'
import { setGateApiConfiguration } from './GateService'
import { setInvoiceApiConfiguration } from './InvoiceService'
import { setLicensePlateApiConfiguration } from './LicensePlateService'
import { setM2mTrainApiConfiguration } from './M2mTrainService'
import { setNhmApiConfiguration } from './NhmService'
import { setNotificationControllerApiConfiguration } from './NotificationService'
import { setPriceListApiConfiguration } from './PriceListService'
import { setProcessedFileApiConfiguration } from './ProcessedFileService'
import { setShipownerApiConfiguration } from './ShipownerService'
import { seAsparkingTicketApiConfiguration } from './TicketService'
import { setTrainExportConfigurationApiConfiguration } from './TrainExportConfigurationService'
import { setTrainApiConfiguration } from './TrainService'
import { setUserApiConfiguration } from './UserService'
import { setCdCargoExportConfigurationApiConfiguration } from './CdCargoExportConfigurationService'
import { setUploadDownloadApiConfiguration } from './UploadDownloadService'
import { setCarrierApiConfiguration } from './CarrierService'
import { setRidApiConfiguration } from './RidService'
import { setTruckMonitoringApiConfiguration } from './TruckMonitoringService'
import { setShortTermPassageConfiguration } from './ShortTermPassageService'
import { setVeronaApiServiceConfiguration } from './VeronaService'
import { setManipulationRequestApiConfiguration } from './ManipulationRequestService'
import { setTerminalLayoutApiConfiguration } from './TerminalLayoutService'
import { setDriverArrivalApiConfiguration } from './DriverArrivalService'
import { setDriverApiConfiguration } from './DriverService'
import { setAutocompleteApiConfiguration } from './AutocompleteService'
import { setUploadedFileApiConfiguration } from './UploadedFileService'
import { setDriverArrivalKioskApiConfiguration } from './KioskDriverArrivalService'
import { setTrainGateInApiConfiguration } from './TrainGateInService'
import { setTrainGateOutApiConfiguration } from './TrainGateOutService'
import { setExtraApiConfiguration } from './TransitionExtraService'

export const setApiConfig = () => {
  setTransitionApiConfiguration()
  setAsparkingGateControlApiConfiguration()
  setAsparkingSemaphoreApiConfiguration()
  setAuditLogApiConfiguration()
  setCardApiConfiguration()
  setCompanyApiConfiguration()
  setContainerApiConfiguration()
  setCoparnApiConfiguration()
  setGateInApiConfiguration()
  setGateOutApiConfiguration()
  setGateApiConfiguration()
  setInvoiceApiConfiguration()
  setLicensePlateApiConfiguration()
  setM2mTrainApiConfiguration()
  setNhmApiConfiguration()
  setNotificationControllerApiConfiguration()
  setPriceListApiConfiguration()
  setProcessedFileApiConfiguration()
  setShipownerApiConfiguration()
  seAsparkingTicketApiConfiguration()
  setTrainExportConfigurationApiConfiguration()
  setTrainApiConfiguration()
  setUserApiConfiguration()
  setCdCargoExportConfigurationApiConfiguration()
  setUploadDownloadApiConfiguration()
  setRidApiConfiguration()
  setCarrierApiConfiguration()
  setTruckMonitoringApiConfiguration()
  setShortTermPassageConfiguration()
  setVeronaApiServiceConfiguration()
  setManipulationRequestApiConfiguration()
  setTerminalLayoutApiConfiguration()
  setDriverArrivalApiConfiguration()
  setDriverArrivalKioskApiConfiguration()
  setDriverApiConfiguration()
  setAutocompleteApiConfiguration()
  setUploadedFileApiConfiguration()
  setTrainGateInApiConfiguration()
  setTrainGateOutApiConfiguration()
  setExtraApiConfiguration()
}
