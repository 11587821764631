import { PageShortTermPassageDto, ShortTermPassageApi, ShortTermPassageDto, ShortTermPassageSearchDto } from '../api'
import myAxios from '../config/axios/axios'
import { AxiosPromise } from 'axios'

let api: ShortTermPassageApi

export const setShortTermPassageConfiguration = () => {
  api = new ShortTermPassageApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const shortTermPassageService = {
  findAll: (
    page: number,
    pageSize: number,
    currentSort: string[] | undefined
  ): AxiosPromise<PageShortTermPassageDto> => {
    return api.shortTermPassageFindAll(page, pageSize, currentSort)
  },
  shortTermPassageFindById: (id: number): AxiosPromise<ShortTermPassageDto> => {
    return api.shortTermPassageFindById(id)
  },
  shortTermPassageCreate: (shortTermPassageDto: ShortTermPassageDto): AxiosPromise<ShortTermPassageDto> => {
    return api.shortTermPassageCreate(shortTermPassageDto)
  },
  shortTermPassageUpdate: (id: number, shortTermPassageDto: ShortTermPassageDto): AxiosPromise<ShortTermPassageDto> => {
    return api.shortTermPassageUpdate(id, shortTermPassageDto)
  },
  shortTermPassageDelete: (id: number): AxiosPromise<void> => {
    return api.shortTermPassageDelete(id)
  },
  shortTermPassageSearch: (
    page: number | undefined,
    pageSize: number | undefined,
    currentSort: string[] | undefined,
    shortTermPassageDto: ShortTermPassageSearchDto
  ): AxiosPromise<PageShortTermPassageDto> => {
    return api.shortTermPassageSearch(page, pageSize, currentSort, shortTermPassageDto)
  },
}

export default shortTermPassageService
