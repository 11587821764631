import { Autocomplete } from '@material-ui/lab'
import React, { FC, useEffect, useRef, useState } from 'react'

import { ConardTextField, ConardTextFieldProps } from './ConardTextField'
import { makeStyles, createStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { AutocompleteItem } from '../../model/autocomplete'
import { isAutocompleteItem } from '../../utils/forms'

const useStyles = makeStyles((theme) =>
  createStyles({
    iluAutocompleteInput: {
      borderRadius: 12,
      borderColor: theme.palette.secondary.light,
      backgroundColor: theme.palette.secondary.main,
      display: 'table',
      paddingRight: 60,
      height: 50,
      maxHeight: 200,
    },
    autocompleteOption: {
      width: '100%',
    },
    autocomplete: {
      width: '100%',
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(34px, -6px) scale(0.75)',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        '& legend': {
          marginLeft: '20px',
        },
      },
      '& .MuiAutocomplete-endAdornment': {
        right: '7px',
      },
    },
  })
)

interface ConardAutocompleteProps {
  onChange: (data: AutocompleteItem | string | null) => void
  onTextInputChange?: (value: string) => void
  label: string
  options: AutocompleteItem[]
  disabled?: boolean
  value: AutocompleteItem | string
  error?: boolean
  helperText?: string
}

export type ConardAutocompletePropsUnited = ConardAutocompleteProps & Pick<ConardTextFieldProps, 'placeholder'>

export const ConardAutocomplete: FC<ConardAutocompletePropsUnited> = ({
  label,
  options,
  onChange,
  placeholder,
  onTextInputChange,
  disabled,
  value,
  error,
  helperText,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const firstDataSet = useRef<boolean>(false)

  const [internalValue, setInternalValue] = useState<string>(isAutocompleteItem(value) ? value.name : value ?? '')
  const [comboValue, setComboValue] = useState<AutocompleteItem | string | null>(value ?? null)

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const itemInList = options.find((x) => x.name.toLowerCase() === event.target.value?.toLowerCase())

    itemInList && setComboValue(itemInList)

    if (isAutocompleteItem(comboValue) && comboValue.id === itemInList?.id && comboValue.name === itemInList.name) {
      return
    }

    onChange(itemInList ?? event.target.value)
  }

  useEffect(() => {
    if (isAutocompleteItem(value)) {
      setComboValue(value)
    } else {
      setInternalValue(value)
    }
  }, [value])

  return (
    <Autocomplete
      freeSolo
      value={comboValue ?? undefined}
      inputValue={internalValue}
      options={options}
      getOptionLabel={(option: AutocompleteItem) => {
        return option.name ?? ''
      }}
      noOptionsText={t('any.noOptions')}
      clearOnBlur={false}
      disabled={disabled}
      disableClearable
      renderOption={(option: AutocompleteItem) => <div className={classes.autocompleteOption}>{option.name ?? ''}</div>}
      onChange={(_, data) => {
        setComboValue(data)
        onChange(data)
      }}
      onInputChange={(_, data, reason) => {
        setInternalValue(data)

        // hack: first RESET is ignored (aka setting the data for this autocomplete)
        if (reason === 'reset' && !firstDataSet.current) {
          firstDataSet.current = true
          return
        }

        onTextInputChange?.(data)
      }}
      onBlur={handleBlur}
      renderInput={(params) => (
        <ConardTextField
          {...params}
          placeholder={placeholder}
          variant="outlined"
          error={error}
          helperText={helperText}
          label={label}
          className={classes.autocomplete}
          InputProps={{
            ...params.InputProps,
            className: classes.iluAutocompleteInput,
          }}
        />
      )}
    />
  )
}
