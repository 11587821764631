import { AxiosPromise } from 'axios'
import {
  CoparnTruckDto,
  GateOutApi,
  JoinCoparnTruckContainerDto,
  PageTransitionSearchListDto,
  TransitionDto,
  TransitionSearchListSearchDto,
} from '../api'
import myAxios from '../config/axios/axios'
import { cleanObject } from '../utils/utils'

let api: GateOutApi

export const setGateOutApiConfiguration = () => {
  api = new GateOutApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const gateOutService = {
  findWithReceiptById: (id: number) => {
    return api.gateOutFindTransitionForRoadGateOut(id)
  },
  create: (transitionDto: TransitionDto): AxiosPromise<TransitionDto> => {
    return api.gateOutCreateSimple(cleanObject(transitionDto))
  },

  simpleTransitionList: (
    page?: number,
    size?: number,
    sort?: string[],
    transitionSearchDto?: TransitionSearchListSearchDto
  ): AxiosPromise<PageTransitionSearchListDto> => {
    return api.gateOutSimpleTransitionList(page, size, sort, transitionSearchDto)
  },

  finishGateOut: (transitionId: number | undefined): AxiosPromise<TransitionDto> => {
    return api.gateOutFinishGateOut(transitionId || -1)
  },

  releaseContainer: (transitionId: number | undefined): AxiosPromise<TransitionDto> => {
    return api.gateOutReleaseContainer(transitionId || -1)
  },

  update: (transitionId: number): AxiosPromise => {
    return api.gateOutUpdate(transitionId)
  },

  gateOutCreateGoFromSelectedContainer: (
    joinCoparnTruckContainerDto: JoinCoparnTruckContainerDto
  ): AxiosPromise<void> => {
    return api.gateOutCreateGoFromSelectedContainer(joinCoparnTruckContainerDto)
  },

  getDriverGateOutType: (driverId: number): AxiosPromise<string> => {
    return api.gateOutGetDriverGateOutType(driverId)
  },

  getDriverCoparnGateOut: (driverId: number): AxiosPromise<CoparnTruckDto> => {
    return api.gateOutGetDriverGateOutCoparnTruck(driverId)
  },

  getDriverTransitionGateOut: (driverId: number): AxiosPromise<TransitionDto> => {
    return api.gateOutGetDriverGateOutTransition(driverId)
  },

  getGateOutNightTransitionList: (
    page?: number,
    size?: number,
    sort?: string[],
    transitionSearchDto?: TransitionSearchListSearchDto
  ): AxiosPromise<PageTransitionSearchListDto> => {
    return api.gateOutNightTransitionList(page, size, sort, transitionSearchDto)
  },
}

export default gateOutService
