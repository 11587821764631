import React, { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core'
import ConardButton from '../ConardButton'
import { TrainDto } from '../../api'
import { format } from 'date-fns'

const useStyles = makeStyles(() =>
  createStyles({
    dialogContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
)

interface TrainApproveGateOutDialogProps {
  showDialog: boolean
  setShowDialog: (showDialog: boolean) => void
  onSubmit: (data: TrainDto) => void
  train?: TrainDto
}

const TrainApproveGateOutDialog: FC<TrainApproveGateOutDialogProps> = ({
  showDialog,
  setShowDialog,
  onSubmit,
  train,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Dialog open={showDialog} maxWidth="sm">
      <DialogTitle>{t('pages.gateOut.dialog.trainGateOutApprove.title')}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography>
          <Trans
            i18nKey="pages.gateOut.dialog.trainGateOutApprove.content"
            values={{
              date: format(new Date(train?.departureDateTime ?? new Date()), 'dd.MM.yyyy HH:mm:ss'),
            }}
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <ConardButton
          conardVariant="dark"
          onClick={() => {
            setShowDialog(false)
            onSubmit(train!)
          }}
        >
          {t('any.buttons.ok')}
        </ConardButton>
        <ConardButton conardVariant="transparent" onClick={() => setShowDialog(false)}>
          {t('any.buttons.cancel')}
        </ConardButton>
      </DialogActions>
    </Dialog>
  )
}

export default TrainApproveGateOutDialog
