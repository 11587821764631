import { PageTrainExportConfigurationDto, TrainExportConfigurationApi, TrainExportConfigurationDto } from '../api'
import { AxiosPromise } from 'axios'
import myAxios from '../config/axios/axios'
import { cleanObject } from '../utils/utils'

let api: TrainExportConfigurationApi

export const setTrainExportConfigurationApiConfiguration = () => {
  api = new TrainExportConfigurationApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const trainExportConfigurationService = {
  findAll: (
    page: number | undefined,
    pageSize: number | undefined,
    orderBy: string,
    order: string
  ): AxiosPromise<PageTrainExportConfigurationDto> => {
    return api.trainExportConfigurationFindAll(page, pageSize, [orderBy + ',' + order])
  },

  findById: (id: number): AxiosPromise<TrainExportConfigurationDto> => {
    return api.trainExportConfigurationFindById(id)
  },

  create: (trainExportConfigurationDto: TrainExportConfigurationDto): AxiosPromise<TrainExportConfigurationDto> => {
    return api.trainExportConfigurationCreate(cleanObject(trainExportConfigurationDto))
  },

  update: (
    id: number | undefined,
    trainExportConfigurationDto: TrainExportConfigurationDto
  ): AxiosPromise<TrainExportConfigurationDto> => {
    return api.trainExportConfigurationUpdate(id || -1, cleanObject(trainExportConfigurationDto))
  },

  delete: (confId: number | undefined): AxiosPromise<void> => {
    return api.trainExportConfigurationDelete(confId || -1)
  },

  duplicate: (id: number): AxiosPromise<TrainExportConfigurationDto> => {
    return api.trainExportConfigurationDuplicate(id)
  },
}

export default trainExportConfigurationService
