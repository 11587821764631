import React, { FC, useContext } from 'react'
import { ConfigContext } from '../context/ConfigContext'
import { OperatorTransitionDto, TransitionDto, TransitionSearchListDto } from '../api'

interface ConardCoordinatesProps {
  transition: TransitionSearchListDto | TransitionDto | OperatorTransitionDto | undefined
}

export const ConardCoordinates: FC<ConardCoordinatesProps> = (props) => {
  const { transition } = props
  const configContext = useContext(ConfigContext)

  return (
    <>
      {(configContext.coordinates.sectorLetterCount === 0 ? '' : (transition?.sectorLetter || '-') + ' | ') +
        (configContext.coordinates.maxSectorColumn === 0 ? '' : (transition?.sectorColumn || '-') + ' | ') +
        (configContext.coordinates.maxSectorRow === 0 ? '' : (transition?.sectorRow || '-') + ' | ') +
        (configContext.coordinates.maxSectorFloor === 0 ? '' : transition?.sectorFloor || '-')}
    </>
  )
}
