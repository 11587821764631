import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

export const DriverValidationScheme = () => {
  const { t } = useTranslation()

  const driverValidation = yup.object().shape({
    driver: yup.mixed().test('is-driver', t('kioskApp.step0.validations.driver'), function (value) {
      if (!value) {
        return this.createError({ message: t('kioskApp.step0.validations.driver') })
      }
      return true
    }),
    carrier: yup.mixed().test('is-carrier', t('kioskApp.step0.validations.carrier'), function (value) {
      if (!value) {
        return this.createError({ message: t('kioskApp.step0.validations.carrier') })
      }
      return true
    }),
    frontLicensePlate: yup
      .mixed()
      .test('is-frontLicensePlate', t('kioskApp.step0.validations.frontLicensePlate'), function (value) {
        if (!value) {
          return this.createError({ message: t('kioskApp.step0.validations.frontLicensePlate') })
        }
        return true
      }),
  })

  return driverValidation
}
