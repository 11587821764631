import { PageUserDto, UserApi, UserDto, UserSearchDto } from '../api'
import { AxiosPromise } from 'axios'
import myAxios from '../config/axios/axios'

let api: UserApi

export const setUserApiConfiguration = () => {
  api = new UserApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const userService = {
  findAll: (page: number, pageSize: number, currentSort: string[] | undefined): AxiosPromise<PageUserDto> => {
    return api.userFindAll(page, pageSize, currentSort)
  },
  userCreate: (userDto: UserDto): AxiosPromise<UserDto> => {
    return api.userCreate(userDto)
  },
  userUpdate: (id: number, userDto: UserDto): AxiosPromise<UserDto> => {
    return api.userUpdate(id, userDto)
  },
  userDelete: (id: number): AxiosPromise<void> => {
    return api.userDelete(id)
  },
  userSearch: (
    page: number | undefined,
    pageSize: number | undefined,
    currentSort: string[] | undefined,
    userSearchDto: UserSearchDto
  ): AxiosPromise<PageUserDto> => {
    return api.userSearch(page, pageSize, currentSort, userSearchDto)
  },
  downloadExport: (userSearchDto: UserSearchDto | null): AxiosPromise => {
    return api.userDownloadExport(userSearchDto || {}, { responseType: 'blob' })
  },
  userFindById: (id: number): AxiosPromise<UserDto> => {
    return api.userFindById(id)
  },
  userUpdateNotificationsDisabled: (id: number, notificationsDisabled: boolean): AxiosPromise<void> => {
    return api.userUpdateNotificationsDisabled(id, notificationsDisabled)
  },
  changeLanguage: (userId: number, language: string): AxiosPromise => {
    return api.userChangeLanguage(userId, language)
  },
}

export default userService
