import React, { FC, useContext, useEffect, useState } from 'react'
import {
  Checkbox,
  createStyles,
  Grid,
  Hidden,
  InputAdornment,
  ListItemText,
  makeStyles,
  MenuItem,
} from '@material-ui/core'
import ConardSelect from '../../components/ConardSelect'
import {
  CompanyDto,
  ContainerDtoSemitrailerTypeEnum,
  ContainerSearchDtoContainerTypeEnum,
  ContainerSearchDtoSemitrailerTypeEnum,
  ContainerSearchDtoShippingContainerTypeEnum,
  ShipownerDto,
  TransitionDtoQualityTypeEnum,
  TransitionDtoReceiptMethodEnum,
  TransitionSearchDto,
  TransitionSearchDtoCustomsClearanceEnum,
  TransitionSearchListSearchDto,
  TransitionSearchListSearchDtoContainerStatesEnum,
  TransitionSearchListSearchDtoCustomsClearanceEnum,
  TransitionSearchListSearchDtoQualityTypeEnum,
  TransitionSearchListSearchDtoReceiptMethodEnum,
  UserDtoRoleEnum,
} from '../../api'
import ConardTextField from '../../components/ConardTextField'
import ConardButton from '../../components/ConardButton'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import companyService from '../../services/CompanyService'
import ConardSwitch from '../../components/ConardSwitch'
import shipownerService from '../../services/ShipownerService'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../redux/store'
import { setSearchTransition } from '../../redux/transition/TransitionSearchSlice'
import { TransitionMode } from '../../enums/TransitionMode'
import { FieldName } from 'react-hook-form/dist/types/fields'
import ConardMultiSelect from '../../components/ConardMultiSelect'
import { ConfigContext } from '../../context/ConfigContext'
import { cleanShippingContainerType } from '../../utils/utils'
import { useConardAuth } from '../../hooks/useConardAuth'
import carrierService from '../../services/CarrierService'
import { cs, enGB, pl } from 'date-fns/locale'
import DateFnsUtils from '@date-io/date-fns'

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      marginTop: '2vh',
      width: '100%',
      marginLeft: '15px',
      marginRight: '15px',
    },
    searchButton: {
      width: '13vw',
      minWidth: '300px',
      height: '5vh',
      minHeight: '50px',
      textAlign: 'center',
      float: 'right',
      marginBottom: '20px',
      marginLeft: '10px',
    },
    datePickerInputInput: {
      width: '100%',
      textAlign: 'center',
    },
    datePickerInputRoot: {
      width: '60%',
      height: '56px',
      margin: 'auto',
    },
    multiSelectCheckbox: {
      color: theme.palette.primary.main,
    },
    datePickerRoot: {
      width: '100%',
      background: theme.palette.secondary.main,
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderRadius: '30px',
      '&:hover': {
        borderColor: 'black',
      },
    },
    colorDiv: {
      width: '10px',
      height: '10px',
      borderRadius: '100%',
      marginLeft: '10px',
    },
    companySelectItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  })
)

interface SearchFilterProps {
  searchFilter: (data: TransitionSearchListSearchDto | undefined) => void
  blockSearch: boolean
  transitionMode?: TransitionMode
}

interface Sector {
  sectorName: string
  sectorTranslation: string
  menuValues: number[] | string[]
}

const TransitionSearchFilter: FC<SearchFilterProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const configContext = useContext(ConfigContext)
  const auth = useConardAuth()

  const { searchFilter, blockSearch, transitionMode } = props

  const transitions = useSelector<RootState, TransitionSearchListSearchDto | undefined>((state) =>
    transitionMode === TransitionMode.TRAIN
      ? state.transitionSearchListSearch.transitionSearchTrainContent
      : state.transitionSearchListSearch.transitionSearch
  )

  const { handleSubmit, control, setValue } = useForm<TransitionSearchDto>({})

  const [carrierSearchDto, setCarrierSearchDto] = useState<TransitionSearchListSearchDto | undefined>(undefined)
  const [containerStates, setContainerStates] = useState<Array<TransitionSearchListSearchDtoContainerStatesEnum>>(
    transitions?.containerStates ?? []
  )

  const showedContainerStates = configContext.entryAndParking
    ? Object.values(TransitionSearchListSearchDtoContainerStatesEnum)
    : Object.values(TransitionSearchListSearchDtoContainerStatesEnum).filter(
        (enumType) =>
          enumType !== TransitionSearchListSearchDtoContainerStatesEnum.Before2NdCheckpointGo &&
          enumType !== TransitionSearchListSearchDtoContainerStatesEnum.Before3RdCheckpointGo
      )

  const [companyId, setCompanyId] = useState<number | undefined>(transitions?.container?.company?.id ?? undefined)
  const [shipownerId, setShipownerId] = useState<number | undefined>(transitions?.container?.shipowner?.id ?? undefined)
  const [transitionDateFrom, setTransitionDateFrom] = useState<MaterialUiPickersDate | null>(
    transitions?.transitionDateTimeFrom ? new Date(transitions?.transitionDateTimeFrom) : null
  )
  const [transitionDateTo, setTransitionDateTo] = useState<MaterialUiPickersDate | null>(
    transitions?.transitionDateTimeTo ? new Date(transitions?.transitionDateTimeTo) : null
  )
  const [onSite, setOnSite] = useState<boolean>(transitions?.onSite ?? false)
  const [repleted, setRepleted] = useState<boolean | undefined>(transitions?.repleted ?? undefined)
  const [receiptMethod, setReceiptMethod] = useState<TransitionSearchListSearchDtoReceiptMethodEnum | undefined>(
    transitions?.receiptMethod ?? undefined
  )
  const [containerType, setContainerType] = useState<ContainerSearchDtoContainerTypeEnum | undefined>(
    transitions?.container?.containerType ?? undefined
  )
  const [shippingContainerType, setShippingContainerType] = useState<
    ContainerSearchDtoShippingContainerTypeEnum | undefined
  >(transitions?.container?.shippingContainerType ?? undefined)
  const [semitrailerType, setSemitrailerType] = useState<ContainerSearchDtoSemitrailerTypeEnum | undefined>(
    transitions?.container?.semitrailerType ?? undefined
  )
  const [quality, setQuality] = useState<TransitionSearchListSearchDtoQualityTypeEnum | undefined>(
    transitions?.qualityType ?? undefined
  )
  const [damaged, setDamaged] = useState<boolean | undefined>(transitions?.damaged ?? undefined)
  const [adrRid, setAdrRid] = useState<boolean | undefined>(transitions?.hasAdrRid ?? undefined)
  const [sectorLetter, setSectorLetter] = useState<string | undefined>(transitions?.sectorLetter ?? undefined)
  const [sectorColumn, setSectorColumn] = useState<number | undefined>(transitions?.sectorColumn ?? undefined)
  const [sectorRow, setSectorRow] = useState<number | undefined>(transitions?.sectorRow ?? undefined)
  const [sectorFloor, setSectorFloor] = useState<number | undefined>(transitions?.sectorFloor ?? undefined)
  const [customsClearance, setCustomsClearance] = useState<TransitionSearchListSearchDtoCustomsClearanceEnum>(
    transitions?.customsClearance ?? TransitionSearchListSearchDtoCustomsClearanceEnum.All
  )

  const [companies, setCompanies] = useState<CompanyDto[] | undefined>(undefined)
  const [shipowners, setShipowners] = useState<ShipownerDto[] | undefined>(undefined)

  const sectors: Sector[] = [
    {
      sectorName: 'sectorLetter',
      sectorTranslation: 'pages.gateIn.form.sector.label',
      menuValues: Array.from(Array(configContext.coordinates.sectorLetterCount))
        .map((e, i) => i + 65)
        .map((x) => String.fromCharCode(x)),
    },
    {
      sectorName: 'sectorColumn',
      sectorTranslation:
        configContext.coordinates.sectorLetterCount === 0
          ? 'pages.gateIn.form.sector.label'
          : 'pages.gateIn.form.sectorColumn.label',
      menuValues: Array.from({ length: configContext.coordinates.maxSectorColumn }, (_, i) => i + 1),
    },
    {
      sectorName: 'sectorRow',
      sectorTranslation: 'pages.gateIn.form.sectorRow.label',
      menuValues: Array.from({ length: configContext.coordinates.maxSectorRow }, (_, i) => i + 1),
    },
    {
      sectorName: 'sectorFloor',
      sectorTranslation: 'pages.gateIn.form.sectorFloor.label',
      menuValues: Array.from({ length: configContext.coordinates.maxSectorFloor }, (_, i) => i + 1),
    },
  ]

  const getAllCompanies = () => {
    companyService.findAll(0, 100, '', '').then((response) => {
      setCompanies(response.data.content)
    })
  }

  const getAllShipowners = () => {
    shipownerService.findAll(undefined, undefined, undefined).then((response) => {
      setShipowners(response.data.content)
    })
  }

  const changeSwitch = (newValue: boolean) => {
    setOnSite(newValue)
  }

  const onChangeContainerStates = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    if (
      (event.target.value as Array<TransitionSearchListSearchDtoContainerStatesEnum | undefined>).includes(undefined)
    ) {
      const newStates = containerStates.length === showedContainerStates.length ? [] : showedContainerStates
      setContainerStates(newStates)
      setValue('containerStates', newStates)
    } else {
      setContainerStates(event.target.value as Array<TransitionSearchListSearchDtoContainerStatesEnum>)
    }
  }

  const renderMultiselect = (selected: Array<string>) => {
    const states = selected.filter((value) => !!value)

    if (states.length === 0 || states.length === showedContainerStates.length) {
      return t('pages.common.select.all')
    }

    return states.map((value) => t(`enum.containerState.${value}`)).join(', ')
  }

  const onChangeCompany = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCompanyId(event.target.value as unknown as number)
  }

  const onChangeCustomsClearance = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCustomsClearance(event.target.value as unknown as TransitionSearchListSearchDtoCustomsClearanceEnum)
  }

  const onChangeShipowner = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setShipownerId(event.target.value as unknown as number)
  }

  const dateFromOnChange = (date: MaterialUiPickersDate | null) => {
    setTransitionDateFrom(date || new Date())
    setValue('transitionDateTimeFrom', date)
  }
  const dateToOnChange = (date: MaterialUiPickersDate | null) => {
    setTransitionDateTo(date || new Date())
    setValue('transitionDateTimeTo', date)
  }

  const onChangeRepleted = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRepleted((event.target.value as unknown as number) === -1 ? undefined : event.target.value === 'true')
  }

  const onChangeReceiptMethod = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setReceiptMethod(event.target.value as TransitionSearchListSearchDtoReceiptMethodEnum)
  }

  const onChangeContainerType = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setContainerType(event.target.value as ContainerSearchDtoContainerTypeEnum)
    setShippingContainerType(undefined)
    setSemitrailerType(undefined)
  }

  const onChangeShippingContainerType = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setShippingContainerType(event.target.value as ContainerSearchDtoShippingContainerTypeEnum)
  }

  const onChangeSemitrailerType = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSemitrailerType(event.target.value as ContainerSearchDtoSemitrailerTypeEnum)
  }

  const onChangeQuality = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setQuality(event.target.value as TransitionSearchListSearchDtoQualityTypeEnum)
  }

  const onChangeDamaged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setDamaged(+event.target.value === -1 ? undefined : event.target.value === 'true')
  }
  const onChangeAdrRid = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setAdrRid(+event.target.value === -1 ? undefined : event.target.value === 'true')
  }

  const onChangeSector = (
    field: FieldName<TransitionSearchDto>,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    switch (field) {
      case 'sectorLetter':
        setSectorLetter(event.target.value)
        break
      case 'sectorColumn':
        setSectorColumn(event.target.value as unknown as number)
        break
      case 'sectorRow':
        setSectorRow(event.target.value as unknown as number)
        break
      case 'sectorFloor':
        setSectorFloor(event.target.value as unknown as number)
        break
    }
  }

  const cleanFilter = () => {
    control.setValue('container.iluCode', '')
    setContainerStates([])
    control.setValue('containerStates', [])
    setCompanyId(undefined)
    control.setValue('container.company.id', -1)
    setShipownerId(undefined)
    control.setValue('container.shipowner.id', -1)
    control.setValue('container.semitrailerLicencePlate', '')
    setTransitionDateFrom(null)
    control.setValue('transitionDateTimeFrom', null)
    setTransitionDateTo(null)
    control.setValue('transitionDateTimeTo', null)
    setOnSite(false)
    control.setValue('onSite', false)
    setRepleted(undefined)
    control.setValue('repleted', -1)
    setReceiptMethod(undefined)
    control.setValue('receiptMethod', -1)
    setContainerType(undefined)
    control.setValue('container.containerType', -1)
    setShippingContainerType(undefined)
    control.setValue('container.shippingContainerType', -1)
    setSemitrailerType(undefined)
    control.setValue('container.semitrailerType', -1)
    setQuality(undefined)
    control.setValue('qualityType', -1)
    setDamaged(undefined)
    control.setValue('damaged', -1)
    setSectorLetter(undefined)
    control.setValue('sectorLetter', -1)
    setSectorColumn(undefined)
    control.setValue('sectorColumn', -1)
    setSectorRow(undefined)
    control.setValue('sectorRow', -1)
    setSectorFloor(undefined)
    control.setValue('sectorFloor', -1)
    setCustomsClearance(TransitionSearchListSearchDtoCustomsClearanceEnum.All)
    control.setValue('customsClearance', TransitionSearchDtoCustomsClearanceEnum.All)
    control.setValue('trainName', '')
    control.setValue('mrn', '')
    setAdrRid(undefined)
    control.setValue('hasAdrRid', -1)
    control.setValue('truckLicencePlate', '')

    if (auth.getRole() === UserDtoRoleEnum.Carrier) {
      setDefaultValues()
    }

    dispatch(setSearchTransition(undefined))
    searchFilter(undefined)
  }

  useEffect(() => {
    getAllCompanies()
    getAllShipowners()
    if (auth.getRole() === UserDtoRoleEnum.Carrier) {
      carrierService.getSearchDtoOfCurrentCarrier().then((response) => {
        setCarrierSearchDto(response.data)
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const setDefaultValues = () => {
    setContainerStates(
      !!carrierSearchDto?.containerStates && carrierSearchDto.containerStates.length > 0
        ? carrierSearchDto?.containerStates
        : containerStates
    )
    setCompanyId(carrierSearchDto?.container?.company?.id ?? companyId)
    setShipownerId(carrierSearchDto?.container?.shipowner?.id ?? shipownerId)
    setRepleted(carrierSearchDto?.repleted ?? repleted)
    setReceiptMethod(carrierSearchDto?.receiptMethod ?? receiptMethod)
    setContainerType(carrierSearchDto?.container?.containerType ?? containerType)
    setShippingContainerType(carrierSearchDto?.container?.shippingContainerType ?? shippingContainerType)
    setSemitrailerType(carrierSearchDto?.container?.semitrailerType ?? semitrailerType)
    setQuality(carrierSearchDto?.qualityType ?? quality)
    setDamaged(carrierSearchDto?.damaged ?? damaged)
    setAdrRid(carrierSearchDto?.hasAdrRid ?? adrRid)
    setCustomsClearance(carrierSearchDto?.customsClearance ?? customsClearance)
  }

  useEffect(() => {
    if (auth.getRole() === UserDtoRoleEnum.Carrier && !!carrierSearchDto) {
      setDefaultValues()
    }
  }, [carrierSearchDto]) // eslint-disable-line react-hooks/exhaustive-deps

  const getLanguage = () => {
    switch (auth.getLanguage()) {
      case 'pl':
        return pl
      case 'cs':
        return cs
      default:
        return enGB
    }
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit(searchFilter)}>
      <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={2}>
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <ConardTextField
            id="container.iluCode"
            name="container.iluCode"
            label={t('pages.gateIn.form.iluCode.label')}
            defaultValue={transitions?.container?.iluCode ?? ''}
            control={control}
            disabled={!!carrierSearchDto?.container?.iluCode}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
          <ConardMultiSelect
            label={t('pages.gateIn.form.state.label')}
            name="containerStates"
            value={containerStates}
            defaultValue={containerStates}
            onChange={onChangeContainerStates}
            control={control}
            renderValue={(selected) => renderMultiselect(selected as Array<string>)}
            disabled={!!carrierSearchDto?.containerStates && carrierSearchDto.containerStates.length > 0}
          >
            <MenuItem value={undefined}>
              <Checkbox
                color="primary"
                checked={
                  showedContainerStates.length > 0 &&
                  containerStates.filter((state) => !!state).length === showedContainerStates.length
                }
                indeterminate={
                  containerStates.length > 0 &&
                  containerStates.filter((state) => !!state).length < showedContainerStates.length
                }
              />
              <ListItemText primary={t('pages.common.select.all')} />
            </MenuItem>
            {showedContainerStates.map((value) => (
              <MenuItem key={value} value={value}>
                <Checkbox color="primary" checked={containerStates.includes(value)} />
                <ListItemText primary={t(`enum.containerState.${value}`)} />
              </MenuItem>
            ))}
          </ConardMultiSelect>
        </Grid>
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <ConardSelect
            id="container.company.id"
            name="container.company.id"
            label={t('entity.company.fields.name')}
            defaultValue={companyId ?? -1}
            value={companyId ?? -1}
            onChange={(event) => onChangeCompany(event)}
            control={control}
            disabled={!!carrierSearchDto?.container?.company?.id}
          >
            <MenuItem value={-1}>{t('pages.common.select.all')}</MenuItem>
            {companies?.map((company) => (
              <MenuItem key={company.id} value={company.id}>
                <div className={classes.companySelectItem}>
                  {company.name}
                  {auth.getRole() !== UserDtoRoleEnum.Carrier && (
                    <div style={{ backgroundColor: company.color }} className={classes.colorDiv} />
                  )}
                </div>
              </MenuItem>
            ))}
          </ConardSelect>
        </Grid>
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <ConardSelect
            id="container.shipowner.id"
            name="container.shipowner.id"
            label={t('pages.gateIn.form.shipowner.label')}
            defaultValue={shipownerId ?? -1}
            value={shipownerId ?? -1}
            onChange={(event) => onChangeShipowner(event)}
            control={control}
            disabled={!!carrierSearchDto?.container?.shipowner?.id}
          >
            <MenuItem value={-1}>{t('pages.common.select.all')}</MenuItem>
            {shipowners?.map((shipowner) => (
              <MenuItem key={shipowner.id} value={shipowner.id}>
                {shipowner.name}
              </MenuItem>
            ))}
          </ConardSelect>
        </Grid>
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <ConardTextField
            id="container.semitrailerLicencePlate"
            name="container.semitrailerLicencePlate"
            label={t('pages.gateIn.form.semitrailerLicencePlate.label')}
            defaultValue={transitions?.container?.semitrailerLicencePlate ?? ''}
            control={control}
            disabled={!!carrierSearchDto?.container?.semitrailerLicencePlate}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <ConardSwitch
            id="onSite"
            name="onSite"
            checked={onSite}
            defaultChecked={onSite}
            onChange={(newValue) => changeSwitch(newValue)}
            control={control}
            checkedLabel={t('pages.searchTransition.onSite.labels.onSite')}
            uncheckedLabel={t('pages.searchTransition.onSite.labels.all')}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <MuiPickersUtilsProvider locale={getLanguage()} utils={DateFnsUtils}>
            <Controller
              name="transitionDateTimeFrom"
              control={control}
              defaultValue={transitionDateFrom}
              render={() => (
                <DateTimePicker
                  emptyLabel={t('pages.searchTransition.datePicker.labels.transitionDateFrom')}
                  format="dd.MM.yyyy HH:mm"
                  ampm={false}
                  value={transitionDateFrom}
                  onChange={(value) => {
                    dateFromOnChange(value)
                  }}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <Hidden lgDown>
                        <InputAdornment position="start">
                          <CalendarTodayIcon />
                        </InputAdornment>
                      </Hidden>
                    ),
                    classes: {
                      root: classes.datePickerInputRoot,
                      input: classes.datePickerInputInput,
                    },
                  }}
                  className={classes.datePickerRoot}
                  disabled={!!carrierSearchDto?.transitionDateTimeFrom}
                />
              )}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <MuiPickersUtilsProvider locale={getLanguage()} utils={DateFnsUtils}>
            <Controller
              name="transitionDateTimeTo"
              control={control}
              defaultValue={transitionDateTo}
              render={() => (
                <DateTimePicker
                  emptyLabel={t('pages.searchTransition.datePicker.labels.transitionDateTo')}
                  format="dd.MM.yyyy HH:mm"
                  ampm={false}
                  value={transitionDateTo}
                  onChange={(value) => {
                    dateToOnChange(value)
                  }}
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <Hidden lgDown>
                        <InputAdornment position="start">
                          <CalendarTodayIcon />
                        </InputAdornment>
                      </Hidden>
                    ),
                    classes: {
                      root: classes.datePickerInputRoot,
                      input: classes.datePickerInputInput,
                    },
                  }}
                  className={classes.datePickerRoot}
                  disabled={!!carrierSearchDto?.transitionDateTimeTo}
                />
              )}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <ConardSelect
            id="repleted"
            name="repleted"
            label={`${t('pages.gateIn.form.repleted.checked')} / ${t('pages.gateIn.form.repleted.unchecked')}`}
            defaultValue={repleted ?? -1}
            value={repleted ?? -1}
            onChange={(event) => onChangeRepleted(event)}
            control={control}
            disabled={!!carrierSearchDto?.repleted}
          >
            <MenuItem value={-1}>{t('pages.common.select.all')}</MenuItem>
            <MenuItem value="true">{t('pages.gateIn.form.repleted.checked')}</MenuItem>
            <MenuItem value="false">{t('pages.gateIn.form.repleted.unchecked')}</MenuItem>
          </ConardSelect>
        </Grid>
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <ConardSelect
            id="receiptMethod"
            name="receiptMethod"
            label={`${t('pages.gateIn.form.receiptMethod.checked')} / ${t(
              'pages.gateIn.form.receiptMethod.unchecked'
            )}`}
            defaultValue={receiptMethod ?? -1}
            value={receiptMethod ?? -1}
            onChange={(event) => onChangeReceiptMethod(event)}
            control={control}
            disabled={!!carrierSearchDto?.receiptMethod}
          >
            <MenuItem value={-1}>{t('pages.common.select.all')}</MenuItem>
            <MenuItem value={TransitionDtoReceiptMethodEnum.Train}>
              {t('pages.gateIn.form.receiptMethod.checked')}
            </MenuItem>
            <MenuItem value={TransitionDtoReceiptMethodEnum.Road}>
              {t('pages.gateIn.form.receiptMethod.unchecked')}
            </MenuItem>
          </ConardSelect>
        </Grid>
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <ConardSelect
            id="container.containerType"
            name="container.containerType"
            label={t('pages.searchTransition.containerType.label')}
            value={containerType ?? -1}
            defaultValue={containerType ?? -1}
            onChange={(event) => onChangeContainerType(event)}
            control={control}
            disabled={!!carrierSearchDto?.container?.containerType}
          >
            <MenuItem value={-1}>{t('pages.common.select.all')}</MenuItem>
            <MenuItem value={ContainerSearchDtoContainerTypeEnum.ShippingContainer}>
              {t('enum.containerType.SHIPPING_CONTAINER')}
            </MenuItem>
            <MenuItem value={ContainerSearchDtoContainerTypeEnum.Semitrailer}>
              {t('enum.containerType.SEMITRAILER')}
            </MenuItem>
          </ConardSelect>
        </Grid>

        {containerType === ContainerSearchDtoContainerTypeEnum.ShippingContainer && (
          <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
            <ConardSelect
              id="container.shippingContainerType"
              name="container.shippingContainerType"
              label={t('pages.gateIn.form.shippingContainerType.label')}
              value={shippingContainerType ?? -1}
              defaultValue={shippingContainerType ?? -1}
              onChange={(event) => onChangeShippingContainerType(event)}
              control={control}
              disabled={!!carrierSearchDto?.container?.shippingContainerType}
            >
              <MenuItem value={-1}>{t('pages.common.select.all')}</MenuItem>
              {Object.values(ContainerSearchDtoShippingContainerTypeEnum).map((value) => (
                <MenuItem key={value} value={value}>
                  {cleanShippingContainerType(value)}
                </MenuItem>
              ))}
            </ConardSelect>
          </Grid>
        )}
        {containerType === ContainerSearchDtoContainerTypeEnum.Semitrailer && (
          <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
            <ConardSelect
              id="container.semitrailerType"
              name="container.semitrailerType"
              label={t('pages.gateIn.form.semitrailerType.label')}
              value={semitrailerType ?? -1}
              defaultValue={semitrailerType ?? -1}
              onChange={(event) => onChangeSemitrailerType(event)}
              control={control}
              disabled={!!carrierSearchDto?.container?.semitrailerType}
            >
              <MenuItem value={-1}>{t('pages.common.select.all')}</MenuItem>
              {Object.values(ContainerDtoSemitrailerTypeEnum).map((value) => (
                <MenuItem key={value} value={value}>
                  {t(`${value}`)}
                </MenuItem>
              ))}
            </ConardSelect>
          </Grid>
        )}
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <ConardSelect
            id="qualityType"
            name="qualityType"
            label={t('pages.gateIn.form.qualityType.label')}
            defaultValue={quality ?? -1}
            value={quality ?? -1}
            onChange={(event) => onChangeQuality(event)}
            control={control}
            disabled={!!carrierSearchDto?.qualityType}
          >
            <MenuItem value={-1}>{t('pages.common.select.all')}</MenuItem>
            {Object.values(TransitionDtoQualityTypeEnum).map((value) => (
              <MenuItem key={value} value={value}>
                {t(`enum.qualityType.${value}`)}
              </MenuItem>
            ))}
          </ConardSelect>
        </Grid>
        {sectors
          .filter((sector) => configContext.coordinates.sectorLetterCount !== 0 || sector.sectorName !== 'sectorLetter')
          .map((sector) => {
            return (
              <Grid item key={sector.sectorName} xl={2} lg={2} md={6} sm={6} xs={12}>
                <ConardSelect
                  id={sector.sectorName}
                  name={sector.sectorName}
                  defaultValue={
                    sector.sectorName === 'sectorLetter'
                      ? sectorLetter ?? -1
                      : sector.sectorName === 'sectorColumn'
                      ? sectorColumn ?? -1
                      : sector.sectorName === 'sectorRow'
                      ? sectorRow ?? -1
                      : sectorFloor ?? -1
                  }
                  label={t(sector.sectorTranslation)}
                  value={
                    sector.sectorName === 'sectorLetter'
                      ? sectorLetter ?? -1
                      : sector.sectorName === 'sectorColumn'
                      ? sectorColumn ?? -1
                      : sector.sectorName === 'sectorRow'
                      ? sectorRow ?? -1
                      : sectorFloor ?? -1
                  }
                  onChange={(event) => onChangeSector(sector.sectorName, event)}
                  control={control}
                >
                  <MenuItem value={-1}>{t('pages.common.select.all')}</MenuItem>
                  {sector.menuValues.map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </ConardSelect>
              </Grid>
            )
          })}
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <ConardSelect
            id="damaged"
            name="damaged"
            label={t('pages.gateIn.form.damaged.label')}
            defaultValue={damaged ?? -1}
            value={damaged ?? -1}
            onChange={(event) => onChangeDamaged(event)}
            control={control}
            disabled={!!carrierSearchDto?.damaged}
          >
            <MenuItem value={-1}>{t('pages.common.select.all')}</MenuItem>
            <MenuItem value="true">{t('pages.gateIn.form.damaged.checked')}</MenuItem>
            <MenuItem value="false">{t('pages.gateIn.form.damaged.unchecked')}</MenuItem>
          </ConardSelect>
        </Grid>
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <ConardSelect
            id="customsClearance"
            name="customsClearance"
            label={t('enum.customsClearance.label')}
            defaultValue={customsClearance ?? 'ALL'}
            value={customsClearance ?? 'ALL'}
            onChange={(event) => onChangeCustomsClearance(event)}
            control={control}
            disabled={!!carrierSearchDto?.customsClearance && carrierSearchDto.customsClearance !== 'ALL'}
          >
            {Object.values(TransitionSearchDtoCustomsClearanceEnum).map((value) => (
              <MenuItem key={value} value={value}>
                {t(`enum.customsClearance.${value}`)}
              </MenuItem>
            ))}
          </ConardSelect>
        </Grid>
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <ConardTextField
            id="mrn"
            name="mrn"
            label={t('pages.gateIn.form.mrn.label')}
            defaultValue={transitions?.mrn ?? ''}
            control={control}
            disabled={!!carrierSearchDto?.mrn}
          />
        </Grid>
        {transitionMode === TransitionMode.SEARCH && (
          <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
            <ConardTextField
              id="trainName"
              name="trainName"
              label={t('pages.operator.table.trainName')}
              defaultValue={transitions?.trainName ?? ''}
              control={control}
              disabled={!!carrierSearchDto?.trainName}
            />
          </Grid>
        )}
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <ConardSelect
            id="hasAdrRid"
            name="hasAdrRid"
            label={t('pages.gateIn.form.adrRid.label')}
            defaultValue={adrRid ?? -1}
            value={adrRid ?? -1}
            onChange={(event) => onChangeAdrRid(event)}
            control={control}
            disabled={!!carrierSearchDto?.hasAdrRid}
          >
            <MenuItem value={-1}>{t('pages.common.select.all')}</MenuItem>
            <MenuItem value="true">{t('pages.searchTransition.adrRid.include')}</MenuItem>
            <MenuItem value="false">{t('pages.searchTransition.adrRid.exclude')}</MenuItem>
          </ConardSelect>
        </Grid>
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <ConardTextField
            id="truckLicencePlate"
            name="truckLicencePlate"
            label={t('pages.gateIn.form.truckLicencePlate.label')}
            defaultValue={transitions?.truckLicencePlate ?? ''}
            control={control}
            disabled={!!carrierSearchDto?.truckLicencePlate}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className={classes.searchButton}>
            <ConardButton conardVariant="light" type="submit" disabled={blockSearch}>
              {t('any.buttons.search')}
            </ConardButton>
          </div>
          {transitionMode !== TransitionMode.TRAIN && (
            <div className={classes.searchButton}>
              <ConardButton conardVariant="transparent" type="reset" onClick={cleanFilter} disabled={blockSearch}>
                {t('any.buttons.reset')}
              </ConardButton>
            </div>
          )}
        </Grid>
      </Grid>
    </form>
  )
}

export default TransitionSearchFilter
