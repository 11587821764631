import React, { FC, useState } from 'react'
import { createStyles, Grid, Hidden, InputAdornment, makeStyles, MenuItem } from '@material-ui/core'
import ConardTextField from '../../components/ConardTextField'
import { useTranslation } from 'react-i18next'
import { ShortTermPassageSearchDto } from '../../api'
import { Controller, useForm } from 'react-hook-form'
import ConardSelect from '../../components/ConardSelect'
import ConardButton from '../../components/ConardButton'
import { DatePicker } from '@material-ui/pickers'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      width: '95%',
    },
    searchButton: {
      width: '15vw',
      minWidth: '300px',
      height: '5vh',
      minHeight: '50px',
      textAlign: 'center',
      margin: '20px',
      float: 'right',
    },
    datePickerInputInput: {
      width: '100%',
      textAlign: 'center',
    },
    datePickerInputRoot: {
      width: '60%',
      height: '58.5px',
      margin: 'auto',
    },
    datePickerRoot: {
      width: '100%',
      background: theme.palette.secondary.main,
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderRadius: '30px',
      '&:hover': {
        borderColor: 'black',
      },
    },
  })
)

interface ShortTermPassageFilterProps {
  shortTermPassageFilter: (data: ShortTermPassageSearchDto) => void
}

const ShortTermPassageFilter: FC<ShortTermPassageFilterProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { shortTermPassageFilter } = props

  const [validFrom, setValidFrom] = useState<MaterialUiPickersDate | null>(null)
  const [validTo, setValidTo] = useState<MaterialUiPickersDate | null>(null)
  const [selectedCheckpointOne, setSelectedCheckpointOne] = useState('NOT-SELECTED')
  const [selectedCheckpointTwo, setSelectedCheckpointTwo] = useState('NOT-SELECTED')
  const [selectedCheckpointThree, setSelectedCheckpointThree] = useState('NOT-SELECTED')

  const { handleSubmit, control, setValue } = useForm<ShortTermPassageSearchDto>({})

  const dateFromOnChange = (date: MaterialUiPickersDate | null) => {
    setValidFrom(date || new Date())
    setValue('validFrom', date)
  }

  const dateToOnChange = (date: MaterialUiPickersDate | null) => {
    setValidTo(date || new Date())
    setValue('validTo', date)
  }

  return (
    <form onSubmit={handleSubmit(shortTermPassageFilter)}>
      <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardTextField
            id="licensePlate"
            name="licensePlate"
            defaultValue=""
            label={t('entity.shortTermPassage.fields.licensePlate')}
            control={control}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardTextField
            id="firstName"
            name="firstName"
            defaultValue=""
            label={t('entity.shortTermPassage.fields.firstName')}
            control={control}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardTextField
            id="surname"
            name="surname"
            defaultValue=""
            label={t('entity.shortTermPassage.fields.surname')}
            control={control}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardTextField
            id="company"
            name="company"
            defaultValue=""
            label={t('entity.shortTermPassage.fields.company')}
            control={control}
          />
        </Grid>
        <Grid item xl={4} lg={4} />
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <Controller
            name="validFrom"
            control={control}
            defaultValue={null}
            render={() => (
              <DatePicker
                emptyLabel={t('entity.shortTermPassage.fields.validFrom')}
                format="dd.MM.yyyy"
                value={validFrom}
                onChange={(value) => {
                  dateFromOnChange(value)
                }}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <Hidden lgDown>
                      <InputAdornment position="start">
                        <CalendarTodayIcon />
                      </InputAdornment>
                    </Hidden>
                  ),
                  classes: {
                    root: classes.datePickerInputRoot,
                    input: classes.datePickerInputInput,
                  },
                }}
                className={classes.datePickerRoot}
              />
            )}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <Controller
            name="validTo"
            defaultValue={null}
            control={control}
            render={() => (
              <DatePicker
                emptyLabel={t('entity.shortTermPassage.fields.validTo')}
                format="dd.MM.yyyy"
                value={validTo}
                onChange={(value) => {
                  dateToOnChange(value)
                }}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <Hidden lgDown>
                      <InputAdornment position="start">
                        <CalendarTodayIcon />
                      </InputAdornment>
                    </Hidden>
                  ),
                  classes: {
                    root: classes.datePickerInputRoot,
                    input: classes.datePickerInputInput,
                  },
                }}
                className={classes.datePickerRoot}
              />
            )}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardSelect
            id="checkpointOneAllowed"
            name="checkpointOneAllowed"
            label={t('pages.licensePlate.table.checkpointOne')}
            control={control}
            value={selectedCheckpointOne}
            onChange={(event) => setSelectedCheckpointOne(event.target.value)}
            defaultValue="NOT-SELECTED"
          >
            <MenuItem value="NOT-SELECTED">{t('pages.common.select.all')}</MenuItem>
            <MenuItem value="true">{t('pages.shortTermPassage.gates.allowed')}</MenuItem>
            <MenuItem value="false">{t('pages.shortTermPassage.gates.notAllowed')}</MenuItem>
          </ConardSelect>
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardSelect
            id="checkpointTwoAllowed"
            name="checkpointTwoAllowed"
            label={t('pages.licensePlate.table.checkpointTwo')}
            control={control}
            value={selectedCheckpointTwo}
            onChange={(event) => setSelectedCheckpointTwo(event.target.value)}
            defaultValue="NOT-SELECTED"
          >
            <MenuItem value="NOT-SELECTED">{t('pages.common.select.all')}</MenuItem>
            <MenuItem value="true">{t('pages.shortTermPassage.gates.allowed')}</MenuItem>
            <MenuItem value="false">{t('pages.shortTermPassage.gates.notAllowed')}</MenuItem>
          </ConardSelect>
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardSelect
            id="checkpointThreeAllowed"
            name="checkpointThreeAllowed"
            label={t('pages.licensePlate.table.checkpointThree')}
            control={control}
            value={selectedCheckpointThree}
            onChange={(event) => setSelectedCheckpointThree(event.target.value)}
            defaultValue="NOT-SELECTED"
          >
            <MenuItem value="NOT-SELECTED">{t('pages.common.select.all')}</MenuItem>
            <MenuItem value="true">{t('pages.shortTermPassage.gates.allowed')}</MenuItem>
            <MenuItem value="false">{t('pages.shortTermPassage.gates.notAllowed')}</MenuItem>
          </ConardSelect>
        </Grid>
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <div>
            <ConardButton conardVariant="light" type="submit">
              {t('any.buttons.filter')}
            </ConardButton>
          </div>
        </Grid>
      </Grid>
    </form>
  )
}

export default ShortTermPassageFilter
