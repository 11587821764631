import { Box, Dialog, DialogActions, DialogContent, Grid } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import ConardButton from '../../components/ConardButton'
import { useTranslation } from 'react-i18next'
import Container from './Container'
import { Typography, makeStyles } from '@material-ui/core'

import terminalLayoutService from '../../services/TerminalLayoutService'
import { StackContainerDto } from '../../api'

const useStyles = makeStyles({
  dialog: {
    backgroundColor: 'transparent',
  },
  dialogContent: {
    display: 'flex',
    width: '300px',
    flexDirection: 'column',
    gap: '5px',
    '&.MuiGrid-root': {
      flexDirection: 'column',
    },
  },
  position: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10px',
  },
  button: {
    borderRadius: 12,
  },
})

interface FloorsDialogProps {
  showFloorsDialog: boolean
  sectorColumn: number
  sectorRow: number
  sectorLetter: string
  onClose: () => void
}

const FloorsDialog: FC<FloorsDialogProps> = ({ showFloorsDialog, onClose, sectorColumn, sectorLetter, sectorRow }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [floor, setFloor] = useState<StackContainerDto[]>([])

  useEffect(() => {
    if (showFloorsDialog) {
      const fetchData = async (columnIndex: number, rowIndex: number, sectorName: string) => {
        const response = await terminalLayoutService.getFloor({
          sectorLetter: sectorName,
          sectorColumn: columnIndex,
          sectorRow: rowIndex,
        })

        const filteredArray = response.data.filter((d) => !!d)

        setFloor(filteredArray)
      }
      fetchData(sectorColumn, sectorRow, sectorLetter).catch((error) => {
        console.log(error)
      })
    }
  }, [showFloorsDialog, sectorColumn, sectorLetter, sectorRow])

  return (
    <Dialog className={classes.dialog} open={showFloorsDialog} onClose={onClose}>
      <DialogContent>
        <Box className={classes.position}>
          <Typography>{`${t('pages.terminalLayout.section')} ${sectorLetter}, ${t(
            'pages.terminalLayout.column'
          )}: ${sectorColumn}, ${t('pages.terminalLayout.row')}: ${sectorRow}`}</Typography>
        </Box>
        {!!floor.length && (
          <Grid className={classes.dialogContent}>
            {floor.map((container) => (
              <React.Fragment key={container.id}>
                <Container container={container} />
              </React.Fragment>
            ))}
          </Grid>
        )}
        {!floor.length && <Box>{t('pages.terminalLayout.noContainers')}</Box>}
      </DialogContent>
      <DialogActions>
        <ConardButton className={classes.button} conardVariant="dark" onClick={onClose}>
          {t('any.buttons.close')}
        </ConardButton>
      </DialogActions>
    </Dialog>
  )
}

export default FloorsDialog
