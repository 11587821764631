import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { createStyles, Grid, MenuItem, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { AsparkingCardDto, AsparkingLicensePlateDto, CarrierDto } from '../../api'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Mode } from '../../enums/Mode'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import ConardSelect from '../../components/ConardSelect'
import ConardTextField from '../../components/ConardTextField'
import { makeStyles } from '@material-ui/core/styles'
import ConardButton from '../../components/ConardButton'
import { cleanObject } from '../../utils/utils'
import { Alert } from '@material-ui/lab'
import cardService from '../../services/CardService'
import ConardDatePicker from '../../components/ConardDatePicker'
import ConardSwitch from '../../components/ConardSwitch'
import { FieldName } from 'react-hook-form/dist/types/fields'
import licensePlateService from '../../services/LicensePlateService'

const useStyles = makeStyles((theme) =>
  createStyles({
    typography: {
      width: '60vw',
    },
    button: {
      width: '15vw',
      minWidth: '200px',
      textAlign: 'center',
      margin: 'auto',
    },
    container: {
      marginTop: '10vh',
      width: '50vw',
      minWidth: '300px',
      margin: 'auto',
    },
    alert: {
      margin: 'auto',
      width: '50vw',
      marginTop: '20vh',
    },
    datePickerInputInput: {
      width: '100%',
      textAlign: 'center',
    },
    datePickerInputRoot: {
      width: '60%',
      height: '58.5px',
      margin: 'auto',
    },
    datePickerRoot: {
      width: '100%',
      background: theme.palette.secondary.main,
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderRadius: '30px',
      marginTop: '-18px',
      '&:hover': {
        borderColor: 'black',
      },
    },
  })
)

interface CardFormProps {
  mode: Mode
  carrier?: CarrierDto
  card?: AsparkingCardDto
}

const CardEditForm: FC<CardFormProps> = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  const { carrier, card, mode } = props

  const [licensePlates, setLicensePlates] = useState<AsparkingLicensePlateDto[] | undefined>(undefined)
  const [licensePlate, setLicensePlate] = useState<AsparkingLicensePlateDto | undefined>(card?.licensePlate)
  const [firstName, setFirstName] = useState<string | undefined>(card?.licensePlate?.firstName)
  const [surname, setSurname] = useState<string | undefined>(card?.licensePlate?.surname)
  const [disabled, setDisabled] = useState<boolean>(false)

  const yupSchema = yup.object().shape({
    cardNumber: yup
      .number()
      .typeError(t('pages.card.validation.cardNumber.number'))
      .required(t('pages.card.validation.cardNumber.required')),
    licensePlate: yup
      .object()
      .shape({
        id: yup.number().positive(t('pages.card.validation.licensePlate.required')),
      })
      .required(t('pages.card.validation.licensePlate.required')),
  })

  const { handleSubmit, control, errors } = useForm<AsparkingCardDto>({
    resolver: yupResolver(yupSchema),
  })

  const getAllLicensePlates = () => {
    if (carrier) {
      licensePlateService
        .licensePlateSearch(0, 100, undefined, { carrierId: carrier.id, hasCard: false })
        .then((response) => {
          setLicensePlates(response.data.content)
        })
    }
  }

  const changeSwitch = (field: FieldName<AsparkingCardDto>, newValue: boolean) => {
    control.setValue(field, newValue)
    setDisabled(newValue)
  }

  const onChangeLicensePlate = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (parseInt(event.target.value) === card?.licensePlate?.id) {
      control.setValue('licensePlate', card?.licensePlate)
      setLicensePlate(card?.licensePlate)
    } else {
      control.setValue(
        'licensePlate',
        licensePlates?.find((licensePlate) => licensePlate.id === parseInt(event.target.value))
      )
      setLicensePlate(licensePlates?.find((licensePlate) => licensePlate.id === parseInt(event.target.value)))
    }
  }

  useEffect(() => {
    control.setValue('licensePlate.firstName', licensePlate?.firstName ?? '')
    setFirstName(licensePlate?.firstName ?? '')
    control.setValue('licensePlate.surname', licensePlate?.surname ?? '')
    setSurname(licensePlate?.surname ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licensePlate])

  const save = (data: AsparkingCardDto) => {
    if (licensePlate) {
      data = {
        ...data,
        licensePlate,
      }
    }

    if (mode === Mode.Create) {
      cardService
        .cardCreate(cleanObject(data))
        .then(() => {
          history.push('/entry-and-parking/carrier/' + carrier?.id + '/card')
        })
        .catch()
    } else {
      cardService
        .cardUpdate(card?.id || -1, cleanObject(data))
        .then(() => {
          history.push('/entry-and-parking/carrier/' + carrier?.id + '/card')
        })
        .catch()
    }
  }

  useEffect(() => {
    getAllLicensePlates()
    setDisabled(card?.disabled ?? false)
    control.setValue('disabled', card?.disabled)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Typography className={classes.typography} variant="h4" color="primary">
        {mode === Mode.Create ? t('pages.card.create.title') : t('pages.card.update.title')}
      </Typography>

      {mode === Mode.Update && card === undefined && (
        <Alert severity="error" className={classes.alert}>
          {t('be.error.entity.notFound')}
        </Alert>
      )}

      {(mode === Mode.Create || card !== undefined) && (
        <form onSubmit={handleSubmit(save)}>
          <Grid
            container
            className={classes.container}
            direction="row"
            alignItems="flex-start"
            justifyContent="center"
            spacing={4}
          >
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="cardNumber"
                name="cardNumber"
                type="text"
                label={t('pages.card.header.cardNumber')}
                defaultValue={card?.cardNumber ?? ''}
                fullWidth
                error={!!errors.cardNumber}
                helperText={errors.cardNumber ? errors.cardNumber.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardSelect
                id="licensePlate.id"
                name="licensePlate.id"
                label={t('pages.card.header.licensePlate')}
                defaultValue={licensePlate?.id ?? -1}
                value={licensePlate?.id ?? -1}
                onChange={(event) => onChangeLicensePlate(event)}
                control={control}
                error={!!errors.licensePlate?.id}
                helperText={errors.licensePlate?.id ? t('pages.card.validation.licensePlate.required') : ' '}
              >
                <MenuItem value={-1}>{t('any.notSelected')}</MenuItem>
                {card && card.licensePlate && (
                  <MenuItem key={card.licensePlate.id} value={card.licensePlate.id}>
                    {card.licensePlate.licensePlate}
                  </MenuItem>
                )}
                {licensePlates?.map((licensePlateMenuItem) => (
                  <MenuItem key={licensePlateMenuItem.id} value={licensePlateMenuItem.id}>
                    {licensePlateMenuItem.licensePlate}
                  </MenuItem>
                ))}
              </ConardSelect>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="licensePlate.firstName"
                name="licensePlate.firstName"
                type="text"
                label={t('entity.licensePlate.fields.firstName')}
                defaultValue={firstName ?? ''}
                fullWidth
                disabled
                error={!!errors.licensePlate?.firstName}
                helperText={errors.licensePlate?.firstName ? errors.licensePlate?.firstName.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="licensePlate.surname"
                name="licensePlate.surname"
                type="text"
                label={t('entity.licensePlate.fields.surname')}
                defaultValue={surname ?? ''}
                fullWidth
                disabled
                error={!!errors.licensePlate?.surname}
                helperText={errors.licensePlate?.surname ? errors.licensePlate?.surname.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardDatePicker
                name="validFrom"
                defaultValue={new Date(card?.validFrom ?? new Date().setHours(0, 0, 0, 0))}
                control={control}
                label={t('entity.card.fields.validFrom')}
                error={!!errors.validFrom}
                helperText={errors.validFrom ? errors.validFrom.message : ' '}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardDatePicker
                name="validTo"
                defaultValue={new Date(card?.validTo ?? new Date().setHours(23, 59, 59, 0))}
                control={control}
                label={t('entity.card.fields.validTo')}
                error={!!errors.validTo}
                helperText={errors.validTo ? errors.validTo.message : ' '}
              />
            </Grid>
            <Grid item xl={3} lg={3} md={3} />
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardSwitch
                id="disabled"
                name="disabled"
                checked={disabled}
                onChange={(newValue) => changeSwitch('disabled', newValue)}
                control={control}
                checkedLabel={t('pages.card.table.disabled')}
                uncheckedLabel={t('pages.card.table.active')}
              />
            </Grid>
            <Grid item xl={3} lg={3} md={3} />
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <div className={classes.button}>
                <ConardButton conardVariant="dark" type="submit">
                  {t('any.buttons.save')}
                </ConardButton>
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <div className={classes.button}>
                <ConardButton conardVariant="dark" onClick={() => history.goBack()}>
                  {t('any.buttons.back')}
                </ConardButton>
              </div>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  )
}

export default CardEditForm
