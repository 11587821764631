import { useContext } from 'react'
import { TerminalLayoutContext } from '../context/TerminalLayoutActionContext'

export const useTerminalLayoutContext = () => {
  const contextValue = useContext(TerminalLayoutContext)

  if (!contextValue) {
    throw new Error('useTerminalLayoutContext have to be used within TerminalLayoutContextProvider')
  }

  return contextValue
}
