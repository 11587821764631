import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { TruckMonitoringLogDto, TruckMonitoringLogDtoCheckpointEnum } from '../../api'
import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import TruckMonitoringTableRow from './TruckMonitoringTableRow'

const useStyles = makeStyles((theme) =>
  createStyles({
    typography: {
      color: theme.palette.secondary.main,
      justifyContent: 'center',
      display: 'flex',
    },
    tableContainer: {
      border: `1px solid ${theme.palette.grey.A200}`,
      borderRadius: 8,
      height: '100%',
      backgroundColor: theme.palette.secondary.main,
    },
  })
)

interface TruckMonitoringTableProps {
  checkpoint: TruckMonitoringLogDtoCheckpointEnum
  logs: Array<TruckMonitoringLogDto> | undefined
  handleShowDialog: (log: TruckMonitoringLogDto | undefined, checkpoint: TruckMonitoringLogDtoCheckpointEnum) => void
}

const TruckMonitoringTable: FC<TruckMonitoringTableProps> = ({ checkpoint, logs, handleShowDialog }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const handleRowOnClick = (log: TruckMonitoringLogDto | undefined) => {
    handleShowDialog(log, checkpoint)
  }

  return (
    <>
      <TableContainer className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell key="licensePlate">
                <Typography variant="h5" className={classes.typography}>
                  {t(`pages.truckMonitoring.table.${checkpoint}`)}
                </Typography>
                <Typography variant="subtitle2" className={classes.typography}>
                  {'(' + (logs?.length ?? 0) + ')'}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logs !== undefined &&
              logs?.map((log) => (
                <TruckMonitoringTableRow
                  key={log.id}
                  onClick={handleRowOnClick}
                  id={log.id?.toString() ?? ''}
                  rowKey={log.id}
                  log={log}
                />
              ))}

            <TruckMonitoringTableRow onClick={handleRowOnClick} id="newLog" rowKey="newLog" />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
export default TruckMonitoringTable
