import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

export const RepletedValidationScheme = () => {
  const { t } = useTranslation()

  const repletedValidaion = yup.object().shape({
    signetValue: yup
      .string()
      .when('repleted', {
        is: true,
        then: yup.string().required(t('kioskApp.step3.validations.signetValueRequired')),
      })
      .notRequired(),
    netWeight: yup
      .number()
      .when('repleted', {
        is: true,
        then: yup
          .number()
          .required(t('kioskApp.step3.validations.netWeightRequired'))
          .min(0, t('kioskApp.step3.validations.netWeightNegative'))
          .typeError(t('kioskApp.step3.validations.netWeightRequired')),
      })
      .notRequired(),
  })

  return repletedValidaion
}
