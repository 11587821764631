import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core'
import ConardButton from '../ConardButton'
import { DeleteForeverOutlined } from '@material-ui/icons'

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '5rem',
    },
    dialogIcon: {
      color: theme.palette.primary.main,
      margin: '20px',
    },
  })
)

interface TrainImportDialogProps {
  showDialog: boolean
  onClose: () => void
  onSubmit: () => void
}
const TrainImportDialog: FC<TrainImportDialogProps> = ({ showDialog, onClose, onSubmit }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Dialog open={showDialog}>
      <DialogTitle>{t('pages.gateIn.dialogs.trainModel.title')}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DeleteForeverOutlined fontSize="inherit" className={classes.dialogIcon} />
        <Typography>{t('pages.gateIn.dialogs.trainModel.description')}</Typography>
      </DialogContent>
      <DialogActions>
        <ConardButton
          conardVariant="dark"
          onClick={() => {
            onClose()
            onSubmit()
          }}
        >
          {t('any.buttons.load')}
        </ConardButton>
      </DialogActions>
      <DialogActions>
        <ConardButton conardVariant="dark" onClick={onClose}>
          {t('any.buttons.back')}
        </ConardButton>
      </DialogActions>
    </Dialog>
  )
}

export default TrainImportDialog
