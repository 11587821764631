import React, { FC, useEffect, useState } from 'react'
import { LayoutContainerDto } from '../../api'
import terminalLayoutService from '../../services/TerminalLayoutService'
import { Box, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ConardButton from '../../components/ConardButton'
import { Semitrailer } from './Semitrailer'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  dialog: {
    backgroundColor: 'transparent',
  },
  dialogContent: {
    display: 'flex',
    width: '500px',
    flexDirection: 'column',
    gap: '5px',
    '&.MuiGrid-root': {
      flexDirection: 'column',
    },
  },
  typography: {
    paddingBottom: '10px',
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
})

interface SemitrailerSectorDialogProps {
  showSectorDialog: boolean
  sectorLetter: string
  onClose: () => void
}

export const SemitrailerSectorDialog: FC<SemitrailerSectorDialogProps> = ({
  sectorLetter,
  showSectorDialog,
  onClose,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [dialog, setDialog] = useState<LayoutContainerDto[]>()

  useEffect(() => {
    if (showSectorDialog) {
      const fetchData = async (sectorLetter: string) => {
        const response = await terminalLayoutService.getUnordered({
          sectorLetter: sectorLetter,
        })

        setDialog(response.data)
      }
      fetchData(sectorLetter).catch((error) => {
        console.log(error)
      })
    }
  }, [sectorLetter, showSectorDialog])

  return (
    <Dialog open={showSectorDialog} onClose={onClose} className={classes.dialog}>
      <DialogContent className={classes.dialogContent}>
        <Box className={classes.typography}>
          <Typography>{`${t('pages.terminalLayout.section')} ${sectorLetter}`}</Typography>
        </Box>
        {!!dialog?.length && (
          <Grid container className={classes.grid}>
            {dialog.map((f, index) => (
              <Grid item key={index}>
                <Semitrailer container={dialog[index]} />
              </Grid>
            ))}
          </Grid>
        )}
        {!dialog?.length && <Box>{t('pages.terminalLayout.sectorEmpty')}</Box>}
      </DialogContent>
      <DialogActions>
        <ConardButton conardVariant="dark" onClick={onClose}>
          {t('any.buttons.close')}
        </ConardButton>
      </DialogActions>
    </Dialog>
  )
}
