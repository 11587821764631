import { useContext } from 'react'
import { TerminalTemporaryDrawerContext } from '../context/TerminalTemporaryDrawerContext'

export const useTerminalTemporaryDrawer = () => {
  const contextValue = useContext(TerminalTemporaryDrawerContext)

  if (!contextValue) {
    throw new Error('useTerminalTemporaryDrawer must be used inside TerminalTemporaryDrawerContext')
  }

  return contextValue
}
