import React, { FC, PropsWithChildren, useMemo, useState } from 'react'
import { ContainerForPlacement } from './containerPlacementTypes'

interface ContextValue {
  containers: ContainerForPlacement[]
  addContainer: (container: ContainerForPlacement) => void
  removeContainer: (containerId: number) => void
  getSelectedContainer: () => ContainerForPlacement | undefined
  selectContainer: (container: ContainerForPlacement) => void
  resetSelectedContainer: () => void
}

export const TerminalTemporaryDrawerContext = React.createContext<ContextValue | undefined>(undefined)

export const TerminalTemporaryDrawerContextProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [containers, setContainers] = useState<ContainerForPlacement[]>([])
  const [selectedContainer, setSelectedContainer] = useState<ContainerForPlacement | undefined>(undefined)

  const contextVal = useMemo<ContextValue>(
    () => ({
      containers,
      addContainer: (container: ContainerForPlacement) => {
        setContainers((prev) => [...prev, container])
      },
      removeContainer: (containerId: number) => {
        setContainers((prev) =>
          prev.reduce<ContainerForPlacement[]>((acc, container) => {
            if (container.id !== containerId) {
              return [...acc, container]
            }
            return acc
          }, [])
        )
      },
      getSelectedContainer: () => selectedContainer,
      selectContainer: (container: ContainerForPlacement) => {
        setSelectedContainer(container)
      },
      resetSelectedContainer: () => {
        setSelectedContainer(undefined)
      },
    }),
    [containers, selectedContainer]
  )

  return (
    <TerminalTemporaryDrawerContext.Provider value={contextVal}>{children}</TerminalTemporaryDrawerContext.Provider>
  )
}

// because i need to see/identify it in devtools
TerminalTemporaryDrawerContext.displayName = 'TerminalTemporaryDrawerContext'
