import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

export const GateOutCoparnCreateValidationScheme = () => {
  const { t } = useTranslation()
  return yup.object().shape({
    numberOfContainers: yup
      .number()
      .transform((value, originalValue) => {
        return originalValue.trim() === '' ? undefined : value
      })
      .required(t('pages.coparnCreate.validations.numberOfContainers'))
      .positive(t('pages.coparnCreate.validations.positiveNumber'))
      .max(200, t('pages.coparnCreate.validations.maxNumber')),
    medlogReference: yup.string().required(t('pages.coparnCreate.validations.medlogRef')),
    shipowner: yup.object().shape({
      id: yup.number().positive(t('pages.coparnCreate.validations.shipowner')),
    }),
    uniqueCoparnContainerQuality: yup.string().required(t('pages.coparnCreate.validations.quality')),
    uniqueShippingContainerType: yup.string().required(t('pages.coparnCreate.validations.type')),
  })
}
