import React, { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { ConardAutocomplete, ConardAutocompletePropsUnited } from '../ConardAutocomplete'

type Props = Omit<ConardAutocompletePropsUnited, 'value' | 'onChange'> &
  Partial<Pick<ConardAutocompletePropsUnited, 'onChange'>> & {
    name: string
  }

export const RHFAutocomplete: FC<Props> = ({
  name,
  disabled,
  label,
  options,
  onChange: onChangeProps,
  helperText,
  ...rest
}) => {
  const { control, errors } = useFormContext()
  return (
    <Controller
      control={control}
      name={name}
      render={({ value, onChange }) => {
        return (
          <ConardAutocomplete
            disabled={disabled}
            label={label}
            options={options}
            value={value}
            onChange={(data) => {
              onChange(data)

              onChangeProps?.(data)
            }}
            helperText={errors?.[name]?.message ?? helperText}
            {...rest}
          />
        )
      }}
    />
  )
}
