import { Box, Grid, Typography, createStyles, makeStyles, Divider } from '@material-ui/core'
import React, { FC, useEffect, useState } from 'react'
import { useConardAuth } from '../../../hooks/useConardAuth'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import driverArrivalService from '../../../services/DriverArrivalService'
import { TransitionCard } from './transition-card/TransitionCard'
import { RHFDatePicker } from '../../../components/shared/rhf/RHFDateTimePicker'
import { useHistory } from 'react-router-dom'
import { DriverArrivalDto } from '../../../api'
import { ConardLabel } from '../../../components/transition/DriverFields'
import ConardButton from '../../../components/ConardButton'
import { SemaphoreInfo } from './SemaphoreInfo'
import { DeleteDialog } from './DeleteDialog'

const useStyles = makeStyles((theme) =>
  createStyles({
    nameWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: '20px',
    },
    driverName: {
      fontWeight: 750,
    },
    wrapper: {
      paddingTop: '10px',
      minHeight: '550px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    gridDeleteButton: {
      display: 'flex',
      justifyContent: 'center',
    },
    deleteButton: {
      borderRadius: 12,
      height: '50px',
      [theme.breakpoints.up('sm')]: {
        width: '300px',
      },
    },
    divider: {
      margin: '1rem 0',
    },
    disabledDeleteButton: {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
    grid: {
      maxWidth: '600px',
      margin: 'auto',
    },
  })
)

type DriverArrivalFormValue = {
  arrivalDateTime: Date | null
  withoutGateIn: boolean
  withoutGateOut: boolean
}

const initialValues: DriverArrivalFormValue = {
  arrivalDateTime: null,
  withoutGateIn: false,
  withoutGateOut: false,
}

export const DriverArrivalPage: FC = () => {
  const formMethods = useForm<DriverArrivalFormValue>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
  })

  const auth = useConardAuth()
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  const [showDialog, setShowDialog] = useState(false)

  const [data, setData] = useState<DriverArrivalDto | undefined>(undefined)

  const [isDateTimeChosen, setIsDateTimeChosen] = useState(false)

  const [loaded, setLoaded] = useState<boolean>(false)

  const isNew = !data

  const handleClickDelete = () => {
    setShowDialog(true)
  }

  const handleDeleteAll = async () => {
    const id = data?.id

    if (id) {
      try {
        await driverArrivalService.cancel(id)

        setShowDialog(false)

        setData(undefined)
        setIsDateTimeChosen(false)

        formMethods.reset(initialValues)
      } catch (err) {
        console.error(err)
      }
    }
  }

  const handleDeleteGateIn = async () => {
    if (data?.id) {
      try {
        await driverArrivalService.deleteGateInOrOut(data.id, 'IN')

        const response = await driverArrivalService.get()
        if (response.data) {
          setData(response.data)
        }

        setShowDialog(false)
      } catch (err) {
        console.error(err)
      }
    }
  }

  const handleDeleteGateOut = async () => {
    if (data?.id) {
      try {
        await driverArrivalService.deleteGateInOrOut(data.id, 'OUT')
        const response = await driverArrivalService.get()
        if (response.data) {
          setData(response.data)
        }

        setShowDialog(false)
      } catch (err) {
        console.error(err)
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await driverArrivalService.get()

      if (response.data) {
        setData(response.data)

        const withoutGateIn = response.data.withoutGateIn ?? false
        const withoutGateOut = response.data.withoutGateOut ?? false

        setIsDateTimeChosen(true)

        formMethods.setValue('arrivalDateTime', response.data.arrivalDateTime)
        formMethods.setValue('withoutGateIn', withoutGateIn)
        formMethods.setValue('withoutGateOut', withoutGateOut)
      }

      setLoaded(true)
    }

    try {
      fetchData()
    } catch (err) {
      setLoaded(true)
      console.error(err)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps -- formMethods is not there intentionally (cyclic server calls)
  }, [])

  const onSubmit = (data: DriverArrivalFormValue) => {
    console.log(data)
  }

  const changeArrivallDateTime = async () => {
    setIsDateTimeChosen(true)

    const formValue: DriverArrivalFormValue = formMethods.getValues()

    if (formValue.arrivalDateTime) {
      try {
        if (isNew) {
          const result = await driverArrivalService.create({
            arrivalDateTime: formValue.arrivalDateTime.toISOString(),
            driver: {
              name: `${auth.getFirstName()} ${auth.getSurname()}`,
            },
          })

          if (result.data) {
            setData(result.data)
          }
        } else {
          if (data) {
            await driverArrivalService.update(data?.id ?? 0, {
              ...data,
              arrivalDateTime: formValue.arrivalDateTime.toISOString(),
            })
          }
        }
      } catch (err) {
        console.error(err)
      }
    }
  }

  const onGateInNoCargo = async () => {
    if (data) {
      try {
        const result = await driverArrivalService.update(data.id ?? 0, {
          ...data,
          withoutGateIn: true,
        })

        if (result.data) {
          setData(result.data)
        }
      } catch (err) {
        console.error(err)
      }
    }
  }

  const onGateOutNoCargo = async () => {
    if (data) {
      try {
        const result = await driverArrivalService.update(data?.id ?? 0, {
          ...data,
          withoutGateOut: true,
        })

        if (result.data) {
          setData(result.data)
        }
      } catch (err) {
        console.error(err)
      }
    }
  }

  const onEditGateIn = (idDriverArrival: number, containerId: number) => {
    if (data?.plannedTransition?.id) {
      history.push(`/driver-arrival/gate-in/${idDriverArrival}?${containerId}`)
    }
  }

  const onGateIn = (idDriverArrival: number) => {
    history.push(`/driver-arrival/check-ilu/${idDriverArrival}`)
  }

  const onGateOut = (idDriverArrival: number) => {
    history.push(`/driver-arrival/gate-out/${idDriverArrival}`)
  }

  const onEditGateOut = (id: number) => {
    if (data?.reference || data?.iluCode || data?.semitrailerLicencePlate) {
      history.push(`/driver-arrival/gate-out/${id}`)
    }
  }

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)} style={{ display: !loaded ? 'none' : undefined }}>
          <Box className={classes.grid}>
            <Box className={classes.nameWrapper}>
              <Typography variant="h4" className={classes.driverName}>
                {auth.getFirstName()} {auth.getSurname()}
              </Typography>
              <SemaphoreInfo
                driverPass={data?.driverPass}
                isCarrier={false}
                instantGreenPass={data?.instantGreenPass ?? false}
              />
            </Box>
            <Divider className={classes.divider} />
            <Grid container direction="column" className={classes.wrapper}>
              <Grid item>
                <ConardLabel>{t('driverApp.driverArrival.arrivalDateTime')}</ConardLabel>

                <RHFDatePicker
                  name="arrivalDateTime"
                  placeholder={t('driverApp.driverArrival.dateTimePlaceholder')}
                  ampm={false}
                  disablePast
                  onChange={() => changeArrivallDateTime()}
                />
              </Grid>

              <Grid item>
                <TransitionCard
                  isCarrier={false}
                  data={data}
                  disabled={!isDateTimeChosen}
                  onGateInNoCargo={() => {
                    onGateInNoCargo()
                  }}
                  onGateOutNoCargo={() => {
                    onGateOutNoCargo()
                  }}
                  onGateIn={() => {
                    onGateIn(data?.id ?? 0)
                  }}
                  onGateOUt={() => {
                    onGateOut(data?.id ?? 0)
                  }}
                  onEditGateIn={() => {
                    onEditGateIn(data?.id ?? 0, data?.plannedTransition?.container.id ?? 0)
                  }}
                  onEditGateOut={() => {
                    onEditGateOut(data?.id ?? 0)
                  }}
                />
              </Grid>

              <Grid item className={classes.gridDeleteButton}>
                <ConardButton
                  type="reset"
                  conardVariant="red"
                  disabled={!isDateTimeChosen}
                  onClick={handleClickDelete}
                  className={`${classes.deleteButton} ${!isDateTimeChosen ? classes.disabledDeleteButton : ''}`}
                >
                  {t('driverApp.driverArrival.buttons.delete')}
                </ConardButton>
              </Grid>
            </Grid>
          </Box>

          <DeleteDialog
            showDeleteDialog={showDialog}
            onClose={() => setShowDialog(false)}
            onDeleteAll={handleDeleteAll}
            onDeleteGateIn={handleDeleteGateIn}
            onDeleteGateOut={handleDeleteGateOut}
          />
        </form>
      </FormProvider>
    </>
  )
}
