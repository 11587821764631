import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import { CoparnService } from '../../services/CoparnService'
import { CoparnDtoCoparnStatusEnum, CoparnSearchDto, PageCoparnDto } from '../../api'
import ConardButton from '../../components/ConardButton'
import { format } from 'date-fns'
import { cleanObject, cleanShippingContainerType } from '../../utils/utils'
import { Order } from '../../enums/Order'
import ConardProgressBar from '../../components/ConardProgressBar'
import { ConfigContext } from '../../context/ConfigContext'
import { GateOutCoparnFilter } from './GateOutCoparnFilter'
import AddIcon from '@material-ui/icons/Add'
import ConardTooltip from '../../components/ConardTooltip'
import PersonIcon from '@material-ui/icons/Person'
import MailIcon from '@material-ui/icons/Mail'

const useStyles = makeStyles((theme) =>
  createStyles({
    gridContainer: {
      marginTop: '8vh',
    },
    noData: {
      textAlign: 'center',
    },
    detailColumn: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: 4,
      padding: '20px',
    },
    filterButton: {
      textAlign: 'center',
      margin: '20px',
      float: 'left',
    },
    button: {
      width: '150px',
    },
    icon: {
      color: theme.palette.primary.main,
    },
    buttonsHeadCell: {
      width: '350px',
    },
  })
)

interface HeadCell {
  id: string
  label: string
}

export const GateOutCoparnListPage: FC = () => {
  const [coparnList, setCoparnList] = useState<PageCoparnDto>()
  const [page, setPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(50)
  const [order, setOrder] = useState<Order>(Order.Desc)
  const [orderBy, setOrderBy] = useState<string>('createdAt')
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()
  const { generalCarrierReference, manualCoparnCreate } = useContext(ConfigContext)

  const headCells: HeadCell[] = [
    { id: 'coparnStatus', label: t('pages.gateOut.coparn.status.label') },
    {
      id: 'medlogReference',
      label: generalCarrierReference ? t('pages.gateOut.coparn.carrierRef') : t('pages.gateOut.coparn.medlogRef'),
    },
    { id: 'uniqueShippingContainerType', label: t('pages.gateOut.coparn.detail.containerType') },
    { id: 'uniqueCoparnContainerQuality', label: t('pages.gateOut.coparn.detail.quality') },
    { id: 'shipownerName', label: t('pages.gateOut.coparn.contractHolder') },
    { id: 'numberOfProcessedContainers', label: t('pages.gateOut.coparn.processed') },
    { id: 'createdAt', label: t('pages.gateOut.coparn.createdAt') },
    { id: 'firstName', label: t('pages.gateOut.coparn.createdBy') },
  ]

  const showDetail = (id: number, medlogReference: string | undefined) => {
    history.push(`/gate-out/coparn/${id}?medlogRef=${medlogReference}`)
  }

  const handleCoparnCancel = async (id: number) => {
    await CoparnService.coparnCancel(id)
    search(undefined, false)
  }

  const onPage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage)
  }

  const onRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleSort = (property: string) => () => {
    const isAsc = orderBy === property && order === Order.Asc
    setOrder(isAsc ? Order.Desc : Order.Asc)
    setOrderBy(property)
  }

  const searchFiltered = (data: CoparnSearchDto | undefined) => {
    search(data, true)
  }

  const search = (data: CoparnSearchDto | undefined, fromFilter: boolean) => {
    CoparnService.searchCoparns(page, pageSize, [orderBy + ',' + order], cleanObject(data))
      .then((response) => {
        setCoparnList(response.data)
        if (fromFilter) {
          setPage(0)
        }
      })
      .catch()
  }

  useEffect(() => {
    search(undefined, false)
  }, [page, pageSize, orderBy, order]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Typography variant="h4" color="primary">
        {t('pages.gateOut.coparn.pendingContainer')}
      </Typography>
      <Grid
        container
        className={classes.gridContainer}
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={3}
      >
        <Grid item lg={10} md={9} sm={9} xs={9}>
          <GateOutCoparnFilter coparnFilter={searchFiltered} />
        </Grid>
        {manualCoparnCreate && (
          <Grid item lg={2} md={3} sm={3} xs={3}>
            <ConardButton
              conardVariant="light"
              startIcon={<AddIcon />}
              onClick={() => history.push('/gate-out/coparn/create')}
            >
              {t('any.buttons.add')}
            </ConardButton>
          </Grid>
        )}
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper variant="outlined">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        component="th"
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={handleSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell component="th" className={classes.buttonsHeadCell}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!coparnList?.content?.length &&
                    coparnList?.content?.map((coparnDto) => {
                      return (
                        <TableRow key={coparnDto.id}>
                          <TableCell>
                            {coparnDto?.coparnStatus
                              ? t(`pages.gateOut.coparn.status.enum.${coparnDto.coparnStatus}`)
                              : ''}
                          </TableCell>
                          <TableCell>{coparnDto.medlogReference}</TableCell>
                          <TableCell>{cleanShippingContainerType(coparnDto.uniqueShippingContainerType)}</TableCell>
                          <TableCell>{coparnDto.uniqueCoparnContainerQuality}</TableCell>
                          <TableCell>{coparnDto.shipowner?.name}</TableCell>
                          <TableCell>{`${coparnDto.numberOfProcessedContainers} / ${coparnDto.numberOfContainers}`}</TableCell>
                          <TableCell>
                            {coparnDto.createdAt ? format(new Date(coparnDto.createdAt), 'dd.MM.yyyy HH:mm') : ''}
                          </TableCell>
                          <TableCell>
                            {coparnDto.user?.systemAccount ? (
                              <ConardTooltip title="COPARN">
                                <MailIcon className={classes.icon} />
                              </ConardTooltip>
                            ) : (
                              <ConardTooltip title={`${coparnDto.user?.firstName} ${coparnDto.user?.surname}`}>
                                <PersonIcon className={classes.icon} />
                              </ConardTooltip>
                            )}
                          </TableCell>
                          <TableCell className={classes.detailColumn}>
                            {!coparnDto.user?.systemAccount &&
                              coparnDto.coparnStatus === CoparnDtoCoparnStatusEnum.New && (
                                <ConardButton
                                  className={classes.button}
                                  conardVariant="red"
                                  onClick={() => {
                                    handleCoparnCancel(coparnDto.id ?? 0)
                                  }}
                                >
                                  {t('any.buttons.cancel')}
                                </ConardButton>
                              )}
                            <ConardButton
                              className={classes.button}
                              conardVariant="dark"
                              onClick={() => showDetail(coparnDto.id!, coparnDto.medlogReference)}
                            >
                              {t('any.buttons.detail')}
                            </ConardButton>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  {coparnList?.content?.length === 0 && (
                    <TableRow>
                      <TableCell className={classes.noData} colSpan={9}>
                        {t('pages.common.table.noDataAvailable')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <ConardProgressBar showBar={!coparnList} />
            </TableContainer>
            <TablePagination
              component="div"
              count={coparnList?.totalElements || 0}
              onPageChange={onPage}
              page={page}
              rowsPerPage={pageSize}
              labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
              rowsPerPageOptions={[10, 20, 50, 150]}
              onRowsPerPageChange={onRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}
