import { AxiosPromise } from 'axios'
import myAxios from '../config/axios/axios'
import { GateApi, GateDto } from '../api'

let api: GateApi

export const setGateApiConfiguration = () => {
  api = new GateApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const gateService = {
  findAll: (): AxiosPromise<GateDto[]> => {
    return api.gateFindAll()
  },
}

export default gateService
