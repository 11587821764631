import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  PageTruckMonitoringLogDto,
  TruckMonitoringLogDto,
  TruckMonitoringLogDtoCheckpointEnum,
  TruckMonitoringLogSearchDto,
} from '../../api'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import TruckMonitoringTable from './TruckMonitoringTable'
import ConardButton from '../../components/ConardButton'
import { useHistory } from 'react-router-dom'
import TruckMonitoringDialog from './TruckMonitoringDialog'
import truckMonitoringService from '../../services/TruckMonitoringService'
import { cleanObject } from '../../utils/utils'

const useStyles = makeStyles({
  typography: {
    width: '60vw',
  },
  gridContainer: {
    marginTop: '5vh',
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: 4,
  },
  historyButton: {
    padding: 24,
  },
})

const TruckMonitoringPage: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const classes = useStyles()

  const [pageLogs, setPageLogs] = useState<PageTruckMonitoringLogDto | null>()
  const [showDialog, setShowDialog] = useState(false)
  const [selectedLog, setSelectedLog] = useState<TruckMonitoringLogDto | undefined>()
  const [selectedCheckpoint, setSelectedCheckpoint] = useState<TruckMonitoringLogDtoCheckpointEnum>(
    TruckMonitoringLogDtoCheckpointEnum._1
  )

  const search = (data: TruckMonitoringLogSearchDto | undefined) => {
    data = { ...data, finished: false }

    truckMonitoringService
      .search(undefined, 1000, ['arrivedAt,desc'], data)
      .then((response) => {
        setPageLogs(response.data)
      })
      .catch()
  }

  const handleShowDialog = (
    log: TruckMonitoringLogDto | undefined,
    checkpoint: TruckMonitoringLogDtoCheckpointEnum
  ) => {
    setSelectedLog(log)
    setSelectedCheckpoint(checkpoint)
    setShowDialog(true)
  }

  const handleCreateUpdate = (log: TruckMonitoringLogDto) => {
    if (!log.id) {
      truckMonitoringService
        .create(cleanObject(log))
        .then(() => {
          search(undefined)
          setShowDialog(false)
        })
        .catch()
    } else {
      truckMonitoringService
        .update(log.id, log.licensePlate ? cleanObject(log) : { ...log, licensePlate: '' })
        .then(() => {
          search(undefined)
          setShowDialog(false)
        })
        .catch()
    }
  }

  useEffect(() => {
    search(undefined)

    const intervalId = setInterval(() => {
      if (!showDialog) {
        search(undefined)
      }
    }, 10000)

    return () => {
      clearInterval(intervalId)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Typography className={classes.typography} variant="h4" color="primary">
        {t('pages.entryAndParking.monitoring.title')}
      </Typography>
      <Grid container direction="row" alignItems="stretch" justifyContent="center" className={classes.gridContainer}>
        <Grid item lg={10} md={8} xs={12} />
        <Grid item className={classes.historyButton} lg={2} md={4} xs={12}>
          <ConardButton conardVariant="dark" onClick={() => history.push('/entry-and-parking/monitoring/history')}>
            {t('mainMenu.pages.history.title')}
          </ConardButton>
        </Grid>
        <Grid className={classes.gridItem} item md={4} xs={12}>
          <TruckMonitoringTable
            logs={pageLogs?.content?.filter((log) => log.checkpoint === TruckMonitoringLogDtoCheckpointEnum._1)}
            checkpoint={TruckMonitoringLogDtoCheckpointEnum._1}
            handleShowDialog={handleShowDialog}
          />
        </Grid>
        <Grid className={classes.gridItem} item md={4} xs={12}>
          <TruckMonitoringTable
            logs={pageLogs?.content?.filter((log) => log.checkpoint === TruckMonitoringLogDtoCheckpointEnum._2)}
            checkpoint={TruckMonitoringLogDtoCheckpointEnum._2}
            handleShowDialog={handleShowDialog}
          />
        </Grid>
        <Grid className={classes.gridItem} item md={4} xs={12}>
          <TruckMonitoringTable
            logs={pageLogs?.content?.filter((log) => log.checkpoint === TruckMonitoringLogDtoCheckpointEnum._3)}
            checkpoint={TruckMonitoringLogDtoCheckpointEnum._3}
            handleShowDialog={handleShowDialog}
          />
        </Grid>
      </Grid>
      {showDialog && (
        <TruckMonitoringDialog
          open={showDialog}
          truckLog={selectedLog}
          checkpoint={selectedCheckpoint}
          handleClose={() => setShowDialog(false)}
          handleCreateUpdate={handleCreateUpdate}
        />
      )}
    </>
  )
}
export default TruckMonitoringPage
