export enum Teu {
  ISO_20G1 = 1,
  ISO_22R1 = 1,
  ISO_22U1 = 1,
  ISO_22P4 = 1,
  ISO_42R0 = 2,
  ISO_42U1 = 2,
  ISO_42P4 = 2,
  ISO_22G0 = 1,
  ISO_22G1 = 1,
  ISO_25G0 = 1,
  ISO_22R0 = 1,
  ISO_22U0 = 1,
  ISO_22K2 = 1,
  ISO_42G0 = 2,
  ISO_42G1 = 2,
  ISO_42R1 = 2,
  ISO_42U0 = 2,
  ISO_42P1 = 2,
  ISO_45G0 = 2,
  ISO_45G1 = 2,
  ISO_45R1 = 2,
  ISO_45U1 = 2,
  ISO_L5G0 = 2,
  ISO_L5G1 = 2,
  SB = 2,
  STANDARD = 2.4,
  MEGA = 2.4,
  XM = 1,
  XXM = 1,
  XL = 1,
  XXL = 1,
  XXL_LIGHT = 1,
  XXL_MTL = 1,
  P20 = 1,
  P22 = 1,
  P28 = 1,
  TC23 = 1.5,
  MT = 1,
  RT_ORE = 1,
  GRAINTAINER = 1,
  XML = 1,
  HT_M = 1,
  HT_XXL = 1,
  AT_OT = 1,
  AT_HT = 1,
  CITYTAINER = 1,
  XXL_SD = 1,
  CHEMIETAINER = 1,
  CEMTAINER = 1,
}
