import { AxiosPromise } from 'axios'
import { InvoiceApi, InvoiceDataRequestDto } from '../api'
import myAxios from '../config/axios/axios'
import { cleanObject } from '../utils/utils'

let api: InvoiceApi

export const setInvoiceApiConfiguration = () => {
  api = new InvoiceApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const invoiceService = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  generateInvoice: (invoice: InvoiceDataRequestDto, options?: any): AxiosPromise => {
    return api.invoiceGenerateInvoiceDataFile(cleanObject(invoice), options)
  },
}

export default invoiceService
