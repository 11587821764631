import React, { FC, useEffect, useState } from 'react'
import { TrainDto } from '../../api'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { alpha, createStyles, Grid, Paper, Typography } from '@material-ui/core'
import ConardButton from '../ConardButton'
import { ForwardToInbox } from '@mui/icons-material'
import veronaService from '../../services/VeronaService'
import VeronaSentDialog from '../dialogs/VeronaSentDialog'
import processedFileService from '../../services/ProcessedFileService'
import { format } from 'date-fns'

const useStyles = makeStyles((theme) =>
  createStyles({
    gridItem: {
      marginBottom: '10px',
    },
    paper: {
      backgroundColor: alpha(theme.palette.secondary.light, 0.3),
      padding: '2vh 2vw 2vh 2vw',
      width: '100%',
    },
  })
)

interface VeronaBoxProps {
  train: TrainDto
}

const VeronaBox: FC<VeronaBoxProps> = (props) => {
  const { train } = props
  const { t } = useTranslation()
  const classes = useStyles()

  const [showDialog, setShowDialog] = useState(false)
  const [latestMessageDate, setLatestMessageDate] = useState<string | undefined>(undefined)

  const sendTrainToVerona = () => {
    veronaService
      .sendVeronaTrain(train.id ?? -1)
      .then(() => {
        setShowDialog(true)
        getLatestMessage()
      })
      .catch()
  }

  const getLatestMessage = () => {
    processedFileService
      .findLatestVeronaMessage(train.id ?? -1)
      .then((response) => {
        setLatestMessageDate(response.data.processed ?? undefined)
      })
      .catch()
  }

  useEffect(() => {
    getLatestMessage()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Paper elevation={0} className={classes.paper}>
        <Grid container>
          <Grid item xs={12} className={classes.gridItem}>
            <Typography variant="h4" color="primary">
              {t('pages.gateOut.headers.verona.title')}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            {t('pages.gateOut.headers.verona.latestMessage') +
              (!!latestMessageDate
                ? format(new Date(latestMessageDate!), 'dd.MM.yyyy, HH:mm')
                : t('pages.gateOut.headers.verona.notSent'))}
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <ConardButton
              conardVariant="light"
              startIcon={<ForwardToInbox />}
              disabled={!!latestMessageDate}
              onClick={sendTrainToVerona}
            >
              {t('pages.gateOut.buttons.send')}
            </ConardButton>
          </Grid>
        </Grid>
      </Paper>
      <VeronaSentDialog showDialog={showDialog} setShowDialog={setShowDialog} />
    </>
  )
}

export default VeronaBox
