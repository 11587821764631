import React from 'react'
import { CreateDriverPage } from '../carrier/drivers-page/CreateDriverPage'
import { useParams } from 'react-router-dom'

export const CreateDriverByManager = () => {
  const { id: carrierId } = useParams<{
    id: string
  }>()

  return <CreateDriverPage carrierId={Number(carrierId)} />
}
