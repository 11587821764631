import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ConardButton from '../../../components/ConardButton'
import AddIcon from '@material-ui/icons/Add'

import ConardProgressBar from '../../../components/ConardProgressBar'
import { DeleteAllDialog } from '../../drivers/DeleteAllDialog'
import carrierService from '../../../services/CarrierService'
import { Order } from '../../../enums/Order'
import { PageDriverDto } from '../../../api'

const useStyles = makeStyles(() =>
  createStyles({
    newUserButton: {
      width: '300px',
      minWidth: '200px',
      height: '50px',
      minHeight: '50px',
      textAlign: 'center',
      margin: '10px',
      float: 'right',
      borderRadius: 12,
    },
    buttonsWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      borderRadius: 12,
      maxWidth: '200px',
      marginLeft: '10px',
    },
    buttonsCell: {
      width: '500px',
    },
  })
)

interface HeadCell {
  id: string
  label: string
}

interface DriversListProps {
  carrierId: number

  onAddDriver: () => void
  onEditDriver: (driverId: number) => void
}

export const DriversList: FC<DriversListProps> = ({ onEditDriver, carrierId, onAddDriver }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [orderBy, setOrderBy] = useState<string>('name')
  const [order, setOrder] = useState<Order>(Order.Asc)
  const [pageSize, setPageSize] = useState(50)
  const [page, setPage] = useState(0)

  const [drivers, setDrivers] = useState<PageDriverDto | null>()
  const [loaded, setLoaded] = useState<boolean>(false)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined)

  const headCells: HeadCell[] = [
    { id: 'name', label: t('entity.driver.fields.driverName') },
    { id: 'frontLicensePlate', label: t('entity.driver.fields.frontLicensePlate') },
    { id: 'rearLicensePlate', label: t('entity.driver.fields.rearLicensePlate') },
  ]

  const currentSort: Array<string> | undefined = useMemo(() => {
    if (orderBy) {
      const currentOrder: string = order !== undefined ? order : 'asc'
      return [orderBy + ',' + currentOrder]
    }

    return undefined
  }, [order, orderBy])

  const handleSort = (property: string) => () => {
    const isAsc = orderBy === property && order === Order.Asc
    setOrder(isAsc ? Order.Desc : Order.Asc)
    setOrderBy(property)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value))
    setPage(0)
  }

  const handleDeleteDriver = (driverId: number) => {
    const driver = drivers?.content?.find((x) => x.id === driverId)

    if (driver?.id) {
      setSelectedId(driverId)
      setShowDialog(true)
    }
  }

  const handleDialogYes = async () => {
    setShowDialog(false)

    if (selectedId) {
      const driver = drivers?.content?.find((x) => x.id === selectedId)

      if (driver?.id) {
        if (!carrierId) {
          return
        }

        try {
          await carrierService.removeDriver(carrierId, driver.id)
          await fetchPageData()
        } catch (err) {
          console.error(err)
        }
      }
      setSelectedId(undefined)
    }
  }

  const fetchPageData = useCallback(async () => {
    if (!carrierId) {
      return
    }

    const response = await carrierService.getAllDrivers(carrierId, page, pageSize, currentSort)

    if (response.data) {
      setDrivers(response.data)
    }

    setLoaded(true)
  }, [carrierId, currentSort, page, pageSize])

  useEffect(() => {
    try {
      fetchPageData()
    } catch (err) {
      console.error(err)
    }
  }, [fetchPageData])

  return (
    <div style={{ display: !loaded ? 'none' : undefined }}>
      <Typography variant="h4" color="primary">
        {t('mainMenu.pages.drivers.title')}
      </Typography>
      <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={3}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <ConardButton
            className={classes.newUserButton}
            conardVariant="light"
            startIcon={<AddIcon />}
            onClick={onAddDriver}
          >
            {t('any.buttons.add')}
          </ConardButton>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper variant="outlined">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={handleSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {drivers?.content?.map((driver) => (
                    <TableRow key={driver.id}>
                      <TableCell component="th" scope="row">
                        {driver.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {driver.frontLicensePlate?.licensePlate}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {driver.rearLicensePlate?.licensePlate}
                      </TableCell>
                      <TableCell component="th" scope="row" className={classes.buttonsCell}>
                        <Box className={classes.buttonsWrapper}>
                          <ConardButton
                            className={classes.button}
                            conardVariant="light"
                            onClick={() => onEditDriver(driver.id ?? 0)}
                          >
                            {t('any.buttons.edit')}
                          </ConardButton>
                          <ConardButton
                            className={classes.button}
                            conardVariant="red"
                            onClick={() => handleDeleteDriver(driver.id ?? 0)}
                          >
                            {t('any.buttons.remove')}
                          </ConardButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}

                  {drivers?.content?.length === 0 && (
                    <TableRow key="noData">
                      <TableCell component="th" scope="row" colSpan={7}>
                        {t('pages.common.table.noData')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <ConardProgressBar showBar={!drivers} />
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 150]}
              component="div"
              count={drivers?.totalElements ?? 0}
              rowsPerPage={pageSize}
              labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
      <DeleteAllDialog showDialog={showDialog} onClose={() => setShowDialog(false)} onYes={handleDialogYes} />
    </div>
  )
}
