import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'
import ConardButton from '../ConardButton'
import { MarkEmailReadOutlined } from '@mui/icons-material'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
      fontSize: '5rem',
    },
    dialogIcon: {
      color: theme.palette.primary.main,
      margin: '20px',
    },
  })
)

interface VeronaSentDialogProps {
  showDialog: boolean
  setShowDialog: (showDialog: boolean) => void
}
const VeronaSentDialog: FC<VeronaSentDialogProps> = ({ showDialog, setShowDialog }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Dialog open={showDialog}>
      <DialogTitle>{t('pages.gateOut.dialog.verona.title')}</DialogTitle>
      <DialogContent>
        <Grid container className={classes.container}>
          <MarkEmailReadOutlined fontSize="inherit" className={classes.dialogIcon} />
          <Typography>{t('pages.gateOut.dialog.verona.content')}</Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ConardButton conardVariant="dark" onClick={() => setShowDialog(false)}>
          {t('any.buttons.close')}
        </ConardButton>
      </DialogActions>
    </Dialog>
  )
}

export default VeronaSentDialog
