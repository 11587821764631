import React, { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ShipownerDto } from '../../../api'
import { Mode } from '../../../enums/Mode'
import shipownerService from '../../../services/ShipownerService'
import ShipownerForm from './ShipownerForm'

interface UrlParams {
  id?: string
}

const ShipownerEditPage: FC = () => {
  const { id } = useParams<UrlParams>()
  const idNumber = parseInt(id ?? '-1', 10)
  const [loaded, setLoaded] = useState(false)
  const [shipowner, setShipowner] = useState<ShipownerDto | undefined>(undefined)

  useEffect(() => {
    shipownerService
      .findById(idNumber)
      .then((response) => {
        setShipowner(response.data)
        setLoaded(true)
      })
      .catch(() => {
        setLoaded(true)
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return <>{loaded && <ShipownerForm mode={Mode.Update} shipowner={shipowner} />}</>
}
export default ShipownerEditPage
