import { Box, makeStyles } from '@material-ui/core'
import React, { FC } from 'react'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import { Warehouse } from '@mui/icons-material'

const useStyles = makeStyles({
  box: {
    maxwWidth: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

interface TransitionCardIconsProps {
  gateIn: boolean
}

export const TransitionCardIcons: FC<TransitionCardIconsProps> = ({ gateIn }) => {
  const classes = useStyles()

  return (
    <div>
      {gateIn ? (
        <Box className={classes.box}>
          <LocalShippingIcon fontSize="large" color="action" />
          <ArrowRightAltIcon fontSize="large" color="action" />
          <Warehouse fontSize="large" color="action" />{' '}
        </Box>
      ) : (
        <Box className={classes.box}>
          <Warehouse fontSize="large" color="action" />
          <ArrowRightAltIcon fontSize="large" color="action" />
          <LocalShippingIcon fontSize="large" color="action" />
        </Box>
      )}
    </div>
  )
}
