import { Button, createStyles, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DateRangeIcon from '@material-ui/icons/DateRange'
import { format } from 'date-fns'
import React, { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ConfigContext } from '../../context/ConfigContext'

const useStyles = makeStyles((theme) =>
  createStyles({
    containerGrid: {
      width: '100%',
    },
    containerGridItem: {
      width: '80%',
    },
    calendarIcon: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
      fontSize: 40,
      color: theme.palette.primary.main,
    },
    navigationButtonGridItem: {
      width: '33%',
    },
    navigationButton: {
      height: '10vh',
      minHeight: '150px',
      width: '100%',
      '&.MuiButton-contained.Mui-disabled': {
        backgroundColor: theme.palette.grey.A100,
      },
    },
  })
)

const GateOutDashboard: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  const { nightGateOut } = useContext(ConfigContext)

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.containerGrid}
      spacing={8}
    >
      <Grid item className={classes.containerGridItem}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
          <Grid item>
            <DateRangeIcon className={classes.calendarIcon} />
          </Grid>
          <Grid item>
            <Typography variant="h4" color="primary">
              {format(new Date(), 'dd.MM.yyyy, HH:mm')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.containerGridItem}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={6}>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.navigationButton}
              onClick={() => history.push('/gate-out/single-list')}
            >
              <Typography variant="h5">{t('pages.gateOut.buttons.container')}</Typography>
            </Button>
          </Grid>

          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.navigationButton}
              onClick={() => history.push('/gate-out/coparn')}
            >
              <Typography variant="h5">{t('pages.gateOut.buttons.coparn')}</Typography>
            </Button>
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.navigationButton}
              onClick={() => history.push('/gate-out/coparn-empty/create')}
            >
              <Typography variant="h5">{t('pages.gateOut.buttons.coparnEmpty')}</Typography>
            </Button>
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.navigationButton}
              onClick={() => history.push('/gate-out/coparn-empty/delete')}
            >
              <Typography variant="h5">{t('pages.gateOut.buttons.deleteCoparnEmpty')}</Typography>
            </Button>
          </Grid>
          {nightGateOut && (
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.navigationButton}
                onClick={() => history.push('/gate-out/planned')}
              >
                <Typography variant="h5">{t('pages.gateOut.buttons.plannedGateOut')}</Typography>
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default GateOutDashboard
