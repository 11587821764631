import React from 'react'
import { Switch, SwitchProps, makeStyles, createStyles, Box, Typography } from '@material-ui/core'

export type ConardSwitchSmallProps = SwitchProps & {
  label: string
  helperText?: string
  adrRid?: boolean
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 3,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: theme.palette.primary.main,
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
      },
    },
    switchBaseAdrRid: {
      '&$checked': {
        '& + $track': {
          backgroundColor: theme.palette.warning.main,
        },
      },
    },
    thumb: {
      width: 20,
      height: 20,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[200],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},

    // Custom
    wrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: theme.spacing(2),
    },
  })
)

export const ConardSwitchSmall = ({ label, helperText, adrRid, ...switchProps }: ConardSwitchSmallProps) => {
  const classes = useStyles()
  return (
    <Box>
      <Box className={classes.wrapper}>
        <Typography variant="caption">{label}</Typography>
        <Switch
          focusVisibleClassName={classes.focusVisible}
          disableRipple
          classes={{
            root: classes.root,
            switchBase: adrRid ? `${classes.switchBase} ${classes.switchBaseAdrRid}` : classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
          {...switchProps}
        />
      </Box>
      {helperText && <span>{helperText}</span>}
    </Box>
  )
}
