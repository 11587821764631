import React, { FC } from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { ContainerIcon } from '../../../assets/icons/ContainerIcon'
import { ContainerBox } from './ContainerBox'
import { useTranslation } from 'react-i18next'
import { ContainerDto, ContainerDtoContainerTypeEnum } from '../../../api'
import { SemitrailerIcon } from '../../../assets/icons/SemitrailerIcon'
import { FormViewMode } from '../../../enums/FormViewMode'

const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridItem: {
    width: '100%',
  },
  container: {
    scale: 12,
    color: theme.palette.primary.main,
  },
  semitrailer: {
    margin: '1rem 0',
  },
  containerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '4rem 0 4rem 0',
  },
  iluCodeDiv: {
    position: 'absolute',
    marginTop: '-15px',
    backgroundColor: theme.palette.background.default,
    zIndex: 2,
    width: '12.5rem',
    height: '4rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'center',
  },
  iluCodeDivSemitrailer: {
    marginTop: '-40px',
  },
  label: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  iluCode: {
    fontSize: '26px',
    fontWeight: 750,
  },
}))

interface ContainerFormProps {
  container: ContainerDto | undefined
  formMode: FormViewMode
}

export const ContainerForm: FC<ContainerFormProps> = ({ container, formMode }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container className={classes.grid}>
      <Grid item className={classes.containerWrapper}>
        {container?.containerType === ContainerDtoContainerTypeEnum.ShippingContainer ? (
          <ContainerIcon className={classes.container} />
        ) : (
          <SemitrailerIcon className={`${classes.container} ${classes.semitrailer}`} />
        )}

        <div
          className={
            container?.containerType === ContainerDtoContainerTypeEnum.ShippingContainer
              ? classes.iluCodeDiv
              : `${classes.iluCodeDiv} ${classes.iluCodeDivSemitrailer}`
          }
        >
          <Typography className={classes.label}>{t('form.containerForm.iluCode')}</Typography>
          <Typography className={classes.iluCode}>{container?.iluCode}</Typography>
        </div>
      </Grid>

      <Grid item className={classes.gridItem}>
        <ContainerBox container={container} formMode={formMode} />
      </Grid>
    </Grid>
  )
}
