import React, { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@material-ui/core'
import { TrainDto, TrainDtoTrainStateEnum } from '../../../api'
import trainService from '../../../services/TrainService'
import { Alert } from '@material-ui/lab'
import StoreForm, { StoreInputParams } from './StoreForm'
import trainGateInService from '../../../services/TrainGateInService'

const useStyles = makeStyles(() =>
  createStyles({
    alert: {
      margin: 'auto',
      width: '50vw',
      marginTop: '20vh',
    },
  })
)

interface UrlParamTypes {
  id?: string
}

const TrainStoreForm: FC = () => {
  const { id } = useParams<UrlParamTypes>()
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  const [train, setTrain] = useState<TrainDto | null>(null)
  const [loaded, setLoaded] = useState(false)

  const loadTrain = () => {
    trainService
      .findById(parseInt(id ?? '-1', 10))
      .then((response) => {
        setTrain(response.data)
      })
      .catch()
      .finally(() => {
        setLoaded(true)
      })
  }

  const onStore = (storeInputParam: StoreInputParams) => {
    if (train?.trainState === TrainDtoTrainStateEnum.IncomingAccepted) {
      trainGateInService
        .storeContainersOnTrain(train.id ?? -1, storeInputParam)
        .then(() => {
          history.replace('/success/train/' + train.id + '/store')
        })
        .catch()
    }
  }

  useEffect(() => {
    loadTrain()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {loaded && (
        <>
          {train === null && (
            <Alert severity="error" className={classes.alert}>
              {t('be.error.entity.notFound')}
            </Alert>
          )}
          {train !== null && (
            <StoreForm title={t('pages.gateIn.headers.storeFromTrain')} name={train.name} onStore={onStore} />
          )}
        </>
      )}
    </>
  )
}

export default TrainStoreForm
