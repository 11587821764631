import { UploadedFileApi, UploadedFileDto } from '../api'
import myAxios from '../config/axios/axios'
import { AxiosPromise } from 'axios'

let api: UploadedFileApi

export const setUploadedFileApiConfiguration = () => {
  api = new UploadedFileApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const uploadedFileService = {
  assignSignetPhoto: (transitionId: number, signetPhoto: UploadedFileDto): AxiosPromise => {
    return api.uploadedFileAssignSignetPhoto(transitionId, signetPhoto)
  },

  getSignetPhoto: (transitionId: number): AxiosPromise<UploadedFileDto> => {
    return api.uploadedFileGetSignetPhoto(transitionId)
  },

  deleteSignetPhoto: (transitionId: number): AxiosPromise => {
    return api.uploadedFileDeleteSignetPhoto(transitionId)
  },
}

export default uploadedFileService
