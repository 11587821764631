import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OperatorTransitionSearchDto } from '../../api'

export interface OperatorTransitionSearchState {
  operatorTransitionSearch: OperatorTransitionSearchDto | undefined
}

export const operatorTransitionSearchInitialState: OperatorTransitionSearchState = {
  operatorTransitionSearch: undefined,
}

export const operatorTransitionSearchSlice = createSlice({
  name: 'operatorTransition',
  initialState: operatorTransitionSearchInitialState,
  reducers: {
    setSearchOperatorTransition(
      state: OperatorTransitionSearchState,
      action: PayloadAction<OperatorTransitionSearchDto | undefined>
    ) {
      state.operatorTransitionSearch = action.payload
    },
  },
})

export const { setSearchOperatorTransition } = operatorTransitionSearchSlice.actions
