import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TransitionSearchDto } from '../../api'

export interface TransitionSearchState {
  transitionSearch: TransitionSearchDto | undefined
  transitionSearchTrainContent: TransitionSearchDto | undefined
}

export const transitionSearchInitialState: TransitionSearchState = {
  transitionSearch: undefined,
  transitionSearchTrainContent: undefined,
}

export const transitionSearchSlice = createSlice({
  name: 'transition',
  initialState: transitionSearchInitialState,
  reducers: {
    setSearchTransition(state: TransitionSearchState, action: PayloadAction<TransitionSearchDto | undefined>) {
      state.transitionSearch = action.payload
    },
  },
})

export const { setSearchTransition } = transitionSearchSlice.actions
