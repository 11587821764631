import React, { FC, ReactElement } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { UserDtoRoleEnum } from '../api'
import { useConardAuth } from '../hooks/useConardAuth'

interface AuthorizedRouteProps extends RouteProps {
  roles?: UserDtoRoleEnum[]
  requiredAll?: boolean
  enabled?: boolean
}

const AuthorizedRoute: FC<AuthorizedRouteProps> = (props): ReactElement => {
  const { roles = [], requiredAll = false, enabled = true, ...routeProps } = props

  if (routeProps.component && routeProps.children) {
    throw new Error('Authorized route - cannot use component prop and children prop! Just use only one...')
  }

  const auth = useConardAuth()

  if (!auth.isAuthenticated()) {
    window.location.reload()
    return <Redirect to="/login" />
  }
  let accessGranted = false

  if (enabled) {
    if (roles.length) {
      if (requiredAll) {
        accessGranted = true
        roles.forEach((role) => {
          if (role != auth.getRole()) {
            accessGranted = false
          }
        })
      } else {
        roles.forEach((role) => {
          if (role == auth.getRole()) {
            accessGranted = true
          }
        })
      }
    }
  } else {
    accessGranted = false
  }

  if (!accessGranted) {
    return <Redirect to="/unauthorized" />
  }

  if (routeProps.component) {
    const Comp = routeProps.component

    return <Route {...routeProps} component={Comp} />
  }

  return <Route {...routeProps}>{routeProps.children}</Route>
}

export default AuthorizedRoute
