import React, { FC, useState } from 'react'
import { LayoutContainerDto, LayoutContainerDtoContainerTypeEnum } from '../../api'
import { Grid, IconButton } from '@mui/material'
import { createStyles, makeStyles } from '@material-ui/core'
import { cleanShippingContainerType } from '../../utils/utils'
import ConardTooltip from '../../components/ConardTooltip'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { useTranslation } from 'react-i18next'
import { CustomsIcon } from './CustomsIcon'
import { SearchOutlined } from '@material-ui/icons'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import { Output } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'

const useStyles = makeStyles((theme) =>
  createStyles({
    adrRidIcon: {
      color: theme.palette.warning.main,
    },
    customsClearedIcon: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontFamily: 'Arial',
    },
    customsNotClearedIcon: {
      color: theme.palette.error.main,
    },
    item: {
      border: '1px solid #807979',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
    },
    containerMoved: {
      opacity: '0.5',
      transition: '0',
      '&:hover': {
        backgroundColor: 'none',
        boxShadow: 'none',
      },
    },
    grid: {
      width: '100px',
    },
    icons: {
      paddingTop: '3px',
      width: '45px',
    },
    containerGrid: {
      width: '150px',
    },
    iluCode: {
      display: 'flex',
      justifyContent: 'center',
    },
    containerInfo: {
      display: 'flex',
      flexDirection: 'column',
    },
    companyName: {
      display: 'flex',
      justifyContent: 'center',
    },
  })
)

interface SemitrailerProps {
  container: LayoutContainerDto
}
export const Semitrailer: FC<SemitrailerProps> = ({ container }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  const [containerMoved, setContainerMoved] = useState(false)

  const handleClickDetail = (id: number | undefined) => {
    history.push(`/search/transition/detail/${id}`)
  }

  const handleClickGateOut = (id: number | undefined) => {
    history.push(`/gate-out/single-container/${id}`)
  }

  const handleClickMove = () => {
    setContainerMoved(true)
  }

  return (
    <Grid
      container
      className={clsx(`${classes.item}`, {
        [classes.containerMoved]: containerMoved,
      })}
    >
      <Grid item className={classes.grid}>
        <Grid container>
          {container.containerType === LayoutContainerDtoContainerTypeEnum.ShippingContainer ? (
            <Grid item>{cleanShippingContainerType(container.shippingContainerType)}</Grid>
          ) : (
            <Grid item>{container.semitrailerType}</Grid>
          )}
        </Grid>
      </Grid>

      <Grid item className={classes.icons}>
        {!!container.adrRid && (
          <ConardTooltip title={t('pages.terminalLayout.tooltip.ADR/RID')}>
            <WarningAmberOutlinedIcon className={classes.adrRidIcon} fontSize="small" />
          </ConardTooltip>
        )}
        <CustomsIcon container={container} />
      </Grid>

      <Grid item className={classes.containerGrid}>
        <Grid container className={classes.containerInfo}>
          <Grid item className={classes.iluCode}>
            <strong>{container.iluCode}</strong>
          </Grid>
          <Grid item className={classes.companyName}>
            {container.containerType === LayoutContainerDtoContainerTypeEnum.ShippingContainer ? (
              <i>{container.shipownerName}</i>
            ) : (
              <i>{container.companyName}</i>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container direction="row">
          <ConardTooltip title={t('pages.terminalLayout.tooltip.detail')}>
            <IconButton onClick={() => handleClickDetail(container.id)}>
              <SearchOutlined fontSize="small" />
            </IconButton>
          </ConardTooltip>
          <ConardTooltip title={t('pages.terminalLayout.tooltip.move')}>
            <IconButton onClick={handleClickMove}>
              <LowPriorityIcon fontSize="small" />
            </IconButton>
          </ConardTooltip>
          <ConardTooltip title={t('pages.terminalLayout.tooltip.gateOut')}>
            <IconButton onClick={() => handleClickGateOut(container.id)} disabled={containerMoved}>
              <Output fontSize="small" />
            </IconButton>
          </ConardTooltip>
        </Grid>
      </Grid>
    </Grid>
  )
}
