import { CompanyApi, CompanyDto, CompanySearchDto, PageCompanyDto } from '../api'
import { AxiosPromise } from 'axios'
import myAxios from '../config/axios/axios'
import { cleanObject } from '../utils/utils'

let api: CompanyApi

export const setCompanyApiConfiguration = () => {
  api = new CompanyApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const companyService = {
  findAll: (
    page: number | undefined,
    pageSize: number | undefined,
    orderBy: string,
    order: string
  ): AxiosPromise<PageCompanyDto> => {
    return api.companyFindAll(page, pageSize, [orderBy + ',' + order])
  },

  findAllParentCompanies: (): AxiosPromise<PageCompanyDto> => {
    return api.companySearch(undefined, undefined, undefined, { showOnlyParents: true })
  },

  findAllCompanies: (): AxiosPromise<PageCompanyDto> => {
    return api.companySearch(undefined, undefined, undefined, { showOnlyParents: false })
  },

  search: (
    page: number,
    pageSize: number,
    orderBy: string,
    order: string,
    companySearchDto: CompanySearchDto
  ): AxiosPromise<PageCompanyDto> => {
    return api.companySearch(page, pageSize, [orderBy + ',' + order], cleanObject(companySearchDto))
  },

  findById: (id: number): AxiosPromise<CompanyDto> => {
    return api.companyFindById(id)
  },

  create: (company: CompanyDto): AxiosPromise<CompanyDto> => {
    return api.companyCreate(cleanObject(company))
  },

  update: (id: number | undefined, company: CompanyDto): AxiosPromise<CompanyDto> => {
    return api.companyUpdate(id || -1, cleanObject(company))
  },

  delete: (companyId: number | undefined): AxiosPromise<void> => {
    return api.companyDelete(companyId || -1)
  },
}

export default companyService
