import React, { FC, useEffect, useState } from 'react'
import { createStyles, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { LockOpen } from '@material-ui/icons'
import GateComponent from './GateComponent'
import GateService from '../../services/GateService'
import { GateDto, GateDtoGateTypeEnum } from '../../api'

const useStyles = makeStyles((theme) =>
  createStyles({
    containerGrid: {
      width: '100%',
    },
    containerGridItem: {
      width: '80%',
    },
    lockIcon: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
      fontSize: 40,
      color: theme.palette.primary.main,
    },
    navigationButtonGridItem: {
      width: '33%',
    },
    warning: {
      color: theme.palette.error.main,
    },
  })
)

const GateControlPage: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [gateDevices, setGateDevices] = useState<GateDto[]>([])
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    getGateControl()

    const interval = setInterval(() => {
      getGateControl()
    }, 1000)

    // reset back on exit
    return () => {
      clearInterval(interval)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getGateControl = () => {
    GateService.findAll().then((response) => {
      setGateDevices(response.data)
      setLoaded(true)
    })
  }

  const tollgateComponent = (gate: GateDto) => {
    let label: string = t('gateControl.tollgate') + ' ' + (gate.gateNumber ? gate.gateNumber + ' - ' : '')

    switch (gate.gateType) {
      case GateDtoGateTypeEnum.In:
        label += t('gateControl.in')
        break
      case GateDtoGateTypeEnum.Out:
        label += t('gateControl.out')
        break
    }

    return (
      <GateComponent key={gate.id} deviceNumber={gate.asparkingNumber ?? 0} isOpen={gate.open} headerLabel={label} />
    )
  }

  return (
    <>
      {loaded && (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.containerGrid}
          spacing={8}
        >
          <Grid item className={classes.containerGridItem}>
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
              <Grid item>
                <LockOpen className={classes.lockIcon} />
              </Grid>
              <Grid item>
                <Typography variant="h4" color="primary">
                  {t('pages.entryAndParking.gate.title')}
                </Typography>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography variant="h5" align="center" className={classes.warning}>
                  <i>{t('gateControl.warning')}</i>
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item className={classes.containerGridItem}>
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={6}>
              <GateComponent deviceNumber={-1} headerLabel={t('gateControl.allGates')} allGates />

              {gateDevices
                .filter((gate) => gate.gateType === GateDtoGateTypeEnum.Main)
                .map((gate) => {
                  return (
                    <GateComponent
                      key={gate.id}
                      deviceNumber={gate.asparkingNumber ?? 0}
                      headerLabel={t('gateControl.main')}
                      isOpen={gate.open}
                      mainGate
                    />
                  )
                })}

              {gateDevices
                .filter((gate) => gate.gateType !== GateDtoGateTypeEnum.Main)
                .sort((a, b) => a.asparkingNumber - b.asparkingNumber)
                .map((gate) => {
                  return tollgateComponent(gate)
                })}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default GateControlPage
