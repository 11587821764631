import React, { FC, useEffect, useState } from 'react'
import { CompanyDto, TransitionSearchListSearchDto } from '../../api'
import { useTranslation } from 'react-i18next'
import { RootState, useAppDispatch } from '../../redux/store'
import { useSelector } from 'react-redux'
import { FormProvider, useForm } from 'react-hook-form'
import { Grid, MenuItem, createStyles, makeStyles } from '@material-ui/core'
import { RHFTextField } from '../../components/shared/rhf/RHFTextField'
import ConardSelect from '../../components/ConardSelect'
import companyService from '../../services/CompanyService'
import { RHFDatePicker } from '../../components/shared/rhf/RHFDateTimePicker'
import ConardButton from '../../components/ConardButton'
import { setSearchTransition } from '../../redux/transition/TransitionSearchSlice'

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      marginTop: '4vh',
      width: '100%',
      marginLeft: '15px',
      marginRight: '15px',
    },
    searchButton: {
      width: '13vw',
      minWidth: '300px',
      height: '5vh',
      minHeight: '50px',
      textAlign: 'center',
      float: 'right',
      marginBottom: '20px',
      marginLeft: '10px',
    },
    button: {
      borderRadius: 12,
    },
  })
)

type PlannedGateOutFilterFormValues = {
  container: {
    iluCode: string
    company: {
      id: number | undefined
    }
  }
  semitrailerLicencePlate: string
  transitionDateTimeFrom: Date | null
  transitionDateTimeTo: Date | null
  repleted: boolean | null
  truckLicencePlate: string
}

const initialValues: PlannedGateOutFilterFormValues = {
  container: {
    iluCode: '',
    company: {
      id: undefined,
    },
  },
  semitrailerLicencePlate: '',
  transitionDateTimeFrom: null,
  transitionDateTimeTo: null,
  repleted: null,
  truckLicencePlate: '',
}

interface PlannedGateOutFilterProps {
  searchFilter: (data: TransitionSearchListSearchDto | undefined) => void
  blockSearch: boolean
}

export const PlannedGateOutFilter: FC<PlannedGateOutFilterProps> = ({ searchFilter, blockSearch }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const classes = useStyles()

  const transitions = useSelector<RootState, TransitionSearchListSearchDto | undefined>(
    (state) => state.transitionSearchListSearch.transitionSearch
  )

  const [companyId, setCompanyId] = useState<number | undefined>(transitions?.container?.company?.id ?? undefined)
  const [repleted, setRepleted] = useState<boolean | undefined>(transitions?.repleted ?? undefined)
  const [companies, setCompanies] = useState<CompanyDto[] | undefined>(undefined)

  const formMethods = useForm<PlannedGateOutFilterFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
  })

  const getAllCompanies = () => {
    companyService.findAll(0, 100, '', '').then((response) => {
      setCompanies(response.data.content)
    })
  }

  const onChangeCompany = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCompanyId(event.target.value as unknown as number)
  }

  const onChangeRepleted = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRepleted((event.target.value as unknown as number) === -1 ? undefined : event.target.value === 'true')
  }

  const clearFilter = () => {
    formMethods.reset(initialValues)
    setCompanyId(undefined)
    setRepleted(undefined)
    dispatch(setSearchTransition(undefined))
    searchFilter(undefined)
  }

  useEffect(() => {
    formMethods.setValue('transitionDateTimeFrom', transitions?.transitionDateTimeFrom ?? null)
    formMethods.setValue('transitionDateTimeTo', transitions?.transitionDateTimeTo ?? null)
    formMethods.setValue('container.iluCode', transitions?.container?.iluCode)
    formMethods.setValue('semitrailerLicencePlate', transitions?.container?.semitrailerLicencePlate)
    formMethods.setValue('truckLicencePlate', transitions?.truckLicencePlate)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getAllCompanies()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FormProvider {...formMethods}>
      <form className={classes.form} onSubmit={formMethods.handleSubmit(searchFilter)}>
        <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={2}>
          <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
            <RHFTextField name="container.iluCode" label={t('pages.gateIn.form.iluCode.label')} />
          </Grid>
          <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
            <ConardSelect
              roundedSquare
              id="container.company.id"
              name="container.company.id"
              label={t('entity.company.fields.name')}
              defaultValue={companyId ?? -1}
              value={companyId ?? -1}
              onChange={(event) => onChangeCompany(event)}
              control={formMethods.control}
            >
              <MenuItem value={-1}>{t('pages.common.select.all')}</MenuItem>
              {companies?.map((company) => (
                <MenuItem key={company.id} value={company.id}>
                  {company.name}
                </MenuItem>
              ))}
            </ConardSelect>
          </Grid>
          <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
            <RHFTextField name="semitrailerLicencePlate" label={t('pages.gateIn.form.semitrailerLicencePlate.label')} />
          </Grid>
          <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
            <RHFDatePicker
              name="transitionDateTimeFrom"
              label={t('pages.searchTransition.datePicker.labels.transitionDateFrom')}
              placeholder={t('pages.searchTransition.datePicker.labels.transitionDateFrom')}
              ampm={false}
            />
          </Grid>
          <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
            <RHFDatePicker
              name="transitionDateTimeTo"
              label={t('pages.searchTransition.datePicker.labels.transitionDateTo')}
              placeholder={t('pages.searchTransition.datePicker.labels.transitionDateTo')}
              ampm={false}
            />
          </Grid>
          <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
            <ConardSelect
              roundedSquare
              id="repleted"
              name="repleted"
              label={`${t('pages.gateIn.form.repleted.checked')} / ${t('pages.gateIn.form.repleted.unchecked')}`}
              defaultValue={repleted ?? -1}
              value={repleted ?? -1}
              onChange={(event) => onChangeRepleted(event)}
              control={formMethods.control}
            >
              <MenuItem value={-1}>{t('pages.common.select.all')}</MenuItem>
              <MenuItem value="true">{t('pages.gateIn.form.repleted.checked')}</MenuItem>
              <MenuItem value="false">{t('pages.gateIn.form.repleted.unchecked')}</MenuItem>
            </ConardSelect>
          </Grid>
          <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
            <RHFTextField name="truckLicencePlate" label={t('pages.gateIn.form.truckLicencePlate.label')} />
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className={classes.searchButton}>
              <ConardButton className={classes.button} conardVariant="light" type="submit" disabled={blockSearch}>
                {t('any.buttons.search')}
              </ConardButton>
            </div>
            <div className={classes.searchButton}>
              <ConardButton
                className={classes.button}
                conardVariant="transparent"
                type="reset"
                onClick={clearFilter}
                disabled={blockSearch}
              >
                {t('any.buttons.reset')}
              </ConardButton>
            </div>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}
