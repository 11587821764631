import React, { FC, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core'
import ConardButton from '../../components/ConardButton'
import { useTranslation } from 'react-i18next'
import { TransitionDto } from '../../api'
import { DropzoneArea } from 'material-ui-dropzone'
import uploadDownloadApi from '../../services/UploadDownloadService'

const useStyles = makeStyles({
  dialogContent: {
    height: '340px',
  },
})

export interface CustomsFileUploadModalProps {
  transition?: TransitionDto
  onClose: () => void
  search: () => void
}

const CustomsFileUploadModal: FC<CustomsFileUploadModalProps> = (props) => {
  const { onClose, transition, search } = props
  const { t } = useTranslation()
  const [file, setFile] = useState<File | undefined>(undefined)
  const classes = useStyles()

  const confirmUpload = () => {
    if (!!file && !!transition) {
      uploadDownloadApi
        .uploadCustomsFile(transition?.id ?? -1, file)
        .then(() => {
          search()
        })
        .catch()
    }
    onClose()
  }

  const filesUploaded = (files: File[]) => {
    if (files.length > 0) {
      setFile(files[0])
    }
  }

  return (
    <Dialog open={!!transition} onClose={onClose} aria-labelledby="simple-dialog-title" maxWidth="md">
      <DialogTitle id="simple-dialog-title">{t('pages.customs.modal.dropzone.title')}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container direction="column" justifyContent="center" alignItems="center" spacing={3}>
          <Grid item>
            <DropzoneArea
              acceptedFiles={['.pdf']}
              dropzoneText={t('pages.customs.modal.dropzone.text')}
              showAlerts={false}
              filesLimit={1}
              useChipsForPreview
              showPreviewsInDropzone={false}
              showPreviews
              previewText={t('pages.customs.modal.dropzone.preview')}
              onChange={filesUploaded}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ConardButton conardVariant="transparent" onClick={onClose}>
          {t('any.buttons.back')}
        </ConardButton>
        <ConardButton disabled={!file} conardVariant="dark" onClick={confirmUpload}>
          {t('any.buttons.save')}
        </ConardButton>
      </DialogActions>
    </Dialog>
  )
}

export default CustomsFileUploadModal
