import React, { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AsparkingTicketDto } from '../../api'
import TicketEditForm from './TicketEditForm'
import ticketService from '../../services/TicketService'

interface UrlParamTypes {
  id?: string
}

const TicketUpdatePage: FC = () => {
  const { id } = useParams<UrlParamTypes>()

  const [ticket, setTicket] = useState<AsparkingTicketDto>()
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (id !== undefined) {
      ticketService
        .ticketFindById(Number.parseInt(id))
        .then((response) => {
          setTicket(response.data)
          setLoaded(true)
        })
        .catch(() => {
          setLoaded(true)
        })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return <>{loaded && <TicketEditForm ticket={ticket} />}</>
}

export default TicketUpdatePage
