import React, { FC, useEffect, useState } from 'react'
import { CarrierDto } from '../../api'
import { useParams } from 'react-router-dom'
import { Mode } from '../../enums/Mode'
import CarrierEditForm from './CarrierEditForm'
import carrierService from '../../services/CarrierService'

interface UrlParamTypes {
  id?: string
}

const CarrierUpdatePage: FC = () => {
  const { id } = useParams<UrlParamTypes>()

  const [carrier, setCarrier] = useState<CarrierDto>()
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (id !== undefined) {
      carrierService
        .carrierFindById(Number.parseInt(id))
        .then((response) => {
          setCarrier(response.data)
          setLoaded(true)
        })
        .catch(() => {
          setLoaded(true)
        })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return <>{loaded && <CarrierEditForm mode={Mode.Update} carrier={carrier} />}</>
}

export default CarrierUpdatePage
