import {
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { PagePriceListDto, PriceListDto } from '../../../api'
import ConardButton from '../../../components/ConardButton'
import { Order } from '../../../enums/Order'
import priceListService from '../../../services/PriceListService'
import ConardProgressBar from '../../../components/ConardProgressBar'

interface HeadCell {
  id: keyof PriceListDto
  label: string
}

const useStyles = makeStyles({
  table: {
    minWidth: '80vh',
  },
  gridContainer: {
    marginTop: '8vh',
  },
  typographyLabel: {
    width: '50vw',
  },
  noData: {
    textAlign: 'center',
  },
  editTable: {
    width: '7vw',
  },
})

const PriceListPage: FC = () => {
  const { t } = useTranslation()
  const prefix = process.env.REACT_APP_CONARD_TERMINAL
  const classes = useStyles()
  const history = useHistory()

  const [priceList, setPriceList] = useState<PagePriceListDto | undefined>(undefined)
  const [order, setOrder] = useState(Order.Asc)
  const [orderBy, setOrderBy] = useState('id')

  const headCells: HeadCell[] = [
    { id: 'itemCode', label: t('entity.priceList.fields.itemCode') },
    { id: 'minAmount', label: t('entity.priceList.fields.minAmount') },
    { id: 'price', label: t('entity.priceList.fields.price') },
  ]

  const onSort = (newOrderBy: string) => () => {
    setOrderBy(newOrderBy)
    setOrder(orderBy === newOrderBy ? (order === Order.Asc ? Order.Desc : Order.Asc) : Order.Asc)
  }

  const loadData = () => {
    priceListService
      .findAll(undefined, undefined, orderBy, order)
      .then((response) => {
        setPriceList(response.data)
      })
      .catch()
  }

  useEffect(() => {
    loadData()
  }, [order, orderBy]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Typography variant="h4" color="primary" className={classes.typographyLabel}>
        {t('pages.priceList.labels.title')}
      </Typography>
      <Grid container direction="row" justifyContent="center" alignItems="flex-start" className={classes.gridContainer}>
        <Grid item lg={12} md={11} sm={11} xs={11}>
          <Paper variant="outlined">
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                        {headCell.id !== 'itemCode' && (
                          <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : Order.Asc}
                            onClick={onSort(headCell.id)}
                          >
                            {headCell.label}
                          </TableSortLabel>
                        )}
                        {headCell.id === 'itemCode' && <>{headCell.label}</>}
                      </TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {priceList?.content &&
                    priceList?.content
                      ?.filter((invoiceType) => invoiceType.itemCode?.startsWith(prefix ?? ''))
                      .map((priceListItem) => (
                        <TableRow key={priceListItem.id}>
                          <TableCell>{t(`enum.priceListItemCode.${priceListItem.itemCode}`)}</TableCell>
                          <TableCell>{priceListItem.minAmount}</TableCell>
                          <TableCell>{priceListItem.price}</TableCell>
                          <TableCell className={classes.editTable}>
                            <ConardButton
                              conardVariant="light"
                              onClick={() => history.push('/expenses/price-list/update/' + priceListItem.id)}
                              startIcon={<EditIcon />}
                            >
                              {t('any.buttons.edit')}
                            </ConardButton>
                          </TableCell>
                        </TableRow>
                      ))}

                  {priceList?.content?.length === 0 && (
                    <TableRow>
                      <TableCell className={classes.noData}>{t('pages.common.table.noData')}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <ConardProgressBar showBar={!priceList} />
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default PriceListPage
