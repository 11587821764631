import { AxiosPromise } from 'axios'
import { PagePriceListDto, PriceListApi, PriceListDto } from '../api'
import myAxios from '../config/axios/axios'
import { cleanObject } from '../utils/utils'

let api: PriceListApi

export const setPriceListApiConfiguration = () => {
  api = new PriceListApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const priceListService = {
  findAll: (
    page: number | undefined,
    pageSize: number | undefined,
    orderBy: string,
    order: string
  ): AxiosPromise<PagePriceListDto> => {
    return api.priceListFindAll(page, pageSize, [orderBy + ',' + order])
  },
  findById: (id: number): AxiosPromise<PriceListDto> => {
    return api.priceListFindById(id)
  },
  update: (id: number | undefined, priceListDto: PriceListDto): AxiosPromise<PriceListDto> => {
    return api.priceListUpdate(id || -1, cleanObject(priceListDto))
  },
}

export default priceListService
