import { makeStyles, createStyles, Grid, alpha, Typography, MenuItem } from '@material-ui/core'
import React, { ChangeEvent, FC, useContext } from 'react'
import { Control, FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ConfigContext } from '../../context/ConfigContext'
import ConardButton from '../ConardButton'
import ConardSelect from '../ConardSelect'
import RemoveIcon from '@material-ui/icons/Remove'
import ConardTextField from '../ConardTextField'
import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles((theme) =>
  createStyles({
    positionButton: {
      marginTop: '-20px',
      width: '80%',
      marginLeft: '-5px',
    },
    storeInfoTitle: {
      marginBottom: '20px',
    },
    storePanelContainer: {
      margin: '3rem 0',
      backgroundColor: alpha(theme.palette.secondary.dark, 0.3),
      borderRadius: '8px',
      textAlign: 'center',
      minWidth: '290px',
      padding: '20px 0px 20px 0px',
    },
  })
)

interface ConardStoreProps {
  transitionPositionDisabled: boolean

  sectorLetterValue: string | undefined
  sectorColumnValue: number | undefined
  sectorRowValue: number | undefined
  sectorFloorValue: number | undefined

  sectorLetterDisabled: boolean

  errorSectorLetter: boolean | undefined
  errorSectorColumn: boolean | undefined
  errorSectorRow: boolean | undefined
  errorSectorFloor: boolean | undefined

  helperTextSectorLetter: React.ReactNode
  helperTextSectorColumn: React.ReactNode
  helperTextSectorRow: React.ReactNode
  helperTextSectorFloor: React.ReactNode

  controlSectorLetter: Control<FieldValues>
  controlSectorColumn: Control<FieldValues>
  controlSectorRow: Control<FieldValues>
  controlSectorFloor: Control<FieldValues>

  decrementColumnButtonDisabled: boolean
  decrementRowButtonDisabled: boolean
  incrementColumnButtonDisabled: boolean
  incrementRowButtonDisabled: boolean
  decrementFloorButtonDisabled: boolean
  incrementFloorButtonDisabled: boolean

  onChangeSectorLetter: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onColumnPositionDecrement: () => void
  onColumnPositionIncrement: () => void
  onRowPositionDecrement: () => void
  onRowPositionIncrement: () => void
  onFloorPositionIncrement: () => void
  onFloorPositionDecrement: () => void
}

export const ConardStore: FC<ConardStoreProps> = ({
  transitionPositionDisabled,
  sectorLetterDisabled,
  onChangeSectorLetter,
  errorSectorLetter,
  helperTextSectorLetter,
  controlSectorLetter,
  sectorLetterValue,
  decrementColumnButtonDisabled,
  onColumnPositionDecrement,
  controlSectorColumn,
  sectorColumnValue,
  errorSectorColumn,
  helperTextSectorColumn,
  incrementColumnButtonDisabled,
  onColumnPositionIncrement,
  decrementRowButtonDisabled,
  controlSectorRow,
  sectorRowValue,
  errorSectorRow,
  helperTextSectorRow,
  incrementRowButtonDisabled,
  decrementFloorButtonDisabled,
  controlSectorFloor,
  sectorFloorValue,
  errorSectorFloor,
  helperTextSectorFloor,
  incrementFloorButtonDisabled,
  onRowPositionDecrement,
  onRowPositionIncrement,
  onFloorPositionIncrement,
  onFloorPositionDecrement,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { coordinates } = useContext(ConfigContext)

  const sectorLetters = Array.from(Array(coordinates.sectorLetterCount))
    .map((e, i) => i + 65)
    .map((x) => String.fromCharCode(x))

  return (
    <Grid container direction="column" className={classes.storePanelContainer}>
      <Grid item>
        <Typography variant="h5" className={classes.storeInfoTitle}>
          {t('pages.gateIn.headers.storeInfo')}
        </Typography>
      </Grid>
      {!transitionPositionDisabled && (
        <>
          {coordinates.sectorLetterCount !== 0 && (
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <Grid item xl={5} lg={5} md={5} sm={5} xs={5}>
                <ConardSelect
                  id="sectorLetter"
                  name="sectorLetter"
                  label={t('pages.gateIn.headers.sector')}
                  disabled={sectorLetterDisabled}
                  value={sectorLetterValue}
                  onChange={(event) => onChangeSectorLetter(event)}
                  error={errorSectorLetter}
                  helperText={helperTextSectorLetter}
                  control={controlSectorLetter}
                >
                  <MenuItem value="NOT-SELECTED">{t('any.notSelected')}</MenuItem>
                  {sectorLetters.map((sector) => (
                    <MenuItem key={sector} value={sector}>
                      {sector}
                    </MenuItem>
                  ))}
                </ConardSelect>
              </Grid>
            </Grid>
          )}
          {coordinates.maxSectorColumn !== 0 && (
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                <ConardButton
                  conardVariant="light"
                  disabled={decrementColumnButtonDisabled}
                  onClick={onColumnPositionDecrement}
                  className={classes.positionButton}
                >
                  <RemoveIcon />
                </ConardButton>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                <ConardTextField
                  id="sectorColumn"
                  name="sectorColumn"
                  label={
                    coordinates.sectorLetterCount === 0
                      ? t('pages.gateIn.headers.sector')
                      : t('pages.gateIn.headers.sectorColumn')
                  }
                  InputLabelProps={{ shrink: true }}
                  control={controlSectorColumn}
                  value={sectorColumnValue}
                  disabled
                  error={errorSectorColumn}
                  helperText={helperTextSectorColumn}
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                <ConardButton
                  conardVariant="light"
                  disabled={incrementColumnButtonDisabled}
                  onClick={onColumnPositionIncrement}
                  className={classes.positionButton}
                >
                  <AddIcon />
                </ConardButton>
              </Grid>
            </Grid>
          )}
          {coordinates.maxSectorRow !== 0 && (
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                <ConardButton
                  conardVariant="light"
                  disabled={decrementRowButtonDisabled}
                  onClick={onRowPositionDecrement}
                  className={classes.positionButton}
                >
                  <RemoveIcon />
                </ConardButton>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                <ConardTextField
                  id="sectorRow"
                  name="sectorRow"
                  label={t('pages.gateIn.headers.sectorRow')}
                  InputLabelProps={{ shrink: true }}
                  control={controlSectorRow}
                  value={sectorRowValue}
                  disabled
                  error={errorSectorRow}
                  helperText={helperTextSectorRow}
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                <ConardButton
                  conardVariant="light"
                  disabled={incrementRowButtonDisabled}
                  onClick={onRowPositionIncrement}
                  className={classes.positionButton}
                >
                  <AddIcon />
                </ConardButton>
              </Grid>
            </Grid>
          )}
          {coordinates.maxSectorFloor !== 0 && (
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                <ConardButton
                  conardVariant="light"
                  disabled={decrementFloorButtonDisabled}
                  onClick={onFloorPositionDecrement}
                  className={classes.positionButton}
                >
                  <RemoveIcon />
                </ConardButton>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                <ConardTextField
                  id="sectorFloor"
                  name="sectorFloor"
                  label={t('pages.gateIn.headers.sectorFloor')}
                  InputLabelProps={{ shrink: true }}
                  control={controlSectorFloor}
                  value={sectorFloorValue}
                  disabled
                  error={errorSectorFloor}
                  helperText={helperTextSectorFloor}
                />
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                <ConardButton
                  conardVariant="light"
                  disabled={incrementFloorButtonDisabled}
                  onClick={onFloorPositionIncrement}
                  className={classes.positionButton}
                >
                  <AddIcon />
                </ConardButton>
              </Grid>
            </Grid>
          )}
        </>
      )}
      <Grid item>
        {transitionPositionDisabled && (
          <>
            {coordinates.sectorLetterCount !== 0 && (
              <Typography variant="h6">
                {t('pages.gateIn.headers.sector') + ': ' + (sectorLetterValue || t('pages.gateIn.headers.notStored'))}
              </Typography>
            )}
            {coordinates.maxSectorColumn !== 0 && (
              <Typography variant="h6">
                {(coordinates.sectorLetterCount === 0
                  ? t('pages.gateIn.headers.sector')
                  : t('pages.gateIn.headers.sectorColumn')) +
                  ': ' +
                  (sectorColumnValue || t('pages.gateIn.headers.notStored'))}
              </Typography>
            )}
            {coordinates.maxSectorRow !== 0 && (
              <Typography variant="h6">
                {t('pages.gateIn.headers.sectorRow') + ': ' + (sectorRowValue || t('pages.gateIn.headers.notStored'))}
              </Typography>
            )}
            {coordinates.maxSectorFloor !== 0 && (
              <Typography variant="h6">
                {t('pages.gateIn.headers.sectorFloor') +
                  ': ' +
                  (sectorFloorValue || t('pages.gateIn.headers.notStored'))}
              </Typography>
            )}
          </>
        )}
      </Grid>
    </Grid>
  )
}
