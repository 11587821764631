import React, { FC } from 'react'
import { ContainerDtoContainerTypeEnum, TrainDto, TrainWagonContentDto, TrainWagonDto, TransitionDto } from '../../api'
import { TransitionMode } from '../../enums/TransitionMode'
import ConardTransitionForm from '../../components/forms/ConardTransitionForm'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../redux/store'
import { setTrain } from '../../redux/train/trainSlice'
import { useHistory } from 'react-router-dom'
import trainService from '../../services/TrainService'
import trainGateInService from '../../services/TrainGateInService'

const TrainSemitrailerGateInPage: FC = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const train = useSelector<RootState, TrainDto>((state) => state.train.train)
  const currentWagon = useSelector<RootState, TrainWagonDto | null>((state) => state.train.currentWagon)
  const currentWagonContent = useSelector<RootState, TrainWagonContentDto | null>(
    (state) => state.train.currentWagonContent
  )

  const onTransitionSave = (transition: TransitionDto) => {
    if (currentWagon && currentWagonContent) {
      trainGateInService
        .loadOnWagon(currentWagon.id ?? -1, currentWagonContent?.wagonContentOrder ?? -1, transition)
        .then((response) => {
          if (response.data) {
            const updatedTrain = trainService.addWagonContent(train, currentWagon, response.data)
            dispatch(setTrain(updatedTrain))
          }
        })
        .finally(history.goBack)
    }
  }

  return (
    <ConardTransitionForm
      containerType={ContainerDtoContainerTypeEnum.Semitrailer}
      transitionMode={TransitionMode.TRAIN}
      onTransitionSave={onTransitionSave}
      transitionDateTime={train.departureDateTime}
      onCancel={() => {
        history.goBack()
      }}
    />
  )
}

export default TrainSemitrailerGateInPage
