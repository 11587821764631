import { CdCargoApi, CdCargoTemplateDto, PageCdCargoTemplateDto } from '../api'
import myAxios from '../config/axios/axios'
import { AxiosPromise } from 'axios'
import { cleanObject } from '../utils/utils'

let api: CdCargoApi

export const setCdCargoExportConfigurationApiConfiguration = () => {
  api = new CdCargoApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const cdCargoExportConfigurationService = {
  findAll: (
    page: number | undefined,
    pageSize: number | undefined,
    orderBy: string,
    order: string
  ): AxiosPromise<PageCdCargoTemplateDto> => {
    return api.cdCargoFindAll(page, pageSize, [orderBy + ',' + order])
  },

  findById: (id: number, options?: never): AxiosPromise<CdCargoTemplateDto> => {
    return api.cdCargoFindById(id, options)
  },

  create: (cdCargoTemplateDto: CdCargoTemplateDto): AxiosPromise<CdCargoTemplateDto> => {
    return api.cdCargoCreate(cleanObject(cdCargoTemplateDto))
  },

  update: (id: number | undefined, cdCargoTemplateDto: CdCargoTemplateDto): AxiosPromise<CdCargoTemplateDto> => {
    return api.cdCargoUpdate(id || -1, cleanObject(cdCargoTemplateDto))
  },
}

export default cdCargoExportConfigurationService
