import { BuildProperties } from '../../api'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface AppInfoState {
  info: BuildProperties
}

export const appInfoInitialState: AppInfoState = {
  info: {
    version: '1.0',
  },
}

export const appInfoSlice = createSlice({
  name: 'appInfo',
  initialState: appInfoInitialState,
  reducers: {
    setAppInfo(state: AppInfoState, action: PayloadAction<BuildProperties>) {
      state.info = action.payload
    },
  },
})

export const { setAppInfo } = appInfoSlice.actions
