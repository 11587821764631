import React, { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { createStyles, Theme, Typography, useTheme, withWidth, WithWidth } from '@material-ui/core'

import { ReactComponent as ImageContainerDrawingFront } from '../../assets/images/svg/container-drawing-front.svg'
import { ReactComponent as ImageContainerDrawingTop } from '../../assets/images/svg/container-drawing-top-door.svg'
import { ReactComponent as ImageContainerDrawingSideRight } from '../../assets/images/svg/container-drawing-side-right.svg'
import { ReactComponent as ImageContainerDrawingSideLeft } from '../../assets/images/svg/container-drawing-side-left.svg'
import { ReactComponent as ImageContainerDrawingBack } from '../../assets/images/svg/container-drawing-back.svg'

import { ReactComponent as ImageSemitrailerDrawingFront } from '../../assets/images/svg/semitrailer-drawing-front.svg'
import { ReactComponent as ImageSemitrailerDrawingTop } from '../../assets/images/svg/semitrailer-drawing-top-door.svg'
import { ReactComponent as ImageSemitrailerDrawingRight } from '../../assets/images/svg/semitrailer-drawing-right.svg'
import { ReactComponent as ImageSemitrailerDrawingLeft } from '../../assets/images/svg/semitrailer-drawing-left.svg'
import { ReactComponent as ImageSemitrailerDrawingBack } from '../../assets/images/svg/semitrailer-drawing-back.svg'

import { ContainerDtoContainerTypeEnum, DamageLogEntryDto, DamageLogEntryDtoSideEnum } from '../../api'
import { renderToStaticMarkup } from 'react-dom/server'

interface StyleProps {
  error: boolean
}

const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    container: {
      paddingTop: '3vh',
    },
    canvas: (props) => ({
      border: props.error ? `2px solid ${theme.palette.error.main}` : '',
    }),
  })
)

export type Coordinate = {
  x: number
  y: number
}

interface DamageLogSideDrawProps extends WithWidth {
  side: DamageLogEntryDtoSideEnum
  onClick: (damageLogEntry: DamageLogEntryDto) => void
  inside: boolean
  painted: boolean
  error?: boolean
  containerType: ContainerDtoContainerTypeEnum
}

const DamageLogSideDraw: FC<DamageLogSideDrawProps> = ({
  side,
  onClick,
  inside,
  painted,
  error,
  containerType,
  width,
}) => {
  const classes = useStyles({ error: error ?? false })
  const { t } = useTranslation()
  const theme = useTheme()

  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const [originalWidth, setOriginalWidth] = useState<number | null>(null)
  const [originaHeight, setOriginalHeight] = useState<number | null>(null)

  useEffect(() => {
    if (!painted) {
      repaint()
    }
  }, [painted]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    repaint()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    repaint()
  }, [width]) // eslint-disable-line react-hooks/exhaustive-deps

  const repaint = (onComplete?: (context: CanvasRenderingContext2D) => void) => {
    const canvas = canvasRef.current
    const context = canvas?.getContext('2d')

    if (context) {
      let imageSrc = <></>

      switch (side) {
        case DamageLogEntryDtoSideEnum.Front: {
          imageSrc =
            containerType === ContainerDtoContainerTypeEnum.ShippingContainer ? (
              <ImageContainerDrawingFront />
            ) : (
              <ImageSemitrailerDrawingFront />
            )
          break
        }
        case DamageLogEntryDtoSideEnum.Back: {
          imageSrc =
            containerType === ContainerDtoContainerTypeEnum.ShippingContainer ? (
              <ImageContainerDrawingBack />
            ) : (
              <ImageSemitrailerDrawingBack />
            )
          break
        }
        case DamageLogEntryDtoSideEnum.Left: {
          imageSrc =
            containerType === ContainerDtoContainerTypeEnum.ShippingContainer ? (
              <ImageContainerDrawingSideLeft />
            ) : (
              <ImageSemitrailerDrawingLeft />
            )
          break
        }
        case DamageLogEntryDtoSideEnum.Right: {
          imageSrc =
            containerType === ContainerDtoContainerTypeEnum.ShippingContainer ? (
              <ImageContainerDrawingSideRight />
            ) : (
              <ImageSemitrailerDrawingRight />
            )
          break
        }
        case DamageLogEntryDtoSideEnum.Top: {
          imageSrc =
            containerType === ContainerDtoContainerTypeEnum.ShippingContainer ? (
              <ImageContainerDrawingTop />
            ) : (
              <ImageSemitrailerDrawingTop />
            )
          break
        }
        default: {
          break
        }
      }

      const svgString = renderToStaticMarkup(imageSrc)
      const svgDOM = new DOMParser().parseFromString(svgString, 'image/svg+xml')
      const svgStringSerialized = new XMLSerializer().serializeToString(svgDOM.documentElement)

      const image = new Image()
      image.src =
        'data:image/svg+xml;base64,' +
        btoa(svgStringSerialized.replace(/fill="currentColor"/, `fill="${theme.palette.primary.main}"`))

      image.onload = function () {
        let scale = 1
        switch (width) {
          case 'lg': {
            scale = 0.75
            break
          }
          case 'md': {
            scale = 0.75
            break
          }
          case 'sm': {
            scale = 0.5
            break
          }
          case 'xs': {
            scale = 0.5
            break
          }
        }
        setOriginalWidth(image.width)
        setOriginalHeight(image.height)

        image.width = image.width * scale
        image.height = image.height * scale

        context.canvas.width = image.width
        context.canvas.height = image.height

        context.drawImage(image, 0, 0, image.width, image.height)

        if (onComplete) {
          onComplete(context)
        }
      }
    }
  }

  const onPaint = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    const coordinate = getCoordinate(event)
    drawDot(coordinate)

    //get coordinates of original picture
    if (originalWidth && originaHeight && canvasRef.current) {
      const widthRatio = coordinate.x / canvasRef.current.width
      const heightRatio = coordinate.y / canvasRef.current.height

      onClick({
        side: side,
        inside: inside,
        positionX: originalWidth * widthRatio,
        positionY: originaHeight * heightRatio,
        photo: '',
      })
    } else {
      onClick({
        side: side,
        inside: inside,
        positionX: coordinate.x,
        positionY: coordinate.y,
        photo: '',
      })
    }
  }

  const getCoordinate = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>): Coordinate => {
    if (!canvasRef.current) {
      return { x: 0, y: 0 }
    }

    return { x: event.pageX - canvasRef.current.offsetLeft, y: event.pageY - canvasRef.current.offsetTop }
  }

  const drawDot = (center: Coordinate) => {
    repaint((context) => {
      context.fillStyle = theme.palette.error.main
      context.beginPath()
      context.arc(center.x, center.y, 10, 0, 2 * Math.PI, true)
      context.fill()
    })
  }

  return (
    <div className={classes.container}>
      <Typography variant="h6" color="primary">
        {t(`enum.damageLogEntrySide.${side}`)}
      </Typography>
      <canvas ref={canvasRef} onClick={onPaint} className={classes.canvas} />
    </div>
  )
}

export default withWidth()(DamageLogSideDraw)
