import {
  CdCargoRequestDto,
  PageTransitionSearchListDto,
  TrainDto,
  TrainGateOutApi,
  TransitionSearchListSearchDto,
} from '../api'
import myAxios from '../config/axios/axios'
import { AxiosPromise } from 'axios'
import { cleanObject } from '../utils/utils'

let api: TrainGateOutApi

export const setTrainGateOutApiConfiguration = () => {
  api = new TrainGateOutApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const trainGateOutService = {
  findFinishedGateInForTrainGateOut: (
    searchDto: TransitionSearchListSearchDto,
    page?: number,
    size?: number,
    sort?: string[]
  ): AxiosPromise<PageTransitionSearchListDto> => {
    return api.trainGateOutFindFinishedGateInForTrainGateOut(searchDto, page, size, sort)
  },

  createTrain: (trainDto: TrainDto): AxiosPromise<TrainDto> => {
    return api.trainGateOutCreateTrain(cleanObject(trainDto))
  },

  downloadCimDocument: (cdCargoRequest: CdCargoRequestDto): AxiosPromise => {
    return api.trainGateOutGenerateCimDocument(cdCargoRequest, { responseType: 'blob' })
  },

  sendCimDocument: (cdCargoRequest: CdCargoRequestDto): AxiosPromise => {
    return api.trainGateOutSendCimDocument(cdCargoRequest, { responseType: 'blob' })
  },

  downloadCimTrainFile: (id: number, trainId: number): AxiosPromise => {
    return api.trainGateOutDownloadCimTrainFile(id, trainId, { responseType: 'blob' })
  },

  downloadWagonReport: (id: number, trainId: number): AxiosPromise => {
    return api.trainGateOutDownloadWagonReport(id, trainId, { responseType: 'blob' })
  },
  createTrainModel: (id: number, file: File) => {
    return api.trainGateOutUploadFile(id, file)
  },

  changeUnseatedTransitions: (trainId: number, transitionIds: number[]): AxiosPromise<TrainDto> => {
    return api.trainGateOutChangeUnseatedTransitions(trainId, transitionIds)
  },

  assignCoparnUnseatedTransitions: (
    trainId: number,
    transitionIds: number[],
    coparnId: number
  ): AxiosPromise<TrainDto> => {
    return api.trainGateOutCreateGateOutTransitionsCoparnUnseated(trainId, coparnId, transitionIds)
  },

  createCoparnTrainWithUnseatedTransitions: (coparnId: number, transitionIds: number[]): AxiosPromise<TrainDto> => {
    return api.trainGateOutCreateTrainWithUnseatedTransitions(coparnId, transitionIds)
  },

  removeWagon: (trainId: number, wagonId: number): AxiosPromise<TrainDto> => {
    return api.trainGateOutRemoveWagon(trainId, wagonId)
  },
}

export default trainGateOutService
