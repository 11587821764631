import React, { FC, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { createStyles, Grid, MenuItem, Typography } from '@material-ui/core'
import { DemoApi, DemoDto, PageDemoDto } from '../../api'
import myAxios from '../../config/axios/axios'
import { useForm } from 'react-hook-form'
import ConardTextField from '../../components/ConardTextField'
import ConardSelect from '../../components/ConardSelect'
import ConardSwitch from '../../components/ConardSwitch'
import ConardDateTimePicker from '../../components/ConardDateTimePicker'
import ConardButton from '../../components/ConardButton'
import { cleanObject } from '../../utils/utils'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'

const useStyles = makeStyles(() =>
  createStyles({
    inputContainer: {
      width: '30vw',
      minWidth: '300px',
      textAlign: 'center',
    },
  })
)

const DemoPage: FC = () => {
  const api = new DemoApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)

  const { t } = useTranslation()
  const classes = useStyles()

  const [demos, setDemos] = useState<PageDemoDto | null>(null)

  const yupSchema = yup.object().shape({
    textField: yup
      .string()
      .required(t('pages.demo.validation.textField.required'))
      .min(3, t('pages.demo.validation.textField.min', { min: 3 })),
    enumField: yup.mixed().notOneOf(['NOT-SELECTED'], t('pages.demo.validation.enumField.required')),
    // enumField: yup.mixed().test("exclusiveName", t("pages.demo.validation.enumField.required"), (value) => {
    //     return value !== "NOT-SELECTED"
    // })
    // enumField: yup.mixed().test({
    //     name: 'max',
    //     exclusive: true,
    //     message: t("pages.demo.validation.enumField.required"),
    //     test: (value) => value !== "NOT-SELECTED",
    // })
  })

  const { handleSubmit, control, errors } = useForm<DemoDto>({
    resolver: yupResolver(yupSchema),
  })

  useEffect(() => {
    api
      .demoSearch(undefined, undefined, undefined)
      .then((response) => {
        setDemos(response.data)
      })
      .catch()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = (data: DemoDto) => {
    api
      .demoSearch(undefined, undefined, undefined, cleanObject(data))
      .then((response) => {
        setDemos(response.data)
      })
      .catch()
  }

  const pingError = () => {
    myAxios.get('/demo/error').catch()
  }

  return (
    <>
      <Typography variant="h4" color="primary">
        Vyhledávání uživatelů
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={5}
          style={{ height: '100%', marginTop: '15vh' }}
        >
          <Grid item>
            <div className={classes.inputContainer}>
              <ConardTextField
                name="textField"
                label={t('pages.demo.labels.textField')}
                control={control}
                error={!!errors.textField}
                helperText={errors.textField ? errors.textField.message : ''}
                onChange={(event) => console.log(event.target.value)}
              />
            </div>
          </Grid>
          <Grid item>
            <div className={classes.inputContainer}>
              <ConardSelect
                name="enumField"
                label={t('pages.demo.labels.enumField')}
                defaultValue="OPTION1"
                control={control}
                error={!!errors.enumField}
                helperText={errors.enumField ? errors.enumField.message : ''}
                onChange={(event) => console.log(event.target.value)}
              >
                <MenuItem value="NOT-SELECTED">All</MenuItem>
                <MenuItem value="OPTION1">Option1</MenuItem>
                <MenuItem value="OPTION2">Option2</MenuItem>
              </ConardSelect>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.inputContainer}>
              <ConardSwitch
                name="booleanField"
                defaultChecked
                checkedLabel="Yes"
                uncheckedLabel="No"
                onChange={(value) => console.log(value)}
                control={control}
              />
            </div>
          </Grid>
          <Grid item>
            <div className={classes.inputContainer}>
              <ConardDateTimePicker name="dateField" control={control} onChange={(value) => console.log(value)} />
            </div>
          </Grid>
          <Grid item>
            <div className={classes.inputContainer}>
              <ConardButton conardVariant="dark" type="submit">
                Submit
              </ConardButton>
            </div>
          </Grid>
          <Grid item>
            <Typography>Hello! We have {demos?.content?.length} demos!</Typography>
          </Grid>
          <Grid item>
            <ConardButton conardVariant="light" onClick={pingError}>
              Error test
            </ConardButton>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default DemoPage
