import React, { ChangeEvent, FC, useContext, useMemo, useState } from 'react'
import {
  ContainerSimpleDtoContainerTypeEnum,
  TrainDto,
  TrainDtoTrainStateEnum,
  TrainWagonContentDto,
  TrainWagonDto,
  TransitionSearchListDtoQualityTypeEnum,
} from '../../api'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ConardTrainWagonContentForm from './ConardTrainWagonContentForm'
import { MoveDirection, OrderWagonsFrom } from './ConardTrainForm'
import { useTranslation } from 'react-i18next'
import { TrainMode } from '../../enums/TrainMode'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../redux/store'
import { addUnseatedContent, setTrain } from '../../redux/train/trainSlice'
import trainGateInService from '../../services/TrainGateInService'
import trainGateOutService from '../../services/TrainGateOutService'
import ConardButton from '../ConardButton'
import { ConfigContext } from '../../context/ConfigContext'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      minHeight: '200px',
      minWidth: '120px',
      marginBottom: '4vh',
    },
    removeGridItem: {
      width: '10vh',
      textAlign: 'center',
      lineHeight: '200px',
    },
    containerGridItem: {
      flexGrow: 1,
      height: '100%',
      display: 'grid',
    },
    wagonPaper: {
      height: '100%',
      border: `1px solid ${theme.palette.info.main}`,
    },
    wagonDetailGridContainer: {
      height: '100%',
    },
    wagonDetailGridItem: {
      flexGrow: 1,
      height: '100%',
    },
    wagonOrderContainer: {
      width: '50px',
      height: '50px',
      backgroundColor: theme.palette.primary.main,
      textAlign: 'center',
      lineHeight: '50px',
    },
    wagonMovementGridItem: {
      height: '100%',
    },
    wagonMovementGridContainer: {
      height: '100%',
    },
    wagonMovementUpGridItem: {
      height: '50%',
      lineHeight: '115px',
      borderLeft: `1px solid ${theme.palette.info.main}`,
      borderBottom: `1px solid ${theme.palette.info.main}`,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    wagonMovementDownGridItem: {
      height: '50%',
      lineHeight: '115px',
      borderLeft: `1px solid ${theme.palette.info.main}`,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    wagonGridContainer: {
      height: '100%',
    },
    wagonGridItemHeader: {
      width: '100%',
    },
    wagonGridItemContent: {
      width: '100%',
      flexGrow: 1,
    },
    wagonHeaderGridItem: {
      paddingLeft: '1vw',
    },
    contentGrid: {
      height: '100%',
      padding: theme.spacing(3),
    },
    contentGridItem: {
      padding: '10px',
    },
    IconButton: {
      width: '100%',
    },
    wagonContainerSum: {
      paddingLeft: '1vw',
    },
    wagonOverloaded: {
      align: 'center',
      color: theme.palette.error.main,
    },
  })
)

interface ConardTrainWagonProps {
  wagonModel: TrainWagonDto
  mode: TrainMode
  onCreateContainer?: (trainWagon: TrainWagonDto, trainWagonContent: TrainWagonContentDto) => void
  onCreateSemitrailer?: (trainWagon: TrainWagonDto, trainWagonContent: TrainWagonContentDto) => void
  onEditContainer?: (trainWagon: TrainWagonDto, trainWagonContent: TrainWagonContentDto) => void
  onEditSemitrailer?: (trainWagon: TrainWagonDto, trainWagonContent: TrainWagonContentDto) => void
  onLoadContainer?: (
    trainWagon: TrainWagonDto,
    trainWagonContent: TrainWagonContentDto,
    transitionId: number,
    qualityType: TransitionSearchListDtoQualityTypeEnum | undefined
  ) => void
  onFill: (trainWagon: TrainWagonDto, trainWagonContent: TrainWagonContentDto) => void
  onRemoveWagon: (onRemove: () => void) => void
}

const ConardTrainWagonForm: FC<ConardTrainWagonProps> = (props) => {
  const {
    wagonModel,
    mode,
    onCreateContainer,
    onCreateSemitrailer,
    onEditContainer,
    onEditSemitrailer,
    onLoadContainer,
    onFill,
    onRemoveWagon,
  } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const { eightContainersToTrain } = useContext(ConfigContext)

  const train = useSelector<RootState, TrainDto>((state) => state.train.train)
  const dispatch = useAppDispatch()

  const [isNameInEdit, setIsNameInEdit] = useState(false)
  const [wagonName, setWagonName] = useState('')
  const [wagonNameError, setWagonNameError] = useState(false)

  const [isWeightInEdit, setIsWeightInEdit] = useState(false)
  const [wagonWeight, setWagonWeight] = useState(wagonModel.weight ? wagonModel.weight : undefined)
  const [wagonWeightError, setWagonWeightError] = useState(false)

  const [isCapacityInEdit, setIsCapacityInEdit] = useState(false)
  const [wagonCapacity, setWagonCapacity] = useState(wagonModel.wagonCapacity ? wagonModel.wagonCapacity : undefined)
  const [wagonCapacityError, setWagonCapacityError] = useState(false)

  const [isLengthInEdit, setIsLengthInEdit] = useState(false)
  const [wagonLength, setWagonLength] = useState(wagonModel.wagonLength ? wagonModel.wagonLength : undefined)
  const [wagonLengthError, setWagonLengthError] = useState(false)

  const [isAxleCountInEdit, setIsAxleCountInEdit] = useState(false)
  const [axleCount, setAxleCount] = useState<number | undefined>(wagonModel.axleCount)
  const [wagonAxleCountError, setWagonAxleCountError] = useState(false)

  const [showRemoveDialog, setShowRemoveDialog] = useState(false)

  const isMiddleOverloaded = useMemo(() => {
    return (
      (mode === TrainMode.GATE_OUT ||
        (mode === TrainMode.SEARCH &&
          new Set([
            TrainDtoTrainStateEnum.OutgoingDispatched,
            TrainDtoTrainStateEnum.OutgoingLoaded,
            TrainDtoTrainStateEnum.OutgoingPlanned,
            TrainDtoTrainStateEnum.OutgoingPrepared,
          ]).has(train.trainState ?? TrainDtoTrainStateEnum.IncomingAccepted))) &&
      wagonModel.content?.length === 4 &&
      wagonModel.content.every((content) => content.transition.container.teu === 1) &&
      wagonModel.content.reduce(
        (prev, curr) =>
          curr.wagonContentOrder === 1 || curr.wagonContentOrder === 2
            ? prev + (curr.transition.grossWeight ?? 0)
            : prev,
        0
      ) > 40000
    )
  }, [mode, train.trainState, wagonModel.content])

  const onCloseWagonNameEdit = () => {
    setIsNameInEdit(false)
    setWagonName(wagonModel.name)
    setWagonNameError(false)
  }

  const onNameSaveRequest = () => {
    if (!wagonName) {
      setWagonNameError(true)
    } else {
      const newArray = train.wagons?.filter((wagon) => {
        return wagonModel !== wagon
      })

      newArray?.splice(wagonModel.wagonOrder - OrderWagonsFrom, 0, {
        ...wagonModel,
        name: wagonName,
      })

      dispatch(
        setTrain({
          ...train,
          wagons: [...(newArray ?? [])],
        })
      )

      setIsNameInEdit(false)
      setWagonNameError(false)
    }
  }

  const onCloseWagonWeightEdit = () => {
    setIsWeightInEdit(false)
    setWagonWeight(wagonModel.weight)
    setWagonWeightError(false)
  }

  const onWeightSaveRequest = () => {
    if (!!wagonWeight && wagonWeight <= 0) {
      setWagonWeightError(true)
    } else {
      const newArray = train.wagons?.filter((wagon) => {
        return wagonModel !== wagon
      })

      newArray?.splice(wagonModel.wagonOrder - OrderWagonsFrom, 0, {
        ...wagonModel,
        weight: !isNaN(wagonWeight ?? 0) ? wagonWeight : undefined,
      })

      dispatch(
        setTrain({
          ...train,
          wagons: [...(newArray ?? [])],
        })
      )

      setIsWeightInEdit(false)
      setWagonWeightError(false)
    }
  }

  const onCloseWagonCapacityEdit = () => {
    setIsCapacityInEdit(false)
    setWagonCapacity(wagonModel.wagonCapacity)
    setWagonCapacityError(false)
  }

  const onWagonCapacitySaveRequest = () => {
    if (!!wagonCapacity && wagonCapacity <= 0) {
      setWagonCapacityError(true)
    } else {
      const newArray = train.wagons?.filter((wagon) => {
        return wagonModel !== wagon
      })

      newArray?.splice(wagonModel.wagonOrder - OrderWagonsFrom, 0, {
        ...wagonModel,
        wagonCapacity: !isNaN(wagonCapacity ?? 0) ? wagonCapacity : undefined,
      })

      dispatch(
        setTrain({
          ...train,
          wagons: [...(newArray ?? [])],
        })
      )

      setIsCapacityInEdit(false)
      setWagonCapacityError(false)
    }
  }

  const onCloseWagonLengthEdit = () => {
    setIsLengthInEdit(false)
    setWagonLength(wagonModel.wagonLength)
    setWagonLengthError(false)
  }

  const onWagonLengthSaveRequest = () => {
    if (!!wagonLength && wagonLength <= 0) {
      setWagonLengthError(true)
    } else {
      const newArray = train.wagons?.filter((wagon) => {
        return wagonModel !== wagon
      })

      newArray?.splice(wagonModel.wagonOrder - OrderWagonsFrom, 0, {
        ...wagonModel,
        wagonLength: !isNaN(wagonLength ?? 0) ? wagonLength : undefined,
      })

      dispatch(
        setTrain({
          ...train,
          wagons: [...(newArray ?? [])],
        })
      )

      setIsLengthInEdit(false)
      setWagonLengthError(false)
    }
  }

  const onChangeSetNewWagonLength = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setWagonLength(Number.parseFloat(e.target.value))
  }

  const onCloseWagonAxleCountEdit = () => {
    setIsAxleCountInEdit(false)
    setAxleCount(wagonModel.axleCount)
    setWagonAxleCountError(false)
  }

  const onWagonAxleCountSaveRequest = () => {
    if (!!axleCount && axleCount <= 0) {
      setWagonAxleCountError(true)
    } else {
      const newArray = train.wagons?.filter((wagon) => {
        return wagonModel !== wagon
      })

      newArray?.splice(wagonModel.wagonOrder - OrderWagonsFrom, 0, {
        ...wagonModel,
        axleCount: !isNaN(axleCount ?? 0) ? axleCount : undefined,
      })

      dispatch(
        setTrain({
          ...train,
          wagons: [...(newArray ?? [])],
        })
      )

      setIsAxleCountInEdit(false)
      setWagonAxleCountError(false)
    }
  }

  const onChangeSetNewWagonAxleCount = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setAxleCount(Number.parseInt(e.target.value))
  }

  const onRemove = () => {
    //if wagon has id call remove wagon and setTrain from response
    if (wagonModel.id) {
      if (TrainMode.GATE_IN === mode) {
        trainGateInService.removeWagon(train.id ?? -1, wagonModel.id).then((response) => {
          dispatch(setTrain(response.data))
          setShowRemoveDialog(false)
        })
      }
      if (TrainMode.GATE_OUT === mode) {
        trainGateOutService.removeWagon(train.id ?? -1, wagonModel.id).then((response) => {
          dispatch(setTrain(response.data))
          setShowRemoveDialog(false)
        })
      }
    } else {
      //if wagon has not id remove it manually
      const remainingWagons = train.wagons?.filter((wagon) => {
        return wagonModel !== wagon
      })
      const newWagons: TrainWagonDto[] = []
      remainingWagons?.forEach((wagonModel, index) => {
        newWagons.push({
          ...wagonModel,
          wagonOrder: index + OrderWagonsFrom,
        })
      })
      dispatch(setTrain({ ...train, wagons: newWagons }))
      //retrieve transitions back as unseated
      if (mode === TrainMode.GATE_OUT) {
        wagonModel.content?.forEach((wc) => {
          if (wc.transition) {
            dispatch(addUnseatedContent(wc.transition))
          }
        })
      }
      setShowRemoveDialog(false)
    }
  }

  const onMove = (direction: MoveDirection) => {
    const currentIndex = train.wagons
      ? train.wagons.findIndex((component) => component.wagonOrder === wagonModel.wagonOrder)
      : -1
    const currentWagons = [...(train.wagons ?? [])]
    const removedItem = currentWagons.splice(currentIndex, 1)[0]

    if (direction === MoveDirection.UP) {
      currentWagons.splice(currentIndex - 1, 0, removedItem)
    } else {
      currentWagons.splice(currentIndex + 1, 0, removedItem)
    }

    const newWagons: TrainWagonDto[] = []
    currentWagons?.forEach((wagonModel, index) => {
      newWagons.push({
        ...wagonModel,
        wagonOrder: index + OrderWagonsFrom,
      })
    })

    dispatch(setTrain({ ...train, wagons: newWagons }))
  }

  const handleKeyDown = (action: () => void) => (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      action()
      e.preventDefault()
      e.nativeEvent.stopImmediatePropagation()
    }
  }

  const isTrainDispatched = TrainDtoTrainStateEnum.OutgoingDispatched === train.trainState

  const isTrainFinished = TrainDtoTrainStateEnum.IncomingFinished === train.trainState

  return (
    <>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" className={classes.container}>
        <Grid item xl={1} lg={1} md={1} sm={1} xs={1} className={classes.removeGridItem}>
          <IconButton
            onClick={() => setShowRemoveDialog(true)}
            disabled={
              new Set([TrainMode.SEARCH]).has(mode) || isTrainDispatched || (isTrainFinished && !!wagonModel.id)
            }
          >
            <DeleteOutlineIcon fontSize="large" />
          </IconButton>
        </Grid>
        <Grid item xl={11} lg={11} md={11} sm={11} xs={11} className={classes.containerGridItem}>
          <Paper square elevation={0} className={classes.wagonPaper}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
              className={classes.wagonDetailGridContainer}
            >
              <Grid item xl={11} lg={11} md={11} sm={11} xs={11} className={classes.wagonDetailGridItem}>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="center"
                  className={classes.wagonGridContainer}
                >
                  <Grid item className={classes.wagonGridItemHeader}>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                      <Grid item>
                        <Typography variant="h6" color="secondary" className={classes.wagonOrderContainer}>
                          {wagonModel.wagonOrder}
                        </Typography>
                      </Grid>
                      <Grid item className={classes.wagonHeaderGridItem}>
                        {isNameInEdit && (
                          <Grid container alignItems="center">
                            <Grid item>
                              <TextField
                                label={t('pages.train.labels.wagonName')}
                                value={wagonName}
                                onChange={(e) => setWagonName(e.target.value)}
                                onKeyDown={handleKeyDown(onNameSaveRequest)}
                                error={wagonNameError}
                                helperText={
                                  wagonNameError ? t('pages.train.validations.wagonName.required') : undefined
                                }
                                autoFocus
                              />
                            </Grid>
                            <Grid item>
                              <IconButton onClick={onNameSaveRequest}>
                                <CheckIcon fontSize="small" />
                              </IconButton>
                            </Grid>
                            <Grid item>
                              <IconButton onClick={onCloseWagonNameEdit}>
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </Grid>
                          </Grid>
                        )}

                        {!isNameInEdit && (
                          <Grid container alignItems="center">
                            <Grid item>
                              <Typography variant="h6" color="primary">
                                {wagonModel.name.length ? wagonModel.name : t('pages.train.labels.newTrainWagonName')}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <IconButton
                                onClick={() => {
                                  setIsNameInEdit(true)
                                }}
                                disabled={
                                  new Set([TrainMode.SEARCH]).has(mode) ||
                                  (new Set([TrainMode.GATE_OUT]).has(mode) &&
                                    new Set([TrainDtoTrainStateEnum.OutgoingDispatched]).has(
                                      train.trainState ?? TrainDtoTrainStateEnum.IncomingAccepted
                                    ))
                                }
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                      <Grid item className={classes.wagonHeaderGridItem}>
                        {isWeightInEdit && (
                          <Grid container alignItems="center">
                            <Grid item>
                              <TextField
                                type="number"
                                label={t('pages.train.labels.wagonWeight')}
                                value={wagonWeight}
                                onChange={(e) => setWagonWeight(Number.parseInt(e.target.value))}
                                onKeyDown={handleKeyDown(onWeightSaveRequest)}
                                error={wagonWeightError}
                                helperText={wagonWeightError ? t('pages.train.validations.wagonWeight.required') : ''}
                                autoFocus
                              />
                            </Grid>
                            <Grid item>
                              <IconButton onClick={onWeightSaveRequest}>
                                <CheckIcon fontSize="small" />
                              </IconButton>
                            </Grid>
                            <Grid item>
                              <IconButton onClick={onCloseWagonWeightEdit}>
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </Grid>
                          </Grid>
                        )}
                        {!isWeightInEdit && (
                          <Grid container alignItems="center">
                            <Grid item>
                              <Typography variant="h6" color="primary">
                                {wagonModel.weight
                                  ? wagonModel.weight + ' ' + t('any.units.kilogram')
                                  : t('pages.train.labels.newTrainWagonWeight') + ' [' + t('any.units.kilogram') + ']'}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <IconButton
                                onClick={() => setIsWeightInEdit(true)}
                                disabled={
                                  new Set([TrainMode.SEARCH]).has(mode) ||
                                  (new Set([TrainMode.GATE_OUT]).has(mode) &&
                                    new Set([TrainDtoTrainStateEnum.OutgoingDispatched]).has(
                                      train.trainState ?? TrainDtoTrainStateEnum.IncomingAccepted
                                    ))
                                }
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                      <Grid item className={classes.wagonHeaderGridItem}>
                        {isCapacityInEdit && (
                          <Grid container alignItems="center">
                            <Grid item>
                              <TextField
                                type="number"
                                label={t('pages.train.labels.wagonCapacity')}
                                value={wagonCapacity}
                                onChange={(e) => setWagonCapacity(Number.parseInt(e.target.value))}
                                onKeyDown={handleKeyDown(onWagonCapacitySaveRequest)}
                                error={wagonCapacityError}
                                helperText={wagonCapacityError ? t('pages.train.validations.wagonWeight.required') : ''}
                                autoFocus
                              />
                            </Grid>
                            <Grid item>
                              <IconButton onClick={onWagonCapacitySaveRequest}>
                                <CheckIcon fontSize="small" />
                              </IconButton>
                            </Grid>
                            <Grid item>
                              <IconButton onClick={onCloseWagonCapacityEdit}>
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </Grid>
                          </Grid>
                        )}
                        {!isCapacityInEdit && (
                          <Grid container alignItems="center">
                            <Grid item>
                              <Typography variant="h6" color="primary">
                                {wagonModel.wagonCapacity
                                  ? wagonModel.wagonCapacity + ' ' + t('any.units.kilogram')
                                  : t('pages.train.labels.newTrainWagonCapacity') +
                                    ' [' +
                                    t('any.units.kilogram') +
                                    ']'}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <IconButton
                                onClick={() => setIsCapacityInEdit(true)}
                                disabled={
                                  new Set([TrainMode.SEARCH]).has(mode) ||
                                  (new Set([TrainMode.GATE_OUT]).has(mode) &&
                                    new Set([TrainDtoTrainStateEnum.OutgoingDispatched]).has(
                                      train.trainState ?? TrainDtoTrainStateEnum.IncomingAccepted
                                    ))
                                }
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                      <Grid item className={classes.wagonHeaderGridItem}>
                        {isLengthInEdit && (
                          <Grid container alignItems="center">
                            <Grid item>
                              <TextField
                                type="number"
                                label={t('pages.train.labels.wagonLength')}
                                value={wagonLength}
                                onChange={onChangeSetNewWagonLength}
                                onKeyDown={handleKeyDown(onWagonLengthSaveRequest)}
                                error={wagonLengthError}
                                helperText={wagonLengthError ? t('pages.train.validations.wagonLength.required') : ''}
                                autoFocus
                              />
                            </Grid>
                            <Grid item>
                              <IconButton onClick={onWagonLengthSaveRequest}>
                                <CheckIcon fontSize="small" />
                              </IconButton>
                            </Grid>
                            <Grid item>
                              <IconButton onClick={onCloseWagonLengthEdit}>
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </Grid>
                          </Grid>
                        )}
                        {!isLengthInEdit && (
                          <Grid container alignItems="center">
                            <Grid item>
                              <Typography variant="h6" color="primary">
                                {wagonModel.wagonLength
                                  ? wagonModel.wagonLength + ' ' + t('any.units.meter')
                                  : t('pages.train.labels.newTrainWagonLength') + ' [' + t('any.units.meter') + ']'}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <IconButton
                                onClick={() => setIsLengthInEdit(true)}
                                disabled={
                                  new Set([TrainMode.SEARCH]).has(mode) ||
                                  (new Set([TrainMode.GATE_OUT]).has(mode) &&
                                    new Set([TrainDtoTrainStateEnum.OutgoingDispatched]).has(
                                      train.trainState ?? TrainDtoTrainStateEnum.IncomingAccepted
                                    ))
                                }
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                      <Grid item className={classes.wagonHeaderGridItem}>
                        {isAxleCountInEdit && (
                          <Grid container alignItems="center">
                            <Grid item>
                              <TextField
                                type="number"
                                label={t('pages.train.labels.wagonAxleCount')}
                                value={axleCount}
                                onChange={onChangeSetNewWagonAxleCount}
                                onKeyDown={handleKeyDown(onWagonAxleCountSaveRequest)}
                                error={wagonAxleCountError}
                                helperText={
                                  wagonAxleCountError ? t('pages.train.validations.wagonAxleCount.required') : ''
                                }
                                autoFocus
                              />
                            </Grid>
                            <Grid item>
                              <IconButton onClick={onWagonAxleCountSaveRequest}>
                                <CheckIcon fontSize="small" />
                              </IconButton>
                            </Grid>
                            <Grid item>
                              <IconButton onClick={onCloseWagonAxleCountEdit}>
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </Grid>
                          </Grid>
                        )}
                        {!isAxleCountInEdit && (
                          <Grid container alignItems="center">
                            <Grid item>
                              <Typography variant="h6" color="primary">
                                {wagonModel.axleCount ?? t('pages.train.labels.wagonAxleCount')}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <IconButton
                                onClick={() => setIsAxleCountInEdit(true)}
                                disabled={
                                  new Set([TrainMode.SEARCH]).has(mode) ||
                                  (new Set([TrainMode.GATE_OUT]).has(mode) &&
                                    new Set([TrainDtoTrainStateEnum.OutgoingDispatched]).has(
                                      train.trainState ?? TrainDtoTrainStateEnum.IncomingAccepted
                                    ))
                                }
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                      <Grid item className={classes.wagonContainerSum}>
                        <Typography variant="subtitle1" color="primary">
                          {t('pages.train.labels.wagonContainerSum', {
                            weight: wagonModel.wagonContainerSum
                              ? Math.round((wagonModel.wagonContainerSum + Number.EPSILON) * 100) / 100
                              : '',
                          })}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {(wagonModel.wagonCapacity ?? 0) < (wagonModel.wagonContainerSum ?? 0) && (
                    <Grid item className={classes.wagonOverloaded}>
                      <Typography variant="subtitle1">
                        <i>{t('pages.train.validations.wagonWeight.overloaded')}</i>
                      </Typography>
                    </Grid>
                  )}

                  {isMiddleOverloaded && (
                    <Grid item className={classes.wagonOverloaded}>
                      <Typography variant="subtitle1">
                        <i>{t('pages.train.validations.wagonWeight.middleOverloaded')}</i>
                      </Typography>
                    </Grid>
                  )}
                  <Grid item className={classes.wagonGridItemContent}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={3}
                      className={classes.contentGrid}
                    >
                      {Array(eightContainersToTrain ? 8 : 4)
                        .fill(1)
                        .map((value, index) => {
                          const wagonContent: TrainWagonContentDto = wagonModel.content?.find(
                            (wagonContent) => wagonContent.wagonContentOrder === index
                          ) ?? {
                            wagonContentOrder: index,
                            transition: {
                              container: {
                                iluCode: '',
                                containerType: ContainerSimpleDtoContainerTypeEnum.ShippingContainer,
                              },
                            },
                          }

                          return (
                            <Grid item xl={3} lg={3} md={3} sm={3} xs={3} key={index}>
                              <ConardTrainWagonContentForm
                                wagonContentModel={wagonContent}
                                mode={mode}
                                onCreateContainer={() =>
                                  onCreateContainer ? onCreateContainer(wagonModel, wagonContent) : undefined
                                }
                                onCreateSemitrailer={() =>
                                  onCreateSemitrailer ? onCreateSemitrailer(wagonModel, wagonContent) : undefined
                                }
                                onEditContainer={() =>
                                  onEditContainer ? onEditContainer(wagonModel, wagonContent) : undefined
                                }
                                onEditSemitrailer={() =>
                                  onEditSemitrailer ? onEditSemitrailer(wagonModel, wagonContent) : undefined
                                }
                                onLoadContainer={(transitionId, qualityType) =>
                                  onLoadContainer
                                    ? onLoadContainer(wagonModel, wagonContent, transitionId, qualityType)
                                    : undefined
                                }
                                onFill={() => onFill(wagonModel, wagonContent)}
                                wagonModel={wagonModel}
                                isMiddleOverloaded={isMiddleOverloaded}
                              />
                            </Grid>
                          )
                        })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={1} lg={1} md={1} sm={1} xs={1} className={classes.wagonMovementGridItem}>
                <Box className={classes.wagonMovementGridContainer}>
                  <Box className={classes.wagonMovementUpGridItem}>
                    <IconButton
                      className={classes.IconButton}
                      onClick={() => onMove(MoveDirection.UP)}
                      disabled={
                        wagonModel.wagonOrder === OrderWagonsFrom ||
                        new Set([TrainMode.SEARCH]).has(mode) ||
                        !train.trainState ||
                        new Set([TrainDtoTrainStateEnum.OutgoingDispatched]).has(train.trainState)
                      }
                    >
                      <ArrowUpwardIcon />
                    </IconButton>
                  </Box>
                  <Box className={classes.wagonMovementDownGridItem}>
                    <IconButton
                      className={classes.IconButton}
                      onClick={() => onMove(MoveDirection.DOWN)}
                      disabled={
                        wagonModel.wagonOrder ===
                          (train.wagons ? train.wagons?.length - 1 + OrderWagonsFrom : OrderWagonsFrom) ||
                        new Set([TrainMode.SEARCH]).has(mode) ||
                        !train.trainState ||
                        new Set([TrainDtoTrainStateEnum.OutgoingDispatched]).has(train.trainState)
                      }
                    >
                      <ArrowDownwardIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Dialog open={showRemoveDialog}>
        <DialogTitle>{t('pages.gateOut.coparn.delete.dialog.title')}</DialogTitle>
        <DialogContent>
          <Typography>{t('pages.train.wagon.dialog.delete.description')}</Typography>
        </DialogContent>
        <DialogActions>
          <ConardButton conardVariant="transparent" onClick={() => setShowRemoveDialog(false)}>
            {t('any.buttons.cancel')}
          </ConardButton>
          <ConardButton conardVariant="dark" onClick={() => (wagonModel.id ? onRemoveWagon(onRemove) : onRemove())}>
            {t('any.buttons.ok')}
          </ConardButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ConardTrainWagonForm
