import { AsparkingSemaphoreApi } from '../api'
import myAxios from '../config/axios/axios'
import { AsparkingCheckpoint } from '../enums/AsparkingCheckpoint'
import { SemaphoreMode } from '../enums/SemaphoreMode'

let api: AsparkingSemaphoreApi

export const setAsparkingSemaphoreApiConfiguration = () => {
  api = new AsparkingSemaphoreApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const asparkingSemaphoreControlService = {
  asparkingSemaphoreControl: (semaphoreMode: SemaphoreMode, asparkingCheckpoint: AsparkingCheckpoint) => {
    return api.asparkingSemaphoreSemaphoreControl(semaphoreMode, asparkingCheckpoint)
  },
}

export default asparkingSemaphoreControlService
