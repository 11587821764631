import React, { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Mode } from '../../enums/Mode'
import LicensePlateEditForm from './LicensePlateEditForm'
import { AsparkingLicensePlateDto, CarrierDto } from '../../api'
import licensePlateService from '../../services/LicensePlateService'
import carrierService from '../../services/CarrierService'

interface UrlParamTypes {
  id?: string
  licensePlateId?: string
}

const LicensePlateUpdatePage: FC = () => {
  const { id, licensePlateId } = useParams<UrlParamTypes>()

  const [licensePlate, setLicensePlate] = useState<AsparkingLicensePlateDto>()
  const [carrier, setCarrier] = useState<CarrierDto>()
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (licensePlateId !== undefined && id !== undefined) {
      licensePlateService
        .licensePlateFindById(Number.parseInt(licensePlateId))
        .then((response) => {
          setLicensePlate(response.data)
          carrierService
            .carrierFindById(Number.parseInt(id))
            .then((response) => {
              setCarrier(response.data)
              setLoaded(true)
            })
            .catch(() => {
              setLoaded(true)
            })
        })
        .catch(() => {
          setLoaded(true)
        })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return <>{loaded && <LicensePlateEditForm carrier={carrier} mode={Mode.Update} licensePlate={licensePlate} />}</>
}

export default LicensePlateUpdatePage
