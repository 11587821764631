import React, { FC, useContext, useEffect, useState } from 'react'
import {
  ContainerDtoContainerTypeEnum,
  NotificationDto,
  TransitionDto,
  TransitionDtoContainerStateEnum,
} from '../../api'
import { useLocation, useParams } from 'react-router-dom'
import ConardTransitionForm from '../../components/forms/ConardTransitionForm'
import transitionService from '../../services/TransitionService'
import { createStyles } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { TransitionMode } from '../../enums/TransitionMode'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../redux/store'
import { setTransition } from '../../redux/transition/transitionSlice'
import { ConfigContext } from '../../context/ConfigContext'

const useStyles = makeStyles(() =>
  createStyles({
    alert: {
      margin: 'auto',
      width: '50vw',
      marginTop: '20vh',
    },
  })
)

interface UrlParamTypes {
  id?: string
}

interface NotificationState {
  notification?: NotificationDto
  transitionMode?: TransitionMode
}

const TransitionSearchDetailPage: FC = () => {
  const { id } = useParams<UrlParamTypes>()
  const location = useLocation<NotificationState>()
  const locationNotification = location?.state?.notification
  const locationTransitionMode = location?.state?.transitionMode
  const classes = useStyles()
  const { t } = useTranslation()
  const configContext = useContext(ConfigContext)

  const transition = useSelector<RootState, TransitionDto>((state) => state.transition.transition)
  const dispatch = useAppDispatch()

  const [notification, setNotification] = useState<NotificationDto | undefined>(locationNotification)
  const [transitionMode, setTransitionMode] = useState<TransitionMode>(locationTransitionMode ?? TransitionMode.SEARCH)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(false)
    if (locationNotification) {
      setNotification(locationNotification)
    }
    if (locationTransitionMode) {
      setTransitionMode(locationTransitionMode)
    }
  }, [locationNotification, locationTransitionMode])

  useEffect(() => {
    if (id !== undefined) {
      transitionService
        .findById(Number.parseInt(id))
        .then((response) => {
          dispatch(
            setTransition({
              ...response.data,
              ...{
                container: {
                  ...response.data.container,
                  heavyTested:
                    response.data.container.heavyTested ||
                    !!response.data.container.heavyTestedValue ||
                    (configContext.heavyTested && !response.data.repleted),
                  iluPhoto: response.data.container.iluPhoto ?? transition?.container?.iluPhoto,
                },
                damaged: transition.damaged ? transition.damaged : response.data.damaged,
                damageLogEntries: transition.damageLogEntries
                  ? transition.damageLogEntries
                  : response.data.damageLogEntries,
              },
              transitionDateTime:
                response.data.containerState === TransitionDtoContainerStateEnum.PlannedGateIn
                  ? new Date().toISOString()
                  : response.data.transitionDateTime,
            })
          )
        })
        .catch()
        .finally(() => {
          setLoaded(true)
        })
    }
    //
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {loaded && (
        <>
          {transition.id === undefined && (
            <Alert severity="error" className={classes.alert}>
              {t('be.error.entity.notFound')}
            </Alert>
          )}
          {transition.id && (
            <ConardTransitionForm
              containerType={transition?.container.containerType || ContainerDtoContainerTypeEnum.Semitrailer}
              transitionMode={transitionMode}
              notification={notification}
            />
          )}
        </>
      )}
    </>
  )
}

export default TransitionSearchDetailPage
