import React from 'react'
import { DriversList } from '../carrier/drivers-page/DriversList'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

export const DriversPageForManager = () => {
  const history = useHistory()
  const { id: carrierId } = useParams<{
    id: string
  }>()

  const onAddDriver = () => {
    history.push(`/entry-and-parking/carrier/${carrierId}/driver/create`)
  }

  const handleEditDriver = (driverId: number) => {
    history.push(`/entry-and-parking/carrier/${carrierId}/driver/update/${driverId}`)
  }

  return <DriversList carrierId={Number(carrierId)} onAddDriver={onAddDriver} onEditDriver={handleEditDriver} />
}
