import React, { FC, useEffect, useState } from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { PriceListDto } from '../../../api'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import ConardTextField from '../../../components/ConardTextField'
import ConardButton from '../../../components/ConardButton'
import { Alert } from '@material-ui/lab'
import priceListService from '../../../services/PriceListService'

interface UrlParams {
  id?: string
}

const useStyles = makeStyles({
  container: {
    marginTop: '8vh',
    width: '70vw',
    margin: 'auto',
    minWidth: '300px',
  },
  typographyLabel: {
    width: '50vw',
  },
  button: {
    width: '15vw',
    minWidth: '200px',
    textAlign: 'center',
    margin: 'auto',
  },
  alert: {
    margin: 'auto',
    width: '50vw',
    marginTop: '20vh',
  },
})

const PriceListFormPage: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()
  const { id } = useParams<UrlParams>()
  const idNumber = parseInt(id ?? '-1', 10)
  const [loaded, setLoaded] = useState(false)
  const [priceListItem, setPriceListItem] = useState<PriceListDto | undefined>(undefined)

  const priceListSchema = yup.object().shape({
    price: yup.number().required(),
    minAmount: yup.number().positive(),
  })

  const { handleSubmit, control, errors } = useForm<PriceListDto>({
    resolver: yupResolver(priceListSchema),
    reValidateMode: 'onBlur',
  })

  const onSubmit = (values: PriceListDto) => {
    values = {
      ...values,
      itemCode: priceListItem?.itemCode,
      defaultValue: priceListItem?.defaultValue,
      minAmount: priceListItem?.defaultValue ? priceListItem?.minAmount : values.minAmount,
    }
    priceListService.update(priceListItem?.id, values).then(() => {
      history.push('/expenses/price-list')
    })
  }

  useEffect(() => {
    priceListService
      .findById(idNumber)
      .then((response) => {
        setPriceListItem(response.data)
        setLoaded(true)
      })
      .catch(() => {
        setLoaded(true)
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {loaded && (
        <>
          <Typography variant="h4" color="primary" className={classes.typographyLabel}>
            {t('pages.priceList.labels.edit')}
          </Typography>
          {!priceListItem && (
            <Alert severity="error" className={classes.alert}>
              {t('be.error.entity.notFound')}
            </Alert>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              className={classes.container}
              direction="row"
              spacing={7}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                <ConardTextField
                  id="itemCode"
                  name="itemCode"
                  type="text"
                  label={t('entity.priceList.fields.itemCode')}
                  defaultValue={t(`enum.priceListItemCode.${priceListItem ? priceListItem.itemCode : ''}`)}
                  disabled
                  error={!!errors.itemCode}
                  helperText={errors.itemCode ? t('pages.priceList.validations.itemCode') : ' '}
                  control={control}
                />
              </Grid>

              {(!priceListItem || !priceListItem.defaultValue) && (
                <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                  <ConardTextField
                    id="minAmount"
                    name="minAmount"
                    type="text"
                    label={t('entity.priceList.fields.minAmount')}
                    defaultValue={priceListItem?.minAmount}
                    error={!!errors.minAmount}
                    helperText={errors.minAmount ? t('pages.priceList.validations.minAmount') : ' '}
                    control={control}
                  />
                </Grid>
              )}

              <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                <ConardTextField
                  id="price"
                  name="price"
                  type="text"
                  label={t('entity.priceList.fields.price')}
                  defaultValue={priceListItem?.price}
                  error={!!errors.price}
                  helperText={errors.price ? t('pages.priceList.validations.price') : ' '}
                  control={control}
                />
              </Grid>

              <Grid item xl={5} lg={5} md={12} sm={12} xs={12}>
                <div className={classes.button}>
                  <ConardButton conardVariant="dark" type="submit">
                    {t('any.buttons.save')}
                  </ConardButton>
                </div>
              </Grid>
              <Grid item xl={5} lg={5} md={12} sm={12} xs={12}>
                <div className={classes.button}>
                  <ConardButton conardVariant="dark" onClick={() => history.goBack()}>
                    {t('any.buttons.back')}
                  </ConardButton>
                </div>
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </>
  )
}

export default PriceListFormPage
