import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TransitionSearchListSearchDto } from '../../api'

export interface TransitionSearchListSearchState {
  transitionSearch: TransitionSearchListSearchDto | undefined
  transitionSearchTrainContent: TransitionSearchListSearchDto | undefined
}

export const transitionSearchListSearchInitialState: TransitionSearchListSearchState = {
  transitionSearch: undefined,
  transitionSearchTrainContent: undefined,
}

export const transitionSearchListSearchSlice = createSlice({
  name: 'transitionSearchList',
  initialState: transitionSearchListSearchInitialState,
  reducers: {
    setTransitionSearchListSearch(
      state: TransitionSearchListSearchState,
      action: PayloadAction<TransitionSearchListSearchDto | undefined>
    ) {
      state.transitionSearch = action.payload
    },
  },
})

export const { setTransitionSearchListSearch } = transitionSearchListSearchSlice.actions
