import React, { FC, useState } from 'react'
import { Typography, makeStyles, createStyles } from '@material-ui/core'
import { Box } from '@mui/material'
import ConardTooltip from '../../components/ConardTooltip'
import FloorsDialog from './FloorsDialog'
import SideView from './SideView'
import { useTranslation } from 'react-i18next'
import {
  StackContainerSearchDto,
  TerminalCellLayoutDto,
  TerminalRowLayoutDto,
  TerminalSectorLayoutDto,
} from '../../api'
import clsx from 'clsx'
import { useTerminalLayoutContext } from '../../hooks/useTerminalLayoutContext'
import manipulationRequestService from '../../services/ManipulationRequestService'
import { useTerminalTemporaryDrawer } from '../../hooks/useTerminalTemporaryDrawerContext'

const ColorMap: Record<number, string> = {
  0: '#FFFFFF',
  1: '#ffb3b3',
  2: '#ff6b6b',
  3: '#ff2424',
  4: '#bf0000',
}
const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      backgroundColor: '#ccc8c8e9',
      padding: '0 10px 0 10px',
      marginRight: '20px',
    },
    sector: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      top: '7%',
      left: '-28%',
      scale: '0.4',
      [theme.breakpoints.only('xl')]: {
        top: '18%',
        left: '-6%',
        scale: '0.6',
      },
    },
    row: {
      display: 'flex',
    },
    cell: {
      width: '20px',
      height: '10px',
      fontSize: '8px',
      textAlign: 'center',
      cursor: 'pointer',
      '&:hover': {
        transform: 'scale(1.07)',
        boxShadow: '0px 2px 2px rgba(26,24,26,0.5)',
        borderRadius: '1px',
        transition: 'all 300ms ease-in-out',
      },
    },
    cell40: {
      width: '40px',
    },
    axisLabels: {
      width: '20px',
      height: '10px',
      fontSize: '6px',
      textAlign: 'center',
      paddingTop: '2px',
    },
    yAxis: {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    xAxis: {
      display: 'flex',
      flexDirection: 'row',
    },
    zeroLabel: {
      visibility: 'hidden',
      width: '20px',
    },
    clickable: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#D0D0D0',
        fontWeight: '600',
      },
    },
  })
)

interface ContainerSectorProps {
  sector: TerminalSectorLayoutDto
  onUpdateManipulationRequestTable: () => void
  onUpdateTerminalLayout: () => void
}

export const ContainerSector: FC<ContainerSectorProps> = ({
  sector,
  onUpdateManipulationRequestTable,
  onUpdateTerminalLayout,
}) => {
  const [showFloorsDialog, setShowFloorsDialog] = useState(false)
  const [floorDialogData, setFloorDialogData] = useState<StackContainerSearchDto>()
  const [sideDialogData, setSideDialogData] = useState<StackContainerSearchDto>()

  const [showSideView, setShowSideView] = useState(false)

  const classes = useStyles()
  const { t } = useTranslation()

  const { mode, setMode } = useTerminalLayoutContext()
  const { getSelectedContainer, removeContainer, resetSelectedContainer } = useTerminalTemporaryDrawer()

  const handleClick = async (column: TerminalCellLayoutDto, row: TerminalRowLayoutDto) => {
    if (mode === 'openStack') {
      setShowFloorsDialog(true)
      setFloorDialogData({
        sectorColumn: column.columnNumber,
        sectorRow: row.rowNumber,
        sectorLetter: sector.name,
      })
    }

    if (mode === 'chooseCoordinates') {
      const selectedContainer = getSelectedContainer()

      if (!selectedContainer?.id) {
        return
      }

      try {
        await manipulationRequestService.create({
          sectorLetter: sector.name,
          sectorColumn: column.columnNumber,
          sectorRow: row.rowNumber,
          transitionId: selectedContainer.id,
        })

        removeContainer(selectedContainer.id)
        resetSelectedContainer()
        setMode('openStack')
        onUpdateManipulationRequestTable()
        onUpdateTerminalLayout()
      } catch (err) {
        console.error('Error creating manipulation request', err)
      }
      //TODO:
      //zobrazit nekde velkou informaci o tom, ktery mod je na layoutu zapnuty.. jesti je to vybirani koordinatu nebo zobrazovani koji
      //vsechny informace o kontejnerech v temporaryDraweru musi drzet BE, ted se po hard refreshi stranky context vyprazdni
      //pri zobrazeni floor dialogu / layout dialogu, dovolit presun pouze od vrchu.. nelze presunout kontejner vespod, stejne tak GO? - konzultace s BE - nedelat for now
    }
  }

  return (
    <>
      <Box className={classes.sector}>
        <Box className={classes.wrapper}>
          {sector && <Typography>{`${t('pages.terminalLayout.section')}: ${sector.name}`}</Typography>}
          {sector.rows?.map((row, rowIndex) => (
            <Box className={classes.row} key={rowIndex}>
              <Box className={`${classes.axisLabels} ${classes.yAxis}`}>{row.rowNumber}</Box>
              {row.columns.map((column, columnIndex) => {
                const prevIndex = columnIndex - 1

                if (prevIndex >= 0 && prevIndex <= row.columns.length) {
                  if (row.columns[prevIndex].teu === 2) {
                    return
                  }
                }

                return (
                  <Box key={columnIndex}>
                    <ConardTooltip
                      title={
                        column.teu === 1
                          ? `${t('pages.terminalLayout.tooltip.column')}: ${column.columnNumber} ${t(
                              'pages.terminalLayout.tooltip.row'
                            )}: ${row.rowNumber} ${t('pages.terminalLayout.tooltip.floors')}: ${column.containerCount}`
                          : `${t('pages.terminalLayout.tooltip.column')}: ${column.columnNumber} - ${
                              column.columnNumber + 1
                            } ${t('pages.terminalLayout.tooltip.row')}: ${row.rowNumber} ${t(
                              'pages.terminalLayout.tooltip.floors'
                            )}: ${column.containerCount}`
                      }
                    >
                      <Box
                        className={clsx(classes.cell, {
                          [classes.cell40]: sector.rows?.[rowIndex].columns[columnIndex].teu === 2,
                        })}
                        sx={{
                          backgroundColor: ColorMap[column.containerCount],
                        }}
                        onClick={() => handleClick(column, row)}
                      >
                        {sector.rows?.[rowIndex].columns[columnIndex].containerCount}
                      </Box>
                    </ConardTooltip>
                  </Box>
                )
              })}
            </Box>
          ))}
          <Box className={classes.xAxis}>
            <Box className={classes.zeroLabel}>{0}</Box>
            {sector.rows?.[0].columns.map((column, columnIndex) => (
              <Box
                className={`${classes.axisLabels} ${classes.clickable}`}
                key={columnIndex}
                onClick={() => {
                  setShowSideView(true)
                  setSideDialogData({
                    sectorLetter: sector.name,
                    sectorColumn: column.columnNumber,
                  })
                }}
              >
                {column.columnNumber}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>

      {floorDialogData && (
        <FloorsDialog
          showFloorsDialog={showFloorsDialog}
          onClose={() => setShowFloorsDialog(false)}
          sectorLetter={floorDialogData.sectorLetter ?? ''}
          sectorColumn={floorDialogData.sectorColumn ?? 0}
          sectorRow={floorDialogData.sectorRow ?? 0}
        />
      )}
      {sideDialogData && (
        <SideView
          showSideView={showSideView}
          onClose={() => setShowSideView(false)}
          sectorColumn={sideDialogData.sectorColumn ?? 0}
          sectorLetter={sideDialogData.sectorLetter ?? ''}
        />
      )}
    </>
  )
}
