import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import { transitionSlice } from './transition/transitionSlice'
import storage from 'redux-persist/lib/storage'
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist'
import { appInfoSlice } from './info/appInfoSlice'
import { trainSlice } from './train/trainSlice'
import { notificationSlice } from './notification/notificationSlice'
import { transitionSearchSlice } from './transition/TransitionSearchSlice'
import { operatorTransitionSearchSlice } from './operator/OperatorTransitionSearchSlice'
import { transitionSearchListSearchSlice as transitionSearchListSearchSlice } from './transition/TransitionSearchListSearch'

const reducers = combineReducers({
  appInfo: appInfoSlice.reducer,
  transition: transitionSlice.reducer,
  transitionSearch: transitionSearchSlice.reducer,
  operatorTransitionSearch: operatorTransitionSearchSlice.reducer,
  transitionSearchListSearch: transitionSearchListSearchSlice.reducer,
  train: trainSlice.reducer,
  notification: notificationSlice.reducer,
})

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>

export type DispatchType = typeof store.dispatch
export const useAppDispatch = () => useDispatch<DispatchType>()

export default store
