import React, { FC, useState } from 'react'
import { KioskProgressPanel } from './KioskProgressPanel'
import { useParams } from 'react-router-dom'
import { KioskDriverInfo } from './step0/KioskDriverInfo'
import { KioskGateIn } from './step1/KioskGateIn'
import { useKioskFormStep } from '../../hooks/useKioskFormStep'
import { KioskRepletedForm } from './step3/KioskRepletedForm'
import { KioskCheckIlu } from './step2/KioskCheckIlu'
import { KioskAdrForm } from './step4/KioskAdrForm'
import { KioskReferenceForm } from './step5/KioskReferenceForm'
import { KioskGateOut } from './step6/KioskGateOut'
import { KioskGateOutInfoForm } from './step8/KioskGateOutInfoForm'
import { KioskGateOutCargoTypeForm } from './step7/KioskGateOutCargoTypeForm'
import { KioskSuccessPage } from './KioskSuccessPage'
import { KioskSummary } from './step9/KioskSummary'
import { Box, createStyles, IconButton, makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import kioskDriverArrivalService from '../../services/KioskDriverArrivalService'
import { DeleteAllDialog } from '../drivers/DeleteAllDialog'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& .MuiSvgIcon-root': { fontSize: 50 },
    },
  })
)

export const KioskForm: FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const { id: idDriverArrival } = useParams<{
    id: string
  }>()

  const [showDialog, setShowDialog] = useState(false)

  const {
    previous,
    next,
    currentStepIndex,
    goTo,
    isLastStep,
    handleWithoutGateIn,
    withoutGateIn,
    handleWithoutGateOut,
    withoutGateOut,
  } = useKioskFormStep(10)

  const driverArrivalId = Number(idDriverArrival)

  const resolveStepComponent = (currentStep: number): React.ReactNode => {
    switch (currentStep) {
      case 0:
        return <KioskDriverInfo idDriverArrival={driverArrivalId} next={next} />

      case 1:
        return (
          <KioskGateIn
            next={next}
            previous={previous}
            idDriverArrival={driverArrivalId}
            goTo={goTo}
            handleWithoutGateIn={handleWithoutGateIn}
          />
        )

      case 2:
        return <KioskCheckIlu idDriverArrival={driverArrivalId} previous={previous} next={next} />

      case 3:
        return <KioskRepletedForm previous={previous} idDriverArrival={driverArrivalId} next={next} />

      case 4:
        return <KioskAdrForm idDriverArrival={driverArrivalId} previous={previous} next={next} />

      case 5:
        return <KioskReferenceForm idDriverArrival={driverArrivalId} previous={previous} next={next} />

      case 6:
        return (
          <KioskGateOut
            idDriverArrival={driverArrivalId}
            previous={previous}
            next={next}
            goTo={goTo}
            handleWithoutGateOut={handleWithoutGateOut}
          />
        )

      case 7:
        return <KioskGateOutCargoTypeForm previous={previous} next={next} idDriverArrival={driverArrivalId} />

      case 8:
        return <KioskGateOutInfoForm idDriverArrival={driverArrivalId} previous={previous} next={next} />

      case 9:
        return <KioskSummary idDriverArrival={driverArrivalId} previous={previous} next={next} goTo={goTo} />
      case 10:
        return <KioskSuccessPage />

      default:
        return <div>nothing</div>
    }
  }

  const handleClickDelete = async () => {
    setShowDialog(false)
    try {
      await kioskDriverArrivalService.cancel(driverArrivalId)
    } catch (error) {
      console.error('Error deleting kioskDriverArrival', error)
    }
    history.push('/')
  }

  return (
    <div>
      <Box style={{ display: isLastStep ? 'none' : undefined }} className={classes.icon}>
        <IconButton onClick={() => setShowDialog(true)}>
          <CloseIcon fontSize="large" />
        </IconButton>
      </Box>
      <KioskProgressPanel
        currentStepIndex={currentStepIndex}
        withoutGateIn={withoutGateIn}
        withoutGateOut={withoutGateOut}
        disabled={isLastStep}
      />

      {resolveStepComponent(currentStepIndex)}
      <DeleteAllDialog showDialog={showDialog} onYes={handleClickDelete} onClose={() => setShowDialog(false)} />
    </div>
  )
}
