import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import { format } from 'date-fns'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ManipulationRequestDto } from '../../api'
import ConardButton from '../../components/ConardButton'
import ConardProgressBar from '../../components/ConardProgressBar'
import { Order } from '../../enums/Order'

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      marginTop: '30px',
      width: '65%',
    },
    buttonCell: {
      width: '100px',
    },
    deleteButton: {
      borderRadius: 12,
    },
  })
)

interface HeadCell {
  id: string
  label: string
}

interface ManipulationReuestsTableProps {
  pendingRequests: ManipulationRequestDto[]
  loaded: boolean
  onDeleteManipulationRequest: (id: number) => void
}

export const ManipulationReuestsTable: FC<ManipulationReuestsTableProps> = ({
  pendingRequests,
  onDeleteManipulationRequest,
  loaded,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [order, setOrder] = useState<Order>(Order.Asc)
  const [orderBy, setOrderBy] = useState('createdAt')

  const headCells: HeadCell[] = [
    { id: 'iluCode', label: t('pages.terminalLayout.labels.ilucode') },
    { id: 'oldCoordinates', label: t('pages.terminalLayout.labels.oldCoordinates') },
    { id: 'newCoordinates', label: t('pages.terminalLayout.labels.newCoordinates') },
    { id: 'createdAt', label: t('pages.terminalLayout.labels.createdAt') },
    { id: 'button', label: '' },
  ]
  const handleSort = (property: string) => () => {
    const isAsc = orderBy === property && order === Order.Asc
    setOrder(isAsc ? Order.Desc : Order.Asc)
    setOrderBy(property)
  }

  return (
    <div className={classes.wrapper}>
      <Typography variant="h5" color="primary">
        {t('pages.terminalLayout.pendingRequests')}
      </Typography>
      <Grid>
        <Paper variant="outlined">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={handleSort(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {pendingRequests?.map((request) => (
                  <TableRow key={request.id}>
                    <TableCell component="td" scope="row">
                      {request.iluCode}
                    </TableCell>

                    <TableCell component="td" scope="row">
                      {request.oldSectorLetter +
                        ' | ' +
                        request.oldSectorColumn +
                        ' | ' +
                        request.oldSectorRow +
                        ' | ' +
                        request.oldSectorFloor}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {request.sectorLetter +
                        ' | ' +
                        request.sectorColumn +
                        ' | ' +
                        request.sectorRow +
                        ' | ' +
                        request.sectorFloor}
                    </TableCell>
                    <TableCell component="td" scope="row">
                      {request.createdAt ? format(new Date(request.createdAt), 'dd.MM.yyyy HH:mm') : ''}
                    </TableCell>
                    <TableCell component="td" scope="row" className={classes.buttonCell}>
                      <ConardButton
                        className={classes.deleteButton}
                        conardVariant="red"
                        onClick={() => onDeleteManipulationRequest(request.id ?? 0)}
                      >
                        {t('any.buttons.delete')}
                      </ConardButton>
                    </TableCell>
                  </TableRow>
                ))}
                {loaded && !pendingRequests?.length && (
                  <TableRow key="noData">
                    <TableCell component="th" scope="row" align="center" colSpan={16}>
                      {t('pages.common.table.noData')}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <ConardProgressBar showBar={!loaded} />
          </TableContainer>
        </Paper>
      </Grid>
    </div>
  )
}
