import React, { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core'
import ConardButton from '../ConardButton'
import { TransitionDto } from '../../api'

const useStyles = makeStyles(() =>
  createStyles({
    dialogContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
)

interface TransitionWrongIluDialogProps {
  showDialog: boolean
  setShowDialog: (showDialog: boolean) => void
  onSubmit: (transition: TransitionDto) => void
  transition: TransitionDto
}

const TransitionWrongIluDialog: FC<TransitionWrongIluDialogProps> = (props) => {
  const { showDialog, setShowDialog, transition, onSubmit } = props
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      {showDialog && (
        <Dialog open={showDialog} maxWidth="sm">
          <DialogTitle>{t('pages.gateOut.dialog.transitionWrongIlu.title')}</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Typography>
              <Trans
                i18nKey="pages.gateOut.dialog.transitionWrongIlu.content"
                values={{
                  ilu: transition?.container.iluCode ?? '',
                }}
              />
            </Typography>
          </DialogContent>
          <DialogActions>
            <ConardButton
              conardVariant="dark"
              onClick={() => {
                onSubmit(transition!)
                setShowDialog(false)
              }}
            >
              {t('any.buttons.save')}
            </ConardButton>
            <ConardButton conardVariant="transparent" onClick={() => setShowDialog(false)}>
              {t('any.buttons.cancel')}
            </ConardButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default TransitionWrongIluDialog
