import React from 'react'
import { Accordion, AccordionSummary, AccordionSummaryProps, styled } from '@mui/material'
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp'
import { useConardTheme } from '../../hooks/useConardTheme'

export const ConardAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  '&:only-child': {
    borderRadius: 12,
  },
}))

export const ConardAccordionSummary = styled((props: AccordionSummaryProps) => (
  <AccordionSummary expandIcon={<KeyboardArrowRightSharpIcon />} {...props} />
))(() => ({
  fontSize: '18px',
  fontWeight: 650,
  color: useConardTheme().palette.primary.main,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
}))
