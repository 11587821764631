import React, { FC } from 'react'
import { CarrierShortDto, DriverShortDto, LicensePlateDto, NotificationDto, UserDtoRoleEnum } from '../../api'
import GateOutSimplePrepareList from '../../components/gate-out/GateOutSimplePrepareList'
import { useLocation } from 'react-router-dom'
import { useConardAuth } from '../../hooks/useConardAuth'

interface GateOutSimpleListPageState {
  notification?: NotificationDto
  driver?: DriverShortDto
  carrier?: CarrierShortDto
  frontLicensePlate?: LicensePlateDto
  rearLicensePlate?: LicensePlateDto
}

const GateOutSimpleListPage: FC = () => {
  const auth = useConardAuth()
  const location = useLocation<GateOutSimpleListPageState>()

  return (
    <>
      {auth.getRole() !== UserDtoRoleEnum.Manager && (
        <GateOutSimplePrepareList
          notification={location?.state?.notification}
          driver={location?.state?.driver}
          carrier={location?.state?.carrier}
          frontLicensePlate={location?.state?.frontLicensePlate}
          rearLicensePlate={location?.state?.rearLicensePlate}
        />
      )}
    </>
  )
}

export default GateOutSimpleListPage
