import React, { FC, ReactNode } from 'react'
import { createStyles, FormControl, InputAdornment, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { TextFieldProps } from '@material-ui/core/TextField/TextField'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(34px, -6px) scale(0.75)',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        '& legend': {
          marginLeft: '20px',
        },
      },
    },
    inputRoot: {
      borderRadius: 12,
      borderColor: theme.palette.secondary.light,
      backgroundColor: theme.palette.secondary.main,
    },
  })
)

export type ConardTextFieldProps = Omit<TextFieldProps, 'label' | 'helperText'> & {
  label: string
  helperText?: string
  adornment?: ReactNode
}

export const ConardTextField: FC<ConardTextFieldProps> = ({
  label,
  helperText,
  adornment,
  id,
  error,
  ...textBoxProps
}) => {
  const classes = useStyles()

  return (
    <FormControl variant="outlined" error={error} fullWidth>
      <TextField
        label={label}
        error={error}
        helperText={helperText}
        InputProps={{
          endAdornment: adornment ? <InputAdornment position="end">{adornment}</InputAdornment> : undefined,
          className: classes.inputRoot,
          ...textBoxProps.InputProps,
        }}
        fullWidth
        id={id}
        variant="outlined"
        className={classes.root}
        {...textBoxProps}
      />
    </FormControl>
  )
}
