import React, { FC, useContext } from 'react'
import { StoreInputParams } from './StoreForm'
import { makeStyles } from '@material-ui/core/styles'
import { Button, createStyles, alpha, Grid, Paper, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ConfigContext } from '../../../context/ConfigContext'

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      backgroundColor: alpha(theme.palette.secondary.light, 0.3),
      padding: '2vh 4vw 2vh 4vw',
      marginBottom: '2vh',
    },
    selectorWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    selectorItemContent: {
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    selectorItem: {
      width: '25%',
      backgroundColor: theme.palette.secondary.main,
      position: 'relative',
      border: `1px solid ${theme.palette.secondary.light}`,
      '&::after': {
        content: '""',
        display: 'block',
        paddingBottom: '100%',
      },
      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
    selectorSelected: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
    },
    deleteButton: {
      borderRadius: 20,
      width: '100%',
      height: '100%',
      marginLeft: '1vw',
      fontSize: 'large',
    },
  })
)

interface FloorDialProps {
  floor?: number
  setStoreInputParam: React.Dispatch<React.SetStateAction<StoreInputParams>>
  switchDials: (to: string) => void
}

export const FloorDial: FC<FloorDialProps> = (props) => {
  const { floor, setStoreInputParam, switchDials } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const { coordinates } = useContext(ConfigContext)

  const maximumFloor = coordinates.maxSectorFloor

  const onAddNumber = (selectedNumber: number) => {
    setStoreInputParam((prevState) => ({
      ...prevState,
      sectorFloor: selectedNumber,
    }))
    switchDials('submit')
  }

  const onDelete = () => {
    setStoreInputParam((prevState) => ({
      ...prevState,
      sectorFloor: undefined,
    }))
  }

  return (
    <>
      <Paper elevation={0} className={classes.paper}>
        <Typography variant="h5" align="center" color="primary" style={{ marginBottom: '1vh' }}>
          {t('pages.containerStoreForm.labels.sectorFloor')}
        </Typography>
        <Grid container spacing={1}>
          <Grid item md={2} />
          <Grid item xs={12} md={8}>
            <div className={classes.selectorWrapper}>
              {Array(maximumFloor)
                .fill(1)
                .map((value, index) => (
                  <div key={index} onClick={() => onAddNumber(index + 1)} className={classes.selectorItem}>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      className={
                        index + 1 === floor
                          ? `${classes.selectorItemContent} ${classes.selectorSelected}`
                          : classes.selectorItemContent
                      }
                    >
                      <Grid item>
                        <Typography variant="h3">{index + 1}</Typography>
                      </Grid>
                    </Grid>
                  </div>
                ))}
            </div>
          </Grid>
          <Grid item xs={12} md={2}>
            <Button onClick={onDelete} color="primary" variant="contained" className={classes.deleteButton}>
              {t('any.buttons.delete')}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}
