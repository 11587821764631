import kioskDriverArrivalService from '../../../services/KioskDriverArrivalService'

export const fetchDriverArrival = async (id: number) => {
  try {
    const response = await kioskDriverArrivalService.getById(id)
    return response.data
  } catch (error) {
    console.error(error)

    throw error
  }
}
