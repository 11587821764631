import React, { FC, useEffect } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { AdrRidCodeDto, TransitionDto } from '../../../api'
import { createStyles, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { AdrRidField } from '../../drivers/transition-form/AdrRidField'
import { yupResolver } from '@hookform/resolvers/yup'
import { RHFConardToggle } from '../../../components/shared/rhf/RHFConardToggle'
import { KioskButtons } from '../KioskButtons'
import { useFormInitialValues } from '../../../hooks/useFormInitialValues'
import transitionService from '../../../services/TransitionService'
import { useActualDriverArival } from '../shared/useActualDriverArival'
import { AdrValidationScheme } from './ValidationScheme'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      maxWidth: '800px',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '80px',
      minHeight: '400px',
    },
    label: {
      fontSize: 28,
      fontWeight: 650,
      color: theme.palette.primary.main,
      display: 'flex',
      justifyContent: 'center',
    },
    conardLabel: {
      color: theme.palette.warning.main,
      fontWeight: 600,
      fontSize: '20px',
      marginLeft: '0.5rem',
    },
    whiteDiv: {
      backgroundColor: 'white',
      borderRadius: 12,
      boxShadow: '0 2px 10px gray',
      padding: '1.5rem',
    },
  })
)

type KioskAdrFormValues = {
  adrRid: boolean
  adrRidCodes: AdrRidCodeDto[]
}

const initialValues: KioskAdrFormValues = {
  adrRid: false,
  adrRidCodes: [],
}

interface KioskAdrProps {
  idDriverArrival: number
  previous: () => void
  next: () => void
}

export const KioskAdrForm: FC<KioskAdrProps> = ({ idDriverArrival, previous, next }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { driverArrival, loading } = useActualDriverArival(idDriverArrival)

  const formMethods = useForm<TransitionDto>({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: initialValues,
    resolver: yupResolver(AdrValidationScheme()),
  })
  const { control, handleSubmit, errors } = formMethods

  const hasAdr = useWatch({
    name: 'adrRid',
    control: control,
  })

  const onSubmit = async (formValues: TransitionDto) => {
    if (driverArrival?.plannedTransition) {
      try {
        const updatedTransition: TransitionDto = {
          ...driverArrival?.plannedTransition,
          adrRid: formValues.adrRid,
          adrRidCodes: formValues.adrRidCodes,
        }
        await transitionService.update(driverArrival?.plannedTransition?.id ?? 0, updatedTransition)
        next()
      } catch (err) {
        console.error(err)
      }
    }
  }

  useEffect(() => {
    formMethods.control.setValue('adrRidCodes', driverArrival?.plannedTransition?.adrRidCodes)
    // eslint-disable-next-line react-hooks/exhaustive-deps -- formMethods is not there intentionally (cyclic server calls)
  }, [driverArrival?.plannedTransition?.adrRidCodes])

  useFormInitialValues(mapValues(driverArrival?.plannedTransition), formMethods.reset)

  return (
    <>
      {!loading && (
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction="column" spacing={3} className={classes.container}>
              <Grid item>
                <Typography className={classes.label}>{t('kioskApp.step4.label')}</Typography>
              </Grid>

              <Grid item>
                <RHFConardToggle
                  name="adrRid"
                  option1name={t('kioskApp.step4.adrChecked')}
                  option2name={t('kioskApp.step4.adrUnchecked')}
                />
              </Grid>

              {hasAdr && (
                <Grid item>
                  <div className={classes.whiteDiv}>
                    <Typography className={classes.conardLabel}>{t('kioskApp.step4.adrCodes')}</Typography>
                    <AdrRidField
                      isDisabled={false}
                      initialCodes={driverArrival?.plannedTransition?.adrRidCodes}
                      error={!!errors.adrRidCodes}
                      helperText={!!errors.adrRidCodes ? t('kioskApp.step4.validations.adrCodesRequired') : ''}
                    />
                  </div>
                </Grid>
              )}
            </Grid>
            <KioskButtons visibleBack visibleNext onClickBack={() => previous()} />
          </form>
        </FormProvider>
      )}
    </>
  )
}

const mapValues = (entity: Partial<TransitionDto> | undefined): KioskAdrFormValues | undefined => {
  if (!entity) return undefined

  return {
    adrRid: entity.adrRid ?? false,
    adrRidCodes: entity.adrRidCodes ?? [],
  }
}
