import React, { useEffect, useState } from 'react'
import { createStyles, Grid, Hidden, IconButton, InputAdornment, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { Alert, AlertTitle } from '@material-ui/lab'
import { yupResolver } from '@hookform/resolvers/yup'
import authService from '../../services/AuthService'
import { useHistory } from 'react-router-dom'
import ConardTextField from '../../components/ConardTextField'
import ConardButton from '../../components/ConardButton'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import infoService from '../../services/InfoService'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../redux/store'
import { BuildProperties } from '../../api'
import { setAppInfo } from '../../redux/info/appInfoSlice'
import { setShowNotifications } from '../../redux/notification/notificationSlice'
import { useConardLogo } from '../../hooks/useConardLogo'
import { useConardTitle } from '../../hooks/useConardTitle'
import { useConardAuth } from '../../hooks/useConardAuth'

const useStyles = makeStyles((theme) =>
  createStyles({
    containerGrid: {
      minHeight: '100vh',
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.only('xl')]: {
        margin: 0,
        '& > .MuiGrid-item': {
          padding: '30px',
        },
      },
      [theme.breakpoints.down('lg')]: {
        margin: 0,
        '& > .MuiGrid-item': {
          padding: '10px',
        },
      },
    },
    containerGridItem: {
      [theme.breakpoints.down('sm')]: {
        width: '80%',
      },
      [theme.breakpoints.up('sm')]: {
        width: '50%',
      },
      [theme.breakpoints.only('lg')]: {
        width: '40%',
      },
      [theme.breakpoints.only('xl')]: {
        width: '30%',
      },
    },
    loginPaper: {
      backgroundColor: theme.palette.background.default,
      borderRadius: '0px',
    },
    formGridContainer: {
      [theme.breakpoints.down('lg')]: {
        width: '100%',
        margin: 0,
        '& > .MuiGrid-item': {
          padding: '10px',
        },
      },
    },
    formGridItem: {
      [theme.breakpoints.down('sm')]: {
        width: '80%',
      },
      [theme.breakpoints.only('lg')]: {
        width: '80%',
      },
      [theme.breakpoints.only('xl')]: {
        width: '60%',
      },
      textAlign: 'center',
    },
    submitButton: {
      marginBottom: '5px',
    },
  })
)

type Inputs = {
  username: string
  password: string
}

const LoginPage = () => {
  const { t } = useTranslation()
  const logo = useConardLogo(240)
  const title = useConardTitle()
  const classes = useStyles()
  const auth = useConardAuth()

  const [error, setError] = useState('')
  const history = useHistory()
  const [showPassword, setShowPassword] = useState(false)
  const appInfo = useSelector<RootState, BuildProperties>((state) => state.appInfo.info)
  const dispatch = useAppDispatch()

  const yupSchema = yup.object().shape({
    username: yup.string().required(t('pages.login.username.required')),
    password: yup.string().required(t('pages.login.password.required')),
  })

  const { control, handleSubmit, errors } = useForm<Inputs>({
    reValidateMode: 'onChange',
    resolver: yupResolver(yupSchema),
  })

  const onSubmit = (data: Inputs): void => {
    if (auth.isAuthenticated()) {
      history.push('/home')
    } else {
      authService
        .login(data.username, data.password)
        .then((response) => {
          auth.setAuthentication(response)
          history.push('/home')
        })
        .catch((reason) => {
          console.error(reason)
          setError(t('pages.login.error.notSuccessful'))
        })
    }
  }

  useEffect(() => {
    if (auth.isAuthenticated()) {
      history.push('/home')
    }
    infoService.info().then((response) => {
      dispatch(setAppInfo(response.data))
      dispatch(setShowNotifications(false))
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.containerGrid}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          {logo}
        </Grid>
        <Hidden lgDown>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h4" color="secondary">
              {title}
            </Typography>
          </Grid>
        </Hidden>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.containerGridItem}>
          <Paper elevation={3} className={classes.loginPaper}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignContent="center"
                spacing={4}
                className={classes.formGridContainer}
              >
                <Grid item className={classes.formGridItem}>
                  <Typography variant="h4" color="primary">
                    {t('pages.login.labels.formTitle')}
                  </Typography>
                </Grid>
                {error && (
                  <Grid item>
                    <Alert severity="error">
                      <AlertTitle>{t('any.error')}</AlertTitle>
                      {`${error}`}
                    </Alert>
                  </Grid>
                )}
                <Grid item className={classes.formGridItem}>
                  <ConardTextField
                    id="username"
                    name="username"
                    type="text"
                    defaultValue=""
                    label={t('pages.login.username.label')}
                    control={control}
                    error={!!errors.username}
                    helperText={errors.username ? errors.username.message : ' '}
                  />
                </Grid>
                <Grid item className={classes.formGridItem}>
                  <ConardTextField
                    id="password"
                    name="password"
                    defaultValue=""
                    type={showPassword ? 'text' : 'password'}
                    label={t('pages.login.password.label')}
                    control={control}
                    error={!!errors.password}
                    helperText={errors.password ? errors.password.message : ' '}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item className={classes.submitButton}>
                  <ConardButton type="submit" conardVariant="light">
                    {t('pages.login.form.loginAction')}
                  </ConardButton>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2" color="secondary">
            {t('any.version.label', { version: appInfo.version })}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2" color="secondary">
            {t('any.copyright.label')}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default LoginPage
