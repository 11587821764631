import React, { FC } from 'react'
import { Button, ButtonProps, createStyles, makeStyles, Theme } from '@material-ui/core'

type ConardButtonVariant = 'white' | 'light' | 'dark' | 'transparent'

type ConardUploadButtonPropsUnited = ConardButtonProps & ButtonProps & Omit<ButtonProps, 'type' | 'autoFocus'>

interface ConardButtonProps {
  conardVariant: ConardButtonVariant
  type?: 'button' | 'reset' | 'submit'
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const whiteButtonStyles = makeStyles((theme) =>
  createStyles({
    root: {
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: 20,
      padding: '0.2vh 2vw',
    },
    label: {
      color: theme.palette.secondary.main,
    },
  })
)

const lightButtonStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.primary.main,
      borderRadius: 20,
      padding: '0.2vh 2vw',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    label: (props) => ({
      color: props.disabled ? theme.palette.grey.A200 : theme.palette.secondary.main,
      margin: '5px 20px',
    }),
  })
)

const darkButtonStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.primary.main,
      borderRadius: 20,
      padding: '0.2vh 2vw',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    label: (props) => ({
      color: props.disabled ? theme.palette.grey.A200 : theme.palette.secondary.main,
      margin: '5px 20px',
    }),
  })
)

const transparentButtonStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    root: (props) => ({
      width: '100%',
      height: '100%',
      border: '1px solid',
      borderColor: props.disabled ? theme.palette.grey.A200 : theme.palette.primary.main,
      borderRadius: 30,
      padding: '0.2vh 2vw',
    }),
    label: (props) => ({
      color: props.disabled ? theme.palette.grey.A200 : theme.palette.primary.main,
      margin: '4px 20px',
    }),
  })
)

interface StyleProps {
  disabled: boolean
}

const ConardUploadButton: FC<ConardUploadButtonPropsUnited> = (props) => {
  const { conardVariant, onClick, startIcon, endIcon, disabled = false } = props

  const whiteButtonClasses = whiteButtonStyles()
  const lightButtonClasses = lightButtonStyles({ disabled: disabled })
  const darkButtonClasses = darkButtonStyles({ disabled: disabled })
  const transparentButtonClasses = transparentButtonStyles({ disabled: disabled })

  const useClasses = () => {
    switch (conardVariant) {
      case 'white':
        return whiteButtonClasses
      case 'light':
        return lightButtonClasses
      case 'dark':
        return darkButtonClasses
      case 'transparent':
        return transparentButtonClasses
    }
  }

  return (
    <Button
      component="span"
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      classes={useClasses()}
    >
      {props.children}
    </Button>
  )
}

export default ConardUploadButton
