import { makeStyles } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React, { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import {
  CarrierShortDto,
  ContainerDtoContainerTypeEnum,
  DriverShortDto,
  LicensePlateDto,
  NotificationDto,
  TransitionDto,
  TransitionDtoDirectionEnum,
} from '../../api'
import ConardTransitionForm from '../../components/forms/ConardTransitionForm'
import { TransitionMode } from '../../enums/TransitionMode'
import { setTransition, transitionInitialState } from '../../redux/transition/transitionSlice'
import { RootState, useAppDispatch } from '../../redux/store'
import { useSelector } from 'react-redux'
import gateOutService from '../../services/GateOutService'
import { ConfigContext } from '../../context/ConfigContext'

const useStyles = makeStyles({
  alert: {
    margin: 'auto',
    width: '50vw',
    marginTop: '20vh',
  },
})

interface UrlParamTypes {
  id?: string
  driverId?: string
}

interface GateOutSimpleEditPageState {
  notification?: NotificationDto
  driver?: DriverShortDto
  carrier?: CarrierShortDto
  frontLicensePlate?: LicensePlateDto
  rearLicensePlate?: LicensePlateDto
}

const GateOutSimpleEditPage: FC = () => {
  const { id, driverId } = useParams<UrlParamTypes>()
  const location = useLocation<GateOutSimpleEditPageState>()
  const { t } = useTranslation()
  const classes = useStyles()
  const [loaded, setLoaded] = useState(false)
  const dispatch = useAppDispatch()
  const transition = useSelector<RootState, TransitionDto>((state) => state.transition.transition)
  const [locationNotification] = useState<NotificationDto | undefined>(location.state?.notification)
  const configContext = useContext(ConfigContext)

  const fetchData = async () => {
    try {
      let response

      if (driverId) {
        response = await gateOutService.getDriverTransitionGateOut(Number(driverId))
      }

      if (id) {
        response = await gateOutService.findWithReceiptById(Number.parseInt(id))
      }

      const loadedTransition = response?.data

      if (loadedTransition === undefined) {
        dispatch(setTransition(transitionInitialState.transition))
      } else {
        dispatch(
          setTransition({
            ...loadedTransition,
            container: {
              ...loadedTransition.container,
              heavyTested:
                loadedTransition.container.heavyTested ||
                !!loadedTransition.container.heavyTestedValue ||
                (configContext.heavyTested && !loadedTransition.repleted),
              iluPhoto: loadedTransition.container.iluPhoto ?? transition.container.iluPhoto,
            },
            transitionDateTime: new Date().toISOString(),
            damageLogEntries: loadedTransition.id === transition.id ? transition.damageLogEntries : undefined,
            direction: TransitionDtoDirectionEnum.Out,
            driverShort: location?.state?.driver,
            carrierShort: location?.state?.carrier,
            frontLicensePlate: location?.state?.frontLicensePlate,
            rearLicensePlate:
              loadedTransition.container.containerType === ContainerDtoContainerTypeEnum.ShippingContainer
                ? location?.state?.rearLicensePlate
                : undefined,
            agency: false,
          })
        )
      }

      setLoaded(true)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    fetchData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {loaded && !transition.id && (
        <Alert severity="error" className={classes.alert}>
          {t('be.error.entity.notFound')}
        </Alert>
      )}
      {loaded && transition.id && (
        <ConardTransitionForm
          transitionMode={TransitionMode.GATE_OUT}
          containerType={transition?.container.containerType ?? ContainerDtoContainerTypeEnum.ShippingContainer}
          notification={locationNotification}
        />
      )}
    </>
  )
}

export default GateOutSimpleEditPage
