import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import {
  Box,
  createStyles,
  Divider,
  IconButton,
  makeStyles,
  Paper,
  Slide,
  Switch,
  Theme,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../redux/store'
import { setNotificationsDisabled, setShowNotifications } from '../../redux/notification/notificationSlice'
import {
  GateDtoGateTypeEnum,
  NotificationDto,
  NotificationDtoNotificationTypeEnum,
  PageNotificationDto,
  UserDtoRoleEnum,
} from '../../api'
import { NotificationButtonsByNotificationType } from './NotificationButtonsByNotificationType'
import { Close } from '@material-ui/icons'
import NotificationBody from './NotificationBody'
import notificationService from '../../services/NotificationService'
import DeleteIcon from '@material-ui/icons/Delete'
import NotificationRemoveAllDialog from './NotificationRemoveAllDialog'
import { toast } from 'react-toastify'
import userService from '../../services/UserService'
import { useConardAuth } from '../../hooks/useConardAuth'
import { Pagination } from '@mui/material'

const borderSize = '5px solid'
const borderRadiusSize = '10px'
const marginSize = 2

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'fixed',
      overflowY: 'auto',
      top: 0,
      bottom: 0,
      left: '12vw',
      borderRadius: 0,
      width: '22vw',
      height: '100%',
      zIndex: 200,
    },
    paperDisabled: {
      backgroundColor: theme.palette.grey.A100,
    },
    titleBox: {
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    notificationBox: {
      padding: theme.spacing(1),
    },
    wrapperGate1: {
      margin: marginSize,
      borderRadius: borderRadiusSize,
      border: borderSize + '#f6d347',
    },
    wrapperGate2In: {
      margin: marginSize,
      borderRadius: borderRadiusSize,
      border: borderSize + `${theme.palette.primary.main}`,
    },
    wrapperGate2Out: {
      margin: marginSize,
      borderRadius: borderRadiusSize,
      border: borderSize + '#67f647',
    },
    wrapperGate3: {
      margin: marginSize,
      borderRadius: borderRadiusSize,
      border: borderSize + '#f68b47',
    },
    wrapperGreenPass: {
      borderRadius: borderRadiusSize,
      border: '3px dashed' + `${theme.palette.primary.main}`,
    },
    buttonsBox: {
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'space-evenly',
      flexWrap: 'wrap',
    },
    paginationBox: {
      padding: 8,
      display: 'flex',
      justifyContent: 'center',
    },
    switchRoot: {
      '& .MuiSwitch-colorSecondary.Mui-checked': {
        color: theme.palette.primary.main,
      },
      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  })
)

const NotificationList: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const auth = useConardAuth()

  const showNotifications = useSelector<RootState, boolean>((state) => state.notification.showNotifications)
  const storageNotifications = useSelector<RootState, PageNotificationDto | undefined>(
    (state) => state.notification.notifications
  )
  const notificationsDisabled = useSelector<RootState, boolean>((state) => state.notification.notificationsDisabled)
  const [notifications, setNotifications] = useState(storageNotifications)
  const [notificationsForRemove, setNotificationsForRemove] = useState<number[]>([])
  const [pageNumber, setPageNumber] = useState<number>(0)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [showRemoveAllDialog, setShowRemoveAllDialog] = useState<boolean>(false)
  const [unchecked, setUnchecked] = useState<boolean>(notificationsDisabled)

  const backgroundColorByNotificationType = (notification: NotificationDto) => {
    if (
      new Set([
        NotificationDtoNotificationTypeEnum.FailedGreenPass,
        NotificationDtoNotificationTypeEnum.SuccessfulGreenPass,
      ]).has(notification.notificationType)
    ) {
      return classes.wrapperGreenPass
    }

    switch (notification.gate?.gateNumber) {
      case 1:
        return classes.wrapperGate1
      case 2:
        if (notification.gate?.gateType === GateDtoGateTypeEnum.In) {
          return classes.wrapperGate2In
        }
        return classes.wrapperGate2Out
      case 3:
        return classes.wrapperGate3
    }

    return undefined
  }

  const handleClose = () => {
    dispatch(setShowNotifications(false))
  }

  const handlePageChange = (event: ChangeEvent<unknown>, page: number) => {
    setPageNumber(page - 1)
  }

  const handleShowRemoveAllDialog = () => {
    let notificationsForRemoval: number[] = []
    notifications?.content?.map((notification) => {
      if (notification.id) {
        notificationsForRemoval = [...notificationsForRemoval, notification.id]
        toast.dismiss(notification.id)
      }
    })
    setNotificationsForRemove(notificationsForRemoval)
    setShowRemoveAllDialog(true)
  }

  const handleRemoveAll = () => {
    notificationService.removeAll(notificationsForRemove).then(() => {
      setShowRemoveAllDialog(false)
      searchNotifications()
    })
  }

  const searchNotifications = () => {
    if (auth.getRole() === UserDtoRoleEnum.DispatcherRoad) {
      notificationService.findAllForRoad(pageNumber, 20, ['createdAt,desc']).then((response) => {
        setTotalPages(response.data.totalPages ?? 0)
        setNotifications(response.data)
      })
    } else if (auth.getRole() === UserDtoRoleEnum.DispatcherTrain) {
      notificationService.findAllForTrain(pageNumber, 20, ['createdAt,desc']).then((response) => {
        setTotalPages(response.data.totalPages ?? 0)
        setNotifications(response.data)
      })
    } else {
      notificationService.search(pageNumber, 20, ['createdAt,desc'], { closedAt: undefined }).then((response) => {
        setTotalPages(response.data.totalPages ?? 0)
        setNotifications(response.data)
      })
    }
  }

  useEffect(() => {
    if (!notificationsDisabled) {
      searchNotifications()
    }
  }, [pageNumber, showNotifications, storageNotifications?.totalElements, notificationsDisabled]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    userService.userUpdateNotificationsDisabled(auth.getUserId() ?? -1, unchecked).then(() => {
      dispatch(setNotificationsDisabled(unchecked))
      if (unchecked) {
        setTotalPages(0)
        setNotifications(undefined)
      }
    })
  }, [unchecked]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Slide in={showNotifications} direction="right">
      <Paper className={notificationsDisabled ? `${classes.paper} ${classes.paperDisabled}` : classes.paper}>
        <Box className={classes.titleBox}>
          <Typography variant="h6" color="primary">
            {t('pages.notifications.title')}
          </Typography>
          <Box>
            <IconButton
              disabled={notificationsDisabled || notifications?.content?.length === 0}
              onClick={handleShowRemoveAllDialog}
            >
              <DeleteIcon />
            </IconButton>
            <Switch
              className={classes.switchRoot}
              checked={!unchecked}
              onChange={(event) => setUnchecked(!event.target.checked)}
              name="notificationsDisabled"
            />
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
        </Box>
        {notifications?.content?.map((notification) => (
          <React.Fragment key={notification.id}>
            <Box className={backgroundColorByNotificationType(notification)}>
              <Box className={classes.notificationBox}>
                <Typography variant="subtitle2">
                  <NotificationBody notification={notification} />
                </Typography>
                <Box className={classes.buttonsBox}>
                  <NotificationButtonsByNotificationType
                    notification={notification}
                    refreshList={searchNotifications}
                  />
                </Box>
              </Box>
            </Box>
            <Divider />
          </React.Fragment>
        ))}
        {totalPages !== 0 && (
          <Box className={classes.paginationBox}>
            <Pagination count={totalPages} onChange={(event, page) => handlePageChange(event, page)} size="small" />
          </Box>
        )}
        <NotificationRemoveAllDialog
          showRemoveAllDialog={showRemoveAllDialog}
          onClose={() => setShowRemoveAllDialog(false)}
          onRemoveAll={handleRemoveAll}
        />
      </Paper>
    </Slide>
  )
}

export default NotificationList
