import {
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { format } from 'date-fns'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ContainerDtoContainerTypeEnum, DamageLogEntryDto, TransitionDto } from '../../api'
import ConardButton from '../ConardButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles({
  table: {
    minWidth: '80vh',
  },
  gridContainer: {
    marginTop: '4vh',
    alignItems: 'center',
  },
  noData: {
    textAlign: 'center',
  },
  imageCell: {
    width: '10vw',
  },
  image: {
    width: '100%',
  },
  noteCell: {
    inlineSize: '200px',
    overflowWrap: 'break-word',
    hyphens: 'auto',
  },
  backButton: {
    float: 'right',
    width: '15vw',
    minWidth: '300px',
    height: '5vh',
    minHeight: '50px',
    margin: '20px',
  },
})

interface HeadCell {
  id: string
  label: string
}

interface DamageLogDamagesListProps {
  title: string
  damageLogEntries: DamageLogEntryDto[]
  transition: TransitionDto
}

const DamageLogDamagesList: FC<DamageLogDamagesListProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const { title, damageLogEntries, transition } = props

  const headCells: HeadCell[] = [
    { id: 'photo', label: t('entity.damageLog.fields.damagePhoto') },
    { id: 'side', label: t('entity.damageLog.fields.side') },
    { id: 'containerType', label: t('entity.damageLog.fields.damageType') },
    { id: 'note', label: t('entity.damageLog.fields.note') },
    {
      id: 'causedDamageCz',
      label:
        t('pages.damageLogManagement.labels.causedDamage.title') +
        ' ' +
        t('pages.damageLogManagement.labels.causedDamage.fields.cz'),
    },
    {
      id: 'causedDamageEn',
      label:
        t('pages.damageLogManagement.labels.causedDamage.title') +
        ' ' +
        t('pages.damageLogManagement.labels.causedDamage.fields.en'),
    },
    { id: 'created', label: t('pages.damageLogManagement.labels.created.title') },
  ]

  const getInSideAndDamageType = (entry: DamageLogEntryDto, transition: TransitionDto) => {
    const inside = entry.inside
      ? t('entity.damageLog.fields.inside.checked')
      : t('entity.damageLog.fields.inside.unchecked')

    const damageType =
      transition.container.containerType === ContainerDtoContainerTypeEnum.Semitrailer
        ? t(`enum.damageTypeSemitrailer.${entry.damageTypeSemitrailer}`)
        : t(`enum.damageTypeShippingContainer.${entry.damageTypeShippingContainer}`)

    return `${inside} - ${damageType}`
  }

  const handleBackButton = () => {
    history.goBack()
  }

  return (
    <>
      <Grid container direction="row" justifyContent="center" alignItems="flex-start" className={classes.gridContainer}>
        <Grid item xs={6}>
          <Typography color="primary" variant="h4">
            {title}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <ConardButton
            conardVariant="dark"
            className={classes.backButton}
            startIcon={<ArrowBackIcon />}
            onClick={handleBackButton}
          >
            {t('any.buttons.back')}
          </ConardButton>
        </Grid>
        <Grid item xl={12} lg={12} md={11} sm={11} xs={11}>
          <Paper variant="outlined">
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id}>{headCell.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {damageLogEntries?.map((entry) => (
                    <TableRow key={entry.id}>
                      <TableCell className={classes.imageCell}>
                        <img src={entry.photo} className={classes.image} alt="" />
                      </TableCell>
                      <TableCell>{t(`enum.damageLogEntrySide.${entry.side}`)}</TableCell>
                      <TableCell>{getInSideAndDamageType(entry, transition)}</TableCell>
                      <TableCell className={classes.noteCell}>{entry.note}</TableCell>
                      <TableCell>{entry.causedDamageCz}</TableCell>
                      <TableCell>{entry.causedDamageEn}</TableCell>
                      <TableCell>{format(new Date(entry.created ?? new Date()), 'dd.MM.yyyy')}</TableCell>
                    </TableRow>
                  ))}

                  {(transition === undefined || damageLogEntries === undefined || damageLogEntries.length === 0) && (
                    <TableRow>
                      <TableCell className={classes.noData} colSpan={7}>
                        {t('pages.common.table.noData')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default DamageLogDamagesList
