import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import {
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Theme,
  Tooltip,
} from '@material-ui/core'
import {
  PageTransitionSearchListDto,
  TransitionCustomsInfoSearchListDtoStateEnum,
  TransitionDto,
  TransitionSearchListDto,
  TransitionSearchListDtoContainerTypeEnum,
  TransitionSearchListSearchDto,
} from '../../api'
import { useTranslation } from 'react-i18next'
import ConardButton from '../ConardButton'
import { Order } from '../../enums/Order'
import GateOutTransitionSelectionFilter from './GateOutTransitionSelectionFilter'
import { cleanObject, cleanShippingContainerType } from '../../utils/utils'
import gateOutService from '../../services/GateOutService'
import WarningAmberIcon from '@material-ui/icons/Warning'
import { ContainerDamagedIcon } from '../../assets/icons/ContainerDamagedIcon'
import { ContainerIcon } from '../../assets/icons/ContainerIcon'
import { SemitrailerDamagedIcon } from '../../assets/icons/SemitrailerDamagedIcon'
import { SemitrailerIcon } from '../../assets/icons/SemitrailerIcon'
import transitionService from '../../services/TransitionService'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: '50vh',
    },
    gridContainer: {
      marginTop: '1vh',
    },
    noData: {
      textAlign: 'center',
    },
    tooltip: {
      background: 'rgba(0,0,0,0.87)',
      fontSize: '15px',
      padding: '10px',
      borderRadius: '10px',
    },
    customsWarningIcon: {
      color: theme.palette.error.main,
    },
  })
)

interface HeadCell {
  id: string
  label: string
}

export interface GateOutTransitionSelectionModalProps {
  open: boolean
  onClose: () => void
  onSave: (transitionDto: TransitionDto) => void
}

export const GateOutTransitionSelectionModal: FC<GateOutTransitionSelectionModalProps> = ({
  open,
  onClose,
  onSave,
}) => {
  const [pageTransition, setPageTransition] = useState<PageTransitionSearchListDto>()
  const [selectedTransitionDto, setSelectedTransitionDto] = useState<TransitionSearchListDto>()
  const [searchTransition, setSearchTransition] = useState<TransitionSearchListSearchDto | undefined>()
  const [order, setOrder] = useState(Order.Desc)
  const [orderBy, setOrderBy] = useState('transitionDateTime')
  const [page, setPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)
  const { t } = useTranslation()
  const classes = useStyles()

  const headCells: HeadCell[] = [
    {
      id: 'container.shippingContainerType,container.semitrailerType',
      label: t('pages.gateOut.form.containerType.label'),
    },
    { id: 'container.iluCode', label: t('pages.gateOut.form.iluCode.label') },
    {
      id: 'repleted',
      label: t('pages.gateIn.form.repleted.checked') + ' / ' + t('pages.gateIn.form.repleted.unchecked'),
    },
    { id: 'container.company.name', label: t('pages.gateOut.form.company.label') },
  ]

  const closeModal = () => {
    setSelectedTransitionDto(undefined)
    onClose()
  }

  const save = () => {
    if (selectedTransitionDto) {
      transitionService
        .findById(selectedTransitionDto.id ?? -1)
        .then((response) => onSave(response.data))
        .catch()
        .finally(() => setSelectedTransitionDto(undefined))
    }
  }

  const onSort = (newOrderBy: string) => () => {
    setOrderBy(newOrderBy)
    setOrder(orderBy === newOrderBy ? (order === Order.Asc ? Order.Desc : Order.Asc) : Order.Asc)
  }

  const onPage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage)
  }

  const onRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value, 10))
    setPage(0)
  }

  const searchFiltered = (data: TransitionSearchListSearchDto | undefined) => {
    setSearchTransition(data)
    search(data, true)
  }

  const search = (data: TransitionSearchListSearchDto | undefined, fromFilter: boolean) => {
    data = {
      ...data,
      container: {
        ...cleanObject(data?.container),
        shipowner: {
          ...cleanObject(data?.container?.shipowner),
        },
        company: {
          ...cleanObject(data?.container?.company),
        },
      },
    }

    let currentSort: Array<string> | undefined = undefined
    if (orderBy !== undefined) {
      const currentOrder: string = order !== undefined ? order : 'asc'
      currentSort = [orderBy + ',' + currentOrder]
    }

    gateOutService
      .simpleTransitionList(page, pageSize, currentSort, cleanObject(data))
      .then((response) => {
        setPageTransition(response.data)
        if (fromFilter) {
          setPage(0)
        }
      })
      .catch()
  }

  useEffect(() => {
    if (open) {
      search(searchTransition, false)
    }
  }, [open, page, pageSize, order, orderBy]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog onClose={closeModal} aria-labelledby="simple-dialog-title" open={open} maxWidth="md">
      <DialogTitle id="simple-dialog-title">{t('pages.gateOut.coparn.modal.title')}</DialogTitle>
      <DialogContent>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GateOutTransitionSelectionFilter transitionFilter={searchFiltered} />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          className={classes.gridContainer}
        >
          <br />
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell />
                  {headCells.map((headCell) => (
                    <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : Order.Asc}
                        onClick={onSort(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {!!pageTransition?.content?.length ? (
                  pageTransition?.content?.map((transitionDto: TransitionSearchListDto) => {
                    return (
                      <TableRow key={transitionDto.id}>
                        <TableCell align="center">
                          {transitionDto?.transitionCustomsInfo?.state !==
                            TransitionCustomsInfoSearchListDtoStateEnum.Stop && (
                            <Checkbox
                              color="primary"
                              checked={selectedTransitionDto?.id === transitionDto.id}
                              onChange={() => setSelectedTransitionDto(transitionDto)}
                            />
                          )}
                          {transitionDto?.transitionCustomsInfo?.state ===
                            TransitionCustomsInfoSearchListDtoStateEnum.Stop && (
                            <Tooltip
                              classes={{ tooltip: classes.tooltip }}
                              enterDelay={0}
                              placement="bottom"
                              title={t('pages.gateOut.buttons.customsStop') ?? ''}
                            >
                              <WarningAmberIcon className={classes.customsWarningIcon} />
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell>
                          {t('enum.containerType.' + transitionDto.containerType)} -{' '}
                          {transitionDto.containerType === 'SHIPPING_CONTAINER'
                            ? cleanShippingContainerType(transitionDto.shippingContainerType)
                            : transitionDto.semitrailerType}
                        </TableCell>
                        <TableCell>{transitionDto.iluCode}</TableCell>
                        <TableCell>
                          {transitionDto.repleted
                            ? t('pages.gateIn.form.repleted.checked')
                            : t('pages.gateIn.form.repleted.unchecked')}
                        </TableCell>
                        <TableCell>{transitionDto.companyName}</TableCell>
                        <TableCell>
                          {transitionDto.damaged ? (
                            transitionDto.containerType ===
                            TransitionSearchListDtoContainerTypeEnum.ShippingContainer ? (
                              <ContainerDamagedIcon />
                            ) : (
                              <SemitrailerDamagedIcon />
                            )
                          ) : transitionDto.containerType ===
                            TransitionSearchListDtoContainerTypeEnum.ShippingContainer ? (
                            <ContainerIcon />
                          ) : (
                            <SemitrailerIcon />
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell className={classes.noData} colSpan={8}>
                      {t('pages.common.table.noDataAvailable')}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={pageTransition?.totalElements || 0}
            onPageChange={onPage}
            page={page}
            rowsPerPage={pageSize}
            labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
            rowsPerPageOptions={[10, 20, 50, 150]}
            onRowsPerPageChange={onRowsPerPage}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <ConardButton conardVariant="transparent" onClick={closeModal}>
          {t('any.buttons.back')}
        </ConardButton>
        <ConardButton conardVariant="dark" onClick={save} disabled={!selectedTransitionDto}>
          {t('any.buttons.select')}
        </ConardButton>
      </DialogActions>
    </Dialog>
  )
}
