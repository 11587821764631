import React, { FC } from 'react'
import { DriverArrivalDto } from '../../../api'
import { TableCell, TableRow, createStyles, makeStyles } from '@material-ui/core'
import { ConardDateTimePicker } from '../../../components/shared/ConardDateTimePicker'
import { useTranslation } from 'react-i18next'
import { TransitionCard } from '../../drivers/arrival-page/transition-card/TransitionCard'
import ConardButton from '../../../components/ConardButton'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { SemaphoreInfo } from '../../drivers/arrival-page/SemaphoreInfo'

const useStyles = makeStyles((theme) =>
  createStyles({
    deleteButton: {
      borderRadius: 12,
      padding: '8px',
    },
    driverNameCell: {
      minWidth: '108px',
      color: theme.palette.primary.main,
      fontSize: 24,
      fontWeight: 650,
    },
    transitionCell: {
      alignItems: 'center',
      [theme.breakpoints.down('lg')]: {
        width: '340px',
      },
    },
    disabledDeleteButton: {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
    buttonsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
    },
  })
)

interface CarrierRowProps {
  driverId: number
  driverName: string
  data: DriverArrivalDto | undefined

  onChangeArrivalDateTime: (date: MaterialUiPickersDate) => void
  onDeleteAll: () => void
  onGateInNoCargo: () => void
  onGateOutNoCargo: () => void
  onGateIn: () => void
  onGateOut: () => void
  onEditGateIn: () => void
  onEditGateOut: () => void
  onDeleteGateIn: () => void
  onDeleteGateOut: () => void
}

export const CarrierRow: FC<CarrierRowProps> = ({
  driverId,
  driverName,
  onChangeArrivalDateTime,
  onDeleteAll,
  onEditGateIn,
  onEditGateOut,
  onGateIn,
  onGateOut,
  onGateInNoCargo,
  onGateOutNoCargo,
  data,
  onDeleteGateIn,
  onDeleteGateOut,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const isDisabled = !data?.arrivalDateTime

  return (
    <>
      <TableRow key={driverId}>
        <TableCell component="th" scope="row">
          <div className={classes.driverNameCell}>{driverName}</div>
        </TableCell>
        <TableCell component="th" scope="row">
          <ConardDateTimePicker
            name="arrivaDateTime"
            placeholder={t('driverApp.driverArrival.dateTimePlaceholder')}
            ampm={false}
            disablePast
            onChange={(date) => onChangeArrivalDateTime(date)}
            value={data?.arrivalDateTime ? new Date(data?.arrivalDateTime) : null}
            hideAdornmentDown="lg"
          />
        </TableCell>
        <TableCell>
          <SemaphoreInfo driverPass={data?.driverPass} isCarrier instantGreenPass={data?.instantGreenPass ?? false} />
        </TableCell>
        <TableCell component="th" scope="row" className={classes.transitionCell}>
          <TransitionCard
            isCarrier
            key={driverId}
            data={data}
            disabled={isDisabled}
            onEditGateIn={onEditGateIn}
            onEditGateOut={onEditGateOut}
            onGateIn={onGateIn}
            onGateOUt={onGateOut}
            onGateInNoCargo={onGateInNoCargo}
            onGateOutNoCargo={onGateOutNoCargo}
          />
        </TableCell>
        <TableCell>
          <div className={classes.buttonsWrapper}>
            <ConardButton
              conardVariant="red"
              disabled={isDisabled}
              onClick={onDeleteGateIn}
              className={`${classes.deleteButton} ${isDisabled ? classes.disabledDeleteButton : ''}`}
            >
              {t('driverApp.driverArrival.buttons.deleteGateIn')}
            </ConardButton>
            <ConardButton
              conardVariant="red"
              disabled={isDisabled}
              onClick={onDeleteGateOut}
              className={`${classes.deleteButton} ${isDisabled ? classes.disabledDeleteButton : ''}`}
            >
              {t('driverApp.driverArrival.buttons.deleteGateOut')}
            </ConardButton>
            <ConardButton
              conardVariant="red"
              type="reset"
              disabled={isDisabled}
              onClick={onDeleteAll}
              className={`${classes.deleteButton} ${isDisabled ? classes.disabledDeleteButton : ''}`}
            >
              {t('driverApp.driverArrival.buttons.deleteAll')}
            </ConardButton>
          </div>
        </TableCell>
      </TableRow>
    </>
  )
}
