import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { createStyles, Grid, GridSize, Paper, Typography } from '@material-ui/core'
import StorageIcon from '@material-ui/icons/Storage'
import LoopIcon from '@material-ui/icons/Loop'
import {
  TrainDto,
  TrainDtoTrainStateEnum,
  TrainDtoTrainTypeEnum,
  TrainWagonContentDto,
  TrainWagonDto,
  TransitionSearchListDtoQualityTypeEnum,
  TransitionSimpleDto,
  UserDtoRoleEnum,
} from '../../api'
import ConardTrainWagonForm from './ConardTrainWagonForm'
import ConardTextField from '../ConardTextField'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import ConardButton from '../ConardButton'
import trainService from '../../services/TrainService'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { TrainMode } from '../../enums/TrainMode'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../redux/store'
import {
  setOnTerminal,
  setSelectedUnseatedTransition,
  setTrain,
  setTrainName,
  shiftWagons,
} from '../../redux/train/trainSlice'
import ConardUploadButton from '../ConardUploadButton'
import ConardSwitch from '../ConardSwitch'
import ConardDateTimePicker from '../ConardDateTimePicker'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { ConfigContext } from '../../context/ConfigContext'
import TrainApproveGateOutDialog from '../dialogs/TrainApproveGateOutDialog'
import TrainWrongIluDialog from '../dialogs/TrainWrongIluDialog'
import TrainExportBox from '../train/TrainExportBox'
import CdCargoBox from '../train/CdCargoBox'
import CodecoSendBox from '../train/CodecoSendBox'
import VeronaBox from '../train/VeronaBox'
import { Add } from '@mui/icons-material'
import { useConardAuth } from '../../hooks/useConardAuth'
import GetAppIcon from '@material-ui/icons/GetApp'
import { cleanObject, convertToTons, getDateWithZeroSeconds, renderContainerType } from '../../utils/utils'
import TrainImportDialog from '../dialogs/TrainImportDialog'
import trainGateInService from '../../services/TrainGateInService'
import trainGateOutService from '../../services/TrainGateOutService'
import ConardTrainContainerIcon from '../train/ConardTrainContainerIcon'
import { AxiosResponse } from 'axios'

const useStyles = makeStyles((theme) =>
  createStyles({
    containerGrid: {
      marginTop: '2vh',
    },
    containerGridAddItem: {
      padding: '2vw',
    },
    input: {
      display: 'none',
    },
    unseatedContentGrid: {
      marginTop: '2vh',
    },
    unseatedContentGridOverflow: {
      overflowY: 'auto',
      maxHeight: '73vh',
    },
    unseatedContentPaper: {
      height: '4vh',
      minHeight: '35px',
      margin: '1vh 0vw',
    },
    unseatedContentPaperGridSelected: {
      height: '100%',
      backgroundColor: theme.palette.info.main,
    },
    unseatedContentPaperGrid: {
      height: '100%',
    },
    unseatedContentPaperGridUnloaded: {
      height: '100%',
      backgroundColor: theme.palette.warning.light,
    },
    unseatedGrid: {
      display: 'flex',
      alignItems: 'center',
    },
    unseatedTypography: {
      marginRight: '1vh',
    },
    controls: {
      [theme.breakpoints.up('sm')]: {
        left: '10vw',
      },
      [theme.breakpoints.down('xs')]: {
        left: '0px',
      },
      position: 'fixed',
      bottom: 0,
      width: '100vw',
      backgroundColor: theme.palette.secondary.main,
      zIndex: 100,
    },
    submitButton: {
      width: '16vw',
      minWidth: '200px',
      textAlign: 'center',
      marginTop: '2vh',
      marginBottom: '2vh',
      marginLeft: '5vw',
    },
    rotateButton: {
      height: '56px',
    },
    wagonGrid: {
      overflowY: 'auto',
      maxHeight: 'calc(73vh - 250px)',
    },
    outlinedIcon: {
      color: theme.palette.primary.main,
    },
    containerInfoTypography: {
      color: theme.palette.primary.main,
      fontWeight: 550,
      fontSize: 14,
      marginLeft: '1rem',
    },
  })
)

export enum MoveDirection {
  UP = 'up',
  DOWN = 'down',
}

export const OrderWagonsFrom = 1

interface ConardTrainFormProps {
  mode: TrainMode
  onCreateWagonContainer?: (trainWagon: TrainWagonDto, trainWagonContent: TrainWagonContentDto) => void
  onCreateWagonSemitrailer?: (trainWagon: TrainWagonDto, trainWagonContent: TrainWagonContentDto) => void
  onEditWagonContainer?: (trainWagon: TrainWagonDto, trainWagonContent: TrainWagonContentDto) => void
  onEditWagonSemitrailer?: (trainWagon: TrainWagonDto, trainWagonContent: TrainWagonContentDto) => void
  onLoadContainer?: (
    trainWagon: TrainWagonDto,
    trainWagonContent: TrainWagonContentDto,
    transitionId: number,
    qualityType: TransitionSearchListDtoQualityTypeEnum | undefined
  ) => void
}

const ConardTrainForm: FC<ConardTrainFormProps> = ({
  mode,
  onCreateWagonContainer,
  onCreateWagonSemitrailer,
  onEditWagonContainer,
  onEditWagonSemitrailer,
}) => {
  const auth = useConardAuth()
  const { customsClearance, veronaTrain, mscActive } = useContext(ConfigContext)

  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()

  const train = useSelector<RootState, TrainDto>((state) => state.train.train)
  const selectedUnseatedTransition = useSelector<RootState, TransitionSimpleDto | null>(
    (state) => state.train.selectedUnseatedTransition
  )
  const trainSubmitDisabled = useSelector<RootState, boolean>((state) => state.train.trainSubmitDisabled)
  const onTerminal = useSelector<RootState, boolean>((state) => state.train.onTerminal)

  const dispatch = useAppDispatch()
  const [trainState, setTrainState] = useState<TrainDtoTrainStateEnum | undefined>(undefined)
  const [trainWeight, setTrainWeight] = useState<number>(0)
  const [trainLength, setTrainLength] = useState<number>(0)
  const [delay, setDelay] = useState('')
  const [unseatedCount, setUnseatedCount] = useState<number>(0)
  const [store, setStore] = useState<boolean>(false)

  const [loaded, setLoaded] = useState(false)
  const [formSize, setFormSize] = useState<GridSize>(8)

  const [showApproveGateOutDialog, setShowApproveGateOutDialog] = useState(false)
  const [showTrainImportDialog, setShowTrainImportDialog] = useState(false)

  const [wrongIluCodes, setWrongIluCodes] = useState<Array<string>>([])
  const [trainToSubmit, setTrainToSubmit] = useState<TrainDto | undefined>(undefined)
  const [trainModel, setTrainModel] = useState<File | undefined>(undefined)
  const trainAndBasicDispatcher = new Set([UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherTrain])

  const yupSchema = yup.object().shape({
    name: yup
      .string()
      .required(t('pages.train.validations.trainName.required'))
      .min(3, t('pages.train.validations.trainName.min', { min: 3 })),
  })

  const { control, errors, handleSubmit, getValues } = useForm<TrainDto>({
    resolver: yupResolver(yupSchema),
    defaultValues: {
      ...train,
      name: train.name,
    },
  })

  const add = () => {
    const newArray = [...(train.wagons ?? [])]
    newArray.push({
      name: t('pages.train.labels.newTrainWagonName'),
      wagonOrder: train.wagons ? train.wagons.length + OrderWagonsFrom : OrderWagonsFrom,
    })

    dispatch(setTrain({ ...train, wagons: newArray }))
  }

  const onFillWagonContent = (wagon: TrainWagonDto, wagonContent: TrainWagonContentDto) => {
    if (!selectedUnseatedTransition || wagonContent.transition.id) {
      return
    }

    const newWagons = train.wagons?.filter((w) => w !== wagon) ?? []
    const newWagonContent = wagon.content?.filter((wc) => wc !== wagonContent) ?? []

    newWagonContent.splice(wagonContent.wagonContentOrder, 0, {
      ...wagonContent,
      transition: selectedUnseatedTransition,
    })

    const newWagonContainerSum =
      (wagon.wagonContainerSum ?? 0) +
      (selectedUnseatedTransition.container.tarraWeight ?? 0) +
      (selectedUnseatedTransition.netWeight ?? 0)

    newWagons.splice(wagon.wagonOrder - OrderWagonsFrom, 0, {
      ...wagon,
      wagonContainerSum: newWagonContainerSum,
      content: newWagonContent,
    })

    dispatch(setSelectedUnseatedTransition(null))

    dispatch(
      setTrain({
        ...train,
        wagons: [...newWagons],
        unseatedTransitions: train.unseatedTransitions?.filter((t) => t.id !== selectedUnseatedTransition.id),
      })
    )
  }

  const toggleUnseatedTransitionSelection = (transition: TransitionSimpleDto) => {
    selectedUnseatedTransition === transition
      ? dispatch(setSelectedUnseatedTransition(null))
      : dispatch(setSelectedUnseatedTransition(transition))
  }

  const onUpload = (e: ChangeEvent<HTMLInputElement>) => {
    setTrainModel(e.currentTarget.files?.[0])
    setShowTrainImportDialog(true)

    // Reset the value of the file input to enable uploading the same file again
    if (e.target) {
      e.target.value = ''
    }
  }

  const onSubmitImportDialog = () => {
    if (!!mode && !!trainModel) {
      const data: TrainDto = getValues()
      updateTrain(data, (response) => {
        dispatch(setTrain(response.data))
        if (mode === TrainMode.GATE_IN) {
          trainGateInService
            .createTrainModel(response.data.id ?? -1, trainModel)
            .then((response) => setTrainFromDialog({ ...response.data }))
        } else if (mode === TrainMode.GATE_OUT) {
          trainGateOutService
            .createTrainModel(response.data.id ?? -1, trainModel)
            .then((response) => setTrainFromDialog(response.data))
        }
      })
    }

    setTrainModel(undefined)
  }

  const showTrainImport = () => {
    switch (train.trainState) {
      case TrainDtoTrainStateEnum.IncomingPlanned:
      case TrainDtoTrainStateEnum.IncomingArrivalCustoms:
      case TrainDtoTrainStateEnum.IncomingAccepted:
      case TrainDtoTrainStateEnum.OutgoingPlanned:
      case TrainDtoTrainStateEnum.OutgoingPrepared:
      case undefined:
        return true
      default:
        return false
    }
  }

  const disableTrainImport = () => {
    let result = false
    if (TrainMode.GATE_OUT === mode && train.wagons !== undefined) {
      result = !train.wagons.every((wagon) => {
        return wagon.content === undefined || wagon.content.length === 0
      })
    }

    return result
  }

  const onChangeTrainType = (event: boolean) => {
    control.setValue('trainType', event ? TrainDtoTrainTypeEnum.Partial : TrainDtoTrainTypeEnum.Complete)
    dispatch(
      setTrain({
        ...train,
        trainType: event ? TrainDtoTrainTypeEnum.Partial : TrainDtoTrainTypeEnum.Complete,
      })
    )
  }

  const onChangeCustomsTrain = (event: boolean) => {
    control.setValue('customsClearance', event)
    dispatch(
      setTrain({
        ...train,
        customsClearance: event,
      })
    )
  }

  const onChangeOnTerminal = (event: boolean) => {
    dispatch(setOnTerminal(event))
  }

  const onChangeDepartureDateTime = (event: MaterialUiPickersDate) => {
    dispatch(
      setTrain({
        ...train,
        departureDateTime: !!event ? event.toISOString() : undefined,
      })
    )
  }

  const onChangeRealDepartureDateTime = (event: MaterialUiPickersDate) => {
    dispatch(
      setTrain({
        ...train,
        realDepartureDateTime: !!event ? event.toISOString() : undefined,
      })
    )
  }

  useEffect(() => {
    const delayNumber =
      new Date(train.realDepartureDateTime ?? '').getTime() - new Date(train.departureDateTime ?? '').getTime()

    let minutes = Math.round(delayNumber / 1000 / 60)
    if (Math.abs(minutes) < 60) {
      setDelay(minutes + ' min')
    } else {
      const hours = minutes > 0 ? Math.floor(minutes / 60) : Math.ceil(minutes / 60)
      minutes = Math.abs(minutes % 60)
      setDelay(hours + ' h ' + minutes + ' min')
    }
  }, [train.departureDateTime, train.realDepartureDateTime, loaded])

  const onChangeTrainName = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(setTrainName(event.target.value))
  }

  const checkBeforeSubmit = (values: TrainDto) => {
    setTrainToSubmit(values)

    if (trainState === TrainDtoTrainStateEnum.OutgoingDispatched) {
      setShowApproveGateOutDialog(true)

      return
    }

    trainService.getInvalidIluCodes(train).then((response) => {
      if (response.data.length === 0) {
        return onSubmit(values)
      }
      setWrongIluCodes(response.data)
    })
  }

  const handleDownloadExport = (values: TrainDto) => {
    trainService.update(train.id ?? 0, values)
    trainService.downloadExport(cleanObject(train.id)).then((response) => {
      const url = window.URL.createObjectURL(response.data)
      const a = document.createElement('a')
      a.href = url
      a.download = 'trainExport_' + train?.id + '.xlsx'
      a.click()
    })
  }

  const finishOnSubmit = (data: TrainDto) => {
    //if there is no trainState to change move to succes page
    if (trainState === undefined) {
      switch (mode) {
        case TrainMode.GATE_IN: {
          if (store) {
            history.replace(`/gate-in/train/${train.id}/store`)
          } else {
            history.replace(`/success/train/${data.id}/gate-in`)
          }
          break
        }
        case TrainMode.GATE_OUT: {
          history.replace(`/success/train/${data.id}/gate-out`)
          break
        }
        default: {
          history.replace(`/success/train/${data.id}/update`)
          break
        }
      }

      return
    }

    //if there is trainState to change call changeState
    switch (mode) {
      case TrainMode.GATE_IN: {
        if (
          new Set([
            TrainDtoTrainStateEnum.IncomingArrivalCustoms,
            TrainDtoTrainStateEnum.IncomingAccepted,
            TrainDtoTrainStateEnum.IncomingFinished,
          ]).has(trainState)
        ) {
          trainService
            .changeState(data.id ?? -1, trainState)
            .then(() => history.replace(`/success/train/${data.id}/gate-in`))
        }
        break
      }
      case TrainMode.GATE_OUT: {
        if (
          new Set([
            TrainDtoTrainStateEnum.OutgoingPrepared,
            TrainDtoTrainStateEnum.OutgoingLoaded,
            TrainDtoTrainStateEnum.OutgoingDispatched,
          ]).has(trainState)
        ) {
          trainService
            .changeState(data.id ?? -1, trainState)
            .then(() => history.replace(`/success/train/${data.id}/gate-out`))
        }
        break
      }
      default: {
        if (trainState === TrainDtoTrainStateEnum.IncomingAccepted) {
          trainService
            .changeState(data.id ?? -1, trainState)
            .then(() => history.replace(`/success/train/${data.id}/update`))
        }
        break
      }
    }
  }

  const onSubmit = (data: TrainDto) => {
    updateTrain(data, (response) => {
      finishOnSubmit(response.data)
    })
  }

  const getWagonWithNewWagonSum = (wagon: TrainWagonDto) => {
    let wagonSum = 0

    wagon.content?.map((wagonContent) => {
      wagonSum =
        wagonSum + (wagonContent.transition.container.tarraWeight ?? 0) + (wagonContent.transition.netWeight ?? 0)
    })

    return {
      ...wagon,
      wagonContainerSum: wagonSum,
    }
  }

  const getDepartureTimeLabel = () => {
    if (mode === TrainMode.GATE_IN && !id) {
      if (onTerminal) {
        return t('pages.train.labels.trainArrivalTime')
      } else {
        return t('pages.train.labels.trainPlannedArrivalTime')
      }
    }

    switch (train.trainState) {
      case TrainDtoTrainStateEnum.IncomingPlanned:
        return t('pages.train.labels.trainPlannedArrivalTime')
      case TrainDtoTrainStateEnum.IncomingAccepted:
      case TrainDtoTrainStateEnum.IncomingFinished:
        return t('pages.train.labels.trainArrivalTime')
      case TrainDtoTrainStateEnum.OutgoingPlanned:
      case TrainDtoTrainStateEnum.OutgoingPrepared:
      case TrainDtoTrainStateEnum.OutgoingLoaded:
        return t('pages.train.labels.trainPlannedDepartureTimeInBrackets')
      case TrainDtoTrainStateEnum.OutgoingDispatched:
        return t('pages.train.labels.trainDepartureTime')
      default:
        return ''
    }
  }

  const isIncoming = (trainState: TrainDtoTrainStateEnum) => {
    return new Set([
      TrainDtoTrainStateEnum.IncomingPlanned,
      TrainDtoTrainStateEnum.IncomingAccepted,
      TrainDtoTrainStateEnum.IncomingFinished,
      TrainDtoTrainStateEnum.IncomingArrivalCustoms,
    ]).has(trainState)
  }

  const getRealDepartureTimeLabel = () => {
    if (isIncoming(train.trainState ?? TrainDtoTrainStateEnum.IncomingPlanned)) {
      return t('pages.train.labels.trainRealArrivalTime')
    }
    return t('pages.train.labels.trainRealDepartureTime')
  }

  const reloadTrainSum = () => {
    const newWagons: TrainWagonDto[] = []

    train.wagons?.map((wagon) => {
      newWagons.push(getWagonWithNewWagonSum(wagon))
    })

    dispatch(
      setTrain({
        ...train,
        wagons: newWagons,
      })
    )
  }

  const reloadTrainWeight = () => {
    let newTrainWeight = 0
    let newTrainLength = 0

    train.wagons?.forEach((wagon) => {
      newTrainWeight += (wagon.wagonContainerSum ?? 0) + (wagon.weight ?? 0)
      newTrainLength += wagon.wagonLength ?? 0
    })

    train.unseatedTransitions?.forEach((transition) => {
      newTrainWeight += (transition.netWeight ?? 0) + (transition.container?.tarraWeight ?? 0)
    })

    setTrainWeight(newTrainWeight)
    setTrainLength(Math.round((newTrainLength + Number.EPSILON) * 100) / 100)
  }

  const reloadUnseatedTransitionCount = () => {
    setUnseatedCount(train.unseatedTransitions?.length ?? 0)
  }

  const setTrainFromDialog = (train: TrainDto) => {
    dispatch(setTrain(train))
  }

  useEffect(() => {
    reloadTrainWeight()
    reloadUnseatedTransitionCount()
  }, [train]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      mode !== TrainMode.GATE_IN &&
      new Set([
        TrainDtoTrainStateEnum.OutgoingPlanned,
        TrainDtoTrainStateEnum.OutgoingPrepared,
        TrainDtoTrainStateEnum.OutgoingLoaded,
        TrainDtoTrainStateEnum.OutgoingDispatched,
        TrainDtoTrainStateEnum.IncomingFinished,
      ]).has(train.trainState ?? TrainDtoTrainStateEnum.IncomingPlanned)
    ) {
      setFormSize(8)
    } else {
      setFormSize(11)
    }

    if (id) {
      trainService
        .findById(Number.parseInt(id))
        .then((response) => {
          setTrain(response.data)
          setLoaded(true)
        })
        .catch(() => {
          setLoaded(true)
        })
    } else {
      setLoaded(true)
    }

    reloadTrainSum()
    reloadTrainWeight()
    reloadUnseatedTransitionCount()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const disableTrainRename = () => {
    if (
      auth.getRole() !== UserDtoRoleEnum.Dispatcher &&
      auth.getRole() !== UserDtoRoleEnum.DispatcherTrain &&
      auth.getRole() !== UserDtoRoleEnum.Manager
    ) {
      return true
    }

    if (new Set([TrainMode.GATE_OUT]).has(mode)) {
      return new Set([TrainDtoTrainStateEnum.OutgoingDispatched]).has(
        train.trainState ?? TrainDtoTrainStateEnum.IncomingAccepted
      )
    }

    return false
  }

  const updateTrainAndCreateTransition = (
    wagonContent: TrainWagonContentDto,
    index: number,
    createTransition?: (trainWagon: TrainWagonDto, trainWagonContent: TrainWagonContentDto) => void
  ) => {
    const data: TrainDto = getValues()
    updateTrain(data, (response) => {
      const wagon: TrainWagonDto | undefined = response.data.wagons?.find((wagon) => wagon.wagonOrder - 1 === index)
      if (!!wagon && !!createTransition) {
        createTransition(wagon, wagonContent)
      }
    })
  }

  const updateTrain = (data: TrainDto, afterUpdate?: (response: AxiosResponse<TrainDto>) => void) => {
    if (train.id) {
      trainService
        .update(train.id, {
          ...train,
          name: data.name,
          departureDateTime: data.departureDateTime,
          realDepartureDateTime: data.realDepartureDateTime,
          customsClearance: customsClearance ? data.customsClearance : false,
        })
        .then((response) => {
          dispatch(setTrain(response.data))
          if (afterUpdate) {
            afterUpdate(response)
          }
        })
    }
  }

  const updateTrainAndLoadContainer = (
    wagonContent: TrainWagonContentDto,
    index: number,
    transitionId: number,
    qualityType: TransitionSearchListDtoQualityTypeEnum | undefined
  ) => {
    const data: TrainDto = getValues()
    updateTrain(data, (responseTrain) => {
      const wagon: TrainWagonDto | undefined = responseTrain.data.wagons?.find(
        (wagon) => wagon.wagonOrder - 1 === index
      )
      if (!!wagon) {
        trainGateInService
          .loadPlannedOnWagon(wagon.id ?? -1, wagonContent.wagonContentOrder ?? -1, transitionId, qualityType)
          .then((response) => {
            if (response.data) {
              const updatedTrain = trainService.addWagonContent(responseTrain.data, wagon, response.data)
              dispatch(setTrain(updatedTrain))
            }
          })
      }
    })
  }

  // this is just a workaround for setting wagon weight after the method
  // #updateTrainAndLoadContainer and not needing refreshing whole page
  useEffect(() => {
    setLoaded(loaded)
  }, [train]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {loaded && (
        <form onSubmit={handleSubmit(checkBeforeSubmit)}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={5}>
            <Grid item xs={12}>
              {mode === TrainMode.GATE_IN && (
                <Typography variant="h4" color="primary">
                  {train.trainState === TrainDtoTrainStateEnum.IncomingPlanned
                    ? t('pages.train.labels.formGateInPlannedEdit')
                    : t('pages.train.labels.formGateInFinishedEdit')}
                </Typography>
              )}
              {mode === TrainMode.GATE_OUT && train.id === undefined && (
                <Typography variant="h4" color="primary">
                  {t('pages.train.labels.formGateOutNew')}
                </Typography>
              )}
              {mode === TrainMode.GATE_OUT &&
                train.id !== undefined &&
                train.trainState !== TrainDtoTrainStateEnum.OutgoingLoaded && (
                  <Typography variant="h4" color="primary">
                    {t('pages.train.labels.formGateOutEdit')}
                  </Typography>
                )}
              {mode === TrainMode.SEARCH && (
                <Typography variant="h4" color="primary">{`${t('pages.train.labels.formDetail')} (${t(
                  `enum.trainState.${train.trainState}`
                )})`}</Typography>
              )}
              {mode === TrainMode.GATE_OUT && train.trainState === TrainDtoTrainStateEnum.OutgoingLoaded && (
                <Typography variant="h4" color="primary">
                  {t('pages.train.labels.formGateOutApprove')}
                </Typography>
              )}
            </Grid>
            <Grid item xs={formSize}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={3}
                className={classes.containerGrid}
              >
                <Grid item xs={12}>
                  <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={3}>
                    <Grid item xl={6} lg={4}>
                      <ConardTextField
                        id="name"
                        name="name"
                        label={t('pages.train.labels.trainName')}
                        onChange={(event) => onChangeTrainName(event)}
                        control={control}
                        error={!!errors.name}
                        helperText={errors.name ? errors.name.message : ''}
                        disabled={disableTrainRename()}
                      />
                    </Grid>
                    {mode !== TrainMode.SEARCH && showTrainImport() && (
                      <Grid item xl={3} lg={4}>
                        <input
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          className={classes.input}
                          id="contained-button-file"
                          type="file"
                          onChange={onUpload}
                          disabled={disableTrainImport()}
                        />
                        <label htmlFor="contained-button-file">
                          <ConardUploadButton
                            conardVariant="transparent"
                            startIcon={<StorageIcon />}
                            disabled={disableTrainImport()}
                          >
                            {t('pages.train.buttons.excel')}
                          </ConardUploadButton>
                        </label>
                      </Grid>
                    )}
                    {mode === TrainMode.GATE_IN && train.id && (
                      <Grid item xl={3} lg={4}>
                        <ConardButton
                          conardVariant="transparent"
                          startIcon={<GetAppIcon />}
                          onClick={() => handleDownloadExport(train)}
                        >
                          {t('any.buttons.export')}
                        </ConardButton>
                      </Grid>
                    )}

                    {mode !== TrainMode.SEARCH &&
                      !new Set([TrainDtoTrainStateEnum.OutgoingDispatched]).has(
                        train.trainState ?? TrainDtoTrainStateEnum.IncomingAccepted
                      ) && (
                        <Grid item xl={3} lg={4}>
                          <ConardButton
                            className={classes.rotateButton}
                            conardVariant="transparent"
                            startIcon={<LoopIcon />}
                            onClick={() => dispatch(shiftWagons())}
                          >
                            {t('pages.train.buttons.rotate')}
                          </ConardButton>
                        </Grid>
                      )}
                    <Grid item lg={3} sm={6}>
                      <ConardSwitch
                        id="trainType"
                        name="trainType"
                        checked={train?.trainType === TrainDtoTrainTypeEnum.Partial}
                        onChange={(event) => onChangeTrainType(event)}
                        control={control}
                        checkedLabel={t('pages.gateOut.form.trainType.checked')}
                        uncheckedLabel={t('pages.gateOut.form.trainType.unchecked')}
                        disabled={auth.getRole() === UserDtoRoleEnum.Operator || mode === TrainMode.SEARCH}
                      />
                    </Grid>
                    {customsClearance &&
                      (new Set([
                        TrainDtoTrainStateEnum.IncomingPlanned,
                        TrainDtoTrainStateEnum.IncomingAccepted,
                        TrainDtoTrainStateEnum.IncomingFinished,
                      ]).has(train.trainState ?? TrainDtoTrainStateEnum.OutgoingPlanned) ||
                        mode === TrainMode.GATE_IN) && (
                        <Grid item lg={3} sm={6}>
                          <ConardSwitch
                            id="customsClearance"
                            name="customsClearance"
                            checked={train?.customsClearance ?? false}
                            onChange={(event) => onChangeCustomsTrain(event)}
                            control={control}
                            checkedLabel={t('pages.gateOut.form.customsTrain.checked')}
                            uncheckedLabel={t('pages.gateOut.form.customsTrain.unchecked')}
                            disabled={
                              auth.getRole() === UserDtoRoleEnum.Operator ||
                              mode !== TrainMode.GATE_IN ||
                              (!!id && train.trainState !== TrainDtoTrainStateEnum.IncomingPlanned)
                            }
                          />
                        </Grid>
                      )}
                    {mode === TrainMode.GATE_IN && !id && (
                      <Grid item lg={3} xs={6}>
                        <ConardSwitch
                          id="onTerminal"
                          name="onTerminal"
                          checked={onTerminal}
                          defaultChecked={false}
                          onChange={(event) => onChangeOnTerminal(event)}
                          control={control}
                          checkedLabel={t('pages.gateOut.form.trainState.incomingAccepted')}
                          uncheckedLabel={t('pages.gateOut.form.trainState.incomingPlanned')}
                          disabled={auth.getRole() === UserDtoRoleEnum.Operator || mode !== TrainMode.GATE_IN}
                        />
                      </Grid>
                    )}
                    <Grid item lg={3} xs={6}>
                      <ConardDateTimePicker
                        name="departureDateTime"
                        defaultValue={
                          !!train.departureDateTime ? getDateWithZeroSeconds(train.departureDateTime!) : undefined
                        }
                        control={control}
                        label={getDepartureTimeLabel()}
                        onChange={(event) => onChangeDepartureDateTime(event)}
                        clearable
                      />
                    </Grid>
                    <Grid item lg={3} xs={6}>
                      <ConardDateTimePicker
                        name="realDepartureDateTime"
                        control={control}
                        label={getRealDepartureTimeLabel()}
                        clearable
                        defaultValue={
                          !!train.realDepartureDateTime
                            ? getDateWithZeroSeconds(train.realDepartureDateTime)
                            : undefined
                        }
                        onChange={(event) => onChangeRealDepartureDateTime(event)}
                      />
                    </Grid>
                    {!!train.departureDateTime && !!train.realDepartureDateTime && (
                      <Grid item lg={3} xs={6}>
                        <ConardTextField
                          name="delay"
                          defaultValue=""
                          control={control}
                          value={delay}
                          label={t('pages.train.labels.delay')}
                          disabled
                        />
                      </Grid>
                    )}
                    {train.id && train.unloadingDateTime && (
                      <Grid item lg={3} xs={6}>
                        <ConardDateTimePicker
                          name="unloadingDateTime"
                          defaultValue={
                            !!train.unloadingDateTime ? getDateWithZeroSeconds(train.unloadingDateTime!) : undefined
                          }
                          control={control}
                          disabled
                          label={
                            isIncoming(train.trainState ?? TrainDtoTrainStateEnum.IncomingPlanned)
                              ? t('pages.train.labels.trainUnloadingTime')
                              : t('pages.train.labels.trainLoadingTime')
                          }
                          clearable
                        />
                      </Grid>
                    )}
                    <Grid item lg={3} xs={6}>
                      <ConardTextField
                        id="trainWeight"
                        name="trainWeight"
                        label={t('pages.train.labels.trainWeight')}
                        value={Math.round((trainWeight + Number.EPSILON) * 100) / 100 + ' ' + t('any.units.kilogram')}
                        defaultValue={0}
                        control={control}
                        disabled
                      />
                    </Grid>
                    <Grid item lg={3} xs={6}>
                      <ConardTextField
                        id="trainLength"
                        name="trainLength"
                        label={t('pages.train.labels.trainLength')}
                        value={trainLength + ' ' + t('any.units.meter')}
                        defaultValue={0}
                        control={control}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.containerGridAddItem}>
                      <ConardButton
                        startIcon={<Add />}
                        conardVariant="light"
                        color="primary"
                        onClick={add}
                        disabled={
                          auth.getRole() === UserDtoRoleEnum.Operator ||
                          new Set([TrainMode.SEARCH]).has(mode) ||
                          (new Set([TrainMode.GATE_OUT]).has(mode) &&
                            train.trainState === TrainDtoTrainStateEnum.OutgoingDispatched)
                        }
                      >
                        {t('pages.train.labels.addWagon')}
                      </ConardButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={mode === TrainMode.GATE_OUT ? classes.wagonGrid : undefined}>
                  {[...(train.wagons ?? [])].map((wagon, index) => (
                    <ConardTrainWagonForm
                      key={index}
                      wagonModel={wagon}
                      mode={mode}
                      onCreateContainer={(wagon, wagonContent) =>
                        updateTrainAndCreateTransition(wagonContent, index, onCreateWagonContainer)
                      }
                      onCreateSemitrailer={(wagon, wagonContent) =>
                        updateTrainAndCreateTransition(wagonContent, index, onCreateWagonSemitrailer)
                      }
                      onEditContainer={(wagon, wagonContent) =>
                        updateTrainAndCreateTransition(wagonContent, index, onEditWagonContainer)
                      }
                      onEditSemitrailer={(wagon, wagonContent) =>
                        updateTrainAndCreateTransition(wagonContent, index, onEditWagonSemitrailer)
                      }
                      onLoadContainer={(wagon, wagonContent, transitionId, qualityType) =>
                        updateTrainAndLoadContainer(wagonContent, index, transitionId, qualityType)
                      }
                      onFill={onFillWagonContent}
                      onRemoveWagon={(onRemove) => updateTrain(getValues(), onRemove)}
                    />
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={3}
                className={
                  mode === TrainMode.GATE_OUT
                    ? `${classes.unseatedContentGrid} ${classes.unseatedContentGridOverflow}`
                    : classes.unseatedContentGrid
                }
              >
                {new Set([TrainMode.GATE_OUT, TrainMode.SEARCH]).has(mode) &&
                  new Set([
                    TrainDtoTrainStateEnum.OutgoingPlanned,
                    TrainDtoTrainStateEnum.OutgoingPrepared,
                    TrainDtoTrainStateEnum.OutgoingLoaded,
                  ]).has(train.trainState ?? TrainDtoTrainStateEnum.IncomingPlanned) && (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="h4" color="primary">
                          {t('pages.train.labels.wagonContent') + ' - ' + unseatedCount}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {(train.unseatedTransitions === undefined || train.unseatedTransitions?.length > 0) &&
                          train.unseatedTransitions
                            ?.slice()
                            .sort((a, b) => a.container.iluCode.localeCompare(b.container.iluCode))
                            .map((transition) => (
                              <Paper
                                key={transition.id}
                                onClick={() => toggleUnseatedTransitionSelection(transition)}
                                square
                                className={classes.unseatedContentPaper}
                              >
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="center"
                                  alignItems="center"
                                  className={
                                    selectedUnseatedTransition?.id === transition.id
                                      ? classes.unseatedContentPaperGridSelected
                                      : transition.loadedOnTrain
                                      ? classes.unseatedContentPaperGrid
                                      : classes.unseatedContentPaperGridUnloaded
                                  }
                                >
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    className={
                                      selectedUnseatedTransition?.id === transition.id
                                        ? classes.unseatedContentPaperGridSelected
                                        : transition.loadedOnTrain
                                        ? classes.unseatedContentPaperGrid
                                        : classes.unseatedContentPaperGridUnloaded
                                    }
                                  >
                                    <Grid item className={classes.unseatedGrid}>
                                      <Typography className={classes.unseatedTypography} variant="h6" color="primary">
                                        {transition.container.iluCode}
                                      </Typography>
                                      <ConardTrainContainerIcon
                                        containerType={transition.container.containerType}
                                        repleted={transition.repleted ?? false}
                                      />
                                      <Typography className={classes.containerInfoTypography}>
                                        {renderContainerType({
                                          containerType: transition.container.containerType,
                                          shippingContainerType: transition.container.shippingContainerType,
                                          semitrailerType: transition.container.semitrailerType,
                                        })}
                                      </Typography>
                                      <Typography className={classes.containerInfoTypography}>
                                        {convertToTons(transition.grossWeight ?? 0) + ' ' + t('any.units.ton')}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Paper>
                            ))}

                        {(train.unseatedTransitions === undefined || train.unseatedTransitions?.length === 0) && (
                          <Typography variant="h6" color="primary">
                            {t('pages.gateOut.headers.trainUnseatedContentEmpty')}
                          </Typography>
                        )}

                        <ConardButton
                          conardVariant="light"
                          color="primary"
                          onClick={() =>
                            updateTrain(control.getValues(), (response) =>
                              history.push(`/gate-out/train/${response.data.id}/content`)
                            )
                          }
                          disabled={new Set([TrainMode.SEARCH]).has(mode)}
                        >
                          {t('pages.train.labels.editWagonContent')}
                        </ConardButton>
                      </Grid>
                    </>
                  )}
                {new Set([
                  TrainDtoTrainStateEnum.OutgoingPrepared,
                  TrainDtoTrainStateEnum.OutgoingLoaded,
                  TrainDtoTrainStateEnum.OutgoingDispatched,
                ]).has(train.trainState ?? TrainDtoTrainStateEnum.IncomingPlanned) && (
                  <>
                    <Grid item xs={12}>
                      <TrainExportBox train={train} />
                    </Grid>
                    <Grid item xs={12}>
                      <CdCargoBox train={train} />
                    </Grid>
                  </>
                )}
                {new Set([TrainDtoTrainStateEnum.OutgoingDispatched, TrainDtoTrainStateEnum.IncomingFinished]).has(
                  train.trainState ?? TrainDtoTrainStateEnum.IncomingPlanned
                ) && (
                  <>
                    {train.trainState === TrainDtoTrainStateEnum.OutgoingDispatched && veronaTrain && (
                      <Grid item xs={12}>
                        <VeronaBox train={train} />
                      </Grid>
                    )}
                    {mscActive && (
                      <Grid item xs={12}>
                        <CodecoSendBox train={train} />
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.controls}>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              {new Set([...trainAndBasicDispatcher, UserDtoRoleEnum.Manager]).has(
                auth.getRole() ?? UserDtoRoleEnum.Operator
              ) && (
                <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                  <div className={classes.submitButton}>
                    <ConardButton conardVariant="dark" type="submit" disabled={trainSubmitDisabled}>
                      {t('any.buttons.save')}
                    </ConardButton>
                  </div>
                </Grid>
              )}
              {trainAndBasicDispatcher.has(auth.getRole()) &&
                mode === TrainMode.GATE_IN &&
                (train.trainState === TrainDtoTrainStateEnum.IncomingPlanned ||
                  train.trainState === TrainDtoTrainStateEnum.IncomingArrivalCustoms) && (
                  <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                    <div className={classes.submitButton}>
                      <ConardButton
                        conardVariant="dark"
                        type="submit"
                        onClick={() => setTrainState(TrainDtoTrainStateEnum.IncomingAccepted)}
                      >
                        {t('pages.train.buttons.submitAndAccept')}
                      </ConardButton>
                    </div>
                  </Grid>
                )}
              {customsClearance &&
                train.trainState === TrainDtoTrainStateEnum.IncomingPlanned &&
                mode === TrainMode.GATE_IN && (
                  <Grid item lg={2} md={4} xs={12}>
                    <div className={classes.submitButton}>
                      <ConardButton
                        conardVariant="dark"
                        type="submit"
                        onClick={() => setTrainState(TrainDtoTrainStateEnum.IncomingArrivalCustoms)}
                      >
                        {t('pages.train.buttons.submitAndArrivalCustoms')}
                      </ConardButton>
                    </div>
                  </Grid>
                )}
              {new Set([TrainMode.GATE_OUT]).has(mode) &&
                train.trainState === TrainDtoTrainStateEnum.OutgoingPlanned && (
                  <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                    <div className={classes.submitButton}>
                      <ConardButton
                        conardVariant="dark"
                        type="submit"
                        onClick={() => setTrainState(TrainDtoTrainStateEnum.OutgoingPrepared)}
                      >
                        {t('pages.train.buttons.submitAndHandover')}
                      </ConardButton>
                    </div>
                  </Grid>
                )}
              {new Set([TrainMode.GATE_OUT]).has(mode) &&
                train.trainState === TrainDtoTrainStateEnum.OutgoingPrepared && (
                  <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                    <div className={classes.submitButton}>
                      <ConardButton
                        conardVariant="dark"
                        type="submit"
                        onClick={() => setTrainState(TrainDtoTrainStateEnum.OutgoingLoaded)}
                        disabled={train.unseatedTransitions !== undefined && train.unseatedTransitions?.length > 0}
                      >
                        {t('pages.train.buttons.submitAndSendForApproval')}
                      </ConardButton>
                    </div>
                  </Grid>
                )}
              {new Set([TrainMode.GATE_OUT]).has(mode) &&
                train.trainState === TrainDtoTrainStateEnum.OutgoingLoaded && (
                  <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                    <div className={classes.submitButton}>
                      <ConardButton
                        conardVariant="dark"
                        type="submit"
                        disabled={train.unseatedTransitions && train.unseatedTransitions?.length > 0}
                        onClick={() => setTrainState(TrainDtoTrainStateEnum.OutgoingDispatched)}
                      >
                        {t('pages.train.buttons.submitAndApprove')}
                      </ConardButton>
                    </div>
                  </Grid>
                )}
              {mode === TrainMode.GATE_IN && train.trainState === TrainDtoTrainStateEnum.IncomingAccepted && (
                <Grid item lg={2} md={4} xs={12}>
                  <div className={classes.submitButton}>
                    <ConardButton conardVariant="dark" type="submit" onClick={() => setStore(true)}>
                      {t('pages.train.buttons.storeAll')}
                    </ConardButton>
                  </div>
                </Grid>
              )}
              <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                <div className={classes.submitButton}>
                  <ConardButton conardVariant="dark" onClick={() => history.goBack()}>
                    {t('any.buttons.back')}
                  </ConardButton>
                </div>
              </Grid>
            </Grid>
          </div>
        </form>
      )}
      <TrainWrongIluDialog
        wrongIluCodes={wrongIluCodes}
        setWrongIluCodes={setWrongIluCodes}
        onSubmit={onSubmit}
        train={trainToSubmit}
      />
      <TrainApproveGateOutDialog
        showDialog={showApproveGateOutDialog}
        setShowDialog={setShowApproveGateOutDialog}
        onSubmit={onSubmit}
        train={trainToSubmit}
      />
      <TrainImportDialog
        showDialog={showTrainImportDialog}
        onClose={() => setShowTrainImportDialog(false)}
        onSubmit={onSubmitImportDialog}
      />
    </>
  )
}

export default ConardTrainForm
