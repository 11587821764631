import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'
import ConardButton from '../ConardButton'
import { TrainDto } from '../../api'

const useStyles = makeStyles(() =>
  createStyles({
    dialogContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
)

interface TrainWrongIluDialogProps {
  onSubmit: (train: TrainDto) => void
  train?: TrainDto
  wrongIluCodes: Array<string>
  setWrongIluCodes: (wrongIluCodes: Array<string>) => void
}

const TrainWrongIluDialog: FC<TrainWrongIluDialogProps> = ({ onSubmit, train, wrongIluCodes, setWrongIluCodes }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      {wrongIluCodes.length > 0 && (
        <Dialog open={wrongIluCodes.length > 0} maxWidth="sm">
          <DialogTitle>{t('pages.gateOut.dialog.trainWrongIlu.title')}</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Grid container>
              <Grid item xs={12}>
                <Typography>{t('pages.gateOut.dialog.trainWrongIlu.content') + ': '}</Typography>
              </Grid>
              {wrongIluCodes.map((ilu) => (
                <Grid key={ilu} item xs={12}>
                  <Typography>
                    <strong>{ilu}</strong>
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <ConardButton
              conardVariant="dark"
              onClick={() => {
                onSubmit(train!)
                setWrongIluCodes([])
              }}
            >
              {t('any.buttons.save')}
            </ConardButton>
            <ConardButton conardVariant="transparent" onClick={() => setWrongIluCodes([])}>
              {t('any.buttons.cancel')}
            </ConardButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default TrainWrongIluDialog
