import React, { FC, useEffect, useState } from 'react'
import {
  Box,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ConardButton from '../ConardButton'
import {
  TransitionSearchListSearchDto,
  TransitionSearchListSearchDtoContainerStateEnum,
  TransitionSearchListSearchDtoReceiptMethodEnum,
} from '../../api'
import transitionService from '../../services/TransitionService'

export interface NotificationPlannedGateInDialogProps {
  showGateInDialog: boolean
  onClose: () => void
  onPlanned: () => void
  onNewContainer: () => void
  onNewSemitrailer: () => void
  getTransitionSearchDto: (
    containerState: TransitionSearchListSearchDtoContainerStateEnum
  ) => TransitionSearchListSearchDto
}

const useStyles = makeStyles(() =>
  createStyles({
    dialogActions: {
      flexDirection: 'column',
      display: 'flex',
      alignItems: 'center',
      gap: 16,
    },
    buttonBox: {
      display: 'flex',
      flexDirection: 'row',
      gap: 16,
    },
  })
)

const NotificationPlannedGateInDialog: FC<NotificationPlannedGateInDialogProps> = ({
  showGateInDialog,
  onClose,
  onPlanned,
  onNewContainer,
  onNewSemitrailer,
  getTransitionSearchDto,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [isPlanned, setIsPlanned] = useState(false)

  useEffect(() => {
    let isMounted = true

    const isPlannedGateIn = () => {
      const transitionSearchDto: TransitionSearchListSearchDto = {
        ...getTransitionSearchDto(TransitionSearchListSearchDtoContainerStateEnum.PlannedGateIn),
        receiptMethod: TransitionSearchListSearchDtoReceiptMethodEnum.Road,
      }
      transitionService
        .searchForList(undefined, undefined, undefined, transitionSearchDto)
        .then((response) => {
          if (isMounted) {
            // Check if component is still mounted
            setIsPlanned(response.data?.content?.length !== 0)
          }
        })
        .catch(() => {
          if (isMounted) {
            // Check if component is still mounted
            setIsPlanned(false)
          }
        })
    }

    isPlannedGateIn()

    return () => {
      isMounted = false // Set to false when the component unmounts
    }
  }, [showGateInDialog]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog open={showGateInDialog}>
      <DialogTitle>{t('pages.notifications.dialogs.gateIn.title')}</DialogTitle>
      <DialogContent>
        <Typography>
          {isPlanned
            ? t('pages.notifications.dialogs.gateIn.plannedMessage')
            : t('pages.notifications.dialogs.gateIn.gateInMessage')}
        </Typography>
      </DialogContent>
      <DialogActions disableSpacing className={classes.dialogActions}>
        <Box className={classes.buttonBox}>
          <ConardButton conardVariant={isPlanned ? 'transparent' : 'dark'} onClick={onNewContainer}>
            {t('pages.notifications.dialogs.gateIn.newContainer')}
          </ConardButton>
          <ConardButton conardVariant={isPlanned ? 'transparent' : 'dark'} onClick={onNewSemitrailer}>
            {t('pages.notifications.dialogs.gateIn.newSemitrailer')}
          </ConardButton>
        </Box>
        <Box className={classes.buttonBox}>
          <ConardButton conardVariant={isPlanned ? 'dark' : 'transparent'} onClick={onPlanned}>
            {t('pages.notifications.dialogs.gateIn.planned')}
          </ConardButton>
          <ConardButton conardVariant="transparent" onClick={onClose}>
            {t('any.buttons.cancel')}
          </ConardButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default NotificationPlannedGateInDialog
