import React, { FC } from 'react'
import DispatcherGateInDashboard from './DispatcherGateInDashboard'

const GateInPage: FC = () => {
  return (
    <>
      <DispatcherGateInDashboard />
    </>
  )
}

export default GateInPage
