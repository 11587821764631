import React, { FC, useState } from 'react'
import DamageLogDashboard from '../../components/damage-log/DamageLogDashboard'
import { ContainerDtoContainerTypeEnum } from '../../api'
import { useLocation } from 'react-router-dom'

interface LocationState {
  containerType: ContainerDtoContainerTypeEnum
  startTab: string
}

const DamageLogCreatePage: FC = () => {
  const location = useLocation<LocationState>()

  const [containerType] = useState<ContainerDtoContainerTypeEnum>(
    location.state !== undefined ? location.state.containerType : ContainerDtoContainerTypeEnum.ShippingContainer
  )
  const [startTab] = useState<string>(location.state.startTab ?? 'damageLogEntry')

  return <DamageLogDashboard containerType={containerType} startTab={startTab} />
}

export default DamageLogCreatePage
