import React, { FC, useEffect, useState } from 'react'
import { alpha, createStyles, Grid, Typography } from '@material-ui/core'
import ConardButton from '../ConardButton'
import { DriverArrivalDto, TransitionDtoContainerStateEnum } from '../../api'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import driverArrivalService from '../../services/DriverArrivalService'

const useStyles = makeStyles((theme) =>
  createStyles({
    panelContainer: {
      marginTop: '6vh',
      marginBottom: '6vh',
      backgroundColor: alpha(theme.palette.secondary.dark, 0.3),
      borderRadius: '8px',
      textAlign: 'center',
      minWidth: '290px',
      padding: '20px 0px 20px 0px',
    },
  })
)

interface ConardInstantGreenBoxProps {
  transitionId: number
  containerState: TransitionDtoContainerStateEnum
}

const ConardInstantGreenBox: FC<ConardInstantGreenBoxProps> = ({ transitionId, containerState }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [driverArrival, setDriverArrival] = useState<DriverArrivalDto | undefined>(undefined)

  useEffect(() => {
    if (containerState === TransitionDtoContainerStateEnum.PlannedGateIn) {
      driverArrivalService.findByPlannedTransitionIdAndNew(transitionId).then((response) => {
        if (response.status === 404) {
          setDriverArrival(undefined)
        } else {
          setDriverArrival(response.data)
        }
      })
    }
  }, [containerState, transitionId])

  const modifyInstantGreenPass = () => {
    if (driverArrival !== undefined) {
      driverArrivalService.modifyInstantGreenPass(driverArrival?.id ?? -1, !driverArrival?.instantGreenPass).then(() =>
        setDriverArrival({
          ...driverArrival,
          instantGreenPass: !driverArrival?.instantGreenPass,
        })
      )
    }
  }

  return (
    <>
      {driverArrival !== undefined && (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.panelContainer}
        >
          <Grid item>
            <Typography color={driverArrival.instantGreenPass === true ? 'primary' : undefined} variant="h5">
              {t('pages.gateIn.instantGreenPass.title')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography color={driverArrival.instantGreenPass === true ? 'primary' : undefined} variant="h5">
              {driverArrival.instantGreenPass ? t('pages.gateIn.instantGreenPass.label') : ''}
            </Typography>
          </Grid>
          <Grid item>
            <ConardButton conardVariant="light" onClick={modifyInstantGreenPass}>
              {t(`pages.gateIn.instantGreenPass.button.${driverArrival.instantGreenPass}`)}
            </ConardButton>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default ConardInstantGreenBox
