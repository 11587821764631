import { Box, Dialog, DialogContent, Typography, createStyles, makeStyles } from '@material-ui/core'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next/'
import ConardButton from '../../components/ConardButton'

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonYes: {
      borderRadius: 12,
      width: '105px',
      height: '50px',
      '&:hover': {
        backgroundColor: theme.palette.error.main,
      },
    },
    buttonNo: {
      borderRadius: 12,
      width: '105px',
      height: '50px',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    buttonsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    text: {
      paddingBottom: '10px',
      textAlign: 'center',
    },
  })
)

interface DialogProps {
  showDialog: boolean

  onClose: () => void
  onYes: () => void
}

export const DeleteAllDialog: FC<DialogProps> = ({ showDialog, onClose, onYes }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Dialog open={showDialog} onClose={onClose}>
      <DialogContent>
        <Box>
          <Typography className={classes.text}>
            {t('driverApp.driverArrival.deleteConfirmationDialog.confirmationText')}
          </Typography>
          <Box className={classes.buttonsWrapper}>
            <ConardButton conardVariant="red" type="submit" onClick={onYes} className={classes.buttonYes}>
              {t('driverApp.driverArrival.deleteConfirmationDialog.buttons.yes')}
            </ConardButton>
            <ConardButton conardVariant="dark" type="reset" onClick={onClose} className={classes.buttonNo}>
              {t('driverApp.driverArrival.deleteConfirmationDialog.buttons.no')}
            </ConardButton>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
