import { useEffect, useRef } from 'react'
import { UseFormMethods } from 'react-hook-form'

export const useFormInitialValues = <T>(initialValues: T | undefined, reset: UseFormMethods['reset']) => {
  const firstInitial = useRef(false)

  useEffect(() => {
    if (initialValues && !firstInitial.current) {
      firstInitial.current = true
      reset(initialValues)
    }
  }, [initialValues, reset])
}
