import { TextField, TextFieldProps, createStyles, makeStyles } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { ChangeEvent, FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AutocompleteDto } from '../api'
import autocompleteService from '../services/AutocompleteService'
import { Controller } from 'react-hook-form'
import { Control } from 'react-hook-form/dist/types/form'

const useStyles = makeStyles((theme) =>
  createStyles({
    autocomplete: {
      width: '100%',
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(34px, -6px) scale(0.75)',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        '& legend': {
          marginLeft: '20px',
        },
      },
      '& .MuiAutocomplete-endAdornment': {
        right: '7px',
      },
    },
    autocompleteOption: {
      width: '100%',
    },
    inputAutocomplete: {
      borderRadius: 30,
      borderColor: theme.palette.secondary.light,
      backgroundColor: theme.palette.secondary.main,
    },
    roundedSquare: {
      borderRadius: 12,
    },
  })
)

interface ConardTextAutocompleteProps {
  optionType: 'CONTAINER' | 'CARRIER' | 'DRIVER' | 'LICENSE_PLATE'
  value: AutocompleteDto
  setValue: (value: AutocompleteDto) => void
  onChoose?: (autocompleteDto: AutocompleteDto) => void
  onChangeInput?: (input: string) => void
  name: string
  control: Control
  roundedSquare?: boolean
}

type ConardTextAutocompletePropsUnited = ConardTextAutocompleteProps & Omit<TextFieldProps, 'name'>

const ConardTextAutocomplete: FC<ConardTextAutocompletePropsUnited> = (props) => {
  const {
    id,
    value,
    setValue,
    label,
    error,
    disabled,
    optionType,
    onChoose,
    onChangeInput,
    className,
    name,
    control,
    roundedSquare,
    helperText,
  } = props
  const classes = useStyles()
  const { t } = useTranslation()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [timerID, setTimerID] = useState<any>()
  const [options, setOptions] = useState<AutocompleteDto[]>([])

  const onChangeTextFieldInput = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue({
      id: undefined,
      name: event.target.value,
    })
    if (onChangeInput) {
      onChangeInput(event.target.value)
    }
    clearTimeout(timerID)
    setTimerID(
      setTimeout(() => {
        autocompleteService.find(event.target.value, optionType).then((response) => setOptions(response.data))
      }, 1000)
    )
  }

  const onChangeAuto = (event: ChangeEvent<{}>, data: AutocompleteDto | null) => {
    if (data !== null) {
      setValue(data)
      if (onChoose) {
        onChoose(data)
      }
    } else {
      setValue({})
    }
  }

  const onBlur = () => {
    const similarOptions = options.filter((option) => option.name?.toLowerCase() === value.name?.toLowerCase())
    if (similarOptions.length === 1) {
      if (onChoose) {
        onChoose(similarOptions[0])
      } else if (setValue) {
        setValue(similarOptions[0])
      }
    }
  }

  return (
    <Controller
      id={id}
      name={name}
      control={control}
      defaultValue={value}
      render={() => (
        <Autocomplete
          id={id}
          className={className}
          value={value}
          disabled={disabled}
          clearOnBlur={false}
          options={options}
          noOptionsText={t('any.noOptions')}
          getOptionLabel={(option: AutocompleteDto) => {
            return option.name ?? ''
          }}
          limitTags={4}
          renderOption={(option: AutocompleteDto) => (
            <div className={classes.autocompleteOption}>{option.name ?? ''}</div>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              helperText={helperText}
              variant="outlined"
              onChange={(event) => onChangeTextFieldInput(event)}
              onBlur={onBlur}
              value={value.name}
              error={error}
              InputProps={{
                ...params.InputProps,
                className: roundedSquare
                  ? `${classes.inputAutocomplete} ${classes.roundedSquare}`
                  : classes.inputAutocomplete,
              }}
              className={classes.autocomplete}
            />
          )}
          onChange={(event, data) => onChangeAuto(event, data)}
        />
      )}
    ></Controller>
  )
}

export default ConardTextAutocomplete
