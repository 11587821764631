import myAxios from '../config/axios/axios'

export interface AuthorizationResponse {
  access_token: string
  refresh_token: string
}

const authService = {
  login: (username: string, password: string): Promise<AuthorizationResponse> => {
    return new Promise((resolve, reject) =>
      myAxios({
        baseURL: `${process.env.REACT_APP_BE_REST_URL}/oauth/token?grant_type=password&username=${username}&password=${password}`,
        method: 'POST',
        auth: {
          username: 'client',
          password: 'password',
        },
      })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    )
  },
  refreshToken: (refreshToken: string): Promise<AuthorizationResponse> => {
    return new Promise((resolve, reject) =>
      myAxios({
        baseURL: `${process.env.REACT_APP_BE_REST_URL}/oauth/token?grant_type=refresh_token&refresh_token=${refreshToken}`,
        method: 'POST',
        auth: {
          username: 'client',
          password: 'password',
        },
      })
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    )
  },
}

export default authService
