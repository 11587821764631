import React, { FC } from 'react'
import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { DriverArrivalDto } from '../../../api'
import { KioskGateInContainerBox } from './KioskGateInContainerBox'
import { KioskGateInRepletedBox } from './KioskGateInRepletedBox'
import { KioskGateInAdrBox } from './KioskGateInAdrBox'
import { KioskWithoutTransitionText } from './KioskWithoutTransitionText'

const useStyles = makeStyles((theme) =>
  createStyles({
    label: {
      fontSize: 24,
      fontWeight: 650,
      color: theme.palette.primary.main,
    },
    text: {
      color: theme.palette.primary.main,
    },
  })
)

interface KioskGateInSummaryProps {
  driverArrivalDto: DriverArrivalDto | undefined
}

export const KioskGateInSummary: FC<KioskGateInSummaryProps> = ({ driverArrivalDto }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Grid container direction="column" alignItems="center" spacing={3}>
      <Grid item>
        <Typography className={classes.label}>{t('kioskApp.step9.gateIn')}</Typography>
      </Grid>

      {!driverArrivalDto?.withoutGateIn && !!driverArrivalDto?.plannedTransition ? (
        <>
          <Grid item>
            <KioskGateInContainerBox container={driverArrivalDto.plannedTransition.container} />
          </Grid>

          <Grid item>
            {driverArrivalDto.plannedTransition.repleted ? (
              <KioskGateInRepletedBox transition={driverArrivalDto.plannedTransition} />
            ) : (
              <Typography className={classes.text}>
                <b>{t('kioskApp.step9.notRepleted')}</b>
              </Typography>
            )}
          </Grid>

          <Grid item>
            {driverArrivalDto.plannedTransition.adrRid ? (
              <KioskGateInAdrBox transition={driverArrivalDto.plannedTransition} />
            ) : (
              <Typography className={classes.text}>
                <b>{t('kioskApp.step9.withoutAdr')}</b>
              </Typography>
            )}
          </Grid>

          <Grid item>
            <Typography className={classes.text}>
              <b>{t('kioskApp.step9.reference') + ': '}</b>
              {driverArrivalDto.plannedTransition.bookingReference}
            </Typography>
          </Grid>
        </>
      ) : (
        <KioskWithoutTransitionText />
      )}
    </Grid>
  )
}
