import React, { FC, useContext } from 'react'
import { createStyles, alpha, Grid, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ConfigContext } from '../../../context/ConfigContext'

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      backgroundColor: alpha(theme.palette.secondary.main, 0.3),
      width: '90%',
      paddingTop: '2vh',
    },
  })
)

interface CoordinatesProps {
  label: string
  value?: string | number
  active: boolean
  dialName: string
  switchDials: (to: string) => void
}

export const Coordinate: FC<CoordinatesProps> = (props) => {
  const { label, value, active, switchDials, dialName } = props
  const classes = useStyles()
  const { coordinates } = useContext(ConfigContext)

  const itemSize = coordinates.sectorLetterCount === 0 ? 4 : 3

  return (
    <>
      <Grid item xs={itemSize}>
        <Paper className={classes.paper} elevation={active ? 1 : 10}>
          <Grid container direction="column" onClick={() => switchDials(dialName)}>
            <Grid item>
              <Typography variant="h3" color="primary" align="center">
                {value === undefined || value === '' ? '-' : value}
              </Typography>
              <Typography variant="h6" color="primary" align="center">
                {label}
              </Typography>
              <br />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  )
}
