import { AxiosPromise } from 'axios'
import myAxios from '../config/axios/axios'
import { NhmApi, NhmCodeSearchDto, NhmGroupSearchDto, PageNhmCodeDto, PageNhmGroupDto } from '../api'

let api: NhmApi

export const setNhmApiConfiguration = () => {
  api = new NhmApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const nhmService = {
  findAllCodes: (
    page: number | undefined,
    pageSize: number | undefined,
    currentSort: string[] | undefined
  ): AxiosPromise<PageNhmCodeDto> => {
    return api.nhmFindAllCodes(page, pageSize, currentSort)
  },
  codeSearch: (
    page: number | undefined,
    pageSize: number | undefined,
    currentSort: string[] | undefined,
    nhmCodeSearchDto: NhmCodeSearchDto
  ): AxiosPromise<PageNhmCodeDto> => {
    return api.nhmSearchCode(page, pageSize, currentSort, nhmCodeSearchDto)
  },
  findAllGroups: (page: number, pageSize: number, currentSort: string[] | undefined): AxiosPromise<PageNhmGroupDto> => {
    return api.nhmFindAllGroups(page, pageSize, currentSort)
  },
  groupSearch: (
    page: number | undefined,
    pageSize: number | undefined,
    currentSort: string[] | undefined,
    nhmGroupSearchDto: NhmGroupSearchDto
  ): AxiosPromise<PageNhmGroupDto> => {
    return api.nhmSearchGroup(page, pageSize, currentSort, nhmGroupSearchDto)
  },
}

export default nhmService
