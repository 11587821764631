import { Grid, Typography } from '@material-ui/core'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface TransitionSuccessPageTypographyProps {
  driverGateOutType: string | null
}

export const TransitionSuccessPageTypography: FC<TransitionSuccessPageTypographyProps> = ({ driverGateOutType }) => {
  const { t } = useTranslation()
  const generateTypography = () => {
    if (!driverGateOutType) {
      return (
        <Typography variant="h6" color="primary">
          {t('pages.gateIn.headers.createGateOut')}
        </Typography>
      )
    }
    if (driverGateOutType === 'TRANSITION' || driverGateOutType === 'COPARN') {
      return (
        <Typography variant="h6" color="initial">
          {t('pages.gateIn.headers.driverGateOut')}
        </Typography>
      )
    }
    switch (driverGateOutType) {
      case 'TRANSITION_NOT_EXISTS':
        return (
          <Typography variant="h6" color="initial">
            {t('pages.gateIn.headers.transitionNotExist')}
          </Typography>
        )

      case 'TRANSITION_MULTIPLE_ON_DEPOT':
        return (
          <Typography variant="h6" color="initial">
            {t('pages.gateIn.headers.transitionMultipleOnDepot')}
          </Typography>
        )

      case 'TRANSITION_CUSTOMS_STOP':
        return (
          <Typography variant="h6" color="initial">
            {t('pages.gateIn.headers.transitionCustomsStop')}
          </Typography>
        )

      case 'TRANSITION_FORBIDDEN':
        return (
          <Typography variant="h6" color="initial">
            {t('pages.gateIn.headers.transitionForbidden')}
          </Typography>
        )

      case 'REPLETED_WITHOUT_NEEDED_PARAMETERS':
        return (
          <Typography variant="h6" color="initial">
            {t('pages.gateIn.headers.repletedWithoutParameters')}
          </Typography>
        )

      case 'COPARN_NOT_EXISTS':
        return (
          <Typography variant="h6" color="initial">
            {t('pages.gateIn.headers.coparnNotExist')}
          </Typography>
        )

      case 'COPARN_ALREADY_PROCESSED':
        return (
          <Typography variant="h6" color="initial">
            {t('pages.gateIn.headers.coparnAlreadyProcessed')}
          </Typography>
        )

      case 'COPARN_MULTIPLE':
        return (
          <Typography variant="h6" color="initial">
            {t('pages.gateIn.headers.coparnMultiple')}
          </Typography>
        )

      default:
        return null
    }
  }
  return <Grid item>{generateTypography()}</Grid>
}
