import React, { useEffect, useRef, useState } from 'react'
import terminalMapImage from '../../assets/images/terminal_map_hd.png'
import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef } from 'react-zoom-pan-pinch'
import ConardButton from '../../components/ConardButton'
import { makeStyles } from '@material-ui/core'
import { ContainerSector } from './ContainerSector'
import { TemporaryStore } from './TemporaryStore'
import terminalLayoutService from '../../services/TerminalLayoutService'
import { ManipulationRequestDto, TerminalSectorLayoutDto, TerminalSectorLayoutDtoSectorOrderEnum } from '../../api'
import { SemitrailerSector } from './SemitrailerSector'
import { TerminalLayoutContextProvider } from '../../context/TerminalLayoutActionContext'
import { TerminalTemporaryDrawerContextProvider } from '../../context/TerminalTemporaryDrawerContext'
import { ManipulationReuestsTable } from './ManipulationRequestsTable'
import manipulationRequestService from '../../services/ManipulationRequestService'

const useStyles = makeStyles({
  container: {
    width: '100%',
    //height: '50vh',
    display: 'flex',
    flexDirection: 'column',
  },
  buttonsWrapper: {
    display: 'flex',
    paddingTop: '1vh',
    paddingBottom: '1vh',
    gap: '0.5vw',
    width: '30%',
  },
  canvas: {
    width: '100%',
    height: '100%',
  },
  button: {
    borderRadius: 12,
  },
})

const TerminalLayout = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null)
  const classes = useStyles()

  const [sectors, setSectors] = useState<TerminalSectorLayoutDto[]>([])
  const [pendingRequests, setPendingRequests] = useState<ManipulationRequestDto[]>([])
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const canvas = canvasRef.current
    const container = containerRef.current

    if (!canvas) {
      return
    }

    if (!container) {
      return
    }

    const context = canvas.getContext('2d')
    const image = new Image()
    image.src = terminalMapImage

    image.onload = () => {
      canvas.width = image.width
      canvas.height = image.height
      context?.drawImage(image, 0, 0)
    }
  }, [])

  const handleRefreshLayout = async () => {
    const response = await terminalLayoutService.getSectors()
    setSectors([response.data[0]])
  }

  const handleRefreshManipulationRequestTable = async () => {
    const response = await manipulationRequestService.getPending()

    if (response.data) {
      setPendingRequests(response.data)
    }
    setLoaded(true)
  }

  const handleDeleteRequest = async (id: number) => {
    try {
      await manipulationRequestService.cancel(id)
      await handleRefreshManipulationRequestTable()
      await handleRefreshLayout()
    } catch (err) {
      console.error('Error deleting manipulation request', err)
    }
  }

  useEffect(() => {
    try {
      handleRefreshLayout()
      const intervalId = setInterval(() => {
        handleRefreshLayout()
      }, 60000)
      return () => {
        clearInterval(intervalId)
      }
    } catch (err) {
      console.error('Error fetching layout', err)
    }
  }, [])

  useEffect(() => {
    try {
      handleRefreshManipulationRequestTable()
    } catch (error) {
      console.error('Error fetching Pending manipulation requests for layout', error)
    }
  }, [])

  return (
    <>
      <TerminalLayoutContextProvider>
        <TerminalTemporaryDrawerContextProvider>
          <div ref={containerRef} className={classes.container}>
            <TransformWrapper
              ref={transformComponentRef}
              limitToBounds
              centerZoomedOut
              initialPositionX={0}
              initialPositionY={0}
              minScale={1}
              maxScale={6}
            >
              {({ zoomIn, zoomOut, resetTransform }) => (
                <>
                  <div className={classes.buttonsWrapper}>
                    <ConardButton className={classes.button} conardVariant="transparent" onClick={() => zoomIn()}>
                      +
                    </ConardButton>
                    <ConardButton className={classes.button} conardVariant="transparent" onClick={() => zoomOut()}>
                      -
                    </ConardButton>
                    <ConardButton
                      className={classes.button}
                      conardVariant="transparent"
                      onClick={() => resetTransform()}
                    >
                      x
                    </ConardButton>
                  </div>
                  <TransformComponent>
                    <canvas ref={canvasRef} id="termninal" className={classes.canvas} />

                    {sectors &&
                      sectors.map((sector) => {
                        if (sector.sectorOrder === TerminalSectorLayoutDtoSectorOrderEnum.Ordered) {
                          return (
                            <ContainerSector
                              key={sector.id}
                              sector={sector}
                              onUpdateManipulationRequestTable={handleRefreshManipulationRequestTable}
                              onUpdateTerminalLayout={handleRefreshLayout}
                            />
                          )
                        }
                        if (sector.sectorOrder === TerminalSectorLayoutDtoSectorOrderEnum.Unordered) {
                          return <SemitrailerSector key={sector.id} sector={sector} />
                        }
                        return null
                      })}
                  </TransformComponent>
                </>
              )}
            </TransformWrapper>
          </div>
          <ManipulationReuestsTable
            pendingRequests={pendingRequests}
            onDeleteManipulationRequest={handleDeleteRequest}
            loaded={loaded}
          />
          <TemporaryStore />
        </TerminalTemporaryDrawerContextProvider>
      </TerminalLayoutContextProvider>
    </>
  )
}

export default TerminalLayout
