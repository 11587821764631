import * as React from 'react'
import { FC, useState } from 'react'
import { createStyles, FormHelperText, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Switch, { ReactSwitchProps } from 'react-switch'
import { Controller } from 'react-hook-form'
import { Control } from 'react-hook-form/dist/types/form'

const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    switchRoot: (props: StyleProps) => ({
      width: '100%',
      '& .react-switch-bg': {
        display: 'flex',
        height: '58.5px !important',
        width: '100% !important',
        background: theme.palette.secondary.main + ' !important',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: 'rgba(0, 0, 0, 0.23)',
        '& :first-child': {
          width: '100% !important',
          height: '58.5px !important',
          background: 'none !important',
          [theme.breakpoints.only('xl')]: {
            paddingLeft: '10%',
          },
          [theme.breakpoints.down('lg')]: {
            paddingLeft: '6%',
          },
          lineHeight: '3.5rem',
        },
        '& :nth-child(2)': {
          width: '100% !important',
          height: '58.5px !important',
          background: 'none !important',
          paddingLeft: '65%',
          lineHeight: '3.5rem',
        },
      },
      '& .react-switch-handle': {
        background: `${theme.palette.primary.main} !important`,
        paddingTop: '24.5px',
        paddingBottom: '25.5px',
        lineHeight: '3rem !important',
        textAlign: 'center',
        width: '50% !important',
        marginTop: '3px !important',
        transform: props.checked ? 'translateX(98.5%) !important' : 'translateX(5px) !important',
        color: theme.palette.secondary.main,
        '& div': {
          fontSize: '1rem',
          width: '100% !important',
        },
      },
    }),
    rounded: {
      '& .react-switch-bg': {
        borderRadius: '30px !important',
      },
      '& .react-switch-handle': {
        borderRadius: '30px !important',
      },
    },
    roundedSquare: {
      '& .react-switch-bg': {
        borderRadius: '12px important',
      },
      '& .react-switch-handle': {
        borderRadius: '12px !important',
      },
    },
  })
)

interface StyleProps {
  checked: boolean
}

interface ConardSwitchProps {
  name: string
  checkedLabel: string
  uncheckedLabel: string
  control: Control
  helperText?: string
  roundedSquare?: boolean
}

type ConardConardSwitchPropsUnited = Partial<Omit<ReactSwitchProps, 'checked'>> &
  Partial<Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'name'>> &
  ConardSwitchProps

const ConardSwitch: FC<ConardConardSwitchPropsUnited> = (props) => {
  const {
    name,
    defaultChecked,
    onChange,
    checkedLabel,
    uncheckedLabel,
    control,
    checked,
    disabled,
    helperText,
    roundedSquare,
  } = props

  const [checkedInternal, setCheckedInternal] = useState(defaultChecked ?? false)
  const classes = useStyles({ checked: checked ?? checkedInternal })

  const checkedIcon = <div>{uncheckedLabel}</div>

  const checkedHandleIcon = <div>{checkedLabel}</div>

  const uncheckedIcon = <div>{checkedLabel}</div>

  const uncheckedHandleIcon = <div>{uncheckedLabel}</div>

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultChecked ?? false}
      render={(props) => (
        <>
          <Switch
            name={name}
            onChange={(value, event, id) => {
              setCheckedInternal(!checkedInternal)
              if (!!onChange) {
                onChange(value, event, id)
              }
              props.onChange(value)
            }}
            checked={checked ?? props.value}
            disabled={disabled}
            checkedIcon={checkedIcon}
            checkedHandleIcon={checkedHandleIcon}
            uncheckedIcon={uncheckedIcon}
            uncheckedHandleIcon={uncheckedHandleIcon}
            className={`${classes.switchRoot} ${!!roundedSquare ? classes.roundedSquare : classes.rounded}`}
          />
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </>
      )}
    />
  )
}

export default ConardSwitch
