import React, { FC } from 'react'
import { Button, ButtonProps, createStyles, makeStyles, Theme } from '@material-ui/core'

type ConardButtonVariant = 'white' | 'light' | 'dark' | 'transparent' | 'red' | 'orange'

type ConardButtonPropsUnited = ConardButtonProps & ButtonProps

interface ConardButtonProps {
  conardVariant: ConardButtonVariant
  type?: 'button' | 'reset' | 'submit'
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  autoFocus?: boolean
}

const whiteButtonStyles = makeStyles((theme) =>
  createStyles({
    root: {
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: 20,
      padding: '0.2vh 2vw',
    },
    label: {
      color: theme.palette.secondary.main,
    },
  })
)

const lightButtonStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.primary.main,
      borderRadius: 30,
      padding: '0.2vh 2vw',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    label: (props) => ({
      color: props.disabled ? theme.palette.grey.A200 : theme.palette.secondary.main,
      margin: '5px 20px',
    }),
  })
)

const darkButtonStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.primary.main,
      borderRadius: 20,
      padding: '0.2vh 2vw',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    label: (props) => ({
      color: props.disabled ? theme.palette.grey.A200 : theme.palette.secondary.main,
      margin: '5px 20px',
    }),
  })
)

const transparentButtonStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 30,
      padding: '0.2vh 2vw',
    },
    label: {
      color: theme.palette.primary.main,
      margin: '4px 20px',
    },
  })
)

const orangeButtonStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      border: `1px solid ${theme.palette.warning.main}`,
      borderRadius: 30,
      padding: '0.2vh 2vw',
    },
    label: {
      color: theme.palette.warning.main,
      margin: '4px 20px',
    },
  })
)

const redButtonStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      backgroundColor: '#b60909',
      borderRadius: 20,
      padding: '0.2vh 2vw',
      '&:hover': {
        backgroundColor: '#8c1414',
      },
    },
    label: {
      color: 'white',
      margin: '5px 20px',
    },
  })
)

interface StyleProps {
  disabled: boolean
}

const ConardButton: FC<ConardButtonPropsUnited> = (props) => {
  const {
    conardVariant,
    type = 'button',
    onClick,
    autoFocus = false,
    startIcon,
    endIcon,
    disabled = false,
    className,
    form,
  } = props

  const whiteButtonClasses = whiteButtonStyles()
  const lightButtonClasses = lightButtonStyles({ disabled: disabled })
  const darkButtonClasses = darkButtonStyles({ disabled: disabled })
  const transparentButtonClasses = transparentButtonStyles()
  const redButtonClasses = redButtonStyles()
  const orangeButtonClasses = orangeButtonStyles()

  const useClasses = () => {
    switch (conardVariant) {
      case 'white':
        return whiteButtonClasses
      case 'light':
        return lightButtonClasses
      case 'dark':
        return darkButtonClasses
      case 'transparent':
        return transparentButtonClasses
      case 'red':
        return redButtonClasses
      case 'orange':
        return orangeButtonClasses
    }
  }

  return (
    <Button
      type={type}
      className={className}
      onClick={onClick}
      disabled={disabled}
      autoFocus={autoFocus}
      startIcon={startIcon}
      endIcon={endIcon}
      classes={useClasses()}
      form={form}
    >
      {props.children}
    </Button>
  )
}

export default ConardButton
