import React, { FC, useContext, useEffect, useState } from 'react'
import { createStyles, Grid } from '@material-ui/core'
import LayoutSideBox from './LayoutSideBox'
import { makeStyles } from '@material-ui/core/styles'
import { ConfigContext } from '../../context/ConfigContext'
import { Dialog, DialogActions, DialogContent } from '@mui/material'
import ConardButton from '../../components/ConardButton'
import { useTranslation } from 'react-i18next'
import terminalLayoutService from '../../services/TerminalLayoutService'
import { LayoutContainerDto } from '../../api'

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      minWidth: '1400px',
      backgroundColor: '#e3e3e3',
      padding: '2.5rem 0 0 2.5rem',
      borderRadius: '30px',
      boxShadow: '0 0 5px grey',
      display: 'flex',
      justifyContent: 'center',
    },
    axis: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 'large',
      padding: '10px',
    },
    yAxisLabel: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 'large',
      writingMode: 'vertical-rl',
    },
    dialog: {
      backgroundColor: 'transparent',
    },
    sideView: {
      width: '95%',
    },
    grid: {
      gap: 2,
    },
    sideBoxWrapper: {
      width: 'calc(95% / 8)',
    },
    button: {
      borderRadius: 12,
    },
  })
)

interface SideViewProps {
  showSideView: boolean
  sectorColumn: number
  sectorLetter: string
  onClose: () => void
}

const SideView: FC<SideViewProps> = ({ showSideView, onClose, sectorColumn, sectorLetter }) => {
  const classes = useStyles()
  const { coordinates } = useContext(ConfigContext)
  const { t } = useTranslation()
  const [sideView, setSideView] = useState<LayoutContainerDto[][]>()

  useEffect(() => {
    if (showSideView) {
      const fetchData = async (columnIndex: number, sectorName: string) => {
        const response = await terminalLayoutService.getSide({
          sectorColumn: columnIndex,
          sectorLetter: sectorName,
        })

        setSideView(response.data)
      }
      fetchData(sectorColumn, sectorLetter).catch((error) => {
        console.log(error)
      })
    }
  }, [sectorColumn, sectorLetter, showSideView])

  //todo repair keys

  return (
    <Dialog fullWidth maxWidth="xl" className={classes.dialog} open={showSideView} onClose={onClose}>
      <DialogContent>
        <div className={classes.paper}>
          <Grid container direction="row">
            <Grid item className={classes.sideView}>
              <Grid container direction="column" className={classes.grid}>
                {Array.from({ length: coordinates.maxSectorFloor }).map((_, floor) => (
                  <Grid container direction="row" key={floor} className={classes.grid}>
                    {sideView?.map((c, row) => (
                      <Grid item key={row} className={classes.sideBoxWrapper}>
                        <LayoutSideBox
                          container={sideView[row][floor]}
                          sector={sectorLetter}
                          column={sectorColumn}
                          row={coordinates.maxSectorRow - row}
                          floor={coordinates.maxSectorFloor - floor}
                        />
                      </Grid>
                    ))}
                    <Grid item className={classes.axis}>
                      {coordinates.maxSectorFloor - floor}
                    </Grid>
                  </Grid>
                ))}
                <Grid container direction="row" className={classes.grid}>
                  {Array.from({ length: coordinates.maxSectorRow }).map((_, index) => (
                    <Grid item key={index} className={`${classes.axis} ${classes.sideBoxWrapper}`}>
                      {coordinates.maxSectorRow - index}
                    </Grid>
                  ))}
                </Grid>
                <Grid container direction="row" className={classes.grid}>
                  <Grid item className={`${classes.axis} ${classes.sideView}`}>
                    {t('pages.terminalLayout.row')}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.yAxisLabel}>
              {t('pages.terminalLayout.floor')}
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <ConardButton className={classes.button} conardVariant="dark" onClick={onClose}>
          {t('any.buttons.close')}
        </ConardButton>
      </DialogActions>
    </Dialog>
  )
}

export default SideView
