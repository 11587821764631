import { useState } from 'react'

export const useKioskFormStep = (numberOfSteps: number) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0)
  const [withoutGateIn, setWithoutGateIn] = useState(false)
  const [withoutGateOut, setWithoutGateOut] = useState(false)

  const next = () => {
    if (currentStepIndex < numberOfSteps) {
      setCurrentStepIndex(currentStepIndex + 1)
    }
  }

  const previous = () => {
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1)
    }
  }

  const goTo = (index: number) => {
    setCurrentStepIndex(index)
  }

  const handleWithoutGateIn = (value: boolean) => {
    setWithoutGateIn(value)
  }

  const handleWithoutGateOut = (value: boolean) => {
    setWithoutGateOut(value)
  }

  return {
    currentStepIndex,
    next,
    previous,
    goTo,
    isLastStep: currentStepIndex === numberOfSteps,
    handleWithoutGateIn,
    handleWithoutGateOut,
    withoutGateIn,
    withoutGateOut,
  }
}
