import { AutocompleteApi, AutocompleteDto } from '../api'
import myAxios from '../config/axios/axios'
import { AxiosPromise } from 'axios'

let api: AutocompleteApi

export const setAutocompleteApiConfiguration = () => {
  api = new AutocompleteApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const autocompleteService = {
  find: (
    name: string,
    type: 'CONTAINER' | 'SEMITRAILER' | 'SHIPPING_CONTAINER' | 'CARRIER' | 'DRIVER' | 'LICENSE_PLATE' | 'FREE_DRIVER'
  ): AxiosPromise<AutocompleteDto[]> => {
    return api.autocompleteFind(name, type)
  },
}

export default autocompleteService
