import { AxiosPromise } from 'axios'
import { DriverArrivalDto, DriverArrivalKioskApi } from '../api'
import myAxios from '../config/axios/axios'

let api: DriverArrivalKioskApi

export const setDriverArrivalKioskApiConfiguration = () => {
  api = new DriverArrivalKioskApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const kioskDriverArrivalService = {
  create: (driverArrivalDto: DriverArrivalDto): AxiosPromise<DriverArrivalDto> => {
    return api.driverArrivalKioskCreateDriverArrival(driverArrivalDto)
  },

  update: (id: number, driverArrivalDto: DriverArrivalDto): AxiosPromise<DriverArrivalDto> => {
    return api.driverArrivalKioskUpdateDriverArrival(id, driverArrivalDto)
  },

  cancel: (id: number): AxiosPromise => {
    return api.driverArrivalKioskCancel(id)
  },

  getById: (id: number): AxiosPromise<DriverArrivalDto> => {
    return api.driverArrivalKioskGetDriverArrival(id)
  },

  finish: (id: number): AxiosPromise => {
    return api.driverArrivalKioskFinish(id)
  },
}

export default kioskDriverArrivalService
