import React, { FC, useEffect, useState } from 'react'
import { createStyles, Grid, MenuItem, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { CarrierDto, CarrierDtoContractTypeEnum, CompanyDto } from '../../api'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Mode } from '../../enums/Mode'
import ConardTextField from '../../components/ConardTextField'
import { makeStyles } from '@material-ui/core/styles'
import ConardButton from '../../components/ConardButton'
import { cleanObject } from '../../utils/utils'
import { Alert } from '@material-ui/lab'
import companyService from '../../services/CompanyService'
import carrierService from '../../services/CarrierService'
import ConardDatePicker from '../../components/ConardDatePicker'
import ConardSelect from '../../components/ConardSelect'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const useStyles = makeStyles((theme) =>
  createStyles({
    typography: {
      width: '60vw',
    },
    button: {
      width: '15vw',
      minWidth: '200px',
      textAlign: 'center',
      margin: 'auto',
    },
    container: {
      marginTop: '10vh',
      width: '50vw',
      minWidth: '300px',
      margin: 'auto',
    },
    alert: {
      margin: 'auto',
      width: '50vw',
      marginTop: '20vh',
    },
    datePickerInputInput: {
      width: '100%',
      textAlign: 'center',
    },
    datePickerInputRoot: {
      width: '60%',
      height: '58.5px',
      margin: 'auto',
    },
    datePickerRoot: {
      width: '100%',
      background: theme.palette.secondary.main,
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderRadius: '30px',
      marginTop: '-18px',
      '&:hover': {
        borderColor: 'black',
      },
    },
    colorDiv: {
      width: '10px',
      height: '10px',
      marginLeft: '10px',
      borderRadius: 8,
    },
    companySelectItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  })
)

interface CarrierFormProps {
  mode: Mode
  carrier?: CarrierDto
}

const CarrierEditForm: FC<CarrierFormProps> = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  const { carrier, mode } = props

  const [companies, setCompanies] = useState<CompanyDto[] | undefined>(undefined)

  const [selectCompany, setSelectCompany] = useState(carrier?.company?.id ?? 'NOT-SELECTED')
  const [selectContractType, setSelectContractType] = useState(carrier?.contractType ?? 'NOT-SELECTED')

  const yupSchema = yup.object().shape({
    name: yup.string().required(t('pages.carrier.validations.name.required')),
    contractType: yup.mixed().notOneOf(['NOT-SELECTED'], t('pages.carrier.validations.contractType.required')),
  })

  const { handleSubmit, control, errors } = useForm<CarrierDto>({
    resolver: yupResolver(yupSchema),
  })

  const getAllCompanies = () => {
    companyService.findAll(0, 100, '', '').then((response) => {
      setCompanies(response.data.content)
    })
  }

  const save = (data: CarrierDto) => {
    if (data.company?.id?.toString() === 'NOT-SELECTED') {
      data = {
        ...data,
        company: undefined,
      }
    }

    if (mode === Mode.Create) {
      carrierService
        .carrierCreate(cleanObject(data))
        .then(() => {
          history.push('/entry-and-parking/carrier')
        })
        .catch()
    } else {
      carrierService
        .carrierUpdate(carrier?.id || -1, cleanObject(data))
        .then(() => {
          history.push('/entry-and-parking/carrier')
        })
        .catch()
    }
  }

  useEffect(() => {
    getAllCompanies()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Typography className={classes.typography} variant="h4" color="primary">
        {mode === Mode.Create ? t('pages.carrier.create.title') : t('pages.carrier.update.title')}
      </Typography>

      {mode === Mode.Update && carrier === undefined && (
        <Alert severity="error" className={classes.alert}>
          {t('be.error.entity.notFound')}
        </Alert>
      )}

      {(mode === Mode.Create || carrier !== undefined) && (
        <form onSubmit={handleSubmit(save)}>
          <Grid
            container
            className={classes.container}
            direction="row"
            alignItems="flex-start"
            justifyContent="center"
            spacing={4}
          >
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="name"
                name="name"
                type="text"
                label={t('entity.carrier.fields.name')}
                defaultValue={carrier?.name ?? ''}
                fullWidth
                error={!!errors.name}
                helperText={errors.name ? errors.name.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="phone"
                name="phone"
                type="text"
                label={t('entity.carrier.fields.phone')}
                defaultValue={carrier?.phone ?? ''}
                fullWidth
                error={!!errors.phone}
                helperText={errors.phone ? errors.phone.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardSelect
                id="company.id"
                name="company.id"
                label={t('entity.company.fields.name')}
                defaultValue={carrier?.company?.id ?? 'NOT-SELECTED'}
                value={selectCompany}
                onChange={(event) => setSelectCompany(event.target.value)}
                control={control}
                error={!!errors.company?.id}
                helperText={errors.company?.id ? errors.company?.id?.message : ' '}
              >
                <MenuItem value="NOT-SELECTED">{t('any.notSelected')}</MenuItem>
                {companies?.map((company) => (
                  <MenuItem key={company.id} value={company.id}>
                    <div className={classes.companySelectItem}>
                      {company.name}
                      <div style={{ backgroundColor: company.color }} className={classes.colorDiv} />
                    </div>
                  </MenuItem>
                ))}
              </ConardSelect>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardSelect
                id="contractType"
                name="contractType"
                label={t('entity.carrier.fields.contractType')}
                defaultValue={carrier?.contractType ?? 'NOT-SELECTED'}
                control={control}
                value={selectContractType}
                onChange={(event) => setSelectContractType(event.target.value)}
                error={!!errors.contractType}
                helperText={errors.contractType ? errors.contractType.message : ' '}
              >
                <MenuItem value="NOT-SELECTED">{t('any.notSelected')}</MenuItem>
                {Object.values(CarrierDtoContractTypeEnum).map((value) => (
                  <MenuItem key={value} value={value}>
                    {t(`enum.contractType.${value}`)}
                  </MenuItem>
                ))}
              </ConardSelect>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardDatePicker
                name="contractBeginning"
                defaultValue={new Date(carrier?.contractBeginning ?? new Date())}
                control={control}
                label={t('entity.carrier.fields.contractBeginning')}
                error={!!errors.contractBeginning}
                helperText={errors.contractBeginning ? errors.contractBeginning.message : ' '}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardDatePicker
                name="contractEnd"
                defaultValue={new Date(carrier?.contractEnd ?? new Date())}
                control={control}
                label={t('entity.carrier.fields.contractEnd')}
                error={!!errors.contractEnd}
                helperText={errors.contractEnd ? errors.contractEnd.message : ' '}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <div className={classes.button}>
                <ConardButton conardVariant="dark" type="submit">
                  {t('any.buttons.save')}
                </ConardButton>
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <div className={classes.button}>
                <ConardButton conardVariant="dark" onClick={() => history.goBack()}>
                  {t('any.buttons.back')}
                </ConardButton>
              </div>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  )
}

export default CarrierEditForm
