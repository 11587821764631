import axios from 'axios'
import authService from '../../services/AuthService'
import { accessTokenItemName, refreshTokenItemName, tokenMinSize, useConardAuth } from '../../hooks/useConardAuth'
import { UserDtoRoleEnum } from '../../api'

const myAxios = axios.create({})
// eslint-disable-next-line react-hooks/rules-of-hooks
const auth = useConardAuth()

myAxios.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem(accessTokenItemName)
  if (accessToken) {
    config.headers.common['Authorization'] = 'Bearer ' + accessToken
  }

  return config
})

myAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config

    if (
      error.response.status === 401 &&
      error.response.data.error === 'unauthorized' &&
      (localStorage.getItem(accessTokenItemName) + '').length < tokenMinSize
    ) {
      window.location.reload()
    }
    if (
      error.response.status === 401 &&
      error.response.data.error === 'invalid_token' &&
      error.response.data.error_description.startsWith('Access token expired')
    ) {
      const refreshToken = localStorage.getItem(refreshTokenItemName)
      if (refreshToken) {
        //escape chars that could result to error during encoding
        const escapedTokenPart = refreshToken.split('.')[1].replace(/\s+/g, '').replace(/\-/g, '+').replace(/\_/g, '/')
        const tokenParts = JSON.parse(atob(escapedTokenPart))
        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000)

        if (tokenParts.exp > now) {
          authService
            .refreshToken(refreshToken)
            .then((response) => {
              auth.setAuthentication(response)
              originalRequest.headers.Authorization = 'Bearer ' + response.access_token
              return myAxios(originalRequest)
            })
            .catch((reason) => {
              throw new Error(reason)
            })
        } else {
          console.log('Refresh token is expired.')
          auth.clearAuthentication()
        }
      } else {
        console.log('Refresh token not available.')
        auth.clearAuthentication()
      }
      //window.location.reload()
    }

    // specific error handling done elsewhere
    return Promise.reject(error)
  }
)
const scheduleRefreshToken = () => {
  if (auth.getRole() === UserDtoRoleEnum.Kiosk) hardRefreshToken()
}

const refreshTokenInterval = 12 * 60 * 60 * 1000 // 12 hours
setInterval(scheduleRefreshToken, refreshTokenInterval)

export const hardRefreshToken = () => {
  const refreshToken = localStorage.getItem(refreshTokenItemName)
  if (refreshToken) {
    authService
      .refreshToken(refreshToken)
      .then((response) => {
        auth.setAuthentication(response)
      })
      .catch((reason) => {
        throw new Error(reason)
      })
  } else {
    console.log('Refresh token not available.')
    auth.clearAuthentication()
  }
}

export default myAxios
