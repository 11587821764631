import React, { FC } from 'react'
import CompanyForm from './CompanyForm'
import { Mode } from '../../../enums/Mode'

const CompanyCreatePage: FC = () => {
  return (
    <>
      <CompanyForm mode={Mode.Create} isParent={false} />
    </>
  )
}

export default CompanyCreatePage
