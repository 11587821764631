import React, { FC, useEffect, useState } from 'react'
import { createStyles, Grid, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { ShortTermPassageDto } from '../../api'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Mode } from '../../enums/Mode'
import ConardTextField from '../../components/ConardTextField'
import { makeStyles } from '@material-ui/core/styles'
import ConardButton from '../../components/ConardButton'
import { cleanObject } from '../../utils/utils'
import { Alert } from '@material-ui/lab'
import ConardDatePicker from '../../components/ConardDatePicker'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import shortTermPassageService from '../../services/ShortTermPassageService'
import ConardSwitch from '../../components/ConardSwitch'
import { FieldName } from 'react-hook-form/dist/types/fields'

const useStyles = makeStyles((theme) =>
  createStyles({
    switchText: {
      marginLeft: 35,
      fontSize: 12,
    },
    typography: {
      width: '60vw',
    },
    button: {
      width: '15vw',
      minWidth: '200px',
      textAlign: 'center',
      margin: 'auto',
    },
    container: {
      marginTop: '10vh',
      width: '50vw',
      minWidth: '300px',
      margin: 'auto',
    },
    alert: {
      margin: 'auto',
      width: '50vw',
      marginTop: '20vh',
    },
    datePickerInputInput: {
      width: '100%',
      textAlign: 'center',
    },
    datePickerInputRoot: {
      width: '60%',
      height: '58.5px',
      margin: 'auto',
    },
    datePickerRoot: {
      width: '100%',
      background: theme.palette.secondary.main,
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderRadius: '30px',
      marginTop: '-18px',
      '&:hover': {
        borderColor: 'black',
      },
    },
  })
)

interface ShortTermPassageFormProps {
  mode: Mode
  shortTermPassage?: ShortTermPassageDto
}

const ShortTermPassageEditForm: FC<ShortTermPassageFormProps> = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  const { shortTermPassage, mode } = props

  const [valid, setValid] = useState<boolean>(shortTermPassage?.valid ?? true)
  const [checkpointOneAllowed, setCheckpointOneAllowed] = useState<boolean>(false)
  const [checkpointTwoAllowed, setCheckpointTwoAllowed] = useState<boolean>(false)
  const [checkpointThreeAllowed, setCheckpointThreeAllowed] = useState<boolean>(false)

  const yupSchema = yup.object().shape({
    licensePlate: yup
      .string()
      .required(t('pages.licensePlate.validation.licensePlate.required'))
      .min(5, t('pages.gateIn.validations.semitrailerLicencePlate.min', { min: 5 }))
      .max(10, t('pages.gateIn.validations.semitrailerLicencePlate.max', { max: 10 })),
  })

  const { handleSubmit, control, errors } = useForm<ShortTermPassageDto>({
    resolver: yupResolver(yupSchema),
  })

  const changeValidSwitch = (newValue: boolean) => {
    control.setValue('valid', newValue)
    setValid(newValue)
  }

  const changeCheckpointOneAllowedSwitch = (field: FieldName<ShortTermPassageDto>, newValue: boolean) => {
    control.setValue(field, newValue)
    setCheckpointOneAllowed(newValue)
  }

  const changeCheckpointTwoAllowedSwitch = (field: FieldName<ShortTermPassageDto>, newValue: boolean) => {
    control.setValue(field, newValue)
    setCheckpointTwoAllowed(newValue)
  }

  const changeCheckpointThreeAllowedSwitch = (field: FieldName<ShortTermPassageDto>, newValue: boolean) => {
    control.setValue(field, newValue)
    setCheckpointThreeAllowed(newValue)
  }

  const save = (data: ShortTermPassageDto) => {
    data = {
      ...data,
      valid: data.valid ?? false,
      checkpointOneAllowed: data.checkpointOneAllowed ?? false,
      checkpointTwoAllowed: data.checkpointTwoAllowed ?? false,
      checkpointThreeAllowed: data.checkpointThreeAllowed ?? false,
    }

    if (mode === Mode.Create) {
      shortTermPassageService
        .shortTermPassageCreate(cleanObject(data))
        .then(() => {
          history.push('/entry-and-parking/short-term-passage')
        })
        .catch()
    } else {
      shortTermPassageService
        .shortTermPassageUpdate(shortTermPassage?.id || -1, cleanObject(data))
        .then(() => {
          history.push('/entry-and-parking/short-term-passage')
        })
        .catch()
    }
  }

  useEffect(() => {
    setValid(shortTermPassage?.valid ?? true)
    control.setValue('valid', shortTermPassage?.valid ?? true)
    setCheckpointOneAllowed(shortTermPassage?.checkpointOneAllowed ?? false)
    control.setValue('checkpointOneAllowed', shortTermPassage?.checkpointOneAllowed)
    setCheckpointTwoAllowed(shortTermPassage?.checkpointTwoAllowed ?? false)
    control.setValue('checkpointTwoAllowed', shortTermPassage?.checkpointTwoAllowed)
    setCheckpointThreeAllowed(shortTermPassage?.checkpointThreeAllowed ?? false)
    control.setValue('checkpointThreeAllowed', shortTermPassage?.checkpointThreeAllowed)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Typography className={classes.typography} variant="h4" color="primary">
        {mode === Mode.Create ? t('pages.shortTermPassage.create.title') : t('pages.shortTermPassage.update.title')}
      </Typography>

      {mode === Mode.Update && shortTermPassage === undefined && (
        <Alert severity="error" className={classes.alert}>
          {t('be.error.entity.notFound')}
        </Alert>
      )}

      {(mode === Mode.Create || (mode === Mode.Update && shortTermPassage !== undefined)) && (
        <form onSubmit={handleSubmit(save)}>
          <Grid
            container
            className={classes.container}
            direction="row"
            alignItems="flex-start"
            justifyContent="center"
            spacing={4}
          >
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="firstName"
                name="firstName"
                type="text"
                label={t('entity.shortTermPassage.fields.firstName')}
                defaultValue={shortTermPassage?.firstName ?? ''}
                fullWidth
                error={!!errors.firstName}
                helperText={errors.firstName ? errors.firstName.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="surname"
                name="surname"
                type="text"
                label={t('entity.shortTermPassage.fields.surname')}
                defaultValue={shortTermPassage?.surname ?? ''}
                fullWidth
                error={!!errors.surname}
                helperText={errors.surname ? errors.surname.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="licensePlate"
                name="licensePlate"
                type="text"
                label={t('entity.shortTermPassage.fields.licensePlate')}
                defaultValue={shortTermPassage?.licensePlate ?? ''}
                fullWidth
                error={!!errors.licensePlate}
                helperText={errors.licensePlate ? errors.licensePlate.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="company"
                name="company"
                type="text"
                label={t('entity.shortTermPassage.fields.company')}
                defaultValue={shortTermPassage?.company ?? ''}
                fullWidth
                error={!!errors.company}
                helperText={errors.company ? errors.company.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardDatePicker
                name="validFrom"
                defaultValue={new Date(shortTermPassage?.validFrom ?? new Date().setHours(0, 0, 0, 0))}
                control={control}
                label={t('entity.shortTermPassage.fields.validFrom')}
                error={!!errors.validFrom}
                helperText={errors.validFrom ? errors.validFrom.message : ' '}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardDatePicker
                name="validTo"
                defaultValue={new Date(shortTermPassage?.validTo ?? new Date().setHours(23, 59, 59, 0))}
                control={control}
                label={t('entity.shortTermPassage.fields.validTo')}
                error={!!errors.validTo}
                helperText={errors.validTo ? errors.validTo.message : ' '}
              />
            </Grid>
            <Grid item xl={3} lg={3} md={3} />
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardSwitch
                id="valid"
                name="valid"
                checked={valid}
                onChange={(newValue) => changeValidSwitch(newValue)}
                control={control}
                checkedLabel={t('pages.licensePlate.table.valid')}
                uncheckedLabel={t('pages.licensePlate.table.invalid')}
              />
            </Grid>
            <Grid item xl={3} lg={3} md={3} />
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
              <Typography className={classes.switchText}>{t('pages.licensePlate.table.checkpointOne')}</Typography>
              <ConardSwitch
                id="checkpointOneAllowed"
                name="checkpointOneAllowed"
                checked={checkpointOneAllowed}
                onChange={(newValue) => changeCheckpointOneAllowedSwitch('checkpointOneAllowed', newValue)}
                control={control}
                checkedLabel={t('pages.licensePlate.table.allowed')}
                uncheckedLabel={t('pages.licensePlate.table.notAllowed')}
              />
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
              <Typography className={classes.switchText}>{t('pages.licensePlate.table.checkpointTwo')}</Typography>
              <ConardSwitch
                id="checkpointTwoAllowed"
                name="checkpointTwoAllowed"
                checked={checkpointTwoAllowed}
                onChange={(newValue) => changeCheckpointTwoAllowedSwitch('checkpointTwoAllowed', newValue)}
                control={control}
                checkedLabel={t('pages.licensePlate.table.allowed')}
                uncheckedLabel={t('pages.licensePlate.table.notAllowed')}
              />
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
              <Typography className={classes.switchText}>{t('pages.licensePlate.table.checkpointThree')}</Typography>
              <ConardSwitch
                id="checkpointThreeAllowed"
                name="checkpointThreeAllowed"
                checked={checkpointThreeAllowed}
                onChange={(newValue) => changeCheckpointThreeAllowedSwitch('checkpointThreeAllowed', newValue)}
                control={control}
                checkedLabel={t('pages.licensePlate.table.allowed')}
                uncheckedLabel={t('pages.licensePlate.table.notAllowed')}
              />
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <div className={classes.button}>
                <ConardButton conardVariant="dark" type="submit">
                  {t('any.buttons.save')}
                </ConardButton>
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <div className={classes.button}>
                <ConardButton conardVariant="dark" onClick={() => history.goBack()}>
                  {t('any.buttons.back')}
                </ConardButton>
              </div>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  )
}

export default ShortTermPassageEditForm
