import React, { FC, useEffect, useState } from 'react'
import { createStyles, Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import ConardTextAutocomplete from '../ConardTextAutocomplete'
import { AutocompleteDto, CarrierShortDto, DriverShortDto, LicensePlateDto, NotificationDto } from '../../api'
import driverService from '../../services/DriverService'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      fontSize: 32,
      fontWeight: 550,
      color: theme.palette.primary.main,
      marginLeft: '20px',
    },
    paper: {
      padding: '20px',
      paddingBottom: 0,
      borderRadius: 12,
    },
    container: {
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      flexDirection: 'row',
    },
    item: {
      padding: '10px',
    },
  })
)

interface GateOutCoparnEmptyDriverFormProps {
  locationDriverShort?: DriverShortDto
  locationCarrierShort?: CarrierShortDto
  locationFrontLicensePlate?: LicensePlateDto
  locationRearLicensePlate?: LicensePlateDto
  notification?: NotificationDto
}

export const GateOutCoparnDriverForm: FC<GateOutCoparnEmptyDriverFormProps> = (props) => {
  const {
    notification,
    locationDriverShort = { id: undefined, name: '' },
    locationCarrierShort = { id: undefined, name: '' },
    locationFrontLicensePlate = { id: undefined, licensePlate: notification?.licensePlateFront ?? '' },
    locationRearLicensePlate = { id: undefined, licensePlate: notification?.licensePlateRear ?? '' },
  } = props
  const { control, errors } = useFormContext()
  const classes = useStyles()
  const { t } = useTranslation()

  const [driver, setDriver] = useState<DriverShortDto>(locationDriverShort)
  const [carrier, setCarrier] = useState<CarrierShortDto>(locationCarrierShort)
  const [frontLicensePlate, setFrontLicensePlate] = useState<LicensePlateDto>(locationFrontLicensePlate)
  const [rearLicensePlate, setRearLicensePlate] = useState<LicensePlateDto>(locationRearLicensePlate)

  useEffect(() => {
    setDriver(locationDriverShort)
    setCarrier(locationCarrierShort)
    setFrontLicensePlate(locationFrontLicensePlate)
    setRearLicensePlate(locationRearLicensePlate)
  }, [notification]) // eslint-disable-line react-hooks/exhaustive-deps

  const onChooseDriver = (autocompleteDto: AutocompleteDto) => {
    setDriver(autocompleteDto as DriverShortDto)
    driverService.findById(autocompleteDto.id ?? -1).then((response) => {
      setCarrier({
        id: response.data.carrierShort?.id,
        name: response.data.carrierShort?.name ?? '',
      })
      if (locationFrontLicensePlate.licensePlate === '') {
        setFrontLicensePlate({
          id: response.data.frontLicensePlate?.id,
          licensePlate: response.data.frontLicensePlate?.licensePlate ?? '',
        })
      }
      if (locationRearLicensePlate.licensePlate === '') {
        setRearLicensePlate({
          id: response.data.rearLicensePlate?.id,
          licensePlate: response.data.rearLicensePlate?.licensePlate ?? '',
        })
      }
    })
  }

  const changeDriver = (autocompleteDto: AutocompleteDto) => {
    setDriver(autocompleteDto as DriverShortDto)
  }

  const changeCarrier = (autocompleteDto: AutocompleteDto) => {
    setCarrier(autocompleteDto as CarrierShortDto)
  }

  const changeFrontLicensePlate = (autocompleteDto: AutocompleteDto) => {
    setFrontLicensePlate({
      id: autocompleteDto.id,
      licensePlate: autocompleteDto.name ?? '',
    })
  }

  const changeRearLicensePlate = (autocompleteDto: AutocompleteDto) => {
    setRearLicensePlate({
      id: autocompleteDto.id,
      licensePlate: autocompleteDto.name ?? '',
    })
  }

  useEffect(() => {
    control.setValue('driverShort', driver)
  }, [driver, control])

  useEffect(() => {
    control.setValue('carrierShort', carrier)
  }, [carrier, control])

  useEffect(() => {
    control.setValue('frontLicensePlate', frontLicensePlate)
  }, [frontLicensePlate, control])

  useEffect(() => {
    control.setValue('rearLicensePlate', rearLicensePlate)
  }, [rearLicensePlate, control])

  return (
    <Paper className={classes.paper}>
      <Typography className={classes.header}>{t('pages.driverForm.labels.form')}</Typography>
      <Grid container className={classes.container}>
        <Grid item md={3} xs={6} className={classes.item}>
          <ConardTextAutocomplete
            optionType="DRIVER"
            value={driver}
            onChoose={onChooseDriver}
            setValue={changeDriver}
            name="driverShort"
            control={control}
            label={t('pages.gateIn.form.driver.label')}
            error={!!errors.driverShort}
            helperText={!!errors.driverShort ? errors.driverShort.message : ' '}
          />
        </Grid>
        <Grid item md={3} xs={6} className={classes.item}>
          <ConardTextAutocomplete
            optionType="CARRIER"
            value={carrier}
            setValue={changeCarrier}
            name="carrierShort"
            control={control}
            label={t('pages.gateIn.form.carrier.label')}
            error={!!errors.carrierShort}
            helperText={!!errors.carrierShort ? errors.carrierShort.message : ' '}
          />
        </Grid>
        <Grid item md={3} xs={6} className={classes.item}>
          <ConardTextAutocomplete
            optionType="LICENSE_PLATE"
            value={{ id: frontLicensePlate?.id, name: frontLicensePlate?.licensePlate }}
            setValue={changeFrontLicensePlate}
            name="frontLicensePlate"
            control={control}
            label={t('entity.driver.fields.frontLicensePlate')}
            error={!!errors.frontLicensePlate}
            helperText={!!errors.frontLicensePlate ? errors.frontLicensePlate.licensePlate.message : ' '}
          />
        </Grid>
        <Grid item md={3} xs={6} className={classes.item}>
          <ConardTextAutocomplete
            optionType="LICENSE_PLATE"
            value={{ id: rearLicensePlate?.id, name: rearLicensePlate?.licensePlate }}
            setValue={changeRearLicensePlate}
            name="rearLicensePlate"
            control={control}
            label={t('entity.driver.fields.rearLicensePlate')}
            error={!!errors.rearLicensePlate}
            helperText={!!errors.rearLicensePlate ? errors.rearLicensePlate.licensePlate.message : ' '}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}
