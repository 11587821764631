import React, { FC, useContext, useState } from 'react'
import { createStyles, Grid, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { CoparnSearchDto } from '../../api'
import ConardButton from '../../components/ConardButton'
import { makeStyles } from '@material-ui/core/styles'
import { ConfigContext } from '../../context/ConfigContext'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(34px, -6px) scale(0.75)',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        '& legend': {
          marginLeft: '20px',
        },
      },
    },
    inputRoot: {
      borderRadius: 30,
      borderColor: theme.palette.secondary.light,
      backgroundColor: theme.palette.secondary.main,
    },
  })
)

interface CoparnFilterProps {
  coparnFilter: (data: CoparnSearchDto) => void
  reference?: string
}

const GateOutEmptyCoparnFilter: FC<CoparnFilterProps> = ({ coparnFilter, reference }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { generalCarrierReference } = useContext(ConfigContext)

  const [medlogReference, setMedlogReference] = useState<string>(reference ?? '')

  const handleChangeFilter = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setMedlogReference(event.target.value)
  }

  const handleSearch = () => {
    coparnFilter({
      medlogReference,
    })
  }

  return (
    <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
      <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
        <TextField
          id="medlogReference"
          name="medlogReference"
          type="text"
          defaultValue={medlogReference}
          label={generalCarrierReference ? t('pages.gateOut.coparn.carrierRef') : t('pages.gateOut.coparn.medlogRef')}
          onChange={(e) => handleChangeFilter(e)}
          variant="outlined"
          InputProps={{
            className: classes.inputRoot,
          }}
          className={classes.root}
        />
      </Grid>
      <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
        <div>
          <ConardButton conardVariant="light" onClick={handleSearch}>
            {t('any.buttons.filter')}
          </ConardButton>
        </div>
      </Grid>
    </Grid>
  )
}

export default GateOutEmptyCoparnFilter
