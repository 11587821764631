import React, { FC, useEffect, useState } from 'react'
import { createStyles, Grid, MenuItem, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import ConardSelect from '../../components/ConardSelect'
import ConardTextField from '../../components/ConardTextField'
import { makeStyles } from '@material-ui/core/styles'
import ConardButton from '../../components/ConardButton'
import { cleanObject } from '../../utils/utils'
import { Alert } from '@material-ui/lab'
import companyService from '../../services/CompanyService'
import ConardDatePicker from '../../components/ConardDatePicker'
import { FieldName } from 'react-hook-form/dist/types/fields'
import { AsparkingTicketDto, CompanyDto } from '../../api'
import ticketService from '../../services/TicketService'
import ConardSwitch from '../../components/ConardSwitch'

const useStyles = makeStyles((theme) =>
  createStyles({
    typography: {
      width: '60vw',
    },
    button: {
      width: '15vw',
      minWidth: '200px',
      textAlign: 'center',
      margin: 'auto',
    },
    container: {
      marginTop: '10vh',
      width: '50vw',
      minWidth: '300px',
      margin: 'auto',
    },
    alert: {
      margin: 'auto',
      width: '50vw',
      marginTop: '20vh',
    },
    datePickerInputInput: {
      width: '100%',
      textAlign: 'center',
    },
    datePickerInputRoot: {
      width: '60%',
      height: '58.5px',
      margin: 'auto',
    },
    datePickerRoot: {
      width: '100%',
      background: theme.palette.secondary.main,
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderRadius: '30px',
      marginTop: '-18px',
      '&:hover': {
        borderColor: 'black',
      },
    },
    switchText: {
      marginLeft: 35,
      fontSize: 12,
    },
    colorDiv: {
      width: '10px',
      height: '10px',
      borderRadius: '100%',
      marginLeft: '10px',
    },
    companySelectItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  })
)

interface TicketFormProps {
  ticket?: AsparkingTicketDto
}

const TicketEditForm: FC<TicketFormProps> = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  const { ticket } = props

  const [companies, setCompanies] = useState<CompanyDto[] | undefined>(undefined)
  const [disabled, setDisabled] = useState<boolean>(false)

  const [selectCompany, setSelectCompany] = useState(ticket?.company?.id ?? 'NOT-SELECTED')

  const { handleSubmit, control, errors } = useForm<AsparkingTicketDto>()

  const getAllCompanies = () => {
    companyService.findAll(0, 100, '', '').then((response) => {
      setCompanies(response.data.content)
    })
  }

  const changeSwitch = (field: FieldName<AsparkingTicketDto>, newValue: boolean) => {
    control.setValue(field, newValue)
    setDisabled(newValue)
  }

  const save = (data: AsparkingTicketDto) => {
    if (data.company?.id?.toString() === 'NOT-SELECTED') {
      data = {
        ...data,
        company: undefined,
      }
    }

    ticketService
      .ticketUpdate(ticket?.id || -1, cleanObject(data))
      .then(() => {
        history.push('/entry-and-parking/ticket')
      })
      .catch((error) => {
        console.error('Error updating ticket: ', error)
      })
  }

  useEffect(() => {
    getAllCompanies()
    setDisabled(ticket?.departurePermission ?? false)
    control.setValue('departurePermission', ticket?.departurePermission)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Typography className={classes.typography} variant="h4" color="primary">
        {t('ticket.update.title')}
      </Typography>

      {ticket === undefined && (
        <Alert severity="error" className={classes.alert}>
          {t('be.error.entity.notFound')}
        </Alert>
      )}

      {ticket && (
        <form onSubmit={handleSubmit(save)}>
          <Grid
            container
            className={classes.container}
            direction="row"
            alignItems="flex-start"
            justifyContent="center"
            spacing={4}
          >
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="licencePlate"
                name="licencePlate"
                type="text"
                label={t('entity.ticket.fields.licencePlate')}
                disabled
                defaultValue={ticket?.licencePlate}
                fullWidth
                error={!!errors.licencePlate}
                helperText={errors.licencePlate ? errors.licencePlate.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="barcode"
                name="barcode"
                type="text"
                label={t('entity.ticket.fields.barcode')}
                disabled
                defaultValue={ticket?.barcode}
                fullWidth
                error={!!errors.barcode}
                helperText={errors.barcode ? errors.barcode.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardSelect
                name="company.id"
                label={t('entity.ticket.fields.company')}
                defaultValue={ticket?.company?.id ?? 'NOT-SELECTED'}
                value={selectCompany}
                onChange={(event) => setSelectCompany(event.target.value)}
                error={!!errors.company}
                helperText={errors.company ? errors.company : ' '}
                control={control}
              >
                <MenuItem key="NOT-SELECTED" value="NOT-SELECTED">
                  {t('any.notSelected')}
                </MenuItem>
                {companies?.map((company) => (
                  <MenuItem key={company.id} value={company.id}>
                    <div className={classes.companySelectItem}>
                      {company.name}
                      <div style={{ backgroundColor: company.color }} className={classes.colorDiv} />
                    </div>
                  </MenuItem>
                ))}
              </ConardSelect>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardSwitch
                id="departurePermission"
                name="departurePermission"
                checked={disabled}
                onChange={(newValue) => changeSwitch('departurePermission', newValue)}
                control={control}
                checkedLabel={t('entity.ticket.fields.departureAllowed')}
                uncheckedLabel={t('entity.ticket.fields.departureNotAllowed')}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              {ticket?.arrivedAt === null && (
                <ConardTextField
                  name="arrivedAt"
                  type="text"
                  label={t('entity.ticket.fields.arrivedAt')}
                  disabled
                  defaultValue={undefined}
                  fullWidth
                  error={!!errors.arrivedAt}
                  helperText={errors.arrivedAt ? errors.arrivedAt.message : ' '}
                  control={control}
                />
              )}

              {ticket?.arrivedAt !== null && (
                <ConardDatePicker
                  name="arrivedAt"
                  defaultValue={new Date(ticket?.arrivedAt ?? new Date())}
                  disabled
                  control={control}
                  label={t('entity.ticket.fields.arrivedAt')}
                  error={!!errors.arrivedAt}
                  helperText={errors.arrivedAt ? errors.arrivedAt.message : ' '}
                />
              )}
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              {ticket?.leaveAt === null && (
                <ConardTextField
                  name="leaveAt"
                  type="text"
                  label={t('entity.ticket.fields.leaveAt')}
                  disabled
                  defaultValue={undefined}
                  fullWidth
                  error={!!errors.leaveAt}
                  helperText={errors.leaveAt ? errors.leaveAt.message : ' '}
                  control={control}
                />
              )}

              {ticket?.leaveAt !== null && (
                <ConardDatePicker
                  name="leaveAt"
                  defaultValue={new Date(ticket?.leaveAt ?? new Date())}
                  disabled
                  control={control}
                  label={t('entity.ticket.fields.leaveAt')}
                  error={!!errors.leaveAt}
                  helperText={errors.leaveAt ? errors.leaveAt.message : ' '}
                />
              )}
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <div className={classes.button}>
                <ConardButton conardVariant="dark" type="submit">
                  {t('any.buttons.save')}
                </ConardButton>
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <div className={classes.button}>
                <ConardButton conardVariant="dark" onClick={() => history.goBack()}>
                  {t('any.buttons.back')}
                </ConardButton>
              </div>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  )
}

export default TicketEditForm
