import {
  ColumnSelectDto,
  LayoutApi,
  LayoutContainerDto,
  SectorSelectDto,
  StackContainerDto,
  StackContainerSearchDto,
  TerminalSectorLayoutDto,
} from '../api'
import myAxios from '../config/axios/axios'
import { AxiosPromise } from 'axios'

let api: LayoutApi

export const setTerminalLayoutApiConfiguration = () => {
  api = new LayoutApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const terminalLayoutService = {
  getSectors: (): AxiosPromise<TerminalSectorLayoutDto[]> => {
    return api.layoutGetTerminalLayout()
  },

  getFloor: (floor: StackContainerSearchDto): AxiosPromise<StackContainerDto[]> => {
    return api.layoutGetStackLayout(floor)
  },

  getSide: (column: ColumnSelectDto): AxiosPromise<LayoutContainerDto[][]> => {
    return api.layoutGetSideLayout(column)
  },

  getUnordered: (sector: SectorSelectDto): AxiosPromise<LayoutContainerDto[]> => {
    return api.layoutGetUnorderedLayout(sector)
  },
}

export default terminalLayoutService
