import { makeStyles } from '@material-ui/core'
import { Box } from '@mui/system'
import React, { FC } from 'react'
import ConardButton from '../../components/ConardButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 'auto',
    marginTop: '40px',
    maxWidth: '1100px',
  },
  button: {
    width: '250px',
    height: '80px',
    borderRadius: 12,
    fontWeight: 500,
    fontSize: 'large',
  },
  nextButton: {
    boxShadow: '-2px 2px 5px gray',
  },
}))

interface KioskButtonsProps {
  visibleBack: boolean
  visibleNext: boolean
  onClickBack?: () => void
}

export const KioskButtons: FC<KioskButtonsProps> = ({ visibleBack, visibleNext, onClickBack }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box className={classes.buttonsWrapper}>
      {visibleBack ? (
        <ConardButton
          className={classes.button}
          onClick={onClickBack}
          startIcon={<ArrowBackIcon />}
          conardVariant="transparent"
        >
          {t('kioskApp.buttons.back')}
        </ConardButton>
      ) : (
        <div></div>
      )}

      {visibleNext && (
        <ConardButton
          className={`${classes.button} ${classes.nextButton}`}
          endIcon={<ArrowForwardIcon />}
          conardVariant="dark"
          type="submit"
        >
          {t('kioskApp.buttons.next')}
        </ConardButton>
      )}
    </Box>
  )
}
