import React, { FC, useState } from 'react'
import { createStyles, Grid, Hidden, InputAdornment, makeStyles, MenuItem } from '@material-ui/core'
import ConardSelect from '../../components/ConardSelect'
import { ProcessedFileSearchDto, ProcessedFileSearchDtoProcessedFileTypeEnum } from '../../api'
import ConardTextField from '../../components/ConardTextField'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { DatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import ConardButton from '../../components/ConardButton'

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      width: '95%',
    },
    searchButton: {
      width: '15vw',
      minWidth: '300px',
      height: '5vh',
      minHeight: '50px',
      textAlign: 'center',
      margin: '20px',
      float: 'right',
    },
    datePickerInputInput: {
      width: '100%',
      textAlign: 'center',
    },
    datePickerInputRoot: {
      width: '60%',
      height: '56px',
      margin: 'auto',
    },
    datePickerRoot: {
      width: '100%',
      background: theme.palette.secondary.main,
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderRadius: '30px',
      '&:hover': {
        borderColor: 'black',
      },
    },
  })
)

interface SearchFilterProps {
  searchFilter: (data: ProcessedFileSearchDto) => void
}

const ProcessedFileSearchFilter: FC<SearchFilterProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { searchFilter } = props

  const { handleSubmit, control, setValue } = useForm<ProcessedFileSearchDto>({})

  const [processedFileDateFrom, setProcessedFileDateFrom] = useState<MaterialUiPickersDate | null>(null)
  const [processedFileDateTo, setProcessedFileDateTo] = useState<MaterialUiPickersDate | null>(null)
  const [selectedProcessedFileType, setSelectedProcessedFileType] = useState<string>('NOT-SELECTED')

  const clearTime = (date: MaterialUiPickersDate | null) => {
    date?.setHours(0)
    date?.setMinutes(0)
    date?.setSeconds(0)
  }

  const dateFromOnChange = (date: MaterialUiPickersDate | null) => {
    clearTime(date)
    setProcessedFileDateFrom(date || new Date())
    setValue('processedAtFrom', date)
  }
  const dateToOnChange = (date: MaterialUiPickersDate | null) => {
    clearTime(date)
    setProcessedFileDateTo(date || new Date())
    setValue('processedAtTo', date)
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit(searchFilter)}>
      <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={2}>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardTextField
            id="fileName"
            name="fileName"
            label={t('pages.searchProcessedFiles.form.fileName')}
            defaultValue=""
            control={control}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardSelect
            id="processedFileType"
            name="processedFileType"
            label={t('pages.searchProcessedFiles.form.processedFileType')}
            defaultValue="NOT-SELECTED"
            value={selectedProcessedFileType}
            onChange={(event) => setSelectedProcessedFileType(event.target.value)}
            control={control}
          >
            <MenuItem value="NOT-SELECTED">{t('pages.common.select.all')}</MenuItem>
            {Object.values(ProcessedFileSearchDtoProcessedFileTypeEnum).map((value) => (
              <MenuItem key={value} value={value}>
                {t(`enum.processedFileType.${value}`)}
              </MenuItem>
            ))}
          </ConardSelect>
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <Controller
            name="processedAtFrom"
            control={control}
            defaultValue={null}
            render={() => (
              <DatePicker
                emptyLabel={t('pages.searchTransition.datePicker.labels.processedFileDateFrom')}
                format="dd.MM.yyyy"
                value={processedFileDateFrom}
                onChange={(value) => {
                  dateFromOnChange(value)
                }}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <Hidden lgDown>
                      <InputAdornment position="start">
                        <CalendarTodayIcon />
                      </InputAdornment>
                    </Hidden>
                  ),
                  classes: {
                    root: classes.datePickerInputRoot,
                    input: classes.datePickerInputInput,
                  },
                }}
                className={classes.datePickerRoot}
              />
            )}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <Controller
            name="processedAtTo"
            defaultValue={null}
            control={control}
            render={() => (
              <DatePicker
                emptyLabel={t('pages.searchTransition.datePicker.labels.processedFileDateTo')}
                format="dd.MM.yyyy"
                value={processedFileDateTo}
                onChange={(value) => {
                  dateToOnChange(value)
                }}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <Hidden lgDown>
                      <InputAdornment position="start">
                        <CalendarTodayIcon />
                      </InputAdornment>
                    </Hidden>
                  ),
                  classes: {
                    root: classes.datePickerInputRoot,
                    input: classes.datePickerInputInput,
                  },
                }}
                className={classes.datePickerRoot}
              />
            )}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className={classes.searchButton}>
            <ConardButton conardVariant="light" type="submit">
              {t('any.buttons.search')}
            </ConardButton>
          </div>
        </Grid>
      </Grid>
    </form>
  )
}

export default ProcessedFileSearchFilter
