import React, { FC, useEffect } from 'react'
import { ContainerAdditionalProperty } from './ContainerAdditionalProperty'
import { useTranslation } from 'react-i18next'
import ConardTextField from '../../../components/ConardTextField'
import { InputAdornment, makeStyles } from '@material-ui/core'
import { ContainerDto } from '../../../api'
import { useFormContext, useWatch } from 'react-hook-form'

const useStyles = makeStyles({
  hide: {
    display: 'none',
  },
})

interface ContainerPropertiesProps {
  container: ContainerDto | undefined
}

export const ContainerProperties: FC<ContainerPropertiesProps> = ({ container }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { control, errors } = useFormContext()

  const isRepleted = useWatch({
    control: control,
    name: 'repleted',
  })

  const isHeavyTested = useWatch({
    control: control,
    name: 'container.heavyTested',
  })

  useEffect(() => {
    control.setValue('container.heavyTested', !control.getValues('container.heavyTestedValue') ? !isRepleted : true)
  }, [isRepleted, control])

  useEffect(() => {
    control.setValue('container.heavyTested', container?.heavyTested || !isRepleted)
    control.setValue('container.lashingRings', container?.lashingRings ?? false)
    control.setValue('container.innerWidth', container?.lashingRings ?? false)
    control.setValue('container.flexiTanks', container?.flexiTanks ?? false)
    control.setValue('container.woodenFloor', container?.woodenFloor ?? false)
    control.setValue('container.heavyTestedValue', container?.heavyTestedValue ?? '')
  }, [control, container]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <ContainerAdditionalProperty
        label={t('form.containerForm.properties.heavyTested')}
        name="container.heavyTested"
        control={control}
        disabled={container?.heavyTested !== undefined}
      />
      <div className={isHeavyTested ? `` : classes.hide}>
        <ConardTextField
          roundedSquare
          name="container.heavyTestedValue"
          control={control}
          defaultValue=""
          disabled={!!container?.heavyTestedValue}
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
          }}
          error={!!errors.container?.heavyTestedValue}
          helperText={errors.container?.heavyTestedValue?.message ?? ''}
        />
      </div>
      <ContainerAdditionalProperty
        label={t('form.containerForm.properties.lashingRings')}
        name="container.lashingRings"
        control={control}
        disabled={container?.lashingRings !== undefined}
      />
      <ContainerAdditionalProperty
        label={t('form.containerForm.properties.innerWidth')}
        name="container.innerWidth"
        control={control}
        disabled={container?.innerWidth !== undefined}
      />
      <ContainerAdditionalProperty
        label={t('form.containerForm.properties.flexiTanks')}
        name="container.flexiTanks"
        control={control}
        disabled={container?.flexiTanks !== undefined}
      />
      <ContainerAdditionalProperty
        label={t('form.containerForm.properties.woodenFloor')}
        name="container.woodenFloor"
        control={control}
        disabled={container?.woodenFloor !== undefined}
      />
    </>
  )
}
