import React, { FC } from 'react'
import { ConardAccordion, ConardAccordionSummary } from '../../../components/transition/ConardAccordion'
import { Grid, makeStyles } from '@material-ui/core'
import { AccordionDetails } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import { RHFSwitch } from '../../../components/shared/rhf/RHFSwitch'
import { AdrRidField } from './AdrRidField'
import { AdrRidCodeDto } from '../../../api'

const useStyles = makeStyles((theme) => ({
  adrRidLabel: {
    color: theme.palette.warning.main,
  },
  adrRidEmptyLabel: {
    color: 'gray',
  },
}))

interface AdrRidBoxProps {
  initialCodes?: AdrRidCodeDto[]
}

export const AdrRidBox: FC<AdrRidBoxProps> = ({ initialCodes }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { control } = useFormContext()

  const hasAdrRid = useWatch({
    control,
    name: 'adrRid',
  })

  return (
    <div>
      <ConardAccordion expanded={!!hasAdrRid}>
        <ConardAccordionSummary>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item className={hasAdrRid ? classes.adrRidLabel : classes.adrRidEmptyLabel}>
              {t('form.transitionForm.adrRid')}
            </Grid>
            <Grid item>
              <RHFSwitch adrRid name="adrRid" label="" />
            </Grid>
          </Grid>
        </ConardAccordionSummary>
        <AccordionDetails>
          <AdrRidField isDisabled={!hasAdrRid} initialCodes={initialCodes} />
        </AccordionDetails>
      </ConardAccordion>
    </div>
  )
}
