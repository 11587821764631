import React, { FC, useEffect, useState } from 'react'
import { ShortTermPassageDto } from '../../api'
import { useParams } from 'react-router-dom'
import { Mode } from '../../enums/Mode'
import shortTermPassageService from '../../services/ShortTermPassageService'
import ShortTermPassageEditForm from './ShortTermPassageEditForm'

interface UrlParamTypes {
  id?: string
}

const ShortTermPassageUpdatePage: FC = () => {
  const { id } = useParams<UrlParamTypes>()

  const [shortTermPassage, setShortTermPassage] = useState<ShortTermPassageDto>()
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (id !== undefined) {
      shortTermPassageService
        .shortTermPassageFindById(Number.parseInt(id))
        .then((response) => {
          setShortTermPassage(response.data)
          setLoaded(true)
        })
        .catch(() => {
          setLoaded(true)
        })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return <>{loaded && <ShortTermPassageEditForm mode={Mode.Update} shortTermPassage={shortTermPassage} />}</>
}

export default ShortTermPassageUpdatePage
