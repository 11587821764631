import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import {
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { PageTrainDto, TrainDto, TrainSearchDto } from '../../api'
import { cleanObject } from '../../utils/utils'
import { Order } from '../../enums/Order'
import ConardButton from '../../components/ConardButton'
import { useHistory } from 'react-router-dom'
import trainService from '../../services/TrainService'
import TrainSearchFilter from './TrainSearchFilter'
import ConardProgressBar from '../../components/ConardProgressBar'
import { format } from 'date-fns'

const useStyles = makeStyles({
  gridContainer: {
    marginTop: '8vh',
  },
  detailButton: {
    width: '7vw',
  },
})

interface HeadCell {
  id: keyof TrainDto
  label: string
}

const TrainSearchListPage: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  const [pageTrains, setPageTrains] = useState<PageTrainDto | null>()
  const [searchDto, setSearchDto] = useState<TrainSearchDto | undefined>()

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(50)
  const [order, setOrder] = useState<Order>(Order.Desc)
  const [orderBy, setOrderBy] = useState<keyof TrainDto>('departureDateTime')
  const [showTable, setShowTable] = useState(true)

  const headCells: HeadCell[] = [
    { id: 'name', label: t('pages.train.labels.trainName') },
    { id: 'trainState', label: t('pages.train.labels.trainState') },
    {
      id: 'departureDateTime',
      label: t('pages.train.labels.trainPlannedArrivalAndDepartureTimeInBrackets'),
    },
    { id: 'realDepartureDateTime', label: t('pages.train.labels.realDepartureDateTime') },
  ]

  useEffect(() => {
    if (showTable) {
      search(searchDto, false)
    }
  }, [page, pageSize, orderBy, order]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSort = (property: keyof TrainDto) => () => {
    const isAsc = orderBy === property && order === Order.Asc
    setOrder(isAsc ? Order.Desc : Order.Asc)
    setOrderBy(property)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value))
    setPage(0)
  }

  const searchFiltered = (data: TrainSearchDto | undefined) => {
    search(data, true)
  }

  const search = (data: TrainSearchDto | undefined, fromFilter: boolean) => {
    setSearchDto(data)

    let currentSort: Array<string> | undefined = undefined
    if (orderBy !== undefined) {
      const currentOrder: string = order !== undefined ? order : 'asc'
      currentSort = [orderBy + ',' + currentOrder]
    }

    trainService
      .search(page, pageSize, currentSort, cleanObject(data))
      .then((response) => {
        setPageTrains(response.data)
        setShowTable(true)
        if (fromFilter) {
          setPage(0)
        }
      })
      .catch()
  }

  return (
    <>
      <Typography variant="h4" color="primary">
        {t('pages.searchTrain.title')}
      </Typography>
      <Grid
        container
        className={classes.gridContainer}
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={3}
      >
        <TrainSearchFilter searchFilter={searchFiltered} />
        {showTable && (
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Paper variant="outlined">
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {headCells.map((headCell) => (
                        <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                          <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={handleSort(headCell.id)}
                          >
                            {headCell.label}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pageTrains?.content?.length ?? 0 > 0 ? (
                      pageTrains?.content?.map((train) => (
                        <TableRow key={train.id}>
                          <TableCell component="th" scope="row">
                            {train.name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {t(`enum.trainState.${train.trainState}`)}
                          </TableCell>
                          <TableCell component="td" scope="row">
                            {train.departureDateTime
                              ? format(new Date(train.departureDateTime), 'dd.MM.yyyy HH:mm')
                              : ''}
                          </TableCell>
                          <TableCell component="td" scope="row">
                            {train.realDepartureDateTime
                              ? format(new Date(train.realDepartureDateTime), 'dd.MM.yyyy HH:mm')
                              : ''}
                          </TableCell>
                          <TableCell className={classes.detailButton}>
                            <ConardButton
                              conardVariant="light"
                              onClick={() => history.push('/search/train/detail/' + train.id)}
                            >
                              {t('any.buttons.detail')}
                            </ConardButton>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow key="noData">
                        <TableCell component="th" scope="row" colSpan={7}>
                          {t('pages.common.table.noData')}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <ConardProgressBar showBar={!pageTrains} />
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50, 150]}
                component="div"
                count={pageTrains?.totalElements ?? 0}
                rowsPerPage={pageSize}
                labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default TrainSearchListPage
