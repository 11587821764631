import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { ContentPasteOffOutlined, InventoryOutlined } from '@mui/icons-material'
import ConardButton from '../ConardButton'

const useStyles = makeStyles((theme) =>
  createStyles({
    successIcon: {
      color: theme.palette.primary.main,
      margin: '20px',
    },
    errorIcon: {
      color: theme.palette.error.main,
      margin: '20px',
    },
    gridContainer: {
      fontSize: '10rem',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
)

interface MedlogBoardDialogProps {
  showDialog: boolean
  setShowDialog: React.Dispatch<React.SetStateAction<boolean>>
  success: boolean
}

const MedlogBoardDialog: FC<MedlogBoardDialogProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { showDialog, setShowDialog, success } = props

  return (
    <Dialog open={showDialog}>
      <DialogTitle>{t('pages.gateIn.dialogs.medlogBoard.title')}</DialogTitle>
      <DialogContent>
        {success && (
          <Grid container className={classes.gridContainer}>
            <InventoryOutlined fontSize="inherit" className={classes.successIcon} />
            <Typography>{t('pages.gateIn.dialogs.medlogBoard.success')}</Typography>
          </Grid>
        )}
        {!success && (
          <Grid container className={classes.gridContainer}>
            <ContentPasteOffOutlined fontSize="inherit" className={classes.errorIcon} />
            <Typography>{t('pages.gateIn.dialogs.medlogBoard.failure')}</Typography>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <ConardButton conardVariant="dark" onClick={() => setShowDialog(false)}>
          {t('any.buttons.close')}
        </ConardButton>
      </DialogActions>
    </Dialog>
  )
}

export default MedlogBoardDialog
