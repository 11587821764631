import React, { FC, useEffect, useState } from 'react'
import { createStyles, Grid, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { TrainExportConfigurationDto } from '../../../api'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Mode } from '../../../enums/Mode'
import ConardTextField from '../../../components/ConardTextField'
import { makeStyles } from '@material-ui/core/styles'
import ConardButton from '../../../components/ConardButton'
import { Alert } from '@material-ui/lab'
import ConardSwitch from '../../../components/ConardSwitch'
import { FieldName } from 'react-hook-form/dist/types/fields'
import trainExportConfigurationService from '../../../services/TrainExportConfigurationService'
import { cleanObject } from '../../../utils/utils'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const useStyles = makeStyles(() =>
  createStyles({
    typography: {
      width: '60vw',
    },
    button: {
      width: '15vw',
      minWidth: '200px',
      textAlign: 'center',
      margin: 'auto',
    },
    container: {
      marginTop: '10vh',
      width: '50vw',
      minWidth: '300px',
      margin: 'auto',
    },
    alert: {
      margin: 'auto',
      width: '50vw',
      marginTop: '20vh',
    },
  })
)

interface TrainExportConfigFormProps {
  mode: Mode
  exportConfig?: TrainExportConfigurationDto
}

const TrainExportConfigurationEditForm: FC<TrainExportConfigFormProps> = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  const { exportConfig, mode } = props

  const [extraordinaryShipment, setExtraordinaryShipment] = useState<boolean>(
    exportConfig?.extraordinaryShipment ?? false
  )

  const changeSwitch = (field: FieldName<TrainExportConfigurationDto>, newValue: boolean) => {
    control.setValue(field, newValue)
    if (field === 'extraordinaryShipment') {
      setExtraordinaryShipment(newValue)
    }
  }

  const yupSchema = yup.object().shape({
    configurationName: yup.string().required(t('pages.trainExportConfig.validation.configurationName')),
  })

  const { handleSubmit, control, errors } = useForm<TrainExportConfigurationDto>({
    resolver: yupResolver(yupSchema),
  })

  const save = (data: TrainExportConfigurationDto) => {
    if (mode === Mode.Create) {
      trainExportConfigurationService
        .create(cleanObject(data))
        .then(() => {
          history.push('/export-config/train')
        })
        .catch()
    } else {
      trainExportConfigurationService
        .update(exportConfig?.id || -1, cleanObject(data))
        .then(() => {
          history.push('/export-config/train')
        })
        .catch()
    }
  }

  useEffect(() => {
    control.setValue('extraordinaryShipment', exportConfig?.extraordinaryShipment ?? false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Typography className={classes.typography} variant="h4" color="primary">
        {mode === Mode.Create ? t('pages.trainExportConfig.create.title') : t('pages.trainExportConfig.update.title')}
      </Typography>

      {mode === Mode.Update && exportConfig === undefined && (
        <Alert severity="error" className={classes.alert}>
          {t('be.error.entity.notFound')}
        </Alert>
      )}

      {(mode === Mode.Create || exportConfig !== undefined) && (
        <form onSubmit={handleSubmit(save)}>
          <Grid
            container
            className={classes.container}
            direction="row"
            alignItems="flex-start"
            justifyContent="center"
            spacing={4}
          >
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="configurationName"
                name="configurationName"
                type="text"
                label={t('pages.trainExportConfig.form.name')}
                defaultValue={exportConfig?.configurationName ?? ''}
                fullWidth
                error={!!errors.configurationName}
                helperText={errors.configurationName ? errors.configurationName.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography variant="h4" color="primary" align="center">
                CIM train file
              </Typography>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="sender"
                name="sender"
                type="text"
                multiline
                maxRows={4}
                label={t('pages.trainExportConfig.form.sender')}
                defaultValue={exportConfig?.sender ?? ''}
                fullWidth
                error={!!errors.sender}
                helperText={errors.sender ? errors.sender.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="senderCustomerCode"
                name="senderCustomerCode"
                type="text"
                label={t('pages.trainExportConfig.form.senderCustomerCode')}
                defaultValue={exportConfig?.senderCustomerCode ?? ''}
                fullWidth
                error={!!errors.senderCustomerCode}
                helperText={errors.senderCustomerCode ? errors.senderCustomerCode.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="senderCode"
                name="senderCode"
                type="text"
                label={t('pages.trainExportConfig.form.senderCode')}
                defaultValue={exportConfig?.senderCode ?? ''}
                fullWidth
                error={!!errors.senderCode}
                helperText={errors.senderCode ? errors.senderCode.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="recipient"
                name="recipient"
                type="text"
                multiline
                maxRows={4}
                label={t('pages.trainExportConfig.form.recipient')}
                defaultValue={exportConfig?.recipient ?? ''}
                fullWidth
                error={!!errors.recipient}
                helperText={errors.recipient ? errors.recipient.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="recipientCustomerCode"
                name="recipientCustomerCode"
                type="text"
                label={t('pages.trainExportConfig.form.recipientCustomerCode')}
                defaultValue={exportConfig?.recipientCustomerCode ?? ''}
                fullWidth
                error={!!errors.recipientCustomerCode}
                helperText={errors.recipientCustomerCode ? errors.recipientCustomerCode.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="recipientCode"
                name="recipientCode"
                type="text"
                label={t('pages.trainExportConfig.form.recipientCode')}
                defaultValue={exportConfig?.recipientCode ?? ''}
                fullWidth
                error={!!errors.recipientCode}
                helperText={errors.recipientCode ? errors.recipientCode.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="senderStatement"
                name="senderStatement"
                type="text"
                multiline
                maxRows={5}
                label={t('pages.trainExportConfig.form.senderStatement')}
                defaultValue={exportConfig?.senderStatement ?? ''}
                fullWidth
                error={!!errors.senderStatement}
                helperText={errors.senderStatement ? errors.senderStatement.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="recipientReference"
                name="recipientReference"
                type="text"
                label={t('pages.trainExportConfig.form.recipientReference')}
                defaultValue={exportConfig?.recipientReference ?? ''}
                fullWidth
                error={!!errors.recipientReference}
                helperText={errors.recipientReference ? errors.recipientReference.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="attachments"
                name="attachments"
                type="text"
                multiline
                maxRows={5}
                label={t('pages.trainExportConfig.form.attachments')}
                defaultValue={exportConfig?.attachments ?? ''}
                fullWidth
                error={!!errors.attachments}
                helperText={errors.attachments ? errors.attachments.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="deliveryPlaceStation"
                name="deliveryPlaceStation"
                type="text"
                label={t('pages.trainExportConfig.form.deliveryPlaceStation')}
                defaultValue={exportConfig?.deliveryPlaceStation ?? ''}
                fullWidth
                error={!!errors.deliveryPlaceStation}
                helperText={errors.deliveryPlaceStation ? errors.deliveryPlaceStation.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="deliveryPlaceCountry"
                name="deliveryPlaceCountry"
                type="text"
                label={t('pages.trainExportConfig.form.deliveryPlaceCountry')}
                defaultValue={exportConfig?.deliveryPlaceCountry ?? ''}
                fullWidth
                error={!!errors.deliveryPlaceCountry}
                helperText={errors.deliveryPlaceCountry ? errors.deliveryPlaceCountry.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="deliveryPlaceCode"
                name="deliveryPlaceCode"
                type="text"
                label={t('pages.trainExportConfig.form.deliveryPlaceCode')}
                defaultValue={exportConfig?.deliveryPlaceCode ?? ''}
                fullWidth
                error={!!errors.deliveryPlaceCode}
                helperText={errors.deliveryPlaceCode ? errors.deliveryPlaceCode.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="stationCode"
                name="stationCode"
                type="text"
                label={t('pages.trainExportConfig.form.stationCode')}
                defaultValue={exportConfig?.stationCode ?? ''}
                fullWidth
                error={!!errors.stationCode}
                helperText={errors.stationCode ? errors.stationCode.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="termsAndConditions"
                name="termsAndConditions"
                type="text"
                multiline
                maxRows={5}
                label={t('pages.trainExportConfig.form.termsAndConditions')}
                defaultValue={exportConfig?.termsAndConditions ?? ''}
                fullWidth
                error={!!errors.termsAndConditions}
                helperText={errors.termsAndConditions ? errors.termsAndConditions.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="customerAgreement"
                name="customerAgreement"
                type="text"
                label={t('pages.trainExportConfig.form.customerAgreement')}
                defaultValue={exportConfig?.customerAgreement ?? ''}
                fullWidth
                error={!!errors.customerAgreement}
                helperText={errors.customerAgreement ? errors.customerAgreement.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="records"
                name="records"
                type="text"
                label={t('pages.trainExportConfig.form.records')}
                defaultValue={exportConfig?.records ?? ''}
                fullWidth
                error={!!errors.records}
                helperText={errors.records ? errors.records.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="takeoverPlace"
                name="takeoverPlace"
                type="text"
                label={t('pages.trainExportConfig.form.takeoverPlace')}
                defaultValue={exportConfig?.takeoverPlace ?? ''}
                fullWidth
                error={!!errors.takeoverPlace}
                helperText={errors.takeoverPlace ? errors.takeoverPlace.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="takeoverPlaceCode"
                name="takeoverPlaceCode"
                type="text"
                label={t('pages.trainExportConfig.form.takeoverPlaceCode')}
                defaultValue={exportConfig?.takeoverPlaceCode ?? ''}
                fullWidth
                error={!!errors.takeoverPlaceCode}
                helperText={errors.takeoverPlaceCode ? errors.takeoverPlaceCode.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={12} md={12} sm={12} xs={12}>
              <ConardSwitch
                id="extraordinaryShipment"
                name="extraordinaryShipment"
                checked={extraordinaryShipment}
                onChange={(newValue) => changeSwitch('extraordinaryShipment', newValue)}
                control={control}
                checkedLabel={t('pages.trainExportConfig.form.extraordinaryShipment.checked')}
                uncheckedLabel={t('pages.trainExportConfig.form.extraordinaryShipment.unchecked')}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="nhmCode"
                name="nhmCode"
                type="text"
                label={t('pages.trainExportConfig.form.nhmCode')}
                defaultValue={exportConfig?.nhmCode ?? ''}
                fullWidth
                error={!!errors.nhmCode}
                helperText={errors.nhmCode ? errors.nhmCode.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="weight"
                name="weight"
                type="text"
                label={t('pages.trainExportConfig.form.weight')}
                defaultValue={exportConfig?.weight ?? ''}
                fullWidth
                error={!!errors.weight}
                helperText={errors.weight ? errors.weight.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="placeOfIssuance"
                name="placeOfIssuance"
                type="text"
                label={t('pages.trainExportConfig.form.placeOfIssuance')}
                defaultValue={exportConfig?.placeOfIssuance ?? ''}
                fullWidth
                error={!!errors.placeOfIssuance}
                helperText={errors.placeOfIssuance ? errors.placeOfIssuance.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography variant="h4" color="primary" align="center">
                Wagon report
              </Typography>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="wrSenderName"
                name="wrSenderName"
                type="text"
                label={t('pages.trainExportConfig.form.wrSenderName')}
                defaultValue={exportConfig?.wrSenderName ?? ''}
                fullWidth
                error={!!errors.wrSenderName}
                helperText={errors.wrSenderName ? errors.wrSenderName.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="wrSenderStreet"
                name="wrSenderStreet"
                type="text"
                label={t('pages.trainExportConfig.form.wrSenderStreet')}
                defaultValue={exportConfig?.wrSenderStreet ?? ''}
                fullWidth
                error={!!errors.wrSenderStreet}
                helperText={errors.wrSenderStreet ? errors.wrSenderStreet.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="wrSenderTown"
                name="wrSenderTown"
                type="text"
                label={t('pages.trainExportConfig.form.wrSenderTown')}
                defaultValue={exportConfig?.wrSenderTown ?? ''}
                fullWidth
                error={!!errors.wrSenderTown}
                helperText={errors.wrSenderTown ? errors.wrSenderTown.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="wrPostNumber"
                name="wrPostNumber"
                type="text"
                label={t('pages.trainExportConfig.form.wrPostNumber')}
                defaultValue={exportConfig?.wrPostNumber ?? ''}
                fullWidth
                error={!!errors.wrPostNumber}
                helperText={errors.wrPostNumber ? errors.wrPostNumber.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="wrSenderDic"
                name="wrSenderDic"
                type="text"
                label={t('pages.trainExportConfig.form.wrSenderDic')}
                defaultValue={exportConfig?.wrSenderDic ?? ''}
                fullWidth
                error={!!errors.wrSenderDic}
                helperText={errors.wrSenderDic ? errors.wrSenderDic.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="wrSenderTel"
                name="wrSenderTel"
                type="text"
                label={t('pages.trainExportConfig.form.wrSenderTel')}
                defaultValue={exportConfig?.wrSenderTel ?? ''}
                fullWidth
                error={!!errors.wrSenderTel}
                helperText={errors.wrSenderTel ? errors.wrSenderTel.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="wrSenderFax"
                name="wrSenderFax"
                type="text"
                label={t('pages.trainExportConfig.form.wrSenderFax')}
                defaultValue={exportConfig?.wrSenderFax ?? ''}
                fullWidth
                error={!!errors.wrSenderFax}
                helperText={errors.wrSenderFax ? errors.wrSenderFax.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="wrCountry"
                name="wrCountry"
                type="text"
                label={t('pages.trainExportConfig.form.wrCountry')}
                defaultValue={exportConfig?.wrCountry ?? ''}
                fullWidth
                error={!!errors.wrCountry}
                helperText={errors.wrCountry ? errors.wrCountry.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="wrStation"
                name="wrStation"
                type="text"
                label={t('pages.trainExportConfig.form.wrStation')}
                defaultValue={exportConfig?.wrStation ?? ''}
                fullWidth
                error={!!errors.wrStation}
                helperText={errors.wrStation ? errors.wrStation.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="wrCompany"
                name="wrCompany"
                type="text"
                label={t('pages.trainExportConfig.form.wrCompany')}
                defaultValue={exportConfig?.wrCompany ?? ''}
                fullWidth
                error={!!errors.wrCompany}
                helperText={errors.wrCompany ? errors.wrCompany.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="wrSerialNumber"
                name="wrSerialNumber"
                type="text"
                label={t('pages.trainExportConfig.form.wrSerialNumber')}
                defaultValue={exportConfig?.wrSerialNumber ?? ''}
                fullWidth
                error={!!errors.wrSerialNumber}
                helperText={errors.wrSerialNumber ? errors.wrSerialNumber.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="wrContract"
                name="wrContract"
                type="text"
                label={t('pages.trainExportConfig.form.wrContract')}
                defaultValue={exportConfig?.wrContract ?? ''}
                fullWidth
                error={!!errors.wrContract}
                helperText={errors.wrContract ? errors.wrContract.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="wrDepot"
                name="wrDepot"
                type="text"
                label={t('pages.trainExportConfig.form.wrDepot')}
                defaultValue={exportConfig?.wrDepot ?? ''}
                fullWidth
                error={!!errors.wrDepot}
                helperText={errors.wrDepot ? errors.wrDepot.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="wrDestination"
                name="wrDestination"
                type="text"
                label={t('pages.trainExportConfig.form.wrDestination')}
                defaultValue={exportConfig?.wrDestination ?? ''}
                fullWidth
                error={!!errors.wrDestination}
                helperText={errors.wrDestination ? errors.wrDestination.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="wrRoute"
                name="wrRoute"
                type="text"
                label={t('pages.trainExportConfig.form.wrRoute')}
                defaultValue={exportConfig?.wrRoute ?? ''}
                fullWidth
                error={!!errors.wrRoute}
                helperText={errors.wrRoute ? errors.wrRoute.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <ConardTextField
                id="wrReceiver"
                name="wrReceiver"
                type="text"
                label={t('pages.trainExportConfig.form.wrReceiver')}
                defaultValue={exportConfig?.wrReceiver ?? ''}
                fullWidth
                error={!!errors.wrReceiver}
                helperText={errors.wrReceiver ? errors.wrReceiver.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
              <ConardTextField
                id="wrPayer"
                name="wrPayer"
                type="text"
                label={t('pages.trainExportConfig.form.wrPayer')}
                defaultValue={exportConfig?.wrPayer ?? ''}
                fullWidth
                error={!!errors.wrPayer}
                helperText={errors.wrPayer ? errors.wrPayer.message : ' '}
                control={control}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <div className={classes.button}>
                <ConardButton conardVariant="dark" type="submit">
                  {t('any.buttons.save')}
                </ConardButton>
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <div className={classes.button}>
                <ConardButton conardVariant="dark" onClick={() => history.goBack()}>
                  {t('any.buttons.back')}
                </ConardButton>
              </div>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  )
}

export default TrainExportConfigurationEditForm
