import React, { FC } from 'react'
import { Person, Link, Inventory2Outlined } from '@mui/icons-material'
import { createStyles, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ContainerIcon } from '../../assets/icons/ContainerIcon'
import { useTranslation } from 'react-i18next'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapperBox: {
      backgroundColor: 'white',
      borderRadius: 12,
      boxShadow: '0 2px 10px lightgray',
      padding: '1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    userBox: {
      width: '70px',
      minHeight: '61px',
    },
    gateInBox: {
      width: '300px',
    },
    gateOutBox: {
      width: '170px',
    },
    gateInOutLabel: {
      display: 'flex',
      justifyContent: 'center',
      fontSize: 24,
      fontWeight: 650,
      marginBottom: '20px',
      color: theme.palette.primary.main,
      transition: 'color 0.2s, opacity 0.2s',
    },
    gateInOutLabelInBox: {
      marginBottom: '-44px',
      fontSize: 20,
    },
    hidden: {
      opacity: 0,
    },
    icon: {
      transition: 'color 0.2s, opacity 0.2s',
    },
    cargoIcon: {
      scale: 2,
      margin: '0 15px',
    },
    activeIcon: {
      color: theme.palette.primary.main,
    },
    inactiveIcon: {
      color: 'lightgray',
    },
  })
)

interface ProgressIcon {
  id: string
  icon: JSX.Element
}

interface KioskProgressPanelProps {
  currentStepIndex: number
  withoutGateIn: boolean
  withoutGateOut: boolean
  disabled: boolean
}

export const KioskProgressPanel: FC<KioskProgressPanelProps> = ({
  currentStepIndex,
  withoutGateIn,
  withoutGateOut,
  disabled,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const gateInProgressIcons: ProgressIcon[] = [
    { id: 'gateInCargo', icon: <ContainerIcon className={classes.cargoIcon} /> },
    { id: 'gateInRepleted', icon: <Inventory2Outlined fontSize="medium" /> },
    { id: 'gateInAdrRid', icon: <WarningAmberOutlinedIcon fontSize="medium" /> },
    { id: 'gateInReference', icon: <Link fontSize="medium" /> },
  ]
  const gateOutProgressIcons: ProgressIcon[] = [
    { id: 'gateOutCargo', icon: <ContainerIcon className={classes.cargoIcon} /> },
    { id: 'gateOutReference', icon: <Link fontSize="medium" /> },
  ]

  const getIconClasses = (iconIndex: number, gateIn: boolean) => {
    if (
      (gateIn && (currentStepIndex < 2 || withoutGateIn)) ||
      (!gateIn && (currentStepIndex < gateInProgressIcons.length + 3 || withoutGateOut))
    ) {
      return classes.hidden
    }

    if (currentStepIndex >= iconIndex) {
      return `${classes.icon} ${classes.activeIcon}`
    }
    return `${classes.icon} ${classes.inactiveIcon}`
  }

  const getLabelInBoxClasses = (labelIndex: number, noCargo: boolean) => {
    if (noCargo) {
      return `${classes.gateInOutLabel} ${classes.gateInOutLabelInBox}`
    }

    if (currentStepIndex < labelIndex) {
      return `${classes.gateInOutLabel} ${classes.gateInOutLabelInBox} ${classes.inactiveIcon}`
    }
    if (currentStepIndex === labelIndex) {
      return `${classes.gateInOutLabel} ${classes.gateInOutLabelInBox}`
    }
    return `${classes.gateInOutLabel} ${classes.gateInOutLabelInBox} ${classes.hidden}`
  }

  const getLabelClasses = (labelIndex: number) => {
    if (currentStepIndex < labelIndex) {
      return `${classes.gateInOutLabel} ${classes.hidden}`
    }
    return classes.gateInOutLabel
  }

  return (
    <div>
      {!disabled && (
        <Grid container direction="row" spacing={3} justifyContent="center" alignItems="flex-end">
          <Grid item>
            <div className={`${classes.wrapperBox} ${classes.userBox}`}>
              <Person className={`${classes.icon} ${classes.activeIcon}`} fontSize="medium" />
            </div>
          </Grid>

          <Grid item>
            <Typography className={getLabelClasses(2)}>{t('kioskApp.progressPanel.gateIn')}</Typography>
            <Typography className={getLabelInBoxClasses(1, currentStepIndex > 1 && withoutGateIn)}>
              {currentStepIndex > 1 && withoutGateIn
                ? t('kioskApp.progressPanel.noCargo')
                : t('kioskApp.progressPanel.gateIn')}
            </Typography>
            <div className={`${classes.wrapperBox} ${classes.gateInBox}`}>
              {gateInProgressIcons.map((icon, index) => (
                <div key={icon.id} className={getIconClasses(index + 2, true)}>
                  {icon.icon}
                </div>
              ))}
            </div>
          </Grid>

          <Grid item>
            <Typography className={getLabelClasses(gateInProgressIcons.length + 3)}>
              {t('kioskApp.progressPanel.gateOut')}
            </Typography>
            <Typography
              className={getLabelInBoxClasses(gateInProgressIcons.length + 2, currentStepIndex > 6 && withoutGateOut)}
            >
              {currentStepIndex > 6 && withoutGateOut
                ? t('kioskApp.progressPanel.noCargo')
                : t('kioskApp.progressPanel.gateOut')}
            </Typography>
            <div className={`${classes.wrapperBox} ${classes.gateOutBox}`}>
              {gateOutProgressIcons.map((icon, index) => (
                <div key={icon.id} className={getIconClasses(index + gateInProgressIcons.length + 3, false)}>
                  {icon.icon}
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  )
}
