import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import {
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { M2mTrainDto, M2mTrainSearchDto, PageM2mTrainDto } from '../../api'
import ConardButton from '../../components/ConardButton'
import { Order } from '../../enums/Order'
import { cleanObject } from '../../utils/utils'
import m2mTrainService from '../../services/M2mTrainService'
import { format } from 'date-fns'
import TrainM2mCompareDialog from './TrainM2mCompareDialog'
import ConardProgressBar from '../../components/ConardProgressBar'

const useStyles = makeStyles({
  gridContainer: {
    marginTop: '8vh',
  },
  detailButton: {
    width: '7vw',
  },
})

interface HeadCell {
  id: keyof M2mTrainDto
  label: string
}

const TrainM2mListPage: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [pageTrains, setPageTrains] = useState<PageM2mTrainDto | null>()
  const [m2mTrainId, setM2mTrainId] = useState<number>(-1)
  const [searchDto, setSearchDto] = useState<M2mTrainSearchDto | undefined>()
  const [showCompareDialog, setShowCompareDialog] = useState(false)

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(50)
  const [order, setOrder] = useState<Order>(Order.Desc)
  const [orderBy, setOrderBy] = useState<keyof M2mTrainDto>('createdAt')

  const headCells: HeadCell[] = [{ id: 'createdAt', label: t('pages.m2m.createdAt') }]

  const handleSort = (property: keyof M2mTrainDto) => () => {
    const isAsc = orderBy === property && order === Order.Asc
    setOrder(isAsc ? Order.Desc : Order.Asc)
    setOrderBy(property)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value))
    setPage(0)
  }

  const search = (data: M2mTrainSearchDto | undefined, fromFilter: boolean, isMounted: boolean) => {
    setSearchDto(data)

    let currentSort: Array<string> | undefined = undefined
    if (orderBy !== undefined) {
      const currentOrder: string = order !== undefined ? order : 'asc'
      currentSort = [orderBy + ',' + currentOrder]
    }

    m2mTrainService
      .search(page, pageSize, currentSort, cleanObject(data))
      .then((response) => {
        if (isMounted) {
          setPageTrains(response.data)
          if (fromFilter) {
            setPage(0)
          }
        }
      })
      .catch()
  }

  const handleCompare = (trainId: number | undefined) => {
    if (trainId) {
      setM2mTrainId(trainId)
      setShowCompareDialog(true)
    }
  }

  useEffect(() => {
    let isMounted = true
    search(searchDto, false, isMounted)

    return () => {
      isMounted = false // Set isMounted to false when the component unmounts
    }
  }, [page, pageSize, orderBy, order]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Typography variant="h4" color="primary">
        {t('pages.train.dashboard.m2m')}
      </Typography>
      <Grid container className={classes.gridContainer} direction="row" alignItems="center" spacing={3}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper variant="outlined">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={handleSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pageTrains?.content?.map((train) => (
                    <TableRow key={train.id}>
                      <TableCell component="th" scope="row">
                        {format(new Date(train.createdAt ?? new Date()), 'dd.MM.yyyy HH:mm')}
                      </TableCell>
                      <TableCell className={classes.detailButton}>
                        <ConardButton conardVariant="light" onClick={() => handleCompare(train.id)}>
                          {t('any.buttons.compare')}
                        </ConardButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  {pageTrains?.content && pageTrains.totalElements === 0 && (
                    <TableRow key="noData">
                      <TableCell component="th" scope="row" colSpan={7}>
                        {t('pages.common.table.noData')}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell colSpan={7}>
                      <ConardProgressBar showBar={!pageTrains} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 150]}
              component="div"
              count={pageTrains?.totalElements ?? 0}
              rowsPerPage={pageSize}
              labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
      <TrainM2mCompareDialog
        showCompareDialog={showCompareDialog}
        setShowCompareDialog={setShowCompareDialog}
        m2mTrainId={m2mTrainId}
      />
    </>
  )
}

export default TrainM2mListPage
