import { AxiosPromise } from 'axios'
import { ContainerDtoContainerTypeEnum, GateInApi, TransitionDto, TransitionStoreDto } from '../api'
import myAxios from '../config/axios/axios'
import { cleanObject } from '../utils/utils'

let api: GateInApi

export const setGateInApiConfiguration = () => {
  api = new GateInApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const gateInService = {
  createSimple: (transitionDto: TransitionDto): AxiosPromise<TransitionDto> => {
    return api.gateInCreateSimpleGateIn(cleanObject(transitionDto))
  },

  storeContainer: (transitionId: number, transitionStoreDto: TransitionStoreDto): AxiosPromise<TransitionDto> => {
    return api.gateInStore(transitionId, cleanObject(transitionStoreDto))
  },

  iluCodesForAutocomplete: (iluCode: string, containerType: ContainerDtoContainerTypeEnum): AxiosPromise<string[]> => {
    return api.gateInGetIluCodesForAutocomplete(iluCode, containerType)
  },

  downloadDamageLog: (transitionId: number | undefined): AxiosPromise => {
    return api.gateInDownloadDamageLog(transitionId || -1, { responseType: 'blob' })
  },

  importMedlogBoard: (file: File) => {
    return api.gateInUploadMedlogBoard(file)
  },

  createByDriver: (driverArrivalId: number, transition: TransitionDto): AxiosPromise<TransitionDto> => {
    return api.gateInCreateByDriver(driverArrivalId, transition)
  },

  getPlannedTransitionForDriver: (driverArrivalId: number, containerId: number): AxiosPromise<TransitionDto> => {
    return api.gateInGetPlannedTransitionForContainerId(driverArrivalId, containerId)
  },
}

export default gateInService
