import React, { useEffect } from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import DoneIcon from '@material-ui/icons/Done'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  body: {
    maxWidth: '800px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '400px',
    marginTop: '150px',
    textAlign: 'center',
  },
  typography: {
    color: theme.palette.primary.main,
    fontSize: '28px',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    scale: 10,
    margin: '100px',
    color: theme.palette.primary.main,
  },
  redirection: {
    margin: '20px',
  },
}))

export const KioskSuccessPage = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      history.push('/')
    }, 4000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [history])

  return (
    <Box className={classes.body}>
      <Typography className={classes.typography}>{t('kioskApp.success')}</Typography>
      <DoneIcon className={classes.icon} />
      <Typography className={classes.typography}>{t('kioskApp.thanks')}</Typography>
      <Typography className={classes.redirection}>{t('kioskApp.redirection')}</Typography>
    </Box>
  )
}
