import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ContainerDtoContainerTypeEnum,
  DamageLogEntryDto,
  TransitionDto,
  TransitionDtoQualityTypeEnum,
  TransitionDtoReceiptMethodEnum,
} from '../../api'

export interface TransitionState {
  transition: TransitionDto
  transitionFormDisabled: boolean
  transitionPositionDisabled: boolean
  transitionSaveDisabled: boolean
  comingFromDamageLog: boolean
}
export const transitionDefaults = {
  semitrailer: {
    hasSignet: true,
    damaged: false,
    adrRid: false,
    agency: false,
    repleted: true,
    receiptMethod: TransitionDtoReceiptMethodEnum.Road,
    weighted: false,
    bookingReference: '',
    goods: '',
    nhmCodes: [],
    note: '',
    wagonNumber: '',
    carrier: '',
    driver: '',
    truckLicencePlate: '',
    licencePlateOfSemitrailer: '',
    importExport: undefined,
    damageLogEntries: undefined,
    transitionDate: new Date().toISOString(),
    container: {
      iluCode: '',
      semitrailerLicencePlate: '',
      containerType: ContainerDtoContainerTypeEnum.Semitrailer,
      teu: 1,
      heavyTested: false,
      innerWidth: false,
      lashingRings: false,
      woodenFloor: false,
      flexiTanks: false,
    },
    manipulation: 0,
    transitionMrns: [],
    adrRidCodes: [],
  },
  shippingContainer: {
    hasSignet: true,
    damaged: false,
    adrRid: false,
    agency: false,
    repleted: true,
    receiptMethod: TransitionDtoReceiptMethodEnum.Road,
    weighted: false,
    bookingReference: '',
    goods: '',
    nhmCodes: [],
    note: '',
    wagonNumber: '',
    carrier: '',
    driver: '',
    truckLicencePlate: '',
    licencePlateOfSemitrailer: '',
    importExport: undefined,
    damageLogEntries: undefined,
    transitionDate: new Date().toISOString(),
    qualityType: TransitionDtoQualityTypeEnum.Standard,
    container: {
      iluCode: '',
      containerType: ContainerDtoContainerTypeEnum.ShippingContainer,
      teu: 1,
      heavyTested: false,
      innerWidth: false,
      lashingRings: false,
      woodenFloor: false,
      flexiTanks: false,
    },
    manipulation: 0,
    transitionMrns: [],
    adrRidCodes: [],
    transitionAdditionalInfo: {
      medlogReference: '',
    },
  },
}
export const transitionInitialState: TransitionState = {
  transition: transitionDefaults.shippingContainer,
  transitionFormDisabled: false,
  transitionPositionDisabled: false,
  transitionSaveDisabled: false,
  comingFromDamageLog: false,
}

export const transitionSlice = createSlice({
  name: 'transition',
  initialState: transitionInitialState,
  reducers: {
    setTransition(state: TransitionState, action: PayloadAction<TransitionDto>) {
      state.transition = action.payload
    },
    addDamageLogEntry(state: TransitionState, action: PayloadAction<DamageLogEntryDto>) {
      state.transition = {
        ...state.transition,
        damageLogEntries: [...(state.transition.damageLogEntries ?? []), action.payload],
      }
    },
    addIluCode(state: TransitionState, action: PayloadAction<string>) {
      state.transition = {
        ...state.transition,
        container: {
          ...state.transition?.container,
          iluCode: action.payload,
        },
      }
    },
    setTransitionFormDisabled(state: TransitionState, action: PayloadAction<boolean>) {
      state.transitionFormDisabled = action.payload
    },
    setComingFromDamageLog(state: TransitionState, action: PayloadAction<boolean>) {
      state.comingFromDamageLog = action.payload
    },
    setTransitionPositionDisabled(state: TransitionState, action: PayloadAction<boolean>) {
      state.transitionPositionDisabled = action.payload
    },
    setTransitionSaveDisabled(state: TransitionState, action: PayloadAction<boolean>) {
      state.transitionSaveDisabled = action.payload
    },
  },
})

export const {
  setTransition,
  addDamageLogEntry,
  addIluCode,
  setTransitionFormDisabled,
  setTransitionPositionDisabled,
  setTransitionSaveDisabled,
  setComingFromDamageLog,
} = transitionSlice.actions
