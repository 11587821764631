import React, { FC, useEffect } from 'react'
import { createStyles, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { NhmField } from '../../drivers/transition-form/NhmField'
import { yupResolver } from '@hookform/resolvers/yup'
import { KioskButtons } from '../KioskButtons'
import { RHFConardToggle } from '../../../components/shared/rhf/RHFConardToggle'
import { RHFTextField } from '../../../components/shared/rhf/RHFTextField'
import transitionService from '../../../services/TransitionService'
import { useFormInitialValues } from '../../../hooks/useFormInitialValues'
import { NhmCodeDto, TransitionDto } from '../../../api'
import { useActualDriverArival } from '../shared/useActualDriverArival'
import { RepletedValidationScheme } from './ValidationScheme'
import { KioskLabel } from '../shared/KioskLabel'

const useStyles = makeStyles((theme) =>
  createStyles({
    label: {
      fontSize: 28,
      fontWeight: 650,
      color: theme.palette.primary.main,
      textAlign: 'center',
    },
    whiteDiv: {
      backgroundColor: 'white',
      borderRadius: 12,
      boxShadow: '0 2px 10px gray',
      padding: '1.5rem',
    },
    container: {
      maxWidth: '800px',
      margin: 'auto',
      marginTop: '80px',
      minHeight: '400px',
    },
    typography: {
      marginTop: '30px',
    },
  })
)

type KioskRepletedFormValues = {
  repleted: boolean
  damaged: boolean
  signetValue: string
  netWeight: number | undefined
  nhmCodes: NhmCodeDto[]
}

const initialValues: KioskRepletedFormValues = {
  repleted: false,
  damaged: false,
  signetValue: '',
  netWeight: undefined,
  nhmCodes: [],
}

interface KioskRepletedProps {
  idDriverArrival: number
  previous: () => void
  next: () => void
}

export const KioskRepletedForm: FC<KioskRepletedProps> = ({ previous, idDriverArrival, next }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const formMethods = useForm<KioskRepletedFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(RepletedValidationScheme()),
  })
  const { control, errors, handleSubmit } = formMethods
  const { driverArrival, loading } = useActualDriverArival(idDriverArrival)

  const isRepleted = useWatch({
    name: 'repleted',
    control: control,
  })

  const onSubmit = async (formValues: KioskRepletedFormValues) => {
    if (driverArrival?.plannedTransition) {
      try {
        const updatedTransition: TransitionDto = {
          ...driverArrival.plannedTransition,
          damaged: formValues.damaged,
          repleted: formValues.repleted,
          hasSignet: isRepleted ? true : false,
          signetValue: formValues.signetValue,
          netWeight: formValues.netWeight,
          nhmCodes: formValues.nhmCodes,
        }
        await transitionService.update(driverArrival.plannedTransition.id ?? 0, updatedTransition)
        next()
      } catch (err) {
        console.error(err)
      }
    }
  }

  useEffect(() => {
    formMethods.control.setValue('nhmCodes', driverArrival?.plannedTransition?.nhmCodes)
    // eslint-disable-next-line react-hooks/exhaustive-deps -- formMethods is not there intentionally (cyclic server calls)
  }, [driverArrival?.plannedTransition?.nhmCodes])

  useFormInitialValues(mapValues(driverArrival?.plannedTransition), formMethods.reset)

  return (
    <>
      {!loading && (
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction="column" spacing={3} className={classes.container}>
              <Grid item>
                <Typography className={classes.label}>{t('kioskApp.step3.labelDamaged')}</Typography>
              </Grid>

              <Grid item>
                <RHFConardToggle
                  name="damaged"
                  option1name={t('kioskApp.step3.damaged')}
                  option2name={t('kioskApp.step3.notDamaged')}
                />
              </Grid>

              <Grid item className={classes.typography}>
                <Typography className={classes.label}>{t('kioskApp.step3.labelRepleted')}</Typography>
              </Grid>

              <Grid item>
                <RHFConardToggle
                  name="repleted"
                  option1name={t('kioskApp.step3.repleted')}
                  option2name={t('kioskApp.step3.notRepleted')}
                />
              </Grid>

              {isRepleted && (
                <Grid item>
                  <div className={classes.whiteDiv}>
                    <Grid container direction="column" spacing={2}>
                      <Grid item>
                        <KioskLabel>{t('kioskApp.step3.nhmCodes')}</KioskLabel>
                        <NhmField isDisabled={false} initialCodes={driverArrival?.plannedTransition?.nhmCodes} />
                      </Grid>

                      <Grid item>
                        <KioskLabel>{t('kioskApp.step3.signet')}</KioskLabel>
                        <RHFTextField label="" name="signetValue" error={!!errors.signetValue} />
                      </Grid>

                      <Grid item>
                        <KioskLabel>{t('kioskApp.step3.netWeight')}</KioskLabel>
                        <RHFTextField
                          label=""
                          name="netWeight"
                          error={!!errors.netWeight}
                          type="number"
                          adornment="kg"
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              )}
            </Grid>
            <KioskButtons visibleBack visibleNext onClickBack={() => previous()} />
          </form>
        </FormProvider>
      )}
    </>
  )
}

const mapValues = (entity: Partial<TransitionDto> | undefined): KioskRepletedFormValues | undefined => {
  if (!entity) return undefined

  return {
    netWeight: entity.netWeight ?? undefined,
    nhmCodes: entity.nhmCodes ?? [],
    repleted: entity.repleted ?? false,
    damaged: entity.damaged ?? false,
    signetValue: entity.signetValue ?? '',
  }
}
