import React, { ChangeEvent, FC, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { CoparnService } from '../../services/CoparnService'
import {
  CoparnContainerDto,
  CoparnContainerDtoStatusEnum,
  PageCoparnContainerDto,
  PageTrainDto,
  TrainDto,
  TrainSearchDto,
  TrainSearchDtoRequiredTrainStatesEnum,
  TransitionDto,
  UserDtoRoleEnum,
} from '../../api'
import {
  Checkbox,
  createStyles,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { AvailableContainerSelectionModal } from '../../components/modals/AvailableContainerSelectionModal'
import ConardButton from '../../components/ConardButton'
import { setTrain } from '../../redux/train/trainSlice'
import { useAppDispatch } from '../../redux/store'
import { setTransition } from '../../redux/transition/transitionSlice'
import * as queryString from 'querystring'
import trainService from '../../services/TrainService'
import { cleanObject } from '../../utils/utils'
import { ConfigContext } from '../../context/ConfigContext'
import { useConardAuth } from '../../hooks/useConardAuth'
import trainGateOutService from '../../services/TrainGateOutService'
import ConardProgressBar from '../../components/ConardProgressBar'

const useStyles = makeStyles((theme) =>
  createStyles({
    gridContainer: {
      marginTop: '8vh',
      marginBottom: '10vh',
    },
    iconButton: {
      padding: theme.spacing(1),
    },
    noData: {
      textAlign: 'center',
    },
    controls: {
      [theme.breakpoints.up('sm')]: {
        left: '12vw',
      },
      [theme.breakpoints.down('xs')]: {
        left: '0px',
      },
      position: 'fixed',
      bottom: 0,
      width: '100vw',
      backgroundColor: theme.palette.secondary.main,
      zIndex: 100,
    },
    submitButton: {
      width: '16vw',
      margin: 24,
    },
    addContainerColumn: {
      width: '500px',
    },
    buttonGrid: {
      padding: theme.spacing(0.5),
    },
    disabledRow: {
      backgroundColor: theme.palette.action.disabledBackground,
    },
    rootSelect: {
      width: '100%',
      minWidth: '150px',
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(34px, -6px) scale(0.75)',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        '& legend': {
          marginLeft: '20px',
        },
      },
    },
    inputSelect: {
      borderRadius: 30,
      borderColor: theme.palette.secondary.light,
      backgroundColor: theme.palette.secondary.main,
    },
    selectSelect: {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    tableCell: {
      [theme.breakpoints.down('lg')]: {
        paddingLeft: 5,
        paddingRight: 5,
      },
    },
  })
)

interface UrlParamTypes {
  id?: string
}

export const GateOutCoparnDetailPage: FC = () => {
  const { id } = useParams<UrlParamTypes>()
  const history = useHistory()
  const auth = useConardAuth()
  const { search } = useLocation()
  const medlogRef = queryString.parse(search)['?medlogRef']
  const [coparnContainer, setCoparnContainer] = useState<PageCoparnContainerDto>()
  const [selectedCoparnContainer, setSelectedCoparnContainer] = useState<CoparnContainerDto>()
  const [selectedCoparnContainersForTrain, setSelectedCoparnContainersForTrain] = useState<CoparnContainerDto[]>([])
  const [selectedTrain, setSelectedTrain] = useState<TrainDto | undefined>(undefined)
  const [trainPage, setTrainPage] = useState<PageTrainDto | null>()
  const [page, setPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(150)
  const [open, setOpen] = useState<boolean>(false)
  const [checkAll, setCheckAll] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const { generalCarrierReference } = useContext(ConfigContext)
  const { t } = useTranslation()
  const classes = useStyles()

  const openDialog = (coparnContainerDto: CoparnContainerDto) => {
    setSelectedCoparnContainer(coparnContainerDto)
    setOpen(true)
  }
  const closeDialog = () => {
    setOpen(false)
  }

  const addContainer = (transitionDto: TransitionDto, coparnContainerId: number) => {
    modifyContainer(transitionDto, coparnContainerId)
  }

  const removeContainer = (coparnContainerId: number) => {
    modifyContainer(undefined, coparnContainerId)
  }

  const modifyContainer = (transitionDto: TransitionDto | undefined, coparnContainerId: number) => {
    const newCoparnContainerList: PageCoparnContainerDto = { ...coparnContainer }
    const selectedCoparnContainerFromList = newCoparnContainerList?.content?.find((container: CoparnContainerDto) => {
      return container.id === coparnContainerId
    })
    if (selectedCoparnContainerFromList) {
      selectedCoparnContainerFromList.selectedTransition = transitionDto
      CoparnService.saveContainerForCoparn(selectedCoparnContainerFromList)
        .then(() => {
          setCoparnContainer(newCoparnContainerList)
        })
        .finally(() => {
          setOpen(false)
        })
    }
  }

  const onPage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage)
  }

  const onRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleAllChecked = () => {
    if (!checkAll) {
      setSelectedCoparnContainersForTrain(
        coparnContainer?.content?.filter(
          (container) => container.selectedTransition && container.status !== CoparnContainerDtoStatusEnum.Processed
        ) ?? []
      )
    } else {
      setSelectedCoparnContainersForTrain([])
    }
  }

  useEffect(() => {
    checkAllChecked()
  }, [selectedCoparnContainersForTrain]) // eslint-disable-line react-hooks/exhaustive-deps

  const checkAllChecked = () => {
    let allChecked = false

    if (
      selectedCoparnContainersForTrain.length != 0 &&
      (coparnContainer?.content?.filter(
        (container) => container.selectedTransition && container.status !== CoparnContainerDtoStatusEnum.Processed
      ).length ?? 0) === selectedCoparnContainersForTrain.length
    ) {
      allChecked = true
    }

    setCheckAll(allChecked)
  }

  const handleSelectedCoparnContainersForTrain = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    coparnContainerDto: CoparnContainerDto
  ) => {
    if (checked) {
      setSelectedCoparnContainersForTrain([...selectedCoparnContainersForTrain, coparnContainerDto])
    } else {
      setSelectedCoparnContainersForTrain((coparnContainers) =>
        coparnContainers.filter((coparnContainerDtoItem) => coparnContainerDtoItem.id !== coparnContainerDto.id)
      )
    }
  }

  const handleSelectedTrain = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSelectedTrain(trainPage?.content?.find((train) => train.id === +event.target.value))
  }

  const sendSemitrailer = (transition: TransitionDto) => {
    dispatch(setTransition(transition))
    history.push(`/gate-out/single-container/${transition.id}`)
  }

  const buttonWidth = useMemo(() => {
    return auth.getRole() === UserDtoRoleEnum.DispatcherTrain ? 6 : 4
  }, [auth])

  const sendTrain = () => {
    trainGateOutService
      .createCoparnTrainWithUnseatedTransitions(
        Number(id) ?? -1,
        coparnContainer?.content?.map((coparnContainer) => coparnContainer.selectedTransition?.id ?? -1) ?? []
      )
      .then((response) => {
        history.push('/gate-out/train/' + response.data.id)
      })
  }

  const sendExistingTrain = () => {
    if (selectedTrain?.name) {
      if (selectedCoparnContainersForTrain) {
        trainGateOutService
          .assignCoparnUnseatedTransitions(
            selectedTrain.id ?? -1,
            selectedCoparnContainersForTrain.map((coparnContainer) => coparnContainer.selectedTransition?.id ?? -1),
            Number(id) ?? -1
          )
          .then((response) => {
            dispatch(setTrain(response.data))
            history.push('/gate-out/train/' + selectedTrain.id)
          })
      }
    }
  }

  useEffect(() => {
    if (id) {
      CoparnService.getCoparnContainerByCoparnId(parseInt(id), page, pageSize, [
        'selectedTransition.container.iluCode',
      ]).then((response) => {
        setCoparnContainer(response.data)
      })
    }
  }, [page, pageSize]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const data: TrainSearchDto = {
      requiredTrainStates: [
        TrainSearchDtoRequiredTrainStatesEnum.OutgoingPlanned,
        TrainSearchDtoRequiredTrainStatesEnum.OutgoingPrepared,
        TrainSearchDtoRequiredTrainStatesEnum.OutgoingLoaded,
      ],
    }

    trainService.searchCoparn(0, 50, ['name,asc'], cleanObject(data)).then((response) => {
      setTrainPage(response.data)
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Typography variant="h4" color="primary">
        {t('pages.gateOut.coparn.detail.title')}
      </Typography>
      <Grid
        container
        className={classes.gridContainer}
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={3}
      >
        <Typography variant="h5" color="primary">
          {`${
            generalCarrierReference ? t('pages.gateOut.coparn.carrierRef') : t('pages.gateOut.coparn.medlogRef')
          }: ${medlogRef}`}
        </Typography>
        <Grid item xs={12}>
          <Paper variant="outlined">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCell} component="th">
                      <Checkbox checked={checkAll} onChange={() => handleAllChecked()} />
                    </TableCell>
                    <TableCell className={classes.tableCell} component="th">
                      {t('pages.gateOut.coparn.detail.containerType')}
                    </TableCell>
                    <TableCell className={classes.tableCell} component="th">
                      {t('pages.gateOut.coparn.detail.containerStatus.label')}
                    </TableCell>
                    <TableCell className={classes.tableCell} component="th">
                      {t('pages.gateOut.coparn.detail.quality')}
                    </TableCell>
                    <TableCell className={classes.tableCell} component="th">
                      {t('pages.gateOut.coparn.detail.heavyTested')}
                    </TableCell>
                    <TableCell className={classes.tableCell} component="th">
                      {t('pages.gateOut.coparn.detail.innerWidth')}
                    </TableCell>
                    <TableCell className={classes.tableCell} component="th">
                      {t('pages.gateOut.coparn.detail.lashingRings')}
                    </TableCell>
                    <TableCell className={classes.tableCell} component="th">
                      {t('pages.gateOut.coparn.detail.woodenFloor')}
                    </TableCell>
                    <TableCell className={classes.tableCell} component="th">
                      {t('pages.gateOut.coparn.detail.flexitanks')}
                    </TableCell>
                    <TableCell className={classes.tableCell} component="th">
                      {t('pages.gateOut.coparn.detail.cargoSpecification')}
                    </TableCell>
                    <TableCell className={classes.tableCell} component="th">
                      {t('pages.gateOut.coparn.detail.selectedContainer')}
                    </TableCell>
                    <TableCell className={classes.addContainerColumn} component="th"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {coparnContainer?.content?.map((coparnContainerDto) => {
                    return (
                      <TableRow
                        key={coparnContainerDto.id}
                        className={
                          coparnContainerDto.status === CoparnContainerDtoStatusEnum.Processed ||
                          coparnContainerDto.status === CoparnContainerDtoStatusEnum.Cancelled
                            ? classes.disabledRow
                            : ''
                        }
                      >
                        <TableCell className={classes.tableCell}>
                          <Checkbox
                            color="primary"
                            disabled={
                              !coparnContainerDto.selectedTransition ||
                              coparnContainerDto.status === CoparnContainerDtoStatusEnum.Processed ||
                              coparnContainerDto.status === CoparnContainerDtoStatusEnum.Cancelled
                            }
                            checked={selectedCoparnContainersForTrain.includes(coparnContainerDto)}
                            onChange={(e, checked) =>
                              handleSelectedCoparnContainersForTrain(e, checked, coparnContainerDto)
                            }
                          />
                        </TableCell>
                        <TableCell className={classes.tableCell} component="td">
                          {coparnContainerDto.containerType}
                        </TableCell>
                        <TableCell className={classes.tableCell} component="td">
                          {t(`pages.gateOut.coparn.detail.containerStatus.${coparnContainerDto.status ?? 'NEW'}`)}
                        </TableCell>
                        <TableCell className={classes.tableCell} component="td">
                          {coparnContainerDto.coparnContainerQuality}
                        </TableCell>
                        <TableCell className={classes.tableCell} component="td">
                          <Checkbox checked={!!coparnContainerDto.heavyTested} disabled />
                        </TableCell>
                        <TableCell className={classes.tableCell} component="td">
                          <Checkbox checked={!!coparnContainerDto.innerWidth} disabled />
                        </TableCell>
                        <TableCell className={classes.tableCell} component="td">
                          <Checkbox checked={!!coparnContainerDto.lashingRings} disabled />
                        </TableCell>
                        <TableCell className={classes.tableCell} component="td">
                          <Checkbox checked={!!coparnContainerDto.woodenFloor} disabled />
                        </TableCell>
                        <TableCell className={classes.tableCell} component="td">
                          <Checkbox checked={!!coparnContainerDto.flexitanks} disabled />
                        </TableCell>
                        <TableCell className={classes.tableCell} component="td">
                          {coparnContainerDto.cargoSpecification}
                        </TableCell>
                        <TableCell className={classes.tableCell} component="td">
                          {coparnContainerDto.selectedTransition?.container.iluCode}
                        </TableCell>
                        <TableCell className={classes.addContainerColumn} component="td">
                          <Grid container>
                            <Grid className={classes.buttonGrid} item md={buttonWidth}>
                              <ConardButton
                                conardVariant="dark"
                                disabled={
                                  coparnContainerDto.status === CoparnContainerDtoStatusEnum.Processed ||
                                  coparnContainerDto.status === CoparnContainerDtoStatusEnum.Cancelled
                                }
                                onClick={() => openDialog(coparnContainerDto)}
                              >
                                {t('any.buttons.assign')}
                              </ConardButton>
                            </Grid>
                            <Grid className={classes.buttonGrid} item md={buttonWidth}>
                              <ConardButton
                                conardVariant="dark"
                                disabled={
                                  !coparnContainerDto.selectedTransition ||
                                  coparnContainerDto.status === CoparnContainerDtoStatusEnum.Processed
                                }
                                onClick={() => removeContainer(coparnContainerDto.id!)}
                              >
                                {t('any.buttons.remove')}
                              </ConardButton>
                            </Grid>
                            {auth.getRole() !== UserDtoRoleEnum.DispatcherTrain && (
                              <Grid className={classes.buttonGrid} item md={buttonWidth}>
                                <ConardButton
                                  conardVariant="dark"
                                  disabled={
                                    !coparnContainerDto.selectedTransition ||
                                    coparnContainerDto.status === CoparnContainerDtoStatusEnum.Processed ||
                                    coparnContainerDto.status === CoparnContainerDtoStatusEnum.Cancelled
                                  }
                                  onClick={() => sendSemitrailer(coparnContainerDto.selectedTransition!)}
                                >
                                  {t('pages.gateOut.buttons.sendSemitrailer')}
                                </ConardButton>
                              </Grid>
                            )}
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                  {coparnContainer?.content?.length === 0 && (
                    <TableRow>
                      <TableCell className={classes.noData} colSpan={12}>
                        {t('pages.common.table.noDataAvailable')}
                      </TableCell>
                    </TableRow>
                  )}
                  {selectedCoparnContainer && (
                    <AvailableContainerSelectionModal
                      coparnContainer={selectedCoparnContainer!}
                      open={open}
                      onSave={addContainer}
                      onClose={closeDialog}
                    />
                  )}
                </TableBody>
              </Table>
              <ConardProgressBar showBar={coparnContainer === undefined} />
            </TableContainer>
            <TablePagination
              component="div"
              count={coparnContainer?.totalElements || 0}
              onPageChange={onPage}
              page={page}
              rowsPerPage={pageSize}
              labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
              rowsPerPageOptions={[10, 20, 50, 150]}
              onRowsPerPageChange={onRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
      <div className={classes.controls}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
            <div className={classes.submitButton}>
              <ConardButton conardVariant="dark" onClick={() => history.goBack()}>
                {t('any.buttons.back')}
              </ConardButton>
            </div>
          </Grid>
          {auth.getRole() !== UserDtoRoleEnum.DispatcherRoad && (
            <>
              <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                <div className={classes.submitButton}>
                  <ConardButton
                    conardVariant="dark"
                    type="button"
                    onClick={sendTrain}
                    disabled={
                      !coparnContainer?.content
                        ?.filter(
                          (coparnContainer: CoparnContainerDto) =>
                            coparnContainer.status !== CoparnContainerDtoStatusEnum.Processed
                        )
                        .every((coparnContainer) => coparnContainer.selectedTransition != null)
                    }
                  >
                    {t('pages.gateOut.buttons.sendTrain')}
                  </ConardButton>
                </div>
              </Grid>
              <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                <div className={classes.submitButton}>
                  <TextField
                    select
                    name="train"
                    defaultValue={0}
                    value={selectedTrain}
                    onChange={handleSelectedTrain}
                    variant="outlined"
                    InputProps={{
                      className: classes.inputSelect,
                    }}
                    SelectProps={{
                      classes: {
                        select: classes.selectSelect,
                      },
                    }}
                    className={classes.rootSelect}
                  >
                    <MenuItem value={0}>{t('any.notSelected')}</MenuItem>
                    {trainPage?.content?.map((train) => (
                      <MenuItem key={train.id} value={train.id}>
                        {train.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </Grid>
              <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
                <div className={classes.submitButton}>
                  <ConardButton
                    conardVariant="dark"
                    type="button"
                    onClick={sendExistingTrain}
                    disabled={!selectedTrain || selectedCoparnContainersForTrain.length === 0}
                  >
                    {t('pages.gateOut.buttons.sendExistingTrain')}
                  </ConardButton>
                </div>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </>
  )
}
