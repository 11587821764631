import React, { FC, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core'
import ConardButton from '../ConardButton'
import { TrainDto } from '../../api'
import { format } from 'date-fns'
import trainService from '../../services/TrainService'
import TrainCodecoResendSuccessDialog from './TrainCodecoResendSuccessDialog'

const useStyles = makeStyles(() =>
  createStyles({
    dialogContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
)

interface TrainResendCodecoDialogProps {
  showDialog: boolean
  setShowDialog: (showDialog: boolean) => void
  train: TrainDto
}

const TrainResendCodecoDialog: FC<TrainResendCodecoDialogProps> = ({ showDialog, setShowDialog, train }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [showSuccessDialog, setShowSuccessDialog] = useState(false)

  const handleResendCodeco = (id: number) => {
    trainService
      .sendCodecos(id)
      .then(() => {
        setShowSuccessDialog(true)
      })
      .catch()
      .finally(() => setShowDialog(false))
  }

  return (
    <>
      <Dialog open={showDialog} maxWidth="sm">
        <DialogTitle>{t('pages.gateOut.dialog.trainResendCodeco.title')}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography>
            <Trans
              i18nKey="pages.gateOut.dialog.trainResendCodeco.content"
              values={{
                date: format(new Date(train.departureDateTime ?? new Date()), 'dd.MM.yyyy HH:mm:ss'),
              }}
            />
          </Typography>
        </DialogContent>
        <DialogActions>
          <ConardButton conardVariant="dark" onClick={() => handleResendCodeco(train.id ?? -1)}>
            {t('pages.gateOut.buttons.send')}
          </ConardButton>
          <ConardButton conardVariant="transparent" onClick={() => setShowDialog(false)}>
            {t('any.buttons.cancel')}
          </ConardButton>
        </DialogActions>
      </Dialog>
      <TrainCodecoResendSuccessDialog showDialog={showSuccessDialog} setShowDialog={setShowSuccessDialog} />
    </>
  )
}

export default TrainResendCodecoDialog
