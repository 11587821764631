import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PageNotificationDto } from '../../api'

export interface NotificationState {
  showNotifications: boolean
  notifications: PageNotificationDto | undefined
  notificationsDisabled: boolean
}

export const notificationInitialState: NotificationState = {
  showNotifications: false,
  notifications: undefined,
  notificationsDisabled: false,
}

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: notificationInitialState,
  reducers: {
    setShowNotifications(state: NotificationState, action: PayloadAction<boolean>) {
      state.showNotifications = action.payload
    },
    setNotifications(state: NotificationState, action: PayloadAction<PageNotificationDto | undefined>) {
      state.notifications = action.payload
    },
    setNotificationsDisabled(state: NotificationState, action: PayloadAction<boolean>) {
      state.notificationsDisabled = action.payload
    },
  },
})

export const { setShowNotifications, setNotifications, setNotificationsDisabled } = notificationSlice.actions
