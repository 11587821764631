import React, { FC } from 'react'
import { DriverArrivalDto, DriverArrivalDtoContainerTypeEnum } from '../../../api'
import { FormProvider, useForm } from 'react-hook-form'
import { KioskGateOutSemitrailerInfoForm } from './KioskGateOutSemitrailerInfoForm'
import { KioskGateOutContainerInfoForm } from './KioskGateOutContainerInfoForm'
import { yupResolver } from '@hookform/resolvers/yup'
import kioskDriverArrivalService from '../../../services/KioskDriverArrivalService'
import { KioskButtons } from '../KioskButtons'
import { useActualDriverArival } from '../shared/useActualDriverArival'
import { isAutocompleteItem } from '../../../utils/forms'
import { AutocompleteItem } from '../../../model/autocomplete'
import { useFormInitialValues } from '../../../hooks/useFormInitialValues'
import { SemitrailerValidation, ShippingContainerValidation } from './ValidationScheme'

type KioskGateOutInfoFormValues = {
  repleted: boolean
  iluCodeContainer: AutocompleteItem | string
  iluCodeSemitrailer: AutocompleteItem | string
  reference: string
  semitrailerLicencePlate: string
}

const initialValues: KioskGateOutInfoFormValues = {
  repleted: false,
  iluCodeContainer: '',
  iluCodeSemitrailer: '',
  reference: '',
  semitrailerLicencePlate: '',
}
interface KioskGateOutInfoFormProps {
  idDriverArrival: number
  previous: () => void
  next: () => void
}

export const KioskGateOutInfoForm: FC<KioskGateOutInfoFormProps> = ({ idDriverArrival, previous, next }) => {
  const { driverArrival, loading } = useActualDriverArival(idDriverArrival)

  const formMethods = useForm<DriverArrivalDto>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(
      driverArrival?.containerType === DriverArrivalDtoContainerTypeEnum.ShippingContainer
        ? ShippingContainerValidation()
        : SemitrailerValidation()
    ),
  })
  const { handleSubmit } = formMethods

  const onSubmit = async (formValue: KioskGateOutInfoFormValues) => {
    const { repleted, reference, semitrailerLicencePlate, iluCodeContainer, iluCodeSemitrailer } = formValue

    const isSemitrailer = driverArrival?.containerType === DriverArrivalDtoContainerTypeEnum.Semitrailer

    let iluCode = undefined

    if (isSemitrailer) {
      iluCode = isAutocompleteItem(iluCodeSemitrailer) ? iluCodeSemitrailer.name : iluCodeSemitrailer
    } else if (!isSemitrailer) {
      iluCode = isAutocompleteItem(iluCodeContainer) ? iluCodeContainer.name : iluCodeContainer
    }

    try {
      const updatedEntity: DriverArrivalDto = {
        ...driverArrival,
        driver: driverArrival?.driver,
        arrivalDateTime: driverArrival?.arrivalDateTime ?? '',
        repleted: !isSemitrailer ? !!repleted : undefined,
        iluCode,
        reference: !reference ? undefined : reference,
        semitrailerLicencePlate: isSemitrailer && semitrailerLicencePlate ? semitrailerLicencePlate : undefined,
      }
      await kioskDriverArrivalService.update(idDriverArrival ?? 0, updatedEntity)
      next()
    } catch (error) {
      console.error(error)
    }
  }

  useFormInitialValues(mapValues(driverArrival), formMethods.reset)

  return (
    <div>
      {!loading && (
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {driverArrival?.containerType === DriverArrivalDtoContainerTypeEnum.ShippingContainer ? (
              <KioskGateOutContainerInfoForm />
            ) : (
              <KioskGateOutSemitrailerInfoForm />
            )}
            <KioskButtons visibleBack visibleNext onClickBack={() => previous()} />
          </form>
        </FormProvider>
      )}
    </div>
  )
}

const mapValues = (entity: DriverArrivalDto | undefined): KioskGateOutInfoFormValues | undefined => {
  if (!entity) return undefined

  return {
    iluCodeContainer: entity.iluCode ?? '',
    iluCodeSemitrailer: entity.iluCode ?? '',
    reference: entity.reference ?? '',
    repleted: entity.repleted ?? false,
    semitrailerLicencePlate: entity.semitrailerLicencePlate ?? '',
  }
}
