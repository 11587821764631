import React, { FC, useEffect, useState } from 'react'
import { alpha, createStyles, Dialog, DialogActions, DialogTitle, Grid, Typography } from '@material-ui/core'
import ConardButton from '../ConardButton'
import { TransitionMode } from '../../enums/TransitionMode'
import {
  ContainerDtoContainerTypeEnum,
  TransitionDto,
  TransitionDtoDirectionEnum,
  TransitionDtoReceiptMethodEnum,
} from '../../api'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import transitionService from '../../services/TransitionService'
import { format } from 'date-fns'
import ProcessedFileService from '../../services/ProcessedFileService'
import { useAppDispatch } from '../../redux/store'
import { setTransition } from '../../redux/transition/transitionSlice'

const useStyles = makeStyles((theme) =>
  createStyles({
    panelContainer: {
      marginTop: '6vh',
      marginBottom: '6vh',
      backgroundColor: alpha(theme.palette.secondary.dark, 0.3),
      borderRadius: '8px',
      textAlign: 'center',
      minWidth: '290px',
      padding: '20px 0px 20px 0px',
    },
    dateOfControlTypography: {
      color: theme.palette.error.main,
    },
    title: {
      marginBottom: '20px',
    },
  })
)

interface ConardTransitionCustomsInfoBoxProps {
  transition: TransitionDto
  transitionMode: TransitionMode
}

const ConardTransitionCustomsInfoBox: FC<ConardTransitionCustomsInfoBoxProps> = ({ transition, transitionMode }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [showCreateCustomsInfoDialog, setShowCreateCustomsInfoDialog] = useState(false)
  const [showCreateBox, setShowCreateBox] = useState(false)
  const [customsInfoCreated, setCustomsInfoCreated] = useState(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (
      transitionMode === TransitionMode.SEARCH &&
      transition.direction === TransitionDtoDirectionEnum.In &&
      transition.repleted &&
      transition.receiptMethod === TransitionDtoReceiptMethodEnum.Train &&
      transition.container?.containerType === ContainerDtoContainerTypeEnum.ShippingContainer &&
      !transition.transitionCustomsInfo
    ) {
      transitionService
        .isTransitionWithoutGo(transition.id ?? -1)
        .then((response) => {
          setShowCreateBox(response.data)
        })
        .catch()
    } else {
      setShowCreateBox(false)
    }
  }, [
    transition.container?.containerType,
    transition.direction,
    transition.id,
    transition.receiptMethod,
    transition.repleted,
    transition.transitionCustomsInfo,
    transitionMode,
  ])

  const handleCreateCustomsInfo = () => {
    if (!transition.transitionCustomsInfo) {
      transitionService
        .createCustomsInfo(transition.id ?? -1)
        .catch()
        .finally(() => {
          setShowCreateCustomsInfoDialog(false)
          setCustomsInfoCreated(true)
        })
        .then((response) => {
          dispatch(
            setTransition({
              ...transition,
              transitionCustomsInfo: response.data.transitionCustomsInfo,
            })
          )
        })
    }
  }

  return (
    <>
      {!!transition.transitionCustomsInfo && (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.panelContainer}
        >
          <Grid item>
            <Typography variant="h5" className={classes.title}>
              {t('pages.gateIn.headers.customsState') +
                ': ' +
                (!!transition.transitionCustomsInfo?.state
                  ? t('enum.transitionCustomsState.' + transition.transitionCustomsInfo?.state)
                  : '')}
            </Typography>
          </Grid>
          {transition.transitionCustomsInfo.dateOfControl && (
            <Grid item>
              <Typography className={classes.dateOfControlTypography} variant="h6">
                {t('pages.customs.table.dateOfControl') +
                  ': ' +
                  format(new Date(transition.transitionCustomsInfo?.dateOfControl), 'dd.MM.yyyy')}
              </Typography>
            </Grid>
          )}
          {transition.transitionCustomsInfo.note && (
            <Grid item>
              <Typography variant="h6">
                {t('pages.customs.table.note') + ': ' + transition.transitionCustomsInfo?.note}
              </Typography>
            </Grid>
          )}
          <Grid item>
            <ConardButton
              disabled={!transition.transitionCustomsInfo.processedFile}
              conardVariant="light"
              onClick={() => ProcessedFileService.handleDownload(transition?.transitionCustomsInfo?.processedFile)}
            >
              {t('any.buttons.customsPDF.download')}
            </ConardButton>
          </Grid>
        </Grid>
      )}
      {showCreateBox && (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          className={classes.panelContainer}
        >
          <Grid item>
            <Typography variant="h6">
              {customsInfoCreated
                ? t('pages.gateIn.headers.customsInfoPresent')
                : t('pages.gateIn.headers.customsInfoMissing')}
            </Typography>
          </Grid>
          <Grid item>
            <ConardButton
              disabled={customsInfoCreated}
              conardVariant="light"
              onClick={() => setShowCreateCustomsInfoDialog(true)}
            >
              {t('any.buttons.createCustomsInfo')}
            </ConardButton>
          </Grid>
        </Grid>
      )}
      <Dialog open={showCreateCustomsInfoDialog}>
        <DialogTitle>{t('pages.customs.modal.confirmCreate.title')}</DialogTitle>
        <DialogActions>
          <ConardButton conardVariant="light" onClick={() => setShowCreateCustomsInfoDialog(false)}>
            {t('any.buttons.cancel')}
          </ConardButton>
          <ConardButton conardVariant="dark" onClick={handleCreateCustomsInfo}>
            {t('any.buttons.ok')}
          </ConardButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ConardTransitionCustomsInfoBox
