import { useTranslation } from 'react-i18next'

export const useConardTitle = () => {
  const { t } = useTranslation()

  switch (process.env.REACT_APP_CONARD_TERMINAL) {
    case 'BRNO':
      return t('pages.login.labels.title.brno')
    case 'MOSNOV':
      return t('pages.login.labels.title.mosnov')
    default:
      return t('pages.login.labels.title.default')
  }
}
