import React, { FC } from 'react'
import { TransitionDto } from '../../../api'
import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) =>
  createStyles({
    whiteDiv: {
      backgroundColor: 'white',
      borderRadius: 12,
      boxShadow: '0 2px 10px gray',
      padding: '1.5rem',
      width: '270px',
    },
    repleted: {
      color: theme.palette.primary.main,
      fontSize: 20,
      fontWeight: 600,
      marginBottom: '10px',
    },
  })
)

interface KioskGateInRepletedBoxProps {
  transition: TransitionDto
}

export const KioskGateInRepletedBox: FC<KioskGateInRepletedBoxProps> = ({ transition }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.whiteDiv}>
      <Grid container direction="column">
        <Grid item>
          <Typography className={classes.repleted}>{t('kioskApp.step9.repleted')}</Typography>
        </Grid>

        <Grid item>
          <Typography>
            <b>{t('kioskApp.step9.nhmCodes') + ': '}</b>
            {transition.nhmCodes?.map((nhm, index) => (index > 0 ? ', ' : '') + nhm.code)}
          </Typography>
        </Grid>

        <Grid item>
          <Typography>
            <b>{t('kioskApp.step9.signet') + ': '}</b>
            {transition.signetValue}
          </Typography>
        </Grid>

        <Grid item>
          <Typography>
            <b>{t('kioskApp.step9.netWeight') + ': '}</b>
            {transition.netWeight + ' kg'}
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}
