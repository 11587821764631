import React, { FC } from 'react'
import { createStyles, FormControl, InputLabel, Select, SelectProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Controller } from 'react-hook-form'
import { Control } from 'react-hook-form/dist/types/form'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      minWidth: '150px',
      '& .MuiOutlinedInput-notchedOutline': {
        '& legend': {
          marginLeft: '20px',
        },
      },
      borderRadius: 30,
      borderColor: theme.palette.secondary.light,
      backgroundColor: theme.palette.secondary.main,
    },
    formControl: {
      width: '100%',
    },
    shrinkLabel: {
      marginLeft: '20px',
    },
  })
)

const MenuProps = {
  getContentAnchorEl: null,
}

interface ConardMultiSelectProps {
  name: string
  control: Control
}

type ConardMultiSelectPropsUnited = ConardMultiSelectProps & Omit<SelectProps, 'name'>

const ConardMultiSelect: FC<ConardMultiSelectPropsUnited> = (props) => {
  const { name, label, defaultValue, onChange, control, value, error, children, disabled, renderValue } = props

  const classes = useStyles()

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={(props) => (
        <FormControl className={classes.formControl} variant="outlined">
          <InputLabel classes={{ shrink: classes.shrinkLabel }} variant="outlined">
            {label}
          </InputLabel>
          <Select
            multiple
            label={label}
            inputRef={props.ref}
            defaultValue={props.value}
            disabled={disabled}
            onChange={(event, child) => {
              props.onChange(event)

              if (!!onChange) {
                onChange(event, child)
              }
            }}
            value={value}
            error={error}
            variant="outlined"
            className={classes.root}
            renderValue={renderValue}
            MenuProps={{ ...MenuProps }}
          >
            {children}
          </Select>
        </FormControl>
      )}
    />
  )
}

export default ConardMultiSelect
