import { VeronaApi } from '../api'
import myAxios from '../config/axios/axios'
import { AxiosPromise } from 'axios'

let api: VeronaApi

export const setVeronaApiServiceConfiguration = () => {
  api = new VeronaApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const veronaService = {
  sendVeronaTrain: (id: number): AxiosPromise<void> => {
    return api.veronaSendVeronaTrain(id)
  },
}

export default veronaService
