import { NotificationControllerApi, NotificationDto, NotificationSearchDto, PageNotificationDto } from '../api'
import myAxios from '../config/axios/axios'
import { AxiosPromise } from 'axios'

let api: NotificationControllerApi

export const setNotificationControllerApiConfiguration = () => {
  api = new NotificationControllerApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const notificationService = {
  findAll: (page: number, pageSize: number, currentSort: string[] | undefined): AxiosPromise<PageNotificationDto> => {
    return api.findAll(page, pageSize, currentSort)
  },
  findAllForRoad: (
    page: number | undefined,
    pageSize: number | undefined,
    currentSort: string[] | undefined
  ): AxiosPromise<PageNotificationDto> => {
    return api.findAllForRoadDispatcher(page, pageSize, currentSort)
  },
  findAllForTrain: (
    page: number | undefined,
    pageSize: number | undefined,
    currentSort: string[] | undefined
  ): AxiosPromise<PageNotificationDto> => {
    return api.findAllForTrainDispatcher(page, pageSize, currentSort)
  },
  getNewForDispatcher: (): AxiosPromise<Array<NotificationDto>> => {
    return api.getNew()
  },
  getNewForRoadDispatcher: (): AxiosPromise<Array<NotificationDto>> => {
    return api.getNewRoad()
  },
  getNewForTrainDispatcher: (): AxiosPromise<Array<NotificationDto>> => {
    return api.getNewTrain()
  },
  search: (
    page: number | undefined,
    pageSize: number | undefined,
    currentSort: string[] | undefined,
    notificationSearchDto: NotificationSearchDto
  ): AxiosPromise<PageNotificationDto> => {
    return api.search(page, pageSize, currentSort, notificationSearchDto)
  },
  update: (id: number, notificationDto: NotificationDto): AxiosPromise<NotificationDto> => {
    return api.update(id, notificationDto)
  },
  removeAll: (ids: number[]): AxiosPromise<void> => {
    return api.removeAllNotifications(ids)
  },
}

export default notificationService
