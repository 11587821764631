import React, { FC, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core'
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded'
import { UserDtoRoleEnum } from '../../api'
import ConardButton from '../../components/ConardButton'
import { SemaphoreMode } from '../../enums/SemaphoreMode'
import asparkingSemaphoreControlService from '../../services/AsparkingSemaphoreControlService'
import { AsparkingCheckpoint } from '../../enums/AsparkingCheckpoint'
import { useHistory } from 'react-router-dom'
import { useConardAuth } from '../../hooks/useConardAuth'
import { ConfigContext } from '../../context/ConfigContext'

const useStyles = makeStyles((theme) =>
  createStyles({
    submissionGrid: {
      height: '50vh',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    submissionSuccessIcon: {
      fontSize: 200,
      color: theme.palette.primary.main,
    },
    gateButtons: {
      display: 'flex',
      flexDirection: 'row',
      gap: 8,
    },
  })
)

const CoparnSuccessPage: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const auth = useConardAuth()
  const configContext = useContext(ConfigContext)
  const normalAndRoadDispatcher = new Set([UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherRoad])

  useEffect(() => {
    if (auth.getRole() === UserDtoRoleEnum.Operator) {
      setTimeout(() => {
        history.push('/operator')
      }, 1500)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleOpenGate = (semaphoreMode: SemaphoreMode) => {
    asparkingSemaphoreControlService.asparkingSemaphoreControl(semaphoreMode, AsparkingCheckpoint.CHECKPOINT_2_IN)
    history.push('/')
  }

  return (
    <>
      <Grid container className={classes.submissionGrid}>
        <Grid item>
          <CheckCircleOutlineRoundedIcon className={classes.submissionSuccessIcon} />
        </Grid>
        <Grid item>
          <Typography variant="h6" color="primary">
            {t('any.labels.submissionSuccess')}
          </Typography>
        </Grid>
        {auth.hasOneOfRoles(normalAndRoadDispatcher) && configContext.gateControl && (
          <>
            <Typography variant="h6" color="primary" align="center">
              <br />
              {`${t('gateControl.tollgate')} 2 - ${t('gateControl.in')}`}
            </Typography>
            <Grid item className={classes.gateButtons}>
              <ConardButton conardVariant="light" onClick={() => handleOpenGate(SemaphoreMode.FORWARD_DIRECTION)}>
                {t('gateControl.buttons.forward')}
              </ConardButton>
              <ConardButton conardVariant="light" onClick={() => handleOpenGate(SemaphoreMode.RIGHT_DIRECTION)}>
                {t('gateControl.buttons.right')}
              </ConardButton>
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}
export default CoparnSuccessPage
