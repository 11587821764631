import React, { FC, useEffect, useState } from 'react'
import { createStyles, Grid, Hidden, InputAdornment, makeStyles, MenuItem } from '@material-ui/core'
import ConardSelect from '../../components/ConardSelect'
import {
  CompanyDto,
  ContainerSearchDtoContainerTypeEnum,
  ShipownerDto,
  TransitionDto,
  TransitionDtoContainerStateEnum,
  TransitionSearchDto,
} from '../../api'
import ConardTextField from '../../components/ConardTextField'
import ConardButton from '../../components/ConardButton'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { DatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import companyService from '../../services/CompanyService'
import shipownerService from '../../services/ShipownerService'

const useStyles = makeStyles((theme) =>
  createStyles({
    searchButton: {
      width: '15vw',
      minWidth: '300px',
      height: '5vh',
      minHeight: '50px',
      textAlign: 'center',
      margin: '20px',
      float: 'right',
    },
    datePickerInputInput: {
      width: '100%',
      textAlign: 'center',
    },
    datePickerInputRoot: {
      width: '60%',
      height: '56px',
      margin: 'auto',
    },
    datePickerRoot: {
      width: '100%',
      background: theme.palette.secondary.main,
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderRadius: '30px',
      '&:hover': {
        borderColor: 'black',
      },
    },
    colorDiv: {
      width: '10px',
      height: '10px',
      borderRadius: '100%',
      marginLeft: '10px',
    },
    companySelectItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  })
)

interface SearchFilterProps {
  searchFilter: (data: TransitionSearchDto) => void
  blockSearch: boolean
}

const GateInTransitionPendingFilter: FC<SearchFilterProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { searchFilter, blockSearch } = props

  const { handleSubmit, control, setValue } = useForm<TransitionDto>({})
  const [transitionDateFrom, setTransitionDateFrom] = useState<MaterialUiPickersDate | null>(null)
  const [transitionDateTo, setTransitionDateTo] = useState<MaterialUiPickersDate | null>(null)
  const [companies, setCompanies] = useState<CompanyDto[] | undefined>(undefined)
  const [shipowners, setShipowners] = useState<ShipownerDto[] | undefined>(undefined)

  const [selectRepleted, setSelectRepleted] = useState<string>('NOT-SELECTED')
  const [selectCompany, setSelectCompany] = useState<string>('NOT-SELECTED')
  const [selectShipowner, setSelectShipowner] = useState<string>('NOT-SELECTED')
  const [selectContainerType, setSelectContainerType] = useState<string>('NOT-SELECTED')

  const dateFromOnChange = (date: MaterialUiPickersDate | null) => {
    setTransitionDateFrom(date || new Date())
    setValue('transitionDateTimeFrom', date)
  }
  const dateToOnChange = (date: MaterialUiPickersDate | null) => {
    setTransitionDateTo(date || new Date())
    setValue('transitionDateTimeTo', date)
  }

  const getAllCompanies = () => {
    companyService.findAll(0, 100, '', '').then((response) => {
      setCompanies(response.data.content)
    })
  }

  const getAllShipowners = () => {
    shipownerService.findAll(undefined, undefined, undefined).then((response) => {
      setShipowners(response.data.content)
    })
  }

  useEffect(() => {
    getAllCompanies()
    getAllShipowners()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form onSubmit={handleSubmit(searchFilter)}>
      <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardTextField
            id="container.iluCode"
            name="container.iluCode"
            label={t('pages.gateIn.form.iluCode.label')}
            defaultValue=""
            control={control}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardSelect
            label={t('pages.gateIn.form.state.label')}
            name="containerState"
            disabled
            defaultValue={TransitionDtoContainerStateEnum.PlannedGateIn}
            control={control}
          >
            <MenuItem value={TransitionDtoContainerStateEnum.PlannedGateIn}>
              {t('pages.gateIn.form.state.plannedGateIn')}
            </MenuItem>
          </ConardSelect>
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardSelect
            id="repleted"
            name="repleted"
            defaultValue="NOT-SELECTED"
            label={t('pages.gateIn.form.repleted.checked') + ' / ' + t('pages.gateIn.form.repleted.unchecked')}
            value={selectRepleted}
            onChange={(event) => {
              setSelectRepleted(event.target.value)
            }}
            control={control}
          >
            <MenuItem value="NOT-SELECTED">{t('pages.common.select.all')}</MenuItem>
            <MenuItem value="true">{t('pages.gateIn.form.repleted.checked')}</MenuItem>
            <MenuItem value="false">{t('pages.gateIn.form.repleted.unchecked')}</MenuItem>
          </ConardSelect>
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardSelect
            id="container.company.id"
            name="container.company.id"
            label={t('entity.company.fields.name')}
            defaultValue="NOT-SELECTED"
            value={selectCompany}
            onChange={(event) => {
              setSelectCompany(event.target.value)
            }}
            control={control}
          >
            <MenuItem value="NOT-SELECTED">{t('pages.common.select.all')}</MenuItem>
            {companies?.map((company) => (
              <MenuItem key={company.id} value={company.id}>
                <div className={classes.companySelectItem}>
                  {company.name}
                  <div style={{ backgroundColor: company.color }} className={classes.colorDiv} />
                </div>
              </MenuItem>
            ))}
          </ConardSelect>
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardSelect
            id="container.shipowner.id"
            name="container.shipowner.id"
            label={t('pages.gateIn.form.shipowner.label')}
            defaultValue="NOT-SELECTED"
            value={selectShipowner}
            onChange={(event) => {
              setSelectShipowner(event.target.value)
            }}
            control={control}
          >
            <MenuItem value="NOT-SELECTED">{t('pages.common.select.all')}</MenuItem>
            {shipowners?.map((shipowner) => (
              <MenuItem key={shipowner.id} value={shipowner.id}>
                {shipowner.name}
              </MenuItem>
            ))}
          </ConardSelect>
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12} />
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardTextField
            id="container.semitrailerLicencePlate"
            name="container.semitrailerLicencePlate"
            label={t('pages.gateIn.form.semitrailerLicencePlate.label')}
            defaultValue=""
            control={control}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <Controller
            name="transitionDateTimeFrom"
            control={control}
            defaultValue={null}
            render={() => (
              <DatePicker
                emptyLabel={t('pages.searchTransition.datePicker.labels.transitionDateFrom')}
                format="dd.MM.yyyy"
                value={transitionDateFrom}
                onChange={(value) => {
                  dateFromOnChange(value)
                }}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <Hidden lgDown>
                      <InputAdornment position="start">
                        <CalendarTodayIcon />
                      </InputAdornment>
                    </Hidden>
                  ),
                  classes: {
                    root: classes.datePickerInputRoot,
                    input: classes.datePickerInputInput,
                  },
                }}
                className={classes.datePickerRoot}
              />
            )}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <Controller
            name="transitionDateTimeTo"
            control={control}
            defaultValue={null}
            render={() => (
              <DatePicker
                emptyLabel={t('pages.searchTransition.datePicker.labels.transitionDateTo')}
                format="dd.MM.yyyy"
                value={transitionDateTo}
                onChange={(value) => {
                  dateToOnChange(value)
                }}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <Hidden lgDown>
                      <InputAdornment position="start">
                        <CalendarTodayIcon />
                      </InputAdornment>
                    </Hidden>
                  ),
                  classes: {
                    root: classes.datePickerInputRoot,
                    input: classes.datePickerInputInput,
                  },
                }}
                className={classes.datePickerRoot}
              />
            )}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardSelect
            id="container.containerType"
            name="container.containerType"
            defaultValue="NOT-SELECTED"
            label={t('pages.gateIn.form.shippingContainerType.label')}
            value={selectContainerType}
            onChange={(event) => {
              setSelectContainerType(event.target.value)
            }}
            control={control}
          >
            <MenuItem value="NOT-SELECTED">{t('pages.common.select.all')}</MenuItem>
            <MenuItem value={ContainerSearchDtoContainerTypeEnum.ShippingContainer}>
              {t('enum.containerType.SHIPPING_CONTAINER')}
            </MenuItem>
            <MenuItem value={ContainerSearchDtoContainerTypeEnum.Semitrailer}>
              {t('enum.containerType.SEMITRAILER')}
            </MenuItem>
          </ConardSelect>
        </Grid>
        <Grid item xl={4} lg={4} md={8} sm={12} xs={12} />
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className={classes.searchButton}>
            <ConardButton conardVariant="light" type="submit" disabled={blockSearch}>
              {t('any.buttons.search')}
            </ConardButton>
          </div>
        </Grid>
      </Grid>
    </form>
  )
}

export default GateInTransitionPendingFilter
