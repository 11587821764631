import { AsparkingCardDto, AsparkingCardSearchDto, CardApi, PageAsparkingCardDto } from '../api'
import { AxiosPromise } from 'axios'
import myAxios from '../config/axios/axios'

let api: CardApi

export const setCardApiConfiguration = () => {
  api = new CardApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const cardService = {
  findAll: (page: number, pageSize: number, currentSort: string[] | undefined): AxiosPromise<PageAsparkingCardDto> => {
    return api.cardFindAll(page, pageSize, currentSort)
  },
  cardFindById: (id: number): AxiosPromise<AsparkingCardDto> => {
    return api.cardFindById(id)
  },
  cardCreate: (cardDto: AsparkingCardDto): AxiosPromise<AsparkingCardDto> => {
    return api.cardCreate(cardDto)
  },
  cardUpdate: (id: number, cardDto: AsparkingCardDto): AxiosPromise<AsparkingCardDto> => {
    return api.cardUpdate(id, cardDto)
  },
  cardDelete: (id: number): AxiosPromise<void> => {
    return api.cardDelete(id)
  },
  cardSearch: (
    page: number | undefined,
    pageSize: number | undefined,
    currentSort: string[] | undefined,
    cardSearchDto: AsparkingCardSearchDto
  ): AxiosPromise<PageAsparkingCardDto> => {
    return api.cardSearch(page, pageSize, currentSort, cardSearchDto)
  },
}

export default cardService
