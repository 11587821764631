import i18n from 'i18next'
import translation_cs from './cs/cs.json'
import translation_en from './en/en.json'
import translation_uk from './uk/uk.json'
import translation_hu from './hu/hu.json'
import translation_pl from './pl/pl.json'
import { initReactI18next } from 'react-i18next'

const resources = {
  cs: {
    translation: translation_cs,
  },
  en: {
    translation: translation_en,
  },
  uk: {
    translation: translation_uk,
  },
  hu: {
    translation: translation_hu,
  },
  pl: {
    translation: translation_pl,
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'cs',
  fallbackLng: 'cs',
})

export default i18n
