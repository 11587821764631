import React, { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { createStyles, Grid, Theme, Typography } from '@material-ui/core'
import ConardButton from '../../components/ConardButton'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { CarrierShortDto, CoparnTruckDto, DriverShortDto, LicensePlateDto, NotificationDto } from '../../api'
import { yupResolver } from '@hookform/resolvers/yup'
import GateOutCoparnEmptySelectPage from './GateOutCoparnEmptySelectPage'
import { GateOutCoparnDriverForm } from '../../components/gate-out/GateOutCoparnDriverForm'
import coparnService from '../../services/CoparnService'
import { cleanObject } from '../../utils/utils'
import asparkingSemaphoreControlService from '../../services/AsparkingSemaphoreControlService'
import { SemaphoreMode } from '../../enums/SemaphoreMode'
import notificationService from '../../services/NotificationService'
import { AsparkingCheckpoint } from '../../enums/AsparkingCheckpoint'
import { ConfigContext } from '../../context/ConfigContext'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      width: '60vw',
    },
    error: {
      color: theme.palette.error.main,
      textAlign: 'center',
    },
    container: {
      marginTop: '10vh',
    },
    controls: {
      position: 'fixed',
      bottom: 0,
      left: '10vw',
      width: '100vw',
      backgroundColor: theme.palette.secondary.main,
      zIndex: 100,
    },
    submitButton: {
      width: '15vw',
      minWidth: '200px',
      textAlign: 'center',
      marginTop: '2vh',
      marginBottom: '2vh',
      marginLeft: '5vw',
    },
  })
)

interface GateOutCoparnEmptyCreatePageState {
  notification?: NotificationDto
  frontLicensePlate?: LicensePlateDto
  rearLicensePlate?: LicensePlateDto
  driverShort?: DriverShortDto
  carrierShort?: CarrierShortDto
}

const GateOutCoparnEmptyCreatePage: FC = () => {
  const { t } = useTranslation()
  const { driverId } = useParams<{ driverId: string }>()
  const history = useHistory()
  const { gateControl } = useContext(ConfigContext)
  const classes = useStyles()
  const location = useLocation<GateOutCoparnEmptyCreatePageState>()
  const locationNotification = location?.state?.notification
  const locationFrontLicensePlate = location?.state?.frontLicensePlate
  const locationRearLicensePlate = location?.state?.rearLicensePlate
  const locationDriver = location?.state?.driverShort
  const locationCarrier = location?.state?.carrierShort

  const yupSchema = yup.object().shape({
    frontLicensePlate: yup.object().shape({
      licensePlate: yup.string().notOneOf([''], t('pages.gateIn.validations.frontLicensePlateNotSelected')),
    }),
    coparn: yup.object().required(t('pages.gateOut.coparn.validation.coparn')),
  })

  const formMethods = useForm<CoparnTruckDto>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(yupSchema),
  })

  const save = (data: CoparnTruckDto) => {
    coparnService
      .coparnCreateCoparnTruck(cleanObject(data))
      .then(() => {
        if (!!locationNotification) {
          notificationService.update(locationNotification.id || -1, {
            ...locationNotification,
            closedAt: new Date().toISOString(),
          })
          if (gateControl) {
            asparkingSemaphoreControlService.asparkingSemaphoreControl(
              SemaphoreMode.FORWARD_DIRECTION,
              AsparkingCheckpoint.CHECKPOINT_2_IN
            )
          }
        }
        history.push('/success/coparn')
      })
      .catch()
  }

  return (
    <>
      <Typography className={classes.typography} variant="h4" color="primary">
        {t('pages.gateOut.coparn.create.title')}
      </Typography>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(save)}>
          <Grid
            container
            className={classes.container}
            direction="row"
            alignItems="flex-start"
            justifyContent="center"
            spacing={4}
          >
            <Grid item xs={12}>
              <GateOutCoparnDriverForm
                locationDriverShort={locationDriver}
                locationCarrierShort={locationCarrier}
                locationFrontLicensePlate={locationFrontLicensePlate}
                locationRearLicensePlate={locationRearLicensePlate}
                notification={locationNotification}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} />
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography variant="subtitle1" className={classes.error}>
                <i>{!formMethods.errors.coparn ? '' : t('pages.gateOut.coparn.validation.coparn')}</i>
              </Typography>
            </Grid>
          </Grid>

          <Controller
            id="coparn"
            name="coparn"
            defaultValue=""
            control={formMethods.control}
            render={() => (
              <GateOutCoparnEmptySelectPage
                control={formMethods.control}
                driverId={Number(driverId)}
                notificationReference={locationNotification?.driverArrival?.reference}
              />
            )}
          />
          <div className={classes.controls}>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                <div className={classes.submitButton}>
                  <ConardButton conardVariant="dark" type="submit">
                    {t('any.buttons.save')}
                  </ConardButton>
                </div>
              </Grid>
              <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                <div className={classes.submitButton}>
                  <ConardButton conardVariant="dark" onClick={() => history.goBack()}>
                    {t('any.buttons.back')}
                  </ConardButton>
                </div>
              </Grid>
            </Grid>
          </div>
        </form>
      </FormProvider>
    </>
  )
}
export default GateOutCoparnEmptyCreatePage
