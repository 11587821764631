import myAxios from '../config/axios/axios'
import {
  AvailableContainerDto,
  CoparnApi,
  CoparnContainerDto,
  CoparnDto,
  CoparnSearchDto,
  CoparnTruckDto,
  PageCoparnContainerDto,
  PageCoparnDto,
} from '../api'
import { AxiosPromise } from 'axios'

let api: CoparnApi

export const setCoparnApiConfiguration = () => {
  api = new CoparnApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

export const CoparnService = {
  getAllCoparns: (page = 0, pageSize = 10, sort?: Array<string>) => {
    return api.coparnGetAllCoparns(page, pageSize, sort)
  },

  searchCoparns: (
    page?: number,
    size?: number,
    sort?: string[],
    searchDto?: CoparnSearchDto
  ): AxiosPromise<PageCoparnDto> => {
    return api.coparnSearch(page, size, sort, searchDto)
  },

  getCoparnContainerByCoparnId: (id: number, page = 0, pageSize = 10, sort?: Array<string>) => {
    return api.coparnGetAllCoparnContainerForContainers(id, page, pageSize, sort)
  },

  getAllAvailableContainers: (
    availableContainerDto: AvailableContainerDto,
    page = 0,
    pageSize = 10,
    sort?: Array<string>
  ) => {
    return api.coparnGetAllAvailableContainers(availableContainerDto, page, pageSize, sort)
  },

  saveContainerForCoparn: (coparnContainerDto: CoparnContainerDto) => {
    return api.coparnCreateCoparnContainerForCoparn(coparnContainerDto)
  },

  coparnCreateCoparnTruck: (coparnTruckDto: CoparnTruckDto) => {
    return api.coparnCreateCoparnTruck(coparnTruckDto)
  },

  coparnGetAssignedCoparnContainersForOperator: (
    id: number,
    page = 0,
    pageSize = 10,
    sort?: Array<string>
  ): AxiosPromise<PageCoparnContainerDto> => {
    return api.coparnGetAssignedCoparnContainersForOperator(id, page, pageSize, sort)
  },

  coparnFindAllUnassignedCoparnTrucks: (page?: number, size?: number, sort?: string[]): AxiosPromise<PageCoparnDto> => {
    return api.coparnFindAllUnassignedCoparnTrucks(page, size, sort)
  },

  coparnDeleteCoparnTruck: (id: number) => {
    return api.coparnDeleteCoparnTruck(id)
  },

  coparnCreate: (coparnDto: CoparnDto): AxiosPromise<CoparnDto> => {
    return api.coparnCreate(coparnDto)
  },
  coparnCancel: (id: number) => {
    return api.coparnCancel(id)
  },
}

export default CoparnService
