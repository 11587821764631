import React, { FC, useState } from 'react'
import { TruckMonitoringLogDto } from '../../api'
import { Box, createStyles, Grid, IconButton, makeStyles, TableCell, TableRow, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      margin: theme.spacing(1, 5),
      height: 50,
      border: `1px solid ${theme.palette.grey.A200}`,
      display: 'flex',
      borderRadius: 8,
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        background: theme.palette.primary.light,
        cursor: 'pointer',
      },
    },
    typography: {
      display: 'flex',
      justifyContent: 'center',
    },
    icon: {
      color: theme.palette.error.light,
    },
  })
)

export interface TruckMonitoringTableRowProps {
  id: string
  rowKey: number | string | undefined
  log?: TruckMonitoringLogDto | undefined
  onClick: (log: TruckMonitoringLogDto | undefined) => void
}

const TruckMonitoringTableRow: FC<TruckMonitoringTableRowProps> = ({ id, log, rowKey, onClick }) => {
  const classes = useStyles()
  const [showIcon, setShowIcon] = useState(false)

  return (
    <>
      <TableRow key={rowKey} id={id}>
        <TableCell>
          <Box
            onMouseEnter={() => setShowIcon(true)}
            onMouseLeave={() => setShowIcon(false)}
            onClick={() => onClick(log)}
            className={classes.box}
          >
            <Grid container direction="row" alignItems="center" justifyContent="space-between">
              <Grid item xs={2} />
              <Grid item xs={8}>
                <Typography variant="h6" className={classes.typography}>
                  {log?.licensePlate ?? <AddIcon />}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Grid justifyContent="flex-end" alignItems="center" container>
                  {log &&
                    (showIcon ? (
                      <IconButton>
                        <CloseIcon color="error" />
                      </IconButton>
                    ) : (
                      ''
                    ))}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </TableCell>
      </TableRow>
    </>
  )
}
export default TruckMonitoringTableRow
