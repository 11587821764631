import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Button, createStyles, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { DateRangeIcon } from '@material-ui/pickers/_shared/icons/DateRangeIcon'
import { format } from 'date-fns'
import { UserDtoRoleEnum } from '../../api'
import { useConardAuth } from '../../hooks/useConardAuth'

const useStyles = makeStyles((theme) =>
  createStyles({
    containerGrid: {
      width: '100%',
    },
    containerGridItem: {
      width: '80%',
    },
    calendarIcon: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
      fontSize: 40,
      color: theme.palette.primary.main,
    },
    navigationButtonGridItem: {
      width: '33%',
    },
    navigationButton: {
      height: '10vh',
      minHeight: '150px',
      width: '100%',
      '&.MuiButton-contained.Mui-disabled': {
        backgroundColor: theme.palette.grey.A100,
      },
    },
  })
)

const CustomerDashboard: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()
  const auth = useConardAuth()

  const goToShipowner = () => {
    history.push('/customer/shipowner')
  }

  const goToParentCompany = () => {
    history.push('/customer/parent-company')
  }

  const goToCompany = () => {
    history.push('/customer/company')
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.containerGrid}
      spacing={8}
    >
      <Grid item className={classes.containerGridItem}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
          <Grid item>
            <DateRangeIcon className={classes.calendarIcon} />
          </Grid>
          <Grid item>
            <Typography variant="h4" color="primary">
              {format(new Date(), 'dd.MM.yyyy, HH:mm')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item className={classes.containerGridItem}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={6}>
          {auth.hasOneOfRoles(new Set([UserDtoRoleEnum.Manager])) && (
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.navigationButton}
                onClick={goToShipowner}
              >
                <Typography variant="h5">{t('pages.customerManagement.dashboard.shipowners')}</Typography>
              </Button>
            </Grid>
          )}
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.navigationButton}
              onClick={goToParentCompany}
            >
              <Typography variant="h5">{t('pages.customerManagement.dashboard.parentCompanies')}</Typography>
            </Button>
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <Button variant="contained" color="secondary" className={classes.navigationButton} onClick={goToCompany}>
              <Typography variant="h5">{t('pages.customerManagement.dashboard.companies')}</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CustomerDashboard
