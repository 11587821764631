import React, { FC, ReactElement, useContext, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { createStyles, Grid, Link, Typography } from '@material-ui/core'
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import gateInService from '../../../services/GateInService'
import transitionService from '../../../services/TransitionService'
import {
  NotificationDto,
  TransitionCustomsInfoDtoStateEnum,
  TransitionDto,
  TransitionDtoContainerStateEnum,
  TransitionDtoDirectionEnum,
  TransitionDtoReceiptMethodEnum,
  UserDtoRoleEnum,
} from '../../../api'
import { Alert } from '@material-ui/lab'
import { format } from 'date-fns'
import ConardButton from '../../../components/ConardButton'
import asparkingSemaphoreControlService from '../../../services/AsparkingSemaphoreControlService'
import { SemaphoreMode } from '../../../enums/SemaphoreMode'
import { AsparkingCheckpoint } from '../../../enums/AsparkingCheckpoint'
import asparkingGateControlService from '../../../services/AsparkingGateControlService'
import { ConfigContext } from '../../../context/ConfigContext'
import {
  setTransition,
  setTransitionFormDisabled,
  setTransitionPositionDisabled,
  setTransitionSaveDisabled,
  transitionDefaults,
  transitionInitialState,
} from '../../../redux/transition/transitionSlice'
import { useAppDispatch } from '../../../redux/store'
import ProcessedFileService from '../../../services/ProcessedFileService'
import { useConardAuth } from '../../../hooks/useConardAuth'
import gateOutService from '../../../services/GateOutService'
import { TransitionSuccessPageTypography } from './TransitionSuccessPageTypography'

const useStyles = makeStyles((theme) =>
  createStyles({
    alert: {
      margin: 'auto',
      width: '50vw',
      marginTop: '20vh',
    },
    submissionGrid: {
      height: '70vh',
    },
    submissionSuccessIcon: {
      fontSize: 200,
      color: theme.palette.primary.main,
    },
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      gap: 8,
    },
    backButton: {
      marginTop: '1rem',
    },
    gridSection: {
      marginBottom: '2rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
)

interface UrlParamTypes {
  id?: string
  type?: string
}

interface TransitionSuccessPageState {
  notification?: NotificationDto
}

const TransitionSuccessPage: FC = (): ReactElement => {
  const { id, type } = useParams<UrlParamTypes>()
  const history = useHistory()
  const location = useLocation<TransitionSuccessPageState>()
  const dispatch = useAppDispatch()
  const locationNotification = location?.state?.notification

  const auth = useConardAuth()
  const configContext = useContext(ConfigContext)

  const classes = useStyles()
  const { t } = useTranslation()

  const [transitionDto, setTransitionDto] = useState<TransitionDto>()
  const [loaded, setLoaded] = useState(false)

  const [driverGateOutType, setDriverGateOutType] = useState<string | null>(null)
  const [driverGateOutTypeLoaded, setDriverGateOutTypeLoaded] = useState(false)

  const normalAndRoadDispatcher = new Set([UserDtoRoleEnum.Dispatcher, UserDtoRoleEnum.DispatcherRoad])

  const isGateInOrStore = type === 'gate-in' || type === 'store'
  const isProcessingGateOut =
    type === 'gate-out' && transitionDto?.containerState === TransitionDtoContainerStateEnum.ProcessingGateOut
  const isFinishedGateOut =
    type === 'gate-out' && transitionDto?.containerState === TransitionDtoContainerStateEnum.FinishedGateOut

  useEffect(() => {
    if (id) {
      transitionService
        .findById(Number.parseInt(id))
        .then((response) => {
          const transition = {
            ...response.data,
            driverShort: !!response.data.driverShort ? response.data.driverShort : undefined,
            carrierShort: !!response.data.carrierShort ? response.data.carrierShort : undefined,
            frontLicensePlate: !!response.data.frontLicensePlate ? response.data.frontLicensePlate : undefined,
            rearLicensePlate: !!response.data.rearLicensePlate ? response.data.rearLicensePlate : undefined,
          }
          setTransitionDto(transition)
          setLoaded(true)
        })
        .catch(() => {
          setLoaded(true)
        })
    }

    if (auth.getRole() === UserDtoRoleEnum.Operator) {
      setTimeout(() => {
        history.push('/operator')
      }, 1500)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleDownloadPdf = () => {
    gateInService
      .downloadDamageLog(transitionDto?.id)
      .then((response) => {
        const url = window.URL.createObjectURL(response.data)
        const a = document.createElement('a')
        a.href = url
        a.download = format(new Date(), 'yyyyMMdd_') + transitionDto?.container.iluCode || -1 + '.pdf'
        a.click()
      })
      .catch()
  }

  const handleGateOut = () => {
    history.push('/gate-out/single-list', {
      notification: locationNotification,
      driver: transitionDto?.driverShort,
      carrier: transitionDto?.carrierShort,
      frontLicensePlate: transitionDto?.frontLicensePlate,
      rearLicensePlate: transitionDto?.rearLicensePlate,
    })
  }

  const handleGateOutEmpty = () => {
    history.push('/gate-out/coparn-empty/create', {
      notification: locationNotification,
      driverShort: transitionDto?.driverShort,
      carrierShort: transitionDto?.carrierShort,
      frontLicensePlate: transitionDto?.frontLicensePlate,
      rearLicensePlate: transitionDto?.rearLicensePlate,
    })
  }

  const handleGateOutTransition = (driverId: number) => {
    dispatch(setTransition(transitionDefaults.shippingContainer))
    history.push(`/gate-out/single-container/driver/${driverId}`, {
      notification: locationNotification,
      driver: transitionDto?.driverShort,
      carrier: transitionDto?.carrierShort,
      frontLicensePlate: transitionDto?.frontLicensePlate,
      rearLicensePlate: transitionDto?.rearLicensePlate,
    })
  }

  const handleGateOutCoparn = (driverId: number) => {
    history.push(`/gate-out/coparn-empty/${driverId}/create`, {
      notification: locationNotification,
      driverShort: transitionDto?.driverShort,
      carrierShort: transitionDto?.carrierShort,
      frontLicensePlate: transitionDto?.frontLicensePlate,
      rearLicensePlate: transitionDto?.rearLicensePlate,
    })
  }

  const handleOpenSemaphore = (semaphoreMode: SemaphoreMode) => {
    asparkingSemaphoreControlService.asparkingSemaphoreControl(semaphoreMode, AsparkingCheckpoint.CHECKPOINT_2_IN)
    history.push('/')
  }

  const handleOpenGate = () => {
    // targetDevice 7 = checkpoint 2 gate-out
    asparkingGateControlService.asparkingGateControlOpenGate(7)
    history.push('/')
  }

  const goBackToTransition = () => {
    dispatch(setTransitionFormDisabled(true))
    dispatch(setTransitionPositionDisabled(true))
    dispatch(setTransitionSaveDisabled(true))
    dispatch(setTransition(transitionInitialState.transition))

    history.replace('/search/transition/detail/' + id)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (transitionDto?.driverShort?.id && !driverGateOutTypeLoaded) {
          const response = await gateOutService.getDriverGateOutType(transitionDto?.driverShort?.id)
          setDriverGateOutType(response.data)
          setDriverGateOutTypeLoaded(true)
        }
      } catch (error) {
        console.error('Error fetching driver gate out type', error)
      }
    }
    fetchData()
  }, [driverGateOutTypeLoaded, transitionDto?.driverShort])

  return (
    <>
      {loaded && (
        <>
          {transitionDto === undefined && (
            <Alert severity="error" className={classes.alert}>
              {t('be.error.entity.notFound')}
            </Alert>
          )}
          {transitionDto && (
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              className={classes.submissionGrid}
            >
              <Grid container className={classes.gridSection}>
                <Grid item>
                  <CheckCircleOutlineRoundedIcon className={classes.submissionSuccessIcon} />
                </Grid>
                <Grid item>
                  <Typography variant="h6" color="primary">
                    {type === 'update' ? t('any.labels.transitionUpdateSuccess') : t('any.labels.submissionSuccess')}
                  </Typography>
                </Grid>
                {auth.getRole() !== UserDtoRoleEnum.Operator &&
                  transitionDto?.damageLogEntries &&
                  transitionDto.damageLogEntries.length > 0 && (
                    <Grid item>
                      <Typography variant="h6" color="primary">
                        <Trans i18nKey="pages.gateIn.headers.submissionSuccessDamageReport">
                          See the
                          <Link href="#" underline="hover" onClick={handleDownloadPdf}>
                            description
                          </Link>
                          below.
                        </Trans>
                      </Typography>
                    </Grid>
                  )}
              </Grid>
              {auth.hasOneOfRoles(normalAndRoadDispatcher) && isGateInOrStore && (
                <Grid container className={classes.gridSection} spacing={1}>
                  <TransitionSuccessPageTypography driverGateOutType={driverGateOutType} />

                  {driverGateOutType === 'TRANSITION' || driverGateOutType === 'COPARN' ? (
                    <>
                      {driverGateOutType === 'TRANSITION' && (
                        <Grid item>
                          <ConardButton
                            conardVariant="light"
                            onClick={() => handleGateOutTransition(transitionDto.driverShort?.id ?? 0)}
                          >
                            {t('any.buttons.gateIn.success.createGateOut')}
                          </ConardButton>
                        </Grid>
                      )}
                      {driverGateOutType === 'COPARN' && (
                        <Grid item>
                          <ConardButton
                            conardVariant="light"
                            onClick={() => handleGateOutCoparn(transitionDto.driverShort?.id ?? 0)}
                          >
                            {t('any.buttons.gateIn.success.createGateOutEmpty')}
                          </ConardButton>
                        </Grid>
                      )}
                    </>
                  ) : (
                    <Grid item className={classes.buttons}>
                      <ConardButton conardVariant="light" onClick={handleGateOut}>
                        {t('any.buttons.gateIn.success.createGateOut')}
                      </ConardButton>
                      <ConardButton conardVariant="light" onClick={handleGateOutEmpty}>
                        {t('any.buttons.gateIn.success.createGateOutEmpty')}
                      </ConardButton>
                    </Grid>
                  )}
                </Grid>
              )}
              {configContext.gateControl && auth.hasOneOfRoles(normalAndRoadDispatcher) && isProcessingGateOut && (
                <Grid container className={classes.gridSection} spacing={1}>
                  <Grid item>
                    <Typography variant="h6" color="primary">
                      {`${t('gateControl.tollgate')} 2 - ${t('gateControl.in')}`}
                    </Typography>
                  </Grid>
                  <Grid item className={classes.buttons}>
                    <ConardButton
                      conardVariant="light"
                      onClick={() => handleOpenSemaphore(SemaphoreMode.FORWARD_DIRECTION)}
                    >
                      {t('gateControl.buttons.forward')}
                    </ConardButton>
                    <ConardButton
                      conardVariant="light"
                      onClick={() => handleOpenSemaphore(SemaphoreMode.RIGHT_DIRECTION)}
                    >
                      {t('gateControl.buttons.right')}
                    </ConardButton>
                  </Grid>
                </Grid>
              )}
              {configContext.gateControl && auth.hasOneOfRoles(normalAndRoadDispatcher) && isFinishedGateOut && (
                <Grid container className={classes.gridSection} spacing={1}>
                  <Grid item>
                    <Typography variant="h6" color="primary">
                      {`${t('gateControl.tollgate')} 2 - ${t('gateControl.out')}`}
                    </Typography>
                  </Grid>
                  <Grid item className={classes.buttons}>
                    <ConardButton conardVariant="light" onClick={handleOpenGate}>
                      {t('gateControl.buttons.openGate')}
                    </ConardButton>
                  </Grid>
                </Grid>
              )}
              {configContext.entryAndParking &&
                auth.hasOneOfRoles(normalAndRoadDispatcher) &&
                transitionDto?.receiptMethod === TransitionDtoReceiptMethodEnum.Road &&
                transitionDto.direction === TransitionDtoDirectionEnum.In &&
                transitionDto?.containerState !== TransitionDtoContainerStateEnum.PlannedGateIn && (
                  <Grid container className={classes.gridSection}>
                    <Grid item>
                      <Typography variant="h6" color="primary">
                        {`${t('gateControl.tollgate')} 2 - ${
                          type === 'gate-out' ? t('gateControl.out') : t('gateControl.in')
                        }`}
                      </Typography>
                    </Grid>
                    <Grid item className={classes.buttons}>
                      {type === 'gate-out' && (
                        <ConardButton conardVariant="light" onClick={handleOpenGate}>
                          {t('gateControl.buttons.openGate')}
                        </ConardButton>
                      )}
                      {type !== 'gate-out' && (
                        <>
                          <ConardButton
                            conardVariant="light"
                            onClick={() => handleOpenSemaphore(SemaphoreMode.FORWARD_DIRECTION)}
                          >
                            {t('gateControl.buttons.forward')}
                          </ConardButton>
                          <ConardButton
                            conardVariant="light"
                            onClick={() => handleOpenSemaphore(SemaphoreMode.RIGHT_DIRECTION)}
                          >
                            {t('gateControl.buttons.right')}
                          </ConardButton>
                        </>
                      )}
                    </Grid>
                  </Grid>
                )}
              {new Set([TransitionCustomsInfoDtoStateEnum.T1ByUser, TransitionCustomsInfoDtoStateEnum.T1ByEmail]).has(
                transitionDto?.transitionCustomsInfo?.state ?? TransitionCustomsInfoDtoStateEnum.Stop
              ) &&
                auth.getRole() !== UserDtoRoleEnum.Operator && (
                  <Grid container className={classes.gridSection}>
                    <Grid item>
                      <Typography variant="h6" color="error" align="center">
                        {!!transitionDto?.transitionCustomsInfo?.processedFile
                          ? t('pages.customs.driverDocument.info')
                          : t('pages.customs.driverDocument.error')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <ConardButton
                        disabled={!transitionDto?.transitionCustomsInfo?.processedFile}
                        conardVariant="light"
                        onClick={() =>
                          ProcessedFileService.handleDownload(transitionDto?.transitionCustomsInfo?.processedFile)
                        }
                      >
                        {t('pages.gateOut.buttons.download')}
                      </ConardButton>
                    </Grid>
                  </Grid>
                )}
              {auth.getRole() !== UserDtoRoleEnum.Operator && (
                <Grid item className={classes.backButton}>
                  <ConardButton conardVariant="transparent" onClick={goBackToTransition}>
                    {t('any.buttons.backTo.transition')}
                  </ConardButton>
                </Grid>
              )}
            </Grid>
          )}
        </>
      )}
    </>
  )
}
export default TransitionSuccessPage
