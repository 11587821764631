import React, { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { NotificationDto } from '../../api'
import { makeStyles } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) =>
  createStyles({
    dateTime: {
      fontSize: 12,
      color: theme.palette.primary.main,
    },
  })
)

export interface NotificationBodyProps {
  notification: NotificationDto
}

const NotificationBody: FC<NotificationBodyProps> = ({ notification }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <b className={classes.dateTime}>
        {t('pages.notifications.timestamp', {
          createdAt: format(new Date(notification?.createdAt ?? new Date()), 'dd. MM. yyyy HH:mm'),
        })}
      </b>
      <br />
      <b>
        {notification?.gate?.gateNumber
          ? t(`pages.notifications.gate.${notification?.gate?.gateNumber}`, { gateType: notification?.gate?.gateType })
          : ''}
      </b>
      <Trans
        i18nKey={`pages.notifications.messages.${notification.notificationType}`}
        values={{
          licensePlateFront: notification.licensePlateFront,
          licensePlateRear: notification.licensePlateRear,
          iluCode:
            notification.iluCode ??
            notification.driverArrival?.plannedTransition?.container.iluCode ??
            notification.driverArrival?.iluCode,
          flag: notification.flag,
          driver: notification.driverArrival?.driver?.name,
          reference: notification.driverArrival?.reference,
          semitrailerLicencePlate: notification.driverArrival?.semitrailerLicencePlate,
        }}
        tOptions={{ interpolation: { escapeValue: false } }}
      />
    </>
  )
}

export default NotificationBody
