import React, { FC, useEffect, useState } from 'react'
import { ContainerDtoContainerTypeEnum, TrainDto, TrainWagonContentDto, TrainWagonDto, TransitionDto } from '../../api'
import { TransitionMode } from '../../enums/TransitionMode'
import ConardTransitionForm from '../../components/forms/ConardTransitionForm'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../redux/store'
import { useHistory, useParams } from 'react-router-dom'
import trainService from '../../services/TrainService'
import { setTrain } from '../../redux/train/trainSlice'
import transitionService from '../../services/TransitionService'
import { setTransition } from '../../redux/transition/transitionSlice'

interface UrlParam {
  id: string
}

const TrainTransitionUpdatePage: FC = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { id } = useParams<UrlParam>()

  const train = useSelector<RootState, TrainDto>((state) => state.train.train)
  const currentWagon = useSelector<RootState, TrainWagonDto | null>((state) => state.train.currentWagon)
  const currentWagonContent = useSelector<RootState, TrainWagonContentDto | null>(
    (state) => state.train.currentWagonContent
  )
  const [containerType, setContainerType] = useState<ContainerDtoContainerTypeEnum>()
  const [loaded, setLoaded] = useState(false)

  const onTransitionSave = (transition: TransitionDto) => {
    if (currentWagon && currentWagonContent) {
      transitionService
        .update(parseInt(id ?? '-1'), transition)
        .then((response) => {
          if (response.data) {
            const updatedTrain = trainService.addTransitionsOnWagonContent(
              train,
              currentWagon,
              currentWagonContent,
              response.data
            )
            dispatch(setTrain(updatedTrain))
          }
        })
        .finally(history.goBack)
    }
  }

  useEffect(() => {
    transitionService
      .findById(Number.parseInt(id))
      .then((response) => {
        dispatch(
          setTransition({
            ...response.data,
          })
        )
        setContainerType(response.data.container.containerType)
      })
      .catch()
      .finally(() => {
        setLoaded(true)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <>
      {loaded && (
        <ConardTransitionForm
          containerType={containerType ?? ContainerDtoContainerTypeEnum.ShippingContainer}
          transitionMode={TransitionMode.TRAIN}
          onTransitionSave={onTransitionSave}
          onCancel={() => history.goBack()}
        />
      )}
    </>
  )
}

export default TrainTransitionUpdatePage
