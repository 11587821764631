import { InfoApi } from '../api'
import myAxios from '../config/axios/axios'

const api = new InfoApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)

const infoService = {
  info: () => {
    return api.infoInfo()
  },
}

export default infoService
