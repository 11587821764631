import React, { FC } from 'react'
import { UserDtoRoleEnum } from '../api'
import GateInPage from '../pages/gateIn/GateInPage'
import UserListPage from '../pages/user/UserListPage'
import OperatorListPage from '../pages/operator/OperatorListPage'
import CustomsListPage from '../pages/customs/CustomsListPage'
import { useConardAuth } from '../hooks/useConardAuth'
import TrainDashboard from '../pages/train/TrainDashboard'
import { DriverArrivalPage } from '../pages/drivers/arrival-page/DriverArrivalPage'
import { CarrierMainPage } from '../pages/carrier/main-page/CarrierMainPage'
import { KioskHomePage } from '../pages/kiosk/KioskHomePage'

const HomePage: FC = () => {
  const auth = useConardAuth()

  return (
    <>
      {auth.getRole() === UserDtoRoleEnum.Dispatcher && <GateInPage />}
      {auth.getRole() === UserDtoRoleEnum.DispatcherRoad && <GateInPage />}
      {auth.getRole() === UserDtoRoleEnum.DispatcherTrain && <TrainDashboard />}
      {auth.getRole() === UserDtoRoleEnum.Manager && <UserListPage />}
      {auth.getRole() === UserDtoRoleEnum.Operator && <OperatorListPage />}
      {auth.getRole() === UserDtoRoleEnum.CustomsOfficer && <CustomsListPage />}
      {auth.getRole() === UserDtoRoleEnum.Driver && <DriverArrivalPage />}
      {auth.getRole() === UserDtoRoleEnum.Carrier && <CarrierMainPage />}
      {auth.getRole() === UserDtoRoleEnum.Kiosk && <KioskHomePage />}
    </>
  )
}

export default HomePage
