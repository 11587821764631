import { CoparnContainerDto, CoparnContainerDtoStatusEnum, PageCoparnContainerDto, TransitionDto } from '../../api'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { Order } from '../../enums/Order'
import { useTranslation } from 'react-i18next'
import { CoparnService } from '../../services/CoparnService'
import ConardButton from '../../components/ConardButton'
import { AvailableContainerSelectionModal } from '../../components/modals/AvailableContainerSelectionModal'
import { cleanShippingContainerType } from '../../utils/utils'
import { ConardCoordinates } from '../../components/ConardCoordinates'
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material'
import { useConardTheme } from '../../hooks/useConardTheme'

interface HeadCell {
  id: string
  label: string
}

export interface ScheduledContainerSelectionModalProps {
  open: boolean
  driverInfo: { frontLicensePlate?: string; driver?: string }
  coparnContainerId: number
  onClose: () => void
  onSave: (transitionDto: TransitionDto, coparnContainerId: number) => void
}

export const OperatorCoparnContainerSelectionModal: FC<ScheduledContainerSelectionModalProps> = ({
  open,
  driverInfo,
  coparnContainerId,
  onClose,
  onSave,
}) => {
  const [pageCoparn, setPageCoparn] = useState<PageCoparnContainerDto>()
  const [selectedCoparnContainerDto, setSelectedCoparnContainerDto] = useState<CoparnContainerDto>()
  const [order, setOrder] = useState(Order.Asc)
  const [orderBy, setOrderBy] = useState('')
  const [page, setPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)
  const { t } = useTranslation()

  const [submodalSelectedCoparnContainerDto, setSubmodalSelectedCoparnContainerDto] = useState<CoparnContainerDto>()
  const [submodalOpen, setSubmodalOpen] = useState<boolean>(false)

  const headCells: HeadCell[] = [
    { id: 'selectedTransition.container.iluCode', label: t('pages.gateOut.coparn.modal.iluCode') },
    { id: 'shipowner', label: t('pages.gateOut.coparn.modal.shipowner') },
    { id: 'coparnContainerQuality', label: t('pages.gateOut.coparn.modal.quality') },
    { id: 'containerType', label: t('pages.gateOut.coparn.modal.containerType') },
    {
      id: 'selectedTransition.sectorLetter,selectedTransition.sectorColumn,selectedTransition.sectorRow,selectedTransition.sectorFloor',
      label: t('pages.gateOut.coparn.modal.sector'),
    },
    { id: 'selectedTransition.note', label: t('pages.gateOut.coparn.modal.note') },
  ]

  const closeModal = () => {
    setSelectedCoparnContainerDto(undefined)
    onClose()
  }

  const addContainer = (transitionDto: TransitionDto | undefined, coparnContainerId: number) => {
    const newCoparnContainerList: PageCoparnContainerDto = { ...pageCoparn }
    const selectedCoparnContainerFromList = newCoparnContainerList?.content?.find((container: CoparnContainerDto) => {
      return container.id === coparnContainerId
    })
    if (selectedCoparnContainerFromList) {
      selectedCoparnContainerFromList.selectedTransition = transitionDto
      CoparnService.saveContainerForCoparn(selectedCoparnContainerFromList)
        .then(() => {
          setPageCoparn(newCoparnContainerList)
        })
        .finally(() => {
          setSubmodalOpen(false)
        })
    }
  }

  const save = () => {
    if (selectedCoparnContainerDto) {
      onSave(selectedCoparnContainerDto.selectedTransition!, selectedCoparnContainerDto.id!)
      setSelectedCoparnContainerDto(undefined)
    }
  }

  const onSort = (newOrderBy: string) => () => {
    setOrderBy(newOrderBy)
    setOrder(orderBy === newOrderBy ? (order === Order.Asc ? Order.Desc : Order.Asc) : Order.Asc)
  }

  const onPage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage)
  }

  const onRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value, 10))
    setPage(0)
  }

  const search = () => {
    CoparnService.coparnGetAssignedCoparnContainersForOperator(coparnContainerId, page, pageSize, [
      orderBy + ',' + order,
    ]).then((response) => {
      setPageCoparn(response.data)
    })
  }

  const openSubmodal = (coparnContainerDto: CoparnContainerDto) => {
    setSubmodalSelectedCoparnContainerDto(coparnContainerDto)
    setSubmodalOpen(true)
  }

  useEffect(() => {
    if (open) {
      search()
    }
  }, [open, page, pageSize, order, orderBy]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog onClose={closeModal} aria-labelledby="simple-dialog-title" open={open} maxWidth="md">
      <DialogTitle id="simple-dialog-title">{t('pages.gateOut.coparn.modal.title')}</DialogTitle>
      <DialogContent>
        <StyledDriverInfo>
          <i>{driverInfo.frontLicensePlate ?? ''}</i>
          {` ${driverInfo.driver ? driverInfo.driver : ''}`}
        </StyledDriverInfo>
        <br />
        <TableContainer>
          <Table>
            <TableHead>
              <StyledTableRow>
                <TableCell />
                {headCells.map((headCell) => (
                  <StyledHeadCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : Order.Asc}
                      onClick={onSort(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </StyledHeadCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {!!pageCoparn?.content?.length ? (
                pageCoparn?.content?.map((coparnContainerDto: CoparnContainerDto) => {
                  return (
                    <TableRow key={coparnContainerDto.id}>
                      <TableCell>
                        <Checkbox
                          color="primary"
                          disabled={!coparnContainerDto.selectedTransition?.container?.iluCode}
                          checked={selectedCoparnContainerDto?.id === coparnContainerDto?.id}
                          onChange={() => setSelectedCoparnContainerDto(coparnContainerDto)}
                        />
                      </TableCell>
                      <TableCell>
                        {coparnContainerDto.selectedTransition?.container?.iluCode ?? (
                          <StyledButton
                            conardVariant="dark"
                            disabled={coparnContainerDto.status === CoparnContainerDtoStatusEnum.Processed}
                            onClick={() => openSubmodal(coparnContainerDto)}
                          >
                            {t('any.buttons.assign')}
                          </StyledButton>
                        )}
                      </TableCell>
                      <TableCell>{coparnContainerDto.coparn?.shipowner?.name}</TableCell>
                      <TableCell>{coparnContainerDto.coparnContainerQuality}</TableCell>
                      <TableCell>
                        {coparnContainerDto.containerType
                          ? cleanShippingContainerType(coparnContainerDto.containerType)
                          : ''}
                      </TableCell>
                      <TableCell scope="row">
                        <ConardCoordinates transition={coparnContainerDto.selectedTransition} />
                      </TableCell>
                      <TableCell>{coparnContainerDto.selectedTransition?.note}</TableCell>
                    </TableRow>
                  )
                })
              ) : (
                <TableRow>
                  <StyledNoDataCell colSpan={8}>{t('pages.common.table.noDataAvailable')}</StyledNoDataCell>
                </TableRow>
              )}
              {submodalSelectedCoparnContainerDto && (
                <AvailableContainerSelectionModal
                  driverInfo={driverInfo}
                  coparnContainer={submodalSelectedCoparnContainerDto!}
                  open={submodalOpen}
                  onSave={addContainer}
                  onClose={() => setSubmodalOpen(false)}
                />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={pageCoparn?.totalElements || 0}
          onPageChange={onPage}
          page={page}
          rowsPerPage={pageSize}
          labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
          rowsPerPageOptions={[10, 20, 50, 150]}
          onRowsPerPageChange={onRowsPerPage}
          sx={{
            '& .MuiToolbar-root': {
              backgroundColor: 'white',
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <StyledButton conardVariant="transparent" onClick={closeModal}>
          {t('any.buttons.back')}
        </StyledButton>
        <StyledButton conardVariant="dark" onClick={save} disabled={!selectedCoparnContainerDto}>
          {t('any.buttons.select')}
        </StyledButton>
      </DialogActions>
    </Dialog>
  )
}

const StyledNoDataCell = styled(TableCell)({
  textAlign: 'center',
})
const StyledDriverInfo = styled(Typography)(() => ({
  fontWeight: 650,
  fontSize: 24,
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  color: useConardTheme().palette.primary.main,
}))
const StyledHeadCell = styled(TableCell)(() => ({
  backgroundColor: useConardTheme().palette.primary.main,
  color: useConardTheme().palette.primary.contrastText,
}))
const StyledButton = styled(ConardButton)({
  borderRadius: 12,
})
const StyledTableRow = styled(TableRow)(() => ({
  backgroundColor: useConardTheme().palette.primary.main,
}))
