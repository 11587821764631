import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { createStyles, alpha, Grid, MenuItem, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  ContainerDtoContainerTypeEnum,
  DamageLogEntryDto,
  DamageLogEntryDtoDamageTypeSemitrailerEnum,
  DamageLogEntryDtoDamageTypeShippingContainerEnum,
  DamageLogEntryDtoSideEnum,
  TransitionDto,
} from '../../api'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import TouchAppIcon from '@material-ui/icons/TouchApp'
import ConardSelect from '../ConardSelect'
import DamageLogSideDraw from './DamageLogSideDraw'
import ConardButton from '../ConardButton'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import ConardPhotographer from '../ConardPhotographer'
import ConardTextField from '../ConardTextField'
import { useHistory } from 'react-router-dom'
import { addDamageLogEntry, setComingFromDamageLog } from '../../redux/transition/transitionSlice'
import { RootState, useAppDispatch } from '../../redux/store'
import { useSelector } from 'react-redux'
import ConardSwitch from '../ConardSwitch'

const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    container: {
      width: '100%',
      marginTop: '3vh',
      paddingBottom: '10vh',
    },
    damageDetailsGridItem: {
      width: '100%',
      minHeight: '110px',
    },
    insideSwitch: {
      width: '40%',
      minHeight: '110px',
    },
    damagePositionGridItem: {
      width: '90%',
      textAlign: 'center',
    },
    damagePositionHintGrid: {
      backgroundColor: theme.palette.secondary.main,
      padding: theme.spacing(5),
      borderRadius: 8,
    },
    grayPanel: {
      width: '95%',
      margin: 'auto',
      backgroundColor: alpha(theme.palette.secondary.light, 0.3),
      borderRadius: '8px',
    },
    drawingGridItem: {
      textAlign: 'center',
      padding: '10px',
    },
    photoPlaceHolderGridItem: (props) => ({
      width: '50%',
      height: '5vh',
      minHeight: '300px',
      borderSize: '2px',
      borderStyle: 'dashed',
      borderColor:
        props.submitError && props.submitError === FormSubmitError.Photo
          ? theme.palette.error.main
          : theme.palette.primary.main,
      marginBottom: '2vh',
      '& :hover': {
        cursor: 'pointer',
      },
    }),
    photoPlaceholderGrid: {
      height: '100%',
    },
    iluPhotoGridItem: {
      [theme.breakpoints.down('md')]: {
        marginTop: '2vh',
      },
    },
    iluPhotoImage: {
      maxWidth: '100%',
    },
    controls: {
      [theme.breakpoints.up('sm')]: {
        left: '10vw',
      },
      [theme.breakpoints.down('xs')]: {
        left: '0px',
      },
      position: 'fixed',
      bottom: 0,
      width: '100vw',
      backgroundColor: theme.palette.secondary.main,
      zIndex: 100,
    },
    submitButton: {
      width: '15vw',
      minWidth: '200px',
      marginTop: '2vh',
      marginBottom: '2vh',
      marginLeft: '5vw',
    },
    damageLogDialog: {
      minWidth: '600px',
    },
  })
)

interface StyleProps {
  submitError: FormSubmitError | null
}

enum FormSubmitError {
  DamageType = 1,
  Side = 2,
  Photo = 3,
  CausedDamageCz = 4,
  CausedDamageEn = 5,
}

interface DamageLogEntryFormProps {
  containerType: ContainerDtoContainerTypeEnum
}

const DamageLogEntryForm: FC<DamageLogEntryFormProps> = (props) => {
  const [formSubmitError, setFormSubmitError] = useState<FormSubmitError | null>(null)

  const { containerType } = props

  const { t } = useTranslation()
  const classes = useStyles({ submitError: formSubmitError })
  const history = useHistory()

  const transition = useSelector<RootState, TransitionDto>((state) => state.transition.transition)
  const dispatch = useAppDispatch()

  const [damageLogDrawState, setDamageLogDrawState] = useState<Map<DamageLogEntryDtoSideEnum, boolean>>(
    new Map([
      [DamageLogEntryDtoSideEnum.Left, false],
      [DamageLogEntryDtoSideEnum.Front, false],
      [DamageLogEntryDtoSideEnum.Right, false],
      [DamageLogEntryDtoSideEnum.Back, false],
    ])
  )
  const [damageLogEntry, setDamageLogEntry] = useState<DamageLogEntryDto>({
    side: DamageLogEntryDtoSideEnum.Left,
    inside: false,
    positionX: -1,
    positionY: -1,
    photo: '',
  })

  const [showPhotographer, setShowPhotographer] = useState(false)

  const { control, handleSubmit } = useForm<DamageLogEntryDto>()

  const onDamageTypeSelect = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (transition.container.containerType === ContainerDtoContainerTypeEnum.ShippingContainer) {
      setDamageLogEntry({
        ...damageLogEntry,
        damageTypeShippingContainer: event.target.value as DamageLogEntryDtoDamageTypeShippingContainerEnum,
      })
    } else {
      setDamageLogEntry({
        ...damageLogEntry,
        damageTypeSemitrailer: event.target.value as DamageLogEntryDtoDamageTypeSemitrailerEnum,
      })
    }

    setFormSubmitError(null)
  }

  const onDraw = (entry: DamageLogEntryDto) => {
    const newMap = new Map(damageLogDrawState)

    newMap.forEach((value, key) => {
      newMap.set(key, false)
    })

    newMap.set(entry.side, true)

    setDamageLogDrawState(newMap)
    setDamageLogEntry({
      ...damageLogEntry,
      side: entry.side,
      positionX: entry.positionX,
      positionY: entry.positionY,
    })

    setFormSubmitError(null)
  }

  const onDamagePhotoAccepted = (photo: string) => {
    setShowPhotographer(false)

    setDamageLogEntry({
      ...damageLogEntry,
      photo: photo,
    })

    setFormSubmitError(null)
  }

  const onChangeInside = (event: boolean) => {
    setDamageLogEntry({
      ...damageLogEntry,
      inside: event,
    })
  }

  useEffect(() => {
    return () => {
      dispatch(setComingFromDamageLog(true))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onSave = (values: DamageLogEntryDto) => {
    if (
      (damageLogEntry.damageTypeSemitrailer as string) === 'NOT-SELECTED' ||
      (damageLogEntry.damageTypeShippingContainer as string) === 'NOT-SELECTED' ||
      (damageLogEntry.damageTypeSemitrailer == null && damageLogEntry.damageTypeShippingContainer == null)
    ) {
      setFormSubmitError(FormSubmitError.DamageType)
      return
    }

    if (damageLogEntry.positionX === -1 || damageLogEntry.positionY === -1) {
      setFormSubmitError(FormSubmitError.Side)
      return
    }

    if (!damageLogEntry.photo) {
      setFormSubmitError(FormSubmitError.Photo)
      return
    }

    if (!values.causedDamageCz) {
      setFormSubmitError(FormSubmitError.CausedDamageCz)
      return
    }

    if (!values.causedDamageEn) {
      setFormSubmitError(FormSubmitError.CausedDamageEn)
      return
    }

    dispatch(
      addDamageLogEntry({
        ...damageLogEntry,
        note: values.note,
        causedDamageCz: values.causedDamageCz,
        causedDamageEn: values.causedDamageEn,
      })
    )
    history.goBack()
  }

  return (
    <>
      <Typography variant="h4" color="primary">
        {t('pages.damageLogManagement.labels.damageLogEntry.title')}
      </Typography>

      <form onSubmit={handleSubmit(onSave)}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          className={classes.container}
        >
          <Grid item xl={9} lg={9} md={12} sm={12} xs={12}>
            <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
              <Grid item className={classes.damageDetailsGridItem}>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}
                  className={classes.grayPanel}
                >
                  <Grid item>
                    <Typography variant="h6" color="primary">
                      {t('pages.damageLogManagement.labels.damageType.title')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {transition.container.containerType === ContainerDtoContainerTypeEnum.ShippingContainer && (
                      <ConardSelect
                        name="damageType"
                        label={t('entity.damageLog.fields.damageType')}
                        control={control}
                        defaultValue="NOT-SELECTED"
                        onChange={onDamageTypeSelect}
                        error={formSubmitError == FormSubmitError.DamageType}
                        helperText={
                          formSubmitError === FormSubmitError.DamageType
                            ? t('pages.damageLogManagement.validations.damageType.required')
                            : ''
                        }
                      >
                        <MenuItem key="NOT-SELECTED" value="NOT-SELECTED">
                          {t('any.notSelected')}
                        </MenuItem>
                        {Object.values(DamageLogEntryDtoDamageTypeShippingContainerEnum).map((value) => (
                          <MenuItem key={value} value={value}>
                            {t(`enum.damageTypeShippingContainer.${value}`)}
                          </MenuItem>
                        ))}
                      </ConardSelect>
                    )}

                    {transition.container.containerType === ContainerDtoContainerTypeEnum.Semitrailer && (
                      <ConardSelect
                        name="damageType"
                        label={t('entity.damageLog.fields.damageType')}
                        control={control}
                        defaultValue="NOT-SELECTED"
                        onChange={onDamageTypeSelect}
                        error={formSubmitError == FormSubmitError.DamageType}
                        helperText={
                          formSubmitError === FormSubmitError.DamageType
                            ? t('pages.damageLogManagement.validations.damageType.required')
                            : ''
                        }
                      >
                        <MenuItem key="NOT-SELECTED" value="NOT-SELECTED">
                          {t('any.notSelected')}
                        </MenuItem>
                        {Object.values(DamageLogEntryDtoDamageTypeSemitrailerEnum).map((value) => (
                          <MenuItem key={value} value={value}>
                            {t(`enum.damageTypeSemitrailer.${value}`)}
                          </MenuItem>
                        ))}
                      </ConardSelect>
                    )}
                  </Grid>
                  <Grid item className={classes.insideSwitch}>
                    <ConardSwitch
                      id="inside"
                      name="inside"
                      checked={damageLogEntry?.inside ?? false}
                      onChange={(event) => onChangeInside(event)}
                      control={control}
                      checkedLabel={t('entity.damageLog.fields.inside.checked')}
                      uncheckedLabel={t('entity.damageLog.fields.inside.unchecked')}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.damageDetailsGridItem}>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}
                  className={classes.grayPanel}
                >
                  <Grid item className={classes.damagePositionGridItem}>
                    <Typography variant="h6" color="primary">
                      {t('pages.damageLogManagement.labels.damagePosition.title')}
                    </Typography>
                  </Grid>
                  <Grid item className={classes.damagePositionGridItem}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      className={classes.damagePositionHintGrid}
                    >
                      <Grid item>
                        <TouchAppIcon color="primary" />
                      </Grid>
                      <Grid item>
                        <Typography variant="h6" color="primary">
                          {t('pages.damageLogManagement.labels.damagePosition.hint')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                      <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.drawingGridItem}>
                        <DamageLogSideDraw
                          side={DamageLogEntryDtoSideEnum.Left}
                          onClick={onDraw}
                          inside={damageLogEntry.inside}
                          containerType={containerType}
                          painted={damageLogDrawState.get(DamageLogEntryDtoSideEnum.Left) ?? false}
                          error={formSubmitError == FormSubmitError.Side}
                        />
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.drawingGridItem}>
                        <DamageLogSideDraw
                          side={DamageLogEntryDtoSideEnum.Right}
                          onClick={onDraw}
                          inside={damageLogEntry.inside}
                          containerType={containerType}
                          painted={damageLogDrawState.get(DamageLogEntryDtoSideEnum.Right) ?? false}
                          error={formSubmitError == FormSubmitError.Side}
                        />
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.drawingGridItem}>
                        <DamageLogSideDraw
                          side={DamageLogEntryDtoSideEnum.Back}
                          onClick={onDraw}
                          inside={damageLogEntry.inside}
                          containerType={containerType}
                          painted={damageLogDrawState.get(DamageLogEntryDtoSideEnum.Back) ?? false}
                          error={formSubmitError == FormSubmitError.Side}
                        />
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.drawingGridItem}>
                        <DamageLogSideDraw
                          side={DamageLogEntryDtoSideEnum.Front}
                          onClick={onDraw}
                          inside={damageLogEntry.inside}
                          containerType={containerType}
                          painted={damageLogDrawState.get(DamageLogEntryDtoSideEnum.Front) ?? false}
                          error={formSubmitError == FormSubmitError.Side}
                        />
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.drawingGridItem}>
                        <DamageLogSideDraw
                          side={DamageLogEntryDtoSideEnum.Top}
                          onClick={onDraw}
                          inside={damageLogEntry.inside}
                          containerType={containerType}
                          painted={damageLogDrawState.get(DamageLogEntryDtoSideEnum.Top) ?? false}
                          error={formSubmitError == FormSubmitError.Side}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.damageDetailsGridItem}>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}
                  className={classes.grayPanel}
                >
                  <Grid item>
                    <Typography variant="h6" color="primary">
                      {t('pages.damageLogManagement.labels.damagePhoto.title')}
                    </Typography>
                  </Grid>

                  {!damageLogEntry.photo && (
                    <Grid item className={classes.photoPlaceHolderGridItem}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        className={classes.photoPlaceholderGrid}
                        onClick={() => setShowPhotographer(true)}
                      >
                        <Grid item>
                          <ConardButton conardVariant="light">
                            <PhotoCameraIcon color="secondary" />
                            {t('pages.damageLogManagement.labels.damagePhoto.takePicture')}
                          </ConardButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  {!!damageLogEntry.photo && (
                    <Grid item>
                      <img src={damageLogEntry.photo} className={classes.iluPhotoImage} alt="" />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item className={classes.damageDetailsGridItem}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}
                  className={classes.grayPanel}
                >
                  <Grid item className={classes.damagePositionGridItem}>
                    <Typography variant="h6" color="primary">
                      {t('pages.damageLogManagement.labels.causedDamage.title')}
                    </Typography>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className={classes.damageDetailsGridItem}>
                    <ConardTextField
                      id="causedDamageCz"
                      name="causedDamageCz"
                      label={t('pages.damageLogManagement.labels.causedDamage.fields.cz')}
                      control={control}
                      error={formSubmitError == FormSubmitError.CausedDamageCz}
                      helperText={
                        formSubmitError === FormSubmitError.CausedDamageCz
                          ? t('pages.damageLogManagement.validations.causedDamage.required')
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className={classes.damageDetailsGridItem}>
                    <ConardTextField
                      id="causedDamageEn"
                      name="causedDamageEn"
                      label={t('pages.damageLogManagement.labels.causedDamage.fields.en')}
                      control={control}
                      error={formSubmitError == FormSubmitError.CausedDamageEn}
                      helperText={
                        formSubmitError === FormSubmitError.CausedDamageEn
                          ? t('pages.damageLogManagement.validations.causedDamage.required')
                          : ''
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.damageDetailsGridItem}>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}
                  className={classes.grayPanel}
                >
                  <Grid item className={classes.damagePositionGridItem}>
                    <Typography variant="h6" color="primary">
                      {t('pages.damageLogManagement.labels.note.title')}
                    </Typography>
                  </Grid>
                  <Grid item className={classes.damageDetailsGridItem}>
                    <ConardTextField
                      id="note"
                      name="note"
                      label={t('pages.damageLogManagement.labels.note.title')}
                      control={control}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xl={3} lg={3} md={12} sm={12} xs={12} className={classes.iluPhotoGridItem}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={4}
              className={classes.grayPanel}
            >
              <Grid item>
                <Typography variant="h6" color="primary">
                  {t('pages.damageLogManagement.labels.iluPhoto.title')}
                </Typography>
              </Grid>
              <Grid item>
                <img src={transition?.container.iluPhoto} className={classes.iluPhotoImage} alt="" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <ConardPhotographer
          title={t('pages.damageLogManagement.dialog.damagePhoto.title')}
          open={showPhotographer}
          onClose={() => setShowPhotographer(false)}
          onAccept={onDamagePhotoAccepted}
        />

        <div className={classes.controls}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
              <div className={classes.submitButton}>
                <ConardButton conardVariant="dark" type="submit">
                  {t('pages.damageLogManagement.buttons.addLog')}
                </ConardButton>
              </div>
            </Grid>
            <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
              <div className={classes.submitButton}>
                <ConardButton conardVariant="dark" onClick={() => history.goBack()}>
                  {t('any.buttons.back')}
                </ConardButton>
              </div>
            </Grid>
          </Grid>
        </div>
      </form>
    </>
  )
}

export default DamageLogEntryForm
