import React, { FC } from 'react'
import { ConardDateTimePicker, ConardDateTimePickerProps } from '../ConardDateTimePicker'
import { Controller, useFormContext } from 'react-hook-form'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

type Props = Omit<ConardDateTimePickerProps, 'onChange' | 'value'> & {
  name: string
} & Partial<Pick<ConardDateTimePickerProps, 'onChange'>>

export const RHFDatePicker: FC<Props> = ({ name, disabled, onChange: onChangeFromProps, ...rest }) => {
  const { control, errors } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={({ onBlur, onChange, value }) => {
        return (
          <ConardDateTimePicker
            disabled={disabled}
            error={!!errors?.[name]}
            helperText={errors?.[name]?.message}
            onChange={(date: MaterialUiPickersDate) => {
              onChange(date)
              onChangeFromProps?.(date)
            }}
            onBlur={onBlur}
            value={value}
            {...rest}
          />
        )
      }}
    />
  )
}
