import React from 'react'
import { DriversList } from './DriversList'
import { useCarrierId } from '../../../context/CarrierContext'
import { useHistory } from 'react-router-dom'

export const DriversPage = () => {
  const history = useHistory()
  const carrierId = useCarrierId()

  const onAddDriver = () => {
    history.push('/driver/create')
  }

  const handleEditDriver = (driverId: number) => {
    history.push(`driver/update/${driverId}`)
  }

  return <DriversList carrierId={carrierId ?? 0} onAddDriver={onAddDriver} onEditDriver={handleEditDriver} />
}
