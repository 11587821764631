import React from 'react'
import { AccordionSummary, AccordionSummaryProps, styled } from '@mui/material'
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp'
import { useConardTheme } from '../../../hooks/useConardTheme'

export const KioskAccordionSummary = styled((props: AccordionSummaryProps) => (
  <AccordionSummary expandIcon={<KeyboardArrowRightSharpIcon />} {...props} />
))(() => ({
  fontSize: '24px',
  fontWeight: 650,
  color: useConardTheme().palette.primary.main,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
}))
