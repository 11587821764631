import { AxiosPromise } from 'axios'
import { ContainerApi, ContainerDto, ContainerSearchDto, DamageLogEntryDto, PageContainerDto } from '../api'
import myAxios from '../config/axios/axios'

let api: ContainerApi

export const setContainerApiConfiguration = () => {
  api = new ContainerApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const containerService = {
  search: (
    page?: number,
    size?: number,
    sort?: string[],
    searchDto?: ContainerSearchDto
  ): AxiosPromise<PageContainerDto> => {
    return api.containerSearch(page, size, sort, searchDto)
  },

  getAllDamages: (id: number): AxiosPromise<Set<DamageLogEntryDto>> => {
    return api.containerGetAllDamages(id)
  },

  findById: (id: number): AxiosPromise<ContainerDto> => {
    return api.containerFindById(id)
  },
}

export default containerService
