import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import React, { FC, useState } from 'react'
import MainMenuButtonItems from './MainMenuButtonItems'
import MainMenuUserArea from './MainMenuUserArea'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { BuildProperties } from '../../api'
import { useTranslation } from 'react-i18next'
import ConardButton from '../../components/ConardButton'
import authorsImage from '../../assets/images/authors2.jpg'
import { useConardLogo } from '../../hooks/useConardLogo'

const useStyles = makeStyles(() =>
  createStyles({
    drawerGrid: {
      flex: 1,
    },
    drawerGridItem: {
      width: '100%',
      textAlign: 'center',
    },
    version: {
      paddingBottom: '1vh',
    },
    drawerBottomGridItem: {
      width: '100%',
      flexGrow: 1,
    },
    dialog: {
      maxWidth: '1072px',
    },
  })
)

interface MainMenuProps {
  onClick: () => void
}

const MainMenu: FC<MainMenuProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { onClick } = props
  const logo = useConardLogo()

  const appInfo = useSelector<RootState, BuildProperties>((state) => state.appInfo.info)

  const [showAuthors, setShowAuthors] = useState(false)

  const authorsEasterEgg = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    if (event.ctrlKey && event.altKey) {
      setShowAuthors(true)
    }
  }

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        className={classes.drawerGrid}
        onClick={onClick}
        onKeyDown={onClick}
      >
        <Grid item className={classes.drawerGridItem}>
          {logo}
          <Typography variant="subtitle2" color="secondary" className={classes.version} onClick={authorsEasterEgg}>
            {t('any.version.label', { version: appInfo.version })}
          </Typography>
          <Divider light />
        </Grid>

        <Grid item className={classes.drawerGridItem}>
          <MainMenuButtonItems />
        </Grid>

        <Grid item className={classes.drawerBottomGridItem}>
          <MainMenuUserArea />
        </Grid>
      </Grid>
      <Dialog classes={{ paper: classes.dialog }} open={showAuthors} onClose={() => setShowAuthors(false)}>
        <DialogTitle>{t('authors.title')}</DialogTitle>
        <DialogContent>
          <Grid container direction="column" justifyContent="flex-start" alignItems="center">
            <Grid item>
              <img src={authorsImage} style={{ width: '32vw', height: '31vw' }} alt="" />
            </Grid>
            <Grid item>
              <Typography>David &apos;Koule do huby&apos; Růžička</Typography>
            </Grid>
            <Grid item>
              <Typography>Petr &apos;Šturm&apos; Vala</Typography>
            </Grid>
            <Grid item>
              <Typography>Adam &apos;Arnold&apos;s Bitch&apos; Tobiška</Typography>
            </Grid>
            <Grid item>
              <Typography>Marek &apos;Budamára&apos; Bulant</Typography>
            </Grid>
            <Grid item>
              <Typography>Martin &apos;Superstar&apos; Hvězda</Typography>
            </Grid>
            <Grid item>
              <Typography>Adrian &apos;Peaky Blinder&apos; Veselka</Typography>
            </Grid>
            <Grid item>
              <Typography>Marek &apos;Ogre&apos; Ogrodník</Typography>
            </Grid>
            <Grid item>
              <Typography>Jenda &apos;Johny&apos; Bartoň</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ConardButton conardVariant="dark" onClick={() => setShowAuthors(false)}>
            {t('any.buttons.ok')}
          </ConardButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MainMenu
