import React, { FC, useEffect, useState } from 'react'
import { CdCargoTemplateDto } from '../../../api'
import { useParams } from 'react-router-dom'
import { Mode } from '../../../enums/Mode'
import CdCargoExportConfigurationEditForm from './CdCargoExportConfigurationEditForm'
import cdCargoExportConfigurationService from '../../../services/CdCargoExportConfigurationService'

interface UrlParamTypes {
  id?: string
}

const CdCargoExportConfigurationUpdatePage: FC = () => {
  const { id } = useParams<UrlParamTypes>()

  const [config, setConfig] = useState<CdCargoTemplateDto>()
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (id !== undefined) {
      cdCargoExportConfigurationService
        .findById(Number.parseInt(id))
        .then((response) => {
          setConfig(response.data)
          setLoaded(true)
        })
        .catch(() => {
          setLoaded(true)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>{loaded && <CdCargoExportConfigurationEditForm mode={Mode.Update} exportConfig={config} />}</>
}

export default CdCargoExportConfigurationUpdatePage
