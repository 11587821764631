import React, { FC, useEffect, useState } from 'react'
import { TrainDto, TrainExportConfigurationDto } from '../../api'
import { alpha, createStyles, Grid, MenuItem, Paper, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ConardSelect from '../ConardSelect'
import ConardButton from '../ConardButton'
import trainExportConfigurationService from '../../services/TrainExportConfigurationService'
import { useForm } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import { Download } from '@mui/icons-material'
import trainGateOutService from '../../services/TrainGateOutService'
import trainService from '../../services/TrainService'

const useStyles = makeStyles((theme) =>
  createStyles({
    gridItem: {
      marginBottom: '10px',
    },
    paper: {
      backgroundColor: alpha(theme.palette.secondary.light, 0.3),
      padding: '2vh 2vw 2vh 2vw',
      width: '100%',
    },
  })
)

interface TrainExportBoxProps {
  train: TrainDto
}

const TrainExportBox: FC<TrainExportBoxProps> = (props) => {
  const { train } = props
  const { t } = useTranslation()
  const classes = useStyles()

  const [configurations, setConfigurations] = useState<TrainExportConfigurationDto[] | undefined>(undefined)
  const [configurationId, setConfigurationId] = useState<string>()

  const { control } = useForm<TrainDto>()

  const handleDownloadCimTrainFile = () => {
    trainGateOutService
      .downloadCimTrainFile(Number.parseInt(configurationId || '-1'), train?.id || -1)
      .then((response) => {
        const url = window.URL.createObjectURL(response.data)
        const a = document.createElement('a')
        a.href = url
        a.download = 'cimTrainFile_' + train?.id || -1 + '.xlsx'
        a.click()
      })
      .catch()
  }

  const handleDownloadWagonReport = (values: TrainDto) => {
    trainService.update(train.id ?? 0, values)
    trainGateOutService
      .downloadWagonReport(Number.parseInt(configurationId || '-1'), train?.id || -1)
      .then((response) => {
        const url = window.URL.createObjectURL(response.data)
        const a = document.createElement('a')
        a.href = url
        a.download = 'wagonReport_' + train?.id || -1 + '.xlsx'
        a.click()
      })
      .catch()
  }

  useEffect(() => {
    trainExportConfigurationService
      .findAll(undefined, 200, 'configurationName', 'ASC')
      .then((response) => {
        setConfigurations(response.data.content)
      })
      .catch()
  }, [])

  return (
    <Paper elevation={0} className={classes.paper}>
      <Grid container>
        <Grid item xs={12} className={classes.gridItem}>
          <Typography variant="h4" color="primary">
            {t('pages.gateOut.headers.trainExport.title')}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          <Typography variant="h6" color="primary">
            {t('pages.gateOut.headers.trainExport.selectConfiguration')}
          </Typography>
          <ConardSelect
            name="configurationName"
            control={control}
            defaultValue="0"
            value={configurationId}
            onChange={(event) => setConfigurationId(event.target.value)}
          >
            <MenuItem value="0">{t('any.notSelected')}</MenuItem>
            {configurations?.map((conf) => (
              <MenuItem key={conf.id} value={conf.id}>
                {conf.configurationName}
              </MenuItem>
            ))}
          </ConardSelect>
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          <ConardButton
            startIcon={<Download />}
            conardVariant="light"
            disabled={configurationId === undefined || configurationId === '0'}
            onClick={handleDownloadCimTrainFile}
          >
            {t('pages.gateOut.buttons.download') + ' - CIM train file'}
          </ConardButton>
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          <ConardButton
            startIcon={<Download />}
            conardVariant="light"
            disabled={configurationId === undefined || configurationId === '0'}
            onClick={() => handleDownloadWagonReport(train)}
          >
            {t('pages.gateOut.buttons.download') + ' - Wagon report'}
          </ConardButton>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default TrainExportBox
