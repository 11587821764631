import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { ConardTextField, ConardTextFieldProps } from '../ConardTextField'

type Props = Omit<ConardTextFieldProps, 'helperText'> & {
  name: string
}

export const RHFTextField: React.FC<Props> = ({ name, ...rest }) => {
  const {
    control,
    errors,
    formState: { isSubmitting },
  } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={({ onBlur, onChange, value }) => {
        return (
          <ConardTextField
            disabled={isSubmitting || rest.disabled}
            error={!!errors?.[name]}
            helperText={errors?.[name]?.message}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            {...rest}
          />
        )
      }}
    />
  )
}
