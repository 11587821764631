import React, { FC, useEffect } from 'react'

import { toast, ToastClassName, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import notificationService from '../../services/NotificationService'
import { makeStyles } from '@material-ui/core/styles'
import { Box, createStyles } from '@material-ui/core'
import {
  GateDtoGateTypeEnum,
  NotificationDto,
  NotificationDtoNotificationTypeEnum,
  PageNotificationDto,
  UserDtoRoleEnum,
} from '../../api'
import { NotificationButtonsByNotificationType } from './NotificationButtonsByNotificationType'
import { setNotifications } from '../../redux/notification/notificationSlice'
import { RootState, store, useAppDispatch } from '../../redux/store'
import NotificationBody from './NotificationBody'
import { useSelector } from 'react-redux'
import { useConardAuth } from '../../hooks/useConardAuth'

const borderSize = '5px solid'
const borderRadiusSize = '4px'

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonsBox: {
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'space-evenly',
    },
    toastContainer: {
      minWidth: 600,
    },
    toastWrapperGate1: {
      borderRadius: borderRadiusSize,
      border: borderSize + '#f6d347',
    },
    toastWrapperGate2In: {
      borderRadius: borderRadiusSize,
      border: borderSize + `${theme.palette.primary.main}`,
    },
    toastWrapperGate2Out: {
      borderRadius: borderRadiusSize,
      border: borderSize + '#67f647',
    },
    toastWrapperGate3: {
      borderRadius: borderRadiusSize,
      border: borderSize + '#f68b47',
    },
    toastWrapperGreenPass: {
      borderRadius: borderRadiusSize,
      border: '3px dashed' + `${theme.palette.primary.main}`,
    },
    toastProgressBar: {
      margin: 2,
      borderRadius: borderRadiusSize,
      background: theme.palette.primary.main,
    },
  })
)

const NotificationToastContainer: FC = () => {
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const auth = useConardAuth()

  const notificationsDisabled = useSelector<RootState, boolean>((state) => state.notification.notificationsDisabled)

  const backgroundColorByNotificationType = (notification: NotificationDto) => {
    if (
      new Set([
        NotificationDtoNotificationTypeEnum.SuccessfulGreenPass,
        NotificationDtoNotificationTypeEnum.FailedGreenPass,
      ]).has(notification.notificationType)
    ) {
      return classes.toastWrapperGreenPass as ToastClassName
    }

    switch (notification.gate?.gateNumber) {
      case 1:
        return classes.toastWrapperGate1 as ToastClassName
      case 2:
        if (notification.gate?.gateType === GateDtoGateTypeEnum.In) {
          return classes.toastWrapperGate2In as ToastClassName
        }
        return classes.toastWrapperGate2Out as ToastClassName
      case 3:
        return classes.toastWrapperGate3 as ToastClassName
    }

    return undefined
  }

  const toastBody = (notification: NotificationDto) => (
    <>
      <NotificationBody notification={notification} />
      <Box className={classes.buttonsBox}>
        <NotificationButtonsByNotificationType notification={notification} refreshList={checkNewNotifications} />
      </Box>
    </>
  )

  const createToast = (notification: NotificationDto) => {
    toast(toastBody(notification), {
      toastId: notification.id,
      position: 'bottom-right',
      autoClose: 60000,
      closeOnClick: false,
      pauseOnHover: false,
      className: `${backgroundColorByNotificationType(notification)}`,
      progressClassName: classes.toastProgressBar,
    })
  }

  const loadNewNotifications = (oldNotifications: NotificationDto[], newNotificationPage: PageNotificationDto) => {
    const newNotifications = newNotificationPage.content ?? []

    if (
      oldNotifications.length !== newNotifications.length ||
      oldNotifications.some((notification, index) => notification.id !== newNotifications[index].id)
    ) {
      dispatch(setNotifications(newNotificationPage))
    }
  }

  const checkNewNotifications = () => {
    const notifications = store.getState().notification.notifications?.content ?? []

    if (auth.getRole() === UserDtoRoleEnum.DispatcherRoad) {
      notificationService.findAllForRoad(undefined, undefined, ['createdAt,desc']).then((response) => {
        loadNewNotifications(notifications, response.data)
      })
      notificationService.getNewForRoadDispatcher().then((response) => {
        if (response.data) {
          response.data.map((notification) => {
            createToast(notification)
          })
        }
      })
    } else if (auth.getRole() === UserDtoRoleEnum.DispatcherTrain) {
      notificationService.findAllForTrain(undefined, undefined, ['createdAt,desc']).then((response) => {
        loadNewNotifications(notifications, response.data)
      })
      notificationService.getNewForTrainDispatcher().then((response) => {
        if (response.data) {
          response.data.map((notification) => {
            createToast(notification)
          })
        }
      })
    } else {
      notificationService.search(undefined, undefined, ['createdAt,desc'], { closedAt: undefined }).then((response) => {
        loadNewNotifications(notifications, response.data)
      })
      notificationService.getNewForDispatcher().then((response) => {
        if (response.data) {
          response.data.map((notification) => {
            createToast(notification)
          })
        }
      })
    }
  }

  useEffect(() => {
    if (!notificationsDisabled) {
      checkNewNotifications()

      const intervalId = setInterval(() => {
        checkNewNotifications()
      }, 5000)

      return () => {
        clearInterval(intervalId)
      }
    } else {
      toast.dismiss()
    }
  }, [notificationsDisabled]) // eslint-disable-line react-hooks/exhaustive-deps

  return <ToastContainer className={classes.toastContainer} pauseOnFocusLoss={false} limit={5} />
}

export default NotificationToastContainer
