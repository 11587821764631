import React from 'react'
import { CheckboxProps } from '@material-ui/core/Checkbox/Checkbox'
import { FC } from 'react'
import { Controller } from 'react-hook-form'
import { Control } from 'react-hook-form/dist/types/form'
import { Checkbox } from '@material-ui/core'

interface ConardCheckBoxProps {
  name: string
  control: Control
}

type ConardCheckBoxPropsUnited = ConardCheckBoxProps & Omit<CheckboxProps, 'name' | 'value' | 'checked' | 'onBlur'>

export const ConardCheckbox: FC<ConardCheckBoxPropsUnited> = (props) => {
  const { name, control, onChange: onChangeFromProps, ...checkboxProps } = props

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={props.defaultChecked ?? false}
      render={({ onChange, onBlur, value }) => (
        <Checkbox
          name={name}
          checked={value}
          onBlur={onBlur}
          onChange={(e, checked) => {
            onChange(e.target.checked)
            onChangeFromProps?.(e, checked)
          }}
          {...checkboxProps}
        />
      )}
    />
  )
}

export default ConardCheckbox
