import React, { FC } from 'react'
import { ContainerDtoContainerTypeEnum, TransitionSearchListDtoContainerTypeEnum } from '../api'
import { TransitionSearchData, renderContainerType } from '../utils/utils'
import { ContainerDamagedIcon } from '../assets/icons/ContainerDamagedIcon'
import { SemitrailerDamagedIcon } from '../assets/icons/SemitrailerDamagedIcon'
import { ContainerIcon } from '../assets/icons/ContainerIcon'
import { SemitrailerIcon } from '../assets/icons/SemitrailerIcon'
import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core'
import ConardTooltip from './ConardTooltip'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) =>
  createStyles({
    gridContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    containerType: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontFamily: 'Arial',
      fontSize: '0.8rem',
    },
  })
)

interface ConardTransitionSearchIconProps {
  transitionSearchListDto?: TransitionSearchData
  damaged: boolean
  containerType: TransitionSearchListDtoContainerTypeEnum | ContainerDtoContainerTypeEnum | undefined
}

const ConardTransitionSearchIcon: FC<ConardTransitionSearchIconProps> = (props) => {
  const { transitionSearchListDto, damaged, containerType } = props
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <ConardTooltip title={t('pages.gateIn.form.damaged.' + (damaged ? 'checked' : 'unchecked'))}>
      <Grid container className={classes.gridContainer}>
        <Grid item>
          <Typography className={classes.containerType}>{renderContainerType(transitionSearchListDto)}</Typography>
        </Grid>
        <Grid item>
          {damaged ? (
            containerType === TransitionSearchListDtoContainerTypeEnum.ShippingContainer ? (
              <ContainerDamagedIcon />
            ) : (
              <SemitrailerDamagedIcon />
            )
          ) : containerType === TransitionSearchListDtoContainerTypeEnum.ShippingContainer ? (
            <ContainerIcon />
          ) : (
            <SemitrailerIcon />
          )}
        </Grid>
      </Grid>
    </ConardTooltip>
  )
}

export default ConardTransitionSearchIcon
