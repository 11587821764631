import React, { FC } from 'react'
import { Box, Grid } from '@mui/material'
import { IconButton, Typography, makeStyles } from '@material-ui/core'
import CancelSharpIcon from '@material-ui/icons/CancelSharp'
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined'
import { useTranslation } from 'react-i18next'
import ConardTooltip from '../../components/ConardTooltip'
import { useTerminalLayoutContext } from '../../hooks/useTerminalLayoutContext'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { useTerminalTemporaryDrawer } from '../../hooks/useTerminalTemporaryDrawerContext'
import { ContainerIcon } from '../../assets/icons/ContainerIcon'
import { ContainerForPlacement } from '../../context/containerPlacementTypes'

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '280px',
    minWidth: '280px',
    maxWidth: '280px',
    backgroundColor: 'white',
    position: 'absolute',
    right: '50px',
    bottom: '8vh',
    boxShadow: '5px 10px 18px rgba(26,24,26,0.3)',
    borderRadius: '2px',
    overflowY: 'auto',
    maxHeight: 'calc(60vh - 100px)',
  },
  containerGrid: {
    width: '230px',
    minWidth: '230px',
    height: '140px',
    backgroundColor: '#ccc8c8e9',
    marginBottom: 3,
  },
  selectedContainer: {
    backgroundColor: '#AFE1AF',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  hintWrapper: {
    marginTop: '7px',
  },
  hint: {
    fontSize: '12px',
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '10px',
  },
  icons: {
    display: 'flex',
    marginTop: '30px',
    scale: 1.3,
    zIndex: 10,
  },
  containerType: {
    fontSize: '12px',
  },
  containerInfo: {
    scale: 8,
    color: theme.palette.primary.main,
    marginTop: '30px',
  },
  iluCodeDiv: {
    position: 'absolute',
    marginTop: '13px',
    backgroundColor: '#ccc8c8',
    zIndex: 2,
    width: '130px',
    height: '48px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedIluCodeDiv: {
    position: 'absolute',
    marginTop: '13px',
    backgroundColor: '#AFE1AF',
    zIndex: 2,
    width: '130px',
    height: '48px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iluCode: {
    fontSize: '20px',
    fontWeight: 600,
  },
}))

export const TemporaryStore: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { setMode } = useTerminalLayoutContext()
  const { containers, removeContainer, selectContainer, resetSelectedContainer, getSelectedContainer } =
    useTerminalTemporaryDrawer()

  const handleStopChoosingCoordinates = () => {
    resetSelectedContainer()
    setMode('openStack')
  }

  const handleNewCoordinates = (container: ContainerForPlacement) => {
    setMode('chooseCoordinates')
    selectContainer(container)
  }

  const selectedContainerId = getSelectedContainer()?.id

  return (
    <Grid className={classes.drawer} container direction="row" justifyContent="center" spacing={2}>
      <Grid item className={classes.header}>
        <Typography variant="h6" color="primary">
          {t('pages.terminalLayout.containersToMove')}
        </Typography>
        <Box className={classes.hintWrapper}>
          <Typography className={classes.hint}>
            {selectedContainerId ? `${t('pages.terminalLayout.chooseNewLocation')}` : ''}
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        {!containers.length && <div>{t('pages.terminalLayout.noContainersToMove')}</div>}
        {containers.map((container) => (
          <Box
            key={container.id}
            className={`${classes.containerGrid} ${
              selectedContainerId === container.id ? classes.selectedContainer : ''
            }`}
          >
            <Box className={classes.container}>
              <ContainerIcon className={classes.containerInfo} />
              <div className={selectedContainerId ? classes.selectedIluCodeDiv : classes.iluCodeDiv}>
                <Typography className={classes.containerType}>{`TEU ${container.teu}`}</Typography>
                <Typography className={classes.iluCode}>{container.iluCode}</Typography>
              </div>

              <Box className={classes.icons}>
                <ConardTooltip title={t('pages.terminalLayout.tooltip.newCoordinates')}>
                  <IconButton
                    onClick={() => {
                      handleNewCoordinates(container)
                    }}
                  >
                    <AddLocationAltOutlinedIcon />
                  </IconButton>
                </ConardTooltip>
                {selectedContainerId === container.id ? (
                  <ConardTooltip title={t('pages.terminalLayout.tooltip.cancelChooseCoordinates')}>
                    <IconButton onClick={handleStopChoosingCoordinates}>
                      <CloseOutlinedIcon color="inherit" />
                    </IconButton>
                  </ConardTooltip>
                ) : (
                  <ConardTooltip title={t('pages.terminalLayout.tooltip.returnContainer')}>
                    <IconButton
                      onClick={() => {
                        removeContainer(container.id)
                      }}
                    >
                      <CancelSharpIcon color="primary" />
                    </IconButton>
                  </ConardTooltip>
                )}
              </Box>
            </Box>
          </Box>
        ))}
      </Grid>
    </Grid>
  )
}
