import { Grid, MenuItem, makeStyles, createStyles } from '@material-ui/core'
import React, { FC, useEffect, useState } from 'react'
import ConardTextField from '../ConardTextField'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import ConardSelect from '../ConardSelect'
import { CompanyDto, ShipownerDto, TransitionSearchListDto } from '../../api'
import ConardButton from '../ConardButton'
import companyService from '../../services/CompanyService'
import shipownerService from '../../services/ShipownerService'

const useStyles = makeStyles(() =>
  createStyles({
    searchButton: {
      width: '15vw',
      minWidth: '300px',
      height: '5vh',
      minHeight: '50px',
      textAlign: 'center',
      margin: '20px',
      float: 'right',
    },
    colorDiv: {
      width: '10px',
      height: '10px',
      borderRadius: '100%',
      marginLeft: '10px',
    },
    companySelectItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  })
)

interface GateOutFilterProps {
  gateOutFilter: (data: TransitionSearchListDto) => void
}

export const GateOutFilter: FC<GateOutFilterProps> = ({ gateOutFilter }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { handleSubmit, control } = useForm<TransitionSearchListDto>({})

  const [companies, setCompanies] = useState<CompanyDto[] | undefined>(undefined)
  const [shipowners, setShipowners] = useState<ShipownerDto[] | undefined>(undefined)

  const [selectCompany, setSelectCompany] = useState('NOT-SELECTED')
  const [selectShipowner, setSelectShowner] = useState('NOT-SELECTED')
  const [selectRepleted, setSelectRepleted] = useState('NOT-SELECTED')

  const getAllCompanies = () => {
    companyService.findAll(0, 100, '', '').then((response) => {
      setCompanies(response.data.content)
    })
  }

  const getAllShipowners = () => {
    shipownerService.findAll(undefined, undefined, undefined).then((response) => {
      setShipowners(response.data.content)
    })
  }

  useEffect(() => {
    getAllCompanies()
    getAllShipowners()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form onSubmit={handleSubmit(gateOutFilter)}>
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardTextField
            id="container.iluCode"
            name="container.iluCode"
            label={t('pages.gateIn.form.iluCode.label')}
            defaultValue=""
            control={control}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardSelect
            id="container.company.id"
            name="container.company.id"
            label={t('entity.company.fields.name')}
            defaultValue="NOT-SELECTED"
            value={selectCompany}
            onChange={(event) => setSelectCompany(event.target.value)}
            control={control}
          >
            <MenuItem value="NOT-SELECTED">{t('pages.common.select.all')}</MenuItem>
            {companies?.map((company) => (
              <MenuItem key={company.id} value={company.id}>
                <div className={classes.companySelectItem}>
                  {company.name}
                  <div style={{ backgroundColor: company.color }} className={classes.colorDiv} />
                </div>
              </MenuItem>
            ))}
          </ConardSelect>
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardSelect
            id="container.shipowner.id"
            name="container.shipowner.id"
            label={t('pages.gateIn.form.shipowner.label')}
            defaultValue="NOT-SELECTED"
            value={selectShipowner}
            onChange={(event) => setSelectShowner(event.target.value)}
            control={control}
          >
            <MenuItem value="NOT-SELECTED">{t('pages.common.select.all')}</MenuItem>
            {shipowners?.map((shipowner) => (
              <MenuItem key={shipowner.id} value={shipowner.id}>
                {shipowner.name}
              </MenuItem>
            ))}
          </ConardSelect>
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardSelect
            id="repleted"
            name="repleted"
            label={t('pages.gateIn.form.repleted.checked') + ' / ' + t('pages.gateIn.form.repleted.unchecked')}
            defaultValue="NOT-SELECTED"
            value={selectRepleted}
            onChange={(event) => setSelectRepleted(event.target.value)}
            control={control}
          >
            <MenuItem value="NOT-SELECTED">{t('pages.common.select.all')}</MenuItem>
            <MenuItem value="true">{t('pages.gateIn.form.repleted.checked')}</MenuItem>
            <MenuItem value="false">{t('pages.gateIn.form.repleted.unchecked')}</MenuItem>
          </ConardSelect>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className={classes.searchButton}>
            <ConardButton conardVariant="light" type="submit">
              {t('any.buttons.search')}
            </ConardButton>
          </div>
        </Grid>
      </Grid>
    </form>
  )
}
