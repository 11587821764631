import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ContainerDtoContainerTypeEnum } from '../../api'
import { SemitrailerFillIcon } from '../../assets/icons/SemitrailerFillIcon'
import { SemitrailerIcon } from '../../assets/icons/SemitrailerIcon'
import { ContainerFillIconAlt } from '../../assets/icons/ContainerFillIconAlt'
import { ContainerIcon } from '../../assets/icons/ContainerIcon'
import ConardTooltip from '../ConardTooltip'

interface ConardTrainContainerIconProps {
  containerType: string
  repleted: boolean
}

const ConardTrainContainerIcon: FC<ConardTrainContainerIconProps> = ({ containerType, repleted }) => {
  const { t } = useTranslation()

  const icon = useMemo(() => {
    if (containerType == ContainerDtoContainerTypeEnum.ShippingContainer) {
      return repleted ? <ContainerFillIconAlt /> : <ContainerIcon />
    }
    return repleted ? <SemitrailerFillIcon /> : <SemitrailerIcon />
  }, [containerType, repleted])

  return (
    <>
      {containerType && (
        <ConardTooltip
          title={`${t('enum.containerType.' + containerType)} - 
        ${t('pages.gateIn.form.repleted.' + (repleted ? 'checked' : 'unchecked'))}`}
        >
          {icon}
        </ConardTooltip>
      )}
    </>
  )
}

export default ConardTrainContainerIcon
