import { alpha, createTheme } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'

export const developTheme = createTheme({
  palette: {
    primary: {
      main: '#c0701f',
      light: '#f4ece6',
      dark: '#826543',
    },
    secondary: {
      main: '#FFFFFF',
      light: '#C2DCE2',
      dark: '#B3C6D0',
    },
    background: {
      default: '#f3e0d8',
    },
    error: {
      main: '#cc0505',
      light: '#f16b6b',
    },
    info: {
      main: '#e7c495',
      light: '#e6daca',
      dark: '#c2874c',
    },
    warning: {
      main: '#49ff20',
      light: '#e4ffca',
    },
    success: {
      main: '#00953C',
      light: '#e6f4ec',
      dark: '#438255',
    },
    grey: grey,
  },
  typography: {
    h4: {
      fontWeight: 500,
    },
  },
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#da7106',
      },
    },
    MuiDialog: {
      root: {
        minWidth: 300,
        backgroundColor: '#f4e6ea',
      },
    },
    MuiDivider: {
      light: {
        backgroundColor: alpha('#FFFFFF', 0.3),
      },
    },
    MuiToolbar: {
      root: {
        backgroundColor: '#da7106',
      },
    },
    MuiTablePagination: {
      toolbar: {
        backgroundColor: '#FFFFFF',
      },
      menuItem: {
        color: '#000000',
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: '#da7106',
          fontWeight: 'bold',
        },
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: '#da7106',
      },
    },
    MuiTableCell: {
      head: {
        color: 'white',
      },
    },
    MuiTableSortLabel: {
      active: {
        color: 'white !important',
      },
      icon: {
        color: 'white !important',
      },
    },
  },
})
