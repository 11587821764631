import React, { FC } from 'react'
import {
  Box,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core'
import ConardButton from '../../components/ConardButton'
import { useTranslation } from 'react-i18next'
import { TruckMonitoringLogDto, TruckMonitoringLogDtoCheckpointEnum } from '../../api'
import ConardDateTimePicker from '../../components/ConardDateTimePicker'
import ConardTextField from '../../components/ConardTextField'
import { useForm } from 'react-hook-form'
import { cleanObject } from '../../utils/utils'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

const useStyles = makeStyles(() =>
  createStyles({
    dialogActions: {
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 16,
    },
  })
)

export interface TruckMonitoringDialogProps {
  open: boolean
  truckLog: TruckMonitoringLogDto | undefined
  checkpoint: TruckMonitoringLogDtoCheckpointEnum
  handleClose: () => void
  handleCreateUpdate: (log: TruckMonitoringLogDto) => void
}

const TruckMonitoringDialog: FC<TruckMonitoringDialogProps> = ({
  open,
  handleClose,
  truckLog,
  handleCreateUpdate,
  checkpoint,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const yupSchema = !!truckLog
    ? yup.object().shape({})
    : yup.object().shape({
        licensePlate: yup
          .string()
          .required(t('pages.licensePlate.validation.licensePlate.required'))
          .min(5, t('pages.gateIn.validations.semitrailerLicencePlate.min', { min: 5 }))
          .max(10, t('pages.gateIn.validations.semitrailerLicencePlate.max', { max: 10 })),
      })

  const { control, handleSubmit, errors } = useForm<TruckMonitoringLogDto>({ resolver: yupResolver(yupSchema) })

  const onSubmit = (log: TruckMonitoringLogDto) => {
    log = {
      ...cleanObject(log),
      ...cleanObject(truckLog),
      checkpoint,
    }

    handleCreateUpdate(
      cleanObject({
        ...log,
        arrivedAt: new Date(log.arrivedAt).toISOString(),
        leftAt: log.leftAt ? new Date(log.leftAt).toISOString() : undefined,
      })
    )
  }

  return (
    <Dialog onSubmit={handleSubmit(onSubmit)} open={open}>
      <form>
        <DialogTitle>
          {!!truckLog ? t('pages.truckMonitoring.dialog.confirmLeave') : t('pages.common.button.create')}
        </DialogTitle>
        <DialogContent>
          <Typography>
            <ConardTextField
              id="licensePlate"
              name="licensePlate"
              defaultValue={truckLog?.licensePlate ?? ''}
              label={t('entity.ticket.fields.licencePlate')}
              control={control}
              disabled={!!truckLog}
              error={!!errors.licensePlate}
              helperText={errors.licensePlate ? errors.licensePlate?.message : ' '}
            />
            {!truckLog && (
              <ConardDateTimePicker
                name="arrivedAt"
                control={control}
                defaultValue={new Date()}
                label={t('entity.ticket.fields.arrivedAt')}
                helperText=" "
              />
            )}
            {!!truckLog && (
              <ConardDateTimePicker
                name="leftAt"
                control={control}
                defaultValue={new Date()}
                label={t('entity.ticket.fields.leaveAt')}
                minDate={truckLog.arrivedAt ?? undefined}
                helperText=" "
              />
            )}
          </Typography>
        </DialogContent>
        <DialogActions disableSpacing className={classes.dialogActions}>
          <Box>
            <ConardButton conardVariant="transparent" onClick={handleClose}>
              {t('any.buttons.back')}
            </ConardButton>
          </Box>
          <Box>
            <ConardButton conardVariant="dark" type="submit">
              {!!truckLog ? t('any.buttons.leave') : t('any.buttons.add')}
            </ConardButton>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default TruckMonitoringDialog
