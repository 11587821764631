import React, { FC } from 'react'

import ConardCreateTrainForm from '../../components/forms/ConardCreateTrainForm'
import { TrainMode } from '../../enums/TrainMode'
import { TrainDto } from '../../api'
import { useHistory } from 'react-router-dom'
import trainGateInService from '../../services/TrainGateInService'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { makeStyles, createStyles } from '@material-ui/core'

const useStyles = makeStyles(() =>
  createStyles({
    body: {
      fontSize: 16,
    },
  })
)

const TrainCreateGateInPage: FC = () => {
  const history = useHistory()
  const clasess = useStyles()
  const { t } = useTranslation()

  const createGateIn = (train: TrainDto, onTerminal: boolean) => {
    trainGateInService.createTrain(onTerminal, train).then((response) => {
      history.replace('/gate-in/train/' + response.data.id)
      toast.success(<>{t('pages.alert.trainSaved')}</>, {
        position: 'top-center',
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: false,
        hideProgressBar: true,
        bodyClassName: clasess.body,
      })
    })
  }

  return <ConardCreateTrainForm mode={TrainMode.GATE_IN} onSubmit={createGateIn} />
}

export default TrainCreateGateInPage
