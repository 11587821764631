import React, { FC, useEffect, useState } from 'react'
import ConardTrainForm from '../../components/forms/ConardTrainForm'
import { TrainDto, TrainWagonContentDto, TrainWagonDto } from '../../api'
import { setTransition, transitionDefaults } from '../../redux/transition/transitionSlice'
import { TrainMode } from '../../enums/TrainMode'
import { RootState, useAppDispatch } from '../../redux/store'
import {
  setCurrentWagon,
  setCurrentWagonContent,
  setSelectedUnseatedTransition,
  setTrain,
} from '../../redux/train/trainSlice'
import { useHistory, useParams } from 'react-router-dom'
import trainService from '../../services/TrainService'
import { useSelector } from 'react-redux'

interface UrlParam {
  id?: string
}

const TrainGateInPage: FC = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()

  const { id } = useParams<UrlParam>()
  const [loaded, setLoaded] = useState(false)
  const train = useSelector<RootState, TrainDto>((state) => state.train.train)

  useEffect(() => {
    if (id && train.id !== parseInt(id ?? '-1')) {
      trainService
        .findById(parseInt(id ?? '-1'))
        .then((response) => {
          dispatch(setTrain(response.data))
          dispatch(setSelectedUnseatedTransition(null))
        })
        .catch()
        .finally(() => setLoaded(true))
    } else {
      setLoaded(true)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onCreateWagonContainer = (wagon: TrainWagonDto, wagonContent: TrainWagonContentDto) => {
    dispatch(setCurrentWagon(wagon))
    dispatch(setCurrentWagonContent(wagonContent))
    dispatch(setTransition(transitionDefaults.shippingContainer))

    history.push('/gate-in/train/container')
  }

  const onCreateWagonSemitrailer = (wagon: TrainWagonDto, wagonContent: TrainWagonContentDto) => {
    dispatch(setCurrentWagon(wagon))
    dispatch(setCurrentWagonContent(wagonContent))
    dispatch(setTransition(transitionDefaults.semitrailer))

    history.push('/gate-in/train/semitrailer')
  }

  const onEditWagonTransition = (wagon: TrainWagonDto, wagonContent: TrainWagonContentDto) => {
    dispatch(setCurrentWagon(wagon))
    dispatch(setCurrentWagonContent(wagonContent))

    history.push(`/train/transition/${wagonContent.transition.id}`)
  }

  return (
    <>
      {loaded && (
        <ConardTrainForm
          mode={TrainMode.GATE_IN}
          onCreateWagonContainer={onCreateWagonContainer}
          onCreateWagonSemitrailer={onCreateWagonSemitrailer}
          onEditWagonContainer={onEditWagonTransition}
          onEditWagonSemitrailer={onEditWagonTransition}
        />
      )}
    </>
  )
}

export default TrainGateInPage
