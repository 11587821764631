import React, { FC } from 'react'
import BasePage from './layout/BasePage'
import { CssBaseline, MuiThemeProvider } from '@material-ui/core'
import localizationConfig from './config/localization/localizationConfig'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import LoginPage from './pages/login/LoginPage'
import { I18nextProvider } from 'react-i18next'
import PrivateRoute from './layout/PrivateRoute'
import BackendServiceProvider from './context/BackendServiceContext'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import csDateFnsLocale from 'date-fns/locale/cs'
import store, { persistor } from './redux/store'
import { Provider } from 'react-redux'
import ProvideConfig from './context/ConfigContext'
import { PersistGate } from 'redux-persist/integration/react'
import { useConardTheme } from './hooks/useConardTheme'

const App: FC = () => {
  const theme = useConardTheme()

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <I18nextProvider i18n={localizationConfig}>
            <ProvideConfig>
              <MuiThemeProvider theme={theme}>
                <BackendServiceProvider>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={csDateFnsLocale}>
                    <CssBaseline />
                    <BrowserRouter>
                      <Switch>
                        <Route path="/login" component={LoginPage} />
                        <PrivateRoute path="/" component={BasePage} />
                      </Switch>
                    </BrowserRouter>
                  </MuiPickersUtilsProvider>
                </BackendServiceProvider>
              </MuiThemeProvider>
            </ProvideConfig>
          </I18nextProvider>
        </PersistGate>
      </Provider>
    </>
  )
}
export default App
