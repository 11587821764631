import { styled } from '@mui/material'
import { Typography } from '@material-ui/core'
import { useConardTheme } from '../../hooks/useConardTheme'

export const ConardLabel = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'inactive',
})<{ inactive?: boolean }>`
  font-weight: 600;
  font-size: 15px;
  margin-left: 0.5rem;
  color: ${(props) => (!!props.inactive ? 'gray' : useConardTheme().palette.primary.main)};
`
