import React, { createContext, useContext, useState, useEffect, FC } from 'react'
import carrierService from '../services/CarrierService'

interface Props {
  children: React.ReactNode
}

type CarrierContextType = {
  carrierId: number | null
}

const CarrierContext = createContext<CarrierContextType | undefined>(undefined)

export const CarrierProvider: FC<Props> = ({ children }) => {
  const [carrierId, setCarrierId] = useState<number | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const fetchCarrierId = async () => {
      try {
        const response = await carrierService.getCurrentCarrier()
        if (response.data?.id) {
          setCarrierId(response.data.id)
          setLoading(false)
        }
      } catch (error) {
        console.error('Error fetching carrierId:', error)
        setLoading(false)
      }
    }

    fetchCarrierId()
  }, [])

  if (loading) {
    return null
  }

  return <CarrierContext.Provider value={{ carrierId }}>{children}</CarrierContext.Provider>
}

export const useCarrierId = () => {
  const context = useContext(CarrierContext)

  if (context === undefined) {
    throw new Error('useCarrierId must be used within a CarrierProvider')
  }

  return context.carrierId
}
