import { AxiosPromise } from 'axios'
import { ExtraApi, ExtraDto, PageExtraDto } from '../api'
import myAxios from '../config/axios/axios'

let api: ExtraApi

export const setExtraApiConfiguration = () => {
  api = new ExtraApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const transitionExtraService = {
  findAll: (
    page: number | undefined,
    pageSize: number | undefined,
    orderBy: string,
    order: string
  ): AxiosPromise<PageExtraDto> => {
    return api.extraFindAll(page, pageSize, [orderBy + ',' + order])
  },
  changePrice: (id: number, price: number): AxiosPromise<ExtraDto> => {
    return api.extraChangePrice(id, price)
  },
  findById: (id: number): AxiosPromise<ExtraDto> => {
    return api.extraFindById(id)
  },
}

export default transitionExtraService
