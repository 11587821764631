/* eslint-disable */
import React, { FC, useRef, useState } from 'react'
import { RHFAutocomplete } from '../shared/rhf/RHFAutocomplete'
import autocompleteService from '../../services/AutocompleteService'
import { AutocompleteDto } from '../../api'
import { makeStyles } from '@material-ui/core';


interface SemitrailerIluCodeAutocompleteProps {
  name: string
  disabled?: boolean
  error?: boolean
  helperText?: string
}


export const SemitrailerIluCodeAutocomplete: FC<SemitrailerIluCodeAutocompleteProps> = ({
  name,
  disabled,
  error,
  helperText,
}) => {
  const timerId = useRef<NodeJS.Timeout | null>(null)

  const [iluCodeOptionsSemitrailer, setIluCodeOptionsSemitrailer] = useState<AutocompleteDto[]>([])

  const onChangeIlucodeSemitrailer = async (value: string) => {
    if (timerId.current) {
      clearTimeout(timerId.current)
    }
    timerId.current = setTimeout(async () => {
      const response = await autocompleteService.find(value, 'SEMITRAILER')
      setIluCodeOptionsSemitrailer(response.data)
    }, 500)
  }

  return (
    <RHFAutocomplete
      label=""
      name={name}
      options={iluCodeOptionsSemitrailer.map((m) => ({
        id: m.id ?? 0,
        name: m.name ?? '',
      }))}
      onTextInputChange={onChangeIlucodeSemitrailer}
      disabled={!!disabled}
      error={!!error}
      helperText={helperText ?? ''}
    />
  )
}
