import React, { FC } from 'react'
import { createStyles, makeStyles, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() =>
  createStyles({
    withoutTransition: {
      fontSize: 28,
      fontWeight: 650,
      color: 'lightgray',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '20px 15px',
    },
  })
)

export const KioskWithoutTransitionText: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return <Typography className={classes.withoutTransition}>{t('kioskApp.step9.withoutTransition')}</Typography>
}
