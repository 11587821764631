import { AppBar, createStyles, makeStyles, Tab } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DamageLogEntryForm from './DamageLogEntryForm'
import DamageLogDamagesList from './DamageLogDamagesList'
import { ContainerDtoContainerTypeEnum, DamageLogEntryDto, TransitionDto } from '../../api'
import { RootState } from '../../redux/store'
import { useSelector } from 'react-redux'
import containerService from '../../services/ContainerService'

const useStyles = makeStyles((theme) =>
  createStyles({
    tabList: {
      backgroundColor: theme.palette.secondary.main,
    },
    tab: {
      color: theme.palette.primary.main,
    },
  })
)

interface DamageLogDashboardProps {
  containerType: ContainerDtoContainerTypeEnum
  startTab: string
}

const DamageLogDashboard: FC<DamageLogDashboardProps> = (props) => {
  const { containerType, startTab } = props
  const { t } = useTranslation()
  const classes = useStyles()

  const transition = useSelector<RootState, TransitionDto>((state) => state.transition.transition)

  const [panel, setPanel] = useState(startTab)
  const [oldDamageEntries, setOldDamageEntries] = useState<DamageLogEntryDto[]>([])

  const loadDamages = () => {
    if (!!transition.container.id) {
      containerService.getAllDamages(transition.container.id).then((response) => {
        const ids = transition.damageLogEntries?.map((e) => {
          return e.id
        })
        const damages = [...response.data].filter((element) => !ids?.includes(element.id))
        setOldDamageEntries(damages)
      })
    }
  }

  useEffect(() => {
    loadDamages()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setPanel(newValue)
  }

  return (
    <>
      <TabContext value={panel}>
        <AppBar position="static">
          <TabList onChange={handleChange} className={classes.tabList} indicatorColor="primary">
            <Tab
              label={t('pages.damageLogManagement.labels.damageLogEntry.title')}
              value="damageLogEntry"
              className={classes.tab}
            />
            <Tab
              label={t('pages.damageLogManagement.labels.transitionDamages.title')}
              value="transitionDamages"
              className={classes.tab}
            />
            <Tab
              label={t('pages.damageLogManagement.labels.oldDamages.title')}
              value="oldDamages"
              className={classes.tab}
            />
          </TabList>
        </AppBar>
        <TabPanel value="damageLogEntry">
          <DamageLogEntryForm containerType={containerType} />
        </TabPanel>
        <TabPanel value="transitionDamages">
          <DamageLogDamagesList
            title={t('pages.damageLogManagement.labels.transitionDamages.title')}
            damageLogEntries={transition.damageLogEntries ?? []}
            transition={transition}
          />
        </TabPanel>
        <TabPanel value="oldDamages">
          <DamageLogDamagesList
            title={t('pages.damageLogManagement.labels.oldDamages.title')}
            damageLogEntries={oldDamageEntries}
            transition={transition}
          />
        </TabPanel>
      </TabContext>
    </>
  )
}

export default DamageLogDashboard
