import React, { FC, ReactElement } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useConardAuth } from '../hooks/useConardAuth'

interface PrivateRouteProps extends RouteProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any
}

const PrivateRoute: FC<PrivateRouteProps> = (props): ReactElement => {
  const { component: Component, ...rest } = props

  const auth = useConardAuth()

  return auth.isAuthenticated() ? (
    <Route {...rest} render={(props) => <Component {...props} />} />
  ) : (
    <Redirect to="/login" />
  )
}

export default PrivateRoute
