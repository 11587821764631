import { Typography, createStyles, makeStyles } from '@material-ui/core'
import { Box, Grid } from '@mui/material'
import React, { FC, useState } from 'react'
import { TerminalSectorLayoutDto } from '../../api'
import { useTranslation } from 'react-i18next'
import { SemitrailerSectorDialog } from './SemitrailerSectorDialog'

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      backgroundColor: '#ccc8c8e9',
      padding: '5px',
      height: 'auto',
    },
    sector: {
      display: 'flex',
      position: 'absolute',
      width: '400px',
      height: 'auto',
      top: '7%',
      left: '50%',
      scale: '0.4',
      [theme.breakpoints.only('xl')]: {
        top: '18%',
        left: '45%',
        scale: '0.6',
      },
    },
    semitrailerWrapper: {
      display: 'flex',
      flexDirection: 'row',
      gap: '3px',
      marginTop: '20px',
      flexWrap: 'wrap',
    },
    occupiedSlot: {
      backgroundColor: '#2b442e',
      height: '30px',
      width: '10px',
      '&:hover': {
        transform: 'scale(1.07)',
        boxShadow: '0px 2px 2px rgba(26,24,26,0.5)',
        borderRadius: '1px',
        transition: 'all 250ms ease-in-out',
        cursor: 'pointer',
      },
    },
    freeSlot: {
      backgroundColor: '#ffffff',
      border: '1px solid black',
      height: '30px',
      width: '10px',
    },
  })
)

interface SemitrailerSectorProps {
  sector: TerminalSectorLayoutDto
}

export const SemitrailerSector: FC<SemitrailerSectorProps> = ({ sector }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [showSectorDialog, setShowSectorDialog] = useState(false)
  const [sectorDialogData, setSectorDialogData] = useState<string>()

  const renderSlots = () => {
    if (typeof sector.unorderedCapacity === 'number' && typeof sector.unorderedCount === 'number') {
      const slots = []

      for (let i = 0; i < sector.unorderedCapacity; i++) {
        const isOccupied = i < sector.unorderedCount
        const slotClass = isOccupied ? classes.occupiedSlot : classes.freeSlot

        slots.push(<Box className={slotClass} />)
      }

      return slots
    }

    // Return a placeholder or an empty array if either unorderedCapacity or unorderedCount is undefined
    return <Box className={classes.freeSlot} />
  }

  return (
    <>
      <Grid className={classes.sector}>
        <Box
          className={classes.wrapper}
          onClick={() => {
            setShowSectorDialog(true)
            setSectorDialogData(sector.name)
          }}
        >
          <Typography>{`${t('pages.terminalLayout.section')}: ${sector.name}`}</Typography>
          <div className={classes.semitrailerWrapper}>{renderSlots()}</div>
        </Box>
      </Grid>

      {sectorDialogData && (
        <SemitrailerSectorDialog
          showSectorDialog={showSectorDialog}
          onClose={() => setShowSectorDialog(false)}
          sectorLetter={sector.name}
        />
      )}
    </>
  )
}
