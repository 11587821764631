import React, { FC, useEffect, useRef, useState } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid, makeStyles, Divider, Typography } from '@material-ui/core'
import { ContainerIcon } from '../../assets/icons/ContainerIcon'
import { RHFTextField } from '../../components/shared/rhf/RHFTextField'
import { SemitrailerIcon } from '../../assets/icons/SemitrailerIcon'
import driverArrivalService from '../../services/DriverArrivalService'
import { AutocompleteDto, DriverArrivalDto, DriverArrivalDtoContainerTypeEnum } from '../../api'
import { useHistory, useParams } from 'react-router-dom'
import { RHFDatePicker } from '../../components/shared/rhf/RHFDateTimePicker'
import { DriverInfo } from './DriverInfo'
import autocompleteService from '../../services/AutocompleteService'
import { AutocompleteItem } from '../../model/autocomplete'
import { useFormInitialValues } from '../../hooks/useFormInitialValues'
import { isAutocompleteItem } from '../../utils/forms'
import ConardButton from '../../components/ConardButton'
import { RHFConardToggle } from '../../components/shared/rhf/RHFConardToggle'
import { ConardLabel } from '../../components/transition/DriverFields'
import { SemitrailerIluCodeAutocomplete } from '../../components/driver-app/SemitrailerIluCodeAutocomplete'
import { ContainerIluCodeAutocomplete } from '../../components/driver-app/ContainerIluCodeAutocomplete'

const useStyles = makeStyles((theme) => ({
  icon: {
    scale: 11.5,
    color: theme.palette.primary.main,
    zIndex: -1,
  },
  divider: {
    margin: '1rem 0',
    width: '100%',
  },
  button: {
    borderRadius: 12,
    height: '3rem',
    fontWeight: 600,
    marginTop: '1rem',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '5.5rem 0',
  },
  repleted: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '600px',
    height: 'auto',
    gap: '0.8rem',
    maxWidth: '600px',
    margin: 'auto',
  },
  driverForm: {
    marginTop: '8px',
    marginBottom: '8px',
    width: '100%',
  },
  typography: {
    fontSize: '19px',
    color: theme.palette.primary.main,
    margin: '5px 0 10px 5px',
    fontWeight: 600,
  },
  semitrailer: {
    borderRadius: 12,
    padding: '15px',
    background: theme.palette.secondary.main,
  },
  input: {
    marginBottom: '10px',
  },
}))

type DriverGateOutFormValue = {
  containerType: boolean | null
  reference: string | null
  repleted: boolean | null
  arrivalDateTime: Date | null
  ilucodeSemitrailer: AutocompleteItem | string
  ilucodeContainer: AutocompleteItem | string
  driver: AutocompleteItem | string
  carrier: AutocompleteItem | string
  frontLicensePlate: AutocompleteItem | string
  rearLicensePlate: AutocompleteItem | string
  semitrailerLicencePlate: string | null
}

const initialValues: DriverGateOutFormValue = {
  containerType: null,
  reference: '',
  repleted: null,
  arrivalDateTime: null,
  ilucodeSemitrailer: '',
  ilucodeContainer: '',
  carrier: '',
  driver: '',
  frontLicensePlate: '',
  rearLicensePlate: '',
  semitrailerLicencePlate: null,
}

export const DriverGateOutPage: FC = () => {
  const formMethods = useForm<DriverGateOutFormValue>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
  })

  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const timerId = useRef<NodeJS.Timeout | null>(null)

  const [data, setData] = useState<DriverArrivalDto | undefined>(undefined)
  const [frontLicensePlateOptions, setFrontLicensePlateOptions] = useState<AutocompleteDto[]>([])
  const [rearLicensePlateOptions, setRearLicensePlateOptions] = useState<AutocompleteDto[]>([])
  const [loaded, setLoaded] = useState<boolean>(false)

  const { id: idDriverArrival } = useParams<{
    id: string
  }>()

  const isSemitrailer = useWatch<boolean>({
    name: 'containerType',
    control: formMethods.control,
  })

  const isContainerRepleted = useWatch<boolean>({
    name: 'repleted',
    control: formMethods.control,
  })

  const filledIluCode = useWatch<string>({
    name: 'ilucodeSemitrailer',
    control: formMethods.control,
  })

  const filledReference = useWatch<string>({
    name: 'reference',
    control: formMethods.control,
  })

  const filledSemitrailerLicensePlate = useWatch<string>({
    name: 'semitrailerLicencePlate',
    control: formMethods.control,
  })

  const onSubmit = async (formValue: DriverGateOutFormValue) => {
    if (!data) {
      console.error('no data for driver gate out page loaded (GET)')
      return
    }

    const {
      containerType: containerTypeForm,
      reference,
      repleted,
      ilucodeSemitrailer,
      ilucodeContainer,
      frontLicensePlate,
      rearLicensePlate,
      semitrailerLicencePlate,
    } = formValue

    const isSemitrailer = containerTypeForm
    let iluCode = undefined

    if (isSemitrailer) {
      iluCode = isAutocompleteItem(ilucodeSemitrailer) ? ilucodeSemitrailer.name : ilucodeSemitrailer
    } else if (isContainerRepleted) {
      iluCode = isAutocompleteItem(ilucodeContainer) ? ilucodeContainer.name : ilucodeContainer
    }

    try {
      const updatedEntity: DriverArrivalDto = {
        ...data,
        containerType: isSemitrailer
          ? DriverArrivalDtoContainerTypeEnum.Semitrailer
          : DriverArrivalDtoContainerTypeEnum.ShippingContainer,
        reference: !reference ? undefined : reference,
        repleted: !isSemitrailer ? !!repleted : undefined,
        iluCode,
        semitrailerLicencePlate: isSemitrailer && semitrailerLicencePlate ? semitrailerLicencePlate : undefined,
        driver: {
          ...data.driver,
          name: data.driver?.name ?? '',
          frontLicensePlate: {
            id: isAutocompleteItem(frontLicensePlate) ? frontLicensePlate.id : undefined,
            licensePlate: isAutocompleteItem(frontLicensePlate) ? frontLicensePlate.name : frontLicensePlate,
          },
          rearLicensePlate: isSemitrailer
            ? data.driver?.rearLicensePlate
            : {
                id: isAutocompleteItem(rearLicensePlate) ? rearLicensePlate.id : undefined,
                licensePlate: isAutocompleteItem(rearLicensePlate) ? rearLicensePlate.name : rearLicensePlate,
              },
        },
      }
      await driverArrivalService.update(data?.id ?? 0, updatedEntity)
      history.push('/')
    } catch (err) {
      console.error(err)
    }
  }

  const onChangeFrontLicensePlate = async (value: string) => {
    if (timerId.current) {
      clearTimeout(timerId.current)
    }
    timerId.current = setTimeout(async () => {
      const response = await autocompleteService.find(value, 'LICENSE_PLATE')
      setFrontLicensePlateOptions(response.data)
    }, 500)
  }

  const onChangeRearLicensePlate = async (value: string) => {
    if (timerId.current) {
      clearTimeout(timerId.current)
    }
    timerId.current = setTimeout(async () => {
      const response = await autocompleteService.find(value, 'LICENSE_PLATE')
      setRearLicensePlateOptions(response.data)
    }, 500)
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await driverArrivalService.getById(Number(idDriverArrival))

      if (response.data) {
        setData(response.data)
      }
      setLoaded(true)
    }
    try {
      fetchData()
    } catch (err) {
      console.error(err)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- formMethods is not there intentionally (cyclic server calls)
  }, [])

  useFormInitialValues(mapValues(data), formMethods.reset)

  return (
    <div style={{ display: !loaded ? 'none' : undefined }}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Grid container className={classes.gridContainer}>
            <Grid item className={classes.iconWrapper}>
              {isSemitrailer ? (
                <SemitrailerIcon className={classes.icon} />
              ) : (
                <ContainerIcon className={classes.icon} />
              )}
            </Grid>

            <Grid item>
              <RHFConardToggle
                name="containerType"
                option1name={t('form.containerForm.type.semitrailer')}
                option2name={t('form.containerForm.type.shippingContainer')}
              />
            </Grid>

            <Grid item>
              <Divider className={classes.divider} />
            </Grid>

            {!isSemitrailer && (
              <Grid item>
                <RHFConardToggle
                  name="repleted"
                  option1name={t('form.transitionForm.repleted')}
                  option2name={t('form.transitionForm.notRepleted')}
                />
              </Grid>
            )}

            {isContainerRepleted && !isSemitrailer && (
              <Grid item className={classes.input}>
                <ConardLabel>{t('form.containerForm.iluCode')}</ConardLabel>
                <ContainerIluCodeAutocomplete name="ilucodeContainer" />
              </Grid>
            )}

            <Grid item className={isSemitrailer ? classes.semitrailer : ''}>
              {isSemitrailer && (
                <Grid item>
                  <Typography className={classes.typography}>{t('form.containerForm.fillOutOption')}</Typography>
                </Grid>
              )}

              {isSemitrailer && (
                <Grid item className={classes.input}>
                  <ConardLabel inactive={!!filledReference || !!filledSemitrailerLicensePlate}>
                    {t('form.containerForm.iluCode')}
                  </ConardLabel>
                  <SemitrailerIluCodeAutocomplete
                    name="ilucodeSemitrailer"
                    disabled={!!filledReference || !!filledSemitrailerLicensePlate}
                  />
                </Grid>
              )}

              {isSemitrailer && (
                <Grid item className={classes.input}>
                  <ConardLabel inactive={!!filledReference || !!filledIluCode}>
                    {t('form.containerForm.semitrailerLicensePlate')}
                  </ConardLabel>
                  <RHFTextField
                    label=""
                    name="semitrailerLicencePlate"
                    disabled={!!filledIluCode || !!filledReference}
                  />
                </Grid>
              )}

              <Grid item className={classes.input}>
                <ConardLabel inactive={!!isSemitrailer && (!!filledIluCode || !!filledSemitrailerLicensePlate)}>
                  {t('form.transitionForm.reference')}
                </ConardLabel>
                <RHFTextField
                  label=""
                  name="reference"
                  disabled={isSemitrailer && (!!filledIluCode || !!filledSemitrailerLicensePlate)}
                />
              </Grid>
            </Grid>

            <Grid item>
              <Divider className={classes.divider} />
            </Grid>

            <Grid item>
              <RHFDatePicker name="arrivalDateTime" disabled />
            </Grid>

            <Grid item className={classes.driverForm}>
              <DriverInfo
                onChangeCarrier={noop}
                onChangeDriver={noop}
                onChangeFrontLicensePlate={onChangeFrontLicensePlate}
                onChangeRearLicensePlate={onChangeRearLicensePlate}
                frontLicensePlateOptions={frontLicensePlateOptions.map((m) => ({
                  id: m.id ?? 0,
                  name: m.name ?? '',
                }))}
                rearLicensePlateOptions={rearLicensePlateOptions.map((m) => ({
                  id: m.id ?? 0,
                  name: m.name ?? '',
                }))}
                carrierDisabled
                driverDisabled
                defaultExpanded
                carrierOptions={[]}
                driverOptions={[]}
                initialValues={data?.driver}
                rearLicensePlateVisible={isSemitrailer ?? false}
              />
            </Grid>

            <Grid item>
              <ConardButton conardVariant="dark" type="submit" className={classes.button}>
                {t('driverApp.form.buttons.planGateOut')}
              </ConardButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </div>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
const noop = (v: string) => {}

const mapValues = (entity: DriverArrivalDto | undefined): DriverGateOutFormValue | undefined => {
  if (!entity) return undefined

  return {
    ilucodeSemitrailer: entity.iluCode ?? '',
    ilucodeContainer: entity.iluCode ?? '',
    arrivalDateTime: new Date(entity.arrivalDateTime),
    reference: entity.reference ?? '',
    repleted: entity.repleted ?? false,
    containerType: entity.containerType === DriverArrivalDtoContainerTypeEnum.Semitrailer,

    driver: {
      id: entity.driver?.id ?? 0,
      name: entity.driver?.name ?? '',
    },

    carrier: {
      id: entity.driver?.carrierShort?.id ?? 0,
      name: entity.driver?.carrierShort?.name ?? '',
    },

    frontLicensePlate: {
      id: entity.driver?.frontLicensePlate?.id ?? 0,
      name: entity.driver?.frontLicensePlate?.licensePlate ?? '',
    },

    rearLicensePlate: {
      id: entity.driver?.rearLicensePlate?.id ?? 0,
      name: entity.driver?.rearLicensePlate?.licensePlate ?? '',
    },
    semitrailerLicencePlate: entity.semitrailerLicencePlate ?? '',
  }
}
