import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

export const ShippingContainerValidation = () => {
  const { t } = useTranslation()
  const shippingContainerValidation = yup.object().shape({
    repleted: yup.boolean(),
    iluCodeContainer: yup
      .mixed()
      .test('is-iluCodeContainer', t('kioskApp.step8.validations.iluCode'), function (value) {
        const repleted = this.parent.repleted
        if (repleted && (!value || (typeof value === 'object' && !value.name))) {
          return this.createError({ message: t('kioskApp.step8.validations.iluCode') })
        }
        return true
      }),
    reference: yup.string().required(t('kioskApp.step8.validations.reference')),
  })

  return shippingContainerValidation
}

export const SemitrailerValidation = () => {
  const { t } = useTranslation()
  const semitrailerValidation = yup.object().shape({
    iluCodeSemitrailer: yup
      .mixed()
      .test('at-least-one-field', t('kioskApp.step8.validations.atLeastOne'), function (value) {
        const { semitrailerLicencePlate, reference } = this.parent
        return !!value || !!semitrailerLicencePlate || !!reference
      }),
    semitrailerLicencePlate: yup
      .string()
      .test('is-semitrailerLicencePlate', t('kioskApp.step8.validations.atLeastOne'), function (value) {
        const { iluCodeSemitrailer, reference } = this.parent
        return !!value || !!iluCodeSemitrailer || !!reference
      }),
    reference: yup.string().test('is-reference', t('kioskApp.step8.validations.atLeastOne'), function (value) {
      const { iluCodeSemitrailer, semitrailerLicencePlate } = this.parent
      return !!value || !!iluCodeSemitrailer || !!semitrailerLicencePlate
    }),
  })

  return semitrailerValidation
}
