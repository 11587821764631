import React, { FC, useMemo } from 'react'
import { Box, createStyles, Grid, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { SearchOutlined } from '@material-ui/icons'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import { Output } from '@mui/icons-material'
import { LayoutContainerDto, LayoutContainerDtoManipulationRequestStatusEnum } from '../../api'
import ConardTooltip from '../../components/ConardTooltip'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { cleanShippingContainerType } from '../../utils/utils'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { CustomsIcon } from './CustomsIcon'
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm'
import { useTerminalTemporaryDrawer } from '../../hooks/useTerminalTemporaryDrawerContext'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      padding: '10px',
      display: 'flex',
      flexDirection: 'column',
      aspectRatio: '1',
      alignItems: 'center',
    },
    boxFull: {
      justifyContent: 'space-between',
      backgroundColor: theme.palette.secondary.main,
      transition: 'box-shadow 0.5s, background-color 0.5s',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        boxShadow: '0 0 15px grey',
      },
    },
    boxEmpty: {
      justifyContent: 'center',
      fontSize: 'large',
      fontWeight: 'bold',
      color: 'rgba(185, 185, 185, 0)',
      transition: 'color 0.5s',
      '&:hover': {
        color: 'rgb(185,185,185)',
      },
    },
    topInfo: {
      justifyContent: 'space-between',
    },
    middleInfo: {
      alignItems: 'center',
    },
    buttons: {
      justifyContent: 'center',
    },
    iconButton: {
      padding: '10px',
    },
    adrRidIcon: {
      color: theme.palette.warning.main,
    },
    reservationIcon: {
      color: theme.palette.warning.main,
    },
    manipulationIcon: {
      color: theme.palette.primary.main,
    },
    emptyEven: {
      backgroundColor: '#ffffff',
    },
    emptyOdd: {
      backgroundColor: '#fcfcfcfc',
    },
    coordinates: {
      fontSize: 'smaller',
    },
    disabledText: {
      opacity: 0.5,
    },
    disabledMiddleInfo: {
      opacity: 0.5,
      alignItems: 'center',
    },
    disabledHover: {
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        boxShadow: 'none',
        cursor: 'default',
      },
    },
    reservation: {
      borderColor: theme.palette.warning.main,
      borderWidth: '2px',
      borderStyle: 'dotted',
      paddingBottom: '40px',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        boxShadow: 'none',
        cursor: 'default',
      },
    },
  })
)

interface LayoutSideBox {
  container: LayoutContainerDto | null
  sector: string
  column: number
  row: number
  floor: number
}
const LayoutSideBox: FC<LayoutSideBox> = (props) => {
  const { container, sector, column, row, floor } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  const { addContainer, containers } = useTerminalTemporaryDrawer()

  const getCoordinate = () => {
    return `${sector} | ${column} | ${row} | ${floor}`
  }

  const emptyField = () => {
    if ((floor % 2 === 0 && row % 2 === 0) || (floor % 2 !== 0 && row % 2 !== 0)) {
      return <Box className={`${classes.box} ${classes.boxEmpty} ${classes.emptyEven}`}>{getCoordinate()}</Box>
    }
    return <Box className={`${classes.box} ${classes.boxEmpty} ${classes.emptyOdd}`}>{getCoordinate()}</Box>
  }

  const containerExistsInContext = useMemo(
    () => containers.some((c) => c.id === container?.id),
    [containers, container?.id]
  )
  const isDisabled = containerExistsInContext

  // const getCustomsIcon = () => {
  //   if (!!container?.customsState) {
  //     switch (container?.customsState) {
  //       case LayoutContainerDtoCustomsStateEnum.T1ByEmail:
  //       case LayoutContainerDtoCustomsStateEnum.T1ByUser:
  //         return (
  //           <ConardTooltip
  //             title={`${t('pages.customs.table.customsState.true')} - ${t('enum.transitionCustomsState.T1_BY_USER')}}`}
  //           >
  //             <Typography className={classes.customsClearedIcon}>
  //               <strong>{t('enum.transitionCustomsState.T1_BY_USER')}</strong>
  //             </Typography>
  //           </ConardTooltip>
  //         )
  //       default:
  //         return (
  //           <ConardTooltip
  //             title={
  //               t('pages.customs.table.customsState.' + !!container) +
  //               ' - ' +
  //               t('enum.transitionCustomsState.' + container.customsState)
  //             }
  //           >
  //             <PolicyOutlinedIcon
  //               className={
  //                 container.customsState === LayoutContainerDtoCustomsStateEnum.Stop
  //                   ? classes.customsNotClearedIcon
  //                   : classes.customsClearedIcon
  //               }
  //               fontSize="small"
  //             />
  //           </ConardTooltip>
  //         )
  //     }
  //   }
  // }

  const waitingForManipulation =
    container?.manipulationRequestStatus === LayoutContainerDtoManipulationRequestStatusEnum.Pending

  const handleClickDetail = (id: number | undefined) => {
    history.push('/search/transition/detail/' + id)
  }

  const handleClickGateOut = (id: number | undefined) => {
    //check from BE if the container can be moved - aka is not on lower level
    history.push('/gate-out/single-container/' + id)
  }

  const handleClickMove = () => {
    //check from BE if the container can be moved - aka is not on lower level
    addContainer({
      id: container?.id ?? 0,
      iluCode: container?.iluCode ?? '',
      teu: container?.teu ?? 0,
    })
  }

  return (
    <>
      {!container && emptyField()}
      {container && !container.reserved && (
        <Box
          className={clsx(`${classes.box} ${classes.boxFull}`, {
            [classes.disabledHover]: waitingForManipulation,
          })}
          style={{
            pointerEvents: isDisabled ? 'none' : 'auto',
            opacity: isDisabled ? 0.5 : 1,
          }}
        >
          <Grid container direction="row" className={classes.topInfo}>
            <Grid item className={waitingForManipulation ? classes.disabledText : ''}>
              {cleanShippingContainerType(container.shippingContainerType)}
            </Grid>
            <Grid item>
              <Grid container direction="row">
                {!!container.adrRid && (
                  <ConardTooltip title={t('pages.terminalLayout.tooltip.ADR/RID')}>
                    <WarningAmberOutlinedIcon className={classes.adrRidIcon} fontSize="small" />
                  </ConardTooltip>
                )}
                <CustomsIcon container={container} />
                {waitingForManipulation && (
                  <ConardTooltip title={t('pages.terminalLayout.tooltip.waiting')}>
                    <AccessAlarmIcon fontSize="default" className={classes.manipulationIcon} />
                  </ConardTooltip>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            direction="column"
            className={waitingForManipulation ? classes.disabledMiddleInfo : classes.middleInfo}
          >
            <strong>{container.iluCode}</strong>
            <i>{container.shipownerName}</i>
            <span className={classes.coordinates}>{getCoordinate()}</span>
          </Grid>

          <Grid container direction="row" className={classes.buttons}>
            <ConardTooltip title={t('pages.terminalLayout.tooltip.detail')}>
              <IconButton className={classes.iconButton} onClick={() => handleClickDetail(container.id)}>
                <SearchOutlined fontSize="small" />
              </IconButton>
            </ConardTooltip>
            <ConardTooltip title={t('pages.terminalLayout.tooltip.move')} disabled={waitingForManipulation}>
              <IconButton className={classes.iconButton} onClick={handleClickMove} disabled={waitingForManipulation}>
                <LowPriorityIcon fontSize="small" />
              </IconButton>
            </ConardTooltip>
            <ConardTooltip title={t('pages.terminalLayout.tooltip.gateOut')} disabled={waitingForManipulation}>
              <IconButton
                className={classes.iconButton}
                onClick={() => handleClickGateOut(container.id)}
                disabled={waitingForManipulation}
              >
                <Output fontSize="small" />
              </IconButton>
            </ConardTooltip>
          </Grid>
        </Box>
      )}
      {container && container.reserved && (
        <Box className={clsx(`${classes.box} ${classes.boxFull} ${classes.reservation}`)}>
          <Grid container direction="row" className={classes.topInfo}>
            <Grid item className={classes.disabledText}>
              {cleanShippingContainerType(container.shippingContainerType)}
            </Grid>
            <Grid item>
              <Grid container direction="row">
                {!!container.adrRid && (
                  <ConardTooltip title={t('pages.terminalLayout.tooltip.ADR/RID')}>
                    <WarningAmberOutlinedIcon className={classes.adrRidIcon} fontSize="small" />
                  </ConardTooltip>
                )}
                <CustomsIcon container={container} />
                <ConardTooltip title={t('pages.terminalLayout.tooltip.reservation')}>
                  <HelpOutlineIcon fontSize="default" className={classes.reservationIcon} />
                </ConardTooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="column" className={classes.disabledMiddleInfo}>
            <strong>{container.iluCode}</strong>
            <i>{container.shipownerName}</i>
            <span className={classes.coordinates}>{getCoordinate()}</span>
          </Grid>
        </Box>
      )}
    </>
  )
}

export default LayoutSideBox
