import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import {
  Checkbox,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core'
import transitionService from '../../services/TransitionService'
import { cleanObject } from '../../utils/utils'
import {
  PageTransitionSearchListDto,
  TrainDto,
  TransitionSearchListDto,
  TransitionSearchListDtoContainerTypeEnum,
  TransitionSearchListDtoQualityTypeEnum,
  TransitionSearchListSearchDto,
  TransitionSearchListSearchDtoContainerStateEnum,
  TransitionSearchListSearchDtoReceiptMethodEnum,
} from '../../api'
import { useTranslation } from 'react-i18next'
import ConardButton from '../ConardButton'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { ConardCoordinates } from '../ConardCoordinates'

const useStyles = makeStyles(() =>
  createStyles({
    noData: {
      textAlign: 'center',
    },
    dialog: {
      maxWidth: '700px',
    },
  })
)

export interface ScheduledContainerSelectionModalProps {
  open: boolean
  onClose: () => void
  onSave: (transitionId: number, qualityType: TransitionSearchListDtoQualityTypeEnum | undefined) => void
}

export const ScheduledContainerSelectionModal: FC<ScheduledContainerSelectionModalProps> = ({
  open,
  onClose,
  onSave,
}) => {
  const dataSort = ['direction,iluCode,asc']
  const [pageTransition, setPageTransition] = useState<PageTransitionSearchListDto>()
  const [usedTransitionIds, setUsedTransitionIds] = useState<Array<number>>([])
  const [selectedTransitionDto, setSelectedTransitionDto] = useState<TransitionSearchListDto>()
  const [selectedQuality, setSelectedQuality] = useState<TransitionSearchListDtoQualityTypeEnum>()
  const train = useSelector<RootState, TrainDto>((state) => state.train.train)
  const [page, setPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)
  const { t } = useTranslation()
  const classes = useStyles()

  const closeModal = () => {
    onClose()
  }

  const save = () => {
    if (selectedTransitionDto) {
      onSave(selectedTransitionDto.id ?? -1, selectedQuality)
    }
  }

  const onPage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage)
  }

  const onRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    if (open) {
      const transitionIds: Array<number> = []
      train.wagons?.forEach((wagon) => {
        if (wagon.content) {
          transitionIds.push(...wagon.content.map((content) => content.transition.id!))
        }
      })
      const searchDto: TransitionSearchListSearchDto = {
        containerState: TransitionSearchListSearchDtoContainerStateEnum.PlannedGateIn,
        receiptMethod: TransitionSearchListSearchDtoReceiptMethodEnum.Road,
        container: {},
      }
      transitionService.searchForListPlanned(page, pageSize, dataSort, cleanObject(searchDto)).then((response) => {
        const data = response.data
        setUsedTransitionIds(transitionIds)
        setPageTransition(data)
      })
    } else {
      if (usedTransitionIds.length > 0) {
        setUsedTransitionIds([])
      }
      if (!!selectedTransitionDto) {
        setSelectedTransitionDto(undefined)
        setSelectedQuality(undefined)
      }
    }
  }, [open, page, pageSize]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog
      onClose={closeModal}
      aria-labelledby="simple-dialog-title"
      open={open}
      classes={{ paperWidthSm: classes.dialog }}
    >
      <DialogTitle id="simple-dialog-title">{t('pages.gateIn.headers.selectScheduledContainers')}</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>{t('pages.gateIn.form.iluCode.label')}</TableCell>
                <TableCell>{t('pages.gateIn.form.shipowner.label')}</TableCell>
                <TableCell>{t('pages.gateIn.form.containerType.label')}</TableCell>
                <TableCell>{t('pages.gateIn.form.qualityType.label')}</TableCell>
                <TableCell>{t('pages.gateIn.form.location.label')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!!pageTransition?.content?.length && pageTransition.content.length - usedTransitionIds.length !== 0 ? (
                pageTransition?.content
                  ?.filter((transitionDto: TransitionSearchListDto) => !usedTransitionIds.includes(transitionDto.id!))
                  .map((transitionDto: TransitionSearchListDto) => {
                    return (
                      <TableRow key={transitionDto.id}>
                        <TableCell>
                          <Checkbox
                            color="primary"
                            checked={selectedTransitionDto?.id === transitionDto.id}
                            onChange={() => {
                              setSelectedTransitionDto(transitionDto)
                              setSelectedQuality(transitionDto.qualityType)
                            }}
                          />
                        </TableCell>
                        <TableCell>{transitionDto.iluCode}</TableCell>
                        <TableCell>{transitionDto.shipownerName}</TableCell>
                        <TableCell>{t(`enum.containerType.${transitionDto.containerType}`)}</TableCell>
                        <TableCell>
                          {selectedTransitionDto?.id != transitionDto.id ? (
                            transitionDto.qualityType ? (
                              t(`enum.qualityType.${transitionDto.qualityType}`)
                            ) : (
                              t(`any.notSelected`)
                            )
                          ) : (
                            <Select
                              label={t('pages.gateIn.form.qualityType.label')}
                              disabled={
                                !!transitionDto.qualityType ||
                                transitionDto.containerType !==
                                  TransitionSearchListDtoContainerTypeEnum.ShippingContainer
                              }
                              value={selectedQuality ?? 'NOT-SELECTED'}
                              onChange={(event) => {
                                const nevim = Object.values(TransitionSearchListDtoQualityTypeEnum).find(
                                  (value) => value == event.target.value
                                )
                                setSelectedQuality(nevim)
                              }}
                            >
                              <MenuItem value="NOT-SELECTED">{t('any.notSelected')}</MenuItem>
                              {Object.values(TransitionSearchListDtoQualityTypeEnum).map((qualityType) => (
                                <MenuItem key={qualityType} value={qualityType}>
                                  {t(`enum.qualityType.${qualityType}`)}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        </TableCell>
                        <TableCell>
                          <ConardCoordinates transition={transitionDto} />
                        </TableCell>
                      </TableRow>
                    )
                  })
              ) : (
                <TableRow>
                  <TableCell className={classes.noData} colSpan={5}>
                    {t('pages.common.table.noDataAvailable')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={pageTransition?.totalElements ? pageTransition.totalElements - usedTransitionIds.length : 0}
          onPageChange={onPage}
          page={page}
          rowsPerPage={pageSize}
          labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
          rowsPerPageOptions={[10, 20, 50, 150]}
          onRowsPerPageChange={onRowsPerPage}
        />
      </DialogContent>
      <DialogActions>
        <ConardButton conardVariant="transparent" onClick={closeModal}>
          {t('any.buttons.back')}
        </ConardButton>
        <ConardButton
          conardVariant="dark"
          onClick={save}
          disabled={
            !selectedTransitionDto ||
            (selectedTransitionDto.containerType === TransitionSearchListDtoContainerTypeEnum.ShippingContainer &&
              !selectedQuality)
          }
        >
          {t('any.buttons.select')}
        </ConardButton>
      </DialogActions>
    </Dialog>
  )
}
