import React, { FC, useMemo } from 'react'
import { OperatorTransitionDto, OperatorTransitionDtoContainerTypeEnum, TransitionSearchListDto } from '../../api'
import { createStyles, Grid, makeStyles } from '@material-ui/core'
import { renderContainerType } from '../../utils/utils'
import { ContainerFillIconAlt } from '../../assets/icons/ContainerFillIconAlt'
import { ContainerIcon } from '../../assets/icons/ContainerIcon'
import { SemitrailerFillIcon } from '../../assets/icons/SemitrailerFillIcon'
import { SemitrailerIcon } from '../../assets/icons/SemitrailerIcon'
import ConardTooltip from '../ConardTooltip'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) =>
  createStyles({
    type: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontFamily: 'Arial',
      fontSize: '1rem',
    },
  })
)

interface OperatorContainerIconProps {
  transition: OperatorTransitionDto | TransitionSearchListDto
}

const OperatorContainerIcon: FC<OperatorContainerIconProps> = ({ transition }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const icon = useMemo(() => {
    if (transition.containerType === OperatorTransitionDtoContainerTypeEnum.ShippingContainer) {
      return transition.repleted ? <ContainerFillIconAlt /> : <ContainerIcon />
    }
    return transition.repleted ? <SemitrailerFillIcon /> : <SemitrailerIcon />
  }, [transition])

  const containerType = useMemo(() => {
    return renderContainerType({
      containerType: transition.containerType!,
      shippingContainerType: transition.shippingContainerType,
      semitrailerType: transition.semitrailerType,
    })
  }, [transition])

  return (
    <>
      {transition.containerType && (
        <ConardTooltip
          title={`${t('pages.gateIn.form.repleted.' + (transition.repleted ? 'checked' : 'unchecked'))} ${
            containerType ? '-' : ''
          } ${containerType ?? ''}`}
        >
          <Grid container direction="column" alignItems="center" justifyContent="center">
            <Grid item>
              <span className={classes.type}>{containerType}</span>
            </Grid>
            <Grid item>{icon}</Grid>
          </Grid>
        </ConardTooltip>
      )}
    </>
  )
}

export default OperatorContainerIcon
