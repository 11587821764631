import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

export const ReferenceValidationScheme = () => {
  const { t } = useTranslation()
  const referenceValidation = yup.object().shape({
    reference: yup.string().required(t('kioskApp.step5.validations.referenceRequired')),
  })

  return referenceValidation
}
