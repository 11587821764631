import React from 'react'
import { FC } from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { Control } from 'react-hook-form/dist/types/form'
import ConardCheckbox from '../../../components/ConardCheckbox'

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: '16px',
    marginLeft: '15px',
  },
  checkbox: {
    color: theme.palette.primary.main,
    '& .MuiSvgIcon-root': { fontSize: 30 },
  },
}))

interface ContainerAdditionalPropertyProps {
  name: string
  label: string
  control: Control
  disabled: boolean
}

export const ContainerAdditionalProperty: FC<ContainerAdditionalPropertyProps> = ({
  name,
  label,
  control,
  disabled,
}) => {
  const classes = useStyles()

  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography className={classes.text}>{label}</Typography>
      </Grid>

      <Grid item>
        <ConardCheckbox
          name={name}
          control={control}
          color="primary"
          className={classes.checkbox}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  )
}
