import React, { FC } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ConardButton from '../ConardButton'

export interface NotificationRemoveAllDialogProps {
  showRemoveAllDialog: boolean
  onClose: () => void
  onRemoveAll: () => void
}

const NotificationRemoveAllDialog: FC<NotificationRemoveAllDialogProps> = ({
  showRemoveAllDialog,
  onClose,
  onRemoveAll,
}) => {
  const { t } = useTranslation()

  return (
    <Dialog open={showRemoveAllDialog}>
      <DialogTitle>{t('pages.notifications.dialogs.removeAll.title')}</DialogTitle>
      <DialogContent>
        <Typography>{t('pages.notifications.dialogs.removeAll.message')}</Typography>
      </DialogContent>
      <DialogActions>
        <ConardButton conardVariant="dark" onClick={onRemoveAll}>
          {t('any.buttons.ok')}
        </ConardButton>
        <ConardButton conardVariant="dark" onClick={onClose}>
          {t('any.buttons.cancel')}
        </ConardButton>
      </DialogActions>
    </Dialog>
  )
}

export default NotificationRemoveAllDialog
