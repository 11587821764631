import React, { FC } from 'react'
import { TransitionDtoDirectionEnum, TransitionSearchListDtoDirectionEnum } from '../../api'
import ConardButton from '../ConardButton'
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogContentWrapper: {
      textAlign: 'center',
      marginTop: '5%',
    },
    warningText: {
      color: theme.palette.warning.main,
    },
  })
)

interface TransitionDeleteDialogProps {
  showDialog: boolean
  setShowDialog: React.Dispatch<React.SetStateAction<boolean>>
  data?: TransitionDeleteDialogData
  onDelete: (id: number) => void
}

interface TransitionDeleteDialogData {
  id: number | undefined
  iluCode: string
  direction: TransitionSearchListDtoDirectionEnum | TransitionDtoDirectionEnum | undefined
  transitionDateTime: string
}

const TransitionDeleteDialog: FC<TransitionDeleteDialogProps> = (props) => {
  const { showDialog, setShowDialog, data, onDelete } = props
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Dialog open={showDialog}>
      <DialogTitle>{t('pages.searchTransition.dialog.delete.title')}</DialogTitle>
      <DialogContent>
        <div className={classes.dialogContentWrapper}>
          <Typography>{t('pages.searchTransition.dialog.delete.content')}</Typography>
          <Typography style={{ marginTop: '2vh', marginBottom: '2vh' }}>
            <b>{data?.iluCode}</b>
            <br />
            {data?.direction === TransitionSearchListDtoDirectionEnum.In
              ? t('enum.direction.IN')
              : t('enum.direction.OUT')}
            <br />
            {t('pages.gateIn.form.transitionDatetime.label') +
              ': ' +
              (data?.transitionDateTime ? format(new Date(data?.transitionDateTime), 'dd.MM.yyyy HH:mm') : '')}
          </Typography>
          <Typography className={classes.warningText}>
            <i>{t('pages.searchTransition.dialog.delete.warning')}</i>
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <ConardButton
          conardVariant="transparent"
          onClick={() => {
            setShowDialog(false)
          }}
        >
          {t('any.buttons.back')}
        </ConardButton>
        <ConardButton
          conardVariant="dark"
          onClick={() => {
            setShowDialog(false)
            onDelete(data?.id ?? -1)
          }}
        >
          {t('any.buttons.delete')}
        </ConardButton>
      </DialogActions>
    </Dialog>
  )
}

export default TransitionDeleteDialog
