import React, { FC, useEffect, useState } from 'react'
import { TrainExportConfigurationDto } from '../../../api'
import { useParams } from 'react-router-dom'
import { Mode } from '../../../enums/Mode'
import TrainExportConfigurationEditForm from './TrainExportConfigurationEditForm'
import trainExportConfigurationService from '../../../services/TrainExportConfigurationService'

interface UrlParamTypes {
  id?: string
}

const TrainExportConfigurationUpdatePage: FC = () => {
  const { id } = useParams<UrlParamTypes>()

  const [config, setConfig] = useState<TrainExportConfigurationDto>()
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (id !== undefined) {
      trainExportConfigurationService
        .findById(Number.parseInt(id))
        .then((response) => {
          setConfig(response.data)
          setLoaded(true)
        })
        .catch(() => {
          setLoaded(true)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>{loaded && <TrainExportConfigurationEditForm mode={Mode.Update} exportConfig={config} />}</>
}

export default TrainExportConfigurationUpdatePage
