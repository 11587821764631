import { Box, Button, Typography, createStyles, makeStyles } from '@material-ui/core'
import React, { FC } from 'react'
import {
  ContainerDtoContainerTypeEnum,
  DriverArrivalDto,
  DriverArrivalDtoContainerTypeEnum,
  TransitionDto,
} from '../../../../api'
import { ContainerIcon } from '../../../../assets/icons/ContainerIcon'
import { SemitrailerIcon } from '../../../../assets/icons/SemitrailerIcon'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) =>
  createStyles({
    secondRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'space-around',
      },
    },
    containerTypeBox: {
      marginLeft: '50px',
    },
    carrierContainerTypeBox: {
      marginLeft: '30px',
    },
    icon: {
      scale: 4,
      color: theme.palette.primary.main,
    },
    carrierIcon: {
      scale: 3,
      color: theme.palette.primary.main,
    },
    infoAboutContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '150px',
    },
    containerInfoText: {
      fontSize: '18px',
      fontWeight: 600,
    },
    containerRepleted: {
      fontSize: '15px',
    },
    noCargoText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      fontSize: '24px',
      fontWeight: 600,
      opacity: '0.5',
      textTransform: 'uppercase',
      marginBottom: '40px',
    },
    buttonsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'space-around',
      },
    },
    button: {
      borderRadius: 12,
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.primary.main,
      width: '48%',
      height: '50px',
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        boxShadow: '0 2px 3px rgba(0, 0, 0, 0.2)',
      },
    },
    coordinatesWrapper: {
      display: 'flex',
      marginTop: '10px',
    },
    sectorLetter: {
      marginRight: '5px',
    },
  })
)

interface TransitionCardSecondRowProps {
  plannedTransition: TransitionDto | undefined
  driverArrivalDto: DriverArrivalDto | undefined
  withoutGateInOut: boolean
  disabled: boolean
  isCarrier: boolean
  ilucode: string
  repleted: boolean
  bookingReference: string
  medlogReference: string
  semitrailerLicencePlate?: string
  isGateOut: boolean
  sectorLetter: string | undefined
  sectorColumn: number | undefined
  onAdd: () => void
  onNoCargo: () => void
}

export const TransitionCardSecondRow: FC<TransitionCardSecondRowProps> = ({
  plannedTransition,
  withoutGateInOut,
  disabled,
  onAdd,
  onNoCargo,
  driverArrivalDto,
  ilucode,
  repleted,
  bookingReference,
  medlogReference,
  isCarrier,
  semitrailerLicencePlate,
  isGateOut,
  sectorColumn,
  sectorLetter,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <>
      {plannedTransition ||
      (driverArrivalDto &&
        (driverArrivalDto.reference || driverArrivalDto.iluCode || driverArrivalDto.semitrailerLicencePlate)) ? (
        <Box className={classes.secondRow}>
          <Box className={`${isCarrier ? classes.carrierContainerTypeBox : classes.containerTypeBox}`}>
            {plannedTransition?.container.containerType === ContainerDtoContainerTypeEnum.ShippingContainer ||
            driverArrivalDto?.containerType === DriverArrivalDtoContainerTypeEnum.ShippingContainer ? (
              <ContainerIcon className={`${isCarrier ? classes.carrierIcon : classes.icon}`} />
            ) : (
              <SemitrailerIcon className={`${isCarrier ? classes.carrierIcon : classes.icon}`} />
            )}
            {isGateOut && (
              <Box className={classes.coordinatesWrapper}>
                {sectorLetter && <Typography className={classes.sectorLetter}>{sectorLetter}</Typography>}
                {sectorColumn && <Typography>{' | ' + sectorColumn}</Typography>}
              </Box>
            )}
          </Box>

          <Box className={classes.infoAboutContainer}>
            <Typography className={classes.containerInfoText}>{ilucode}</Typography>
            <Typography>{repleted ? bookingReference : medlogReference}</Typography>
            <Typography>{semitrailerLicencePlate}</Typography>
            {(plannedTransition ||
              driverArrivalDto?.containerType === DriverArrivalDtoContainerTypeEnum.ShippingContainer) && (
              <Typography className={classes.containerRepleted}>
                <i>{repleted ? t('form.transitionForm.repleted') : t('form.transitionForm.notRepleted')}</i>
              </Typography>
            )}
          </Box>
        </Box>
      ) : (
        <Box>
          {withoutGateInOut ? (
            <Typography className={classes.noCargoText}>{t('driverApp.driverArrival.noCargo')}</Typography>
          ) : (
            <Box className={classes.buttonsWrapper}>
              <Button disabled={disabled} className={classes.button} onClick={onAdd}>
                {t('driverApp.driverArrival.buttons.add')}
              </Button>
              <Button disabled={disabled} className={classes.button} onClick={onNoCargo}>
                {t('driverApp.driverArrival.buttons.noCargo')}
              </Button>
            </Box>
          )}
        </Box>
      )}
    </>
  )
}
