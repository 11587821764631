import React, { FC, useEffect, useState } from 'react'
import { Mode } from '../../enums/Mode'
import { useParams } from 'react-router-dom'
import { AsparkingCardDto, CarrierDto } from '../../api'
import carrierService from '../../services/CarrierService'
import CardEditForm from './CardEditForm'
import cardService from '../../services/CardService'

interface UrlParamTypes {
  id?: string
  cardId?: string
}

const CardUpdatePage: FC = () => {
  const { id, cardId } = useParams<UrlParamTypes>()

  const [card, setCard] = useState<AsparkingCardDto>()
  const [carrier, setCarrier] = useState<CarrierDto>()
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (cardId !== undefined && id !== undefined) {
      cardService
        .cardFindById(Number.parseInt(cardId))
        .then((response) => {
          setCard(response.data)
          carrierService
            .carrierFindById(Number.parseInt(id))
            .then((response) => {
              setCarrier(response.data)
              setLoaded(true)
            })
            .catch(() => {
              setLoaded(true)
            })
        })
        .catch(() => {
          setLoaded(true)
        })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return <>{loaded && <CardEditForm carrier={carrier} mode={Mode.Update} card={card} />}</>
}

export default CardUpdatePage
