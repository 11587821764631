import React, { FC, useContext, useState } from 'react'
import {
  ContainerSearchDtoContainerTypeEnum,
  OperatorTransitionDtoQualityTypeEnum,
  OperatorTransitionSearchDto,
  TransitionSearchDto,
} from '../../api'
import { useTranslation } from 'react-i18next'
import ConardSelect from '../../components/ConardSelect'
import { FormProvider, useForm } from 'react-hook-form'
import ConardButton from '../../components/ConardButton'
import { FieldName } from 'react-hook-form/dist/types/fields'
import { ConfigContext } from '../../context/ConfigContext'
import { Grid, MenuItem, styled } from '@mui/material'
import { RHFTextField } from '../../components/shared/rhf/RHFTextField'

type OperatorFilterFormValue = {
  iluCode: string
  truckLicencePlate: string
  trainName: string
  medlogReference: string
  containerType: ContainerSearchDtoContainerTypeEnum | undefined
  qualityType: OperatorTransitionDtoQualityTypeEnum | undefined
  sectorLetter: string
  sectorColumn: number | null
  sectorRow: number | null
  sectorFloor: number | null
}

const initialValues: OperatorFilterFormValue = {
  iluCode: '',
  truckLicencePlate: '',
  trainName: '',
  medlogReference: '',
  containerType: undefined,
  qualityType: undefined,
  sectorLetter: '',
  sectorColumn: null,
  sectorRow: null,
  sectorFloor: null,
}

interface OperatorFilterProps {
  searchFilter: (data: OperatorTransitionSearchDto | undefined) => void
}

interface Sector {
  sectorName: string
  sectorTranslation: string
  menuValues: number[] | string[]
}

const OperatorFilter: FC<OperatorFilterProps> = ({ searchFilter }) => {
  const { t } = useTranslation()
  const { coordinates, generalCarrierReference } = useContext(ConfigContext)

  const formMethods = useForm<OperatorFilterFormValue>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
  })

  const [sectorPlace, setSectorPlace] = useState<string | undefined>(undefined)
  const [sectorColumn, setSectorColumn] = useState<number | undefined>(undefined)
  const [sectorRow, setSectorRow] = useState<number | undefined>(undefined)
  const [sectorFloor, setSectorFloor] = useState<number | undefined>(undefined)

  const sectors: Sector[] = [
    {
      sectorName: 'sectorLetter',
      sectorTranslation: 'pages.gateIn.form.sector.label',
      menuValues: Array.from(Array(coordinates.sectorLetterCount))
        .map((e, i) => i + 65)
        .map((x) => String.fromCharCode(x)),
    },
    {
      sectorName: 'sectorColumn',
      sectorTranslation:
        coordinates.sectorLetterCount === 0 ? 'pages.gateIn.form.sector.label' : 'pages.gateIn.form.sectorColumn.label',
      menuValues: Array.from({ length: coordinates.maxSectorColumn }, (_, i) => i + 1),
    },
    {
      sectorName: 'sectorRow',
      sectorTranslation: 'pages.gateIn.form.sectorRow.label',
      menuValues: Array.from({ length: coordinates.maxSectorRow }, (_, i) => i + 1),
    },
    {
      sectorName: 'sectorFloor',
      sectorTranslation: 'pages.gateIn.form.sectorFloor.label',
      menuValues: Array.from({ length: coordinates.maxSectorFloor }, (_, i) => i + 1),
    },
  ]

  const onChangeSector = (
    field: FieldName<TransitionSearchDto>,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    switch (field) {
      case 'sectorLetter':
        setSectorPlace(event.target.value)
        break
      case 'sectorColumn':
        setSectorColumn(event.target.value as unknown as number)
        break
      case 'sectorRow':
        setSectorRow(event.target.value as unknown as number)
        break
      case 'sectorFloor':
        setSectorFloor(event.target.value as unknown as number)
        break
    }
  }

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(searchFilter)}>
          <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
            <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
              <RHFTextField name="iluCode" label={t('pages.gateIn.form.iluCode.label')} />
            </Grid>
            <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
              <RHFTextField name="truckLicencePlate" label={t('pages.gateIn.form.truckLicencePlate.label')} />
            </Grid>
            <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
              <RHFTextField name="trainName" label={t('pages.operator.table.trainName')} />
            </Grid>
            <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
              <RHFTextField
                name="medlogReference"
                label={
                  generalCarrierReference
                    ? t('pages.gateIn.form.carrierReference.label')
                    : t('pages.gateIn.form.medlogReference.label')
                }
              />
            </Grid>
            <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
              <ConardSelect
                roundedSquare
                id="containerType"
                name="containerType"
                label={t('pages.searchTransition.containerType.label')}
                control={formMethods.control}
                defaultValue={-1}
              >
                <MenuItem value={-1}>{t('pages.common.select.all')}</MenuItem>
                <MenuItem value={ContainerSearchDtoContainerTypeEnum.ShippingContainer}>
                  {t('enum.containerType.SHIPPING_CONTAINER')}
                </MenuItem>
                <MenuItem value={ContainerSearchDtoContainerTypeEnum.Semitrailer}>
                  {t('enum.containerType.SEMITRAILER')}
                </MenuItem>
              </ConardSelect>
            </Grid>
            <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
              <ConardSelect
                roundedSquare
                id="qualityType"
                name="qualityType"
                label={t('pages.gateIn.form.qualityType.label')}
                control={formMethods.control}
                defaultValue={-1}
              >
                <MenuItem value={-1}>{t('pages.common.select.all')}</MenuItem>
                {Object.values(OperatorTransitionDtoQualityTypeEnum).map((value) => (
                  <MenuItem key={value} value={value}>
                    {t(`enum.qualityType.${value}`)}
                  </MenuItem>
                ))}
              </ConardSelect>
            </Grid>
            {sectors
              .filter((sector) => coordinates.sectorLetterCount !== 0 || sector.sectorName !== 'sectorLetter')
              .map((sector) => {
                return (
                  <Grid item key={sector.sectorName} xl={2} lg={2} md={6} sm={6} xs={12}>
                    <ConardSelect
                      roundedSquare
                      id={sector.sectorName}
                      name={sector.sectorName}
                      defaultValue={
                        sector.sectorName === 'sectorLetter'
                          ? sectorPlace ?? -1
                          : sector.sectorName === 'sectorColumn'
                          ? sectorColumn ?? -1
                          : sector.sectorName === 'sectorRow'
                          ? sectorRow ?? -1
                          : sectorFloor ?? -1
                      }
                      label={t(sector.sectorTranslation)}
                      value={
                        sector.sectorName === 'sectorLetter'
                          ? sectorPlace ?? -1
                          : sector.sectorName === 'sectorColumn'
                          ? sectorColumn ?? -1
                          : sector.sectorName === 'sectorRow'
                          ? sectorRow ?? -1
                          : sectorFloor ?? -1
                      }
                      onChange={(event) => onChangeSector(sector.sectorName, event)}
                      control={formMethods.control}
                    >
                      <MenuItem value={-1}>{t('pages.common.select.all')}</MenuItem>
                      {sector.menuValues.map((value) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </ConardSelect>
                  </Grid>
                )
              })}
            {coordinates.sectorLetterCount === 0 && <Grid item xl={2} lg={2} md={2} sm={6} xs={12} />}
            <Grid item xl={2} lg={2} md={2} sm={6} xs={12} />
            <Grid item xl={2} lg={2} md={2} sm={6} xs={12} />
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <div>
                <StyledSearchButton conardVariant="light" type="submit">
                  {t('any.buttons.search')}
                </StyledSearchButton>
              </div>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  )
}

export default OperatorFilter

const StyledSearchButton = styled(ConardButton)({
  width: '15vw',
  minWidth: '300px',
  height: '5vh',
  minHeight: '50px',
  textAlign: 'center',
  float: 'right',
  borderRadius: 12,
})
