import React, { FC, useEffect, useState } from 'react'
import { TrainDto, TrainWagonContentDto, TrainWagonDto } from '../../api'
import ConardTrainForm from '../../components/forms/ConardTrainForm'
import { TrainMode } from '../../enums/TrainMode'
import { useHistory, useParams } from 'react-router-dom'
import trainService from '../../services/TrainService'
import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../redux/store'
import {
  setCurrentWagon,
  setCurrentWagonContent,
  setInitialTrain,
  setSelectedUnseatedTransition,
  setTrain,
} from '../../redux/train/trainSlice'

const useStyles = makeStyles(() =>
  createStyles({
    alert: {
      margin: 'auto',
      width: '50vw',
      marginTop: '20vh',
    },
  })
)

interface UrlParams {
  id?: string
}

const GateOutTrainDetailPage: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { id } = useParams<UrlParams>()

  const train = useSelector<RootState, TrainDto>((state) => state.train.train)
  const dispatch = useAppDispatch()
  const history = useHistory()

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    dispatch(setInitialTrain())

    trainService
      .findById(parseInt(id ?? '-1'))
      .then((response) => {
        dispatch(setTrain(response.data))
        dispatch(setSelectedUnseatedTransition(null))
      })
      .catch()
      .finally(() => setLoaded(true))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onEditWagonTransition = (wagon: TrainWagonDto, wagonContent: TrainWagonContentDto) => {
    dispatch(setCurrentWagon(wagon))
    dispatch(setCurrentWagonContent(wagonContent))

    history.push(`/train/transition/${wagonContent.transition.id}`)
  }

  return (
    <>
      {loaded && train.id && (
        <ConardTrainForm
          mode={TrainMode.GATE_OUT}
          onEditWagonContainer={onEditWagonTransition}
          onEditWagonSemitrailer={onEditWagonTransition}
        />
      )}

      {loaded && train.id === undefined && (
        <Alert severity="error" className={classes.alert}>
          {t('be.error.entity.notFound')}
        </Alert>
      )}
    </>
  )
}

export default GateOutTrainDetailPage
