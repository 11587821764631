import React, { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CompanyDto } from '../../../api'
import companyService from '../../../services/CompanyService'
import CompanyForm from './CompanyForm'
import { Mode } from '../../../enums/Mode'

interface UrlParams {
  id?: string
}

const CompanyEditPage: FC = () => {
  const { id } = useParams<UrlParams>()
  const idNumber = parseInt(id ?? '-1', 10)
  const [loaded, setLoaded] = useState(false)
  const [company, setCompany] = useState<CompanyDto | undefined>(undefined)

  useEffect(() => {
    companyService
      .findById(idNumber)
      .then((response) => {
        setCompany(response.data)
        setLoaded(true)
      })
      .catch(() => {
        setLoaded(true)
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>{loaded && <CompanyForm mode={Mode.Update} company={company} isParent={company?.parentCompanyId === null} />}</>
  )
}
export default CompanyEditPage
