import { useTranslation } from 'react-i18next'
import { createStyles, IconButton, TableCell, TablePagination, TableSortLabel, Typography } from '@material-ui/core'
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { Grid, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { LocalShipping, Train } from '@material-ui/icons'
import PolicyOutlinedIcon from '@material-ui/icons/PolicyOutlined'
import { Order } from '../../../enums/Order'
import {
  PageTransitionSearchListDto,
  TransitionCustomsInfoSearchListDto,
  TransitionCustomsInfoSearchListDtoStateEnum,
  TransitionSearchListDtoReceiptMethodEnum,
  TransitionSearchListSearchDto,
} from '../../../api'
import { setTransitionSearchListSearch } from '../../../redux/transition/TransitionSearchListSearch'
import { cleanObject } from '../../../utils/utils'
import transitionService from '../../../services/TransitionService'
import { RootState, useAppDispatch } from '../../../redux/store'
import { useSelector } from 'react-redux'
import ConardTooltip from '../../../components/ConardTooltip'
import { format } from 'date-fns'
import ConardTransitionSearchIcon from '../../../components/ConardTransitionSearchIcon'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import ConardButton from '../../../components/ConardButton'
import ProcessedFileService from '../../../services/ProcessedFileService'
import ConardProgressBar from '../../../components/ConardProgressBar'
import FilterListIcon from '@material-ui/icons/FilterList'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import TransitionSearchFilter from '../../search/TransitionSearchFilter'
import { TransitionMode } from '../../../enums/TransitionMode'
import {
  setTransition,
  setTransitionFormDisabled,
  setTransitionPositionDisabled,
  setTransitionSaveDisabled,
  transitionInitialState,
} from '../../../redux/transition/transitionSlice'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) =>
  createStyles({
    gridContainer: {
      marginTop: '8vh',
    },
    temporaryCustomer: {
      color: theme.palette.warning.main,
    },
    companyCell: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    adrRidIcon: {
      color: theme.palette.warning.main,
    },
    detailButton: {
      width: '7vw',
    },
    t1Typography: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontFamily: 'Arial',
    },
    filterButton: {
      textAlign: 'center',
      margin: '0 20px ',
    },
  })
)

interface HeadCell {
  id: string
  label: string | ReactElement
}

export const CarrierSearchListPage = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const history = useHistory()

  const [pageTransitions, setPageTransitions] = useState<PageTransitionSearchListDto | null>()
  const transitionSearch = useSelector<RootState, TransitionSearchListSearchDto | undefined>(
    (state) => state.transitionSearchListSearch.transitionSearch
  )
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(150)
  const [order, setOrder] = useState<Order>(Order.Desc)
  const [orderBy, setOrderBy] = useState<string>('transitionDateTime')
  const [showFilter, setShowFilter] = useState(true)
  const [blockSearch, setBlockSearch] = useState<boolean>(true)

  const headCells: HeadCell[] = [
    { id: 'direction', label: t('pages.gateIn.form.direction.label') },
    {
      id: 'receiptMethod',
      label: (
        <>
          <Train fontSize="small" />
          <Typography variant="h5">/</Typography>
          <LocalShipping fontSize="small" />
        </>
      ),
    },
    { id: 'iluCode', label: t('pages.gateIn.form.iluCode.label') },
    { id: 'containerState', label: t('pages.gateIn.form.state.label') },
    { id: 'companyName', label: t('entity.company.fields.name') },
    { id: 'shipownerName', label: t('pages.gateIn.form.shipowner.label') },
    { id: 'semitrailerLicencePlate', label: t('pages.gateIn.form.semitrailerLicencePlate.label') },
    {
      id: 'repleted',
      label: `${t('pages.gateIn.form.repleted.checked')} / ${t('pages.gateIn.form.repleted.unchecked')}`,
    },
    { id: 'truckLicencePlate', label: t('pages.gateIn.form.truckLicencePlate.label') },
    { id: 'transitionDateTime', label: t('pages.gateIn.form.transitionDatetime.label') },
    { id: 'damaged', label: '' },
    { id: 'transitionCustomsInfo.state', label: <PolicyOutlinedIcon fontSize="small" /> },
    { id: 'adrRid', label: t('pages.gateIn.form.adrRid.label') },
  ]

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value))
    setPage(0)
  }

  useEffect(() => {
    search(transitionSearch, false)
  }, [page, pageSize, orderBy, order]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSort = (property: string) => () => {
    const isAsc = orderBy === property && order === Order.Asc
    setOrder(isAsc ? Order.Desc : Order.Asc)
    setOrderBy(property)
  }

  const searchFiltered = (data: TransitionSearchListSearchDto | undefined) => {
    search(data, true)
  }

  const search = (data: TransitionSearchListSearchDto | undefined, fromFilter: boolean) => {
    setBlockSearch(true)
    dispatch(setTransitionSearchListSearch(data))

    const dateFrom: Date | undefined = data?.transitionDateTimeFrom ? new Date(data?.transitionDateTimeFrom) : undefined
    dateFrom?.setHours(0, 0, 0)

    const dateTo: Date | undefined = data?.transitionDateTimeTo ? new Date(data?.transitionDateTimeTo) : undefined
    dateTo?.setHours(23, 59, 59)

    data = {
      ...data,
      container: {
        ...cleanObject(data?.container),
        shipowner: {
          ...cleanObject(data?.container?.shipowner),
        },
        company: {
          ...cleanObject(data?.container?.company),
        },
      },
      containerStates: data?.containerStates ? data?.containerStates.filter((state) => !!state) : [],
      transitionDateTimeFrom: dateFrom?.toISOString(),
      transitionDateTimeTo: dateTo?.toISOString(),
    }

    let currentSort: Array<string> | undefined = undefined
    if (orderBy !== undefined) {
      const currentOrder: string = order !== undefined ? order : 'asc'
      currentSort = [orderBy + ',' + currentOrder]
    }

    transitionService
      .carrierSearch(page, pageSize, currentSort, cleanObject(data))
      .then((response) => {
        setPageTransitions(response.data)
        if (fromFilter) {
          setPage(0)
        }
      })
      .catch()
      .finally(() => {
        setBlockSearch(false)
      })
  }

  const onDetail = (id: number | undefined) => {
    dispatch(setTransitionFormDisabled(true))
    dispatch(setTransitionPositionDisabled(true))
    dispatch(setTransitionSaveDisabled(true))
    dispatch(setTransition(transitionInitialState.transition))

    history.push(`/search/transition/detail/${id}`)
  }

  const getCustomsState = (customsInfo: TransitionCustomsInfoSearchListDto | undefined) => {
    if (!!customsInfo?.processedFile) {
      return (
        <IconButton onClick={() => ProcessedFileService.handleDownload(customsInfo?.processedFile)}>
          <ConardTooltip placement="top" title={t('any.buttons.customsPDF.download') ?? ''}>
            {getCustomsStateIcon(customsInfo?.state)}
          </ConardTooltip>
        </IconButton>
      )
    }
    return getCustomsStateIcon(customsInfo?.state)
  }

  const getCustomsStateIcon = (customsState: TransitionCustomsInfoSearchListDtoStateEnum | undefined) => {
    switch (customsState) {
      case undefined: {
        return ''
      }
      case TransitionCustomsInfoSearchListDtoStateEnum.Stop: {
        return (
          <ConardTooltip title={t('pages.customs.table.customsState.false')}>
            <PolicyOutlinedIcon color="error" />
          </ConardTooltip>
        )
      }
      case TransitionCustomsInfoSearchListDtoStateEnum.T1ByEmail:
      case TransitionCustomsInfoSearchListDtoStateEnum.T1ByUser: {
        return (
          <ConardTooltip
            title={`${t('pages.customs.table.customsState.true')} - ${t(
              'enum.transitionCustomsState.' + customsState
            )}`}
          >
            <Typography className={classes.t1Typography}>{t('enum.transitionCustomsState.T1_BY_USER')}</Typography>
          </ConardTooltip>
        )
      }
      default: {
        return (
          <ConardTooltip
            title={`${t('pages.customs.table.customsState.true')} - ${t(
              'enum.transitionCustomsState.' + customsState
            )}`}
          >
            <PolicyOutlinedIcon color="primary" />
          </ConardTooltip>
        )
      }
    }
  }

  return (
    <>
      <Typography variant="h4" color="primary">
        {t('pages.searchTransition.title')}
      </Typography>
      <Grid container className={classes.gridContainer} direction="column" spacing={3}>
        <Grid item xl={2} lg={3} xs={6}>
          <div className={classes.filterButton}>
            <ConardButton
              conardVariant="transparent"
              startIcon={<FilterListIcon />}
              endIcon={showFilter ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              onClick={() => setShowFilter(!showFilter)}
            >
              {t('any.buttons.filter')}
            </ConardButton>
          </div>
        </Grid>
        {showFilter && (
          <TransitionSearchFilter
            searchFilter={searchFiltered}
            transitionMode={TransitionMode.SEARCH}
            blockSearch={blockSearch}
          />
        )}
        <Grid item xs={12}>
          <Paper variant="outlined">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={handleSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pageTransitions?.content?.map((transition) => (
                    <TableRow key={transition.id}>
                      <TableCell component="td" scope="row">
                        {t(`enum.direction.${transition.direction}`)}
                      </TableCell>
                      <TableCell component="td" scope="row" align="center">
                        {transition.receiptMethod === TransitionSearchListDtoReceiptMethodEnum.Road ? (
                          <ConardTooltip title={t('pages.gateIn.form.receiptMethod.unchecked')}>
                            <LocalShipping color="primary" />
                          </ConardTooltip>
                        ) : (
                          <ConardTooltip
                            title={
                              t('pages.gateIn.form.receiptMethod.checked') +
                              (transition.trainName ? ` - ${transition.trainName}` : '')
                            }
                          >
                            <Train color="primary" />
                          </ConardTooltip>
                        )}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {transition.iluCode}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {t(`enum.containerState.${transition.containerState}`)}
                      </TableCell>
                      <TableCell
                        component="td"
                        scope="row"
                        className={transition.customerName ? classes.temporaryCustomer : ''}
                      >
                        {transition.customerName ? (
                          <ConardTooltip title={t('pages.gateIn.form.temporaryCustomer.tooltip')}>
                            <div className={classes.companyCell}>{transition.customerName}</div>
                          </ConardTooltip>
                        ) : (
                          <div className={classes.companyCell}>{transition.companyName}</div>
                        )}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {transition.shipownerName}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {transition.semitrailerLicencePlate}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {transition.repleted
                          ? t('pages.gateIn.form.repleted.checked')
                          : t('pages.gateIn.form.repleted.unchecked')}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {transition.truckLicencePlate}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {transition.transitionDateTime
                          ? format(new Date(transition.transitionDateTime), 'dd.MM.yyyy HH:mm')
                          : ''}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        <ConardTransitionSearchIcon
                          transitionSearchListDto={{
                            containerType: transition.containerType,
                            semitrailerType: transition.semitrailerType,
                            shippingContainerType: transition.shippingContainerType,
                          }}
                          containerType={transition.containerType}
                          damaged={transition.damaged ?? false}
                        />
                      </TableCell>
                      <TableCell component="td" scope="row" align="center">
                        {getCustomsState(transition.transitionCustomsInfo)}
                      </TableCell>
                      <TableCell component="td" scope="row" align="left">
                        {transition.adrRid && (
                          <ConardTooltip title={t('pages.gateIn.form.adrRid.label')}>
                            <WarningAmberOutlinedIcon className={classes.adrRidIcon} />
                          </ConardTooltip>
                        )}
                      </TableCell>
                      <TableCell component="td" className={classes.detailButton}>
                        <ConardButton conardVariant="light" onClick={() => onDetail(transition.id)}>
                          {t('any.buttons.detail')}
                        </ConardButton>
                      </TableCell>
                    </TableRow>
                  ))}

                  {pageTransitions?.content && pageTransitions?.totalElements === 0 && (
                    <TableRow key="noData">
                      <TableCell component="th" scope="row" align="center" colSpan={16}>
                        {t('pages.common.table.noData')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <ConardProgressBar showBar={!pageTransitions} />
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 150]}
              component="div"
              count={pageTransitions?.totalElements ?? 0}
              rowsPerPage={pageSize}
              labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}
