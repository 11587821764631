import { createStyles, Grid, MenuItem, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded'
import { Alert } from '@material-ui/lab'
import React, { FC, ReactElement, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import {
  CdCargoRequestDto,
  CdCargoTemplateDto,
  TrainDto,
  TrainDtoTrainStateEnum,
  TrainExportConfigurationDto,
} from '../../api'
import ConardButton from '../../components/ConardButton'
import ConardSelect from '../../components/ConardSelect'
import trainService from '../../services/TrainService'
import trainExportConfigurationService from '../../services/TrainExportConfigurationService'
import cdCargoExportConfigurationService from '../../services/CdCargoExportConfigurationService'
import CdCargoDocumentSentDialog from '../../components/dialogs/CdCargoDocumentSentDialog'
import { Download, ForwardToInbox } from '@mui/icons-material'
import { useAppDispatch } from '../../redux/store'
import { setInitialTrain } from '../../redux/train/trainSlice'
import trainGateOutService from '../../services/TrainGateOutService'

const useStyles = makeStyles((theme) =>
  createStyles({
    alert: {
      margin: 'auto',
      width: '50vw',
      marginTop: '20vh',
    },
    submissionSuccess: {
      height: '70vh',
    },
    submissionSuccessIcon: {
      fontSize: 200,
      color: theme.palette.primary.main,
    },
    gridContainerColumn: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    gridContainerRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '1rem',
    },
    gridSection: {
      marginBottom: '2rem',
    },
    gridExportColumn: {
      width: '30rem',
      padding: '10px',
    },
    gridExportItem: {
      width: '100%',
      marginBottom: '0.5em',
    },
    backButton: {
      marginTop: '8rem',
    },
  })
)

interface UrlParamTypes {
  id?: string
  type?: string
}

const TransitionSuccessPage: FC = (): ReactElement => {
  const { id, type } = useParams<UrlParamTypes>()

  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useAppDispatch()

  const [train, setTrain] = useState<TrainDto>()
  const [templateId, setTemplateId] = useState<number>()

  const [loaded, setLoaded] = useState(false)

  const { control } = useForm()
  const [configurations, setConfigurations] = useState<TrainExportConfigurationDto[] | undefined>(undefined)
  const [configurationId, setConfigurationId] = useState<string>()
  const [templates, setTemplates] = useState<CdCargoTemplateDto[] | undefined>(undefined)
  const [showCdCargoDialog, setShowCdCargoDialog] = useState(false)

  const handleDownloadXml = () => {
    const request: CdCargoRequestDto = {
      trainId: train?.id || -1,
      templateId: templateId ?? -1,
    }

    trainGateOutService
      .downloadCimDocument(request)
      .then((response) => {
        const url = window.URL.createObjectURL(response.data)
        const a = document.createElement('a')
        a.href = url
        a.download = 'cimDocument_' + train?.id || -1 + '.xml'
        a.click()
      })
      .catch()
  }

  const handleSendXml = () => {
    const request: CdCargoRequestDto = {
      trainId: train?.id || -1,
      templateId: templateId ?? -1,
    }

    trainGateOutService
      .sendCimDocument(request)
      .then(() => {
        setShowCdCargoDialog(true)
      })
      .catch()
  }

  const getAllTemplates = () => {
    cdCargoExportConfigurationService.findAll(undefined, 200, '', '').then((response) => {
      setTemplates(response.data.content)
    })
  }

  const handleDownloadCimTrainFile = () => {
    trainGateOutService
      .downloadCimTrainFile(Number.parseInt(configurationId || '-1'), train?.id || -1)
      .then((response) => {
        const url = window.URL.createObjectURL(response.data)
        const a = document.createElement('a')
        a.href = url
        a.download = 'cimTrainFile_' + train?.id || -1 + '.xlsx'
        a.click()
      })
      .catch()
  }

  const handleDownloadWagonReport = () => {
    trainGateOutService
      .downloadWagonReport(Number.parseInt(configurationId || '-1'), train?.id || -1)
      .then((response) => {
        const url = window.URL.createObjectURL(response.data)
        const a = document.createElement('a')
        a.href = url
        a.download = 'wagonReport_' + train?.id || -1 + '.xlsx'
        a.click()
      })
      .catch()
  }

  const goBackToTrain = () => {
    dispatch(setInitialTrain())
    switch (type) {
      case 'gate-in':
        history.replace('/gate-in/train/' + id)
        break
      case 'gate-out':
        history.replace('/gate-out/train/' + id)
        break
      default: //store, update
        history.replace('/search/train/detail/' + id)
        break
    }
  }

  useEffect(() => {
    getAllTemplates()

    if (id) {
      trainService
        .findById(Number.parseInt(id))
        .then((response) => {
          setTrain(response.data)
          setLoaded(true)
        })
        .catch(() => {
          setLoaded(true)
        })
      trainExportConfigurationService
        .findAll(undefined, 200, 'configurationName', 'ASC')
        .then((response) => {
          setConfigurations(response.data.content)
        })
        .catch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {loaded && (
        <>
          {train === undefined && (
            <Alert severity="error" className={classes.alert}>
              {t('be.error.entity.notFound')}
            </Alert>
          )}

          {train && (
            <Grid container className={`${classes.gridContainerColumn} ${classes.submissionSuccess}`}>
              <Grid container className={`${classes.gridContainerColumn} ${classes.gridSection}`}>
                <Grid item>
                  <CheckCircleOutlineRoundedIcon className={classes.submissionSuccessIcon} />
                </Grid>
                <Grid item>
                  <Typography variant="h6" color="primary">
                    {t('any.labels.submissionSuccess')}
                  </Typography>
                </Grid>
              </Grid>
              {train?.trainState === TrainDtoTrainStateEnum.OutgoingDispatched && (
                <Grid container className={`${classes.gridContainerRow} ${classes.gridSection}`}>
                  <Grid item>
                    <Grid container className={`${classes.gridContainerColumn} ${classes.gridExportColumn}`}>
                      <Grid item className={classes.gridExportItem}>
                        <Typography variant="h6" color="primary">
                          {t('pages.gateOut.headers.trainCdCargo.selectTemplate')}
                        </Typography>
                        <ConardSelect
                          name="template"
                          defaultValue={-1}
                          value={templateId}
                          control={control}
                          onChange={(event) => setTemplateId(+event.target.value)}
                        >
                          <MenuItem value={-1}>{t('any.notSelected')}</MenuItem>
                          {templates?.map((t) => (
                            <MenuItem key={t.id} value={t.id}>
                              {t.name}
                            </MenuItem>
                          ))}
                        </ConardSelect>
                      </Grid>
                      <Grid item className={classes.gridExportItem}>
                        <ConardButton
                          startIcon={<Download />}
                          conardVariant="light"
                          disabled={!templateId || templateId === -1}
                          onClick={handleDownloadXml}
                        >
                          {t('pages.gateOut.buttons.download')}
                        </ConardButton>
                      </Grid>
                      <Grid item className={classes.gridExportItem}>
                        <ConardButton
                          startIcon={<ForwardToInbox />}
                          conardVariant="light"
                          disabled={!templateId || templateId === -1}
                          onClick={handleSendXml}
                        >
                          {t('pages.gateOut.buttons.send')}
                        </ConardButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container className={`${classes.gridContainerColumn} ${classes.gridExportColumn}`}>
                      <Grid item className={classes.gridExportItem}>
                        <Typography variant="h6" color="primary">
                          {t('pages.gateOut.headers.trainExport.selectConfiguration')}
                        </Typography>
                        <ConardSelect
                          name="configurationName"
                          defaultValue="0"
                          value={configurationId}
                          control={control}
                          onChange={(event) => setConfigurationId(event.target.value)}
                        >
                          <MenuItem value="0">{t('any.notSelected')}</MenuItem>
                          {configurations?.map((conf) => (
                            <MenuItem key={conf.id} value={conf.id}>
                              {conf.configurationName}
                            </MenuItem>
                          ))}
                        </ConardSelect>
                      </Grid>
                      <Grid item className={classes.gridExportItem}>
                        <ConardButton
                          startIcon={<Download />}
                          conardVariant="light"
                          disabled={!configurationId || configurationId === '0'}
                          onClick={handleDownloadCimTrainFile}
                        >
                          {t('pages.gateOut.buttons.download') + ' - CIM train file'}
                        </ConardButton>
                      </Grid>
                      <Grid item className={classes.gridExportItem}>
                        <ConardButton
                          startIcon={<Download />}
                          conardVariant="light"
                          disabled={!configurationId || configurationId === '0'}
                          onClick={handleDownloadWagonReport}
                        >
                          {t('pages.gateOut.buttons.download') + ' - Wagon report'}
                        </ConardButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item className={classes.backButton}>
                <ConardButton conardVariant="transparent" onClick={goBackToTrain}>
                  {t('any.buttons.backTo.train')}
                </ConardButton>
              </Grid>
            </Grid>
          )}

          <CdCargoDocumentSentDialog showDialog={showCdCargoDialog} setShowDialog={setShowCdCargoDialog} />
        </>
      )}
    </>
  )
}
export default TransitionSuccessPage
