export enum UsersMosnov {
  Operator = 'OPERATOR',
  Dispatcher = 'DISPATCHER',
  Manager = 'MANAGER',
  CustomsOfficer = 'CUSTOMS_OFFICER',
  DispatcherTrain = 'DISPATCHER_TRAIN',
  DispatcherRoad = 'DISPATCHER_ROAD',
  Driver = 'DRIVER',
  Carrier = 'CARRIER',
}
