import React, { FC } from 'react'
import { LayoutContainerDto, LayoutContainerDtoCustomsStateEnum } from '../../api'
import ConardTooltip from '../../components/ConardTooltip'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { createStyles, makeStyles } from '@material-ui/core'
import PolicyOutlinedIcon from '@material-ui/icons/PolicyOutlined'

const useStyles = makeStyles((theme) =>
  createStyles({
    adrRidIcon: {
      color: theme.palette.warning.main,
    },
    customsClearedIcon: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontFamily: 'Arial',
    },
    customsNotClearedIcon: {
      color: theme.palette.error.main,
    },
  })
)

interface CustomsIconProps {
  container: LayoutContainerDto
}

export const CustomsIcon: FC<CustomsIconProps> = ({ container }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  if (!!container?.customsState) {
    switch (container?.customsState) {
      case LayoutContainerDtoCustomsStateEnum.T1ByEmail:
      case LayoutContainerDtoCustomsStateEnum.T1ByUser:
        return (
          <ConardTooltip
            title={`${t('pages.customs.table.customsState.true')} - ${t('enum.transitionCustomsState.T1_BY_USER')}}`}
          >
            <Typography className={classes.customsClearedIcon}>
              <strong>{t('enum.transitionCustomsState.T1_BY_USER')}</strong>
            </Typography>
          </ConardTooltip>
        )
      default:
        return (
          <ConardTooltip
            title={
              t('pages.customs.table.customsState.' + !!container) +
              ' - ' +
              t('enum.transitionCustomsState.' + container.customsState)
            }
          >
            <PolicyOutlinedIcon
              className={
                container.customsState === LayoutContainerDtoCustomsStateEnum.Stop
                  ? classes.customsNotClearedIcon
                  : classes.customsClearedIcon
              }
              fontSize="small"
            />
          </ConardTooltip>
        )
    }
  }
  return null
}
