import { DriverApi, DriverDto, PageDriverDto } from '../api'
import myAxios from '../config/axios/axios'
import { AxiosPromise } from 'axios'

let api: DriverApi

export const setDriverApiConfiguration = () => {
  api = new DriverApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const driverService = {
  findById: (id: number): AxiosPromise<DriverDto> => {
    return api.driverFindById(id)
  },

  getAll: (
    page: number | undefined,
    pageSize: number | undefined,
    currentSort: string[] | undefined
  ): AxiosPromise<PageDriverDto> => {
    return api.driverFindAll(page, pageSize, currentSort)
  },

  create: (driverDto: DriverDto): AxiosPromise<DriverDto> => {
    return api.driverCreate(driverDto)
  },

  update: (id: number, driverDto: DriverDto): AxiosPromise<DriverDto> => {
    return api.driverUpdate(id, driverDto)
  },

  delete: (id: number): AxiosPromise => {
    return api.driverDelete(id)
  },
}

export default driverService
