import { createStyles, SvgIcon, SvgIconProps } from '@material-ui/core'
import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ReactComponent as Icon } from '../images/svg/semitrailer-drawing-right-fill.svg'

const useStyles = makeStyles((theme) =>
  createStyles({
    icon: {
      width: '3rem',
      color: theme.palette.primary.main,
    },
  })
)

export const SemitrailerFillIcon: FC<SvgIconProps> = (props) => {
  const classes = useStyles()

  return (
    <SvgIcon viewBox="0 0 288 144" className={classes.icon} {...props}>
      <Icon />
    </SvgIcon>
  )
}
