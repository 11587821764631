import React, { FC } from 'react'
import { Mode } from '../../../enums/Mode'
import ShipownerForm from './ShipownerForm'

const ShipownerCreatePage: FC = () => {
  return (
    <>
      <ShipownerForm mode={Mode.Create} />
    </>
  )
}

export default ShipownerCreatePage
