import React, { FC, useEffect, useState } from 'react'
import {
  JoinCoparnTruckContainerDto,
  OperatorTransitionDto,
  OperatorTransitionDtoDirectionEnum,
  OperatorTransitionDtoReceiptMethodEnum,
  OperatorTransitionSearchDto,
  TransitionDto,
} from '../../api'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { OperatorCoparnContainerSelectionModal } from './OperatorCoparnContainerSelectionModal'
import gateOutService from '../../services/GateOutService'
import transitionService from '../../services/TransitionService'
import manipulationRequestService from '../../services/ManipulationRequestService'
import { OperatorConfirmDialog, OperatorConfirmDialogProps } from '../../components/dialogs/OperatorConfirmDialog'
import { styled, TableCell, TableRow, Typography } from '@mui/material'

interface OperatorClickableRowProps {
  operatorTransition: OperatorTransitionDto
  handleTrainStyle: (operatorTransition: OperatorTransitionDto) => React.ElementType
  search: (data: OperatorTransitionSearchDto | undefined, fromFilter: boolean) => void
  setEnableIntervalSearch: (enable: boolean) => void
}

const OperatorClickableRow: FC<OperatorClickableRowProps> = ({
  operatorTransition,
  handleTrainStyle,
  search,
  children,
  setEnableIntervalSearch,
}) => {
  const { t } = useTranslation()
  const history = useHistory()

  const [showCoparnDialog, setShowCoparnDialog] = useState(false)
  const [dialogProps, setDialogProps] = useState<OperatorConfirmDialogProps | null>(null)

  const confirmCoparn = (transitionDto: TransitionDto, coparnContainerId: number) => {
    const data: JoinCoparnTruckContainerDto = {
      coparnContainerDto: {
        id: coparnContainerId,
        selectedTransition: { id: transitionDto.id, container: transitionDto.container },
      },
      coparnTruckDto: { id: operatorTransition.coparnTruckId },
    }

    gateOutService
      .gateOutCreateGoFromSelectedContainer(data)
      .then(() => {
        setShowCoparnDialog(false)
        search(undefined, false)
      })
      .catch((error) => {
        console.error('Error confirming coparn: ', error)
      })

    setShowCoparnDialog(false)
  }

  const confirmGateOut = () => {
    gateOutService
      .releaseContainer(operatorTransition.transitionId)
      .then(() => {
        search(undefined, false)
      })
      .catch((error) => {
        console.error('Error releasing container: ', error)
      })
      .finally(() => {
        setDialogProps(null)
      })
  }

  const finishManipulationRequest = () => {
    if (operatorTransition.manipulationRequestId) {
      manipulationRequestService
        .store(operatorTransition.manipulationRequestId)
        .then(() => {
          search(undefined, false)
        })
        .catch((error) => {
          console.error('Error finishing manipulation request: ', error)
        })
        .finally(() => {
          setDialogProps(null)
        })
    }
  }

  const confirmLoad = () => {
    transitionService
      .changeLoadedOnTrain(operatorTransition.transitionId ?? -1, true)
      .then(() => {
        search(undefined, false)
      })
      .catch((error) => {
        console.error('Error confirming load: ', error)
      })
      .finally(() => {
        setDialogProps(null)
      })
  }

  const handleClick = () => {
    if (operatorTransition.coparnTruckId) {
      setShowCoparnDialog(true)
    } else if (operatorTransition.manipulationRequestId) {
      setDialogProps({
        open: true,
        title: operatorTransition.iluCode,
        content: t('pages.gateOut.dialog.confirmManipulation.content', { ilu: operatorTransition.iluCode }),
        primaryButtonText: t('any.buttons.move'),
        secondaryButtonText: t('any.buttons.cancel'),
        onPrimaryButtonClick: finishManipulationRequest,
        onSecondaryButtonClick: () => setDialogProps(null),
      })
    } else if (operatorTransition.direction === OperatorTransitionDtoDirectionEnum.In) {
      history.push('/gate-in/transition/store/' + operatorTransition.transitionId)
    } else if (
      operatorTransition.direction === OperatorTransitionDtoDirectionEnum.Out &&
      operatorTransition.receiptMethod === OperatorTransitionDtoReceiptMethodEnum.Road
    ) {
      setDialogProps({
        open: true,
        title: `${operatorTransition.iluCode} ${operatorTransition.truckLicencePlate || ''}`,
        content: t('pages.gateOut.dialog.confirmGateOut.content', {
          ilu: operatorTransition.iluCode,
          licencePlate: operatorTransition.truckLicencePlate ? operatorTransition.truckLicencePlate : '',
        }),
        primaryButtonText: t('any.buttons.ok'),
        secondaryButtonText: t('any.buttons.cancel'),
        onPrimaryButtonClick: confirmGateOut,
        onSecondaryButtonClick: () => setDialogProps(null),
      })
    } else if (
      operatorTransition.direction === OperatorTransitionDtoDirectionEnum.Out &&
      operatorTransition.receiptMethod === OperatorTransitionDtoReceiptMethodEnum.Train
    ) {
      setDialogProps({
        open: true,
        title: operatorTransition.iluCode,
        content: t('pages.gateOut.dialog.confirmLoad.content', {
          ilu: operatorTransition.iluCode,
          train: operatorTransition.trainName,
        }),
        primaryButtonText: t('any.buttons.ok'),
        secondaryButtonText: t('any.buttons.cancel'),
        onPrimaryButtonClick: confirmLoad,
        onSecondaryButtonClick: () => setDialogProps(null),
      })
    }
  }

  const getButtonText = () => {
    if (operatorTransition.coparnTruckId) {
      return t('any.buttons.select')
    } else if (operatorTransition.direction === OperatorTransitionDtoDirectionEnum.In) {
      return t('pages.operator.buttons.gateIn')
    } else if (
      operatorTransition.direction === OperatorTransitionDtoDirectionEnum.Out &&
      operatorTransition.receiptMethod === OperatorTransitionDtoReceiptMethodEnum.Road
    ) {
      return t('pages.operator.buttons.gateOut')
    } else if (
      operatorTransition.direction === OperatorTransitionDtoDirectionEnum.Out &&
      operatorTransition.receiptMethod === OperatorTransitionDtoReceiptMethodEnum.Train
    ) {
      return t('pages.operator.buttons.load')
    }
    return ''
  }

  const TableRowComponent =
    operatorTransition.receiptMethod === OperatorTransitionDtoReceiptMethodEnum.Train
      ? handleTrainStyle(operatorTransition)
      : TableRow

  useEffect(() => {
    setEnableIntervalSearch(dialogProps === null && !showCoparnDialog)
  }, [dialogProps, showCoparnDialog]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <TableRowComponent key={operatorTransition.id} onClick={handleClick}>
        <TableCell>
          <StyledButtonText variant="body1">{getButtonText()}</StyledButtonText>
        </TableCell>
        {children}
      </TableRowComponent>
      {showCoparnDialog && (
        <OperatorCoparnContainerSelectionModal
          driverInfo={{ frontLicensePlate: operatorTransition.truckLicencePlate, driver: operatorTransition.driver }}
          coparnContainerId={operatorTransition.coparnId!}
          open={showCoparnDialog}
          onSave={confirmCoparn}
          onClose={() => setShowCoparnDialog(false)}
        />
      )}
      {dialogProps && <OperatorConfirmDialog {...dialogProps} />}
    </>
  )
}

export default OperatorClickableRow

const StyledButtonText = styled(Typography)({
  textTransform: 'uppercase',
  fontWeight: 'bold',
})
