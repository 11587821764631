import React, { FC } from 'react'
import { Box, createStyles, Divider, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { KioskGateInSummary } from './KioskGateInSummary'
import ConardButton from '../../../components/ConardButton'
import { KioskGateOutSummary } from './KioskGateOutSummary'
import { KioskButtons } from '../KioskButtons'
import { useActualDriverArival } from '../shared/useActualDriverArival'
import kioskDriverArrivalService from '../../../services/KioskDriverArrivalService'

const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      display: 'flex',
      justifyContent: 'center',
    },
    container: {
      alignItems: 'center',
    },
    label: {
      fontSize: 28,
      fontWeight: 650,
      color: theme.palette.primary.main,
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      marginTop: '40px',
    },
    button: {
      borderRadius: 12,
      height: '80px',
      width: '300px',
      boxShadow: '-2px 2px 5px gray',
      fontWeight: 500,
      fontSize: 'large',
    },
    buttonWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
  })
)

interface KioskSummaryProps {
  idDriverArrival: number
  previous: () => void
  next: () => void
  goTo: (index: number) => void
}

export const KioskSummary: FC<KioskSummaryProps> = ({ idDriverArrival, previous, next, goTo }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { driverArrival, loading } = useActualDriverArival(idDriverArrival)

  const handleClickFinish = async () => {
    try {
      await kioskDriverArrivalService.finish(idDriverArrival)
      next()
    } catch (error) {
      console.error('Error finishing kioskDriverArrival', error)
    }
  }

  const handleClickBack = async () => {
    if (driverArrival?.withoutGateOut) {
      try {
        await kioskDriverArrivalService.update(idDriverArrival, {
          ...driverArrival,
          withoutGateOut: false,
        })
        goTo(6)
      } catch (err) {
        console.error(err)
      }
    } else {
      previous()
    }
  }

  return (
    <>
      {!loading && (
        <Grid container direction="column" spacing={3} className={classes.container}>
          <Grid item>
            <Typography className={classes.label}>{t('kioskApp.step9.label')}</Typography>
          </Grid>

          <Grid item>
            <Box className={classes.box}>
              <KioskGateInSummary driverArrivalDto={driverArrival} />
              <Divider orientation="vertical" variant="middle" flexItem />
              <KioskGateOutSummary driverArrivalDto={driverArrival} />
            </Box>
          </Grid>

          <Grid item className={classes.buttonWrapper}>
            <ConardButton conardVariant="light" className={classes.button} onClick={handleClickFinish}>
              {t('kioskApp.step9.buttons.finish')}
            </ConardButton>
            <KioskButtons visibleBack visibleNext={false} onClickBack={handleClickBack} />
          </Grid>
        </Grid>
      )}
    </>
  )
}
