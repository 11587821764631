import { AxiosPromise } from 'axios'
import myAxios from '../config/axios/axios'
import {
  AsparkingLicensePlateDto,
  AsparkingLicensePlateSearchDto,
  LicensePlateApi,
  PageAsparkingLicensePlateDto,
} from '../api'

let api: LicensePlateApi

export const setLicensePlateApiConfiguration = () => {
  api = new LicensePlateApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const licensePlateService = {
  findAll: (
    page: number,
    pageSize: number,
    currentSort: string[] | undefined
  ): AxiosPromise<PageAsparkingLicensePlateDto> => {
    return api.licensePlateFindAll(page, pageSize, currentSort)
  },
  licensePlateCreate: (licensePlateDto: AsparkingLicensePlateDto): AxiosPromise<AsparkingLicensePlateDto> => {
    return api.licensePlateCreate(licensePlateDto)
  },
  licensePlateUpdate: (
    id: number,
    licensePlateDto: AsparkingLicensePlateDto
  ): AxiosPromise<AsparkingLicensePlateDto> => {
    return api.licensePlateUpdate(id, licensePlateDto)
  },
  licensePlateDelete: (id: number): AxiosPromise<void> => {
    return api.licensePlateDelete(id)
  },
  licensePlateSearch: (
    page: number | undefined,
    pageSize: number | undefined,
    currentSort: string[] | undefined,
    licensePlateSearchDto: AsparkingLicensePlateSearchDto
  ): AxiosPromise<PageAsparkingLicensePlateDto> => {
    return api.licensePlateSearch(page, pageSize, currentSort, licensePlateSearchDto)
  },
  licensePlateFindById: (id: number): AxiosPromise<AsparkingLicensePlateDto> => {
    return api.licensePlateFindById(id)
  },
}

export default licensePlateService
