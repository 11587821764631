import React, { FC } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { createStyles, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { ContainerIluCodeAutocomplete } from '../../../components/driver-app/ContainerIluCodeAutocomplete'
import { RHFConardToggle } from '../../../components/shared/rhf/RHFConardToggle'
import { RHFTextField } from '../../../components/shared/rhf/RHFTextField'
import { KioskLabel } from '../shared/KioskLabel'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      maxWidth: '800px',
      margin: 'auto',
      marginTop: '80px',
      minHeight: '400px',
    },
    label: {
      fontWeight: 650,
      color: theme.palette.primary.main,
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
    },
    mainLabel: {
      fontSize: 28,
    },
    secondaryLabel: {
      fontSize: 24,
    },
    whiteDiv: {
      backgroundColor: 'white',
      borderRadius: 12,
      boxShadow: '0 2px 10px gray',
      padding: '1.5rem',
    },
  })
)

export const KioskGateOutContainerInfoForm: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { control, errors } = useFormContext()

  const isRepleted = useWatch<boolean>({
    name: 'repleted',
    control: control,
  })

  return (
    <Grid container direction="column" spacing={3} className={classes.container}>
      <Grid item>
        <Typography className={`${classes.label} ${classes.mainLabel}`}>
          {t('kioskApp.step8.labelShippingContainerRepleted')}
        </Typography>
      </Grid>

      <Grid item>
        <RHFConardToggle
          name="repleted"
          option1name={t('kioskApp.step8.repleted')}
          option2name={t('kioskApp.step8.notRepleted')}
        />
      </Grid>

      <Grid item>
        <div className={classes.whiteDiv}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography className={`${classes.label} ${classes.secondaryLabel}`}>
                {t('kioskApp.step8.labelShippingContainerAddInfo')}
              </Typography>
            </Grid>

            {isRepleted && (
              <Grid item>
                <KioskLabel>{t('kioskApp.step8.iluCode')}</KioskLabel>
                <ContainerIluCodeAutocomplete
                  error={!!errors.iluCodeContainer}
                  helperText={errors.iluCodeContainer ? errors.iluCodeContainer?.message : ''}
                  name="iluCodeContainer"
                />
              </Grid>
            )}

            <Grid item>
              <KioskLabel>{t('kioskApp.step8.reference')}</KioskLabel>
              <RHFTextField label="" name="reference" error={!!errors.reference} />
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  )
}
