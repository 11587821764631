import { Button, createStyles, Grid, makeStyles, Typography } from '@material-ui/core'
import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import DateRangeIcon from '@material-ui/icons/DateRange'
import { format } from 'date-fns'

const useStyles = makeStyles((theme) =>
  createStyles({
    containerGrid: {
      width: '100%',
    },
    containerGridItem: {
      width: '80%',
    },
    calendarIcon: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
      fontSize: 40,
      color: theme.palette.primary.main,
    },
    navigationButtonGridItem: {
      width: '33%',
    },
    navigationButton: {
      height: '10vh',
      minHeight: '150px',
      width: '100%',
      '&.MuiButton-contained.Mui-disabled': {
        backgroundColor: theme.palette.grey.A100,
      },
    },
  })
)

const ExportConfigurationDashboard: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.containerGrid}
      spacing={8}
    >
      <Grid item className={classes.containerGridItem}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
          <Grid item>
            <DateRangeIcon className={classes.calendarIcon} />
          </Grid>
          <Grid item>
            <Typography variant="h4" color="primary">
              {format(new Date(), 'dd.MM.yyyy, HH:mm')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.containerGridItem}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={6}>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.navigationButton}
              onClick={() => history.push('/export-config/train')}
            >
              <Typography variant="h5">{t('pages.exportConfig.buttons.train')}</Typography>
            </Button>
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.navigationButton}
              onClick={() => history.push('/export-config/cd-cargo')}
            >
              <Typography variant="h5">{t('pages.exportConfig.buttons.cdCargo')}</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ExportConfigurationDashboard
