import React, { useEffect, useState } from 'react'
import ConardButton from '../../components/ConardButton'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { createStyles, Grid, IconButton, makeStyles, Typography } from '@material-ui/core'
import kioskDriverArrivalService from '../../services/KioskDriverArrivalService'
import { DriverArrivalDto } from '../../api'
import logoBrno from '../../assets/images/logo/brno-logo.png'
import ReactCountryFlag from 'react-country-flag'
import i18next from 'i18next'

const useStyles = makeStyles((theme) =>
  createStyles({
    mainDiv: {
      textAlign: 'center',
      marginTop: '1vh',
      display: 'flex',
      justifyContent: 'center',
    },
    whiteDiv: {
      backgroundColor: theme.palette.secondary.main,
      padding: '30px 40px',
      borderRadius: 12,
      boxShadow: '0 2px 5px gray',
      width: '90vw',
    },
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    welcomeTitle: {
      fontWeight: 650,
      color: theme.palette.primary.main,
    },
    logoCircle: {
      display: 'inline-flex',
      borderRadius: '50%',
      backgroundColor: '#176FBB',
      padding: '10px 10px 5px 10px',
    },
    button: {
      height: '80px',
      width: '250px',
      borderRadius: 12,
      backgroundColor: '#176FBB',
      fontWeight: 650,
      fontSize: 'large',
      boxShadow: '-2px 2px 5px gray',
    },
    flagBackground: {
      backgroundColor: theme.palette.background.default,
      borderRadius: 12,
    },
  })
)

interface Language {
  code: string
  language: string
}

export const KioskHomePage = () => {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const [, setData] = useState<DriverArrivalDto | undefined>(undefined)

  const languages: Language[] = [
    {
      code: 'CZ',
      language: 'cs',
    },
    {
      code: 'GB',
      language: 'en',
    },
    {
      code: 'UA',
      language: 'uk',
    },
    {
      code: 'HU',
      language: 'hu',
    },
    {
      code: 'PL',
      language: 'pl',
    },
  ]

  const handleChangeLanguage = (language: string) => {
    i18next.changeLanguage(language)
  }

  const handleClickStart = async () => {
    try {
      const result = await kioskDriverArrivalService.create({
        arrivalDateTime: new Date().toISOString(),
      })
      if (result.data) {
        setData(result.data)
        const idDriverArrival = result.data?.id
        history.push(`/kiosk/form/${idDriverArrival}`)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleChangeLanguage('cs')
  }, [])

  return (
    <div className={classes.mainDiv}>
      <div className={classes.whiteDiv}>
        <Grid container direction="column" className={classes.wrapper} spacing={5}>
          <Grid item>
            <div className={classes.logoCircle}>
              <img src={logoBrno} alt="" width="120px" />
            </div>
          </Grid>
          <Grid item>
            <Typography variant="h3" className={classes.welcomeTitle}>
              {t('kioskApp.home.welcomeTitle')}
            </Typography>
          </Grid>
          <Grid item>
            <div className={classes.flagBackground}>
              {languages.map((language) => (
                <IconButton key={language.code} onClick={() => handleChangeLanguage(language.language)}>
                  <ReactCountryFlag countryCode={language.code} svg style={{ width: '50px', height: '50px' }} />
                </IconButton>
              ))}
            </div>
          </Grid>
          <Grid item>
            <Typography variant="h5">{t('kioskApp.home.descriptionText')}</Typography>
          </Grid>
          <Grid item>
            <ConardButton className={classes.button} conardVariant="dark" onClick={handleClickStart}>
              {t('kioskApp.buttons.start')}
            </ConardButton>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
