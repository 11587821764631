import React, { FC, useState } from 'react'
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
} from '@material-ui/core'
import ConardButton from '../../components/ConardButton'
import { useTranslation } from 'react-i18next'
import { TransitionCustomsInfoDto, TransitionCustomsInfoDtoStateEnum } from '../../api'
import { DatePicker } from '@material-ui/pickers'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

const useStyles = makeStyles((theme) =>
  createStyles({
    submitButtonContainer: {
      margin: 12,
    },
    submitButton: {
      padding: 0,
      [theme.breakpoints.up('lg')]: {
        minHeight: '60px',
      },
    },
    controls: {
      [theme.breakpoints.up('sm')]: {
        left: '12vw',
        width: '88vw',
      },
      [theme.breakpoints.down('xs')]: {
        left: '0px',
        width: '100vw',
      },
      position: 'fixed',
      bottom: 0,
      backgroundColor: theme.palette.secondary.main,
      zIndex: 100,
    },
    datePickerInputInput: {
      width: '100%',
      textAlign: 'center',
    },
    datePickerInputRoot: {
      width: '80%',
      margin: 'auto',
    },
    datePickerDisabled: {
      color: theme.palette.grey.A200,
    },
    datePickerRoot: {
      cursor: 'pointer',
      width: '100%',
      background: theme.palette.primary.main,
      borderStyle: 'solid',
      borderRadius: 20,
      '& > .MuiInputBase-root': {
        cursor: 'pointer',
        color: theme.palette.secondary.main,
        paddingLeft: 0,
        fontSize: '14px',
      },

      [theme.breakpoints.up('lg')]: {
        minHeight: '60px',
      },
    },
    noteTextfield: {
      width: '550px',
    },
    noteTextfieldProps: {
      borderRadius: 20,
      borderColor: theme.palette.secondary.light,
      backgroundColor: theme.palette.secondary.main,
    },
  })
)

interface CustomsControlButtonsProps {
  disabled: boolean
  handleChangeCustomsInfo: (customInfo: TransitionCustomsInfoDto) => void
  handleDeleteCustomsInfo: () => void
}

const CustomsControlButtons: FC<CustomsControlButtonsProps> = ({
  disabled,
  handleChangeCustomsInfo,
  handleDeleteCustomsInfo,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [customsState, setCustomsState] = useState<TransitionCustomsInfoDtoStateEnum | undefined>(undefined)
  const [showNoteDialog, setShowNoteDialog] = useState<boolean>(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
  const [note, setNote] = useState<string>('')

  const confirmCustomsState = (state: TransitionCustomsInfoDtoStateEnum) => {
    handleChangeCustomsInfo({ state: state })
    setCustomsState(undefined)
  }

  const confirmNote = (note: string) => {
    handleChangeCustomsInfo({ note: note })
    setShowNoteDialog(false)
    setNote('')
  }

  const confirmDateOfControl = (dateOfControl: MaterialUiPickersDate) => {
    if (dateOfControl !== null) {
      handleChangeCustomsInfo({ dateOfControl: dateOfControl.toISOString() })
    }
  }

  const confirmSetDelete = () => {
    handleDeleteCustomsInfo()
    setShowDeleteDialog(false)
  }

  return (
    <>
      <div className={classes.controls}>
        <Grid container direction="row" alignItems="stretch">
          <Grid item lg md sm={12} xs={12}>
            <div className={classes.submitButtonContainer}>
              <ConardButton
                disabled={disabled}
                onClick={() => setCustomsState(TransitionCustomsInfoDtoStateEnum.T1ByUser)}
                className={classes.submitButton}
                conardVariant="dark"
                type="button"
              >
                {t('pages.customs.buttons.setT1')}
              </ConardButton>
            </div>
          </Grid>
          <Grid item lg md sm={6} xs={6}>
            <div className={classes.submitButtonContainer}>
              <ConardButton
                disabled={disabled}
                onClick={() => setCustomsState(TransitionCustomsInfoDtoStateEnum.SadPortService)}
                className={classes.submitButton}
                conardVariant="dark"
                type="button"
              >
                {t('pages.customs.buttons.setSadPort')}
              </ConardButton>
            </div>
          </Grid>
          <Grid item lg md sm={6} xs={6}>
            <div className={classes.submitButtonContainer}>
              <ConardButton
                disabled={disabled}
                onClick={() => setCustomsState(TransitionCustomsInfoDtoStateEnum.SadExternal)}
                className={classes.submitButton}
                conardVariant="dark"
                type="button"
              >
                {t('pages.customs.buttons.setSadExternal')}
              </ConardButton>
            </div>
          </Grid>
          <Grid item lg md sm={6} xs={6}>
            <div className={classes.submitButtonContainer}>
              <ConardButton
                disabled={disabled}
                onClick={() => setCustomsState(TransitionCustomsInfoDtoStateEnum.Stop)}
                className={classes.submitButton}
                conardVariant="dark"
                type="button"
              >
                {t('pages.customs.buttons.setStop')}
              </ConardButton>
            </div>
          </Grid>
          <Grid item lg md sm={6} xs={6}>
            <div className={classes.submitButtonContainer}>
              <ConardButton
                disabled={disabled}
                onClick={() => setShowDeleteDialog(true)}
                className={classes.submitButton}
                conardVariant="dark"
                type="button"
              >
                {t('pages.customs.buttons.setDelete')}
              </ConardButton>
            </div>
          </Grid>
          <Grid item lg md={6} sm={12} xs={12}>
            <div className={classes.submitButtonContainer}>
              <DatePicker
                disabled={disabled}
                className={classes.datePickerRoot}
                emptyLabel={t('pages.customs.table.dateOfControl').toUpperCase()}
                format="dd.MM.yyyy"
                value={null}
                onChange={(value) => {
                  confirmDateOfControl(value)
                }}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: <CalendarTodayIcon className={disabled ? classes.datePickerDisabled : ''} />,
                  classes: {
                    root: classes.datePickerInputRoot,
                    input: classes.datePickerInputInput,
                    disabled: classes.datePickerDisabled,
                  },
                }}
              />
            </div>
          </Grid>
          <Grid item lg md={6} sm={12} xs={12}>
            <div className={classes.submitButtonContainer}>
              <ConardButton
                disabled={disabled}
                onClick={() => setShowNoteDialog(true)}
                className={classes.submitButton}
                conardVariant="dark"
                type="button"
              >
                {t('pages.customs.buttons.addNote')}
              </ConardButton>
            </div>
          </Grid>
        </Grid>
      </div>
      {!!customsState && (
        <Dialog open={!!customsState}>
          <DialogTitle>
            {t('pages.customs.modal.confirmState.title', {
              state: t('enum.transitionCustomsState.' + customsState).toUpperCase(),
            })}
          </DialogTitle>
          <DialogActions>
            <ConardButton conardVariant="light" onClick={() => setCustomsState(undefined)}>
              {t('any.buttons.cancel')}
            </ConardButton>
            <ConardButton conardVariant="dark" onClick={() => confirmCustomsState(customsState!)}>
              {t('any.buttons.ok')}
            </ConardButton>
          </DialogActions>
        </Dialog>
      )}
      <Dialog open={showDeleteDialog}>
        <DialogTitle>{t('pages.customs.modal.confirmDelete.title')}</DialogTitle>
        <DialogActions>
          <ConardButton conardVariant="light" onClick={() => setShowDeleteDialog(false)}>
            {t('any.buttons.cancel')}
          </ConardButton>
          <ConardButton conardVariant="dark" onClick={confirmSetDelete}>
            {t('any.buttons.ok')}
          </ConardButton>
        </DialogActions>
      </Dialog>
      <Dialog open={showNoteDialog}>
        <DialogContent>
          <TextField
            className={classes.noteTextfield}
            label={t('pages.customs.buttons.addNote')}
            value={note}
            onChange={(event) => {
              setNote(event.target.value)
            }}
            InputProps={{
              className: classes.noteTextfieldProps,
            }}
            variant="outlined"
            multiline={false}
          />
        </DialogContent>
        <DialogActions>
          <ConardButton conardVariant="light" onClick={() => setShowNoteDialog(false)}>
            {t('any.buttons.cancel')}
          </ConardButton>
          <ConardButton conardVariant="dark" onClick={() => confirmNote(note)}>
            {t('any.buttons.ok')}
          </ConardButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CustomsControlButtons
