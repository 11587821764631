import React, { FC } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { ContainerDtoContainerTypeEnum, TransitionDto } from '../../../api'
import { Divider, Grid, makeStyles } from '@material-ui/core'
import { ContainerForm } from '../container/ContainerForm'
import { RepletedBox } from './RepletedBox'
import { AdrRidBox } from './AdrRidBox'
import { ConardLabel } from '../../../components/transition/DriverFields'
import ConardTextField from '../../../components/ConardTextField'
import { useTranslation } from 'react-i18next'
import ConardButton from '../../../components/ConardButton'
import { TransitionDatePicker } from '../../../components/transition/TransitionDatePicker'
import DriverForm from '../../../components/gate-in/DriverForm'
import { yupResolver } from '@hookform/resolvers/yup'
import { ValidationScheme } from './ValidationScheme'
import { FormViewMode } from '../../../enums/FormViewMode'

const useStyles = makeStyles({
  grid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridItem: {
    width: '100%',
  },
  divider: {
    margin: '1rem 0',
    width: '100%',
  },
  submitButton: {
    height: '3rem',
    fontWeight: 600,
    borderRadius: 12,
  },
})

interface TransitionFormProps {
  initialValues: TransitionDto
  onSubmit: (data: TransitionDto) => void
  formMode: FormViewMode
  driverId?: number
  signetPhoto: string
  loadingSignetPhoto: boolean
  handleNewPhoto: (photo: string) => void
}

export const TransitionForm: FC<TransitionFormProps> = ({
  initialValues,
  onSubmit,
  formMode,
  driverId,
  signetPhoto,
  loadingSignetPhoto,
  handleNewPhoto,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const formMethods = useForm<TransitionDto>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(ValidationScheme(formMode, initialValues?.container.containerType)),
  })

  const isRepleted = useWatch({
    control: formMethods.control,
    name: 'repleted',
  })

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Grid container className={classes.grid} spacing={2}>
            <Grid item className={classes.gridItem}>
              <ContainerForm container={initialValues.container} formMode={formMode} />
            </Grid>

            <Divider className={classes.divider} />

            <Grid item className={classes.gridItem}>
              <RepletedBox
                signetPhoto={signetPhoto}
                loadingSignetPhoto={loadingSignetPhoto}
                handleNewPhoto={handleNewPhoto}
                initialNhmCodes={initialValues.nhmCodes}
                containerType={initialValues.container.containerType}
              />
            </Grid>

            <Grid item className={classes.gridItem}>
              <AdrRidBox initialCodes={initialValues.adrRidCodes} />
            </Grid>

            <Grid item className={classes.gridItem}>
              <ConardLabel
                inactive={
                  initialValues.container.containerType === ContainerDtoContainerTypeEnum.ShippingContainer &&
                  formMode === FormViewMode.DRIVER &&
                  (!!isRepleted || !!initialValues.id)
                }
              >
                {t('form.transitionForm.reference')}
              </ConardLabel>
              {isRepleted ? (
                <ConardTextField
                  roundedSquare
                  name="bookingReference"
                  defaultValue=""
                  error={!!formMethods.errors.bookingReference}
                  helperText={formMethods.errors.bookingReference?.message ?? ''}
                  control={formMethods.control}
                  disabled={
                    initialValues.container.containerType === ContainerDtoContainerTypeEnum.ShippingContainer &&
                    formMode === FormViewMode.DRIVER &&
                    (!!isRepleted || !!initialValues.id)
                  }
                />
              ) : (
                <ConardTextField
                  roundedSquare
                  name="transitionAdditionalInfo.medlogReference"
                  defaultValue=""
                  error={!!formMethods.errors.transitionAdditionalInfo?.medlogReference}
                  helperText={formMethods.errors.transitionAdditionalInfo?.medlogReference?.message ?? ''}
                  control={formMethods.control}
                  disabled={
                    initialValues.container.containerType === ContainerDtoContainerTypeEnum.ShippingContainer &&
                    formMode === FormViewMode.DRIVER &&
                    !!initialValues.id
                  }
                />
              )}
            </Grid>

            <Divider className={classes.divider} />

            <Grid item className={classes.gridItem}>
              <TransitionDatePicker
                disabled
                dateTime={initialValues.transitionDateTime}
                control={formMethods.control}
              />
            </Grid>

            <Grid item className={classes.gridItem}>
              <DriverForm
                driverId={driverId}
                control={formMethods.control}
                containerType={initialValues.container.containerType}
                disableDriver={formMode === FormViewMode.DRIVER}
                disableCarrier={formMode === FormViewMode.DRIVER}
              />
            </Grid>

            <Grid item className={classes.gridItem}>
              <ConardButton conardVariant="dark" type="submit" className={classes.submitButton}>
                {t('driverApp.form.buttons.planGateIn')}
              </ConardButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  )
}
