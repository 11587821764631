import { FC } from 'react'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { ConardToggle, ConardToggleProps } from '../ConardToggle'

type Props = Omit<ConardToggleProps, 'activeOption' | 'onChange'> & {
  name: string
}

export const RHFConardToggle: FC<Props> = ({ name, option1name, option2name }) => {
  const { control } = useFormContext()
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={null}
      render={({ onChange, value }) => {
        return (
          <ConardToggle option1name={option1name} option2name={option2name} activeOption={value} onChange={onChange} />
        )
      }}
    />
  )
}
