import * as yup from 'yup'

export const AdrValidationScheme = () => {
  const adrValidation = yup.object().shape({
    adrRidCodes: yup
      .array()
      .when('adrRid', {
        is: true,
        then: yup.array().required().min(1),
      })
      .notRequired(),
  })

  return adrValidation
}
