import { mosnovTheme } from '../themes/mosnovTheme'
import { brnoTheme } from '../themes/brnoTheme'
import { developTheme } from '../themes/developTheme'

export const useConardTheme = () => {
  // const today = new Date()
  //
  // if (today.getDate() === 1 && today.getMonth() + 1 === 4) {
  //   return aprilFoolsTheme
  // }

  switch (process.env.REACT_APP_CONARD_TERMINAL) {
    case 'BRNO':
      return brnoTheme
    case 'MOSNOV':
      return mosnovTheme
    default:
      return developTheme
  }
}
