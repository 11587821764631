import React, { FC, useMemo } from 'react'
import { OperatorTransitionDto, TransitionSearchListDto } from '../../api'
import { createStyles, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ConardTooltip from '../ConardTooltip'

const useStyles = makeStyles((theme) =>
  createStyles({
    quality: {
      color: theme.palette.primary.main,
      fontSize: 'medium',
    },
  })
)

interface OperatorContainerQualityProps {
  transition: OperatorTransitionDto | TransitionSearchListDto
}

const OperatorContainerQuality: FC<OperatorContainerQualityProps> = ({ transition }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const quality = useMemo(() => {
    if (transition.damaged) {
      return t('pages.operator.qualityType.DAMAGED')
    }
    return transition.qualityType ? t('pages.operator.qualityType.' + transition.qualityType) : ''
  }, [transition, t])

  return (
    <ConardTooltip title={`${t('pages.gateIn.form.qualityType.label')}: ${quality}`}>
      <span className={classes.quality}>
        <b>{quality}</b>
      </span>
    </ConardTooltip>
  )
}

export default OperatorContainerQuality
