import React, { FC } from 'react'
import { ConardAccordion, ConardAccordionSummary } from '../../components/transition/ConardAccordion'
import { useTranslation } from 'react-i18next'
import { AccordionDetails, Grid, makeStyles } from '@material-ui/core'
import { ConardLabel } from '../../components/transition/DriverFields'
import { RHFAutocomplete } from '../../components/shared/rhf/RHFAutocomplete'
import { DriverDto } from '../../api'
import { AutocompleteItem } from '../../model/autocomplete'

const useStyles = makeStyles({
  input: {
    width: '100%',
  },
})

interface DriverInfoProps {
  defaultExpanded?: boolean
  driverOptions: AutocompleteItem[]
  carrierOptions: AutocompleteItem[]
  frontLicensePlateOptions: AutocompleteItem[]
  rearLicensePlateOptions: AutocompleteItem[]
  driverDisabled: boolean
  carrierDisabled: boolean
  initialValues: DriverDto | undefined
  rearLicensePlateVisible: boolean

  onChangeDriver: (value: string) => void
  onChangeCarrier: (value: string) => void
  onChangeFrontLicensePlate: (value: string) => void
  onChangeRearLicensePlate: (value: string) => void
}

export const DriverInfo: FC<DriverInfoProps> = ({
  defaultExpanded,
  frontLicensePlateOptions,
  rearLicensePlateOptions,
  onChangeFrontLicensePlate,
  onChangeRearLicensePlate,
  driverDisabled,
  carrierDisabled,
  driverOptions,
  carrierOptions,
  onChangeDriver,
  onChangeCarrier,
  rearLicensePlateVisible,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <ConardAccordion defaultExpanded={defaultExpanded ?? true}>
      <ConardAccordionSummary>{t('form.driverForm.title')}</ConardAccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item className={classes.input}>
            <ConardLabel inactive={driverDisabled}>{t('form.driverForm.driver')}</ConardLabel>
            <RHFAutocomplete
              label=""
              name="driver"
              disabled={driverDisabled}
              options={driverOptions}
              onTextInputChange={onChangeDriver}
            />
          </Grid>

          <Grid item className={classes.input}>
            <ConardLabel inactive={carrierDisabled}>{t('form.driverForm.carrier')}</ConardLabel>
            <RHFAutocomplete
              label=""
              name="carrier"
              disabled={carrierDisabled}
              options={carrierOptions}
              onTextInputChange={onChangeCarrier}
            />
          </Grid>

          <Grid item className={classes.input}>
            <ConardLabel>{t('form.driverForm.frontLicensePlate')}</ConardLabel>
            <RHFAutocomplete
              label=""
              name="frontLicensePlate"
              options={frontLicensePlateOptions}
              onTextInputChange={onChangeFrontLicensePlate}
            />
          </Grid>

          {!rearLicensePlateVisible && (
            <Grid item className={classes.input}>
              <ConardLabel>{t('form.driverForm.rearLicensePlate')}</ConardLabel>
              <RHFAutocomplete
                label=""
                name="rearLicensePlate"
                options={rearLicensePlateOptions}
                onTextInputChange={onChangeRearLicensePlate}
              />
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </ConardAccordion>
  )
}
