import React, { FC } from 'react'
import { Box, Button, createStyles, Grid, Typography } from '@material-ui/core'
import AsparkingGateControlService from '../../services/AsparkingGateControlService'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { GateControlMode } from '../../enums/GateControlMode'
import { AsparkingCheckpoint } from '../../enums/AsparkingCheckpoint'
import { SemaphoreMode } from '../../enums/SemaphoreMode'
import asparkingSemaphoreControlService from '../../services/AsparkingSemaphoreControlService'

const useStyles = makeStyles((theme) =>
  createStyles({
    navigationButton: {
      height: '10vh',
      minHeight: '150px',
      width: '100%',
      '&.MuiButton-contained.Mui-disabled': {
        backgroundColor: theme.palette.grey.A100,
      },
    },
    closedGate: {
      marginLeft: '1vh',
      color: theme.palette.error.main,
    },
    openedGate: {
      marginLeft: '1vh',
      color: theme.palette.primary.main,
    },
    semaphoreBox: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
    },
    semaphoreButton: {
      width: '100%',
      minHeight: 67,
      '&.MuiButton-contained.Mui-disabled': {
        backgroundColor: theme.palette.grey.A100,
      },
    },
  })
)

interface GatePageProps {
  deviceNumber: number
  headerLabel: string
  isOpen?: boolean
  allGates?: boolean
  mainGate?: boolean
}

const GateComponent: FC<GatePageProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { deviceNumber, headerLabel, isOpen, allGates, mainGate } = props

  const handleOnClick = (gateControlMode: GateControlMode) => {
    if (allGates) {
      AsparkingGateControlService.asparkingGateControlHandleAllGates(gateControlMode)
    } else {
      switch (gateControlMode) {
        case GateControlMode.OPEN:
          return AsparkingGateControlService.asparkingGateControlOpenGate(deviceNumber)
        case GateControlMode.OPEN_PERMANENT:
          return AsparkingGateControlService.asparkingGateControlOpenGatePermanently(deviceNumber)
        case GateControlMode.CLOSE:
          return AsparkingGateControlService.asparkingGateControlCloseGate(deviceNumber)
      }
    }
  }

  const handleSemaphore = (semaphoreMode: SemaphoreMode) => {
    asparkingSemaphoreControlService.asparkingSemaphoreControl(semaphoreMode, AsparkingCheckpoint.CHECKPOINT_2_IN)
  }

  return (
    <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
      <Grid>
        <Typography variant="h4" color="primary" display="inline">
          {headerLabel}
        </Typography>
        {!(mainGate ?? false) && (
          <sup className={isOpen ? classes.openedGate : classes.closedGate}>
            {t(`gateControl.status.${isOpen ? 'open' : 'close'}`)}
          </sup>
        )}
      </Grid>

      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
        {!((mainGate ?? false) || (allGates ?? false)) && (
          <>
            {deviceNumber !== 6 && (
              <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.navigationButton}
                  onClick={() => handleOnClick(GateControlMode.OPEN)}
                >
                  <Typography variant="h5">{t('gateControl.buttons.openGate')}</Typography>
                </Button>
              </Grid>
            )}

            {deviceNumber === 6 && (
              <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                <Box className={classes.semaphoreBox}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.semaphoreButton}
                    onClick={() => handleSemaphore(SemaphoreMode.FORWARD_DIRECTION)}
                  >
                    <Typography variant="h5">{t('gateControl.buttons.forward')}</Typography>
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.semaphoreButton}
                    onClick={() => handleSemaphore(SemaphoreMode.RIGHT_DIRECTION)}
                  >
                    <Typography variant="h5">{t('gateControl.buttons.right')}</Typography>
                  </Button>
                </Box>
              </Grid>
            )}

            <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.navigationButton}
                onClick={() => handleOnClick(GateControlMode.OPEN_PERMANENT)}
              >
                <Typography variant="h5">{t('gateControl.buttons.openGatePermanently')}</Typography>
              </Button>
            </Grid>

            <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.navigationButton}
                onClick={() => handleOnClick(GateControlMode.CLOSE)}
              >
                <Typography variant="h5">{t('gateControl.buttons.closeGate')}</Typography>
              </Button>
            </Grid>
          </>
        )}

        {(allGates || mainGate) && (
          <>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.navigationButton}
                onClick={
                  allGates
                    ? () => handleOnClick(GateControlMode.OPEN_PERMANENT)
                    : () => handleOnClick(GateControlMode.OPEN)
                }
              >
                <Typography variant="h5">{t('gateControl.buttons.openGatePermanently')}</Typography>
              </Button>
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.navigationButton}
                onClick={() => handleOnClick(GateControlMode.CLOSE)}
              >
                <Typography variant="h5">{t('gateControl.buttons.closeGate')}</Typography>
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  )
}
export default GateComponent
