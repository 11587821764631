import React, { FC } from 'react'
import { createStyles, Grid, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core'
import { NavLink, useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) =>
  createStyles({
    menuListItem: {
      [theme.breakpoints.only('xl')]: {
        paddingTop: '2vh',
        paddingBottom: '2vh',
      },
      [theme.breakpoints.down('lg')]: {
        paddingTop: '1vh',
        paddingBottom: '0px',
      },
    },
    menuLinkIcon: {
      display: 'block',
      textAlign: 'center',
      color: theme.palette.secondary.main,
    },
    menuLinkText: {
      textAlign: 'center',
      color: theme.palette.secondary.main,
    },
  })
)

interface MenuListItemLinkProps {
  to: string
  icon: React.ReactNode
  text: string
}

const MainMenuListItemLink: FC<MenuListItemLinkProps> = (props) => {
  const classes = useStyles()
  const history = useHistory()

  const { to, icon, text } = props

  const checkActive = () => {
    // mark home as active if on root
    if (history.location.pathname === '/' && to === '/home') {
      return true
    }

    return to === history.location.pathname
  }

  return (
    <>
      <li>
        <ListItem
          key={text}
          button
          component={NavLink}
          to={to}
          isActive={checkActive}
          className={classes.menuListItem}
          activeClassName="Mui-selected"
        >
          <Grid container direction="column" justifyContent="flex-start" alignItems="center">
            <Grid item>
              <ListItemIcon className={classes.menuLinkIcon}>{icon}</ListItemIcon>
            </Grid>
            <Grid item>
              <ListItemText className={classes.menuLinkText} primary={text} />
            </Grid>
          </Grid>
        </ListItem>
      </li>
    </>
  )
}

export default MainMenuListItemLink
