import { AsparkingGateControlApi } from '../api'
import myAxios from '../config/axios/axios'
import { GateControlMode } from '../enums/GateControlMode'

let api: AsparkingGateControlApi

export const setAsparkingGateControlApiConfiguration = () => {
  api = new AsparkingGateControlApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const asparkingGateControlService = {
  asparkingGateControlCloseGate: (targetDevice: number) => {
    return api.asparkingGateControlCloseGate(targetDevice)
  },

  asparkingGateControlOpenGate: (targetDevice: number) => {
    api.asparkingGateControlOpenGate(targetDevice)
  },

  asparkingGateControlOpenGatePermanently: (targetDevice: number) => {
    return api.asparkingGateControlOpenGatePermanently(targetDevice)
  },

  asparkingGateControlHandleAllGates: (gateControlMode: GateControlMode) => {
    let mode: 'OPEN' | 'OPEN_PERMANENT' | 'CLOSE'

    switch (gateControlMode) {
      case GateControlMode.OPEN:
        mode = 'OPEN'
        break
      case GateControlMode.OPEN_PERMANENT:
        mode = 'OPEN_PERMANENT'
        break
      case GateControlMode.CLOSE:
        mode = 'CLOSE'
        break
    }

    return api.asparkingGateControlHandleAllGates(mode)
  },

  asparkingGateControlGetGateProperties: () => {
    return api.asparkingGateControlGetGateProperties()
  },
}

export default asparkingGateControlService
