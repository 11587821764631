import React, { FC, useRef, useState } from 'react'
import { RHFAutocomplete } from '../shared/rhf/RHFAutocomplete'
import autocompleteService from '../../services/AutocompleteService'
import { AutocompleteDto } from '../../api'

interface ContainerIluCodeAutocompleteProps {
  name?: string
  disabled?: boolean
  error?: boolean
  helperText?: string
}

export const ContainerIluCodeAutocomplete: FC<ContainerIluCodeAutocompleteProps> = ({
  name,
  disabled,
  error,
  helperText,
}) => {
  const timerId = useRef<NodeJS.Timeout | null>(null)

  const [iluCodeOptionsContainer, setIluCodeOptionsContainer] = useState<AutocompleteDto[]>([])

  const onChangeIlucodeContainer = async (value: string) => {
    if (timerId.current) {
      clearTimeout(timerId.current)
    }
    timerId.current = setTimeout(async () => {
      const response = await autocompleteService.find(value, 'SHIPPING_CONTAINER')
      setIluCodeOptionsContainer(response.data)
    }, 500)
  }

  return (
    <RHFAutocomplete
      label=""
      name={name ?? 'iluCode'}
      options={iluCodeOptionsContainer.map((m) => ({
        id: m.id ?? 0,
        name: m.name ?? '',
      }))}
      onTextInputChange={onChangeIlucodeContainer}
      disabled={!!disabled}
      error={!!error}
      helperText={helperText ?? ''}
    />
  )
}
