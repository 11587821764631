import React, { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

const UnauthorizedPage: FC = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <>
      <div>{t('unauthorized.accessDenied')}</div>
    </>
  )
}

export default UnauthorizedPage
