import React, { FC, useEffect, useState } from 'react'
import { createStyles, Grid, InputAdornment, makeStyles, Typography } from '@material-ui/core'
import { ConardLabel } from '../../../components/transition/DriverFields'
import ConardTextField from '../../../components/ConardTextField'
import { useTranslation } from 'react-i18next'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import { useFormContext, useWatch } from 'react-hook-form'

const useStyles = makeStyles((theme) =>
  createStyles({
    arrow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.primary.main,
      height: '100%',
      marginTop: '10px',
    },
    grossWeightDiv: {
      display: 'flex',
      alignItems: 'center',
      height: '70%',
      color: theme.palette.primary.main,
    },
    grossWeight: {
      fontWeight: 750,
      fontSize: 18,
      padding: 5,
    },
  })
)

export const ContainerWeightField: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { control, errors } = useFormContext()

  const [grossWeight, setGrossWeight] = useState(0)

  const netWeight = useWatch<number>({
    control: control,
    name: 'netWeight',
  })

  const tarraWeight = useWatch<number>({
    control: control,
    name: 'container.tarraWeight',
  })

  useEffect(() => {
    setGrossWeight(+(netWeight ?? 0) + +(tarraWeight ?? 0))
  }, [netWeight, tarraWeight])

  return (
    <Grid container direction="row">
      <Grid item xs={6}>
        <ConardLabel>{t('form.transitionForm.netWeight')}</ConardLabel>
        <ConardTextField
          roundedSquare
          name="netWeight"
          control={control}
          defaultValue=""
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
          }}
          error={!!errors.netWeight}
          helperText={errors.netWeight?.message ?? ''}
        />
      </Grid>

      <Grid item xs={2}>
        <div className={classes.arrow}>
          <KeyboardDoubleArrowRightIcon />
        </div>
      </Grid>

      <Grid item xs={4}>
        <ConardLabel>{t('form.transitionForm.grossWeight')}</ConardLabel>
        <div className={classes.grossWeightDiv}>
          <Typography className={classes.grossWeight}>{grossWeight + ' kg'}</Typography>
        </div>
      </Grid>
    </Grid>
  )
}
