import React, { FC, useState } from 'react'
import { DriverArrivalDtoDriverPassEnum } from '../../../api'
import { Box, Dialog, DialogContent, DialogTitle, IconButton, makeStyles, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Info } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close'
import { Spellcheck } from '@mui/icons-material'
import ConardTooltip from '../../../components/ConardTooltip'

const useStyles = makeStyles({
  circle: {
    height: '35px',
    width: '35px',
    borderRadius: '50%',
    margin: '5px',
  },
  dialogCircle: {
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    marginRight: '5px',
  },
  greenCircle: {
    backgroundColor: 'green',
  },
  orangeCircle: {
    backgroundColor: 'orange',
  },
  redCircle: {
    backgroundColor: 'red',
  },
  greyCircle: {
    backgroundColor: '#cccccc',
  },
  instantGreenPass: {
    color: 'white',
  },
  box: {
    display: 'flex',
    width: '340px',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginTop: '10px',
  },
  text: {
    fontWeight: 550,
    fontSize: 20,
    textTransform: 'uppercase',
  },
  carrierText: {
    fontWeight: 550,
    fontSize: 16,
    textTransform: 'uppercase',
  },
  greenText: {
    color: 'green',
  },
  orangeText: {
    color: 'orange',
  },
  redText: {
    color: 'red',
  },
  helperSpan: {
    fontSize: 14,
    fontWeight: 550,
  },
  helperDiv: {
    marginTop: '10px',
    marginBottom: '20px',
  },
  closeButton: {
    position: 'absolute',
    right: '10px',
    top: '5px',
  },
  semaphore: {
    display: 'flex',
  },
  boxCarrier: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dialogDiv: {
    display: 'flex',
    alignItems: 'center',
  },
})

interface SemaphoreInfoProps {
  driverPass: DriverArrivalDtoDriverPassEnum | undefined
  instantGreenPass: boolean
  isCarrier: boolean
}

export const SemaphoreInfo: FC<SemaphoreInfoProps> = ({ driverPass, isCarrier, instantGreenPass }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [openHelp, setOpenHelp] = useState(false)

  const handleChangeOpenDialog = () => {
    setOpenHelp(!openHelp)
  }

  const getSemaphore = () => {
    switch (driverPass) {
      case DriverArrivalDtoDriverPassEnum.Green:
        return (
          <Box className={classes.semaphore}>
            <div className={`${classes.circle} ${classes.greyCircle}`} />
            <div className={`${classes.circle} ${classes.greyCircle}`} />
            <div className={`${classes.circle} ${classes.greenCircle}`}>
              {instantGreenPass && (
                <ConardTooltip title={t('pages.gateIn.instantGreenPass.title')}>
                  <Spellcheck fontSize="large" className={classes.instantGreenPass} />
                </ConardTooltip>
              )}
            </div>
          </Box>
        )

      case DriverArrivalDtoDriverPassEnum.Orange:
        return (
          <Box className={classes.semaphore}>
            <div className={`${classes.circle} ${classes.greyCircle}`} />
            <div className={`${classes.circle} ${classes.orangeCircle}`} />
            <div className={`${classes.circle} ${classes.greyCircle}`} />
          </Box>
        )
      default:
        return (
          <Box className={classes.semaphore}>
            <div className={`${classes.circle} ${classes.redCircle}`} />
            <div className={`${classes.circle} ${classes.greyCircle}`} />
            <div className={`${classes.circle} ${classes.greyCircle}`} />
          </Box>
        )
    }
  }

  const getColorClass = () => {
    switch (driverPass) {
      case DriverArrivalDtoDriverPassEnum.Green:
        return classes.greenText

      case DriverArrivalDtoDriverPassEnum.Orange:
        return classes.orangeText

      default:
        return classes.redText
    }
  }

  return (
    <>
      <Box className={isCarrier ? classes.boxCarrier : classes.box}>
        {getSemaphore()}

        <Typography className={`${isCarrier ? classes.carrierText : classes.text} ${getColorClass()}`}>
          {t('driverApp.semaphore.driverPass.' + (driverPass ?? 'RED'))}
        </Typography>

        <IconButton onClick={handleChangeOpenDialog}>
          <Info fontSize="small" />
        </IconButton>
      </Box>

      <Dialog open={openHelp} onClose={handleChangeOpenDialog}>
        <DialogTitle>
          <>
            {t('driverApp.semaphore.semaphoreHelper.title')}
            <IconButton onClick={handleChangeOpenDialog} className={classes.closeButton}>
              <CloseIcon />
            </IconButton>
          </>
        </DialogTitle>
        <DialogContent>
          <div className={classes.dialogDiv}>
            <div className={`${classes.dialogCircle} ${classes.greenCircle}`}>
              <Spellcheck fontSize="small" className={classes.instantGreenPass} />
            </div>
            <span className={classes.helperSpan}>{t('driverApp.semaphore.semaphoreHelper.instantPass.title')}</span>
          </div>
          <div className={classes.helperDiv}>{t('driverApp.semaphore.semaphoreHelper.instantPass.content')}</div>

          <div className={classes.dialogDiv}>
            <div className={`${classes.dialogCircle} ${classes.greenCircle}`} />
            <span className={classes.helperSpan}>{t('driverApp.semaphore.semaphoreHelper.greenPass.title')}</span>
          </div>
          <div className={classes.helperDiv}>{t('driverApp.semaphore.semaphoreHelper.greenPass.content')}</div>

          <div className={classes.dialogDiv}>
            <div className={`${classes.dialogCircle} ${classes.orangeCircle}`} />
            <span className={classes.helperSpan}>{t('driverApp.semaphore.semaphoreHelper.orangePass.title')}</span>
          </div>
          <div className={classes.helperDiv}>{t('driverApp.semaphore.semaphoreHelper.orangePass.content')}</div>

          <div className={classes.dialogDiv}>
            <div className={`${classes.dialogCircle} ${classes.redCircle}`} />
            <span className={classes.helperSpan}>{t('driverApp.semaphore.semaphoreHelper.redPass.title')}</span>
          </div>
          <div className={classes.helperDiv}>{t('driverApp.semaphore.semaphoreHelper.redPass.content')}</div>
        </DialogContent>
      </Dialog>
    </>
  )
}
