import React, { FC, useEffect, useState } from 'react'
import { Mode } from '../../../enums/Mode'
import { CompanyDto, ShipownerDto } from '../../../api'
import { Grid, makeStyles, MenuItem, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Alert } from '@material-ui/lab'
import ConardTextField from '../../../components/ConardTextField'
import ConardSelect from '../../../components/ConardSelect'
import ConardButton from '../../../components/ConardButton'
import companyService from '../../../services/CompanyService'
import shipownerService from '../../../services/ShipownerService'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const useStyles = makeStyles({
  container: {
    marginTop: '8vh',
    width: '50vw',
    margin: 'auto',
    minWidth: '300px',
  },
  typographyLabel: {
    width: '50vw',
  },
  button: {
    width: '15vw',
    minWidth: '200px',
    textAlign: 'center',
    margin: 'auto',
  },
  alert: {
    margin: 'auto',
    width: '50vw',
    marginTop: '20vh',
  },
  colorDiv: {
    width: '10px',
    height: '10px',
    borderRadius: '100%',
    marginLeft: '10px',
  },
  companySelectItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
})

interface ShipownerFormProps {
  mode: Mode
  shipowner?: ShipownerDto
}

const ShipownerForm: FC<ShipownerFormProps> = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()
  const { shipowner, mode } = props

  const [companies, setCompanies] = useState<CompanyDto[] | undefined>(undefined)
  const [selectCompany, setSelectCompany] = useState(shipowner?.companyId ?? '')

  const shipownerSchema = yup.object().shape({
    name: yup.string().required(),
    companyId: yup.number().required(),
  })

  const { handleSubmit, control, errors } = useForm<ShipownerDto>({
    resolver: yupResolver(shipownerSchema),
    reValidateMode: 'onBlur',
  })

  useEffect(() => {
    getCompanies()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getCompanies = () => {
    companyService.findAllCompanies().then((response) => {
      setCompanies(response.data.content)
    })
  }

  const onSubmit = (values: ShipownerDto) => {
    if (mode === 'create') {
      shipownerService.create(values).then(() => {
        history.push('/customer/shipowner')
      })
    }
    if (mode === 'update') {
      shipownerService.update(shipowner?.id, { ...values }).then(() => {
        history.push('/customer/shipowner')
      })
    }
  }

  return (
    <>
      <Typography variant="h4" color="primary" className={classes.typographyLabel}>
        {mode === Mode.Update
          ? t('pages.shipownerManagement.labels.update')
          : t('pages.shipownerManagement.labels.create')}
      </Typography>

      {mode === Mode.Update && shipowner === undefined && (
        <Alert severity="error" className={classes.alert}>
          {t('be.error.entity.notFound')}
        </Alert>
      )}

      {(mode === Mode.Create || shipowner !== undefined) && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            className={classes.container}
            direction="row"
            spacing={7}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <ConardTextField
                id="name"
                name="name"
                type="text"
                label={t('entity.shipowner.fields.name')}
                defaultValue={shipowner?.name ?? ''}
                error={!!errors.name}
                helperText={errors.name ? t('pages.shipownerManagement.validation.required.name') : ' '}
                control={control}
              />
            </Grid>

            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <ConardSelect
                name="companyId"
                defaultValue={shipowner?.companyId ?? ''}
                value={selectCompany}
                onChange={(event) => {
                  setSelectCompany(event.target.value)
                }}
                error={!!errors.companyId}
                label={t('entity.shipowner.fields.company')}
                helperText={errors.companyId ? t('pages.shipownerManagement.validation.required.companyId') : ' '}
                control={control}
              >
                {companies && companies.length > 0 ? (
                  companies.map((company) => (
                    <MenuItem key={company.id} value={company.id}>
                      <div className={classes.companySelectItem}>
                        {company.name}
                        <div style={{ backgroundColor: company.color }} className={classes.colorDiv} />
                      </div>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>{t('pages.shipownerManagement.shipownerListLoading')}</MenuItem>
                )}
              </ConardSelect>
            </Grid>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <div className={classes.button}>
                <ConardButton conardVariant="dark" type="submit">
                  {mode === Mode.Create && t('pages.shipownerManagement.actions.submit.create')}
                  {mode === Mode.Update && t('pages.shipownerManagement.actions.submit.update')}
                </ConardButton>
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
              <div className={classes.button}>
                <ConardButton conardVariant="dark" onClick={() => history.goBack()}>
                  {t('pages.shipownerManagement.actions.cancel')}
                </ConardButton>
              </div>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  )
}

export default ShipownerForm
