import { AuditLogApi, AuditLogSearchDto, PageAuditLogDto } from '../api'
import myAxios from '../config/axios/axios'
import { AxiosPromise } from 'axios'

let api: AuditLogApi

export const setAuditLogApiConfiguration = () => {
  api = new AuditLogApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const auditService = {
  auditFindAll: (page: number, pageSize: number, currentSort: string[] | undefined): AxiosPromise<PageAuditLogDto> => {
    return api.auditLogFindAll(page, pageSize, currentSort)
  },
  auditSearch: (
    page: number | undefined,
    pageSize: number | undefined,
    currentSort: string[] | undefined,
    auditLogSearchDto: AuditLogSearchDto
  ): AxiosPromise<PageAuditLogDto> => {
    return api.auditLogSearch(page, pageSize, currentSort, auditLogSearchDto)
  },
}

export default auditService
