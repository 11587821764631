import React, { FC } from 'react'
import { createStyles, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { TextFieldProps } from '@material-ui/core/TextField/TextField'
import { Controller } from 'react-hook-form'
import { Control } from 'react-hook-form/dist/types/form'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      minWidth: '150px',
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(34px, -6px) scale(0.75)',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        '& legend': {
          marginLeft: '20px',
        },
      },
    },
    rootLarge: {
      width: '100%',
      minWidth: '350px',
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(34px, -6px) scale(0.75)',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        '& legend': {
          marginLeft: '20px',
        },
      },
    },
    inputRoot: {
      borderRadius: 30,
      borderColor: theme.palette.secondary.light,
      backgroundColor: theme.palette.secondary.main,
    },
    inputRootRoundedSquare: {
      borderRadius: 12,
    },
    selectSelect: {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  })
)

interface ConardSelectProps {
  name: string
  control: Control
  roundedSquare?: boolean
  large?: boolean
}

type ConardSelectPropsUnited = ConardSelectProps & Omit<TextFieldProps, 'name'>

const ConardSelect: FC<ConardSelectPropsUnited> = (props) => {
  const {
    name,
    roundedSquare,
    large,
    label,
    defaultValue,
    onChange,
    control,
    value,
    helperText,
    error,
    children,
    disabled,
  } = props

  const classes = useStyles()

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={(props) => (
        <TextField
          select
          label={label}
          inputRef={props.ref}
          defaultValue={props.value}
          disabled={disabled}
          onChange={(event) => {
            if (!!onChange) {
              onChange(event)
            }
            props.onChange(event)
          }}
          value={value}
          helperText={helperText}
          error={error}
          variant="outlined"
          InputProps={{
            className: roundedSquare ? `${classes.inputRoot} ${classes.inputRootRoundedSquare}` : classes.inputRoot,
          }}
          SelectProps={{
            classes: {
              select: classes.selectSelect,
            },
          }}
          className={large ? classes.rootLarge : classes.root}
        >
          {children}
        </TextField>
      )}
    />
  )
}

export default ConardSelect
