import React, { FC, useEffect } from 'react'
import { createStyles, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { SemitrailerIluCodeAutocomplete } from '../../../components/driver-app/SemitrailerIluCodeAutocomplete'
import { makeStyles } from '@material-ui/core/styles'
import { useFormContext, useWatch } from 'react-hook-form'
import { RHFTextField } from '../../../components/shared/rhf/RHFTextField'
import { AutocompleteItem } from '../../../model/autocomplete'
import { KioskLabel } from '../shared/KioskLabel'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      maxWidth: '800px',
      margin: 'auto',
      marginTop: '80px',
      minHeight: '400px',
    },
    whiteDiv: {
      backgroundColor: 'white',
      borderRadius: 12,
      boxShadow: '0 2px 10px gray',
      padding: '1.5rem',
    },
    label: {
      fontSize: 24,
      fontWeight: 650,
      color: theme.palette.primary.main,
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
    },
  })
)

export const KioskGateOutSemitrailerInfoForm: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { control, errors } = useFormContext()

  const iluCode = useWatch<AutocompleteItem>({
    name: 'iluCodeSemitrailer',
    control: control,
  })

  const licensePlate = useWatch<string>({
    name: 'semitrailerLicencePlate',
    control: control,
  })

  const reference = useWatch<string>({
    name: 'reference',
    control: control,
  })

  const disabledIluCode = !!licensePlate || !!reference
  const disabledLicencePlate = !!iluCode || !!reference
  const disabledReference = !!iluCode || !!licensePlate

  useEffect(() => {
    if (disabledIluCode) {
      control.trigger()
    }
    if (disabledLicencePlate) {
      control.trigger()
    }
    if (disabledReference) {
      control.trigger()
    }
  }, [control, disabledIluCode, disabledLicencePlate, disabledReference])

  return (
    <div className={classes.container}>
      <Grid container direction="column" spacing={2} className={classes.whiteDiv}>
        <Grid item>
          <Typography className={classes.label}>{t('kioskApp.step8.labelSemitrailerAddInfo')}</Typography>
        </Grid>

        <Grid item>
          <KioskLabel inactive={disabledIluCode}>{t('kioskApp.step8.iluCode')}</KioskLabel>
          <SemitrailerIluCodeAutocomplete
            disabled={disabledIluCode}
            error={!!errors.iluCodeSemitrailer}
            name="iluCodeSemitrailer"
          />
        </Grid>

        <Grid item>
          <KioskLabel inactive={disabledLicencePlate}>{t('kioskApp.step8.licensePlate')}</KioskLabel>
          <RHFTextField
            name="semitrailerLicencePlate"
            label=""
            disabled={disabledLicencePlate}
            error={!!errors.semitrailerLicencePlate}
          />
        </Grid>

        <Grid item>
          <KioskLabel inactive={disabledReference}>{t('kioskApp.step8.reference')}</KioskLabel>
          <RHFTextField name="reference" label="" disabled={disabledReference} error={!!errors.reference} />
        </Grid>
      </Grid>
    </div>
  )
}
