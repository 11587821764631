import React, { FC } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { createStyles, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { SemitrailerIcon } from '../../../assets/icons/SemitrailerIcon'
import { ContainerIcon } from '../../../assets/icons/ContainerIcon'
import { DriverArrivalDto, DriverArrivalDtoContainerTypeEnum } from '../../../api'
import { RHFConardToggle } from '../../../components/shared/rhf/RHFConardToggle'
import { KioskButtons } from '../KioskButtons'
import kioskDriverArrivalService from '../../../services/KioskDriverArrivalService'
import { useFormInitialValues } from '../../../hooks/useFormInitialValues'
import { useActualDriverArival } from '../shared/useActualDriverArival'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      maxWidth: '800px',
      margin: 'auto',
      marginTop: '80px',
      minHeight: '400px',
    },
    label: {
      fontSize: 28,
      fontWeight: 650,
      color: theme.palette.primary.main,
      display: 'flex',
      justifyContent: 'center',
    },
    icon: {
      scale: 7,
      color: theme.palette.primary.main,
    },
    iconWrapper: {
      display: 'flex',
      justifyContent: 'center',
      margin: '2rem 0',
    },
  })
)

type KioskGateOutCargoValues = {
  containerType: boolean
}

const initialValues: KioskGateOutCargoValues = {
  containerType: false,
}

interface KioskGateOutCargoProps {
  idDriverArrival: number
  previous: () => void
  next: () => void
}

export const KioskGateOutCargoTypeForm: FC<KioskGateOutCargoProps> = ({ previous, next, idDriverArrival }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const formMethods = useForm<KioskGateOutCargoValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
  })
  const { control, handleSubmit } = formMethods

  const isSemitrailer = useWatch({
    name: 'containerType',
    control: control,
  })

  const { driverArrival, loading } = useActualDriverArival(idDriverArrival)

  const onSubmit = async (formValue: KioskGateOutCargoValues) => {
    const { containerType: containerTypeForm } = formValue
    const isSemitrailer = containerTypeForm
    try {
      const updatedEntity: DriverArrivalDto = {
        ...driverArrival,
        driver: driverArrival?.driver,
        arrivalDateTime: driverArrival?.arrivalDateTime ?? '',
        containerType: isSemitrailer
          ? DriverArrivalDtoContainerTypeEnum.Semitrailer
          : DriverArrivalDtoContainerTypeEnum.ShippingContainer,
        iluCode: undefined,
        reference: undefined,
        semitrailerLicencePlate: undefined,
      }
      await kioskDriverArrivalService.update(idDriverArrival ?? 0, updatedEntity)
      next()
    } catch (err) {
      console.error(err)
    }
  }

  useFormInitialValues(mapValues(driverArrival), formMethods.reset)

  return (
    <div>
      {!loading && (
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction="column" spacing={3} className={classes.container}>
              <Grid item>
                <Typography className={classes.label}>{t('kioskApp.step7.label')}</Typography>
              </Grid>

              <Grid item className={classes.iconWrapper}>
                {isSemitrailer ? (
                  <SemitrailerIcon className={classes.icon} />
                ) : (
                  <ContainerIcon className={classes.icon} />
                )}
              </Grid>

              <Grid item>
                <RHFConardToggle
                  name="containerType"
                  option1name={t('kioskApp.step7.semitrailer')}
                  option2name={t('kioskApp.step7.shippingContainer')}
                />
              </Grid>
            </Grid>
            <KioskButtons visibleBack visibleNext onClickBack={() => previous()} />
          </form>
        </FormProvider>
      )}
    </div>
  )
}

const mapValues = (entity: Partial<DriverArrivalDto | undefined>): KioskGateOutCargoValues | undefined => {
  if (!entity) return undefined

  return {
    containerType: entity.containerType === DriverArrivalDtoContainerTypeEnum.Semitrailer,
  }
}
