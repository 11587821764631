import {
  ContainerDtoContainerTypeEnum,
  ContainerSimpleDtoContainerTypeEnum,
  ContainerSimpleDtoSemitrailerTypeEnum,
  ContainerSimpleDtoShippingContainerTypeEnum,
  PageTrainDto,
  TrainApi,
  TrainDto,
  TrainDtoTrainStateEnum,
  TrainSearchDto,
  TrainWagonContentDto,
  TrainWagonDto,
  TransitionDto,
} from '../api'
import myAxios from '../config/axios/axios'
import { AxiosPromise } from 'axios'
import { OrderWagonsFrom } from '../components/forms/ConardTrainForm'

let api: TrainApi

export const setTrainApiConfiguration = () => {
  api = new TrainApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const trainService = {
  findById: (id: number): AxiosPromise<TrainDto> => {
    return api.trainFindById(id)
  },

  update: (id: number, trainDto: TrainDto): AxiosPromise<TrainDto> => {
    return api.trainUpdate(id, trainDto)
  },

  changeState: (id: number, state: TrainDtoTrainStateEnum): AxiosPromise<TrainDto> => {
    return api.trainChangeState(id, state)
  },

  search: (page?: number, size?: number, sort?: string[], searchDto?: TrainSearchDto): AxiosPromise<PageTrainDto> => {
    return api.trainSearch(page, size, sort, searchDto)
  },

  searchCoparn: (
    page?: number,
    size?: number,
    sort?: string[],
    searchDto?: TrainSearchDto
  ): AxiosPromise<PageTrainDto> => {
    return api.trainSearchCoparn(page, size, sort, searchDto)
  },

  addWagonContent: (train: TrainDto, wagon: TrainWagonDto, wagonContent: TrainWagonContentDto): TrainDto => {
    const wagons = train.wagons?.filter((w) => w.id !== wagon.id) ?? []
    const newWagonContent = wagon.content?.filter((wc) => wc !== wagonContent) ?? []
    newWagonContent.push(wagonContent)
    const contentWeight = wagonContent?.transition?.grossWeight ?? 0

    wagons.splice(wagon.wagonOrder - OrderWagonsFrom, 0, {
      ...wagon,
      wagonContainerSum: (wagon.wagonContainerSum ?? 0) + contentWeight,
      content: newWagonContent,
    })

    return {
      ...train,
      wagons: wagons,
    }
  },

  addTransitionsOnWagonContent: (
    train: TrainDto,
    wagon: TrainWagonDto,
    wagonContent: TrainWagonContentDto,
    transition: TransitionDto
  ): TrainDto => {
    const newWagons = train.wagons?.filter((w) => w.id !== wagon.id) ?? []
    const newWagonContent = wagon.content?.filter((wc) => wc.id !== wagonContent.id) ?? []
    const tarraWeight = transition.container.tarraWeight ?? 0
    const grossWeight = (transition.netWeight ?? 0) + tarraWeight

    newWagonContent.splice(wagonContent.wagonContentOrder, 0, {
      ...wagonContent,
      transition: {
        id: transition.id,
        repleted: transition.repleted,
        netWeight: transition.netWeight,
        grossWeight: grossWeight,
        container: {
          id: transition.container.id,
          iluCode: transition.container.iluCode,
          containerType:
            transition.container.containerType === ContainerDtoContainerTypeEnum.ShippingContainer
              ? ContainerSimpleDtoContainerTypeEnum.ShippingContainer
              : ContainerSimpleDtoContainerTypeEnum.Semitrailer,
          shippingContainerType: transition.container
            .shippingContainerType as unknown as ContainerSimpleDtoShippingContainerTypeEnum,
          semitrailerType: transition.container.semitrailerType as unknown as ContainerSimpleDtoSemitrailerTypeEnum,
          tarraWeight: tarraWeight,
          teu: transition.container.teu,
        },
      },
    })

    newWagons.splice(wagon.wagonOrder - OrderWagonsFrom, 0, {
      ...wagon,
      wagonContainerSum: newWagonContent.reduce(
        (prevContent, currContent) =>
          (currContent.transition.netWeight ?? 0) + (currContent.transition.container.tarraWeight ?? 0),
        0
      ),
      content: newWagonContent,
    })

    return {
      ...train,
      wagons: newWagons,
    }
  },

  delete: (id: number): AxiosPromise<void> => {
    return api.trainDelete(id)
  },

  sendCodecos: (id: number): AxiosPromise<void> => {
    return api.trainSendCodecos(id)
  },

  getInvalidIluCodes: (train: TrainDto): AxiosPromise<Array<string>> => {
    return api.trainGetTrainInvalidIluCodes(train)
  },

  downloadExport: (id: number): AxiosPromise => {
    return api.trainDownloadExport(id, { responseType: 'blob' })
  },
}

export default trainService
