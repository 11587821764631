import React, { FC } from 'react'
import { Grid } from '@material-ui/core'
import ConardTextField from '../../components/ConardTextField'
import { useTranslation } from 'react-i18next'
import { TransitionSearchListSearchDto } from '../../api'
import { useForm } from 'react-hook-form'
import ConardButton from '../../components/ConardButton'

interface GateOutTransitionSelectionFilterProps {
  transitionFilter: (data: TransitionSearchListSearchDto) => void
}

const GateOutTransitionSelectionFilter: FC<GateOutTransitionSelectionFilterProps> = ({ transitionFilter }) => {
  const { t } = useTranslation()

  const { handleSubmit, control } = useForm<TransitionSearchListSearchDto>({})

  return (
    <form onSubmit={handleSubmit(transitionFilter)}>
      <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardTextField
            id="container.iluCode"
            name="container.iluCode"
            label={t('pages.gateIn.form.iluCode.label')}
            control={control}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
          <div>
            <ConardButton conardVariant="light" type="submit">
              {t('any.buttons.filter')}
            </ConardButton>
          </div>
        </Grid>
      </Grid>
    </form>
  )
}

export default GateOutTransitionSelectionFilter
