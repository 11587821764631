import React, { FC } from 'react'
import { RHFTextField } from '../../../components/shared/rhf/RHFTextField'
import { Grid, createStyles, makeStyles } from '@material-ui/core'
import ConardButton from '../../../components/ConardButton'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { DriverCarrierSearchDto } from '../../../api'

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonFilter: {
      borderRadius: 12,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    buttonReset: {
      borderRadius: 12,
    },
  })
)

type CarrierFilterFormValue = {
  name: string
}

const initialValues: CarrierFilterFormValue = {
  name: '',
}

interface CarrierFilterProps {
  carrierFilter: (data: DriverCarrierSearchDto) => void
}

export const CarrierFilter: FC<CarrierFilterProps> = ({ carrierFilter }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const formMethods = useForm<CarrierFilterFormValue>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
  })

  const onReset = () => {
    formMethods.reset(initialValues)
  }

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(carrierFilter)}>
          <Grid container direction="row" alignItems="center" justifyContent="flex-start" spacing={2}>
            <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
              <RHFTextField label="" name="name" placeholder={t('entity.driver.fields.driverName')} />
            </Grid>
            <Grid item xl={2} lg={3} md={6} sm={6} xs={12}>
              <ConardButton conardVariant="light" type="submit" className={classes.buttonFilter}>
                {t('any.buttons.filter')}
              </ConardButton>
            </Grid>
            <Grid item xl={2} lg={3} md={6} sm={6} xs={12}>
              <ConardButton conardVariant="transparent" type="submit" className={classes.buttonReset} onClick={onReset}>
                {t('any.buttons.resetFilter')}
              </ConardButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  )
}
