import React, { useState, useMemo, useCallback } from 'react'

type Mode = 'openStack' | 'chooseCoordinates'
interface TerminalLayoutActionState {
  mode: Mode
}

interface TerminalLayoutContextValue {
  mode: Mode
  setMode: (mode: Mode) => void
}

export const TerminalLayoutContext = React.createContext<TerminalLayoutContextValue | undefined>(undefined)

export const TerminalLayoutContextProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [internalState, setInternalState] = useState<TerminalLayoutActionState>({
    mode: 'openStack',
  })

  const setGlobalStateInternal = useCallback((mode: Mode) => {
    setInternalState((previous) => {
      if (previous.mode !== mode) {
        return {
          ...previous,
          mode: mode,
        }
      }

      return previous
    })
  }, [])

  const memoized = useMemo(
    () => ({
      mode: internalState.mode,
      setMode: setGlobalStateInternal,
    }),
    [internalState.mode, setGlobalStateInternal]
  )

  return <TerminalLayoutContext.Provider value={memoized}>{children}</TerminalLayoutContext.Provider>
}

TerminalLayoutContext.displayName = 'TerminalLayoutContext'
