import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, makeStyles, MenuItem } from '@material-ui/core'
import ConardTextField from '../../../components/ConardTextField'
import ConardSelect from '../../../components/ConardSelect'
import ConardButton from '../../../components/ConardButton'
import { CompanyDto, CompanySearchDto, ShipownerSearchDto } from '../../../api'
import { useForm } from 'react-hook-form'
import companyService from '../../../services/CompanyService'

const useStyles = makeStyles({
  container: {
    marginTop: '1vh',
    marginBottom: '2vh',
    margin: 'auto',
  },
  filterButton: {
    textAlign: 'center',
    margin: '20px',
    float: 'left',
  },
})

interface ShipownerFilterProps {
  onFilterSubmit: (values: ShipownerSearchDto) => void
}

const ShipownerFilter: FC<ShipownerFilterProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { onFilterSubmit } = props

  const emptyFilter: ShipownerSearchDto = {
    name: '',
    companyId: -1,
  }

  const { handleSubmit, control } = useForm<CompanySearchDto>({ defaultValues: emptyFilter })

  const [companies, setCompanies] = useState<CompanyDto[] | undefined>(undefined)
  const [selectCompany, setSelectedCompany] = useState('NOT-SELECTED')

  const getCompanies = () => {
    companyService.findAllCompanies().then((response) => {
      setCompanies(response.data.content)
    })
  }

  useEffect(() => {
    getCompanies()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <form onSubmit={handleSubmit(onFilterSubmit)}>
        <Grid
          container
          className={classes.container}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
            <ConardTextField
              id="name"
              name="name"
              type="text"
              defaultValue=""
              label={t('entity.shipowner.fields.name')}
              control={control}
            />
          </Grid>
          <Grid item xl={2} lg={2} md={6} sm={12} xs={12}>
            <ConardSelect
              name="companyId"
              id="companyId"
              defaultValue="NOT-SELECTED"
              value={selectCompany}
              onChange={(event) => {
                setSelectedCompany(event.target.value)
              }}
              control={control}
              label={t('entity.shipowner.fields.company')}
            >
              <MenuItem value="NOT-SELECTED">{t('pages.common.select.all')}</MenuItem>
              {companies?.map((company) => (
                <MenuItem key={company.id} value={company.id}>
                  {company.name}
                </MenuItem>
              ))}
            </ConardSelect>
          </Grid>
          <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
            <ConardButton conardVariant="light" type="submit">
              {t('any.buttons.filter')}
            </ConardButton>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default ShipownerFilter
