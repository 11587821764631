import { Box, IconButton, Typography } from '@mui/material'
import React, { FC, useMemo } from 'react'
import ConardTooltip from '../../components/ConardTooltip'
import LowPriorityIcon from '@mui/icons-material/LowPriority'
import { Output, SearchOutlined } from '@mui/icons-material'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { StackContainerDto, StackContainerDtoManipulationRequestStatusEnum } from '../../api'
import { useHistory } from 'react-router-dom'
import { useTerminalTemporaryDrawer } from '../../hooks/useTerminalTemporaryDrawerContext'
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

const ColorMap: Record<string | number, string> = {
  true: '#a0acb8',
  false: '#FFFFFF',
}

const useStyles = makeStyles({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #ffffff',
    padding: '10px',
    boxShadow: '1px 1px 5px grey',
  },
  codeText: {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  disabledText: {
    opacity: 0.5,
  },
  icons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '30px',
  },
  statusIcon: {
    position: 'absolute',
    top: '7px',
    right: '7px',
  },
  disabledIcon: {
    pointerEvents: 'none',
    opacity: 0.5,
  },
  reservation: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px dotted grey',
    padding: '10px',
    boxShadow: '1px 1px 5px grey',
    opacity: 0.5,
    height: '100px',
  },
})

interface ContainerProps {
  container: StackContainerDto
}

const Container: FC<ContainerProps> = ({ container }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  const { addContainer, containers } = useTerminalTemporaryDrawer()

  const containerIsInDrawer = useMemo(() => containers.some((c) => c.id === container.id), [containers, container.id])

  const waitingForManipulation =
    container.manipulationRequestStatus === StackContainerDtoManipulationRequestStatusEnum.Pending

  const handleClickDetail = (id: number | undefined) => {
    history.push(`/search/transition/detail/${id}`)
  }

  const handleClickGateOut = (id: number | undefined) => {
    history.push(`/gate-out/single-container/${id}`)
  }

  const handleClickMove = () => {
    addContainer({
      id: container.id ?? 0,
      iluCode: container.iluCode,
      teu: container.teu ?? 0,
    })
  }

  return (
    <>
      {!!container.reserved ? (
        <Box
          className={classes.reservation}
          sx={{
            backgroundColor: ColorMap[String(container.repleted)],
          }}
        >
          <Box className={classes.codeText}>
            <Typography>{container.iluCode}</Typography>
          </Box>

          <Box className={classes.statusIcon}>
            <ConardTooltip title={t('pages.terminalLayout.tooltip.reservation')}>
              <HelpOutlineIcon fontSize="default" />
            </ConardTooltip>
          </Box>
        </Box>
      ) : (
        <Box
          className={classes.container}
          sx={{
            backgroundColor: ColorMap[String(container.repleted)],
            pointerEvents: containerIsInDrawer ? 'none' : 'auto',
            opacity: containerIsInDrawer ? 0.5 : 1,
          }}
        >
          <Box className={classes.codeText}>
            <Typography className={waitingForManipulation ? classes.disabledText : ''}>{container.iluCode}</Typography>
          </Box>
          {waitingForManipulation && (
            <Box className={classes.statusIcon}>
              <ConardTooltip title={t('pages.terminalLayout.tooltip.waiting')}>
                <AccessAlarmIcon fontSize="default" />
              </ConardTooltip>
            </Box>
          )}

          <Box className={classes.icons}>
            <ConardTooltip title={t('pages.terminalLayout.tooltip.detail')}>
              <IconButton onClick={() => handleClickDetail(container.id)}>
                <SearchOutlined fontSize="small" />
              </IconButton>
            </ConardTooltip>
            <ConardTooltip title={t('pages.terminalLayout.tooltip.move')} disabled={waitingForManipulation}>
              <IconButton
                onClick={handleClickMove}
                disabled={waitingForManipulation}
                className={waitingForManipulation ? classes.disabledIcon : ''}
              >
                <LowPriorityIcon fontSize="small" />
              </IconButton>
            </ConardTooltip>
            <ConardTooltip title={t('pages.terminalLayout.tooltip.gateOut')} disabled={waitingForManipulation}>
              <IconButton
                onClick={() => {
                  handleClickGateOut(container.id)
                }}
                disabled={waitingForManipulation}
                className={waitingForManipulation ? classes.disabledIcon : ''}
              >
                <Output fontSize="small" />
              </IconButton>
            </ConardTooltip>
          </Box>
        </Box>
      )}
    </>
  )
}
export default Container
