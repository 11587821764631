import React, { FC, useEffect, useState } from 'react'
import { ContainerDtoContainerTypeEnum, NotificationDto } from '../../api'
import ConardTransitionForm from '../../components/forms/ConardTransitionForm'
import { TransitionMode } from '../../enums/TransitionMode'
import { useLocation } from 'react-router-dom'

interface GateInTransitionModeState {
  transitionMode?: TransitionMode
  notification?: NotificationDto
}

const ContainerGateInPage: FC = () => {
  const location = useLocation<GateInTransitionModeState>()
  const locationNotification = location?.state?.notification
  const locationTransitionMode = location?.state?.transitionMode

  const [transitionMode, setTransitionMode] = useState<TransitionMode>(
    locationTransitionMode !== undefined ? locationTransitionMode : TransitionMode.GATE_IN
  )
  const [notification, setNotification] = useState<NotificationDto | undefined>(locationNotification)

  useEffect(() => {
    if (locationNotification) {
      setNotification(locationNotification)
    }
    if (locationTransitionMode) {
      setTransitionMode(locationTransitionMode)
    }
  }, [locationNotification, locationTransitionMode])

  return (
    <ConardTransitionForm
      transitionMode={transitionMode}
      containerType={ContainerDtoContainerTypeEnum.ShippingContainer}
      notification={notification}
    />
  )
}

export default ContainerGateInPage
