import React, { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import { alpha, createStyles } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import ConardButton from '../ConardButton'
import transitionService from '../../services/TransitionService'

const useStyles = makeStyles((theme) =>
  createStyles({
    panelContainer: {
      marginTop: '4vh',
      marginBottom: '4vh',
      backgroundColor: alpha(theme.palette.secondary.dark, 0.3),
      borderRadius: '8px',
      textAlign: 'center',
      minWidth: '290px',
      padding: '20px 0px 20px 0px',
    },
    button: {
      marginTop: '20px',
    },
  })
)

interface ConardTransitionFormPdfExportBoxProps {
  transitionId: number
}

export const ConardTransitionFormPdfExportBox: FC<ConardTransitionFormPdfExportBoxProps> = (props) => {
  const { transitionId } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const handleDownload = () => {
    transitionService.downloadTransitionPdfExport(transitionId).then((response) => {
      const url = window.URL.createObjectURL(response.data)
      const a = document.createElement('a')
      a.href = url
      a.download = `transition_${transitionId}.pdf`
      a.click()
    })
  }

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.panelContainer}>
      <Grid item>
        <Typography variant="h5">{t('pages.gateIn.headers.exportTransitionPdf')}</Typography>
      </Grid>
      <Grid item>
        <ConardButton conardVariant="dark" className={classes.button} onClick={handleDownload}>
          {t('any.buttons.exportPdf')}
        </ConardButton>
      </Grid>
    </Grid>
  )
}
