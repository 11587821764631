import { Box, createStyles, Dialog, DialogContent, makeStyles, Typography } from '@material-ui/core'
import React, { FC } from 'react'
import ConardButton from '../../../components/ConardButton'
import { useTranslation } from 'react-i18next/'

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      borderRadius: 12,
      width: '240px',
      height: '50px',
      backgroundColor: '#e40000',
    },
    deleteAll: {
      borderRadius: 12,
      width: '240px',
      height: '50px',
      backgroundColor: theme.palette.error.dark,
      marginTop: '15px',
    },
    backButton: {
      borderRadius: 12,
      width: '240px',
      height: '50px',
      marginTop: '15px',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    buttonsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '250px',
    },
    text: {
      paddingBottom: '20px',
      textAlign: 'center',
      color: theme.palette.primary.dark,
      fontWeight: 600,
    },
  })
)

interface DeleteDialogProps {
  showDeleteDialog: boolean

  onClose: () => void
  onDeleteGateIn: () => void
  onDeleteGateOut: () => void
  onDeleteAll: () => void
}

export const DeleteDialog: FC<DeleteDialogProps> = ({
  showDeleteDialog,
  onClose,
  onDeleteGateIn,
  onDeleteGateOut,
  onDeleteAll,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Dialog open={showDeleteDialog} onClose={onClose}>
      <DialogContent>
        <Box>
          <Typography className={classes.text}>{t('driverApp.driverArrival.deleteDialog.text')}</Typography>
          <Box className={classes.buttonsWrapper}>
            <ConardButton conardVariant="red" onClick={onDeleteGateIn} className={classes.button}>
              {t('driverApp.driverArrival.buttons.deleteGateIn')}
            </ConardButton>
            <ConardButton conardVariant="red" onClick={onDeleteGateOut} className={classes.button}>
              {t('driverApp.driverArrival.buttons.deleteGateOut')}
            </ConardButton>
            <ConardButton conardVariant="red" onClick={onDeleteAll} className={classes.deleteAll}>
              {t('driverApp.driverArrival.buttons.deleteAll')}
            </ConardButton>
            <ConardButton conardVariant="transparent" type="reset" onClick={onClose} className={classes.backButton}>
              {t('driverApp.driverArrival.deleteDialog.buttons.back')}
            </ConardButton>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
