import { createStyles, makeStyles } from '@material-ui/core'
import React, { FC } from 'react'

const useStyles = makeStyles((theme) =>
  createStyles({
    switchContainer: {
      display: 'flex',
      backgroundColor: theme.palette.secondary.main,
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'rgba(0, 0, 0, 0.23)',
      alignItems: 'center',
      borderRadius: 12,
      width: '100%',
      height: 'auto',
      overflow: 'hidden',
    },
    toggleItem: {
      height: '50px',
      width: '100%',
      display: 'flex',
      borderRadius: 10,
      alignItems: 'center',
      justifyContent: 'center',
      padding: '6px 8px',
      cursor: 'pointer',
      margin: '4px',
      transition:
        'transform 0.3s ease-in-out, background-color 0.3s ease-in-out, opacity 0.3s ease-in-out, scale 0.3s ease-in-out, color 0.3s ease-in-out',
    },
    activeOption: {
      backgroundColor: theme.palette.primary.main,
      '& $text': {
        color: 'white',
      },
    },
    inactiveOption: {
      backgroundColor: 'transparent',
      '& $text': {
        color: 'darkGrey',
      },
    },
    text: {
      transition: 'color 0.3s ease-in-out',
      fontSize: '16px',
    },
  })
)

export interface ConardToggleProps {
  option1name: string
  option2name: string
  onChange: (value: boolean) => void
  activeOption: boolean | null
}

export const ConardToggle: FC<ConardToggleProps> = (props) => {
  const classes = useStyles()

  const handleClick = (val: boolean) => () => {
    props.onChange(val)
  }

  return (
    <div>
      <div className={classes.switchContainer}>
        <div
          className={`${classes.toggleItem} ${
            props.activeOption === true ? classes.activeOption : classes.inactiveOption
          }`}
          onClick={handleClick(true)}
        >
          <div className={classes.text}>{props.option1name}</div>
        </div>
        <div
          className={`${classes.toggleItem} ${
            props.activeOption === false ? classes.activeOption : classes.inactiveOption
          }`}
          onClick={handleClick(false)}
        >
          <div className={classes.text}>{props.option2name}</div>
        </div>
      </div>
    </div>
  )
}
