import myAxios from '../config/axios/axios'
import { PageAdrRidCodeDto, RidApi } from '../api'
import { AxiosPromise } from 'axios'

let api: RidApi

export const setRidApiConfiguration = () => {
  api = new RidApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const ridService = {
  findAllCodes: (
    page: number | undefined,
    pageSize: number | undefined,
    currentSort: string[] | undefined
  ): AxiosPromise<PageAdrRidCodeDto> => {
    return api.ridFindAllCodes(page, pageSize, currentSort)
  },
}

export default ridService
