import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/core'
import logoBrno from '../assets/images/logo/brno-logo.png'
import logoMosnov from '../assets/images/logo/mosnov-logo.png'
import logoDevcors from '../assets/images/logo/devcors-logo-black.png'

const useStyles = makeStyles((theme) =>
  createStyles({
    logo: {
      [theme.breakpoints.only('xl')]: {
        paddingTop: '6vh',
      },
      [theme.breakpoints.down('lg')]: {
        padding: '16px 0',
      },
    },
  })
)

export const useConardLogo = (width = 120) => {
  const classes = useStyles()

  switch (process.env.REACT_APP_CONARD_TERMINAL) {
    case 'BRNO':
      return <img width={width * 0.6 + 'px'} src={logoBrno} className={classes.logo} alt="" />
    case 'MOSNOV':
      return <img width={width + 'px'} src={logoMosnov} className={classes.logo} alt="" />
    default:
      return <img width={width + 'px'} src={logoDevcors} className={classes.logo} alt="" />
  }
}
