import {
  PageTruckMonitoringLogDto,
  TruckMonitoringLogApi,
  TruckMonitoringLogDto,
  TruckMonitoringLogSearchDto,
} from '../api'
import { AxiosPromise } from 'axios'
import myAxios from '../config/axios/axios'

let api: TruckMonitoringLogApi

export const setTruckMonitoringApiConfiguration = () => {
  api = new TruckMonitoringLogApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const truckMonitoringService = {
  findAll: (
    page: number,
    pageSize: number,
    currentSort: string[] | undefined
  ): AxiosPromise<PageTruckMonitoringLogDto> => {
    return api.truckMonitoringLogFindAll(page, pageSize, currentSort)
  },
  create: (logDto: TruckMonitoringLogDto): AxiosPromise<TruckMonitoringLogDto> => {
    return api.truckMonitoringLogCreate(logDto)
  },
  update: (id: number, logDto: TruckMonitoringLogDto): AxiosPromise<TruckMonitoringLogDto> => {
    return api.truckMonitoringLogUpdate(id, logDto)
  },
  delete: (id: number): AxiosPromise<void> => {
    return api.truckMonitoringLogDelete(id)
  },
  search: (
    page: number | undefined,
    pageSize: number | undefined,
    currentSort: string[] | undefined,
    logDto: TruckMonitoringLogSearchDto
  ): AxiosPromise<PageTruckMonitoringLogDto> => {
    return api.truckMonitoringLogSearch(page, pageSize, currentSort, logDto)
  },
}

export default truckMonitoringService
