import { Dialog, DialogActions, DialogContent, DialogTitle, styled, Typography } from '@mui/material'
import React, { FC } from 'react'
import LiveHelpIcon from '@mui/icons-material/LiveHelp'
import ConardButton from '../ConardButton'

export interface OperatorConfirmDialogProps {
  open: boolean
  title: string | undefined
  content: string
  primaryButtonText: string
  secondaryButtonText: string
  onPrimaryButtonClick: () => void
  onSecondaryButtonClick: () => void
}

export const OperatorConfirmDialog: FC<OperatorConfirmDialogProps> = ({
  open,
  title,
  content,
  primaryButtonText,
  secondaryButtonText,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
}) => {
  return (
    <Dialog open={open}>
      <StyledDialogTitle>
        <Typography component="p" variant="h2">
          {title}
        </Typography>
      </StyledDialogTitle>
      <StyledDialogContent>
        <div>
          <StyledHelpIcon />
          <StyledDialogText>{content}</StyledDialogText>
        </div>
      </StyledDialogContent>
      <DialogActions>
        <StyledButton conardVariant="dark" onClick={onPrimaryButtonClick}>
          {primaryButtonText}
        </StyledButton>
        <StyledButton conardVariant="light" onClick={onSecondaryButtonClick}>
          {secondaryButtonText}
        </StyledButton>
      </DialogActions>
    </Dialog>
  )
}

const StyledDialogTitle = styled(DialogTitle)({
  textAlign: 'center',
})

const StyledDialogContent = styled(DialogContent)({
  textAlign: 'center',
})

const StyledHelpIcon = styled(LiveHelpIcon)({
  fontSize: '100px',
})

const StyledDialogText = styled(Typography)({
  fontSize: '20px',
})
const StyledButton = styled(ConardButton)({
  borderRadius: '12px',
})
