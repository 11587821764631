import React, { FC, useEffect, useState } from 'react'
import UserEditForm from './UserEditForm'
import { UserApi, UserDto } from '../../api'
import { useParams } from 'react-router-dom'
import myAxios from '../../config/axios/axios'
import { Mode } from '../../enums/Mode'

interface UrlParamTypes {
  id?: string
}

const UserUpdatePage: FC = () => {
  const { id } = useParams<UrlParamTypes>()
  const userApi = new UserApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)

  const [user, setUser] = useState<UserDto>()
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (id !== undefined) {
      userApi
        .userFindById(Number.parseInt(id))
        .then((response) => {
          setUser(response.data)
          setLoaded(true)
        })
        .catch(() => {
          setLoaded(true)
        })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return <>{loaded && <UserEditForm mode={Mode.Update} user={user} />}</>
}

export default UserUpdatePage
