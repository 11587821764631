import {
  TrainDto,
  TrainDtoTrainStateEnum,
  TrainDtoTrainTypeEnum,
  TrainWagonContentDto,
  TrainWagonDto,
  TransitionSimpleDto,
} from '../../api'
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit'
import i18n from 'i18next'
import { OrderWagonsFrom } from '../../components/forms/ConardTrainForm'

export interface TrainState {
  train: TrainDto
  selectedUnseatedTransition: TransitionSimpleDto | null
  currentWagon: TrainWagonDto | null
  currentWagonContent: TrainWagonContentDto | null
  trainSubmitDisabled: boolean
  onTerminal: boolean
}

export const trainInitialState: TrainState = {
  train: {
    name: '',
    trainState: TrainDtoTrainStateEnum.OutgoingPlanned,
    trainType: TrainDtoTrainTypeEnum.Complete,
    wagons: [
      {
        name: '',
        wagonOrder: OrderWagonsFrom,
      },
    ],
    unseatedTransitions: [],
  },
  selectedUnseatedTransition: null,
  currentWagon: null,
  currentWagonContent: null,
  trainSubmitDisabled: false,
  onTerminal: false,
}

export const trainSlice = createSlice({
  name: 'train',
  initialState: trainInitialState,
  reducers: {
    setTrain(state: TrainState, action: PayloadAction<TrainDto>) {
      state.train = action.payload
    },
    setInitialTrain(state: TrainState) {
      state.train = {
        name: i18n.t('pages.train.labels.newTrainName'),
        trainState: TrainDtoTrainStateEnum.OutgoingPlanned,
        trainType: TrainDtoTrainTypeEnum.Complete,
        wagons: [
          {
            name: i18n.t('pages.train.labels.newTrainWagonName'),
            wagonOrder: OrderWagonsFrom,
          },
        ],
        unseatedTransitions: [],
        customsClearance: true,
      }
      state.onTerminal = false
    },
    shiftWagons(state: TrainState) {
      if (state.train.wagons) {
        const newWagons: TrainWagonDto[] = []
        state.train.wagons.forEach((wagon, index) =>
          newWagons.splice(0, 0, {
            ...wagon,
            wagonOrder: (state.train.wagons?.length ?? 0) - index - 1 + OrderWagonsFrom,
          })
        )
        state.train.wagons = newWagons
      }
    },
    addUnseatedContent(state: TrainState, action: PayloadAction<TransitionSimpleDto>) {
      state.train = {
        ...state.train,
        unseatedTransitions: [...(state.train.unseatedTransitions ?? []), action.payload],
      }
    },
    removeUnseatedContent(state: TrainState, action: PayloadAction<TransitionSimpleDto>) {
      state.train = {
        ...state.train,
        unseatedTransitions: state.train.unseatedTransitions?.filter((t) => t.id !== action.payload.id),
      }
    },
    clearUnseatedContent(state: TrainState) {
      state.train.unseatedTransitions = []
    },
    clearWagonContent(state: TrainState, action: PayloadAction<TrainWagonContentDto>) {
      const train = current(state.train)

      const wagonToUpdate = train.wagons?.find((wagon) => wagon.content?.includes(action.payload))

      if (wagonToUpdate) {
        const newWagons = train.wagons?.filter((w) => w !== wagonToUpdate) ?? []
        const newWagonContent = wagonToUpdate.content?.filter((wc) => wc !== action.payload) ?? []

        const newWagonContainerSum = newWagonContent.reduce(
          (prevContent, currContent) =>
            prevContent + (currContent.transition.netWeight ?? 0) + (currContent.transition.container.tarraWeight ?? 0),
          0
        )

        newWagons.splice(wagonToUpdate?.wagonOrder - OrderWagonsFrom ?? 0, 0, {
          ...wagonToUpdate,
          wagonContainerSum: newWagonContainerSum,
          content: newWagonContent,
        })

        state.train.wagons = newWagons
      }
    },
    setSelectedUnseatedTransition(state: TrainState, action: PayloadAction<TransitionSimpleDto | null>) {
      state.selectedUnseatedTransition = action.payload
    },
    setCurrentWagon(state: TrainState, action: PayloadAction<TrainWagonDto | null>) {
      state.currentWagon = action.payload
    },
    setCurrentWagonContent(state: TrainState, action: PayloadAction<TrainWagonContentDto | null>) {
      state.currentWagonContent = action.payload
    },
    setTrainName(state: TrainState, action: PayloadAction<string>) {
      state.train = {
        ...state.train,
        name: action.payload,
      }
    },
    setShipownerNumber(state: TrainState, action: PayloadAction<string>) {
      state.train = {
        ...state.train,
        shipownerNumber: action.payload,
      }
    },
    setTrainSubmitDisabled(state: TrainState, action: PayloadAction<boolean>) {
      state.trainSubmitDisabled = action.payload
    },
    setOnTerminal(state: TrainState, action: PayloadAction<boolean>) {
      state.onTerminal = action.payload
    },
  },
})

export const {
  setTrain,
  setInitialTrain,
  shiftWagons,
  addUnseatedContent,
  removeUnseatedContent,
  clearUnseatedContent,
  clearWagonContent,
  setSelectedUnseatedTransition,
  setCurrentWagon,
  setCurrentWagonContent,
  setTrainName,
  setShipownerNumber,
  setTrainSubmitDisabled,
  setOnTerminal,
} = trainSlice.actions
