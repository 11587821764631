import React, { useEffect, useRef, useState } from 'react'

import { Box, IconButton, Typography, createStyles, makeStyles, Grid } from '@material-ui/core'

import { RHFAutocomplete } from '../../components/shared/rhf/RHFAutocomplete'
import { AutocompleteDto, DriverArrivalDto } from '../../api'
import { FormProvider, useForm } from 'react-hook-form'
import autocompleteService from '../../services/AutocompleteService'
import { useTranslation } from 'react-i18next'
import { SemitrailerIcon } from '../../assets/icons/SemitrailerIcon'
import { ContainerIcon } from '../../assets/icons/ContainerIcon'
import driverArrivalService from '../../services/DriverArrivalService'
import { useHistory, useParams } from 'react-router-dom'
import { isAutocompleteItem } from '../../utils/forms'

const useStyles = makeStyles((theme) =>
  createStyles({
    icon: {
      scale: 5,
      color: theme.palette.secondary.main,
      margin: '30px',
    },
    iconButton: {
      borderRadius: 12,
      minWidth: '150px',
      flexGrow: 1,
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0 3px 10px rgb(0 0 0 / 0.8)',
      '&:hover, &.Mui-focusVisible, &:active': {
        backgroundColor: theme.palette.primary.main,
        boxShadow: '0 0',
      },
      transition: 'box-shadow 0.1s',
    },
    wrapper: {
      minHeight: '400px',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '30px',
      width: '100%',
    },
    typography: {
      textAlign: 'center',
      marginBottom: '30px',
      fontWeight: 650,
    },
    buttonsWrapper: {
      display: 'flex',
      gap: 15,
      flexDirection: 'row',
      justifyContent: 'space-between',
      minHeight: '120px',
    },
    iconText: {
      fontWeight: 650,
      color: theme.palette.secondary.main,
    },
    header: {
      fontWeight: 650,
      color: theme.palette.primary.main,
      alignItems: 'center',
      marginTop: '20px',
    },
    grid: {
      maxWidth: '600px',
      margin: 'auto',
    },
  })
)

type CheckIluFormValue = {
  ilucode: string
}

const initialValue: CheckIluFormValue = {
  ilucode: '',
}

export const CheckIluPage = () => {
  const formMethods = useForm<CheckIluFormValue>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValue,
  })

  const { t } = useTranslation()
  const classes = useStyles()

  const history = useHistory()

  const timerId = useRef<NodeJS.Timeout | null>(null)
  const [options, setOptions] = useState<AutocompleteDto[]>([])
  const [inputValue, setInputValue] = useState('')

  const apiCalled = useRef<boolean>(false)

  const [data, setData] = useState<DriverArrivalDto | undefined>(undefined)

  const { id: idDriverArrival } = useParams<{
    id: string
  }>()

  const onChangeTextFieldInput = async (value: string) => {
    setInputValue(value)

    if (timerId.current) {
      clearTimeout(timerId.current)
    }

    timerId.current = setTimeout(async () => {
      const response = await autocompleteService.find(value, 'CONTAINER')
      apiCalled.current = true
      setOptions(response.data)
    }, 500)
  }

  const onSubmit = (data: CheckIluFormValue) => {
    console.log(data)
  }

  const handleCLickSemitrailer = (id: number) => {
    const queryParams = new URLSearchParams({
      ilucode: inputValue,
      containerType: 'SEMITRAILER',
    }).toString()

    history.push(`/driver-arrival/gate-in/${id}?${queryParams}`)
  }

  const handleClickContainer = (id: number) => {
    const queryParams = new URLSearchParams({
      ilucode: inputValue,
      containerType: 'SHIPPING_CONTAINER',
    }).toString()

    history.push(`/driver-arrival/gate-in/${id}?${queryParams}`)
  }

  const handleOptionSelected = (option: AutocompleteDto, idDriverArrival: number) => {
    const queryParams = new URLSearchParams()
    if (option.id) {
      queryParams.append('containerId', option.id.toString())
    }

    history.push(`/driver-arrival/gate-in/${idDriverArrival}?${queryParams}`)
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await driverArrivalService.getById(Number(idDriverArrival))

      if (response.data) {
        setData(response.data)
      }
    }
    try {
      fetchData()
    } catch (err) {
      console.error(err)
    }
  }, [idDriverArrival])

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Box className={classes.grid}>
          <Typography variant="h5" className={classes.header}>
            {t('form.containerForm.iluCode')}
          </Typography>
          <RHFAutocomplete
            options={options.map((m) => ({ id: m.id ?? 0, name: m.name ?? '' }))}
            label=""
            name="ilucode"
            onTextInputChange={onChangeTextFieldInput}
            placeholder={t('form.containerForm.iluCodePlaceholder')}
            onChange={(autocompleteData) => {
              if (isAutocompleteItem(autocompleteData)) {
                handleOptionSelected(
                  {
                    id: autocompleteData.id,
                    name: autocompleteData.name,
                  },
                  data?.id ?? 0
                )
              }
            }}
          />

          {apiCalled.current && options.length === 0 && (
            <Box className={classes.wrapper}>
              <Typography className={classes.typography}>{t('form.containerForm.selectCargoType')}</Typography>

              <Box className={classes.buttonsWrapper}>
                <IconButton className={classes.iconButton} onClick={() => handleCLickSemitrailer(data?.id ?? 0)}>
                  <Grid container direction="column">
                    <Grid item>
                      <SemitrailerIcon className={classes.icon} />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.iconText}>{t('form.containerForm.type.semitrailer')}</Typography>
                    </Grid>
                  </Grid>
                </IconButton>

                <IconButton className={classes.iconButton} onClick={() => handleClickContainer(data?.id ?? 0)}>
                  <Grid container direction="column">
                    <Grid item>
                      <ContainerIcon className={classes.icon} />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.iconText}>
                        {t('form.containerForm.type.shippingContainer')}
                      </Typography>
                    </Grid>
                  </Grid>
                </IconButton>
              </Box>
            </Box>
          )}
        </Box>
      </form>
    </FormProvider>
  )
}
