import React, { FC } from 'react'
import { TransitionDto } from '../../../api'
import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) =>
  createStyles({
    whiteDiv: {
      backgroundColor: 'white',
      borderRadius: 12,
      boxShadow: '0 2px 10px gray',
      padding: '1.5rem',
      width: '270px',
    },
    adr: {
      color: theme.palette.warning.main,
      fontSize: 20,
      fontWeight: 600,
      marginBottom: '5px',
    },
    text: {
      color: theme.palette.warning.main,
    },
  })
)

interface KioskGateInAdrBoxProps {
  transition: TransitionDto
}

export const KioskGateInAdrBox: FC<KioskGateInAdrBoxProps> = ({ transition }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.whiteDiv}>
      <Grid container direction="column">
        <Grid item>
          <Typography className={classes.adr}>{t('kioskApp.step9.adr')}</Typography>
        </Grid>

        <Grid item>
          <Typography className={classes.text}>
            <b>{t('kioskApp.step9.adrCodes') + ': '}</b>
            {transition.adrRidCodes?.map((adr, index) => (index > 0 ? ', ' : '') + adr.unCode)}
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}
