import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import { StickyNote2Outlined } from '@mui/icons-material'
import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Order } from '../../enums/Order'
import { PageTransitionSearchListDto, TransitionSearchListSearchDto } from '../../api'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../redux/store'
import { setTransitionSearchListSearch } from '../../redux/transition/TransitionSearchListSearch'
import { cleanObject } from '../../utils/utils'
import gateOutService from '../../services/GateOutService'
import ConardProgressBar from '../../components/ConardProgressBar'
import ConardTooltip from '../../components/ConardTooltip'
import { format } from 'date-fns'
import ConardTransitionSearchIcon from '../../components/ConardTransitionSearchIcon'
import ConardButton from '../../components/ConardButton'
import {
  setTransition,
  setTransitionFormDisabled,
  setTransitionPositionDisabled,
  setTransitionSaveDisabled,
  transitionInitialState,
} from '../../redux/transition/transitionSlice'
import { useHistory } from 'react-router-dom'
import { PlannedGateOutFilter } from './PlannedGateOutFilter'

const useStyles = makeStyles((theme) =>
  createStyles({
    gridContainer: {
      marginTop: '8vh',
    },
    noteIcon: {
      color: theme.palette.primary.main,
    },
    companyCell: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    temporaryCustomer: {
      color: theme.palette.warning.main,
    },
    detailButton: {
      width: '7vw',
    },
    colorDiv: {
      width: '10px',
      height: '10px',
      borderRadius: '100%',
      marginLeft: '10px',
    },
  })
)

interface HeadCell {
  id: string
  label: string | ReactElement
}

export const PlannedGateOutPage = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const history = useHistory()

  const headCells: HeadCell[] = [
    { id: 'note', label: <StickyNote2Outlined fontSize="small" /> },
    { id: 'iluCode', label: t('pages.gateIn.form.iluCode.label') },
    { id: 'containerState', label: t('pages.gateIn.form.state.label') },
    { id: 'companyName', label: t('entity.company.fields.name') },
    { id: 'semitrailerLicencePlate', label: t('pages.gateIn.form.semitrailerLicencePlate.label') },
    {
      id: 'repleted',
      label: t('pages.gateIn.form.repleted.checked') + ' / ' + t('pages.gateIn.form.repleted.unchecked'),
    },
    { id: 'truckLicencePlate', label: t('pages.gateIn.form.truckLicencePlate.label') },
    { id: 'goArrivalDateTime', label: t('pages.gateIn.form.goArrivalDateTime.label') },
    { id: 'damaged', label: '' },
  ]

  const [order, setOrder] = useState<Order>(Order.Desc)
  const [orderBy, setOrderBy] = useState<string>('transitionDateTime')
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(150)
  const [blockSearch, setBlockSearch] = useState<boolean>(true)

  const [pageTransitions, setPageTransitions] = useState<PageTransitionSearchListDto | null>()
  const transitions = useSelector<RootState, TransitionSearchListSearchDto | undefined>(
    (state) => state.transitionSearchListSearch.transitionSearch
  )

  const search = (data: TransitionSearchListSearchDto | undefined, fromFilter: boolean) => {
    setBlockSearch(true)
    dispatch(setTransitionSearchListSearch(data))

    const dateFrom: Date | undefined = data?.transitionDateTimeFrom ? new Date(data?.transitionDateTimeFrom) : undefined
    const dateTo: Date | undefined = data?.transitionDateTimeTo ? new Date(data?.transitionDateTimeTo) : undefined

    data = {
      ...data,
      container: {
        ...cleanObject(data?.container),
        shipowner: {
          ...cleanObject(data?.container?.shipowner),
        },
        company: {
          ...cleanObject(data?.container?.company),
        },
      },
      containerStates: data?.containerStates ? data?.containerStates.filter((state) => !!state) : [],
      transitionDateTimeFrom: dateFrom?.toISOString(),
      transitionDateTimeTo: dateTo?.toISOString(),
    }

    let currentSort: Array<string> | undefined = undefined
    if (orderBy !== undefined) {
      const currentOrder: string = order !== undefined ? order : 'asc'
      currentSort = [orderBy + ',' + currentOrder]
    }

    gateOutService
      .getGateOutNightTransitionList(page, pageSize, currentSort, cleanObject(data))
      .then((response) => {
        setPageTransitions(response.data)
        if (fromFilter) {
          setPage(0)
        }
      })
      .catch()
      .finally(() => {
        setBlockSearch(false)
      })
  }

  const onDetail = (id: number | undefined) => {
    dispatch(setTransitionFormDisabled(true))
    dispatch(setTransitionPositionDisabled(true))
    dispatch(setTransitionSaveDisabled(true))
    dispatch(setTransition(transitionInitialState.transition))

    history.push(`/search/transition/detail/${id}`)
  }

  const handleSort = (property: string) => () => {
    const isAsc = orderBy === property && order === Order.Asc
    setOrder(isAsc ? Order.Desc : Order.Asc)
    setOrderBy(property)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value))
    setPage(0)
  }

  const searchFiltered = (data: TransitionSearchListSearchDto | undefined) => {
    search(data, true)
  }

  useEffect(() => {
    search(transitions, false)
  }, [page, pageSize, orderBy, order]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Typography variant="h4" color="primary">
        {t('pages.plannedGateOut.title')}
      </Typography>
      <div className={classes.gridContainer}>
        <PlannedGateOutFilter searchFilter={searchFiltered} blockSearch={blockSearch} />
      </div>
      <Grid container className={classes.gridContainer} direction="row" alignItems="center" spacing={3}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper variant="outlined">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={handleSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pageTransitions?.content?.map((transition) => (
                    <TableRow key={transition.id}>
                      <TableCell component="td" scope="row">
                        {transition.note ? (
                          <ConardTooltip title={transition.note}>
                            <StickyNote2Outlined className={classes.noteIcon} />{' '}
                          </ConardTooltip>
                        ) : (
                          ''
                        )}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {transition.iluCode}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {t('enum.containerState.' + transition.containerState)}
                      </TableCell>
                      <TableCell
                        component="td"
                        scope="row"
                        className={transition.customerName ? classes.temporaryCustomer : ''}
                      >
                        {transition.customerName ? (
                          <ConardTooltip title={t('pages.gateIn.form.temporaryCustomer.tooltip')}>
                            <div className={classes.companyCell}>
                              {transition.customerName}
                              <div style={{ backgroundColor: transition.customerColor }} className={classes.colorDiv} />
                            </div>
                          </ConardTooltip>
                        ) : (
                          <div className={classes.companyCell}>
                            {transition.companyName}
                            <div style={{ backgroundColor: transition.companyColor }} className={classes.colorDiv} />
                          </div>
                        )}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {transition.semitrailerLicencePlate}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {transition.repleted
                          ? t('pages.gateIn.form.repleted.checked')
                          : t('pages.gateIn.form.repleted.unchecked')}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {transition.truckLicencePlate}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {transition.transitionDateTime
                          ? format(new Date(transition.goArrivalDatetime ?? ''), 'dd.MM.yyyy HH:mm')
                          : ''}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        <ConardTransitionSearchIcon
                          transitionSearchListDto={{
                            containerType: transition.containerType,
                            semitrailerType: transition.semitrailerType,
                            shippingContainerType: transition.shippingContainerType,
                          }}
                          containerType={transition.containerType}
                          damaged={transition.damaged ?? false}
                        />
                      </TableCell>
                      <TableCell component="td" className={classes.detailButton}>
                        <ConardButton conardVariant="light" onClick={() => onDetail(transition.id)}>
                          {t('any.buttons.detail')}
                        </ConardButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <ConardProgressBar showBar={!pageTransitions} />
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 150]}
              component="div"
              count={50}
              rowsPerPage={pageSize}
              labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}
