import { AxiosPromise } from 'axios'
import { DriverArrivalApi, DriverArrivalDto, DriverCarrierSearchDto, PageDriverCarrierDto } from '../api'
import myAxios from '../config/axios/axios'

let api: DriverArrivalApi

export const setDriverArrivalApiConfiguration = () => {
  api = new DriverArrivalApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const driverArrivalService = {
  cancel: (id: number): AxiosPromise => {
    return api.driverArrivalCancel(id)
  },

  create: (driverArrivalDto: DriverArrivalDto): AxiosPromise<DriverArrivalDto> => {
    return api.driverArrivalCreateDriverArrival(driverArrivalDto)
  },

  get: (): AxiosPromise<DriverArrivalDto> => {
    return api.driverArrivalGetDriverArrival()
  },

  getById: (driverArrivalId: number): AxiosPromise<DriverArrivalDto> => {
    return api.driverArrivalGetDriverArrivalById(driverArrivalId)
  },

  update: (id: number, driverArrivalDto: DriverArrivalDto): AxiosPromise<DriverArrivalDto> => {
    return api.driverArrivalUpdateDriverArrival(id, driverArrivalDto)
  },

  getCarriersDriverArrival: (
    page: number | undefined,
    pageSize: number | undefined,
    currentSort: string[] | undefined,
    driverCarrierSearchDto?: DriverCarrierSearchDto
  ): AxiosPromise<PageDriverCarrierDto> => {
    return api.driverArrivalGetCarriersDriverArrival(page, pageSize, currentSort, driverCarrierSearchDto)
  },

  createDriverArrivalByCarrier: (
    driverId: number,
    driverArrivalDto: DriverArrivalDto
  ): AxiosPromise<DriverArrivalDto> => {
    return api.driverArrivalCreateDriverArrivalByCarrier(driverId, driverArrivalDto)
  },

  findByPlannedTransitionIdAndNew: (plannedTransitionId: number): AxiosPromise<DriverArrivalDto> => {
    return api.driverArrivalFindByPlannedTransitionIdAndNew(plannedTransitionId)
  },

  modifyInstantGreenPass: (id: number, instantGreenPass: boolean): AxiosPromise => {
    return api.driverArrivalModifyInstantGreenPass(id, instantGreenPass)
  },

  deleteGateInOrOut: (id: number, direction: 'IN' | 'OUT'): AxiosPromise => {
    return api.driverArrivalDeleteGateInOrOut(id, direction)
  },
}

export default driverArrivalService
