import jwt_decode from 'jwt-decode'
import { UserDtoRoleEnum } from '../api'
import { AuthorizationResponse } from '../services/AuthService'
import i18next from 'i18next'

export const accessTokenItemName = 'accessToken'
export const refreshTokenItemName = 'refreshToken'
export const tokenMinSize = 400

export const useConardAuth = () => {
  const getDecodedJwt = () => {
    const accessToken = localStorage.getItem(accessTokenItemName) + ''
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return jwt_decode(accessToken)
  }

  const getUsername = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const decodedJwt: any = getDecodedJwt()

    return decodedJwt.user_name
  }

  const getFirstName = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const decodedJwt: any = getDecodedJwt()

    return decodedJwt.firstName
  }

  const getSurname = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const decodedJwt: any = getDecodedJwt()

    return decodedJwt.surname
  }

  const getUserId = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const decodedJwt: any = getDecodedJwt()

    return decodedJwt.userId
  }

  const getRole = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const decodedJwt: any = getDecodedJwt()

    return decodedJwt.authorities[0]
  }

  const getLanguage = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const decodedJwt: any = getDecodedJwt()

    return decodedJwt.language
  }

  const isAuthenticated = () => {
    const accessToken = localStorage.getItem(accessTokenItemName) + ''
    return accessToken.length > tokenMinSize
  }

  const setAuthentication = (authenticationResponse: AuthorizationResponse) => {
    localStorage.setItem(accessTokenItemName, authenticationResponse.access_token)
    localStorage.setItem(refreshTokenItemName, authenticationResponse.refresh_token)
    i18next.changeLanguage(getLanguage())
  }
  const clearAuthentication = () => {
    localStorage.clear()
    window.location.reload()
  }

  const hasOneOfRoles = (rolesToCheck: Set<UserDtoRoleEnum> | undefined): boolean => {
    const accessToken = localStorage.getItem(accessTokenItemName) + ''
    if (accessToken.length > tokenMinSize) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const decodedJwt: any = jwt_decode(accessToken)
      const role = decodedJwt.authorities[0]
      if (rolesToCheck === undefined) {
        return true
      }

      let hasOneOfRoles = false
      rolesToCheck.forEach((oneRole) => {
        if (role === oneRole) {
          hasOneOfRoles = true
        }
      })

      return hasOneOfRoles
    }
    return false
  }

  return {
    getUsername,
    getFirstName,
    getSurname,
    getUserId,
    getRole,
    getLanguage,
    isAuthenticated,
    setAuthentication,
    clearAuthentication,
    hasOneOfRoles,
  }
}
