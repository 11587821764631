import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react'
import { CoparnDto, CoparnSearchDto, CoparnSearchDtoCoparnStatusEnum, CoparnTruckDto, PageCoparnDto } from '../../api'
import { Control } from 'react-hook-form/dist/types/form'
import { useTranslation } from 'react-i18next'
import { Order } from '../../enums/Order'
import { cleanObject, cleanShippingContainerType } from '../../utils/utils'
import {
  createStyles,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Theme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import GateOutEmptyCoparnFilter from './GateOutCoparnEmptyFilter'
import coparnService from '../../services/CoparnService'
import ConardButton from '../../components/ConardButton'
import ConardProgressBar from '../../components/ConardProgressBar'
import { ConfigContext } from '../../context/ConfigContext'
import gateOutService from '../../services/GateOutService'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      width: '60vw',
    },
    selectedTableRow: {
      backgroundColor: theme.palette.primary.light,
    },
    buttonCell: {
      display: 'flex',
      alignItems: 'flex-end',
      flexDirection: 'column',
    },
    button: {
      width: '15vw',
      minWidth: '200px',
      right: 0,
    },
  })
)

interface GateOutCoparnEmptySelectPageProps {
  control: Control<CoparnTruckDto>
  driverId?: number
  notificationReference?: string
}

interface HeadCell {
  id: string
  label: string
}

const GateOutCoparnEmptySelectPage: FC<GateOutCoparnEmptySelectPageProps> = ({
  control,
  driverId,
  notificationReference,
}) => {
  const { t } = useTranslation()

  const [coparns, setCoparns] = useState<PageCoparnDto | null>(null)
  const [loaded, setLoaded] = useState(false)

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(50)
  const [order, setOrder] = useState<Order>(Order.Asc)
  const [orderBy, setOrderBy] = useState<string>('medlogReference')
  const [searchDto, setSearchDto] = useState<CoparnSearchDto | undefined>(
    !!notificationReference ? { medlogReference: notificationReference } : undefined
  )
  const [selectedCoparnId, setSelectedCoparnId] = useState<number | undefined>(undefined)
  const { generalCarrierReference } = useContext(ConfigContext)

  const classes = useStyles()

  const headCells: HeadCell[] = [
    {
      id: 'medlogReference',
      label: generalCarrierReference ? t('pages.gateOut.coparn.carrierRef') : t('pages.gateOut.coparn.medlogRef'),
    },
    { id: 'uniqueShippingContainerType', label: t('pages.gateOut.coparn.detail.containerType') },
    { id: 'uniqueCoparnContainerQuality', label: t('pages.gateOut.coparn.detail.quality') },
    { id: 'shipownerName', label: t('pages.gateOut.coparn.contractHolder') },
    { id: 'numberOfProcessedContainers', label: t('pages.gateOut.coparn.processed') },
  ]

  useEffect(() => {
    if (driverId) {
      const fetchDriverCoparn = async () => {
        try {
          const response = await gateOutService.getDriverCoparnGateOut(driverId)
          const driverCoparn = response.data.coparn

          if (driverCoparn) {
            const convertedCoparns: PageCoparnDto = {
              content: [driverCoparn],
              totalElements: 1,
            }
            setCoparns(convertedCoparns)
            setSelectedCoparnId(driverCoparn?.id)
            control.setValue('coparn', driverCoparn)
          }
          setLoaded(true)
        } catch (error) {
          console.error('Error fetching driver coparn:', error)
        }
      }
      fetchDriverCoparn()
    } else {
      search(searchDto, false)
    }
  }, [page, pageSize, orderBy, order, driverId]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSort = (property: string) => () => {
    const isAsc = orderBy === property && order === Order.Asc
    setOrder(isAsc ? Order.Desc : Order.Asc)
    setOrderBy(property)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value))
    setPage(0)
  }

  const searchFiltered = (data: CoparnSearchDto | undefined) => {
    search(data, true)
  }

  const search = async (data: CoparnSearchDto | undefined, fromFilter: boolean) => {
    setSearchDto(data)
    data = {
      ...data,
      coparnStatus: CoparnSearchDtoCoparnStatusEnum.New,
    }

    let currentSort: Array<string> | undefined = undefined
    if (orderBy !== undefined) {
      const currentOrder: string = order !== undefined ? order : 'asc'
      currentSort = [orderBy + ',' + currentOrder]
    }

    const response = await coparnService.searchCoparns(page, pageSize, currentSort, cleanObject(data))
    setCoparns(response.data)
    setLoaded(true)

    if (fromFilter) {
      setPage(0)
    }

    return
  }

  const handleCoparnSet = (coparn: CoparnDto) => {
    if (coparn.id === selectedCoparnId) {
      control.setValue('coparn', undefined)
      setSelectedCoparnId(undefined)
    } else {
      control.setValue('coparn', coparn)
      setSelectedCoparnId(coparn.id)
    }
  }

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={3}
        style={{ marginBottom: '10vh' }}
      >
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <GateOutEmptyCoparnFilter coparnFilter={searchFiltered} reference={notificationReference} />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper variant="outlined">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={handleSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!coparns?.content?.length &&
                    coparns?.content?.map((coparn) => (
                      <TableRow
                        className={coparn.id === selectedCoparnId ? classes.selectedTableRow : ''}
                        key={coparn.id}
                      >
                        <TableCell component="th" scope="row">
                          {coparn.medlogReference}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {cleanShippingContainerType(coparn.uniqueShippingContainerType)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {coparn.uniqueCoparnContainerQuality}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {coparn.shipowner?.name}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                        >{`${coparn.numberOfProcessedContainers} / ${coparn.numberOfContainers}`}</TableCell>
                        <TableCell className={classes.buttonCell} component="th" scope="row">
                          <div className={classes.button}>
                            <ConardButton conardVariant="dark" onClick={() => handleCoparnSet(coparn)}>
                              {coparn.id === selectedCoparnId ? t('any.buttons.unassign') : t('any.buttons.assign')}
                            </ConardButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  {loaded && (!coparns || coparns?.content?.length === 0) && (
                    <TableRow key="noData">
                      <TableCell component="th" scope="row" colSpan={10}>
                        {t('pages.common.table.noData')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <ConardProgressBar showBar={!loaded} />
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 150]}
              component="div"
              count={coparns?.totalElements ?? 0}
              rowsPerPage={pageSize}
              labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default GateOutCoparnEmptySelectPage
