import React, { FC } from 'react'
import { LinearProgress, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  progressBar: {
    margin: 10,
  },
})

export interface ConardProgressBarProps {
  showBar: boolean
}

const ConardProgressBar: FC<ConardProgressBarProps> = ({ showBar }) => {
  const classes = useStyles()

  return <>{showBar && <LinearProgress className={classes.progressBar} />}</>
}

export default ConardProgressBar
