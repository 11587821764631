import React, { FC, useState } from 'react'
import { CircularProgress, Grid, makeStyles } from '@material-ui/core'
import { ConardLabel } from '../../../components/transition/DriverFields'
import ConardTextField from '../../../components/ConardTextField'
import { useTranslation } from 'react-i18next'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import ConardPhotographer from '../../../components/ConardPhotographer'
import { useFormContext } from 'react-hook-form'
import { SignetPhotoDetail } from '../../../components/dialogs/SignetPhotoDetail'
import { circularProgressClasses } from '@mui/material'

const useStyles = makeStyles(() => ({
  photo: {
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: 12,
  },
  photoDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '100%',
    marginTop: '23px',
    borderRadius: 12,
    height: '70%',
  },
  emptyPhoto: {
    border: '1px dashed lightgray',
  },
  photoIcon: {
    color: 'gray',
  },
  circularProgress: {
    [`& .${circularProgressClasses.circle}`]: {
      strokeLinecap: 'round',
      color: 'lightgray',
    },
  },
}))

interface SignetFieldProps {
  signetPhoto: string
  loadingSignetPhoto: boolean
  handleNewPhoto: (photo: string) => void
}

export const SignetField: FC<SignetFieldProps> = ({ signetPhoto, loadingSignetPhoto, handleNewPhoto }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { control } = useFormContext()

  const [showPhotographer, setShowPhotographer] = useState(false)
  const [showPhotoDetail, setShowPhotoDetail] = useState(false)

  const handleClosePhotographer = () => {
    setShowPhotographer(false)
  }

  const handleAcceptPhoto = (photo: string) => {
    setShowPhotographer(false)
    handleNewPhoto(photo)
  }

  const handleShowPhotoDetail = () => {
    setShowPhotoDetail(!showPhotoDetail)
  }

  const handleDeletePhoto = () => {
    setShowPhotoDetail(false)
    handleNewPhoto('')
  }

  const handleAddPhoto = () => {
    if (!loadingSignetPhoto) {
      setShowPhotographer(true)
      setShowPhotoDetail(false)
    }
  }

  return (
    <>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={9}>
          <ConardLabel>{t('form.transitionForm.signet')}</ConardLabel>
          <ConardTextField roundedSquare name="signetValue" control={control} defaultValue="" />
        </Grid>

        <Grid item xs={3}>
          <div
            className={!!signetPhoto ? classes.photoDiv : `${classes.photoDiv} ${classes.emptyPhoto}`}
            onClick={!!signetPhoto ? handleShowPhotoDetail : handleAddPhoto}
          >
            {!!signetPhoto ? (
              <img src={signetPhoto} alt="" className={classes.photo} />
            ) : loadingSignetPhoto ? (
              <CircularProgress size={30} thickness={6} className={classes.circularProgress} />
            ) : (
              <AddAPhotoIcon className={classes.photoIcon} />
            )}
          </div>
        </Grid>
      </Grid>

      <ConardPhotographer
        title={t('form.transitionForm.signet')}
        open={showPhotographer}
        onClose={handleClosePhotographer}
        onAccept={handleAcceptPhoto}
      />

      <SignetPhotoDetail
        signetPhoto={signetPhoto}
        handleAddPhoto={handleAddPhoto}
        handleDeletePhoto={handleDeletePhoto}
        showPhotoDetail={showPhotoDetail}
        handleShowPhotoDetail={handleShowPhotoDetail}
      />
    </>
  )
}
