import { TrainCompareResultDto, M2mTrainApi, M2mTrainSearchDto, PageM2mTrainDto } from '../api'
import myAxios from '../config/axios/axios'
import { AxiosPromise } from 'axios'

let api: M2mTrainApi

export const setM2mTrainApiConfiguration = () => {
  api = new M2mTrainApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const m2mTrainService = {
  search: (
    page?: number,
    size?: number,
    sort?: string[],
    searchDto?: M2mTrainSearchDto
  ): AxiosPromise<PageM2mTrainDto> => {
    return api.m2mTrainSearch(page, size, sort, searchDto)
  },
  compare: (trainId: number, m2mTrainId: number): AxiosPromise<TrainCompareResultDto> => {
    return api.m2mTrainCompare(trainId, m2mTrainId)
  },

  downloadExport: (trainId: number, m2mTrainId: number): AxiosPromise => {
    return api.m2mTrainDownloadExport(trainId, m2mTrainId, { responseType: 'blob' })
  },
}

export default m2mTrainService
