import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AutocompleteDto,
  CarrierShortDto,
  ContainerDtoContainerTypeEnum,
  DriverShortDto,
  LicensePlateDto,
} from '../../api'
import driverService from '../../services/DriverService'
import ConardTextAutocomplete from '../ConardTextAutocomplete'
import { AccordionDetails, Grid } from '@material-ui/core'
import { ConardAccordion, ConardAccordionSummary } from '../transition/ConardAccordion'
import { ConardLabel } from '../transition/DriverFields'
import { Control } from 'react-hook-form/dist/types/form'

interface DriverFormProps {
  driverId: number | undefined
  control: Control

  defaultExpanded?: boolean
  disableDriver?: boolean
  disableCarrier?: boolean
  containerType?: ContainerDtoContainerTypeEnum
}

const DriverInfoComponent: FC<DriverFormProps> = ({
  driverId,
  defaultExpanded,
  control,
  containerType = ContainerDtoContainerTypeEnum.ShippingContainer,
  disableDriver,
  disableCarrier,
}) => {
  const { t } = useTranslation()
  const [driver, setDriver] = useState<DriverShortDto>({ id: undefined, name: '' })
  const [carrier, setCarrier] = useState<CarrierShortDto>({ id: undefined, name: '' })
  const [frontLicensePlate, setFrontLicensePlate] = useState<LicensePlateDto>({ id: undefined, licensePlate: '' })
  const [rearLicensePlate, setRearLicensePlate] = useState<LicensePlateDto>({ id: undefined, licensePlate: '' })

  useEffect(() => {
    if (driverId !== undefined) {
      driverService.findById(driverId).then((response) => {
        setDriver({
          id: response.data.id,
          name: response.data.name,
        })
        setCarrier({
          id: response.data.carrierShort?.id,
          name: response.data.carrierShort?.name ?? '',
        })
        setFrontLicensePlate({
          id: response.data.frontLicensePlate?.id,
          licensePlate: response.data.frontLicensePlate?.licensePlate ?? '',
        })
        setRearLicensePlate({
          id: response.data.rearLicensePlate?.id,
          licensePlate: response.data.rearLicensePlate?.licensePlate ?? '',
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChooseDriver = (autocompleteDto: AutocompleteDto) => {
    setDriver(autocompleteDto as DriverShortDto)
    driverService.findById(autocompleteDto.id ?? -1).then((response) => {
      setCarrier({
        id: response.data.carrierShort?.id,
        name: response.data.carrierShort?.name ?? '',
      })
      setFrontLicensePlate({
        id: response.data.frontLicensePlate?.id,
        licensePlate: response.data.frontLicensePlate?.licensePlate ?? '',
      })
      setRearLicensePlate({
        id: response.data.rearLicensePlate?.id,
        licensePlate: response.data.rearLicensePlate?.licensePlate ?? '',
      })
    })
  }

  const changeDriver = (autocompleteDto: AutocompleteDto) => {
    setDriver(autocompleteDto as DriverShortDto)
  }

  const changeCarrier = (autocompleteDto: AutocompleteDto) => {
    setCarrier(autocompleteDto as CarrierShortDto)
  }

  const changeFrontLicensePlate = (autocompleteDto: AutocompleteDto) => {
    setFrontLicensePlate({
      id: autocompleteDto.id,
      licensePlate: autocompleteDto.name ?? '',
    })
  }

  const changeRearLicensePlate = (autocompleteDto: AutocompleteDto) => {
    setRearLicensePlate({
      id: autocompleteDto.id,
      licensePlate: autocompleteDto.name ?? '',
    })
  }

  useEffect(() => {
    control.setValue('driverShort', driver)
  }, [driver, control])

  useEffect(() => {
    control.setValue('carrierShort', carrier)
  }, [carrier, control])

  useEffect(() => {
    control.setValue('frontLicensePlate', frontLicensePlate)
  }, [frontLicensePlate, control])

  useEffect(() => {
    control.setValue('rearLicensePlate', rearLicensePlate)
  }, [rearLicensePlate, control])

  return (
    <>
      <ConardAccordion defaultExpanded={defaultExpanded ?? true}>
        <ConardAccordionSummary>{t('form.driverForm.title')}</ConardAccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
            <Grid item xs={12}>
              <ConardLabel inactive={!!disableDriver}>{t('form.driverForm.driver')}</ConardLabel>
              <ConardTextAutocomplete
                roundedSquare
                id="driverShort"
                name="driverShort"
                control={control}
                onChoose={onChooseDriver}
                value={driver}
                setValue={changeDriver}
                optionType="DRIVER"
                disabled={!!disableDriver}
              />
            </Grid>

            <Grid item xs={12}>
              <ConardLabel inactive={!!disableCarrier}>{t('form.driverForm.carrier')}</ConardLabel>
              <ConardTextAutocomplete
                roundedSquare
                id="carrierShort"
                name="carrierShort"
                control={control}
                value={carrier}
                setValue={changeCarrier}
                optionType="CARRIER"
                disabled={!!disableCarrier}
              />
            </Grid>

            <Grid item xs={12}>
              <ConardLabel>{t('form.driverForm.frontLicensePlate')}</ConardLabel>
              <ConardTextAutocomplete
                roundedSquare
                id="frontLicensePlate"
                name="frontLicensePlate"
                control={control}
                optionType="LICENSE_PLATE"
                value={{ id: frontLicensePlate.id, name: frontLicensePlate.licensePlate }}
                setValue={changeFrontLicensePlate}
              />
            </Grid>

            {containerType === ContainerDtoContainerTypeEnum.ShippingContainer && (
              <Grid item xs={12}>
                <ConardLabel>{t('form.driverForm.rearLicensePlate')}</ConardLabel>
                <ConardTextAutocomplete
                  roundedSquare
                  id="rearLicensePlate"
                  name="rearLicensePlate"
                  control={control}
                  optionType="LICENSE_PLATE"
                  value={{ id: rearLicensePlate.id, name: rearLicensePlate.licensePlate }}
                  setValue={changeRearLicensePlate}
                />
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </ConardAccordion>
    </>
  )
}

export default DriverInfoComponent
