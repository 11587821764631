import { useEffect, useState } from 'react'
import { DriverArrivalDto } from '../../../api'
import { fetchDriverArrival } from './fetchDriverArrival'

export const useActualDriverArival = (id: number) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [driverArrival, setDriverArrival] = useState<DriverArrivalDto | undefined>(undefined)

  useEffect(() => {
    setLoading(true)
    fetchDriverArrival(id).then((response) => {
      setDriverArrival(response)
      setLoading(false)
    })
  }, [id])

  return {
    loading,
    driverArrival,
  }
}
