import { AxiosPromise } from 'axios'
import myAxios from '../config/axios/axios'
import { AsparkingTicketApi, AsparkingTicketDto, AsparkingTicketSearchDto, PageAsparkingTicketDto } from '../api'

let api: AsparkingTicketApi

export const seAsparkingTicketApiConfiguration = () => {
  api = new AsparkingTicketApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const ticketService = {
  findAll: (
    page: number,
    pageSize: number,
    currentSort: string[] | undefined
  ): AxiosPromise<PageAsparkingTicketDto> => {
    return api.asparkingTicketFindAll(page, pageSize, currentSort)
  },
  ticketUpdate: (id: number, ticketDto: AsparkingTicketDto): AxiosPromise<AsparkingTicketDto> => {
    return api.asparkingTicketUpdate(id, ticketDto)
  },
  ticketDelete: (id: number): AxiosPromise<void> => {
    return api.asparkingTicketDelete(id)
  },
  ticketSearch: (
    page: number | undefined,
    pageSize: number | undefined,
    currentSort: string[] | undefined,
    ticketSearchDto: AsparkingTicketSearchDto
  ): AxiosPromise<PageAsparkingTicketDto> => {
    return api.asparkingTicketSearch(page, pageSize, currentSort, ticketSearchDto)
  },
  ticketFindById: (id: number): AxiosPromise<AsparkingTicketDto> => {
    return api.asparkingTicketFindById(id)
  },
}

export default ticketService
