import React, { FC } from 'react'
import { DriverArrivalDto, DriverArrivalDtoContainerTypeEnum } from '../../../api'
import { Box, createStyles, Grid, makeStyles, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ContainerIcon } from '../../../assets/icons/ContainerIcon'
import { SemitrailerIcon } from '../../../assets/icons/SemitrailerIcon'
import { KioskWithoutTransitionText } from './KioskWithoutTransitionText'

const useStyles = makeStyles((theme) =>
  createStyles({
    label: {
      fontSize: 24,
      fontWeight: 650,
      color: theme.palette.primary.main,
    },
    text: {
      color: theme.palette.primary.main,
    },
    whiteDiv: {
      backgroundColor: 'white',
      borderRadius: 12,
      boxShadow: '0 2px 10px gray',
      padding: '1rem',
      width: '270px',
    },
    box: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    icon: {
      scale: 3,
      color: theme.palette.primary.main,
      margin: '10px 20px',
    },
    iluCode: {
      color: theme.palette.primary.main,
      fontWeight: 650,
    },
  })
)

interface KioskGateOutSummaryProps {
  driverArrivalDto: DriverArrivalDto | undefined
}

export const KioskGateOutSummary: FC<KioskGateOutSummaryProps> = ({ driverArrivalDto }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Grid container direction="column" alignItems="center" spacing={3}>
      <Grid item>
        <Typography className={classes.label}>{t('kioskApp.step9.gateOut')}</Typography>
      </Grid>

      {!driverArrivalDto?.withoutGateOut && !!driverArrivalDto?.containerType ? (
        <>
          <Grid item>
            <div className={classes.whiteDiv}>
              <Grid container direction="row" alignItems="center" justifyContent="space-around">
                <Grid item>
                  <Box className={classes.box}>
                    {driverArrivalDto.containerType === DriverArrivalDtoContainerTypeEnum.ShippingContainer ? (
                      <ContainerIcon className={classes.icon} />
                    ) : (
                      <SemitrailerIcon className={classes.icon} />
                    )}
                    {driverArrivalDto.repleted !== undefined &&
                      driverArrivalDto.containerType !== DriverArrivalDtoContainerTypeEnum.Semitrailer && (
                        <Typography className={classes.text}>
                          <b>{t(`kioskApp.step9.${driverArrivalDto.repleted ? 'repleted' : 'notRepleted'}`)}</b>
                        </Typography>
                      )}
                  </Box>
                </Grid>

                {!!driverArrivalDto.iluCode && (
                  <Grid item>
                    <Typography className={classes.iluCode}>{driverArrivalDto.iluCode}</Typography>
                  </Grid>
                )}
              </Grid>
            </div>
          </Grid>
          {!!driverArrivalDto.semitrailerLicencePlate && (
            <Grid item>
              <Typography className={classes.text}>
                <b>{t('kioskApp.step9.semitrailerLicencePlate') + ': '}</b>
                {driverArrivalDto.semitrailerLicencePlate}
              </Typography>
            </Grid>
          )}
          {!!driverArrivalDto.reference && (
            <Grid item>
              <Typography className={classes.text}>
                <b>{t('kioskApp.step9.reference') + ': '}</b>
                {driverArrivalDto.reference}
              </Typography>
            </Grid>
          )}{' '}
        </>
      ) : (
        <KioskWithoutTransitionText />
      )}
    </Grid>
  )
}
