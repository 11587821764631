import { UploadDownloadApi } from '../api'
import { AxiosPromise } from 'axios'
import myAxios from '../config/axios/axios'

let api: UploadDownloadApi

export const setUploadDownloadApiConfiguration = () => {
  api = new UploadDownloadApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const uploadDownloadApi = {
  uploadCustomsFile: (transitionId: number, file: File): AxiosPromise<void> => {
    return api.uploadDownloadUploadCustomsFile(transitionId, file)
  },

  deleteCustomsFile: (transitionId: number): AxiosPromise<void> => {
    return api.uploadDownloadDeleteCustomsFile(transitionId)
  },
}

export default uploadDownloadApi
