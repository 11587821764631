import { Box, Typography, createStyles, makeStyles, Grid } from '@material-ui/core'
import React, { FC } from 'react'
import { TransitionCardIcons } from './TransitionCardIcons'
import { useTranslation } from 'react-i18next'
import { DriverArrivalDtoDriverPassGateInEnum, DriverArrivalDtoDriverPassGateOutEnum } from '../../../../api'

const useStyles = makeStyles((theme) =>
  createStyles({
    firstRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'space-around',
      },
    },
    gateInOutTypography: {
      fontSize: '24px',
      fontWeight: 600,
      color: theme.palette.primary.main,
      [theme.breakpoints.up('sm')]: {
        fontSize: '28px',
        marginLeft: '0.8rem',
      },
    },
    carrierTypography: {
      fontSize: '20px',
      fontWeight: 500,
      marginLeft: '0.8rem',
      color: theme.palette.primary.main,
      [theme.breakpoints.up('sm')]: {
        fontSize: '20px',
      },
    },
    disabled: {
      color: theme.palette.grey[900],
      opacity: '0.5',
    },
    circle: {
      height: '25px',
      width: '25px',
      borderRadius: '50%',
      margin: '5px',
    },
    greenCircle: {
      backgroundColor: 'green',
    },
    orangeCircle: {
      backgroundColor: 'orange',
    },
    redCircle: {
      backgroundColor: 'red',
    },
    grid: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  })
)

interface TransitionCardFirstRowProps {
  withoutGateIn: boolean
  withoutGateOut: boolean
  isGateIn: boolean
  isCarrier: boolean
  driverPassGateIn: DriverArrivalDtoDriverPassGateInEnum | undefined
  driverPassGateOut: DriverArrivalDtoDriverPassGateOutEnum | undefined
}

export const TransitionCardFirstRow: FC<TransitionCardFirstRowProps> = ({
  withoutGateIn,
  withoutGateOut,
  isGateIn,
  isCarrier,
  driverPassGateIn,
  driverPassGateOut,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const getSemaphoreGateIn = () => {
    switch (driverPassGateIn) {
      case DriverArrivalDtoDriverPassGateInEnum.Green:
        return <div className={`${classes.circle} ${classes.greenCircle}`} />
      case DriverArrivalDtoDriverPassGateInEnum.Orange:
        return <div className={`${classes.circle} ${classes.orangeCircle}`} />
      default:
        return <div className={`${classes.circle} ${classes.redCircle}`} />
    }
  }
  const getSemaphoreGateOut = () => {
    switch (driverPassGateOut) {
      case DriverArrivalDtoDriverPassGateOutEnum.Green:
        return <div className={`${classes.circle} ${classes.greenCircle}`} />
      case DriverArrivalDtoDriverPassGateOutEnum.Orange:
        return <div className={`${classes.circle} ${classes.orangeCircle}`} />
      default:
        return <div className={`${classes.circle} ${classes.redCircle}`} />
    }
  }

  return (
    <Box className={classes.firstRow}>
      <Grid container className={classes.grid}>
        <Grid item>
          {isGateIn ? (
            <Typography
              className={`${isCarrier ? classes.carrierTypography : classes.gateInOutTypography} ${
                withoutGateIn ? classes.disabled : ''
              }`}
            >
              {t('driverApp.driverArrival.gateIn')}
            </Typography>
          ) : (
            <>
              <Typography
                className={`${isCarrier ? classes.carrierTypography : classes.gateInOutTypography} ${
                  withoutGateOut ? classes.disabled : ''
                }`}
              >
                {t('driverApp.driverArrival.gateOut')}
              </Typography>
            </>
          )}
        </Grid>
        <Grid item>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>{isGateIn ? <TransitionCardIcons gateIn /> : <TransitionCardIcons gateIn={false} />}</Grid>
            <Grid item>
              <Grid item>{isGateIn ? getSemaphoreGateIn() : getSemaphoreGateOut()}</Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
