import React, { FC, useContext, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  alpha,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core'
import { StoreInputParams } from './StoreForm'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@material-ui/icons/Close'
import { ConfigContext } from '../../../context/ConfigContext'

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      backgroundColor: alpha(theme.palette.secondary.light, 0.3),
      padding: '2vh 5vw 2vh 5vw',
      marginBottom: '2vh',
    },
    selectorSelected: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
    },
    selectorItem: {
      backgroundColor: theme.palette.secondary.main,
      position: 'relative',
      border: `1px solid ${theme.palette.secondary.light}`,
      '&::after': {
        content: '""',
        display: 'block',
        paddingBottom: '100%',
      },
      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
    selectorItemMain: {
      width: '14.25%',
    },
    selectorItemContent: {
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    selectorWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    validationAlert: {
      marginBottom: '1vh',
    },
    errorBorder: {
      border: `2px solid ${theme.palette.error.main}`,
    },
    selectorItemModal: {
      width: '20%',
    },
  })
)

interface LetterDialProps {
  sectorLetter?: string
  setStoreInputParam: React.Dispatch<React.SetStateAction<StoreInputParams>>
  switchDials: (to: string) => void
}

export const LetterDial: FC<LetterDialProps> = (props) => {
  const { sectorLetter, setStoreInputParam, switchDials } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const { coordinates } = useContext(ConfigContext)

  const [openDialog, setOpenDialog] = useState(false)

  const sectors = Array.from(Array(coordinates.sectorLetterCount))
    .map((e, i) => i + 65)
    .map((x) => String.fromCharCode(x))
  const mainSectors = coordinates.sectorMainLetterCount
  const validationError = false

  const onSectorChange = (newSector: string) => {
    setStoreInputParam((prevState) => ({
      ...prevState,
      sectorLetter: newSector,
    }))
    switchDials('columnDial')
  }

  const otherSectorsLabel = () => {
    if (!sectorLetter || sectors.slice(0, mainSectors).includes(sectorLetter)) {
      return (
        <Typography variant="h5" align="center">
          {t('pages.containerStoreForm.sector.otherSectors')}
        </Typography>
      )
    }
    return (
      <Typography variant="h4" align="center">
        {sectorLetter}
      </Typography>
    )
  }

  return (
    <>
      <Paper elevation={0} className={classes.paper}>
        <Typography variant="h5" align="center" color="primary" style={{ marginBottom: '1vh' }}>
          {t('pages.containerStoreForm.labels.sector')}
        </Typography>
        <div
          className={
            validationError ? `${classes.selectorWrapper} ${classes.errorBorder}` : `${classes.selectorWrapper}`
          }
        >
          {sectors
            .filter((e, idx) => idx < mainSectors)
            .map((value, index) => (
              <div
                key={index}
                onClick={() => onSectorChange(value)}
                className={`${classes.selectorItem} ${classes.selectorItemMain}`}
              >
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  className={
                    value === sectorLetter
                      ? `${classes.selectorItemContent} ${classes.selectorSelected}`
                      : `${classes.selectorItemContent}`
                  }
                >
                  <Grid item>
                    <Typography variant="h3" align="center">
                      {value}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            ))}
          <div onClick={() => setOpenDialog(true)} className={`${classes.selectorItem} ${classes.selectorItemMain}`}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              className={
                !!sectorLetter && !sectors.slice(0, mainSectors).includes(sectorLetter)
                  ? `${classes.selectorItemContent} ${classes.selectorSelected}`
                  : `${classes.selectorItemContent}`
              }
            >
              <Grid item>
                {otherSectorsLabel()}
                <Typography variant="h5" align="center">
                  {`${sectors[mainSectors]}-${sectors[sectors.length - 1]}`}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
      </Paper>
      <Dialog open={openDialog} fullWidth>
        <DialogTitle>
          {t('pages.containerStoreForm.labels.sector')}
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpenDialog(false)
            }}
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Paper elevation={0} className={classes.paper}>
            <div className={classes.selectorWrapper}>
              {sectors.slice(mainSectors).map((value, index) => (
                <div
                  key={index}
                  onClick={() => {
                    onSectorChange(value)
                    setOpenDialog(false)
                  }}
                  className={`${classes.selectorItem} ${classes.selectorItemModal}`}
                >
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    className={
                      value === sectorLetter
                        ? `${classes.selectorItemContent} ${classes.selectorSelected}`
                        : `${classes.selectorItemContent}`
                    }
                  >
                    <Grid item>
                      <Typography variant="h4">{value}</Typography>
                    </Grid>
                  </Grid>
                </div>
              ))}
            </div>
          </Paper>
        </DialogContent>
      </Dialog>
    </>
  )
}
