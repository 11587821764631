import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import {
  AvailableContainerDto,
  AvailableContainerDtoCoparnContainerQualityEnum,
  AvailableContainerDtoShippingContainerTypeEnum,
  CoparnContainerDto,
  CoparnContainerDtoCoparnContainerQualityEnum,
  PageTransitionDto,
  TransitionDto,
  TransitionDtoQualityTypeEnum,
  UserDtoRoleEnum,
} from '../../api'
import { useTranslation } from 'react-i18next'
import ConardButton from '../ConardButton'
import { CoparnService } from '../../services/CoparnService'
import { format } from 'date-fns'
import AvailableContainerSelectionFilter from './AvailableContainerSelectionFilter'
import { Order } from '../../enums/Order'
import { ConardCoordinates } from '../ConardCoordinates'
import { useConardAuth } from '../../hooks/useConardAuth'
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material'
import { useConardTheme } from '../../hooks/useConardTheme'

interface HeadCell {
  id: string
  label: string
}

export interface ScheduledContainerSelectionModalProps {
  driverInfo?: { frontLicensePlate?: string; driver?: string }
  open: boolean
  coparnContainer: CoparnContainerDto
  onClose: () => void
  onSave: (containerDto: TransitionDto, coparnContainerId: number) => void
}

export const AvailableContainerSelectionModal: FC<ScheduledContainerSelectionModalProps> = ({
  driverInfo,
  open,
  coparnContainer,
  onClose,
  onSave,
}) => {
  const [pageTransition, setPageTransition] = useState<PageTransitionDto>()
  const [selectedTransitionDto, setSelectedTransitionDto] = useState<TransitionDto>()
  const [order, setOrder] = useState(Order.Desc)
  const [orderBy, setOrderBy] = useState('transitionDateTime')
  const [page, setPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)
  const { t } = useTranslation()

  const [searchDto, setSearchDto] = useState<AvailableContainerDto | undefined>(undefined)
  const auth = useConardAuth()

  const headCells: HeadCell[] = [
    { id: 'container.iluCode', label: t('pages.gateOut.coparn.modal.iluCode') },
    { id: 'container.shipowner', label: t('pages.gateOut.coparn.modal.shipowner') },
    { id: 'container.containerType', label: t('pages.gateOut.coparn.modal.containerType') },
    { id: 'qualityType', label: t('pages.gateOut.coparn.modal.quality') },
    { id: 'hasSignet', label: t('pages.gateOut.coparn.modal.hasSignet') },
    { id: 'transitionDateTime', label: t('pages.gateOut.coparn.modal.transitionDateTime') },
    { id: 'sectorLetter,sectorColumn,sectorRow,sectorFloor', label: t('pages.gateOut.coparn.modal.sector') },
    { id: 'note', label: t('pages.gateOut.coparn.modal.note') },
  ]

  const closeModal = () => {
    setSelectedTransitionDto(undefined)
    onClose()
  }

  const save = () => {
    if (selectedTransitionDto) {
      onSave(selectedTransitionDto, coparnContainer.id!)
      setSelectedTransitionDto(undefined)
    }
  }

  const onSort = (newOrderBy: string) => () => {
    setOrderBy(newOrderBy)
    setOrder(orderBy === newOrderBy ? (order === Order.Asc ? Order.Desc : Order.Asc) : Order.Asc)
  }

  const onPage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage)
  }

  const onRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value, 10))
    setPage(0)
  }

  const searchFiltered = (data: AvailableContainerDto | undefined) => {
    search(data, true)
  }

  const search = (data: AvailableContainerDto | undefined, fromFilter: boolean) => {
    setSearchDto(data)

    const newSearchDto: AvailableContainerDto = {
      iluCode: data?.iluCode ?? '',
      shipownerId: coparnContainer.coparn?.shipowner?.id,
      coparnContainerQuality:
        data?.coparnContainerQuality ??
        (coparnContainer.coparnContainerQuality as unknown as AvailableContainerDtoCoparnContainerQualityEnum),
      shippingContainerType: coparnContainer.containerType as unknown as AvailableContainerDtoShippingContainerTypeEnum,
    }

    CoparnService.getAllAvailableContainers(newSearchDto, page, pageSize, [orderBy + ',' + order]).then((response) => {
      setPageTransition(response.data)
      if (fromFilter) {
        setPage(0)
      }
    })
  }

  useEffect(() => {
    setPage(0)
    search(undefined, false)
  }, [open]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (open) {
      search(searchDto, false)
    }
  }, [page, pageSize, order, orderBy]) // eslint-disable-line react-hooks/exhaustive-deps

  function convertContainerQuality(qualityType: TransitionDtoQualityTypeEnum | undefined) {
    switch (qualityType) {
      case TransitionDtoQualityTypeEnum.New:
      case TransitionDtoQualityTypeEnum.Food:
        return CoparnContainerDtoCoparnContainerQualityEnum.A
      case TransitionDtoQualityTypeEnum.Paper:
      case TransitionDtoQualityTypeEnum.Standard:
        return CoparnContainerDtoCoparnContainerQualityEnum.B
      case TransitionDtoQualityTypeEnum.Scrap:
      default:
        return CoparnContainerDtoCoparnContainerQualityEnum.C
    }
  }

  return (
    <Dialog onClose={closeModal} aria-labelledby="simple-dialog-title" open={open} maxWidth="xl">
      <DialogTitle id="simple-dialog-title">{t('pages.gateOut.coparn.modal.title')}</DialogTitle>
      <DialogContent>
        {!!driverInfo && (
          <StyledDriverInfo>
            <i>{driverInfo.frontLicensePlate}</i>
            {` ${driverInfo.driver ? driverInfo.driver : ''} `}
          </StyledDriverInfo>
        )}
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <AvailableContainerSelectionFilter coparnContainer={coparnContainer} containersFilter={searchFiltered} />
        </Grid>
        <br />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                {headCells.map((headCell) => (
                  <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : Order.Asc}
                      onClick={onSort(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!!pageTransition?.content?.length ? (
                pageTransition?.content?.map((transitionDto: TransitionDto) => {
                  return (
                    <TableRow key={transitionDto.id}>
                      <TableCell>
                        <Checkbox
                          color="primary"
                          checked={selectedTransitionDto?.id === transitionDto.id}
                          onChange={() => setSelectedTransitionDto(transitionDto)}
                        />
                      </TableCell>
                      <TableCell>{transitionDto.container.iluCode}</TableCell>
                      <TableCell>{transitionDto.container?.shipowner?.name}</TableCell>
                      <TableCell>{t(`enum.containerType.${transitionDto.container.containerType}`)}</TableCell>
                      <TableCell>{t(`enum.qualityType.${transitionDto.qualityType}`)}</TableCell>
                      <TableCell>
                        <Checkbox checked={!!transitionDto.hasSignet} disabled />
                      </TableCell>
                      <TableCell>
                        {transitionDto.transitionDateTime
                          ? format(new Date(transitionDto.transitionDateTime), 'dd.MM.yyyy, HH:mm')
                          : ''}
                      </TableCell>
                      <TableCell scope="row">
                        <ConardCoordinates transition={transitionDto} />
                      </TableCell>
                      <TableCell>{transitionDto.note}</TableCell>
                    </TableRow>
                  )
                })
              ) : (
                <TableRow>
                  <StyledNoDataCell colSpan={9}>{t('pages.common.table.noDataAvailable')}</StyledNoDataCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={pageTransition?.totalElements || 0}
          onPageChange={onPage}
          page={page}
          rowsPerPage={pageSize}
          labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
          rowsPerPageOptions={[10, 20, 50, 150]}
          onRowsPerPageChange={onRowsPerPage}
          sx={{
            '& .MuiToolbar-root': {
              backgroundColor: 'white',
            },
          }}
        />
        {selectedTransitionDto &&
          auth.getRole() === UserDtoRoleEnum.Operator &&
          convertContainerQuality(selectedTransitionDto?.qualityType) >
            (coparnContainer.coparnContainerQuality ?? CoparnContainerDtoCoparnContainerQualityEnum.C) && (
            <StyledLowerQualityError variant="subtitle1">
              <i>{t('pages.gateOut.coparn.modal.operatorLowerQualityError')}</i>
            </StyledLowerQualityError>
          )}
      </DialogContent>
      <DialogActions>
        <StyledButton conardVariant="transparent" onClick={closeModal}>
          {t('any.buttons.back')}
        </StyledButton>
        <StyledButton
          conardVariant="dark"
          onClick={save}
          disabled={
            !selectedTransitionDto ||
            (auth.getRole() === UserDtoRoleEnum.Operator &&
              convertContainerQuality(selectedTransitionDto?.qualityType) >
                (coparnContainer.coparnContainerQuality ?? CoparnContainerDtoCoparnContainerQualityEnum.C))
          }
        >
          {t('any.buttons.select')}
        </StyledButton>
      </DialogActions>
    </Dialog>
  )
}

const StyledNoDataCell = styled(TableCell)({
  textAlign: 'center',
})
const StyledLowerQualityError = styled(Typography)(() => ({
  align: 'center',
  color: useConardTheme().palette.error.main,
}))
const StyledDriverInfo = styled(Typography)(() => ({
  fontWeight: 650,
  fontSize: 24,
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  color: useConardTheme().palette.primary.main,
  marginBottom: '1rem',
}))
const StyledButton = styled(ConardButton)({
  borderRadius: 12,
})
