import React, { FC } from 'react'
import { createStyles, makeStyles, Tooltip } from '@material-ui/core'

const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      background: 'rgba(0,0,0,0.87)',
      fontSize: '15px',
      padding: '10px',
      borderRadius: '10px',
    },
  })
)

export interface ConardTooltipProps {
  title: string
  disabled?: boolean
  placement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start'
}

const ConardTooltip: FC<ConardTooltipProps> = ({ title, placement, children, disabled }) => {
  const classes = useStyles()

  return (
    <div>
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        placement={placement ?? 'bottom'}
        enterDelay={0}
        title={title}
        disableHoverListener={disabled}
      >
        <div>{children}</div>
      </Tooltip>
    </div>
  )
}

export default ConardTooltip
