import React, { FC } from 'react'
import { createStyles, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { TextFieldProps } from '@material-ui/core/TextField/TextField'
import { Controller } from 'react-hook-form'
import { Control } from 'react-hook-form/dist/types/form'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(34px, -6px) scale(0.75)',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        '& legend': {
          marginLeft: '20px',
        },
      },
    },
    inputRoot: {
      borderRadius: 30,
      borderColor: theme.palette.secondary.light,
      backgroundColor: theme.palette.secondary.main,
    },
    inputRootRoundedSquare: {
      borderRadius: 12,
    },
  })
)

interface ConardTextFieldProps {
  name: string
  control: Control
  roundedSquare?: boolean
}

type ConardTextFieldPropsUnited = ConardTextFieldProps & Omit<TextFieldProps, 'name'>

const ConardTextField: FC<ConardTextFieldPropsUnited> = (props) => {
  const {
    name,
    roundedSquare,
    defaultValue,
    label,
    InputLabelProps,
    helperText,
    error,
    type,
    onChange,
    control,
    disabled,
    value,
    InputProps,
    multiline,
    maxRows,
    autoFocus,
  } = props

  const classes = useStyles()

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={(props) => (
        <TextField
          autoFocus={autoFocus}
          type={type}
          label={label}
          inputRef={props.ref}
          InputLabelProps={InputLabelProps}
          value={value ?? props.value}
          helperText={helperText}
          error={error}
          onChange={(event) => {
            if (!!onChange) {
              onChange(event)
            }
            props.onChange(event)
          }}
          variant="outlined"
          disabled={disabled}
          InputProps={{
            ...InputProps,
            className: roundedSquare ? `${classes.inputRoot} ${classes.inputRootRoundedSquare}` : classes.inputRoot,
          }}
          className={classes.root}
          multiline={multiline}
          maxRows={maxRows}
        />
      )}
    />
  )
}

export default ConardTextField
