import { alpha, createTheme } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'

export const brnoTheme = createTheme({
  palette: {
    primary: {
      main: '#435B82',
      light: '#e6ecf4',
      dark: '#2f405b',
    },
    secondary: {
      main: '#FFFFFF',
      light: '#C2DCE2',
      dark: '#B3C6D0',
    },
    background: {
      default: '#F4FAFF',
    },
    error: {
      main: '#ff0000',
      light: '#F4CCCC',
    },
    info: {
      main: '#b4d9ff',
      light: '#dfeeff',
      dark: '#4c8dc2',
    },
    warning: {
      main: '#ff7520',
      light: '#ffe9ca',
    },
    success: {
      main: '#00953C',
      light: '#e6f4ec',
      dark: '#438255',
    },
    grey: grey,
  },
  typography: {
    h4: {
      fontWeight: 500,
    },
  },
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#176FBB',
      },
    },
    MuiDialog: {
      root: {
        minWidth: 300,
        backgroundColor: '#F4FAFF',
      },
    },
    MuiDivider: {
      light: {
        backgroundColor: alpha('#FFFFFF', 0.3),
      },
    },
    MuiToolbar: {
      root: {
        backgroundColor: '#176FBB',
      },
    },
    MuiTablePagination: {
      toolbar: {
        backgroundColor: '#FFFFFF',
      },
      menuItem: {
        color: '#000000',
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: '#176FBB',
          fontWeight: 'bold',
        },
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: '#176FBB',
      },
    },
    MuiTableCell: {
      head: {
        color: 'white',
      },
    },
    MuiTableSortLabel: {
      active: {
        color: 'white !important',
      },
      icon: {
        color: 'white !important',
      },
    },
  },
})
