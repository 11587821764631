import { AxiosPromise } from 'axios'
import myAxios from '../config/axios/axios'
import { PageShipownerDto, ShipownerApi, ShipownerDto, ShipownerSearchDto } from '../api'
import { cleanObject } from '../utils/utils'

let api: ShipownerApi

export const setShipownerApiConfiguration = () => {
  api = new ShipownerApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

export const shipownerService = {
  findAll: (
    page: number | undefined,
    pageSize: number | undefined,
    currentSort: string[] | undefined
  ): AxiosPromise<PageShipownerDto> => {
    return api.shipownerFindAll(page, pageSize, currentSort)
  },
  findById: (id: number): AxiosPromise<ShipownerDto> => {
    return api.shipownerFindById(id)
  },
  create: (shipownerDto: ShipownerDto): AxiosPromise<ShipownerDto> => {
    return api.shipownerCreate(shipownerDto)
  },
  update: (id: number | undefined, shipownerDto: ShipownerDto): AxiosPromise<ShipownerDto> => {
    return api.shipownerUpdate(id || -1, shipownerDto)
  },
  delete: (id: number): AxiosPromise<void> => {
    return api.shipownerDelete(id)
  },
  search: (
    page: number,
    pageSize: number,
    orderBy: string,
    order: string,
    shipownerSearchDto: ShipownerSearchDto
  ): AxiosPromise<PageShipownerDto> => {
    return api.shipownerSearch(page, pageSize, [orderBy + ',' + order], cleanObject(shipownerSearchDto))
  },
}

export default shipownerService
