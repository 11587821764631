export enum AsparkingCheckpoint {
  CHECKPOINT_1_IN_CAR = 'CHECKPOINT_1_IN_CAR',
  CHECKPOINT_1_IN_TRUCK = 'CHECKPOINT_1_IN_TRUCK',
  CHECKPOINT_1_OUT_CAR = 'CHECKPOINT_1_OUT_CAR',
  CHECKPOINT_1_OUT_TRUCK = 'CHECKPOINT_1_OUT_TRUCK',
  MAIN_GATE = 'MAIN_GATE',
  CHECKPOINT_2_IN = 'CHECKPOINT_2_IN',
  CHECKPOINT_2_OUT = 'CHECKPOINT_2_OUT',
  CHECKPOINT_3_IN = 'CHECKPOINT_3_IN',
  CHECKPOINT_3_OUT = 'CHECKPOINT_3_OUT',
}
