import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { alpha, createStyles, Grid, MenuItem, Paper, Typography } from '@material-ui/core'
import ConardSelect from '../ConardSelect'
import ConardButton from '../ConardButton'
import { CdCargoRequestDto, CdCargoTemplateDto, TrainDto } from '../../api'
import { useForm } from 'react-hook-form'
import cdCargoExportConfigurationService from '../../services/CdCargoExportConfigurationService'
import CdCargoDocumentSentDialog from '../dialogs/CdCargoDocumentSentDialog'
import { makeStyles } from '@material-ui/core/styles'
import { Download, ForwardToInbox } from '@mui/icons-material'
import { format } from 'date-fns'
import processedFileService from '../../services/ProcessedFileService'
import trainGateOutService from '../../services/TrainGateOutService'

const useStyles = makeStyles((theme) =>
  createStyles({
    gridItem: {
      marginBottom: '10px',
    },
    downloadButton: {
      paddingRight: '10px',
      marginBottom: '10px',
    },
    sendButton: {
      paddingLeft: '10px',
      marginBottom: '10px',
    },
    paper: {
      backgroundColor: alpha(theme.palette.secondary.light, 0.3),
      padding: '2vh 2vw 2vh 2vw',
      width: '100%',
    },
  })
)

interface CdCargoBoxProps {
  train: TrainDto
}

const CdCargoBox: FC<CdCargoBoxProps> = (props) => {
  const { train } = props
  const { t } = useTranslation()
  const classes = useStyles()

  const [templateId, setTemplateId] = useState<number>()
  const [templates, setTemplates] = useState<CdCargoTemplateDto[] | undefined>(undefined)
  const [showCdCargoDialog, setShowCdCargoDialog] = useState(false)
  const [latestCdCargoSent, setLatestCdCargoSent] = useState<string | undefined>(undefined)

  const { control } = useForm<TrainDto>()

  const handleDownloadXml = () => {
    const request: CdCargoRequestDto = {
      trainId: train?.id || -1,
      templateId: templateId ?? -1,
    }

    trainGateOutService
      .downloadCimDocument(request)
      .then((response) => {
        const url = window.URL.createObjectURL(response.data)
        const a = document.createElement('a')
        a.href = url
        a.download = response.headers.filename
        a.click()
      })
      .catch()
  }

  const handleSendXml = () => {
    const request: CdCargoRequestDto = {
      trainId: train?.id || -1,
      templateId: templateId ?? -1,
    }

    trainGateOutService
      .sendCimDocument(request)
      .then(() => {
        setShowCdCargoDialog(true)
        getLatestMessage()
      })
      .catch()
  }

  const getLatestMessage = () => {
    processedFileService
      .findLatestCdCargoMessage(train.id ?? -1)
      .then((response) => {
        setLatestCdCargoSent(response.data.processed ?? undefined)
      })
      .catch()
  }

  useEffect(() => {
    cdCargoExportConfigurationService.findAll(undefined, 200, '', '').then((response) => {
      setTemplates(response.data.content)
    })

    getLatestMessage()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Paper elevation={0} className={classes.paper}>
      <Grid container>
        <Grid item xs={12} className={classes.gridItem}>
          <Typography variant="h4" color="primary">
            {t('pages.gateOut.headers.trainCdCargo.title')}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          {t('pages.gateOut.headers.trainCdCargo.latestSent') +
            (!!latestCdCargoSent
              ? format(new Date(latestCdCargoSent!), 'dd.MM.yyyy, HH:mm')
              : t('pages.gateOut.headers.trainCdCargo.notSent'))}
        </Grid>
        <Grid item xs={12} className={classes.gridItem}>
          <Typography variant="h6" color="primary">
            {t('pages.gateOut.headers.trainCdCargo.selectTemplate')}
          </Typography>
          <ConardSelect
            name="template"
            defaultValue={-1}
            value={templateId}
            control={control}
            onChange={(event) => setTemplateId(+event.target.value)}
          >
            <MenuItem value={-1}>{t('any.notSelected')}</MenuItem>
            {templates?.map((t) => (
              <MenuItem key={t.id} value={t.id}>
                {t.name}
              </MenuItem>
            ))}
          </ConardSelect>
        </Grid>
        <Grid item md={6} xs={12} className={classes.downloadButton}>
          <ConardButton
            conardVariant="light"
            startIcon={<Download />}
            disabled={!templateId || templateId === -1}
            onClick={handleDownloadXml}
          >
            {t('pages.gateOut.buttons.download')}
          </ConardButton>
        </Grid>
        <Grid item md={6} xs={12} className={classes.sendButton}>
          <ConardButton
            conardVariant="light"
            startIcon={<ForwardToInbox />}
            disabled={!templateId || templateId === -1}
            onClick={handleSendXml}
          >
            {t('pages.gateOut.buttons.send')}
          </ConardButton>
        </Grid>
      </Grid>
      <CdCargoDocumentSentDialog showDialog={showCdCargoDialog} setShowDialog={setShowCdCargoDialog} />
    </Paper>
  )
}

export default CdCargoBox
