import { Box, Typography, makeStyles } from '@material-ui/core'
import React, { FC } from 'react'
import ConardButton from '../../../components/ConardButton'
import { useTranslation } from 'react-i18next'
import { KioskButtons } from '../KioskButtons'
import kioskDriverArrivalService from '../../../services/KioskDriverArrivalService'
import { useActualDriverArival } from '../shared/useActualDriverArival'
import { ContainerIcon } from '../../../assets/icons/ContainerIcon'
import { SemitrailerIcon } from '../../../assets/icons/SemitrailerIcon'

const useStyles = makeStyles((theme) => ({
  typography: {
    color: theme.palette.primary.main,
    fontSize: '45px',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  body: {
    maxWidth: '800px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '400px',
    gap: '5.8rem',
    marginTop: '80px',
  },
  buttons: {
    width: '250px',
    height: '80px',
    borderRadius: 12,
    fontSize: 'large',
    boxShadow: '-2px 2px 5px gray',
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  icon: {
    color: 'white',
    scale: 1.5,
  },
  span: {
    margin: '0 20px',
  },
}))

interface KiosGateOutProps {
  idDriverArrival: number
  next: () => void
  previous: () => void
  goTo: (index: number) => void
  handleWithoutGateOut: (value: boolean) => void
}

export const KioskGateOut: FC<KiosGateOutProps> = ({ previous, next, idDriverArrival, goTo, handleWithoutGateOut }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { driverArrival } = useActualDriverArival(idDriverArrival)

  const handleClickNo = async () => {
    try {
      await kioskDriverArrivalService.update(idDriverArrival ?? 0, {
        ...driverArrival,
        driver: driverArrival?.driver,
        arrivalDateTime: driverArrival?.arrivalDateTime ?? '',
        withoutGateOut: true,
      })
      handleWithoutGateOut(true)
      goTo(9)
    } catch (error) {
      console.error(error)
    }
  }

  const handleClickYes = () => {
    handleWithoutGateOut(false)
    next()
  }

  const handleClickBack = async () => {
    if (driverArrival?.withoutGateIn) {
      try {
        await kioskDriverArrivalService.update(idDriverArrival ?? 0, {
          ...driverArrival,
          withoutGateIn: false,
        })
        goTo(1)
      } catch (error) {
        console.error(error)
      }
    } else {
      previous()
    }
  }

  return (
    <>
      <Box className={classes.body}>
        <Typography className={classes.typography}>{t('kioskApp.step6.label')}</Typography>
        <Box className={classes.buttonsWrapper}>
          <ConardButton className={classes.buttons} conardVariant="dark" onClick={handleClickYes}>
            <ContainerIcon className={classes.icon} fontSize="large" />
            <span className={classes.span}> / </span>
            <SemitrailerIcon className={classes.icon} fontSize="large" />
          </ConardButton>
          <ConardButton className={classes.buttons} conardVariant="light" onClick={handleClickNo}>
            {t('kioskApp.step1.no')}
          </ConardButton>
        </Box>
      </Box>
      <KioskButtons visibleNext={false} visibleBack onClickBack={handleClickBack} />
    </>
  )
}
