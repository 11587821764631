import React, { FC } from 'react'
import GateOutTrainPrepareList from '../../components/gate-out/GateOutTrainPrepareList'

const GateOutTrainPage: FC = () => {
  return (
    <>
      <GateOutTrainPrepareList />
    </>
  )
}

export default GateOutTrainPage
