import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { PageUserDto, UserDto, UserSearchDto } from '../../api'
import userService from '../../services/UserService'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Order } from '../../enums/Order'
import ConardButton from '../../components/ConardButton'
import FilterListIcon from '@material-ui/icons/FilterList'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import UserFilter from './UserFilter'
import { cleanObject } from '../../utils/utils'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import GetAppIcon from '@material-ui/icons/GetApp'
import ConardProgressBar from '../../components/ConardProgressBar'

const useStyles = makeStyles((theme) =>
  createStyles({
    editTable: {
      width: '7vw',
    },
    typography: {
      width: '60vw',
    },
    newUserButton: {
      width: '15vw',
      minWidth: '300px',
      height: '5vh',
      minHeight: '50px',
      textAlign: 'center',
      margin: '20px',
      float: 'right',
    },
    filterButton: {
      textAlign: 'center',
      margin: '20px',
      float: 'left',
    },
    gridContainer: {
      marginTop: '8vh',
    },
    disabledTableRow: {
      backgroundColor: theme.palette.secondary.main,
      '& > .MuiTableCell-root': {
        color: theme.palette.grey.A200,
      },
    },
  })
)

interface HeadCell {
  id: keyof UserDto
  label: string
}

const UserListPage: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const [users, setUsers] = useState<PageUserDto | null>()

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(50)
  const [order, setOrder] = useState<Order>(Order.Asc)
  const [orderBy, setOrderBy] = useState<keyof UserDto>('username')
  const [showFilter, setShowFilter] = useState(true)
  const [searchDto, setSearchDto] = useState<UserSearchDto | undefined>(undefined)

  const classes = useStyles()

  const headCells: HeadCell[] = [
    { id: 'username', label: t('entity.user.fields.username') },
    { id: 'firstName', label: t('entity.user.fields.firstName') },
    { id: 'surname', label: t('entity.user.fields.surname') },
    { id: 'email', label: t('entity.user.fields.email') },
    { id: 'role', label: t('entity.user.fields.role') },
  ]

  useEffect(() => {
    search(searchDto, false)
  }, [page, pageSize, orderBy, order]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSort = (property: keyof UserDto) => () => {
    const isAsc = orderBy === property && order === Order.Asc
    setOrder(isAsc ? Order.Desc : Order.Asc)
    setOrderBy(property)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value))
    setPage(0)
  }

  const handleFilter = () => {
    setShowFilter(!showFilter)
  }

  const searchFiltered = (data: UserSearchDto | undefined) => {
    search(data, true)
  }

  const search = (data: UserSearchDto | undefined, fromFilter: boolean) => {
    setSearchDto(data)

    let currentSort: Array<string> | undefined = undefined
    if (orderBy !== undefined) {
      const currentOrder: string = order !== undefined ? order : 'asc'
      currentSort = [orderBy + ',' + currentOrder]
    }

    userService
      .userSearch(page, pageSize, currentSort, cleanObject(data))
      .then((response) => {
        setUsers(response.data)
        if (fromFilter) {
          setPage(0)
        }
      })
      .catch()
  }

  const downloadExport = () => {
    userService.downloadExport(cleanObject(searchDto)).then((response) => {
      const url = window.URL.createObjectURL(response.data)
      const a = document.createElement('a')
      a.href = url
      a.download = 'userExport.xlsx'
      a.click()
    })
  }

  return (
    <>
      <Typography className={classes.typography} variant="h4" color="primary">
        {t('mainMenu.pages.user.title')}
      </Typography>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={3}
        className={classes.gridContainer}
      >
        <Grid item xl={2} lg={3} md={6} sm={6} xs={6}>
          <div className={classes.filterButton}>
            <ConardButton
              conardVariant="transparent"
              startIcon={<FilterListIcon />}
              endIcon={showFilter ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              onClick={handleFilter}
            >
              {t('any.buttons.filter')}
            </ConardButton>
          </div>
        </Grid>
        <Grid item xl={2} lg={3} md={6} sm={6} xs={6}>
          <div className={classes.filterButton}>
            <ConardButton conardVariant="transparent" startIcon={<GetAppIcon />} onClick={downloadExport}>
              {t('any.buttons.export')}
            </ConardButton>
          </div>
        </Grid>
        <Grid item xl={8} lg={6} md={6} sm={6} xs={6} style={{ float: 'right' }}>
          <div className={classes.newUserButton}>
            <ConardButton conardVariant="light" startIcon={<AddIcon />} onClick={() => history.push('/user/create')}>
              {t('any.buttons.add')}
            </ConardButton>
          </div>
        </Grid>

        {showFilter && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <UserFilter usersFilter={searchFiltered} />
          </Grid>
        )}

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper variant="outlined">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={handleSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users?.content?.map((user) => (
                    <TableRow className={user?.disabled ? classes.disabledTableRow : ''} key={user.id}>
                      <TableCell component="th" scope="row">
                        {user.username}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {user.firstName}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {user.surname}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {user.email}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {t('enum.role.' + user.role)}
                      </TableCell>
                      <TableCell className={classes.editTable}>
                        <ConardButton
                          conardVariant="light"
                          startIcon={<EditIcon />}
                          onClick={() => history.push('/user/update/' + user.id)}
                        >
                          {t('any.buttons.edit')}
                        </ConardButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  {users?.content?.length === 0 && (
                    <TableRow key="noData">
                      <TableCell component="th" scope="row" colSpan={7}>
                        {t('pages.common.table.noData')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <ConardProgressBar showBar={!users} />
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 150]}
              component="div"
              count={users?.totalElements ?? 0}
              rowsPerPage={pageSize}
              labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className={classes.newUserButton}>
            <ConardButton conardVariant="light" startIcon={<AddIcon />} onClick={() => history.push('/user/create')}>
              {t('any.buttons.add')}
            </ConardButton>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default UserListPage
