import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Autocomplete } from '@material-ui/lab'
import { AdrRidCodeDto } from '../../../api'
import ConardTooltip from '../../../components/ConardTooltip'
import { Chip, makeStyles, TextField } from '@material-ui/core'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import RidService from '../../../services/RidService'
import i18next from 'i18next'

const useStyles = makeStyles((theme) => ({
  chipLabel: {
    maxWidth: '180px',
  },
  chip: {
    backgroundColor: theme.palette.warning.main,
  },
  adrRidInputAutocomplete: {
    borderRadius: 12,
    borderColor: theme.palette.secondary.light,
    backgroundColor: theme.palette.secondary.main,
    display: 'table',
    paddingRight: 60,
    height: 50,
    maxHeight: 200,
  },
  autocompleteInput: {
    width: '100%',
  },
  autocomplete: {
    width: '100%',
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(34px, -6px) scale(0.75)',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      '& legend': {
        marginLeft: '20px',
      },
    },
    '& .MuiAutocomplete-endAdornment': {
      right: '7px',
    },
  },
}))

interface AdrRidFieldProps {
  isDisabled: boolean
  initialCodes?: AdrRidCodeDto[]
  error?: boolean
  helperText?: string
}

export const AdrRidField: FC<AdrRidFieldProps> = ({ isDisabled, initialCodes, error, helperText }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { control } = useFormContext()

  const [adrRidCodes, setAdrRidCodes] = useState<AdrRidCodeDto[]>([])
  const [selectedAdrRidCodes, setSelectedAdrRidCodes] = useState<AdrRidCodeDto[]>(initialCodes ?? [])
  const [showOptions, setShowOptions] = useState(false)

  useEffect(() => {
    RidService.findAllCodes(0, 4000, undefined).then((response) => {
      setAdrRidCodes(response.data.content ?? [])
    })
  }, [])

  const onChangeAdrRidCodes = (event: React.ChangeEvent<{}>, value: AdrRidCodeDto[]) => {
    control.setValue('adrRidCodes', value)
    setSelectedAdrRidCodes(value)
    control.trigger()
  }

  const handleTextChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setShowOptions((event.target.value as string).length > 1)
  }

  const hasAdrRid = useWatch({
    control,
    name: 'adrRid',
  })

  useEffect(() => {
    if (!hasAdrRid) {
      control.setValue('adrRidCodes', [])
      setSelectedAdrRidCodes([])
    }
  }, [hasAdrRid, control])

  const getOptionName = (option: AdrRidCodeDto) => {
    return i18next.language === 'cs' ? option.nameCz ?? '' : option.nameEn ?? ''
  }

  return (
    <Controller
      id="adrRidCodes"
      name="adrRidCodes"
      defaultValue={[]}
      control={control}
      render={() => (
        <Autocomplete
          multiple
          className={classes.autocompleteInput}
          filterSelectedOptions
          limitTags={6}
          options={showOptions ? adrRidCodes : []}
          defaultValue={[]}
          value={selectedAdrRidCodes}
          noOptionsText={showOptions ? t('any.noOptions') : t('any.startTyping')}
          getOptionLabel={(option) =>
            `${option.unCode ?? ''} - ${getOptionName(option)}${option.packingGroup ? ', ' + option.packingGroup : ''}`
          }
          disabled={isDisabled}
          onChange={(event, data) => onChangeAdrRidCodes(event, data)}
          renderTags={(tagValue: AdrRidCodeDto[], getTagProps) =>
            tagValue.map((option, index) => (
              <>
                <ConardTooltip
                  title={`${option.unCode ?? ''} - ${getOptionName(option)}${
                    option.packingGroup ? ', ' + option.packingGroup : ''
                  }`}
                >
                  <Chip
                    classes={{ label: classes.chipLabel, colorPrimary: classes.chip }}
                    key={option.unCode}
                    label={`${option.unCode ?? ''} - ${getOptionName(option)}${
                      option.packingGroup ? ', ' + option.packingGroup : ''
                    }`}
                    color="primary"
                    {...getTagProps({ index })}
                  />
                </ConardTooltip>
              </>
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              disabled={isDisabled}
              className={classes.autocomplete}
              error={error ?? false}
              helperText={helperText}
              onChange={(event) => handleTextChange(event)}
              InputProps={{
                ...params.InputProps,
                className: classes.adrRidInputAutocomplete,
              }}
            />
          )}
        />
      )}
    />
  )
}
