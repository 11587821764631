import React, { FC, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import transitionService from '../../../services/TransitionService'
import {
  ContainerDtoContainerTypeEnum,
  NotificationDto,
  TransitionDto,
  TransitionDtoContainerStateEnum,
} from '../../../api'
import { Alert } from '@material-ui/lab'
import { createStyles, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import StoreForm, { StoreInputParams } from './StoreForm'
import gateInService from '../../../services/GateInService'

const useStyles = makeStyles(() =>
  createStyles({
    alert: {
      margin: 'auto',
      width: '50vw',
      marginTop: '20vh',
    },
  })
)

interface ContainerStoreFormState {
  notification?: NotificationDto
}

interface UrlParamTypes {
  id?: string
}

const ContainerStoreForm: FC = () => {
  const { id } = useParams<UrlParamTypes>()
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  const [transition, setTransition] = useState<TransitionDto | null>(null)
  const [loaded, setLoaded] = useState(false)

  const location = useLocation<ContainerStoreFormState>()
  const locationNotification = location?.state?.notification

  const loadTransition = () => {
    transitionService
      .findById(parseInt(id ?? '-1', 10))
      .then((response) => {
        setTransition(response.data)
      })
      .catch()
      .finally(() => setLoaded(true))
  }

  const storeContainer = (storeInputParam: StoreInputParams) => {
    if (transition?.containerState === TransitionDtoContainerStateEnum.ProcessingGateIn) {
      gateInService
        .storeContainer(transition?.id || -1, storeInputParam)
        .then((response) => {
          history.replace('/success/transition/' + response.data.id + '/store', { locationNotification })
        })
        .catch()
    } else {
      transitionService
        .changeContainerPosition(transition?.id || -1, storeInputParam)
        .then((response) => {
          history.replace('/success/transition/' + response.data.id)
        })
        .catch()
    }
  }

  useEffect(() => {
    loadTransition()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {loaded && (
        <>
          {transition === null && (
            <Alert severity="error" className={classes.alert}>
              {t('be.error.entity.notFound')}
            </Alert>
          )}
          {transition != null && (
            <StoreForm
              title={
                transition?.container.containerType === ContainerDtoContainerTypeEnum.ShippingContainer
                  ? t('pages.gateIn.headers.storeContainer')
                  : t('pages.gateIn.headers.storeSemitrailer')
              }
              name={transition?.container.iluCode}
              onStore={storeContainer}
            />
          )}
        </>
      )}
    </>
  )
}

export default ContainerStoreForm
