import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { ConardSwitchSmall, ConardSwitchSmallProps } from '../ConardSwitchSmall'

type Props = Omit<ConardSwitchSmallProps, 'helperText'> & {
  name: string
}

export const RHFSwitch: React.FC<Props> = ({ name, ...rest }) => {
  const {
    control,
    formState: { isSubmitting, errors },
  } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      render={({ onBlur, onChange, value }) => (
        <ConardSwitchSmall
          checked={!!value}
          name={name}
          disabled={isSubmitting}
          helperText={errors?.[name]?.message}
          onChange={(e) => onChange(e.target.checked)}
          onBlur={onBlur}
          {...rest}
        />
      )}
    />
  )
}
