import React, { FC, useEffect, useState } from 'react'
import { createStyles, Grid, MenuItem } from '@material-ui/core'
import ConardTextField from '../../components/ConardTextField'
import { useTranslation } from 'react-i18next'
import { CarrierSearchDto, CarrierSearchDtoContractTypeEnum, CompanyDto } from '../../api'
import { useForm } from 'react-hook-form'
import ConardSelect from '../../components/ConardSelect'
import ConardButton from '../../components/ConardButton'
import companyService from '../../services/CompanyService'
import { makeStyles } from '@material-ui/core/styles'

interface CarrierFilterProps {
  carrierFilter: (data: CarrierSearchDto) => void
}

const useStyles = makeStyles(
  createStyles({
    colorDiv: {
      width: '10px',
      height: '10px',
      borderRadius: 8,
      marginLeft: '10px',
    },
    companySelectItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  })
)

const CarrierFilter: FC<CarrierFilterProps> = (props) => {
  const { t } = useTranslation()
  const { carrierFilter } = props
  const classes = useStyles()

  const [companies, setCompanies] = useState<CompanyDto[] | undefined>(undefined)
  const [companyId, setCompanyId] = useState<number | undefined>(undefined)

  const [selectContractType, setSelectContractType] = useState('NOT-SELECTED')

  const { handleSubmit, control } = useForm<CarrierSearchDto>({})

  const getAllCompanies = () => {
    companyService.findAll(0, 100, '', '').then((response) => {
      setCompanies(response.data.content)
    })
  }

  const onChangeCompany = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCompanyId(event.target.value as unknown as number)
  }

  useEffect(() => {
    getAllCompanies()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form onSubmit={handleSubmit(carrierFilter)}>
      <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardTextField
            id="name"
            name="name"
            label={t('entity.carrier.fields.name')}
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardTextField
            id="licensePlate"
            name="licensePlate"
            defaultValue=""
            label={t('entity.card.fields.licensePlate')}
            control={control}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardTextField
            id="cardNumber"
            name="cardNumber"
            defaultValue=""
            label={t('entity.card.fields.cardNumber')}
            control={control}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardSelect
            id="contractType"
            name="contractType"
            label={t('entity.carrier.fields.contractType')}
            defaultValue="NOT-SELECTED"
            value={selectContractType}
            onChange={(event) => setSelectContractType(event.target.value)}
            control={control}
          >
            <MenuItem value="NOT-SELECTED">{t('pages.common.select.all')}</MenuItem>
            {Object.values(CarrierSearchDtoContractTypeEnum).map((contractType) => (
              <MenuItem key={contractType} value={contractType}>
                {t('enum.contractType.' + contractType)}
              </MenuItem>
            ))}
          </ConardSelect>
        </Grid>
        <Grid item xl={2} lg={2} md={4} sm={6} xs={12}>
          <ConardSelect
            id="companyId"
            name="companyId"
            label={t('entity.company.fields.name')}
            defaultValue={companyId ?? -1}
            value={companyId ?? -1}
            onChange={(event) => onChangeCompany(event)}
            control={control}
          >
            <MenuItem value={-1}>{t('pages.common.select.all')}</MenuItem>
            {companies?.map((company) => (
              <MenuItem key={company.id} value={company.id}>
                <div className={classes.companySelectItem}>
                  {company.name}
                  <div style={{ backgroundColor: company.color }} className={classes.colorDiv} />
                </div>
              </MenuItem>
            ))}
          </ConardSelect>
        </Grid>
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <div>
            <ConardButton conardVariant="light" type="submit">
              {t('any.buttons.filter')}
            </ConardButton>
          </div>
        </Grid>
      </Grid>
    </form>
  )
}

export default CarrierFilter
