import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import {
  PageTransitionSearchListDto,
  TransitionCustomsInfoSearchListDtoStateEnum,
  TransitionSearchListDto,
  TransitionSearchListSearchDto,
} from '../../api'
import { Order } from '../../enums/Order'
import { useAppDispatch } from '../../redux/store'
import { cleanObject } from '../../utils/utils'
import { setSelectedUnseatedTransition, setTrain } from '../../redux/train/trainSlice'
import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import TransitionSearchFilter from '../../pages/search/TransitionSearchFilter'
import ConardButton from '../ConardButton'
import AddIcon from '@material-ui/icons/Add'
import CheckIcon from '@material-ui/icons/Check'
import { TransitionMode } from '../../enums/TransitionMode'
import WarningAmberIcon from '@material-ui/icons/Warning'
import ConardProgressBar from '../ConardProgressBar'
import ConardTransitionSearchIcon from '../ConardTransitionSearchIcon'
import trainGateOutService from '../../services/TrainGateOutService'
import transitionService from '../../services/TransitionService'
import { format } from 'date-fns'

const useStyles = makeStyles((theme) =>
  createStyles({
    gridContainer: {
      marginTop: '2vh',
      marginBottom: '6vh',
    },
    /* this fixes columns when language changes */
    table: {
      tableLayout: 'fixed',
    },
    detailButton: {
      width: '7vw',
    },
    controls: {
      [theme.breakpoints.up('sm')]: {
        left: '10vw',
      },
      [theme.breakpoints.down('xs')]: {
        left: '0px',
      },
      position: 'fixed',
      bottom: 0,
      width: '100%',
      backgroundColor: theme.palette.secondary.main,
      zIndex: 100,
    },
    submitButton: {
      width: '15vw',
      minWidth: '200px',
      textAlign: 'center',
      marginTop: '2vh',
      marginBottom: '2vh',
      marginLeft: '5vw',
    },
    colorDiv: {
      width: '10px',
      height: '10px',
      borderRadius: '100%',
      marginLeft: '10px',
    },
    companyCell: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  })
)

interface HeadCell {
  id: string
  label: string
}

interface UrlParamTypes {
  id?: string
}

const ConardTrainContentSelectForm: FC = () => {
  const { id } = useParams<UrlParamTypes>()
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()
  const trainId = useMemo<number>(() => Number(id), [id])

  const [pageTransitions, setPageTransitions] = useState<PageTransitionSearchListDto | null>()
  const [transitionSearch, setTransitionSearch] = useState<TransitionSearchListSearchDto | undefined>()
  const [blockSearch, setBlockSearch] = useState(true)

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(50)
  const [order, setOrder] = useState<Order>(Order.Asc)
  const [orderBy, setOrderBy] = useState<string>('direction')
  const [selectedTransitions, setSelectedTransitions] = useState<TransitionSearchListDto[]>([])
  const dispatch = useAppDispatch()

  const headCells: HeadCell[] = [
    { id: 'iluCode', label: t('pages.gateIn.form.iluCode.label') },
    { id: 'semitrailerLicencePlate', label: t('pages.gateIn.form.semitrailerLicencePlate.label') },
    {
      id: 'repleted',
      label: t('pages.gateIn.form.repleted.checked') + ' / ' + t('pages.gateIn.form.repleted.unchecked'),
    },
    { id: 'company.name', label: t('entity.company.fields.name') },
    { id: 'shipowner', label: t('pages.gateIn.form.shipowner.label') },
    { id: 'importExport', label: t('pages.gateIn.form.importExport.label') },
    { id: 'transitionDateTime', label: t('pages.gateIn.form.transitionDatetime.label') },
    { id: 'damaged', label: '' },
  ]

  useEffect(() => {
    transitionService
      .searchForList(undefined, 200, undefined, {
        unseatedTrainId: trainId,
      })
      .then((result) => setSelectedTransitions(result.data.content ?? []))
  }, [trainId])

  useEffect(() => {
    search(transitionSearch, false)
  }, [page, pageSize, orderBy, order]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSort = (property: string) => () => {
    const isAsc = orderBy === property && order === Order.Asc
    setOrder(isAsc ? Order.Desc : Order.Asc)
    setOrderBy(property)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value))
    setPage(0)
  }

  const searchFiltered = (searchDto: TransitionSearchListSearchDto | undefined) => {
    search(searchDto, true)
  }

  const search = (searchDto: TransitionSearchListSearchDto | undefined, fromFilter: boolean) => {
    setBlockSearch(true)

    setTransitionSearch(searchDto)

    const dateFrom: Date | undefined = searchDto?.transitionDateTimeFrom
      ? new Date(searchDto?.transitionDateTimeFrom)
      : undefined
    dateFrom?.setHours(0, 0, 0)

    const dateTo: Date | undefined = searchDto?.transitionDateTimeTo
      ? new Date(searchDto?.transitionDateTimeTo)
      : undefined
    dateTo?.setHours(23, 59, 59)

    searchDto = {
      ...searchDto,
      transitionDateTimeFrom: dateFrom?.toISOString(),
      transitionDateTimeTo: dateTo?.toISOString(),
    }

    let currentSort: Array<string> | undefined = undefined
    if (orderBy !== undefined) {
      const currentOrder: string = order !== undefined ? order : 'asc'
      currentSort = [orderBy + ',iluCode,' + currentOrder]
    }

    trainGateOutService
      .findFinishedGateInForTrainGateOut(
        cleanObject({
          ...searchDto,
        }),
        page,
        pageSize,
        currentSort
      )
      .then((response) => {
        setPageTransitions(response.data)
        if (fromFilter) {
          setPage(0)
        }
      })
      .catch()
      .finally(() => {
        setBlockSearch(false)
      })
  }

  const add = (transition: TransitionSearchListDto) => {
    setSelectedTransitions([...selectedTransitions, transition])
  }

  const remove = (transition: TransitionSearchListDto) => {
    setSelectedTransitions(selectedTransitions.filter((element) => element.id !== transition.id))
    let isTransitionInclude = false
    pageTransitions?.content?.forEach((pageTransition) => {
      if (pageTransition.id === transition.id) {
        isTransitionInclude = true
      }
    })

    if (!isTransitionInclude) {
      setPageTransitions({ ...pageTransitions, content: [...(pageTransitions?.content ?? []), transition] })
    }
  }

  const addContentOnTrain = () => {
    dispatch(setSelectedUnseatedTransition(null))
    trainGateOutService
      .changeUnseatedTransitions(
        Number(id),
        selectedTransitions
          .filter((transition) => transition.id !== undefined)
          .map((transition) => Number(transition.id))
      )
      .then((response) => dispatch(setTrain(response.data)))
      .finally(() => history.goBack())
  }

  return (
    <>
      <Grid
        container
        className={classes.gridContainer}
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={3}
      >
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography variant="h4" color="primary">
            {t('pages.gateOut.headers.trainContainerList')}
          </Typography>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TransitionSearchFilter
            searchFilter={searchFiltered}
            transitionMode={TransitionMode.TRAIN}
            blockSearch={blockSearch}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper variant="outlined">
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={handleSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pageTransitions !== undefined &&
                    pageTransitions?.content?.map((transition) => (
                      <TableRow key={transition.id}>
                        <TableCell component="th" scope="row">
                          {transition.iluCode}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {transition.semitrailerLicencePlate}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {transition.repleted
                            ? t('pages.gateIn.form.repleted.checked')
                            : t('pages.gateIn.form.repleted.unchecked')}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <div className={classes.companyCell}>
                            {transition.companyName}
                            <div style={{ backgroundColor: transition.companyColor }} className={classes.colorDiv} />
                          </div>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {transition.shipownerName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {transition.importExport}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {transition.transitionDateTime
                            ? format(new Date(transition.transitionDateTime), 'dd.MM.yyyy HH:mm')
                            : ''}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <ConardTransitionSearchIcon
                            transitionSearchListDto={{
                              containerType: transition.containerType,
                              semitrailerType: transition.semitrailerType,
                              shippingContainerType: transition.shippingContainerType,
                            }}
                            damaged={transition.damaged ?? false}
                            containerType={transition.containerType}
                          />
                        </TableCell>
                        <TableCell className={classes.detailButton}>
                          {transition.transitionCustomsInfo?.state !==
                            TransitionCustomsInfoSearchListDtoStateEnum.Stop &&
                            !transition.lockdown && (
                              <>
                                {selectedTransitions?.find((t) => t.id === transition.id) === undefined && (
                                  <ConardButton
                                    conardVariant="light"
                                    startIcon={<AddIcon />}
                                    onClick={() => add(transition)}
                                  >
                                    {t('any.buttons.add')}
                                  </ConardButton>
                                )}

                                {selectedTransitions?.find((t) => t.id === transition.id) !== undefined && (
                                  <ConardButton conardVariant="transparent" startIcon={<CheckIcon />} disabled>
                                    {t('any.buttons.added')}
                                  </ConardButton>
                                )}
                              </>
                            )}

                          {transition.transitionCustomsInfo?.state ===
                            TransitionCustomsInfoSearchListDtoStateEnum.Stop && (
                            <ConardButton conardVariant="red" startIcon={<WarningAmberIcon />} disabled>
                              {t('pages.gateOut.buttons.customsStop')}
                            </ConardButton>
                          )}

                          {transition.transitionCustomsInfo?.state !==
                            TransitionCustomsInfoSearchListDtoStateEnum.Stop &&
                            transition.lockdown && (
                              <ConardButton conardVariant="red" startIcon={<WarningAmberIcon />} disabled>
                                {t('pages.gateOut.buttons.lockdown')}
                              </ConardButton>
                            )}
                        </TableCell>
                      </TableRow>
                    ))}
                  {!!pageTransitions?.content &&
                    pageTransitions?.content.length > 0 &&
                    pageTransitions?.totalElements === 0 && (
                      <TableRow key="noData">
                        <TableCell component="th" scope="row" colSpan={7}>
                          {t('pages.common.table.noData')}
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
              <ConardProgressBar showBar={!pageTransitions} />
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 150]}
              component="div"
              count={pageTransitions?.totalElements ?? 0}
              rowsPerPage={pageSize}
              labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography variant="h4" color="primary">
            {t('pages.gateOut.headers.trainContainerSelectedList')}
          </Typography>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper variant="outlined">
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id}>{headCell.label}</TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedTransitions?.length > 0 &&
                    [...(selectedTransitions ?? [])].map((transition) => (
                      <TableRow key={transition.id}>
                        <TableCell component="td" scope="row">
                          {transition.iluCode}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {transition.semitrailerLicencePlate}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {transition.repleted
                            ? t('pages.gateIn.form.repleted.checked')
                            : t('pages.gateIn.form.repleted.unchecked')}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          <div className={classes.companyCell}>
                            {transition.companyName}
                            <div style={{ backgroundColor: transition.companyColor }} className={classes.colorDiv} />
                          </div>
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {transition.shipownerName}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {transition.importExport}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {transition.transitionDateTime
                            ? format(new Date(transition.transitionDateTime), 'dd.MM.yyyy HH:mm')
                            : ''}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          <ConardTransitionSearchIcon
                            transitionSearchListDto={{
                              containerType: transition.containerType,
                              semitrailerType: transition.semitrailerType,
                              shippingContainerType: transition.shippingContainerType,
                            }}
                            damaged={transition.damaged ?? false}
                            containerType={transition.containerType}
                          />
                        </TableCell>
                        <TableCell className={classes.detailButton}>
                          <ConardButton conardVariant="light" onClick={() => remove(transition)}>
                            {t('any.buttons.remove')}
                          </ConardButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  {selectedTransitions?.length === 0 && (
                    <TableRow key="noData">
                      <TableCell component="th" scope="row" colSpan={7}>
                        {t('pages.common.table.noData')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
      <div className={classes.controls}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
            <div className={classes.submitButton}>
              <ConardButton conardVariant="dark" onClick={addContentOnTrain}>
                {t('any.buttons.save')}
              </ConardButton>
            </div>
          </Grid>
          <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
            <div className={classes.submitButton}>
              <ConardButton conardVariant="dark" onClick={() => history.goBack()}>
                {t('any.buttons.back')}
              </ConardButton>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default ConardTrainContentSelectForm
