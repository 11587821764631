import React, { FC, useEffect, useState } from 'react'
import { createStyles, Grid, Hidden, InputAdornment, makeStyles, MenuItem } from '@material-ui/core'
import { ShipownerDto, TransitionCustomsInfoSearchDtoStateEnum, TransitionDto, TransitionSearchDto } from '../../api'
import ConardTextField from '../../components/ConardTextField'
import ConardButton from '../../components/ConardButton'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { DatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../redux/store'
import { setSearchTransition } from '../../redux/transition/TransitionSearchSlice'
import { TransitionMode } from '../../enums/TransitionMode'
import ConardSelect from '../../components/ConardSelect'
import shipownerService from '../../services/ShipownerService'

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      width: '80%',
      marginRight: 'auto',
      marginLeft: '20px',
    },
    searchButton: {
      width: '15vw',
      minWidth: '300px',
      height: '5vh',
      minHeight: '50px',
      textAlign: 'center',
      margin: '20px',
      float: 'right',
    },
    datePickerInputInput: {
      width: '100%',
      textAlign: 'center',
    },
    datePickerInputRoot: {
      width: '60%',
      height: '58.5px',
      margin: 'auto',
    },
    datePickerRoot: {
      width: '100%',
      background: theme.palette.secondary.main,
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderRadius: '30px',
      '&:hover': {
        borderColor: 'black',
      },
    },
  })
)

interface SearchFilterProps {
  searchFilter: (data: TransitionSearchDto | undefined) => void
  setShowFilter?: (showFilter: boolean) => void
  transitionMode?: TransitionMode
}

const CustomsFilter: FC<SearchFilterProps> = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useAppDispatch()

  const { searchFilter, transitionMode } = props

  const transitions = useSelector<RootState, TransitionSearchDto | undefined>((state) =>
    transitionMode === TransitionMode.TRAIN
      ? state.transitionSearch.transitionSearchTrainContent
      : state.transitionSearch.transitionSearch
  )

  const { handleSubmit, control, setValue } = useForm<TransitionDto>({})
  const [customsState, setCustomsState] = useState<TransitionCustomsInfoSearchDtoStateEnum | undefined>(
    transitions?.transitionCustomsInfo?.state ?? undefined
  )

  const [shipownerId, setShipownerId] = useState<number | undefined>(transitions?.container?.shipowner?.id ?? undefined)
  const [shipowners, setShipowners] = useState<ShipownerDto[] | undefined>(undefined)

  const [transitionDateFrom, setTransitionDateFrom] = useState<MaterialUiPickersDate | null>(
    transitions?.transitionDateTimeFrom ? new Date(transitions?.transitionDateTimeFrom) : null
  )
  const [transitionDateTo, setTransitionDateTo] = useState<MaterialUiPickersDate | null>(
    transitions?.transitionDateTimeTo ? new Date(transitions?.transitionDateTimeTo) : null
  )

  const [dateOfControlFrom, setDateOfControlFrom] = useState<MaterialUiPickersDate | null>(
    transitions?.transitionCustomsInfo?.dateOfControlFrom
      ? new Date(transitions?.transitionCustomsInfo?.dateOfControlFrom)
      : null
  )
  const [dateOfControlTo, setDateOfControlTo] = useState<MaterialUiPickersDate | null>(
    transitions?.transitionCustomsInfo?.dateOfControlTo
      ? new Date(transitions?.transitionCustomsInfo?.dateOfControlTo)
      : null
  )

  const getAllShipowners = () => {
    shipownerService.findAll(undefined, undefined, undefined).then((response) => {
      setShipowners(response.data.content)
    })
  }

  const onChangeShipowner = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setShipownerId(event.target.value as unknown as number)
  }

  const dateFromOnChange = (date: MaterialUiPickersDate | null) => {
    setTransitionDateFrom(date || new Date())
    setValue('transitionDateTimeFrom', date)
  }
  const dateToOnChange = (date: MaterialUiPickersDate | null) => {
    setTransitionDateTo(date || new Date())
    setValue('transitionDateTimeTo', date)
  }

  const dateOfControlFromOnChange = (date: MaterialUiPickersDate | null) => {
    setDateOfControlFrom(date || new Date())
    setValue('transitionCustomsInfo.dateOfControlFrom', date)
  }
  const dateOfControlToOnChange = (date: MaterialUiPickersDate | null) => {
    setDateOfControlTo(date || new Date())
    setValue('transitionCustomsInfo.dateOfControlTo', date)
  }

  const onChangeCustomsState = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCustomsState(event.target.value as TransitionCustomsInfoSearchDtoStateEnum)
  }

  useEffect(() => {
    getAllShipowners()
  }, [])

  const cleanFilter = () => {
    control.setValue('container.iluCode', '')
    control.setValue('bookingReference', '')
    control.setValue('transitionCustomsInfo.note', '')
    control.setValue('transitionCustomsInfo.gateInTrainName', '')

    setShipownerId(undefined)
    control.setValue('container.shipowner.id', -1)
    setCustomsState(undefined)
    control.setValue('transitionCustomsInfo.state', -1)
    setTransitionDateFrom(null)
    control.setValue('transitionDateTimeFrom', null)
    setTransitionDateTo(null)
    control.setValue('transitionDateTimeTo', null)
    setDateOfControlFrom(null)
    control.setValue('transitionCustomsInfo.dateOfControlFrom', null)
    setDateOfControlTo(null)
    control.setValue('transitionCustomsInfo.dateOfControlTo', null)

    dispatch(setSearchTransition(undefined))
    searchFilter(undefined)
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit(searchFilter)}>
      <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <ConardTextField
            id="container.iluCode"
            name="container.iluCode"
            label={t('pages.customs.table.iluCode')}
            defaultValue={transitions?.container?.iluCode ?? ''}
            control={control}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <ConardSelect
            id="container.shipowner.id"
            name="container.shipowner.id"
            label={t('pages.customs.table.shipowner')}
            defaultValue={shipownerId ?? -1}
            value={shipownerId ?? -1}
            onChange={(event) => onChangeShipowner(event)}
            control={control}
          >
            <MenuItem value={-1}>{t('pages.common.select.all')}</MenuItem>
            {shipowners?.map((shipowner) => (
              <MenuItem key={shipowner.id} value={shipowner.id}>
                {shipowner.name}
              </MenuItem>
            ))}
          </ConardSelect>
        </Grid>
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <ConardSelect
            id="transitionCustomsInfo.state"
            name="transitionCustomsInfo.state"
            label={t('pages.customs.table.customsState.label')}
            value={customsState ?? -1}
            defaultValue={customsState ?? -1}
            onChange={(event) => onChangeCustomsState(event)}
            control={control}
          >
            <MenuItem value={-1}>{t('pages.common.select.all')}</MenuItem>
            {Object.values(TransitionCustomsInfoSearchDtoStateEnum)
              .filter((e) => e !== TransitionCustomsInfoSearchDtoStateEnum.T1ByEmail)
              .map((value) => (
                <MenuItem key={value} value={value}>
                  {t('enum.transitionCustomsState.' + value)}
                </MenuItem>
              ))}
          </ConardSelect>
        </Grid>
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <ConardTextField
            id="bookingReference"
            name="bookingReference"
            label={t('pages.gateIn.form.bookingReference.label')}
            defaultValue={transitions?.bookingReference ?? ''}
            control={control}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <ConardTextField
            id="transitionCustomsInfo.gateInTrainName"
            name="transitionCustomsInfo.gateInTrainName"
            label={t('pages.customs.table.trainName')}
            defaultValue={transitions?.transitionCustomsInfo?.gateInTrainName ?? ''}
            control={control}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <ConardTextField
            id="transitionCustomsInfo.note"
            name="transitionCustomsInfo.note"
            label={t('pages.gateIn.form.note.label')}
            defaultValue={transitions?.transitionCustomsInfo?.note ?? ''}
            control={control}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <Controller
            name="transitionDateTimeFrom"
            control={control}
            render={() => (
              <DatePicker
                emptyLabel={t('pages.customs.datePicker.arrival.transitionDateFrom')}
                format="dd.MM.yyyy"
                value={transitionDateFrom}
                onChange={(value) => {
                  dateFromOnChange(value)
                }}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <Hidden lgDown>
                      <InputAdornment position="start">
                        <CalendarTodayIcon />
                      </InputAdornment>
                    </Hidden>
                  ),
                  classes: {
                    root: classes.datePickerInputRoot,
                    input: classes.datePickerInputInput,
                  },
                }}
                className={classes.datePickerRoot}
              />
            )}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <Controller
            name="transitionDateTimeTo"
            control={control}
            render={() => (
              <DatePicker
                emptyLabel={t('pages.customs.datePicker.arrival.transitionDateTo')}
                format="dd.MM.yyyy"
                value={transitionDateTo}
                onChange={(value) => {
                  dateToOnChange(value)
                }}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <Hidden lgDown>
                      <InputAdornment position="start">
                        <CalendarTodayIcon />
                      </InputAdornment>
                    </Hidden>
                  ),
                  classes: {
                    root: classes.datePickerInputRoot,
                    input: classes.datePickerInputInput,
                  },
                }}
                className={classes.datePickerRoot}
              />
            )}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <Controller
            name="transitionCustomsInfo.dateOfControlFrom"
            control={control}
            render={() => (
              <DatePicker
                emptyLabel={t('pages.customs.datePicker.dateOfControl.dateOfControlFrom')}
                format="dd.MM.yyyy"
                value={dateOfControlFrom}
                onChange={(value) => {
                  dateOfControlFromOnChange(value)
                }}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <Hidden lgDown>
                      <InputAdornment position="start">
                        <CalendarTodayIcon />
                      </InputAdornment>
                    </Hidden>
                  ),
                  classes: {
                    root: classes.datePickerInputRoot,
                    input: classes.datePickerInputInput,
                  },
                }}
                className={classes.datePickerRoot}
              />
            )}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
          <Controller
            name="transitionCustomsInfo.dateOfControlTo"
            control={control}
            render={() => (
              <DatePicker
                emptyLabel={t('pages.customs.datePicker.dateOfControl.dateOfControlTo')}
                format="dd.MM.yyyy"
                value={dateOfControlTo}
                onChange={(value) => {
                  dateOfControlToOnChange(value)
                }}
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <Hidden lgDown>
                      <InputAdornment position="start">
                        <CalendarTodayIcon />
                      </InputAdornment>
                    </Hidden>
                  ),
                  classes: {
                    root: classes.datePickerInputRoot,
                    input: classes.datePickerInputInput,
                  },
                }}
                className={classes.datePickerRoot}
              />
            )}
          />
        </Grid>

        <Grid item xl={2} lg={2} md={6} sm={6} xs={12} />
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12} />
        <Grid item xl={2} lg={2} md={6} sm={6} xs={12} />
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className={classes.searchButton}>
            <ConardButton conardVariant="light" type="submit">
              {t('any.buttons.search')}
            </ConardButton>
          </div>
          {transitionMode !== TransitionMode.TRAIN && (
            <div className={classes.searchButton}>
              <ConardButton conardVariant="transparent" type="reset" onClick={cleanFilter}>
                {t('any.buttons.reset')}
              </ConardButton>
            </div>
          )}
        </Grid>
      </Grid>
    </form>
  )
}

export default CustomsFilter
