import React, { FC, useState } from 'react'
import { TrainDto } from '../../api'
import { alpha, createStyles, Grid, Paper, Typography } from '@material-ui/core'
import ConardButton from '../ConardButton'
import TrainResendCodecoDialog from '../dialogs/TrainResendCodecoDialog'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { ForwardToInbox } from '@mui/icons-material'

const useStyles = makeStyles((theme) =>
  createStyles({
    gridItem: {
      marginBottom: '10px',
    },
    paper: {
      backgroundColor: alpha(theme.palette.secondary.light, 0.3),
      padding: '2vh 2vw 2vh 2vw',
      width: '100%',
    },
  })
)

interface CodecoSendBoxProps {
  train: TrainDto
}

const CodecoSendBox: FC<CodecoSendBoxProps> = (props) => {
  const { train } = props
  const { t } = useTranslation()
  const classes = useStyles()

  const [showResendCodecoDialog, setShowResendCodecoDialog] = useState(false)

  return (
    <>
      <Paper elevation={0} className={classes.paper}>
        <Grid container>
          <Grid item xs={12} className={classes.gridItem}>
            <Typography variant="h4" color="primary">
              {t('pages.gateIn.headers.codeco')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" color="primary">
              {t('any.buttons.resendCodeco') + ': '}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ConardButton
              conardVariant="light"
              startIcon={<ForwardToInbox />}
              onClick={() => setShowResendCodecoDialog(true)}
            >
              {t('pages.gateOut.buttons.send')}
            </ConardButton>
          </Grid>
        </Grid>
      </Paper>
      <TrainResendCodecoDialog
        showDialog={showResendCodecoDialog}
        setShowDialog={setShowResendCodecoDialog}
        train={train}
      />
    </>
  )
}

export default CodecoSendBox
