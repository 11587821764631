import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import {
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import auditService from '../../services/AuditService'
import { Order } from '../../enums/Order'
import { AuditLogDto, AuditLogSearchDto, PageAuditLogDto } from '../../api'
import { cleanObject } from '../../utils/utils'
import { format } from 'date-fns'
import AuditFilter from './AuditFilter'
import ConardProgressBar from '../../components/ConardProgressBar'

const useStyles = makeStyles({
  gridContainer: {
    marginTop: '8vh',
  },
})

interface HeadCell {
  id: keyof AuditLogDto
  label: string
}

const AuditListPage: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const [auditLogs, setAuditLogs] = useState<PageAuditLogDto | null>()
  const [searchDto, setSearchDto] = useState<AuditLogSearchDto | undefined>()

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(50)
  const [order, setOrder] = useState<Order>(Order.Desc)
  const [orderBy, setOrderBy] = useState<keyof AuditLogDto>('createdAt')

  const headCells: HeadCell[] = [
    { id: 'createdBy', label: t('pages.history.header.createdBy') },
    { id: 'createdAt', label: t('pages.history.header.createdAt') },
  ]

  useEffect(() => {
    search(searchDto, false)
  }, [page, pageSize, orderBy, order]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSort = (property: keyof AuditLogDto) => () => {
    const isAsc = orderBy === property && order === Order.Asc
    setOrder(isAsc ? Order.Desc : Order.Asc)
    setOrderBy(property)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value))
    setPage(0)
  }

  const searchFiltered = (data: AuditLogSearchDto | undefined) => {
    search(data, true)
  }

  const search = (data: AuditLogSearchDto | undefined, fromFilter: boolean) => {
    setSearchDto(data)

    const dateFrom: Date | undefined = data?.createdAtFrom ? new Date(data?.createdAtFrom) : undefined
    dateFrom?.setHours(0, 0, 0)

    const dateTo: Date | undefined = data?.createdAtTo ? new Date(data?.createdAtTo) : undefined
    dateTo?.setHours(23, 59, 59)

    data = {
      ...data,
      createdAtFrom: dateFrom?.toISOString(),
      createdAtTo: dateTo?.toISOString(),
    }

    let currentSort: Array<string> | undefined = undefined
    if (orderBy !== undefined) {
      const currentOrder: string = order !== undefined ? order : 'asc'
      currentSort = [orderBy + ',' + currentOrder]
    }

    auditService
      .auditSearch(page, pageSize, currentSort, cleanObject(data))
      .then((response) => {
        setAuditLogs(response.data)
        if (fromFilter) {
          setPage(0)
        }
      })
      .catch()
  }

  return (
    <>
      <Typography variant="h4" color="primary">
        {t('pages.history.title')}
      </Typography>
      <Grid
        container
        className={classes.gridContainer}
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={3}
      >
        <AuditFilter searchFilter={searchFiltered} />
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper variant="outlined">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell key="message">{t('pages.history.header.message')}</TableCell>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={handleSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {auditLogs?.content?.map((auditLog) => (
                    <TableRow key={auditLog.id}>
                      <TableCell component="th" scope="row">
                        {auditLog.message}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {auditLog.createdBy?.username}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {format(new Date(auditLog.createdAt ?? new Date()), 'dd.MM.yyyy HH:mm:ss')}
                      </TableCell>
                    </TableRow>
                  ))}

                  {auditLogs?.content?.length === 0 && (
                    <TableRow key="noData">
                      <TableCell component="th" scope="row" colSpan={7}>
                        {t('pages.common.table.noData')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <ConardProgressBar showBar={!auditLogs} />
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 150]}
              component="div"
              count={auditLogs?.totalElements ?? 0}
              rowsPerPage={pageSize}
              labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default AuditListPage
