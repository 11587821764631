import { Grid, makeStyles, Typography } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { RHFTextField } from '../../../components/shared/rhf/RHFTextField'
import ConardButton from '../../../components/ConardButton'
import transitionExtraService from '../../../services/TransitionExtraService'
import { useFormInitialValues } from '../../../hooks/useFormInitialValues'
import { ExtraDto } from '../../../api'

const useStyles = makeStyles({
  container: {
    marginTop: '8vh',
    width: '70vw',
    margin: 'auto',
    minWidth: '300px',
  },
  typographyLabel: {
    width: '50vw',
  },
  button: {
    width: '250px',
    minWidth: '200px',
    textAlign: 'center',
    margin: 'auto',
    minHeight: '50px',
  },
  alert: {
    margin: 'auto',
    width: '50vw',
    marginTop: '20vh',
  },
  buttonWrapper: {
    marginTop: '20px',
    width: '70vw',
    margin: 'auto',
    minWidth: '300px',
  },
})

type AdditionalServicesItemFormValues = {
  name: string
  price: number | undefined
}
const initialValues: AdditionalServicesItemFormValues = {
  name: '',
  price: undefined,
}

export const AdditionalServicesItem = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const { id } = useParams<{
    id: string
  }>()

  const formMethods = useForm<AdditionalServicesItemFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
  })

  const [item, setItem] = useState<ExtraDto | undefined>(undefined)

  const fetchData = useCallback(async () => {
    const response = await transitionExtraService.findById(Number(id))

    if (response.data) {
      setItem(response.data)
    }
  }, [id])

  const onSubmit = async (formValue: AdditionalServicesItemFormValues) => {
    try {
      await transitionExtraService.changePrice(Number(id), formValue.price ?? 0)
    } catch (err) {
      console.error('error submitting price change', err)
    }
    history.push('/expenses/additional-services')
  }

  useEffect(() => {
    try {
      fetchData()
    } catch (err) {
      console.error('Error fetching TransitionExtra item', err)
    }
  }, [fetchData])

  useFormInitialValues(mapValues(item), formMethods.reset)

  return (
    <>
      {item && (
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <Typography variant="h4" color="primary" className={classes.typographyLabel}>
              {t('pages.priceList.labels.edit')}
            </Typography>
            <Grid
              container
              className={classes.container}
              direction="row"
              spacing={7}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                <RHFTextField label={t('entity.additionalServices.fields.itemCode')} name="name" disabled />
              </Grid>
              <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                <RHFTextField
                  label={t('entity.additionalServices.fields.price')}
                  name="price"
                  error={!!formMethods.errors.price}
                />
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.buttonWrapper}
              direction="row"
              spacing={7}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xl={2} lg={4} md={12} sm={12} xs={12}>
                <ConardButton className={classes.button} conardVariant="dark" type="submit">
                  {t('any.buttons.save')}
                </ConardButton>
              </Grid>
              <Grid item xl={2} lg={4} md={12} sm={12} xs={12}>
                <ConardButton className={classes.button} conardVariant="dark" onClick={() => history.goBack()}>
                  {t('any.buttons.back')}
                </ConardButton>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      )}
    </>
  )
}

const mapValues = (entity: ExtraDto | undefined): AdditionalServicesItemFormValues | undefined => {
  if (!entity) return undefined

  return {
    name: entity.name ?? '',
    price: entity.price ?? 0,
  }
}
