import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import companyService from '../../../services/CompanyService'
import {
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import { CompanyDto, CompanySearchDto, PageCompanyDto } from '../../../api'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Order } from '../../../enums/Order'
import ConardButton from '../../../components/ConardButton'
import CompanyFilter from './CompanyFilter'
import FilterListIcon from '@material-ui/icons/FilterList'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import ConardProgressBar from '../../../components/ConardProgressBar'
import { cleanObject } from '../../../utils/utils'

interface HeadCell {
  id: keyof CompanyDto
  label: string
}

const useStyles = makeStyles({
  table: {
    minWidth: '80vh',
  },
  gridContainer: {
    marginTop: '8vh',
  },
  typographyLabel: {
    width: '50vw',
  },
  filterButton: {
    textAlign: 'center',
    margin: '20px',
    float: 'left',
  },
  createButton: {
    width: '15vw',
    minWidth: '300px',
    height: '5vh',
    minHeight: '50px',
    textAlign: 'center',
    float: 'right',
    margin: '1vh',
  },
  noData: {
    textAlign: 'center',
  },
  editTable: {
    width: '7vw',
  },
})

const ParentCompanyListPage: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  const [companiesPage, setCompaniesPage] = useState<PageCompanyDto | undefined>(undefined)
  const [order, setOrder] = useState(Order.Asc)
  const [orderBy, setOrderBy] = useState('name')
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(50)
  const [filtering, setFiltering] = useState(false)
  const [companySearchDto, setCompanySearchDto] = useState<CompanySearchDto>({ showOnlyParents: true })

  const headCells: HeadCell[] = [
    { id: 'name', label: t('entity.company.fields.parentName') },
    { id: 'address', label: t('entity.company.fields.address') },
    { id: 'companyIdentificationNumber', label: t('entity.company.fields.companyIdentificationNumber') },
    { id: 'companyTaxNumber', label: t('entity.company.fields.companyTaxNumber') },
  ]

  const onSort = (newOrderBy: string) => () => {
    setOrderBy(newOrderBy)
    setOrder(orderBy === newOrderBy ? (order === Order.Asc ? Order.Desc : Order.Asc) : Order.Asc)
  }

  const onPage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage)
  }

  const onRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value, 10))
    setPage(0)
  }

  const searchFiltered = (data: CompanySearchDto) => {
    search({ ...data, showOnlyParents: true }, true)
  }

  const search = (data: CompanySearchDto, fromFilter: boolean) => {
    setCompanySearchDto(data)

    companyService.search(page, pageSize, orderBy, order, cleanObject(data)).then((response) => {
      setCompaniesPage(response.data)
      if (fromFilter) {
        setPage(0)
      }
    })
  }

  useEffect(() => {
    search(companySearchDto, false)
  }, [order, orderBy, page, pageSize]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Typography variant="h4" color="primary" className={classes.typographyLabel}>
        {t('pages.companyManagement.labels.parentList')}
      </Typography>
      <Grid container direction="row" justifyContent="center" alignItems="flex-start" className={classes.gridContainer}>
        <Grid item xs={6}>
          <div className={classes.filterButton}>
            <ConardButton
              conardVariant="transparent"
              startIcon={<FilterListIcon />}
              endIcon={filtering ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              onClick={() => setFiltering(!filtering)}
            >
              {t('any.buttons.filter')}
            </ConardButton>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.createButton}>
            <ConardButton
              conardVariant="light"
              onClick={() => history.push('/customer/parent-company/create')}
              startIcon={<AddIcon />}
            >
              {t('any.buttons.add')}
            </ConardButton>
          </div>
        </Grid>

        {filtering && (
          <Grid item lg={12} xs={11}>
            <CompanyFilter onFilterSubmit={searchFiltered} filterParentCompanies />
          </Grid>
        )}

        <Grid item lg={12} xs={11}>
          <Paper variant="outlined">
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : Order.Asc}
                          onClick={onSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {companiesPage?.content?.map((company) => (
                    <TableRow key={company.id}>
                      <TableCell>{company.name}</TableCell>
                      <TableCell>{company.address}</TableCell>
                      <TableCell>{company.companyIdentificationNumber}</TableCell>
                      <TableCell>{company.companyTaxNumber}</TableCell>
                      <TableCell className={classes.editTable}>
                        <ConardButton
                          conardVariant="light"
                          onClick={() => history.push('/customer/parent-company/update/' + company.id)}
                          startIcon={<EditIcon />}
                        >
                          {t('any.buttons.edit')}
                        </ConardButton>
                      </TableCell>
                    </TableRow>
                  ))}

                  {companiesPage?.content?.length === 0 && (
                    <TableRow>
                      <TableCell className={classes.noData}>{t('pages.common.table.noData')}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <ConardProgressBar showBar={!companiesPage} />
            </TableContainer>
            <TablePagination
              component="div"
              count={companiesPage?.totalElements || 0}
              onPageChange={onPage}
              page={page}
              rowsPerPage={pageSize}
              labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
              rowsPerPageOptions={[10, 20, 50, 150]}
              onRowsPerPageChange={onRowsPerPage}
            />
          </Paper>
        </Grid>
        <Grid item lg={12} xs={11}>
          <div className={classes.createButton}>
            <ConardButton
              conardVariant="light"
              onClick={() => history.push('/customer/parent-company/create')}
              startIcon={<AddIcon />}
            >
              {t('any.buttons.add')}
            </ConardButton>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default ParentCompanyListPage
