import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Order } from '../../../enums/Order'
import ConardButton from '../../../components/ConardButton'
import EditIcon from '@material-ui/icons/Edit'
import ConardProgressBar from '../../../components/ConardProgressBar'
import { useHistory } from 'react-router-dom'
import { ExtraDto, PageExtraDto } from '../../../api'
import transitionExtraService from '../../../services/TransitionExtraService'

const useStyles = makeStyles({
  table: {
    minWidth: '80vh',
  },
  gridContainer: {
    marginTop: '8vh',
  },
  typographyLabel: {
    width: '50vw',
  },
  noData: {
    textAlign: 'center',
  },
  editTable: {
    width: '7vw',
  },
})

interface HeadCell {
  id: keyof ExtraDto
  label: string
}

export const AdditionalServicesPage = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  const headCells: HeadCell[] = [
    { id: 'name', label: t('entity.additionalServices.fields.itemCode') },
    { id: 'price', label: t('entity.additionalServices.fields.price') },
  ]

  const [order, setOrder] = useState(Order.Asc)
  const [orderBy, setOrderBy] = useState('id')
  const [additionalServices, setAdditionalServices] = useState<PageExtraDto | undefined>(undefined)

  const onSort = (newOrderBy: string) => () => {
    setOrderBy(newOrderBy)
    setOrder(orderBy === newOrderBy ? (order === Order.Asc ? Order.Desc : Order.Asc) : Order.Asc)
  }

  const loadData = async () => {
    const response = await transitionExtraService.findAll(undefined, undefined, orderBy, order)
    if (response.data) {
      setAdditionalServices(response.data)
    }
  }

  useEffect(() => {
    try {
      loadData()
    } catch (error) {
      console.error('Error fetching data for transitionExtras', error)
    }
  }, [order, orderBy]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Typography variant="h4" color="primary" className={classes.typographyLabel}>
        {t('pages.additionalServices.labels.title')}
      </Typography>
      <Grid container direction="row" justifyContent="center" alignItems="flex-start" className={classes.gridContainer}>
        <Grid item lg={12} md={11} sm={11} xs={11}>
          <Paper variant="outlined">
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : Order.Asc}
                          onClick={onSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {additionalServices?.content?.map((additionalServicesItem) => (
                    <TableRow key={additionalServicesItem.id}>
                      <TableCell component="th" scope="row">
                        {additionalServicesItem.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {additionalServicesItem.price}
                      </TableCell>
                      <TableCell className={classes.editTable}>
                        <ConardButton
                          conardVariant="light"
                          onClick={() =>
                            history.push('/expenses/additional-services/update/' + additionalServicesItem.id)
                          }
                          startIcon={<EditIcon />}
                        >
                          {t('any.buttons.edit')}
                        </ConardButton>
                      </TableCell>
                    </TableRow>
                  ))}

                  {additionalServices?.content?.length === 0 && (
                    <TableRow key="noData">
                      <TableCell component="th" scope="row" colSpan={7} className={classes.noData}>
                        {t('pages.common.table.noData')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <ConardProgressBar showBar={!additionalServices} />
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}
