import React, { FC, useRef, useState } from 'react'
import Webcam from 'react-webcam'
import { makeStyles } from '@material-ui/core/styles'
import { AppBar, createStyles, Dialog, IconButton, Theme, Toolbar, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import DoneIcon from '@material-ui/icons/Done'

const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    appBar: {
      position: 'relative',
      paddingRight: '0px !important',
    },
    toolbarGutters: {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
    closeIconButton: {
      marginLeft: '0px',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    dialogPaper: {
      [theme.breakpoints.down('sm')]: {
        width: '100vw',
        height: '100vh',
      },
      [theme.breakpoints.up('md')]: {
        width: '80vw',
        height: '80vh',
      },
    },
    container: {
      display: 'grid',
      height: '100%',
      minHeight: '550px',
      minWidth: '320px',
      backgroundColor: theme.palette.primary.light,
    },
    webCam: {
      maxWidth: '100%',
      height: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    doneIconButton: {
      marginLeft: '2vh',
    },
    canvas: (props) => ({
      // canvas must always be present so it can be interacted with => not possible to use ConardHide
      display: props.canvasDisplayed ? 'block' : 'none',
      margin: 'auto',
    }),
  })
)

interface StyleProps {
  canvasDisplayed: boolean
}

interface PhotographerProps {
  title: string
  open: boolean
  onClose: () => void
  onAccept: (photo: string) => void
}

const ConardPhotographer: FC<PhotographerProps> = (props) => {
  const [screenshot, setScreenshot] = useState<string | null>(null)

  const classes = useStyles({ canvasDisplayed: screenshot !== null })

  const cameraRef = useRef<Webcam | null>(null)
  const canvasRef = useRef<HTMLCanvasElement | null>(null)

  const { title, open, onClose, onAccept } = props

  const videoConstraints: MediaStreamConstraints['video'] = {
    facingMode: {
      ideal: 'environment',
    },
  }

  const takeScreenshot = () => {
    setScreenshot(cameraRef.current?.getScreenshot() as string)

    const canvas: HTMLCanvasElement | null = canvasRef.current
    const context = canvas?.getContext('2d')

    if (context && cameraRef.current?.getScreenshot()) {
      const image = new Image()
      image.src = cameraRef.current?.getScreenshot() as string
      image.onload = function () {
        context.canvas.width = image.width
        context.canvas.height = image.height
        context.drawImage(image, 0, 0)
      }
    }
  }

  const accept = () => {
    if (screenshot) {
      onAccept(screenshot)
      setScreenshot(null)
    }
  }

  const close = () => {
    setScreenshot(null)
    onClose()
  }

  return (
    <Dialog open={open} fullScreen>
      <AppBar className={classes.appBar}>
        <Toolbar
          classes={{
            gutters: classes.toolbarGutters,
          }}
        >
          <IconButton edge="start" color="inherit" onClick={close} className={classes.closeIconButton}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>

          {screenshot && (
            <>
              <IconButton edge="start" color="inherit" onClick={() => setScreenshot(null)}>
                <RotateLeftIcon />
              </IconButton>
              <IconButton edge="start" color="inherit" onClick={accept} className={classes.doneIconButton}>
                <DoneIcon />
              </IconButton>
            </>
          )}
        </Toolbar>
      </AppBar>

      <div className={classes.container}>
        <canvas ref={canvasRef} className={classes.canvas} />

        {screenshot === null && (
          <Webcam
            audio={false}
            ref={cameraRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            onClick={takeScreenshot}
            className={classes.webCam}
          />
        )}
      </div>
    </Dialog>
  )
}

export default ConardPhotographer
