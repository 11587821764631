import React, { FC } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ConardButton from '../ConardButton'

export interface NotificationRemoveDialogProps {
  showRemoveDialog: boolean
  onClose: () => void
  onRemove: () => void
  isDisabled: boolean
}

const NotificationRemoveDialog: FC<NotificationRemoveDialogProps> = ({
  showRemoveDialog,
  onClose,
  onRemove,
  isDisabled,
}) => {
  const { t } = useTranslation()

  return (
    <Dialog open={showRemoveDialog}>
      <DialogTitle>{t('pages.notifications.dialogs.remove.title')}</DialogTitle>
      <DialogContent>
        <Typography>{t('pages.notifications.dialogs.remove.message')}</Typography>
      </DialogContent>
      <DialogActions>
        <ConardButton conardVariant="transparent" onClick={onClose}>
          {t('any.buttons.cancel')}
        </ConardButton>
        <ConardButton conardVariant="dark" onClick={onRemove} disabled={isDisabled}>
          {t('any.buttons.ok')}
        </ConardButton>
      </DialogActions>
    </Dialog>
  )
}

export default NotificationRemoveDialog
