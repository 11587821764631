import { makeStyles } from '@material-ui/core'
import React, { FC } from 'react'

const useStyles = makeStyles({
  backdrop: {
    zIndex: 1600,
    top: 0,
    left: 0,
    position: 'fixed',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

interface BackdropProps {
  open: boolean
  children: React.ReactNode
}

export const Backdrop: FC<BackdropProps> = ({ open, children }) => {
  const classes = useStyles()
  if (!open) return null
  return <div className={classes.backdrop}>{children}</div>
}
