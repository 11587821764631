import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core'
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded'

const useStyles = makeStyles((theme) =>
  createStyles({
    submissionGrid: {
      height: '50vh',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    submissionSuccessIcon: {
      fontSize: 200,
      color: theme.palette.primary.main,
    },
  })
)

const CoparnEmptyDeleteSuccessPage: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <Grid container className={classes.submissionGrid}>
        <Grid item>
          <CheckCircleOutlineRoundedIcon className={classes.submissionSuccessIcon} />
        </Grid>
        <Grid item>
          <Typography variant="h6" color="primary">
            {t('any.labels.deleteSuccess')}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}
export default CoparnEmptyDeleteSuccessPage
