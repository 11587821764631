import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AvailableContainerDto,
  AvailableContainerDtoCoparnContainerQualityEnum,
  CoparnContainerDto,
  CoparnContainerDtoCoparnContainerQualityEnum,
} from '../../api'
import { FormProvider, useForm } from 'react-hook-form'
import ConardButton from '../../components/ConardButton'
import ConardSelect from '../ConardSelect'
import { RHFTextField } from '../shared/rhf/RHFTextField'
import { Grid, MenuItem, styled } from '@mui/material'

interface AvailableContainerFilterProps {
  coparnContainer: CoparnContainerDto
  containersFilter: (data: AvailableContainerDto) => void
}

const AvailableContainerSelectionFilter: FC<AvailableContainerFilterProps> = ({
  coparnContainer,
  containersFilter,
}) => {
  const { t } = useTranslation()

  const formMethods = useForm<AvailableContainerDto>({
    defaultValues: {
      iluCode: '',
      coparnContainerQuality:
        coparnContainer.coparnContainerQuality as unknown as AvailableContainerDtoCoparnContainerQualityEnum,
    },
  })

  const [selectQuality, setSelectQuality] = useState(coparnContainer.coparnContainerQuality)

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(containersFilter)}>
        <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <RHFTextField name="iluCode" label={t('pages.gateOut.coparn.modal.iluCode')} />
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <ConardSelect
              roundedSquare
              label={t('pages.gateOut.coparn.modal.quality')}
              name="coparnContainerQuality"
              value={selectQuality}
              onChange={(e) => setSelectQuality(e.target.value as CoparnContainerDtoCoparnContainerQualityEnum)}
              defaultValue={coparnContainer.coparnContainerQuality}
              control={formMethods.control}
            >
              <MenuItem value={AvailableContainerDtoCoparnContainerQualityEnum.All}>
                {t('pages.common.select.all')}
              </MenuItem>
              <MenuItem value={AvailableContainerDtoCoparnContainerQualityEnum.A}>
                {AvailableContainerDtoCoparnContainerQualityEnum.A}
              </MenuItem>
              <MenuItem value={AvailableContainerDtoCoparnContainerQualityEnum.B}>
                {AvailableContainerDtoCoparnContainerQualityEnum.B}
              </MenuItem>
              <MenuItem value={AvailableContainerDtoCoparnContainerQualityEnum.C}>
                {AvailableContainerDtoCoparnContainerQualityEnum.C}
              </MenuItem>
            </ConardSelect>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
            <div>
              <StyledButton conardVariant="light" type="submit">
                {t('any.buttons.filter')}
              </StyledButton>
            </div>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}

export default AvailableContainerSelectionFilter

const StyledButton = styled(ConardButton)({
  borderRadius: 12,
})
