import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@material-ui/core'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { PageShortTermPassageDto, ShortTermPassageDto, ShortTermPassageSearchDto } from '../../api'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Order } from '../../enums/Order'
import ConardButton from '../../components/ConardButton'
import FilterListIcon from '@material-ui/icons/FilterList'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { cleanObject } from '../../utils/utils'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import { format } from 'date-fns'
import shortTermPassageService from '../../services/ShortTermPassageService'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import LockIcon from '@material-ui/icons/Lock'
import ShortTermPassageFilter from './ShortTermPassageFilter'
import ConardProgressBar from '../../components/ConardProgressBar'
import DoneOutlineIcon from '@material-ui/icons/DoneOutline'
import CancelIcon from '@material-ui/icons/Cancel'

const useStyles = makeStyles((theme) =>
  createStyles({
    editTable: {
      width: '7vw',
    },
    typography: {
      width: '60vw',
    },
    newCardButton: {
      width: '15vw',
      minWidth: '300px',
      height: '5vh',
      minHeight: '50px',
      textAlign: 'center',
      margin: '20px',
      float: 'right',
    },
    filterButton: {
      textAlign: 'center',
      margin: '20px',
      float: 'left',
    },
    gridContainer: {
      marginTop: '8vh',
    },
    disabledTableRow: {
      backgroundColor: theme.palette.secondary.main,
      '& > .MuiTableCell-root': {
        color: theme.palette.grey.A200,
      },
    },
  })
)

interface HeadCell {
  id: keyof ShortTermPassageDto
  label: string
}

const ShortTermPassageListPage: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const [shortTermPassages, setShortTermPassages] = useState<PageShortTermPassageDto | null>()

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(150)
  const [order, setOrder] = useState<Order>(Order.Desc)
  const [orderBy, setOrderBy] = useState<keyof ShortTermPassageDto>('validFrom')
  const [showFilter, setShowFilter] = useState(true)
  const [searchDto, setSearchDto] = useState<ShortTermPassageSearchDto | undefined>(undefined)

  const classes = useStyles()

  const headCells: HeadCell[] = [
    { id: 'licensePlate', label: t('entity.shortTermPassage.fields.licensePlate') },
    { id: 'firstName', label: t('entity.shortTermPassage.fields.firstName') },
    { id: 'surname', label: t('entity.shortTermPassage.fields.surname') },
    { id: 'company', label: t('entity.shortTermPassage.fields.company') },
    { id: 'valid', label: t('entity.shortTermPassage.fields.valid') },
    { id: 'checkpointOneAllowed', label: t('entity.shortTermPassage.fields.checkpointOne') },
    { id: 'checkpointTwoAllowed', label: t('entity.shortTermPassage.fields.checkpointTwo') },
    { id: 'checkpointThreeAllowed', label: t('entity.shortTermPassage.fields.checkpointThree') },
    { id: 'validFrom', label: t('entity.shortTermPassage.fields.validFrom') },
    { id: 'validTo', label: t('entity.shortTermPassage.fields.validTo') },
  ]

  useEffect(() => {
    search(searchDto, false)
  }, [page, pageSize, orderBy, order]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSort = (property: keyof ShortTermPassageDto) => () => {
    const isAsc = orderBy === property && order === Order.Asc
    setOrder(isAsc ? Order.Desc : Order.Asc)
    setOrderBy(property)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    setPage(page)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value))
    setPage(0)
  }

  const handleFilter = () => {
    setShowFilter(!showFilter)
  }

  const searchFiltered = (data: ShortTermPassageSearchDto | undefined) => {
    data = {
      ...data,
      validFrom: data?.validFrom ? new Date(new Date(data.validFrom).setHours(0, 0, 0, 0)).toISOString() : undefined,
      validTo: data?.validTo ? new Date(new Date(data.validTo).setHours(23, 59, 59, 0)).toISOString() : undefined,
    }
    search(data, true)
  }

  const search = (data: ShortTermPassageSearchDto | undefined, fromFilter: boolean) => {
    setSearchDto(data)

    let currentSort: Array<string> | undefined = undefined
    if (orderBy !== undefined) {
      const currentOrder: string = order !== undefined ? order : 'asc'
      currentSort = [orderBy + ',' + currentOrder]
    }

    shortTermPassageService
      .shortTermPassageSearch(page, pageSize, currentSort, cleanObject(data))
      .then((response) => {
        setShortTermPassages(response.data)
        if (fromFilter) {
          setPage(0)
        }
      })
      .catch()
  }

  return (
    <>
      <Typography className={classes.typography} variant="h4" color="primary">
        {t('pages.entryAndParking.shortTermPassage.title')}
      </Typography>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={3}
        className={classes.gridContainer}
      >
        <Grid item xl={2} lg={3} md={6} sm={6} xs={6}>
          <div className={classes.filterButton}>
            <ConardButton
              conardVariant="transparent"
              startIcon={<FilterListIcon />}
              endIcon={showFilter ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              onClick={handleFilter}
            >
              {t('any.buttons.filter')}
            </ConardButton>
          </div>
        </Grid>
        <Grid item xl={10} lg={9} md={6} sm={6} xs={6} style={{ float: 'right' }}>
          <div className={classes.newCardButton}>
            <ConardButton
              conardVariant="light"
              startIcon={<AddIcon />}
              onClick={() => history.push('/entry-and-parking/short-term-passage/create')}
            >
              {t('any.buttons.add')}
            </ConardButton>
          </div>
        </Grid>

        {showFilter && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ShortTermPassageFilter shortTermPassageFilter={searchFiltered} />
          </Grid>
        )}

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper variant="outlined">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={handleSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell colSpan={3} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {shortTermPassages?.content?.map((shortTermPasssage) => (
                    <TableRow
                      className={shortTermPasssage?.valid ? '' : classes.disabledTableRow}
                      key={shortTermPasssage.id}
                    >
                      <TableCell component="th" scope="row">
                        {shortTermPasssage?.licensePlate}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {shortTermPasssage?.firstName}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {shortTermPasssage?.surname}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {shortTermPasssage?.company}
                      </TableCell>
                      {shortTermPasssage.valid ? (
                        <>
                          <TableCell component="th" scope="row">
                            <DoneOutlineIcon color="primary" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {shortTermPasssage.checkpointOneAllowed ? (
                              <LockOpenIcon color="primary" />
                            ) : (
                              <LockIcon color="primary" />
                            )}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {shortTermPasssage.checkpointTwoAllowed ? (
                              <LockOpenIcon color="primary" />
                            ) : (
                              <LockIcon color="primary" />
                            )}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {shortTermPasssage.checkpointThreeAllowed ? (
                              <LockOpenIcon color="primary" />
                            ) : (
                              <LockIcon color="primary" />
                            )}
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell component="th" scope="row">
                            <CancelIcon />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {shortTermPasssage.checkpointOneAllowed ? <LockOpenIcon /> : <LockIcon />}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {shortTermPasssage.checkpointTwoAllowed ? <LockOpenIcon /> : <LockIcon />}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {shortTermPasssage.checkpointThreeAllowed ? <LockOpenIcon /> : <LockIcon />}
                          </TableCell>
                        </>
                      )}
                      <TableCell component="th" scope="row">
                        {format(new Date(shortTermPasssage?.validFrom ?? new Date()), 'dd.MM.yyyy')}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {format(new Date(shortTermPasssage?.validTo ?? new Date()), 'dd.MM.yyyy')}
                      </TableCell>
                      <TableCell>
                        <ConardButton
                          conardVariant="light"
                          startIcon={<EditIcon />}
                          onClick={() =>
                            history.push('/entry-and-parking/short-term-passage/update/' + shortTermPasssage.id)
                          }
                        >
                          {t('any.buttons.edit')}
                        </ConardButton>
                      </TableCell>
                    </TableRow>
                  ))}

                  {shortTermPassages?.content?.length === 0 && (
                    <TableRow key="noData">
                      <TableCell component="th" scope="row" colSpan={11}>
                        {t('pages.common.table.noData')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <ConardProgressBar showBar={!shortTermPassages} />
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 150]}
              component="div"
              count={shortTermPassages?.totalElements ?? 0}
              rowsPerPage={pageSize}
              labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className={classes.newCardButton}>
            <ConardButton
              conardVariant="light"
              startIcon={<AddIcon />}
              onClick={() => history.push('/entry-and-parking/short-term-passage/create')}
            >
              {t('any.buttons.add')}
            </ConardButton>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default ShortTermPassageListPage
