import React, { createContext, FC, useEffect, useState } from 'react'
import myAxios from '../config/axios/axios'
import {
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Trans, useTranslation } from 'react-i18next'
import ConardButton from '../components/ConardButton'
import { AxiosRequestConfig } from 'axios'
import { setApiConfig } from '../services'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { Backdrop } from '../components/backdrop/Backdrop'

interface BackendServiceContextProps {
  loading: boolean
  errorMessage: string | null
  setLoading: (loading: boolean) => void
}

export const BackendServiceContext = createContext<BackendServiceContextProps>({
  loading: false,
  errorMessage: null,
  setLoading: () => console.warn('Default implementation of backend service was called.'),
})

const useStyles = makeStyles((theme) =>
  createStyles({
    backdrop: {
      zIndex: 1600,
    },
    errorDialogPaper: {
      minWidth: '320px',
      maxHeight: '500px',
    },
    errorDialogContentWrapper: {
      textAlign: 'center',
      margin: '5% 0 15% 0',
    },
    errorDialogIcon: {
      fontSize: 70,
      marginBottom: '10px',
      color: theme.palette.error.main,
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
    },
    item: {
      flexGrow: 1,
    },
    excelErrorDetail: {
      marginTop: '15px',
    },
  })
)

interface ValidationError {
  objectName: string
  code: string
  field: string
  arguments: any[] // eslint-disable-line @typescript-eslint/no-explicit-any
}

const BackendServiceProvider: FC = (props) => {
  const classes = useStyles()

  const [internalInit, setInternalInit] = useState(false)

  const [loading, setLoading] = useState(false)
  const [errorDialogOpen, setErrorDialogOpen] = useState(false)
  const [errorTitle, setErrorTitle] = useState<string | null>(null)
  const [rawErrorMessage, setRawErrorMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [receivedContainerIluCodes, setReceivedContainerIluCodes] = useState<string[] | null>(null)
  const [excelErrorRow, setExcelErrorRow] = useState<number | null>(null)
  const [excelErrorCol, setExcelErrorCol] = useState<number | null>(null)
  const [value, setValue] = useState<string | null>(null)

  const { t } = useTranslation()

  const contextValue: BackendServiceContextProps = {
    loading: loading,
    errorMessage: errorMessage,
    setLoading: setLoading,
  }

  const createSimpleValidationErrorMessage = (errors: ValidationError[]) => {
    const splittedErrorMessage: string[] = []

    errors.forEach((validationError) => {
      if (splittedErrorMessage.length !== 0) {
        splittedErrorMessage.push('\n')
      }

      const objectName = validationError.objectName.replace('CreateDto', '').replace('UpdateDto', '').replace('Dto', '')

      splittedErrorMessage.push(t(`entity.${objectName}.fields.${validationError.field}`) + ' ')

      const validationCode = validationError.code

      const toBeLocalizedValidationCode = `error.constraintValidation.code.${validationCode}`
      if ((validationCode === 'Max' || validationCode === 'Min') && validationError.arguments.length > 1) {
        splittedErrorMessage.push(t(toBeLocalizedValidationCode, { size: validationError.arguments[1] }))
      } else if (validationCode === 'Size' && validationError.arguments.length > 2) {
        splittedErrorMessage.push(
          t(toBeLocalizedValidationCode, {
            min: validationError.arguments[2],
            max: validationError.arguments[1],
          })
        )
      } else {
        splittedErrorMessage.push(t(toBeLocalizedValidationCode))
      }
    })
    return splittedErrorMessage.join('')
  }

  useEffect(() => {
    setApiConfig()

    const loadingRequestInterceptorIndex = myAxios.interceptors.request.use((config: AxiosRequestConfig) => {
      if (
        !config.url?.includes('/api/rest/notification') &&
        !config.url?.includes('/api/rest/gates') &&
        !config.url?.includes('/api/rest/truck-monitoring-log/search') &&
        !config.url?.includes('/api/rest/transition/operator/search')
      ) {
        setLoading(true)
      }

      return config
    })

    const loadingResponseInterceptorIndex = myAxios.interceptors.response.use(
      (response) => {
        if (
          !response.config.url?.includes('/api/rest/notification') &&
          !response.config.url?.includes('/api/rest/gates') &&
          !response.config.url?.includes('/api/rest/truck-monitoring-log/search') &&
          !response.config.url?.includes('/api/rest/transition/operator/search')
        ) {
          setLoading(false)
        }

        return response
      },
      (error) => {
        if (
          !error.config.url?.includes('/api/rest/notification') &&
          !error.config.url?.includes('/api/rest/gates') &&
          !error.config.url?.includes('/api/rest/truck-monitoring-log/search') &&
          !error.config.url?.includes('/api/rest/transition/operator/search')
        ) {
          setLoading(false)
        }
        return Promise.reject(error)
      }
    )

    const ignore404 = myAxios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.config.url?.includes('/api/rest/driver-arrival/transition/') && error.response.status === 404) {
          return Promise.resolve(error)
        }
        return Promise.reject(error)
      }
    )

    const errorResponseInterceptorIndex = myAxios.interceptors.response.use(
      (response) => response,
      (error) => {
        console.error(error)

        setErrorTitle(t('any.error'))
        setErrorMessage(t('error.unknown'))
        setRawErrorMessage(error.response.data.errorCode)

        if (error.response.data.receivedContainerIluCodes?.length !== 0) {
          setReceivedContainerIluCodes(error.response.data.receivedContainerIluCodes)
        }

        if (error.response.data.excelErrorRow?.length !== 0) {
          setExcelErrorRow(error.response.data.excelErrorRow)
        }
        if (error.response.data.excelErrorCol?.length !== 0) {
          setExcelErrorCol(error.response.data.excelErrorCol)
        }

        if (error.response.data.value !== null) {
          setValue(error.response.data.value)
        }

        if (!error.response || error.response.status !== 401) {
          setErrorDialogOpen(true)
          if (!!error.response.data) {
            if (!!error.response.data.errorCode) {
              setErrorTitle(t('any.error'))
              setErrorMessage(t(error.response.data.errorCode))
            }
            if (!!error.response.data.errors) {
              setErrorTitle(t('error.constraintValidation.title'))
              setErrorMessage(createSimpleValidationErrorMessage(error.response.data.errors))
            }
            if (error.response.status === 413) {
              setErrorTitle(t('any.error'))
              setErrorMessage(t('error.exportTooLarge'))
            }
          }
        }

        return Promise.reject(error)
      }
    )

    setInternalInit(true)

    return () => {
      myAxios.interceptors.request.eject(loadingRequestInterceptorIndex)
      myAxios.interceptors.response.eject(loadingResponseInterceptorIndex)
      myAxios.interceptors.response.eject(ignore404)
      myAxios.interceptors.response.eject(errorResponseInterceptorIndex)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BackendServiceContext.Provider value={contextValue}>
      {internalInit && props.children}
      <Backdrop open={loading}>
        <CircularProgress />
      </Backdrop>
      <Dialog
        open={errorDialogOpen}
        classes={{
          paper: classes.errorDialogPaper,
        }}
      >
        <DialogTitle>{errorTitle}</DialogTitle>
        <DialogContent>
          <div className={classes.errorDialogContentWrapper}>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item>
                <ErrorOutlineIcon className={classes.errorDialogIcon} />
              </Grid>
              <Grid item>
                {!!value ? (
                  <Trans i18nKey={rawErrorMessage} values={{ value: value }} />
                ) : (
                  <Typography>{errorMessage}</Typography>
                )}
              </Grid>
            </Grid>

            {!!receivedContainerIluCodes && (
              <div className={classes.container}>
                <div className={classes.item}>
                  <Typography>{t('pages.train.receivedContainers')}</Typography>

                  {receivedContainerIluCodes.map((iluCode) => (
                    <Typography key={iluCode}>{iluCode}</Typography>
                  ))}
                </div>
              </div>
            )}
            {!!excelErrorRow && !!excelErrorCol && (
              <div className={classes.excelErrorDetail}>
                <Trans
                  i18nKey="be.error.excel.detail"
                  values={{ rowNumber: excelErrorRow, colNumber: excelErrorCol }}
                />
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <ConardButton conardVariant="light" onClick={() => setErrorDialogOpen(false)}>
            {t('any.buttons.close')}
          </ConardButton>
        </DialogActions>
      </Dialog>
    </BackendServiceContext.Provider>
  )
}

export default BackendServiceProvider
