import { Grid, makeStyles } from '@material-ui/core'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { RHFAutocomplete } from '../../../components/shared/rhf/RHFAutocomplete'
import { AutocompleteItem } from '../../../model/autocomplete'
import { KioskLabel } from '../shared/KioskLabel'

const useStyles = makeStyles({
  input: {
    width: '100%',
  },
})

interface KioskDriverInfoFieldsProps {
  driverOptions: AutocompleteItem[]
  carrierOptions: AutocompleteItem[]
  frontLicensePlateOptions: AutocompleteItem[]
  rearLicensePlateOptions: AutocompleteItem[]

  errorDriver: boolean | undefined
  errorCarrier: boolean | undefined
  errorFrontLicensePlate: boolean | undefined
  helperTextDriver: string | undefined
  helperTextCarrier: string | undefined
  helperTextFrontLicensePlate: string | undefined

  onChangeDriver: (value: string) => void
  onChangeCarrier: (value: string) => void
  onChangeFrontLicensePlate: (value: string) => void
  onChangeRearLicensePlate: (value: string) => void
}

export const KioskDriverInfoFields: FC<KioskDriverInfoFieldsProps> = ({
  driverOptions,
  onChangeDriver,
  carrierOptions,
  onChangeCarrier,
  frontLicensePlateOptions,
  onChangeFrontLicensePlate,
  rearLicensePlateOptions,
  onChangeRearLicensePlate,
  errorDriver,
  errorCarrier,
  errorFrontLicensePlate,
  helperTextDriver,
  helperTextCarrier,
  helperTextFrontLicensePlate,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Grid container spacing={3} direction="row" justifyContent="flex-start" alignItems="center">
      <Grid item className={classes.input}>
        <KioskLabel>{t('kioskApp.driverForm.labels.driver')}</KioskLabel>
        <RHFAutocomplete
          label=""
          name="driver"
          options={driverOptions}
          onTextInputChange={onChangeDriver}
          error={errorDriver}
          helperText={helperTextDriver}
        />
      </Grid>

      <Grid item className={classes.input}>
        <KioskLabel>{t('kioskApp.driverForm.labels.carrier')}</KioskLabel>
        <RHFAutocomplete
          label=""
          name="carrier"
          options={carrierOptions}
          onTextInputChange={onChangeCarrier}
          error={errorCarrier}
          helperText={helperTextCarrier}
        />
      </Grid>

      <Grid item className={classes.input}>
        <KioskLabel>{t('kioskApp.driverForm.labels.frontLicensePlate')}</KioskLabel>
        <RHFAutocomplete
          label=""
          name="frontLicensePlate"
          options={frontLicensePlateOptions}
          onTextInputChange={onChangeFrontLicensePlate}
          error={errorFrontLicensePlate}
          helperText={helperTextFrontLicensePlate}
        />
      </Grid>

      <Grid item className={classes.input}>
        <KioskLabel>{t('kioskApp.driverForm.labels.rearLicensePlate')}</KioskLabel>
        <RHFAutocomplete
          label=""
          name="rearLicensePlate"
          options={rearLicensePlateOptions}
          onTextInputChange={onChangeRearLicensePlate}
        />
      </Grid>
    </Grid>
  )
}
