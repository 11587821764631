import { Box, makeStyles, Typography } from '@material-ui/core'
import React, { FC, useEffect, useRef, useState } from 'react'
import { AutocompleteDto, DriverArrivalDto, DriverDto } from '../../../api'
import autocompleteService from '../../../services/AutocompleteService'
import { KioskDriverInfoFields } from './KioskDriverInfoFields'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { AutocompleteItem } from '../../../model/autocomplete'
import { yupResolver } from '@hookform/resolvers/yup'
import { isAutocompleteItem } from '../../../utils/forms'
import kioskDriverArrivalService from '../../../services/KioskDriverArrivalService'
import { KioskButtons } from '../KioskButtons'
import { useFormInitialValues } from '../../../hooks/useFormInitialValues'
import { useActualDriverArival } from '../shared/useActualDriverArival'
import driverService from '../../../services/DriverService'
import { DriverValidationScheme } from './ValidationScheme'

const useStyles = makeStyles((theme) => ({
  typography: {
    color: theme.palette.primary.main,
    fontSize: '45px',
    fontWeight: 600,
  },
  body: {
    maxWidth: '800px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'auto',
    gap: '3rem',
    marginTop: '80px',
  },
}))

export type DriverInfoForm = {
  driver: AutocompleteItem | string
  carrier: AutocompleteItem | string
  frontLicensePlate: AutocompleteItem | string
  rearLicensePlate: AutocompleteItem | string
}

const initialValues: DriverInfoForm = {
  driver: '',
  carrier: '',
  frontLicensePlate: '',
  rearLicensePlate: '',
}

interface KioskDriverInfoProps {
  idDriverArrival: number
  next: () => void
}

export const KioskDriverInfo: FC<KioskDriverInfoProps> = ({ idDriverArrival, next }) => {
  const { t } = useTranslation()
  const timerId = useRef<NodeJS.Timeout | null>(null)
  const classes = useStyles()

  const { driverArrival } = useActualDriverArival(idDriverArrival)

  const formMethods = useForm<DriverInfoForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(DriverValidationScheme()),
  })

  const [driverOptions, setDriverOptions] = useState<AutocompleteDto[]>([])
  const [carrierOptions, setCarrierOptions] = useState<AutocompleteDto[]>([])
  const [frontLicensePlateOptions, setFrontLicensePlateOptions] = useState<AutocompleteDto[]>([])
  const [rearLicensePlateOptions, setRearLicensePlateOptions] = useState<AutocompleteDto[]>([])

  const driverWatch = useWatch<AutocompleteItem>({
    name: 'driver',
    control: formMethods.control,
  })

  const onChangeDriver = async (value: string) => {
    if (timerId.current) {
      clearTimeout(timerId.current)
    }
    timerId.current = setTimeout(async () => {
      const response = await autocompleteService.find(value, 'DRIVER')
      setDriverOptions(response.data)
    }, 500)
  }

  const onChangeCarrier = async (value: string) => {
    if (timerId.current) {
      clearTimeout(timerId.current)
    }
    timerId.current = setTimeout(async () => {
      const response = await autocompleteService.find(value, 'CARRIER')
      setCarrierOptions(response.data)
    }, 500)
  }

  const onChangeFrontLicensePlate = async (value: string) => {
    if (timerId.current) {
      clearTimeout(timerId.current)
    }
    timerId.current = setTimeout(async () => {
      const response = await autocompleteService.find(value, 'LICENSE_PLATE')
      setFrontLicensePlateOptions(response.data)
    }, 500)
  }

  const onChangeRearLicensePlate = async (value: string) => {
    if (timerId.current) {
      clearTimeout(timerId.current)
    }
    timerId.current = setTimeout(async () => {
      const response = await autocompleteService.find(value, 'LICENSE_PLATE')
      setRearLicensePlateOptions(response.data)
    }, 500)
  }

  const onSubmit = async (formValue: DriverInfoForm) => {
    const { driver, carrier, frontLicensePlate, rearLicensePlate } = formValue

    try {
      const updatedEntity: DriverArrivalDto = {
        ...driverArrival,
        arrivalDateTime: driverArrival?.arrivalDateTime ?? '',
        driver: {
          id: isAutocompleteItem(driver) ? driver.id : undefined,
          name: isAutocompleteItem(driver) ? driver.name : driver,
          carrierShort: {
            id: isAutocompleteItem(carrier) ? carrier.id : undefined,
            name: isAutocompleteItem(carrier) ? carrier.name : carrier,
          },
          frontLicensePlate: {
            id: isAutocompleteItem(frontLicensePlate) ? frontLicensePlate.id : undefined,
            licensePlate: isAutocompleteItem(frontLicensePlate) ? frontLicensePlate.name : frontLicensePlate,
          },
          rearLicensePlate: {
            id: isAutocompleteItem(rearLicensePlate) ? rearLicensePlate.id : undefined,
            licensePlate: isAutocompleteItem(rearLicensePlate) ? rearLicensePlate.name : rearLicensePlate,
          },
        },
      }

      await kioskDriverArrivalService.update(idDriverArrival, updatedEntity)
      next()
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const loadDriverInfo = async () => {
      const driverId = driverWatch?.id

      if (driverId) {
        const result = await driverService.findById(driverId)
        if (result.data) {
          formMethods.control.setValue('carrier', result.data.carrierShort?.name ?? '')
          formMethods.control.setValue('frontLicensePlate', result.data.frontLicensePlate?.licensePlate ?? '')
          formMethods.control.setValue('rearLicensePlate', result.data.rearLicensePlate?.licensePlate ?? '')
          formMethods.trigger(['carrier', 'frontLicensePlate'])
        }
      }
    }

    try {
      loadDriverInfo()
    } catch (err) {
      console.error(err)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- formMethods is not there intentionally (cyclic server calls)
  }, [driverWatch?.id])

  useFormInitialValues(mapValues(driverArrival?.driver), formMethods.reset)

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Box className={classes.body}>
          <Typography className={classes.typography}>{t('kioskApp.step0.text')}</Typography>
          <KioskDriverInfoFields
            driverOptions={driverOptions.map((d) => ({
              id: d.id ?? 0,
              name: d.name ?? '',
            }))}
            onChangeDriver={onChangeDriver}
            carrierOptions={carrierOptions.map((c) => ({
              id: c.id ?? 0,
              name: c.name ?? '',
            }))}
            onChangeCarrier={onChangeCarrier}
            frontLicensePlateOptions={frontLicensePlateOptions.map((f) => ({
              id: f.id ?? 0,
              name: f.name ?? '',
            }))}
            onChangeFrontLicensePlate={onChangeFrontLicensePlate}
            rearLicensePlateOptions={rearLicensePlateOptions.map((r) => ({
              id: r.id ?? 0,
              name: r.name ?? '',
            }))}
            onChangeRearLicensePlate={onChangeRearLicensePlate}
            errorDriver={!!formMethods.errors.driver}
            helperTextDriver={formMethods.errors.driver ? formMethods.errors.driver.message : ''}
            errorCarrier={!!formMethods.errors.carrier}
            helperTextCarrier={formMethods.errors.carrier ? formMethods.errors.carrier.message : ''}
            errorFrontLicensePlate={!!formMethods.errors.frontLicensePlate}
            helperTextFrontLicensePlate={
              formMethods.errors.frontLicensePlate ? formMethods.errors.frontLicensePlate.message : ''
            }
          />
        </Box>
        <KioskButtons visibleBack={false} visibleNext />
      </form>
    </FormProvider>
  )
}

const mapValues = (entity: DriverDto | undefined): DriverInfoForm | undefined => {
  if (!entity) return undefined

  return {
    driver: {
      id: entity.id ?? 0,
      name: entity.name ?? '',
    },
    carrier: {
      id: entity.carrierShort?.id ?? 0,
      name: entity.carrierShort?.name ?? '',
    },
    frontLicensePlate: {
      id: entity.frontLicensePlate?.id ?? 0,
      name: entity.frontLicensePlate?.licensePlate ?? '',
    },
    rearLicensePlate: {
      id: entity.rearLicensePlate?.id ?? 0,
      name: entity.rearLicensePlate?.licensePlate ?? '',
    },
  }
}
