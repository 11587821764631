import React, { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { createStyles, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { RHFTextField } from '../../../components/shared/rhf/RHFTextField'
import { KioskButtons } from '../KioskButtons'
import { useFormInitialValues } from '../../../hooks/useFormInitialValues'
import transitionService from '../../../services/TransitionService'
import { TransitionDto } from '../../../api'
import { useActualDriverArival } from '../shared/useActualDriverArival'
import { yupResolver } from '@hookform/resolvers/yup'
import { ReferenceValidationScheme } from './ValidationScheme'
import { KioskLabel } from '../shared/KioskLabel'

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      maxWidth: '800px',
      margin: 'auto',
      marginTop: '80px',
      minHeight: '400px',
    },
    label: {
      fontSize: 28,
      fontWeight: 650,
      color: theme.palette.primary.main,
      display: 'flex',
      justifyContent: 'center',
    },
  })
)

type KioskReferenceFormValue = {
  reference: string
}

const initialValues: KioskReferenceFormValue = {
  reference: '',
}

interface KioskReferenceProps {
  idDriverArrival: number
  previous: () => void
  next: () => void
}

export const KioskReferenceForm: FC<KioskReferenceProps> = ({ idDriverArrival, next, previous }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const formMethods = useForm<KioskReferenceFormValue>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(ReferenceValidationScheme()),
  })
  const { handleSubmit } = formMethods

  const { driverArrival } = useActualDriverArival(idDriverArrival)

  const onSubmit = async (formValue: KioskReferenceFormValue) => {
    if (driverArrival?.plannedTransition) {
      try {
        const updatedTransition: TransitionDto = {
          ...driverArrival.plannedTransition,
          bookingReference: formValue.reference,
        }
        await transitionService.update(driverArrival.plannedTransition.id ?? 0, updatedTransition)
        next()
      } catch (err) {
        console.error(err)
      }
    }
  }

  useFormInitialValues(mapValues(driverArrival?.plannedTransition), formMethods.reset)

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="column" spacing={3} className={classes.container}>
          <Grid item>
            <Typography className={classes.label}>{t('kioskApp.step5.label')}</Typography>
          </Grid>
          <Grid item>
            <KioskLabel>{t('kioskApp.step5.reference')}</KioskLabel>
            <RHFTextField name="reference" label="" />
          </Grid>
        </Grid>
        <KioskButtons visibleBack visibleNext onClickBack={() => previous()} />
      </form>
    </FormProvider>
  )
}

const mapValues = (entity: Partial<TransitionDto> | undefined): KioskReferenceFormValue | undefined => {
  if (!entity) return undefined

  return {
    reference: entity.bookingReference ?? '',
  }
}
