import {
  CarrierApi,
  CarrierDto,
  CarrierSearchDto,
  PageCarrierDto,
  PageDriverDto,
  TransitionSearchListSearchDto,
} from '../api'
import myAxios from '../config/axios/axios'
import { AxiosPromise } from 'axios'

let api: CarrierApi

export const setCarrierApiConfiguration = () => {
  api = new CarrierApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const carrierService = {
  findAll: (page: number, pageSize: number, currentSort: string[] | undefined): AxiosPromise<PageCarrierDto> => {
    return api.carrierFindAll(page, pageSize, currentSort)
  },
  carrierFindById: (id: number): AxiosPromise<CarrierDto> => {
    return api.carrierFindById(id)
  },
  carrierCreate: (carrierDto: CarrierDto): AxiosPromise<CarrierDto> => {
    return api.carrierCreate(carrierDto)
  },
  carrierUpdate: (id: number, carrierDto: CarrierDto): AxiosPromise<CarrierDto> => {
    return api.carrierUpdate(id, carrierDto)
  },
  carrierDelete: (id: number): AxiosPromise<void> => {
    return api.carrierDelete(id)
  },
  carrierSearch: (
    page: number | undefined,
    pageSize: number | undefined,
    currentSort: string[] | undefined,
    carrierSearchDto: CarrierSearchDto
  ): AxiosPromise<PageCarrierDto> => {
    return api.carrierSearch(page, pageSize, currentSort, carrierSearchDto)
  },
  assignDriver: (carrierId: number, driverId: number): AxiosPromise => {
    return api.carrierAssignCarrierDriver(carrierId, driverId)
  },
  removeDriver: (carrierId: number, driverId: number): AxiosPromise => {
    return api.carrierRemoveCarrierDriver(carrierId, driverId)
  },
  getAllDrivers: (
    carrierId: number,
    page: number | undefined,
    pageSize: number | undefined,
    currentSort: string[] | undefined
  ): AxiosPromise<PageDriverDto> => {
    return api.carrierGetCarrierDrivers(carrierId, page, pageSize, currentSort)
  },
  getCurrentCarrier: (): AxiosPromise<CarrierDto> => {
    return api.carrierGetCurrentCarrier()
  },
  getSearchDtoOfCurrentCarrier: (): AxiosPromise<TransitionSearchListSearchDto> => {
    return api.carrierGetSearchDtoOfCurrentCarrier()
  },
}

export default carrierService
