import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import {
  Box,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { PageTrainDto, TrainDto, TrainSearchDto } from '../../api'
import ConardButton from '../../components/ConardButton'
import trainService from '../../services/TrainService'
import m2mTrainService from '../../services/M2mTrainService'
import { useHistory } from 'react-router-dom'
import { format } from 'date-fns'
import { cleanObject } from '../../utils/utils'
import TrainM2mSearchFilter from './TrainM2mSearchFilter'

export interface TrainM2mCompareDialogProps {
  showCompareDialog: boolean
  setShowCompareDialog: (showCompareDialog: boolean) => void
  m2mTrainId: number
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noData: {
      textAlign: 'center',
    },
    dialog: {
      maxWidth: 'none',
      minWidth: 800,
    },
    dialogActions: {
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 16,
    },
    buttonBox: {
      display: 'flex',
      flexDirection: 'row',
      gap: 16,
    },
    trainActiveRow: {
      cursor: 'pointer',
      backgroundColor: theme.palette.background.default,
    },
    trainRow: {
      cursor: 'pointer',
    },
  })
)

const TrainM2mCompareDialog: FC<TrainM2mCompareDialogProps> = ({
  showCompareDialog,
  setShowCompareDialog,
  m2mTrainId,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  const [page, setPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)
  const dataSort = ['departureDateTime,desc']

  const [pageTrain, setPageTrain] = useState<PageTrainDto>()
  const [train, setTrain] = useState<TrainDto | undefined>(undefined)
  const [disableButton, setDisabledButton] = useState<boolean>(true)
  const [searchDto, setSearchDto] = useState<TrainSearchDto | undefined>()

  const onPage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage)
  }

  const onRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPageSize(Number.parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleClickTrain = (trainDto: TrainDto | undefined) => {
    if (trainDto?.id === train?.id) {
      setTrain(undefined)
      setDisabledButton(true)
    } else {
      setTrain(trainDto)
      setDisabledButton(false)
    }
  }

  const handleSubmit = () => {
    if (train?.id) {
      m2mTrainService
        .compare(train.id, m2mTrainId)
        .then((response) => {
          history.push('/train/m2m/compare', {
            trainCompareResultDto: response.data,
            trainId: train.id,
            m2mTrainId: m2mTrainId,
          })
          setShowCompareDialog(false)
        })
        .catch()
    }
  }

  const search = (data: TrainSearchDto | undefined, fromFilter: boolean) => {
    setSearchDto(data)

    trainService
      .search(page, pageSize, dataSort, cleanObject(data))
      .then((response) => {
        setPageTrain(response.data)

        if (fromFilter) {
          setPage(0)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const searchFiltered = (data: TrainSearchDto | undefined) => {
    search(data, true)
  }

  const handleClose = () => {
    setShowCompareDialog(false)
    setSearchDto(undefined)
  }

  useEffect(() => {
    let isMounted = true

    if (showCompareDialog) {
      trainService
        .search(page, pageSize, dataSort, searchDto)
        .then((response) => {
          if (isMounted) {
            //check if component is still mounted
            setPageTrain(response.data)
          }
        })
        .catch()
    }

    return () => {
      // set it to false when the component unmounts
      isMounted = false
    }
  }, [showCompareDialog, page, pageSize]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog classes={{ paper: classes.dialog }} open={showCompareDialog}>
      <DialogTitle>{t('pages.m2m.dialog.title')}</DialogTitle>
      <DialogContent>
        <TrainM2mSearchFilter searchFilter={searchFiltered} />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('pages.train.labels.trainName')}</TableCell>
                <TableCell>{t('pages.train.labels.trainDate')}</TableCell>
                <TableCell>{t('pages.train.labels.realDepartureDateTime')}</TableCell>
                <TableCell>{t('pages.train.labels.trainState')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pageTrain?.content?.length !== 0 ? (
                pageTrain?.content?.map((trainDto: TrainDto) => {
                  return (
                    <TableRow
                      className={train?.id === trainDto.id ? classes.trainActiveRow : classes.trainRow}
                      key={trainDto.id}
                      onClick={() => handleClickTrain(trainDto)}
                    >
                      <TableCell>{trainDto.name}</TableCell>
                      <TableCell>
                        {format(new Date(trainDto.departureDateTime ?? new Date()), 'dd.MM.yyyy HH:mm')}
                      </TableCell>
                      <TableCell align="center">
                        {trainDto.realDepartureDateTime
                          ? format(new Date(trainDto.realDepartureDateTime), 'dd.MM.yyyy HH:mm')
                          : ''}
                      </TableCell>
                      <TableCell>{t(`enum.trainState.${trainDto.trainState}`)}</TableCell>
                    </TableRow>
                  )
                })
              ) : (
                <TableRow>
                  <TableCell className={classes.noData} colSpan={4}>
                    {t('pages.common.table.noDataAvailable')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={pageTrain?.totalElements ? pageTrain.totalElements : 0}
          onPageChange={onPage}
          page={page}
          rowsPerPage={pageSize}
          labelRowsPerPage={t('pages.common.pagination.rowsPerPage')}
          rowsPerPageOptions={[10, 20, 50, 150]}
          onRowsPerPageChange={onRowsPerPage}
        />
      </DialogContent>
      <DialogActions disableSpacing className={classes.dialogActions}>
        <Box className={classes.buttonBox}>
          <ConardButton conardVariant="dark" disabled={disableButton} onClick={handleSubmit}>
            {t('any.buttons.open')}
          </ConardButton>
        </Box>
        <Box>
          <ConardButton conardVariant="dark" onClick={handleClose}>
            {t('any.buttons.cancel')}
          </ConardButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default TrainM2mCompareDialog
