import React, { FC } from 'react'
import { createStyles, Grid, Hidden, makeStyles, Paper, Theme, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import DoneOutlineIcon from '@material-ui/icons/DoneOutline'
import CancelIcon from '@material-ui/icons/Cancel'
import ConardButton from '../../components/ConardButton'
import m2mTrainService from '../../services/M2mTrainService'
import { CompareDto, CompareDtoCompareStatusEnum, TrainCompareResultDto } from '../../api'

interface TrainM2mCompareProps {
  trainCompareResultDto: TrainCompareResultDto
  trainId: number
  m2mTrainId: number
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      marginTop: '8vh',
    },
    button: {
      height: '50px',
      margin: '20px 0',
    },
    resultTable: {
      padding: 16,
    },
    resultHeader: {
      margin: '16px 0 0 16px',
    },
    resultLine: {
      padding: 8,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    resultPassed: {
      padding: 8,
      margin: 8,
      backgroundColor: theme.palette.background.default,
      borderRadius: 4,
      textAlign: 'end',
    },
    resultFailed: {
      padding: 8,
      margin: 8,
      backgroundColor: theme.palette.error.light,
      borderRadius: 4,
      textAlign: 'end',
    },
    resultIconPassed: {
      padding: 10,
      margin: 8,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.default,
      borderRadius: 4,
    },
    resultIconFailed: {
      padding: 10,
      margin: 8,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      backgroundColor: theme.palette.error.light,
      borderRadius: 4,
    },
  })
)

const TrainM2mComparePage: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  const location = useLocation<TrainM2mCompareProps>()
  const locationM2mCompareTrainResult = location?.state?.trainCompareResultDto
  const locationTrainId = location?.state?.trainId
  const locationM2mTrainId = location?.state?.m2mTrainId

  const downloadExport = () => {
    m2mTrainService.downloadExport(locationTrainId, locationM2mTrainId).then((response) => {
      const url = window.URL.createObjectURL(response.data)
      const a = document.createElement('a')
      a.href = url
      a.download = `compare_export_${locationTrainId}_${locationM2mTrainId}.xlsx`
      a.click()
    })
  }

  const renderCompareDtos = (data: CompareDto[]) => {
    return data.map((wagonResult, index) => (
      <Grid container key={`${index}-${wagonResult.identifier}`} className={classes.resultLine}>
        <Grid
          item
          xs={5}
          className={
            wagonResult.compareStatus === CompareDtoCompareStatusEnum.Correct
              ? classes.resultPassed
              : classes.resultFailed
          }
        >
          <Typography variant="subtitle1">
            {wagonResult.compareStatus === CompareDtoCompareStatusEnum.Missing
              ? t('pages.m2m.compare.missing')
              : wagonResult.identifier}
          </Typography>
        </Grid>
        <Grid
          item
          xs={5}
          className={
            wagonResult.compareStatus === CompareDtoCompareStatusEnum.Correct
              ? classes.resultPassed
              : classes.resultFailed
          }
        >
          <Typography variant="subtitle1">
            {wagonResult.compareStatus === CompareDtoCompareStatusEnum.Redundant
              ? t('pages.m2m.compare.missing')
              : wagonResult.identifier}
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
          className={
            wagonResult.compareStatus === CompareDtoCompareStatusEnum.Correct
              ? classes.resultPassed
              : classes.resultFailed
          }
        >
          {wagonResult.compareStatus === CompareDtoCompareStatusEnum.Correct ? (
            <DoneOutlineIcon color="primary" />
          ) : (
            <CancelIcon color="error" />
          )}
        </Grid>
      </Grid>
    ))
  }

  return (
    <>
      <Typography variant="h4" color="primary">
        {t('pages.m2m.compare.title')}
      </Typography>
      <Grid container className={classes.buttonContainer}>
        <Hidden smDown>
          <Grid item xl={10} lg={8} md={10} />
        </Hidden>
        <Grid item xl={2} lg={4} md={2} sm={12} xs={12}>
          <ConardButton
            className={classes.button}
            conardVariant="light"
            onClick={() => history.push('/search/train/detail/' + locationTrainId)}
          >
            {t('pages.train.labels.formDetail')}
          </ConardButton>
          <ConardButton className={classes.button} conardVariant="light" onClick={downloadExport}>
            {t('any.buttons.export')}
          </ConardButton>
        </Grid>
      </Grid>
      <Paper>
        <Grid container>
          <Grid item className={classes.resultTable} xl={6} lg={6} md={6} sm={12} xs={12}>
            <Typography variant="h6" color="primary">
              {t('pages.m2m.compare.wagonNumbers')}
            </Typography>
            <Grid container>
              <Grid item xs={5} className={classes.resultHeader}>
                <Typography variant="subtitle1">{t('pages.m2m.compare.m2mRecords')}</Typography>
              </Grid>
              <Grid item xs={5} className={classes.resultHeader}>
                <Typography variant="subtitle1">{t('pages.m2m.compare.trainRecords')}</Typography>
              </Grid>
              <Grid item xs={1} />
            </Grid>
            {renderCompareDtos(locationM2mCompareTrainResult.wagonCompareDtoList ?? [])}
          </Grid>
          <Grid item className={classes.resultTable} xl={6} lg={6} md={6} sm={12} xs={12}>
            <Typography variant="h6" color="primary">
              {t('pages.m2m.compare.iluCodes')}
            </Typography>
            <Grid container>
              <Grid item xs={5} className={classes.resultHeader}>
                <Typography variant="subtitle1">{t('pages.m2m.compare.m2mRecords')}</Typography>
              </Grid>
              <Grid item xs={5} className={classes.resultHeader}>
                <Typography variant="subtitle1">{t('pages.m2m.compare.trainRecords')}</Typography>
              </Grid>
              <Grid item xs={1} />
            </Grid>
            {renderCompareDtos(locationM2mCompareTrainResult.containerCompareDtoList ?? [])}
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default TrainM2mComparePage
