import { ManipulationRequestApi, ManipulationRequestDto } from '../api'
import myAxios from '../config/axios/axios'
import { AxiosPromise } from 'axios'

let api: ManipulationRequestApi

export const setManipulationRequestApiConfiguration = () => {
  api = new ManipulationRequestApi(undefined, process.env.REACT_APP_BE_REST_URL, myAxios)
}

const manipulationRequestService = {
  create: (manipulationRequest: ManipulationRequestDto): AxiosPromise<ManipulationRequestDto> => {
    return api.manipulationRequestCreate(manipulationRequest)
  },
  store: (manipulationRequestId: number): AxiosPromise<ManipulationRequestDto> => {
    return api.manipulationRequestStore(manipulationRequestId)
  },
  cancel: (manipulationRequestId: number): AxiosPromise<ManipulationRequestDto> => {
    return api.manipulationRequestCancel(manipulationRequestId)
  },
  getPending: (): AxiosPromise<ManipulationRequestDto[]> => {
    return api.manipulationRequestGetPending()
  },
}

export default manipulationRequestService
